import axios from 'axios';
import { StringOrNumber } from 'src/models/common';
import { convertToComponentCreateRequestParameters } from 'src/models/api/Report/Component/componentCreateRequestParameters';
import { convertToComponentUpdateRequestParameters } from 'src/models/api/Report/Component/componentUpdateRequestParameters';
import { ComponentBulkUpdateLayoutRequestParameters } from 'src/models/api/Report/Component/componentBulkUpdateLayoutRequestParameters';
import { convertFromComponentCreateResponseData } from 'src/models/api/Report/Component/componentCreateResponseData';
import { RecordId } from 'src/util/recordId';
import { Component } from 'src/models/new/component';
import { buildBackendUrl } from 'src/apis/util/url';
import { convertToComponentCopyRequestParameters } from 'src/models/api/Report/Component/componentCopyRequestParameters';

// FIXME: stringは本来必要なく、numberを要求すべき
const buildBasePath = (reportId: StringOrNumber): string => {
  return `/masters/reports/${reportId}/components`;
};

export default {
  async create(reportId: StringOrNumber, params: Component): Promise<{ id: RecordId }> {
    const url = buildBackendUrl(buildBasePath(reportId));
    const convertedParams = convertToComponentCreateRequestParameters(params);
    const { data: resData } = await axios.post(url, convertedParams);
    return convertFromComponentCreateResponseData(resData);
  },
  async update(reportId: StringOrNumber, params: Component): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), params.id);
    const convertedParams = convertToComponentUpdateRequestParameters(params);
    await axios.put(url, convertedParams);
    return {};
  },
  async delete(reportId: StringOrNumber, id: StringOrNumber): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), id);
    await axios.delete(url);
    return {};
  },
  async bulkUpdateLayout(reportId: StringOrNumber, params: ComponentBulkUpdateLayoutRequestParameters): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), 'bulk_update_layout');
    await axios.post(url, params);
    return {};
  },
  async copy(reportId: number, id: number, params: { sectionId: number }): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), id, 'copy');
    const requestParams = convertToComponentCopyRequestParameters(params);
    await axios.post(url, requestParams);
    return {};
  },
};
