import axios from 'axios';
import type { ActionLogCreateParameters } from 'src/models/api/actionLog/actionLogCreateParameters';
import type { ActionType } from 'src/models/api/actionLog/actionType';
import { convertKeysToSnakeCase } from 'src/apis/util/object';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (action_type: string): string => `/action_logs/${action_type}_action_logs`;

export default {
  async create(actionType: ActionType, params: ActionLogCreateParameters<typeof actionType>): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(actionType));
    await axios.post(url, convertKeysToSnakeCase(params));
    return {};
  },
};
