import { ValidationResult } from 'src/util/VeeValidate/validationResult'
import { vvReset, vvValidate } from 'src/util/vee_validate'
import { MapObject } from 'vee-validate'
import { ComputedRef, computed, getCurrentInstance, ref } from 'vue'

export const useValidator = () => {
  const vue = getCurrentInstance()!.proxy

  const validations = ref<MapObject | ComputedRef<MapObject>>({})

  const setValidation = (value: MapObject | ComputedRef<MapObject>) => {
    validations.value = value
  }

  const validationResult = computed(() => new ValidationResult(vue, validations.value))

  const ensureValidation = async() => await vvValidate(vue)

  const unvalidate = (key: string) => vvReset(vue, key)

  const resetValidationResult = () => vvReset(vue)

  return {
    validations,
    setValidation,
    validationResult,
    ensureValidation,
    unvalidate,
    resetValidationResult,
  }
}
