<template>
  <!--
    Vue RouterLinkはアクティブな場合にactive-classで指定したクラスを付与するが
    本コンポーネントでその制御は算出プロパティを用いて扱う為、active-classは空文字とする
  -->
  <component
    :is="baseComponent"
    :to="link.path ?? '/'"
    :class="{ active: isActive }"
    active-class=""
    tag="li"
  >
    <a v-if="isMenu"
       data-toggle="collapse"
       href="#"
       @click.prevent="collapseMenu">
      <i :class="link.icon"></i>
      <p>{{link.name}}
        <b class="caret" :class="{rotated: !collapsed}"></b>
      </p>
    </a>
    <collapse-transition v-if="$slots.default || this.isMenu">
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>
    <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
      <component
        :to="link.path"
        :is="elementType(link, false)"
        :class="{active: link.active}"
        :target="link.target"
        @click.native.stop="onItemClick"
        :href="link.path">
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{link.name.substring(0, 1)}}</span>
          <span class="sidebar-normal">{{link.name}}</span>
        </template>
        <template v-else>
          <i :class="link.icon"></i>
          <p>{{link.name}}</p>
          <span v-if="!!link.nameMini" class="name-mini">{{link.nameMini}}</span>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'

export default {
  components: {
    CollapseTransition,
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
      description: 'Whether item is a menu containing multiple items',
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: [],
        }
      },
      description: 'Link object',
    },
    sidebarStore: {
      type: Object,
      required: true,
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    }
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: { default: false },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    }
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? 'li' : 'router-link'
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true
    },
    isActive() {
      // 例外: ルートが初期化されていない場合はfalseを返す
      if (!this.$route) return false

      const path = this.link.path
      const activePath = this.link.activePath
      const currentPath = this.$route.path
      // 例外: パスがルートURLの場合は完全一致で判断する
      if (path === '/') return currentPath === path

      // 要素自体のパスがマッチしていれば選択されていると見做す
      if (this.isIncludedInPath(currentPath, path)) return true
      if (this.isIncludedInPath(currentPath, activePath)) return true

      // 子要素のパスがマッチしていれば選択されていると見做す
      const matchingRoute = this.children
        .find(child => this.isIncludedInPath(currentPath, child.link.path))
      if (matchingRoute !== undefined) return true

      return false
    },
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode)
      this.children.splice(index, 0, item)
    },
    removeChild(item) {
      const tabs = this.children
      const index = tabs.indexOf(item)
      tabs.splice(index, 1)
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a'
      } else {
        return 'router-link'
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed
    },
    onItemClick() {
      if (this.autoClose) {
        // eslint-disable-next-line vue/no-mutating-props
        this.sidebarStore.showSidebar = false
      }
    },
    isIncludedInPath(path, anotherPath) {
      // 完全一致していれば選択されていると見做す
      // 先頭から次のスラッシュまでが一致していれば選択されていると見做す
      return path === anotherPath || path.startsWith(`${anotherPath}/`)
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this)
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this)
    }
  },
}
</script>
<style scoped>
  .caret.rotated {
    transform: rotate(180deg);
  }
</style>
