
import { computed, defineComponent, PropType } from 'vue'
import { Link } from 'src/models/new/link'
import { useLinkViewBuilder } from 'src/views/Dashboard/Settings/Reports/shared/components/LinkList/LinkListRow/composables/useLinkViewBuilder'

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<Link>,
      required: true,
    },
    edit: {
      type: Function,
      required: true,
    },
    del: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { linkViewBuilder } = useLinkViewBuilder()
    const linkView = computed(() => linkViewBuilder(props.link))

    const onEditClicked = () => {
      props.edit(props.link)
    }
    const onDeleteClicked = () => {
      props.del(props.link)
    }

    return {
      props,
      linkView,
      onEditClicked,
      onDeleteClicked,
    }
  },
})
