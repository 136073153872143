import { DataSourceParameterValue } from 'src/models/dataSourceParameterValue';

export type DataSourceParameterValueResponseData = {
  id: number;
  label: string;
};

export const convertFromDataSourceParameterValueResponseData = (
  data: DataSourceParameterValueResponseData,
): DataSourceParameterValue => {
  return {
    id: data.id,
    label: data.label,
  };
};
