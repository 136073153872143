import { useEnumOptions } from 'src/composables/useEnumOptions';
import { METRICS_TYPE_LOCAL_WORDS, MetricsType } from 'src/models/new/metrics';

export const useMetricsTypeOptions = () => {
  const { options, filterOptions } = useEnumOptions<MetricsType>(METRICS_TYPE_LOCAL_WORDS);

  return {
    options,
    filterOptions,
  };
};
