import { isUrlCustomReportUrl } from 'src/business/customUrl'
import { Link } from 'src/models/new/link'
import { ref } from 'vue'

const REPORT = 'report'
const EXTERNAL = 'external'

export type LinkType = typeof REPORT | typeof EXTERNAL

export type LinkTypeOption = {
  label: string
  value: LinkType
}

const LINK_TYPE_OPTIONS: LinkTypeOption[] = [
  { label: 'レポート', value: REPORT },
  { label: '外部リンク', value: EXTERNAL },
]

export const useLinkType = () => {
  const linkType = ref<LinkType>(REPORT)

  const initializeLinkType = (link: Link) => {
    if (isUrlCustomReportUrl(link.url)) {
      linkType.value = REPORT
    } else if (link.url) {
      linkType.value = EXTERNAL
    } else {
      linkType.value = REPORT
    }
  }

  return {
    linkType,
    initializeLinkType,
    LINK_TYPE_OPTIONS,
  }
}
