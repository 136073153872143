import { Report } from 'src/models/new/report'
import { RecordId, isRecordIdValid } from 'src/util/recordId'
import { buildUrl, extractUrlHash, extractUrlMainPart, extractUrlQueryString } from 'src/util/url'

export const SCHEME_REPORT = 'reports://'
const PATH_TEMPLATE_REPORT = '/reports/:id'

export const reportToCustomUrl = (report: Report, queryString: string = '', urlHash: string = ''): string => {
  return buildUrl(SCHEME_REPORT, report.id, queryString, urlHash)
}

export const normalizeReportSchemeUrl = (url: string): string => {
  const parsedUrl = new URL(url)
  const mainPart = extractUrlMainPart(parsedUrl)
  const queryString = extractUrlQueryString(parsedUrl)
  const urlHash = extractUrlHash(parsedUrl)
  // mainPartがidのみである事を想定しており、そうでない場合はレポート画面のURLではなくなってしまう
  return location.origin +
    PATH_TEMPLATE_REPORT.replace('/:id', mainPart) +
    (queryString ? '?' + queryString : '') +
    (urlHash ? '#' + urlHash : '')
}

export const customUrlToReportId = (url: string): RecordId | null => {
  if (!url.startsWith(SCHEME_REPORT)) return null
  const parsedUrl = new URL(url)
  const mainPart = extractUrlMainPart(parsedUrl).replace(/^\/\//g, '/')
  if (!mainPart.match(/^\/\d+$/)) return null

  const id = Number(mainPart.replace(/^\//g, ''))
  // 数字部分があまりに大きくJSのNumber型の範囲を越える場合はInfinityになりnullが返る
  // あまりに大きな場合はバックエンドにもidとして存在しないので普通起きないが、条件分岐を考慮する際注意
  return isRecordIdValid(id) ? id : null
}

export const isUrlCustomReportUrl = (url: string): boolean => {
  return customUrlToReportId(url) !== null
}
