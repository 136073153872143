import ChartjsPluginAnnotation, { LineAnnotationOptions } from 'chartjs-plugin-annotation'
import { Scaling } from 'src/business/scaling'
import { convertChartValue } from 'src/util/Chart/custom/shared'
import { AnnotationDrawTimeOption } from 'src/util/Chart/lineAnnotation'

/**
 * Horizontal Lined Annotation & Vertical Lined Annotation
 * 棒グラフ・折れ線グラフに参考値を横線で表示する設定
 *
 * NOTE: chartjs-plugin-annotation v0.5.7 は chart.js v2.9.3 のみ動作することに注意(2.9.4だと動作しない)
 * https://zenn.dev/kokota/articles/f735089432c3dc
 */

type Plugin = Chart.PluginServiceGlobalRegistration & Chart.PluginServiceRegistrationOptions

export const addPluginForLineAnnotation = (plugins: Plugin[]): Plugin[] => {
  return [...plugins, ChartjsPluginAnnotation]
}

export type CustomVerticalLineAnnotationOptions = {
  annotation: {
    drawTime: AnnotationDrawTimeOption
    annotations: [{
      type: 'line'
      mode: 'vertical'
      scaleID: 'x-axis-1'
      value: number
      borderColor: Chart.ChartColor
      label: {
        backgroundColor: string
        enabled: boolean
        content: string
      }
    }]
  }
}

export type CustomHorizontalLineAnnotationOptions = {
  annotation: {
    drawTime: AnnotationDrawTimeOption
    annotations: [{
      type: 'line'
      mode: 'horizontal'
      // 軸は左右に存在しうるが、水平線アノテーションの参照する軸は左軸とする
      scaleID: 'y-axis-1'
      value: number
      borderColor: Chart.ChartColor
      label: {
        backgroundColor: string
        enabled: boolean
        content: string
      }
    }]
  }
}

export const buildHorizontalLineAnnotationOptions = (
  value: number | null,
  decimalPlaces: number,
  scaling: Scaling,
  label: string | null,
  color: string,
): CustomHorizontalLineAnnotationOptions => {
  const hashedColor = `#${color.replaceAll('#', '')}`

  return {
    annotation: {
      drawTime: 'afterDatasetsDraw',
      annotations: [{
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-1',
        value: convertChartValue(Number(value), decimalPlaces, scaling),
        borderColor: hashedColor,
        label: {
          backgroundColor: hashedColor,
          enabled: !!label,
          content: label || '',
        },
      }],
    },
  }
}

export const buildVerticalLineAnnotationOptions = (
  value: number | null,
  decimalPlaces: number,
  scaling: Scaling,
  label: string | null,
  color: string,
): CustomVerticalLineAnnotationOptions => {
  const hashedColor = `#${color.replaceAll('#', '')}`

  return {
    annotation: {
      drawTime: 'afterDatasetsDraw',
      annotations: [{
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-1',
        value: convertChartValue(Number(value), decimalPlaces, scaling),
        borderColor: hashedColor,
        label: {
          backgroundColor: hashedColor,
          enabled: !!label,
          content: label || '',
        },
      }],
    },
  }
}

// TODO: 使わなくなったら削除
export const addOptionForHorizontalLineAnnotation = (
  options: Chart.ChartOptions,
  value: number | null,
  decimalPlaces: number,
  scaling: Scaling,
  label: string | null,
  color: string,
): Chart.ChartOptions => {
  const hashedColor = `#${color.replaceAll('#', '')}`

  const LABEL_FONT_SIZE = 12
  const LABEL_FONT_STYLE = 'bold'
  const LABEL_FONT_COLOR = 'ffffff'

  const lineAnnotationOptions: LineAnnotationOptions = {
    id: 'a-line-1',
    drawTime: 'afterDraw',
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-1',
    value: convertChartValue(Number(value), decimalPlaces, scaling),
    borderColor: hashedColor,
    borderWidth: 2,
    label: {
      backgroundColor: hashedColor,
      fontSize: LABEL_FONT_SIZE,
      fontStyle: LABEL_FONT_STYLE,
      fontColor: `#${LABEL_FONT_COLOR}`,
      position: 'center',
      enabled: !!label,
      content: label || '',
    },
  }

  return {
    ...options,
    annotation: {
      drawTime: 'afterDatasetsDraw',
      annotations: [lineAnnotationOptions],
    },
  }
}

// TODO: 使わなくなったら削除
export const addOptionForVerticalLineAnnotation = (
  options: Chart.ChartOptions,
  value: number | null,
  decimalPlaces: number,
  scaling: Scaling,
  label: string | null,
  color: string,
): Chart.ChartOptions => {
  const hashedColor = `#${color.replaceAll('#', '')}`

  const LABEL_FONT_SIZE = 12
  const LABEL_FONT_STYLE = 'bold'
  const LABEL_FONT_COLOR = 'ffffff'

  const lineAnnotationOptions: LineAnnotationOptions = {
    id: 'a-line-1',
    drawTime: 'afterDraw',
    type: 'line',
    mode: 'vertical',
    scaleID: 'x-axis-0',
    value: convertChartValue(Number(value), decimalPlaces, scaling),
    borderColor: hashedColor,
    borderWidth: 2,
    label: {
      backgroundColor: hashedColor,
      fontSize: LABEL_FONT_SIZE,
      fontStyle: LABEL_FONT_STYLE,
      fontColor: `#${LABEL_FONT_COLOR}`,
      position: 'center',
      enabled: !!label,
      content: label || '',
    },
  }

  return {
    ...options,
    annotation: {
      drawTime: 'afterDatasetsDraw',
      annotations: [lineAnnotationOptions],
    },
  }
}
