import { AccessGroup, GrantedToAccessGroup, internal } from 'src/models/new/accessGroup';

const REPORT = internal.REPORT;
const READ_ONLY = internal.READ_ONLY;

export const GRANT_TO_ACCESS_GROUP_MAX_COUNT = 6;

export type ReportAccessGroup = AccessGroup & {
  // レポートアクセスグループは権限を他のアクセスグループに与えることができる
  // その際、権限を共有する相手のアクセスグループの情報をgrantToに持つ
  grantTo: GrantedToAccessGroup[];
};

export const constructEmptyReportAccessGroup = (): ReportAccessGroup => {
  return {
    id: 0,
    name: '',
    type: REPORT,
    role: READ_ONLY,
    searchId: '',
    grantTo: [],
  };
};
