import { deepMerge } from 'src/util/object';
import { inject, InjectionKey, nextTick, provide, ref, watch, type Ref } from 'vue';

const STYLE_FOR_PRINT_ID = 'style-for-print' as const;
const PRINT_LAYOUT_ID = 'print-layout' as const;

const key: InjectionKey<Ref<HTMLElement | null>> = Symbol('printLayoutElementInjection');

type CSSStyle = Record<string, string | number>;

type CSSStructure = {
  [key: string]: CSSStyle | CSSStructure;
};

type Margin = {
  top: string;
  right: string;
  bottom: string;
  left: string;
};

const zeroMargin = { top: '0', right: '0', bottom: '0', left: '0' };

export const usePrintLayoutProvider = ({ layoutElement }: { layoutElement: Ref<HTMLElement | null> }) => {
  // 引数に取ったエレメントに対してCSSでスタイルを設定する為、必ずIDが付いている状態にする
  watch(
    layoutElement,
    (element) => {
      if (element !== null && element.id === '') {
        element.id = PRINT_LAYOUT_ID;
      }
    },
    { immediate: true },
  );

  provide(key, layoutElement);
};

export const usePrintLayout = () => {
  const injection = inject(key);

  if (injection === null) {
    console.error(`${key.toString()} is not provided`);
  }

  const layoutElement = injection ?? ref(null);
  const styles = ref<CSSStructure>({});

  watch(styles, (newStyles) => {
    const styleForPrintElement = document.querySelector(`#${STYLE_FOR_PRINT_ID}`) ?? prepareNewStyleElement();
    styleForPrintElement.innerHTML = jsonToCssCode(newStyles);
  });

  const buildSheetSelector = (): string => `#${layoutElement.value?.id} .sheet`;
  const buildSheetScrollSelector = (): string => `#${layoutElement.value?.id} .sheet-scroll`;

  /**
   * 印刷用の @page スタイルは、各ページの scss に書いても排他的に管理できない。
   * （例えば size の landscape がどこかのページで定義されると、他のページも landscape な印刷結果になってしまう）
   * 上記を回避するために印刷用の style タグを 1 つ用意し、印刷前に適宜その内容を書き換えることで対応する。
   */
  const setStyleForPrint = ({ size, margin }: { size?: string; margin?: Margin } = {}) => {
    // usePrintLayoutProviderのwatcherによるid付与をnextTickで待つ
    nextTick(() => {
      setSize(size, margin);
      setMargin(margin);
    });
  };

  const setSize = (size: string = 'A3', margin: Margin = zeroMargin) => {
    layoutElement.value?.classList.remove('A3', 'A4', 'A5', 'landscape');
    layoutElement.value?.classList.add(...size?.split(' '));
    const pageStyleToSet: CSSStructure = { '@media print': { '@page': { size } } };
    const screenStyleToSet: CSSStructure =
      layoutElement.value !== null
        ? {
            '@media screen': {
              [buildSheetScrollSelector()]: { width: getBaseWidthFromSize(size) },
              [buildSheetSelector()]: { width: getBaseWidthFromSize(size), height: getBaseHeightFromSize(size) },
            },
          }
        : {};
    const printStyleToSet: CSSStructure =
      layoutElement.value !== null
        ? {
            '@media print': {
              [buildSheetScrollSelector()]: { width: getWidthExpression(size, margin) },
              [buildSheetSelector()]: {
                width: getWidthExpression(size, margin),
                height: getHeightExpression(size, margin),
              },
            },
          }
        : {};
    styles.value = deepMerge(deepMerge(deepMerge(styles.value, pageStyleToSet), screenStyleToSet), printStyleToSet);
  };

  const setMargin = (margin: Margin = zeroMargin) => {
    const pageStyleToSet: CSSStructure = { '@media print': { '@page': { margin: getMarginExpression(margin) } } };
    const screenStyleToSet: CSSStructure =
      layoutElement.value !== null
        ? {
            '@media screen': {
              [buildSheetScrollSelector()]: { padding: getMarginExpression(margin) },
              [buildSheetSelector()]: { padding: getMarginExpression(margin) },
            },
          }
        : {};
    const printStyleToSet: CSSStructure =
      layoutElement.value !== null
        ? {
            '@media print': {
              [buildSheetScrollSelector()]: { padding: 0 },
              [buildSheetSelector()]: { padding: 0 },
            },
          }
        : {};
    styles.value = deepMerge(deepMerge(deepMerge(styles.value, pageStyleToSet), screenStyleToSet), printStyleToSet);
  };

  return {
    setStyleForPrint,
  };
};

const prepareNewStyleElement = () => {
  const element = document.createElement('style');
  element.id = STYLE_FOR_PRINT_ID;
  document.head.appendChild(element);

  return element;
};

// オブジェクトをCSSコードに変換する
const jsonToCssCode = (styles: CSSStructure): string => {
  const convertProperties = (obj: CSSStyle): string => {
    return Object.entries(obj)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ');
  };

  const convertToCss = (obj: CSSStructure): string => {
    return Object.entries(obj)
      .map(([key, value]) => {
        return isCSSStyle(value)
          ? `${key} {\n${convertProperties(value)}\n}`
          : `${key} {\n${convertToCss(value as CSSStructure)}\n}`;
      })
      .join('\n');
  };

  return convertToCss(styles);
};

const isCSSStyle = (obj: CSSStructure | CSSStyle): obj is CSSStyle => {
  return Object.values(obj).every((val) => typeof val === 'string' || typeof val === 'number');
};

const getBaseHeightFromSize = (size: string): string => {
  switch (size) {
    case 'A3':
      return '420mm';
    case 'A3 landscape':
      return '297mm';
    case 'A4':
      return '297mm';
    case 'A4 landscape':
      return '210mm';
    case 'A5':
      return '210mm';
    case 'A5 landscape':
      return '148mm';
    default:
      // A3 portrait
      return '420mm';
  }
};

const getHeightExpression = (size: string, margin: Margin): string => {
  const topMarginCorrection = margin.top !== '0' ? ` - ${margin.top}` : '';
  const bottomMarginCorrection = margin.bottom !== '0' ? ` - ${margin.bottom}` : '';

  return `calc(${getBaseHeightFromSize(size)}${topMarginCorrection}${bottomMarginCorrection})`;
};

const getBaseWidthFromSize = (size: string): string => {
  switch (size) {
    case 'A3':
      return '297mm';
    case 'A3 landscape':
      return '420mm';
    case 'A4':
      return '210mm';
    case 'A4 landscape':
      return '297mm';
    case 'A5':
      return '148mm';
    case 'A5 landscape':
      return '210mm';
    default:
      // A3 portrait
      return '297mm';
  }
};

const getWidthExpression = (size: string, margin: Margin): string => {
  const rightMarginCorrection = margin.right !== '0' ? ` - ${margin.right}` : '';
  const leftMarginCorrection = margin.left !== '0' ? ` - ${margin.left}` : '';

  return `calc(${getBaseWidthFromSize(size)}${rightMarginCorrection}${leftMarginCorrection})`;
};

const getMarginExpression = (margin: Margin): string => {
  return `${margin.top} ${margin.right} ${margin.bottom} ${margin.left}`;
};
