import { Context } from 'chartjs-plugin-datalabels'
import { Options } from 'chartjs-plugin-datalabels/types/options'

export const datalabelsPluginDefaultOptions: Options = {
  /**
   * Multi-labels definition object where each property represents a new
   * label, the key being the label key and the value being the options
   * specific to each label and merged on top of the root options.
   * @default undefined
   * @since 0.7.0
   */
  // labels: undefined,

  /**
   * The label box alignment relative to `anchor` that can be expressed either
   * by a number representing the clockwise angle (in degree) or a by one of
   * the following string presets:
   *
   * - `'start'`: before the anchor point, following the same direction
   * - `'end'`: after the anchor point, following the same direction
   * - `'center'`: centered on the anchor point
   * - `'right'`: 0°
   * - `'bottom'`: 90°
   * - `'left'`: 180°
   * - `'top'`: 270°
   *
   * @default 'center'
   * @since 0.1.0
   */
  // align: 'center',

  /**
   * The label box alignment relative to the element:
   *
   * - `'center'`
   * - `'end'`
   * - `'start'`
   *
   * @default 'center'
   * @since 0.1.0
   */
  // anchor: 'center',

  /**
   * The color used to draw the background of the surrounding frame.
   * @default null (no background)
   * @since 0.1.0
   */
  // backgroundColor: null,

  /**
   * The color used to draw the border of the surrounding frame.
   * @default null (no border)
   * @since 0.1.0
   */
  // borderColor: null,

  /**
   * The border radius used to add rounded corners to the surrounding frame.
   * @default 0 (not rounded)
   * @since 0.1.0
   */
  // borderRadius: 0,

  /**
   * The border width of the surrounding frame.
   * @default 0 (no border)
   * @since 0.1.0
   */
  // borderWidth: 0,

  /**
   * When `true`, the anchor position is calculated based on the visible
   * geometry of the associated element (i.e. part inside the chart area).
   * @default false (no clamping)
   * @since 0.5.0
   */
  // clamp: false,

  /**
   * Clip the label drawing to the chart area.
   * @default false (no clipping)
   * @since 0.4.0
   */
  // clip: false,

  /**
   * The color used to draw the label text.
   * @default undefined (Chart.defaults.global.defaultFontColor)
   * @since 0.1.0
   */
  color: '#ffffff',

  /**
   * When `false`, the label is hidden and associated options are not
   * calculated, else if `true`, the label is drawn. If `auto`, the
   * label is automatically hidden if it appears under another label.
   * @default true
   * @since 0.1.0 (boolean), 0.5.0 ('auto')
   */
  // display: true,

  /**
   * The font options used to draw the label text.
   * @default Chart.defaults.global.defaultFont.*
   * @since 0.1.0
   */
  font: {
    weight: 'bold',
  },

  /**
   * Allows to customize the label text by transforming input data.
   * @default data[index] // parsed as string
   * @since 0.1.0
   */
  formatter: (value: number | null, _: Context) => {
    // デフォルトでカンマ区切りの数値に変換する
    return value !== null ? value.toLocaleString('ja-JP') : ''
  },

  /**
   * Event listeners, where the property is the type of the event to listen
   * and the value a callback with a unique `context` argument containing
   * the same information as for scriptable options. If a callback explicitly
   * returns `true`, the label is updated with the current context and the
   * chart re-rendered. This allows to implement visual interactions with
   * labels such as highlight, selection, etc.
   * @default {}
   * @since 0.3.0
   */
  listeners: {
    /**
     * Called when a mouse click is detected within a label.
     * @since 0.3.0
     */
    // click: () => {},

    /**
     * Called when the mouse enters a label.
     * @since 0.3.0
     */
    // enter: () => {},

    /**
     * Called when the mouse leaves a label.
     * @since 0.3.0
     */
    // leave: () => {},
  },

  /**
   * The distance (in pixels) to pull the label away from the anchor point,
   * the direction being determined by the `align` value (only applicable
   * if `align` is `start` or `end`).
   * @default 4
   * @since 0.1.0
   */
  // offset: 4,

  /**
   * The label global opacity, including text, background, borders, etc.,
   * specified as a number between 0.0 (fully transparent) and 1.0 (fully opaque).
   * @default 1
   * @since 0.1.0
   */
  // opacity: 1,

  /**
   * The padding (in pixels) to apply between the text and the surrounding frame.
   * @default 4 (all values)
   * @since 0.1.0
   */
  // padding: 4,

  /**
   * Clockwise rotation of the label relative to its center.
   * @default 0
   * @since 0.1.0
   */
  // rotation: 0,

  /**
   * Text alignment for multi-lines labels.
   * @default 'start'
   * @since 0.1.0
   */
  // textAlign: 'start',

  /**
   * The stroke color used to draw the label text. If this options is
   * not set (default), the value of the `color` option will be used.
   * @default color
   * @since 0.5.0
   */
  // textStrokeColor: '#ffffff',

  /**
   * The width of the stroke for the label text.
   * @default 0 (no stroke)
   * @since 0.5.0
   */
  // textStrokeWidth: 0,

  /**
   * The amount of blur applied to shadow under the label text.
   * @default 0 (no shadow)
   * @since 0.5.0
   */
  textShadowBlur: 0,

  /**
   * The color of the shadow under the label text.
   * @default color
   * @since 0.5.0
   */
  // textStrokeColor: '#ffffff',
}
