
import Vue, { PropType, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import { Component, COMPONENT_TYPES, ComponentType, componentTypeToLabel } from 'src/models/new/component';
import { constructEmptyMetricsCardComponent } from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import { constructEmptyMetricsTableComponent } from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { constructEmptyMetricsListComponent } from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import { constructEmptyMetricsGraphComponent } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent';
import { constructEmptyCommentComponent } from 'src/models/new/Component/commentComponent';

interface State {
  // コンポーネントの表示に必要な処理はないが、モーダルのフェードインアニメーションを実行させるために利用している
  isReady: boolean;
  componentType: ComponentType | null;
}

const componentSkeletonMap: Record<ComponentType, Component> = [
  constructEmptyMetricsCardComponent(),
  constructEmptyMetricsTableComponent(),
  constructEmptyMetricsListComponent(),
  constructEmptyMetricsGraphComponent(),
  constructEmptyCommentComponent(),
].reduce((map, el) => {
  map[el.componentType] = el;
  return map;
}, {} as Record<ComponentType, Component>);

function setupState(root: Vue): State {
  const state: State = reactive({
    isReady: false,
    componentType: null,
  });
  return state;
}

export default defineComponent({
  props: {
    onConfirm: {
      type: Function as PropType<(component: Component) => void>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);

    const close = () => {
      emit('close');
    };

    const confirm = (): void => {
      if (!state.componentType) {
        return;
      }
      props.onConfirm(componentSkeletonMap[state.componentType]);
      close();
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false;
      state.isReady = true;
    });

    const componentTypeOptions = COMPONENT_TYPES.map((type) => ({ value: type, label: componentTypeToLabel(type) }));

    return {
      props,
      state,
      componentTypeOptions,
      confirm,
      close,
    };
  },
});
