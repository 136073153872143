// throttleとかdebounceが実装してあってもいいよね的な
type GatedFuncResult<R> = [false, undefined] | [true, R];

type AsyncFuncGateOptions = {
  tag?: string;
};

interface GatedFuncGenerator {
  makeAsyncFuncGated: <A extends any[] = any[], R extends any = any>(
    asyncFunc: (...args: A) => Promise<R>,
    opts?: AsyncFuncGateOptions,
  ) => (...args: A) => Promise<GatedFuncResult<R>>;
}

type FunctionState = {
  isOnGoing: boolean;
};
type FunctionStateByTag = Record<string, FunctionState>;

export function getGatedFuncGenerator(): GatedFuncGenerator {
  const stateByTag: FunctionStateByTag = {};
  return {
    makeAsyncFuncGated<A extends any[] = any[], R extends any = any>(
      asyncFunc: (...args: A) => Promise<R>,
      opts: AsyncFuncGateOptions = {},
    ): (...args: A) => Promise<GatedFuncResult<R>> {
      const tag = opts.tag ?? 'isLoading';
      return async (...args: A) => {
        if (stateByTag[tag]?.isOnGoing) {
          return [false, undefined];
        }

        stateByTag[tag] = { ...(stateByTag[tag] ?? {}), isOnGoing: true };

        let succeeded = false;
        let funcResult: R;
        try {
          funcResult = await asyncFunc(...args);
          succeeded = true;
        } finally {
          stateByTag[tag] = { ...(stateByTag[tag] ?? {}), isOnGoing: false };
        }

        return succeeded ? [true, funcResult] : [false, undefined];
      };
    },
  };
}
