import axios from 'axios';
import { ConversionProgram } from 'src/models/conversionProgram';
import { buildBackendUrl } from 'src/apis/util/url';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';
import { ConversionProgramIndexRequestParameters } from 'src/models/api/ConversionProgram/conversionProgramIndexRequestParameters';
import {
  ConversionProgramResponseData,
  convertConversionProgramResponseData,
} from 'src/models/api/ConversionProgram/conversionProgramResponseData';
import { PaginatedMultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (): string => '/conversion_programs';

export default {
  async index(
    data: ConversionProgramIndexRequestParameters,
  ): Promise<{ result: ConversionProgram[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<ConversionProgramResponseData>>(url, {
      params: data,
    });
    return {
      result: resData.result.map(convertConversionProgramResponseData),
      pagination: convertFromPagination(resData.pagination),
    };
  },
};
