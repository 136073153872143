import { render, staticRenderFns } from "./SelectSingleMetricsOnMetricsForm.vue?vue&type=template&id=55a39677&scoped=true&"
import script from "./SelectSingleMetricsOnMetricsForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SelectSingleMetricsOnMetricsForm.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SelectSingleMetricsOnMetricsForm.vue?vue&type=style&index=0&id=55a39677&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a39677",
  null
  
)

export default component.exports