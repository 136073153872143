import { Component } from 'src/models/new/component'

export type Section = {
  id: number
  name: string | null
  sequentialOrder: number
  // コンポーネントが未登録の他、データをロードしていない状態でも空になりうる
  // TODO: コンポーネントがレポートの直下に位置する構造に変更された
  // 将来的にはSectionがcomponentsを持たず、純粋にComponent側からidで参照される形にする
  components: Component[]
}

export const constructEmptySection = (): Section => {
  return {
    id: 0,
    name: null,
    sequentialOrder: 1,
    // Reportへ移行して削除の予定
    components: [],
  }
}

export const sectionToNumberedLabel = (section: Section) => `${section.sequentialOrder}: ${section.name || '(名称未設定)'}`
