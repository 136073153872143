
import { PropType } from 'vue';
import AppLink from 'src/components/UIComponents/AppLink.vue';
import { normalizeUrl } from 'src/business/customUrl';
import { getUrlInternalPath } from 'src/util/url';
import { Location } from 'vue-router';

/**
 * GeneralLink
 * AppLinkに完全なURLの処理機能を追加したもので、外部リンクを張ることが可能
 *
 * toプロパティの中にpathが含まれている場合、pathの内容によって適当なノードに切り替わる
 * pathが完全なURLでURLがカスタムURLならアプリケーション内のパスに変換してAppLinkに渡す
 * pathが完全なURLでURLが現在のオリジンと同じ場合はパスに変換してAppLinkに渡す
 * pathが完全なURLでURLが現在のオリジンと異なる場合はaタグにhrefとして渡す
 * それ以外はそのままAppLinkに引数を渡す
 * アプリケーション内ルートを指定してリンクしたい場合、リンクミスによる不適切な遷移を防ぐことのできるAppLinkを使用すること
 */

const isPathAbsoluteUrl = (path: string): boolean => !!path.match(/^.+:\/\//);

export default {
  components: {
    AppLink,
  },
  props: {
    to: {
      type: Object as PropType<Location>,
      required: true,
    },
    target: {
      type: String as PropType<string | undefined>,
      required: false,
    },
  },
  setup(props) {
    // FIXME: リンク先の生成がリアクティブではない
    // 既にAppLinkではリアクティブでなければならないケースがありアップデートされている
    // 仕様上必要になった場合はこちらもpropsに対してリアクティブな形に変更する
    const path = props.to.path;
    // pathが完全なURLの場合はabsoluteUrlに値が入る
    // アプリケーションと同じオリジンの完全なURLである可能性もある
    const absoluteUrl = path && isPathAbsoluteUrl(path) ? normalizeUrl(path) : undefined;
    // absoluteUrlが存在しない場合はpathがそのまま内部パスとして扱われる
    // absoluteUrlが存在する場合でも、アプリケーションとオリジンが同じ場合は内部パスが取得できる
    const internalPath = absoluteUrl ? getUrlInternalPath(absoluteUrl) : path;

    const isExternal = absoluteUrl && !internalPath;
    const internalLinkLocation = {
      ...props.to,
      path: internalPath,
    };
    const externalLinkRawHref = isExternal ? absoluteUrl : undefined;

    return {
      props,
      isExternal,
      internalLinkLocation,
      externalLinkRawHref,
    };
  },
};
