
import Vue, { defineComponent, getCurrentInstance, reactive } from 'vue'
import browserDetect from 'vue-browser-detect-plugin'

interface State {
  showBrowserErrMsg1: boolean
}

export default defineComponent({
  name: 'goto-login',
  setup() {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      showBrowserErrMsg1: false,
    })
    function checkBrowser(): void {
      if (root.$browserDetect.isIE) {
        state.showBrowserErrMsg1 = true
      }
    }
    function gotoLogin(): void {
      location.href = '/users/saml/sign_in'
    }
    return {
      state,
      checkBrowser,
      gotoLogin,
    }
  },
  mounted() {
    Vue.use(browserDetect)
    this.checkBrowser()
  },
  computed: {
    loginFailure() {
      return this.$route.query.failure
    },
  },
})
