
import { computed, defineComponent, PropType, reactive } from 'vue'
import { ReportLink } from 'src/models/new/Link/reportLink'
import LinkFormModal from 'src/views/Dashboard/Settings/Reports/shared/components/LinkFormModal/LinkFormModal.vue'
import { useReportLinkCreator } from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkFormModal/composables/useReportLinkCreator'
import { useReportLinkUpdater } from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkFormModal/composables/useReportLinkUpdater'

type State = {
  reportLink: ReportLink
}

export default defineComponent({
  components: {
    LinkFormModal,
  },
  props: {
    value: {
      type: Object as PropType<ReportLink>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'failed'],
  setup(props, { emit }) {
    const {
      createReportLink,
    } = useReportLinkCreator()
    const create = async(): Promise<void> => await createReportLink(props.reportId, props.value)

    const {
      updateReportLink,
    } = useReportLinkUpdater()
    const update = async(): Promise<void> => await updateReportLink(props.reportId, props.value)

    const state: State = reactive({
      reportLink: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', { ...value })
        },
      }),
    })

    const onClose = () => emit('close')

    return {
      props,
      state,
      create,
      update,
      onClose,
    }
  },
})
