import { Link } from 'src/models/new/link'
import {
  LinkUpsertBaseRequestParameters,
  convertToLinkUpsertBaseRequestParameters,
} from 'src/models/api/Link/linkUpsertBaseRequestParameters'

export type LinkUpdateRequestParameters = LinkUpsertBaseRequestParameters & {
  id: number
}

export const convertToLinkUpdateRequestParameters = (link: Link): LinkUpdateRequestParameters => {
  return {
    ...convertToLinkUpsertBaseRequestParameters(link),
    id: link.id,
  }
}
