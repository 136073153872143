
import { defineComponent, onMounted, PropType, reactive } from 'vue'
import { omitString } from 'src/util/text_decorator'
import { ConditionalStatementWithContainerPosition } from 'src/models/new/conditionalStatement'
import conditionalStatementApi from 'src/apis/conditionalStatement'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts'

type State = {
  isReady: boolean
}

export default defineComponent({
  props: {
    conditionalStatement: {
      type: Object as PropType<ConditionalStatementWithContainerPosition<any>>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
  },
  emits: ['updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const state: State = reactive({
      isReady: false,
      selectedContainer: null,
      wordToSearch: '',
    })

    const close = (): void => { emit('close') }
    const execute = async(): Promise<void> => {
      try {
        await conditionalStatementApi.delete(props.reportId, props.componentId, props.conditionalStatement.id)
        emit('updated', '条件付き書式を削除しました。')
      } catch (err: any) {
        const errStatus = err.response.status
        if ([403, 404].includes(errStatus)) {
          const msg = '削除権限がありません。管理者にお問合せください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else {
          const msg = '条件付き書式の削除に失敗しました。管理者に連絡してください。'
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err)
        }
      }
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false
      state.isReady = true
    })

    return {
      props,
      state,
      omitString,
      execute,
      close,
    }
  },
})
