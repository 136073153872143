import { format } from 'date-fns'
import { SYSTEM_DATE_FORMAT } from './Datetime/format'

export type HeaderCell = {
  // levelはヘッダの表端から数えた深さを表し、1から始まる
  level: number
  // positionはヘッダ同じlevelの中で左上に近い方から附番される番号で、1から始まる
  position: number
  span: number
  value: string | null
}

export type HeaderStructure = {
  depth: number
  breadth: number
  data: HeaderCell[]
}

export type DataHeader = {
  layout: HeaderStructure
  computed: HeaderStructure
}

export type MatrixHeaders = {
  head: DataHeader
  side: DataHeader
}

export type DayHeaderOptions = {
  dates: Date[]
  format?: string
}

// 展開する値のパターンが増える場合、Partialに与える型を増やす
type Options = {
  dayHeader?: DayHeaderOptions
}

export const DATA_HEADER_KEY_WORD_DAY_HEADER = '(複数日並ぶ)'
const isDayHeaderKeyWord = (value: string | null): boolean => value === DATA_HEADER_KEY_WORD_DAY_HEADER

export const renderComputedHeaderFromLayout = (header: HeaderStructure, options: Options): HeaderStructure => {
  const computed = { depth: header.depth, breadth: 0, data: [] as HeaderCell[] }
  let positionShift = 0
  for (let position = 1; position <= header.breadth; position++) {
    const currentPositionCells: HeaderCell[] = header.data.filter(el => el.position === position)
    if (currentPositionCells.some(el => isDayHeaderKeyWord(el.value))) {
      if (!options.dayHeader?.dates.every(el => el.valueOf())) {
        console.error('failed to compute day header which needs proper dayHeader options.')
        positionShift -= 1
        continue
      }
      const dayCellLength = options.dayHeader.dates.length
      computed.breadth += dayCellLength
      positionShift += (dayCellLength - 1)
      currentPositionCells.forEach((cell: HeaderCell): void => {
        options?.dayHeader?.dates.forEach((date, index) => {
          if (isDayHeaderKeyWord(cell.value)) {
            const dateLabel = format(date, options.dayHeader!.format || SYSTEM_DATE_FORMAT)
            computed.data.push({
              ...cell,
              position: cell.position + index,
              value: dateLabel,
            })
          } else {
            computed.data.push({
              ...cell,
              position: cell.position + index + positionShift,
              span: cell.span + (index === 0 ? dayCellLength - 1 : 0),
              value: index === 0 ? cell.value : '',
            })
          }
        })
      })
    } else {
      computed.breadth++
      currentPositionCells.forEach((cell: HeaderCell): void => {
        computed.data.push({
          ...cell,
          position: cell.position + positionShift,
        })
      })
    }
  }
  return computed
}

export const constructMinimumEmptyMatrixHeaders = (dataMatrixWidth: number = 1, dataMatrixHeight: number = 1): MatrixHeaders => {
  return {
    head: {
      layout: {
        depth: 1,
        breadth: dataMatrixWidth,
        data: new Array(dataMatrixWidth).fill(null).map((_, index) => {
          return {
            level: 1,
            position: index + 1,
            span: index === 0 ? dataMatrixWidth : 0,
            value: null,
          }
        })
      },
      computed: { depth: 0, breadth: 0, data: [] },
    },
    side: {
      layout: {
        depth: 1,
        breadth: dataMatrixHeight,
        data: new Array(dataMatrixHeight).fill(null).map((_, index) => {
          return {
            level: 1,
            position: index + 1,
            span: index === 0 ? dataMatrixHeight : 0,
            value: null,
          }
        })
      },
      computed: { depth: 0, breadth: 0, data: [] },
    },
  }
}

export const isDataHeaderKeyWord = (value: string | null): boolean => isDayHeaderKeyWord(value)
