import { type InjectionKey, type Ref, inject, provide, ref } from 'vue';
import type { EmptyObject } from 'src/util/type_util';

type InjectionValue<T, U extends Record<string, unknown>> = {
  resources: Ref<T[]>;
  fetcher: (params: U) => Promise<T[]>;
  fetchParams?: U;
};

type Key<T, U extends Record<string, unknown>> = InjectionKey<InjectionValue<T, U>>;

type UseAsyncResourcesProviderArgs<T, U extends Record<string, unknown>> = {
  key: Key<T, U>;
  fetcher: InjectionValue<T, U>['fetcher'];
  fetchParams?: InjectionValue<T, U>['fetchParams'];
  onLoad?: () => unknown;
};
type UseAsyncResourcesProviderResult<T> = {
  resources: InjectionValue<T, Record<string, unknown>>['resources'];
};

type UseAsyncResourcesContextArgs<T, U extends Record<string, unknown>> = {
  key: Key<T, U>;
  fetcher: InjectionValue<T, U>['fetcher'];
  fetchParams?: InjectionValue<T, U>['fetchParams'];
};
type UseAsyncResourcesContextResult = EmptyObject;

type UseAsyncResourcesArgs<T> = {
  key: Key<T, Record<string, unknown>>;
};
type UseAsyncResourcesResult<T> = {
  resources: InjectionValue<T, Record<string, unknown>>['resources'];
};

type UseDynamicAsyncResourcesArgs<T, U extends Record<string, unknown>> = {
  key: Key<T, U>;
};
type UseDynamicAsyncResourcesResult<T, U extends Record<string, unknown>> = {
  resources: InjectionValue<T, Record<string, unknown>>['resources'];
  load: (params: U) => Promise<void>;
};

// useAsyncResourcesProvider: データの共有コンテキストを作るとともに、データロードも行う
export const useAsyncResourcesProvider = <T, U extends Record<string, unknown> = EmptyObject>({
  key,
  fetcher,
  fetchParams,
  onLoad = () => {},
}: UseAsyncResourcesProviderArgs<T, U>): UseAsyncResourcesProviderResult<T> => {
  const injectedResources = inject(key, null);
  if (injectedResources) {
    onLoad();
    return {
      resources: injectedResources.resources,
    };
  }

  const resources = ref<T[]>([]) as Ref<T[]>;
  fetcher(fetchParams ?? ({} as U)).then((fetchedResources) => {
    resources.value = fetchedResources;
    onLoad();
  });

  provide(key, {
    resources,
    fetcher,
    fetchParams,
  });

  return {
    resources,
  };
};

// useAsyncResourcesContext: データの共有コンテキストを作るのみで、データロードは行わない
export const useAsyncResourcesContext = <T, U extends Record<string, unknown> = EmptyObject>({
  key,
  fetcher,
  fetchParams,
}: UseAsyncResourcesContextArgs<T, U>): UseAsyncResourcesContextResult => {
  const injectedResources = inject(key, null);
  if (injectedResources) {
    return {};
  }

  const resources = ref<T[]>([]) as Ref<T[]>;

  provide(key, {
    resources,
    fetcher,
    fetchParams,
  });

  return {};
};

// useAsyncResources: Providerや他のコンポーネントでロードされたデータを使用し、自らはロードの必要が無い場合に使用する
export const useAsyncResources = <T>({ key }: UseAsyncResourcesArgs<T>): UseAsyncResourcesResult<T> => {
  // provideされていない場合にエラーを表示したいので、inject()のデフォルト値はnullにしている
  // 最終的にnullの場合はworkplacesRefが空行列になるが、inject()のデフォルト値を空行列にすると
  // provideされていないのかバックエンドから受け取ったデータが空だったのか区別がつかない
  const injection = inject(key, null);
  if (injection === null) {
    console.error(`${key.toString()} is not provided`);
    return {
      resources: ref<T[]>([]) as Ref<T[]>,
    };
  }

  const resources = injection.resources;

  return {
    resources,
  };
};

// useDynamicAsyncResources: Contextのみ提供されるケースや、ロードされたデータを上書きしたい場合に使用する
export const useDynamicAsyncResources = <T, U extends Record<string, unknown>>({
  key,
}: UseDynamicAsyncResourcesArgs<T, U>): UseDynamicAsyncResourcesResult<T, U> => {
  const injection = inject(key, null);
  if (injection === null) {
    console.error(`${key.toString()} is not provided`);
    return {
      resources: ref<T[]>([]) as Ref<T[]>,
      load: async () => {},
    };
  }

  const resources = injection.resources;
  const fetcher = injection.fetcher;

  const load = async (params: U) => {
    const fetchedResources = await fetcher(params);
    resources.value = fetchedResources;
  };

  return {
    resources,
    load,
  };
};
