
import Vue, { type PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import MetricsCardComponent from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsCardComponent.vue';
import CommentComponent from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/CommentComponent.vue';
import MetricsGraphComponent from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsGraphComponent.vue';
import MetricsTableComponent from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsTableComponent.vue';
import MetricsListComponent from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView/MetricsListComponent.vue';
import type { Report } from 'src/models/new/report';
import type { Section } from 'src/models/new/section';
import { type Component, layoutOfComponent } from 'src/models/new/component';
import type { CSSProperties } from 'vue/types/jsx';
import {
  applyConditionalStatementToMetricsCardComponentBackgroundStyle,
  isComponentTypeMetricsCard,
  isMetricsCardComponentCompleted,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import { isComponentTypeMetricsList } from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import { isComponentTypeComment } from 'src/models/new/Component/commentComponent';
import { isComponentTypeMetricsGraph } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent';
import { isComponentTypeMetricsTable } from 'src/models/new/Component/MetricsComponent/metricsTableComponent';

interface State {
  cardStyle: Pick<CSSProperties, 'background'>;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    cardStyle: computed(() => {
      const component = root.$props.component;
      // isMetricsCardComponentCompletedは閲覧画面においてisComponentTypeMetricsCardならば必ずtrueだが
      // 型の都合上チェックを入れている
      if (isComponentTypeMetricsCard(component) && isMetricsCardComponentCompleted(component)) {
        return applyConditionalStatementToMetricsCardComponentBackgroundStyle(component);
      }
      return {};
    }),
  });
  return state;
}

export default defineComponent({
  components: {
    MetricsTableComponent,
    MetricsListComponent,
    MetricsCardComponent,
    MetricsGraphComponent,
    CommentComponent,
  },
  props: {
    component: {
      type: Object as PropType<Component>,
      required: true,
    },
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
    report: {
      type: Object as PropType<Report>,
      required: true,
    },
    openEditValueModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    openEditCommentModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);

    return {
      props,
      state,
      isComponentTypeMetricsCard,
      isMetricsCardComponentCompleted,
      isComponentTypeComment,
      isComponentTypeMetricsGraph,
      isComponentTypeMetricsList,
      isComponentTypeMetricsTable,
      layoutOfComponent,
    };
  },
});
