import { Ref, ref, unref } from 'vue'

type SelectInputOption<T> = {
  value: T
  label: string
}

export const useEnumOptions = <Enum extends string>(map: Record<Enum, string>) => {
  const dictionary = ref<Record<Enum, string>>(map)
  // アサーションを与えないとvalueへの代入時に型が合わない
  // @see: https://github.com/vuejs/core/issues/2136#issuecomment-1290541387
  const options = ref<SelectInputOption<Enum>[]>([]) as Ref<SelectInputOption<Enum>[]>

  const buildOptions = (values?: Enum[]): SelectInputOption<Enum>[] => {
    const dictionaryValue = unref(dictionary)
    const enabledOptionValues = values ?? Object.keys(dictionaryValue) as Enum[]
    return enabledOptionValues.map((value) => {
      return {
        value,
        label: dictionaryValue[value] ?? '',
      }
    })
  }

  const filterOptions = (values?: Enum[]) => {
    options.value = buildOptions(values)
  }

  filterOptions()

  return {
    options,
    filterOptions,
  }
}
