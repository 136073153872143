import { isDecorationIconDecoration } from 'src/models/new/ConditionalStatement/Decorations/iconDecoration'
import { isDecorationTextDecoration } from 'src/models/new/ConditionalStatement/Decorations/textDecoration'
import { ConditionalStatement, ConditionalStatementWithContainerPosition, Decoration, isConditionalStatementBaseConstant, isConditionalStatementBaseMetrics, isConditionalStatementBaseReference, isConditionalStatementBaseSelf, isConditionalStatementComparatorIsNull, isConditionalStatementTargetBackground, isConditionalStatementTargetGraph, isConditionalStatementTargetIcon, isConditionalStatementTargetText, isConditionalStatementThresholdConstant, isConditionalStatementThresholdMetrics, isConditionalStatementThresholdReference } from 'src/models/new/conditionalStatement'
import { Metrics } from 'src/models/new/metrics'
import { Comparator } from 'src/util/comparator'

const MAIN_METRICS = 'main_metrics'
const CONST = 'const'
const SUB_METRICS = 'sub_metrics'
const OTHER_METRICS = 'other_metrics'

type BaseType = typeof MAIN_METRICS | typeof SUB_METRICS | typeof CONST | typeof OTHER_METRICS
type ThresholdType = typeof CONST | typeof SUB_METRICS | typeof OTHER_METRICS | null

type DecorationSettingsParameters = {
  color: string
  font_weight?: string
  icon?: string
}
type DecorationParameters = {
  version: number
  target: 'icon' | 'text' | 'background' | 'graph'
  settings: DecorationSettingsParameters
}
// priorityはAPI側で自動的に設定する
// primary_position_id, secondary_position_idは作成時のみ送信し、更新時は送信しない
export type ConditionalStatementUpsertBaseRequestParameters = {
  base_type: BaseType
  base_metrics: {
    id: number
  } | null
  base_const: number | null
  comparator: Comparator
  threshold_type: ThresholdType
  threshold_metrics: {
    id: number
  } | null
  threshold_const: number | null
  decoration: DecorationParameters
}

export const convertToConditionalStatementUpsertBaseRequestParameters = (
  statement: ConditionalStatementWithContainerPosition<Decoration>
): ConditionalStatementUpsertBaseRequestParameters => {
  const getBaseType = (statement: ConditionalStatement<any>): BaseType => {
    if (isConditionalStatementBaseSelf(statement)) return 'main_metrics'
    if (isConditionalStatementBaseReference(statement)) return 'sub_metrics'
    if (isConditionalStatementBaseConstant(statement)) return 'const'
    if (isConditionalStatementBaseMetrics(statement)) return 'other_metrics'
    throw Error('Invalid base type.')
  }
  const getThresholdType = (statement: ConditionalStatement<any>): ThresholdType => {
    if (isConditionalStatementComparatorIsNull(statement)) return null

    if (isConditionalStatementThresholdReference(statement)) return 'sub_metrics'
    if (isConditionalStatementThresholdConstant(statement)) return 'const'
    if (isConditionalStatementThresholdMetrics(statement)) return 'other_metrics'
    throw Error('Invalid threshold type.')
  }

  // 取得するときメトリクスの参照値は_metricsで取得するが、保存するときは_valueにidを代入するようになっている
  const getBaseMetrics = (statement: ConditionalStatement<any>): { id: number } | null => {
    if (isConditionalStatementBaseMetrics(statement)) {
      return { id: (statement.base as Metrics).id }
    }
    return null
  }
  const getBaseConst = (statement: ConditionalStatement<any>): number | null => {
    if (isConditionalStatementBaseConstant(statement)) return statement.base as number
    return null
  }
  const getThresholdMetrics = (statement: ConditionalStatement<any>): { id: number } | null => {
    if (isConditionalStatementComparatorIsNull(statement)) return null

    if (isConditionalStatementThresholdMetrics(statement)) {
      return { id: (statement.threshold as Metrics).id }
    }
    return null
  }
  const getThresholdConst = (statement: ConditionalStatement<any>): number | null => {
    if (isConditionalStatementComparatorIsNull(statement)) return null

    if (isConditionalStatementThresholdConstant(statement)) return statement.threshold as number
    return null
  }

  const decorationSettings: DecorationSettingsParameters = {
    color: statement.decoration.settings.color,
  }
  if (isDecorationTextDecoration(statement.decoration)) {
    decorationSettings.font_weight = statement.decoration.settings.fontWeight
  }
  if (isDecorationIconDecoration(statement.decoration)) {
    decorationSettings.icon = statement.decoration.settings.icon
  }

  const decoration = {
    version: 1,
    target: statement.decoration.target,
    settings: decorationSettings
  }

  return {
    comparator: statement.comparator,
    base_type: getBaseType(statement),
    base_metrics: getBaseMetrics(statement),
    base_const: getBaseConst(statement),
    threshold_type: getThresholdType(statement),
    threshold_metrics: getThresholdMetrics(statement),
    threshold_const: getThresholdConst(statement),
    decoration,
  }
}
