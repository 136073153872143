import { TIME_SPAN_DAILY } from 'src/business/timeSpan'
import { Metrics, MetricsType, internal as metricsInternal } from 'src/models/new/metrics'
import { DayOfWeek } from 'src/util/week'

const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'
const YEAR = 'year'

const DOW = 'dow'

const DIRECT_INPUT = metricsInternal.DIRECT_INPUT
const LOGIMETER = metricsInternal.LOGIMETER
const LOGIBOARD = metricsInternal.LOGIBOARD
const CALC = metricsInternal.CALC
const SUMMARY = metricsInternal.SUMMARY
const CROSS = metricsInternal.CROSS
const EXT_CROSS = metricsInternal.EXT_CROSS
const REFERENCE = metricsInternal.REFERENCE

export type OffsetPeriodUnit = typeof DAY | typeof WEEK | typeof MONTH | typeof YEAR
/**
 * Almost the same as PeriodWithValue. The difference with PeriodWithValue is
 * the values of unit values. Ex. "day" and "days"
 */
export type ReferenceMetricsOffsetPeriod = {
  unit: OffsetPeriodUnit
  value: number
}

export const isReferenceMetricsOffsetPeriodDay = (period: ReferenceMetricsOffsetPeriod): boolean => period.unit === DAY
export const isReferenceMetricsOffsetPeriodWeek = (period: ReferenceMetricsOffsetPeriod): boolean => period.unit === WEEK
export const isReferenceMetricsOffsetPeriodMonth = (period: ReferenceMetricsOffsetPeriod): boolean => period.unit === MONTH
export const isReferenceMetricsOffsetPeriodYear = (period: ReferenceMetricsOffsetPeriod): boolean => period.unit === YEAR

export type ReferenceMetrics = Metrics & {
  targetMetricsId: number
  offsetPeriod: ReferenceMetricsOffsetPeriod
  referencePointMonth: number | null
  referencePointDay: number | null
  referencePointDow: DayOfWeek | null
}

export const isMetricsReferenceMetrics = (metrics: Metrics): metrics is ReferenceMetrics => {
  return metrics.metricsType === REFERENCE
}

export const constructEmptyReferenceMetrics = (): ReferenceMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: REFERENCE,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    targetMetricsId: 0,
    offsetPeriod: {
      unit: DAY,
      value: 1,
    },
    referencePointMonth: null,
    referencePointDay: null,
    referencePointDow: null,
    updatedAt: null,
  }
}

const REFERENCE_METRICS_REFERABLE_METRICS_TYPES = [
  DIRECT_INPUT,
  LOGIMETER,
  LOGIBOARD,
  CALC,
  SUMMARY,
  CROSS,
  EXT_CROSS
]

export const getReferenceMetricsReferableMetricsTypes = (): MetricsType[] => REFERENCE_METRICS_REFERABLE_METRICS_TYPES

export const internal = {
  DAY,
  WEEK,
  MONTH,
  YEAR,
  DOW,
} as const
