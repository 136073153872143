import { type InjectionKey, type Ref, inject, provide, ref } from 'vue';
import type { EmptyObject } from 'src/util/type_util';
import type { SlicedResourceFetchParams } from './types/slicedResourceFetchParams';

type InjectionValue<T, U extends Record<string, unknown>> = {
  resources: Ref<T[]>;
  lastLoadedSliceNumber: Ref<number>;
  fetcher: (params: SlicedResourceFetchParams<U>) => Promise<T[]>;
  fetchParams?: U;
};

type Key<T, U extends Record<string, unknown>> = InjectionKey<InjectionValue<T, U>>;

type UseSlicedAsyncResourcesProviderArgs<T, U extends Record<string, unknown>> = {
  key: Key<T, U>;
  fetcher: InjectionValue<T, U>['fetcher'];
  fetchParams?: InjectionValue<T, U>['fetchParams'];
  onLoad?: () => unknown;
};
type UseSlicedAsyncResourcesProviderResult<T> = {
  resources: InjectionValue<T, Record<string, unknown>>['resources'];
};

type UseSlicedAsyncResourcesContextArgs<T, U extends Record<string, unknown>> = {
  key: InjectionKey<InjectionValue<T, U>>;
  fetcher: InjectionValue<T, U>['fetcher'];
  fetchParams?: InjectionValue<T, U>['fetchParams'];
};
type UseSlicedAsyncResourcesContext = EmptyObject;

type UseSlicedAsyncResourcesArgs<T, U extends Record<string, unknown>> = {
  key: InjectionKey<InjectionValue<T, U>>;
};
type UseSlicedAsyncResources<T> = {
  resources: InjectionValue<T, Record<string, unknown>>['resources'];
  loadNextSlice: () => Promise<void>;
};

type UseDynamicSlicedAsyncResourcesArgs<T, U extends Record<string, unknown>> = {
  key: InjectionKey<InjectionValue<T, U>>;
};
type UseDynamicSlicedAsyncResources<T, U extends Record<string, unknown>> = {
  resources: InjectionValue<T, U>['resources'];
  load: (params: U) => Promise<void>;
  loadNextSlice: () => Promise<void>;
};

// useSlicedAsyncResourcesProvider: データの共有コンテキストを作るとともに、データロードも行う
export const useSlicedAsyncResourcesProvider = <T, U extends Record<string, unknown> = EmptyObject>({
  key,
  fetcher,
  fetchParams,
  onLoad = () => {},
}: UseSlicedAsyncResourcesProviderArgs<T, U>): UseSlicedAsyncResourcesProviderResult<T> => {
  const injectedResources = inject(key, null);
  if (injectedResources) {
    onLoad();
    return {
      resources: injectedResources.resources,
    };
  }

  const resources = ref<T[]>([]) as Ref<T[]>;
  const lastLoadedSliceNumber = ref(0);
  fetcher({ ...(fetchParams ?? ({} as U)), page: 1 }).then((fetchedResources) => {
    resources.value = fetchedResources;
    lastLoadedSliceNumber.value = 1;
    onLoad();
  });

  provide(key, {
    resources,
    lastLoadedSliceNumber,
    fetcher,
    fetchParams,
  });

  return {
    resources,
  };
};

// useSlicedAsyncResourcesContext: データの共有コンテキストを作るのみで、データロードは行わない
// 引数のfetchParamsは利用側でuseDynamicAsyncResourcesを使う場合は省略してよい
export const useSlicedAsyncResourcesContext = <T, U extends Record<string, unknown> = EmptyObject>({
  key,
  fetcher,
  fetchParams,
}: UseSlicedAsyncResourcesContextArgs<T, U>): UseSlicedAsyncResourcesContext => {
  const injectedResources = inject(key, null);
  if (injectedResources) {
    return {};
  }

  const resources = ref<T[]>([]) as Ref<T[]>;
  const lastLoadedSliceNumber = ref(0);

  provide(key, {
    resources,
    lastLoadedSliceNumber,
    fetcher,
    fetchParams,
  });

  return {};
};

// useSlicedAsyncResources: Providerや他のコンポーネントでロードされたデータを使用し、自らは初回ロードの必要が無い場合に使用する
export const useSlicedAsyncResources = <T, U extends Record<string, unknown> = EmptyObject>({
  key,
}: UseSlicedAsyncResourcesArgs<T, U>): UseSlicedAsyncResources<T> => {
  // provideされていない場合にエラーを表示したいので、inject()のデフォルト値はnullにしている
  // 最終的にnullの場合はworkplacesRefが空行列になるが、inject()のデフォルト値を空行列にすると
  // provideされていないのかバックエンドから受け取ったデータが空だったのか区別がつかない
  const injection = inject(key, null);
  if (injection === null) {
    console.error(`${key.toString()} is not provided`);
    return {
      resources: ref<T[]>([]) as Ref<T[]>,
      loadNextSlice: async () => {},
    };
  }

  const resources = injection.resources;
  const lastLoadedSliceNumber = injection.lastLoadedSliceNumber;
  const fetcher = injection.fetcher;
  const fetchParams = injection.fetchParams;

  const loadNextSlice = async () => {
    const fetchedResources = await fetcher({
      ...(fetchParams ?? ({} as U)),
      page: lastLoadedSliceNumber.value + 1,
    });
    resources.value = [...resources.value, ...fetchedResources];
    lastLoadedSliceNumber.value += 1;
  };

  return {
    resources,
    loadNextSlice,
  };
};

// useDynamicSlicedAsyncResources: Contextのみ提供されるケースや、ロードされたデータを上書きしたい場合に使用する
// useSlicedAsyncResourcesとの違いはパラメータを取ってデータを取得するloadメソッドが提供されていること
export const useDynamicSlicedAsyncResources = <T, U extends Record<string, unknown>>({
  key,
}: UseDynamicSlicedAsyncResourcesArgs<T, U>): UseDynamicSlicedAsyncResources<T, U> => {
  const injection = inject(key, null);
  if (injection === null) {
    console.error(`${key.toString()} is not provided`);
    return {
      resources: ref<T[]>([]) as Ref<T[]>,
      load: async () => {},
      loadNextSlice: async () => {},
    };
  }

  const resources = injection.resources;
  const lastLoadedSliceNumber = injection.lastLoadedSliceNumber;
  const fetcher = injection.fetcher;

  const currentFetchParams = ref<U | null>(null) as Ref<U | null>;

  const load = async (params: U) => {
    const fetchedResources = await fetcher({ ...params, page: 1 });
    resources.value = fetchedResources;
    lastLoadedSliceNumber.value = 1;
    currentFetchParams.value = params;
  };

  const loadNextSlice = async () => {
    if (currentFetchParams.value === null) {
      return;
    }
    const fetchedResources = await fetcher({
      ...currentFetchParams.value,
      page: lastLoadedSliceNumber.value + 1,
    });
    resources.value = [...resources.value, ...fetchedResources];
    lastLoadedSliceNumber.value += 1;
  };

  return {
    resources,
    load,
    loadNextSlice,
  };
};
