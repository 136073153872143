import { TIME_SPAN_LOCAL_WORDS, TimeSpan } from 'src/business/timeSpan'
import { useEnumOptions } from 'src/composables/useEnumOptions'

export const useTimeSpanOptions = () => {
  const {
    options,
    filterOptions,
  } = useEnumOptions<TimeSpan>(TIME_SPAN_LOCAL_WORDS)

  return {
    options,
    filterOptions,
  }
}
