import { wrappedMapGetters } from 'src/hooks/storeHook'
import { getCurrentInstance } from 'vue'

export const usePageName = () => {
  const vue = getCurrentInstance()!.proxy
  const { pageName } = wrappedMapGetters(vue.$store, 'displayPageName', [
    'pageName',
  ])
  const pageNameRef = pageName

  const setPageName = (pageName: string) => {
    if (!pageName) return
    vue.$store.dispatch('displayPageName/setPageName', { pageName })
  }

  return {
    pageNameRef,
    setPageName,
  }
}
