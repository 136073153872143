import axios from 'axios';
import { Metrics } from 'src/models/new/metrics';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import {
  convertFromMetricsAccessGroupResponseData,
  MetricsAccessGroupResponseData,
} from 'src/models/api/AccessGroup/MetricsAccessGroup/metricsAccessGroupResponseData';
import { convertToMetricsAccessGroupCreateOrUpdateResponse } from 'src/models/api/AccessGroup/MetricsAccessGroup/metricsAccessGroupCreateOrUpdateRequestParameters';
import { MetricsAccessGroupIndexRequestParameters } from 'src/models/api/AccessGroup/MetricsAccessGroup/metricsAccessGroupIndexRequestParameters';
import { buildBackendUrl } from 'src/apis/util/url';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (): string => '/masters/metrics_access_groups';

export default {
  async index(data: MetricsAccessGroupIndexRequestParameters): Promise<{ result: MetricsAccessGroup[] }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<MultiDataResponse<MetricsAccessGroupResponseData>>(url, {
      params: data,
    });
    return {
      result: resData.result.map(convertFromMetricsAccessGroupResponseData),
    };
  },
  async byMetrics(metrics: Metrics): Promise<MetricsAccessGroup[]> {
    const url = buildBackendUrl(buildBasePath(), 'by_metrics', metrics.id);
    const { data: resData } = await axios.get(url);
    return resData.map(convertFromMetricsAccessGroupResponseData);
  },
  async create(accessGroup: MetricsAccessGroup): Promise<{}> {
    const url = buildBackendUrl(buildBasePath());
    await axios.post(url, convertToMetricsAccessGroupCreateOrUpdateResponse(accessGroup));
    return {};
  },
  async update(id: number, accessGroup: MetricsAccessGroup): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), id);
    await axios.put(url, convertToMetricsAccessGroupCreateOrUpdateResponse(accessGroup));
    return {};
  },
  async destroy(id: number): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), id);
    await axios.delete(url);
    return {};
  },
};
