import { LogiCoredataWorkplace } from 'src/models/new/logiCoredataWorkplace'
import { useLogiCoredataWorkplaces } from 'src/composables/asyncResources/useLogiCoredataWorkplaces'
import { useResourceOptions } from 'src/composables/useResourceOptions'

export const useLogiCoredataWorkplaceOptions = () => {
  const {
    options,
    setResources,
    filterOptions,
  } = useResourceOptions<LogiCoredataWorkplace>('name')

  const {
    logiCoredataWorkplacesRef,
  } = useLogiCoredataWorkplaces()

  setResources(logiCoredataWorkplacesRef.value)

  return {
    options,
    filterOptions,
  }
}
