import { CSSProperties } from 'vue/types/jsx'
import { Decoration } from '../../conditionalStatement'

export const DECORATION_TARGET_BACKGROUND = 'background'

export type BackgroundDecoration = {
  target: typeof DECORATION_TARGET_BACKGROUND
  settings: {
    color: string
  }
}

export const isDecorationBackgroundDecoration = (decoration: Decoration): decoration is BackgroundDecoration => {
  return decoration.target === DECORATION_TARGET_BACKGROUND
}

export const styleFromBackgroundDecoration = (decoration: BackgroundDecoration): Pick<CSSProperties, 'backgroundColor'> => {
  return {
    backgroundColor: `#${decoration.settings.color}a6`,
  }
}
