import axios from 'axios';
import { LogiCoredataBudgetGroupIndexRequestParameters } from 'src/models/api/LogiCoredataBudgetGroup/logiCoredataBudgetGroupIndexRequestParameters';
import {
  LogiCoredataBudgetGroupResponseData,
  convertFromLogiCoredataBudgetGroupResponseData,
} from 'src/models/api/LogiCoredataBudgetGroup/logiCoredataBudgetGroupResponseData';
import { LogiCoredataBudgetGroup } from 'src/models/new/logiCoredataBudgetGroup';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/budget_groups';

export default {
  async index(data: LogiCoredataBudgetGroupIndexRequestParameters = {}): Promise<LogiCoredataBudgetGroup[]> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url, { params: data });
    return (resData as LogiCoredataBudgetGroupResponseData[]).map(convertFromLogiCoredataBudgetGroupResponseData);
  },
};
