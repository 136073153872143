import Chart from 'chart.js';
import { ExtendedChartData, ExtendedChartDataSets } from 'src/util/Chart/shared';
import { deepClone } from 'src/util/object';

// In official definition, ChartData.labels is:
// Array<string | string[] | number | number[] | Date | Date[] | Moment | Moment[]> | undefined
// but almost all cases, only string[] or number[] is suitable for labels.
export type ChartDataLabel = string | number;
export type ChartPlugin = Chart.PluginServiceRegistrationOptions & Chart.PluginServiceGlobalRegistration;
export type ChartScaleLabel = {
  axisId: string;
  label: string;
};

export const CHART_BASE_COLOR = '#9f9f9f';

const normalizeAlpha = (alpha: number) => Math.max(Math.min(Number(alpha), 1), 0);

export const hexToRGB = (hex: string, alpha: number | null = null) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r},${g},${b}, ${normalizeAlpha(alpha)})` : `rgb(${r},${g},${b})`;
};

export const buildChartData = (labels: ChartDataLabel[], datasets: ExtendedChartDataSets[]): ExtendedChartData => {
  return {
    labels: [...labels],
    datasets: datasets.map((el) => deepClone(el)),
  };
};

export const isIncludingDatalabelsPlugin = (plugins: ChartPlugin[]): boolean => {
  return plugins.some((el) => el.id === 'datalabels');
};

export const isIncludingAnnotationPlugin = (_: ChartPlugin[]): boolean => {
  // 困ったことAnnotationPluginにはIDが存在せず、与えられたプラグインがAnnotationsPluginかどうかを判定する方法がない
  return true;
};

type AxisDirection = 'x' | 'y';

// 軸ラベルはデータに対して動的になり得るが、一方でdatasetsからのコールバックで値を生成することが難しい
// その為optionsに含めない形で渡すことのできるインターフェースとした
export const attachScaleLabels = (
  scaleLabels: ChartScaleLabel[],
  options: Chart.ChartOptions,
  axisDirection: AxisDirection,
): Chart.ChartOptions => {
  const attachedOptions = deepClone(options);
  scaleLabels.forEach((scaleLabel) => {
    const targetAxis =
      axisDirection === 'x'
        ? attachedOptions.scales?.xAxes?.find((axis) => axis.id === scaleLabel.axisId)
        : attachedOptions.scales?.yAxes?.find((axis) => axis.id === scaleLabel.axisId);

    if (!targetAxis) {
      return;
    }

    targetAxis.scaleLabel = {
      display: true,
      labelString: scaleLabel.label,
    };
  });
  return attachedOptions;
};

export const replaceStagedDataset = (stage: ExtendedChartDataSets, commit: ExtendedChartDataSets) => {
  // stage側に存在しない要素がcommit側に含まれていても無視する
  const keys = Object.keys(stage) as (keyof ExtendedChartDataSets)[];
  keys.forEach((key) => {
    // _meta
    // 型上は存在しないが実際には存在し、書き変えてしまうとVue Chart.jsのウォッチャーが正常に機能しなくなる
    if (key === ('_meta' as keyof ExtendedChartDataSets)) {
      return;
    }

    if (Array.isArray(stage[key]) && Array.isArray(commit[key])) {
      while ((stage[key] as any[]).length > 0) {
        (stage[key] as any[]).pop();
      }
      // commit側の配列長が合っていない場合はエラーが生じる
      (stage[key] as any[]).push(...(commit[key] as any[]));
    } else {
      // 次のエラーが出てしまうため、型チェックを無効化する
      // Expression produces a union type that is too complex to represent.
      // 型としては適切だが、ExtendedChartDataSetsの元であるChart.ChartDataSetsの型があまりに複雑なため解析に失敗してしまう様子
      // @ts-ignore
      stage[key] = commit[key];
    }
  });
};

// イベントからコールバックで受け取るデータ型、実際にはもっと複雑だが必要なものだけ型定義した
// 参考: https://qiita.com/sato_ryu/items/b83eac5c2e1efe29507d
// 公式には型定義やドキュメントが存在しないが、ハック記事を見つけることができる
export type ChartElement = {
  _chart: {
    chart: Chart;
  };
  _datasetIndex: number;
  _index: number;
};

export type RelativePixelPosition = {
  x: number;
  y: number;
};

export const wrapOnChartClick = (
  callback: (event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void,
): ((event?: MouseEvent, activeElements?: Array<ChartElement>) => void) => {
  const onClick = (event?: MouseEvent, activeElements?: Array<ChartElement>) => {
    // activeElementsは常に配列で返ってくるが、DOM 内で現在フォーカスを持っている要素であり必ず1つ存在する
    // @see: https://developer.mozilla.org/ja/docs/Web/API/Document/activeElement
    const chartElement = activeElements?.[0] ?? null;
    if (!event) {
      return;
    }
    const point: RelativePixelPosition = chartElement
      ? Chart.helpers.getRelativePosition(event, chartElement._chart.chart)
      : null;
    callback(event, chartElement, point);
  };
  return onClick;
};
