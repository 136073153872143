import { ChartItem, getValueOnGraphFromGraphItem } from 'src/util/Chart/custom/shared';
import { HorizontalBarChartOptions } from '../horizontalBarChart';
import { ChartScaleLabel } from 'src/components/UIComponents/Charts/shared';

export type CustomHorizontalBarGraphItem = ChartItem;

export type CustomHorizontalBarGraphOptions = HorizontalBarChartOptions & {
  scales: {
    yAxes: [
      {
        ticks: {
          padding: number;
        };
      },
    ];
  };
};

export const getOptionForHorizontalBarGraph = (): CustomHorizontalBarGraphOptions => {
  return {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 10,
          },
        },
      ],
    },
  };
};

export const getCustomScaleLabelsForHorizontalBarGraph = (bottomUnit: string | null): ChartScaleLabel[] => {
  return [
    {
      axisId: 'x-axis-1',
      label: bottomUnit ? `(${bottomUnit})` : '',
    },
  ];
};

const BAR_PERCENTAGE = 0.8;
const CATEGORY_PERCENTAGE = 0.6;

export type CustomHorizontalBarChartDataDataset = {
  data: number[];
  actualData: Array<number | null>;
  borderColor: string[] | string;
  backgroundColor: string[] | string;
  barPercentage: number;
  categoryPercentage: number;
};

export const datasetFromHorizontalBarItems = (
  horizontalBarItems: CustomHorizontalBarGraphItem[],
): CustomHorizontalBarChartDataDataset => {
  const chartValues = horizontalBarItems.map((el) => getValueOnGraphFromGraphItem(el));
  // chartValuesはhorizontalBarItemsの各要素と対応する値を持つ配列であるため、horizontalBarItems[index]は必ず存在する
  const originalValues = chartValues.map((value, index) => (horizontalBarItems[index]!.value !== null ? value : null));
  const colors = horizontalBarItems.map((el) => `#${el.color.replaceAll('#', '')}`);
  return {
    data: chartValues,
    actualData: originalValues,
    borderColor: colors,
    backgroundColor: colors,
    barPercentage: BAR_PERCENTAGE,
    categoryPercentage: CATEGORY_PERCENTAGE,
  };
};

export const legendLabelsFromHorizontalBarItems = (horizontalBarItems: CustomHorizontalBarGraphItem[]): string[] => {
  return horizontalBarItems.map((el) => el.label);
};
