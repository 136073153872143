import { RecordId } from 'src/util/recordId'

export type ReportResponseCopyData = {
  id: RecordId
}

// idしか存在しないのでスネークケースからの変換が実質的には発生しないが
// この書き方に揃えていくため敢えて何もしない変換関数だが定義する
export const convertFromReportCopyResponseData = (data: ReportResponseCopyData): { id: RecordId } => {
  return data
}
