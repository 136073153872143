import axios from 'axios';
import { convertToSectionCreateRequestParameters } from 'src/models/api/Report/Section/sectionCreateRequestParameters';
import { StringOrNumber } from 'src/models/common';
import { convertToSectionUpdateResponse } from 'src/models/api/Report/Section/sectionUpdateRequestParameters';
import { convertFromSectionCreateResponseData } from 'src/models/api/Report/Section/sectionCreateResponseData';
import { RecordId } from 'src/util/recordId';
import { Section } from 'src/models/new/section';
import { buildBackendUrl } from 'src/apis/util/url';

// FIXME: stringは本来必要なく、numberを要求すべき
const buildBasePath = (reportId: StringOrNumber): string => `/masters/reports/${reportId}/sections`;

export default {
  async create(reportId: StringOrNumber, params: Section): Promise<{ id: RecordId }> {
    const url = buildBackendUrl(buildBasePath(reportId));
    const convertedParams = convertToSectionCreateRequestParameters(params);
    const { data: resData } = await axios.post(url, convertedParams);
    return convertFromSectionCreateResponseData(resData);
  },
  async update(reportId: StringOrNumber, params: Section): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), params.id);
    const convertedParams = convertToSectionUpdateResponse(params);
    await axios.put(url, convertedParams);
    return {};
  },
  async delete(reportId: StringOrNumber, id: StringOrNumber): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), id);
    await axios.delete(url);
    return {};
  },
};
