
import { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import Encoding from 'encoding-japanese';
import masterCsvJobStatusApi from 'src/apis/masterCsvJobStatus';
import { MasterCsvJobStatus, getExecuteStatusColor } from 'src/models/masterCsvJobStatus';
import { notifyError1 } from 'src/hooks/notificationHook';
import { formatDate } from 'src/util/datetime';
import { ERROR_REASON_FILE_EXPIRED } from 'src/consts';

type MasterCsvJobStatusEx = MasterCsvJobStatus & {
  startTimeDisp: string;
  endTimeDisp: string;
  showFullMessage: boolean;
};

interface State {
  listItems: MasterCsvJobStatusEx[];
  isEmpty: boolean;
}

export default defineComponent({
  props: {
    masterCsvJobStatuses: {
      type: Array as PropType<MasterCsvJobStatus[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;

    const state: State = reactive({
      listItems: computed(() => props.masterCsvJobStatuses.map(extendMasterCsvJobStatus)),
      isEmpty: computed(() => state.listItems.length === 0),
    });

    const extendMasterCsvJobStatus = (elem: MasterCsvJobStatus): MasterCsvJobStatusEx => {
      return {
        ...elem,
        startTimeDisp: formatDate(elem.startTime, 'yyyy/MM/dd HH:mm:ss'),
        endTimeDisp: elem.endTime ? formatDate(elem.endTime, 'yyyy/MM/dd HH:mm:ss') : '',
        showFullMessage: false,
      };
    };

    function downloadCsv(item: MasterCsvJobStatus): void {
      masterCsvJobStatusApi
        .download(item.id)
        .then((response) => {
          const data = response;
          const convertedToUnicode = Encoding.stringToCode(data);
          const convertedToSjis = Encoding.convert(convertedToUnicode, 'SJIS', 'UNICODE');
          const convertedToBinary = new Uint8Array(convertedToSjis);
          const blob = new Blob([convertedToBinary], { type: 'text/csv' });
          const filename = item.fileName;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch((err: any) => {
          const errStatus = err.response?.status;
          const errRes = err.response?.data || {};

          if (errStatus === 400 && errRes.reason === ERROR_REASON_FILE_EXPIRED) {
            const msg = '指定されたファイルは既に無効です。';
            notifyError1(root, msg, { timeout: 10 * 1000 });
            emit('getList');
          } else {
            const msg = 'ダウンロードに失敗しました。再度お試しください。';
            notifyError1(root, msg, { timeout: 10 * 1000 });
          }
        });
    }

    function toggleShowFullMessage(item: MasterCsvJobStatusEx): void {
      item.showFullMessage = !item.showFullMessage;
    }

    return {
      props,
      state,
      getExecuteStatusColor,
      downloadCsv,
      toggleShowFullMessage,
    };
  },
});
