
import { defineComponent, PropType } from 'vue'
import { ReportLink } from 'src/models/new/Link/reportLink'
import LinkDeleteConfirmModal from 'src/views/Dashboard/Settings/Reports/shared/components/LinkDeleteConfirmModal/LinkDeleteConfirmModal.vue'
import { useReportLinkDeleter } from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkDeleteConfirmModal/composables/useReportLinkDeleter'

export default defineComponent({
  components: {
    LinkDeleteConfirmModal,
  },
  props: {
    reportLink: {
      type: Object as PropType<ReportLink>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
  },
  emits: ['updated', 'close', 'failed'],
  setup(props, { emit }) {
    const {
      deleteReportLink,
    } = useReportLinkDeleter()

    const execute = async(): Promise<void> => {
      await deleteReportLink(props.reportId, props.reportLink)
    }

    const onClose = () => emit('close')

    return {
      props,
      execute,
      onClose,
    }
  },
})
