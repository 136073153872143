import { convertScaling, Scaling } from 'src/business/scaling';

function charLength(char: string): number {
  return !/[^ -~｡-ﾟ]+$/g.test(char) ? 1 : 2;
}

const decideMaxSecondHalfLength = (maxLength: number, ellipsisConsideredLength: number): number => {
  if (maxLength >= 12) {
    return 4;
  } else if (maxLength >= ellipsisConsideredLength + 4) {
    return 2;
  } else {
    return 0;
  }
};

export function strLength(str: string): number {
  let length = 0;
  str.split('').forEach((char) => {
    length += charLength(char);
  });
  return length;
}

/**
 * 第2引数の値は半角換算での長さを表す
 * 例えば文字列"第1週目"は2+1+2+2=7である
 */
export function omitString(string: string, maxLength = 12) {
  if (strLength(string) <= maxLength) {
    return string;
  }

  const ellipsis = '...';
  const ellipsisConsideredLength = 2;
  const maxSecondHalfLength = decideMaxSecondHalfLength(maxLength, ellipsisConsideredLength);
  const maxFirstHalfLength = maxLength - ellipsisConsideredLength - maxSecondHalfLength;

  // 最初と最後は全角3文字まで表示
  const charAry = string.split('');
  let startStr = '';
  let endStr = '';
  let tmpLength = 0;
  for (let i = 0; i < charAry.length; i++) {
    tmpLength += charLength(charAry[i]!);
    if (tmpLength > maxFirstHalfLength) {
      break;
    }

    startStr += charAry[i];
  }
  tmpLength = 0;
  for (let i = charAry.length - 1; i >= 0; i--) {
    tmpLength += charLength(charAry[i]!);
    if (tmpLength > maxSecondHalfLength) {
      break;
    }

    endStr = charAry[i] + endStr;
  }

  return `${startStr}${ellipsis}${endStr}`;
}

function regExpEscape(str: string) {
  return str.replace(/([.*+\-?^${}()|[\]\\])/g, '\\$1');
}

export type HalvedStringsParts = {
  firstHalf: string;
  secondHalf: string;
};

export const formatValue = (numeric: number | string, decimalPlaces: number, scaling: Scaling = 'one'): string => {
  return convertScaling(Number(numeric), scaling).toLocaleString('ja-JP', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const lastNLengthOf = (string: string, length: number): string => {
  const charAry = string.split('');
  let lastNLengthString = '';
  let tmpLength = 0;
  for (let i = charAry.length - 1; i >= 0; i--) {
    tmpLength += charLength(charAry[i]!);
    if (tmpLength > length) {
      break;
    }

    lastNLengthString = charAry[i] + lastNLengthString;
  }
  return lastNLengthString;
};

export const formerRestOf = (original: string, removed: string): string => {
  const reg = new RegExp(`${regExpEscape(removed)}$`);
  return original.replace(reg, '');
};
