import { MetricsGraphComponentConditionalStatement } from 'src/models/new/ConditionalStatement/metricsGraphComponentConditionalStatement';
import { MetricsGraphComponent, MetricsGraphReferenceForBorderLine } from '../metricsGraphComponent';
import { Metrics } from 'src/models/new/metrics';
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementThresholdReference,
  thresholdValueFromConditionalStatement,
} from 'src/models/new/conditionalStatement';
import { compareWithComparator } from 'src/util/comparator';
import {
  GraphLayout,
  GraphLayoutScalePosition,
  GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
  GRAPH_LAYOUT_SCALE_POSITION_LEFT,
  switchGraphLayoutPlotTypes,
} from 'src/business/graphLayout';
import { Component, internal } from 'src/models/new/component';
import { hashedColorFromGraphDecoration } from 'src/models/new/ConditionalStatement/Decorations/graphDecoration';
import { TimeSpan, isTimeSpanLarger } from 'src/business/timeSpan';

const METRICS_GRAPH_GROUPED_GRAPH = internal.METRICS_GRAPH_GROUPED_GRAPH;

export type MetricsGroupedGraphPlot = {
  group: number;
  label: string | null;
  color: string;
  metrics: Metrics;
  conditionalStatements: MetricsGraphComponentConditionalStatement[];
};
export type MetricsGroupedGraph = GraphLayout & {
  label: string | null;
  plots: MetricsGroupedGraphPlot[];
};
export type MetricsGroupedGraphComponent = MetricsGraphComponent & {
  data: {
    graphs: MetricsGroupedGraph[];
    reference: MetricsGraphReferenceForBorderLine | null;
  };
  scaleUnits: Record<GraphLayoutScalePosition, string | null>;
};

export const METRICS_GROUPED_GRAPH_COMPONENT_MAX_GROUPS = 32;

export const isComponentTypeMetricsGroupedGraph = (component: Component): component is MetricsGroupedGraphComponent =>
  component.componentType === METRICS_GRAPH_GROUPED_GRAPH;

const DEFAULT_WIDTH = 2;
const DEFAULT_HEIGHT = 2;

export const constructEmptyMetricsGroupedGraphComponent = (): MetricsGroupedGraphComponent => {
  const emptyGraph: MetricsGroupedGraph = {
    scale: GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
    number: 1,
    plotType: 'bar',
    label: null,
    plots: [],
  };

  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_GRAPH_GROUPED_GRAPH,
    abscissa: 0,
    ordinate: 0,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    referenceDate: null,
    name: '',
    data: {
      graphs: [
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_LEFT,
          number: 1,
          plotType: 'bar',
        },
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_LEFT,
          number: 2,
          plotType: 'bar',
        },
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
          number: 1,
          plotType: 'line',
        },
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
          number: 2,
          plotType: 'line',
        },
      ],
      reference: null,
    },
    scaleUnits: {
      left: null,
      right: null,
    },
  };
};

const getBaseValue = (
  component: MetricsGroupedGraphComponent,
  plot: MetricsGroupedGraphPlot,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) {
    return plot.metrics.value ?? null;
  }
  if (isConditionalStatementBaseReference(conditionalStatement)) {
    return component.data.reference?.metrics.value ?? null;
  }
  return baseValueFromConditionalStatement(conditionalStatement);
};

// 使用していない変数があるが、他のコンポーネントとインターフェースを揃えている
const getThresholdValue = (
  component: MetricsGroupedGraphComponent,
  _plot: MetricsGroupedGraphPlot,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementThresholdReference(conditionalStatement)) {
    return component.data.reference?.metrics.value ?? null;
  }
  return thresholdValueFromConditionalStatement(conditionalStatement);
};

const extractPriorTargetDecoration = (
  component: MetricsGroupedGraphComponent,
  graph: GraphLayout,
  group: number,
): MetricsGraphComponentConditionalStatement | null => {
  const plot = component.data.graphs
    .find((el) => el.scale === graph.scale && el.number === graph.number)
    ?.plots.find((el) => el.group === group);
  return (
    plot?.conditionalStatements
      ?.sort((a, b) => b.priority - a.priority)
      .reduce(
        (
          found: MetricsGraphComponentConditionalStatement | null,
          conditionalStatement: MetricsGraphComponentConditionalStatement,
        ) => {
          if (found) {
            return found;
          }
          const base = getBaseValue(component, plot, conditionalStatement);
          if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
            return compareWithComparator(base, conditionalStatement.comparator, null) ? conditionalStatement : null;
          }
          const threshold = getThresholdValue(component, plot, conditionalStatement);
          if (base === null || threshold === null) {
            return found;
          }
          if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) {
            return found;
          }
          return conditionalStatement;
        },
        null,
      ) ?? null
  );
};

export const applyConditionalStatementToMetricsGroupedGraphComponentColor = (
  component: MetricsGroupedGraphComponent,
  graph: GraphLayout,
  group: number,
): string | null => {
  if (!component.referenceDate) {
    return null;
  }
  const decoration = extractPriorTargetDecoration(component, graph, group)?.decoration;
  return decoration ? hashedColorFromGraphDecoration(decoration) : null;
};

export const switchMetricsGroupedGraphComponentPlotType = (
  component: MetricsGroupedGraphComponent,
  graphLayout: GraphLayout,
): MetricsGroupedGraphComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      graphs: switchGraphLayoutPlotTypes(component.data.graphs, graphLayout) as MetricsGroupedGraph[],
    },
  };
};

export const syncMetricsGroupedGraphGroupedLabel = (
  component: MetricsGroupedGraphComponent,
  graphLayout: GraphLayout,
): MetricsGroupedGraphComponent => {
  const labelMapToSync = component.data.graphs
    .find((graph) => {
      return graph.scale === graphLayout.scale && graph.number === graphLayout.number;
    })!
    .plots.reduce((map, plot) => {
      map[plot.group] = plot.label;
      return map;
    }, {} as Record<number, string | null>);

  return {
    ...component,
    data: {
      ...component.data,
      graphs: component.data.graphs.map((graph) => {
        return {
          ...graph,
          plots: graph.plots.map((plot) => {
            return {
              ...plot,
              label: labelMapToSync[plot.group] ?? plot.label,
            };
          }),
        };
      }),
    },
  };
};

export const syncMetricsGroupedGraphComponentLinePlotColors = (
  component: MetricsGroupedGraphComponent,
): MetricsGroupedGraphComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      graphs: component.data.graphs.map((graph) => {
        const lineColor = graph.plotType === 'line' && (graph.plots[0]?.color ?? null);
        return {
          ...graph,
          plots: graph.plots.map((plot) => {
            return {
              ...plot,
              color: lineColor || plot.color,
            };
          }),
        };
      }),
    },
  };
};

export const specifyMetricsGroupedGraphComponentDominantTimeSpan = (
  component: MetricsGroupedGraphComponent,
): TimeSpan | null => {
  return component.data.graphs.reduce((dominantTimeSpan: TimeSpan | null, graph: MetricsGroupedGraph) => {
    const dominantTimeSpanInGraph = graph.plots.reduce(
      (dominantTimeSpan: TimeSpan | null, plot: MetricsGroupedGraphPlot) => {
        if (dominantTimeSpan === null) {
          return plot.metrics.timeSpan;
        }
        return isTimeSpanLarger(plot.metrics.timeSpan, dominantTimeSpan) ? plot.metrics.timeSpan : dominantTimeSpan;
      },
      null as TimeSpan | null,
    );
    if (dominantTimeSpanInGraph === null) {
      return dominantTimeSpan;
    } else if (dominantTimeSpan === null) {
      return dominantTimeSpanInGraph;
    }
    return isTimeSpanLarger(dominantTimeSpanInGraph, dominantTimeSpan) ? dominantTimeSpanInGraph : dominantTimeSpan;
  }, null as TimeSpan | null);
};

// plotsには参考値は含まない
// グラフのplotに当たる設定を全て削除した状態のコンポーネントを返す
export const clearMetricsGroupedGraphPlots = (
  component: MetricsGroupedGraphComponent,
): MetricsGroupedGraphComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      graphs: component.data.graphs.map((graph) => {
        return {
          ...graph,
          plots: [],
        };
      }),
    },
  };
};
