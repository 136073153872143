
import { computed, defineComponent, PropType, reactive } from 'vue'
import { LinkType, useLinkType } from 'src/views/Dashboard/Settings/Reports/shared/components/LinkFormModal/composables/useLinkType'

type State = {
  selectedLinkType: LinkType
}

export default defineComponent({
  props: {
    value: {
      type: String as PropType<LinkType>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const {
      LINK_TYPE_OPTIONS,
    } = useLinkType()

    const state: State = reactive({
      selectedLinkType: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
    })

    const setLinkType = (linkType: LinkType) => {
      state.selectedLinkType = linkType
    }

    return {
      props,
      state,
      LINK_TYPE_OPTIONS,
      setLinkType,
      close,
    }
  },
})
