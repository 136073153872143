
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue';
import { omitString } from 'src/util/text_decorator';
import { ConditionalStatementWithContainerPosition } from 'src/models/new/conditionalStatement';
import { ConditionalStatementContainer } from '../logics/ConditionalStatementContainer';
import conditionalStatementApi from 'src/apis/conditionalStatement';
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts';

type State = {
  isReady: boolean;
  wordToSearch: string;
  containersToShow: ConditionalStatementContainer[];
  selectedContainer: ConditionalStatementContainer | null;
};

export default defineComponent({
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
    containers: {
      type: Array as PropType<ConditionalStatementContainer[]>,
      required: true,
    },
    selectedConditionalStatements: {
      type: Array as PropType<ConditionalStatementWithContainerPosition<any>[]>,
      required: true,
    },
  },
  emits: ['updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const state: State = reactive({
      isReady: false,
      wordToSearch: '',
      containersToShow: computed(() => {
        return props.containers.filter((el) => {
          return el.label.name.includes(state.wordToSearch) || el.label.position.includes(state.wordToSearch);
        });
      }),
      selectedContainer: null,
    });

    const selectContainer = (container: ConditionalStatementContainer) => {
      state.selectedContainer = container;
    };

    // 内部的に選択中の設置領域は検索文字列の変更によっては直ちに変更されないようにしている
    // そのため、選択中の設置領域が表示されていないケースがあり、この状態はユーザーにとって
    // どの設置領域も選択していない状態と見なす
    // この関数ではユーザー視点で実質的に選択している設置領域を取得する
    const getActuallySelectedContainer = (): ConditionalStatementContainer | null => {
      if (!state.selectedContainer) {
        return null;
      }

      return (
        state.containersToShow.find((el) => {
          return (
            el.primaryPositionId === state.selectedContainer!.primaryPositionId &&
            el.secondaryPositionId === state.selectedContainer!.secondaryPositionId
          );
        }) ?? null
      );
    };

    const generateCopies = (
      conditionalStatements: ConditionalStatementWithContainerPosition<any>[],
      targetContainer: ConditionalStatementContainer,
    ): ConditionalStatementWithContainerPosition<any>[] => {
      return conditionalStatements.map((el) => {
        return {
          ...el,
          primaryPositionId: targetContainer.primaryPositionId,
          secondaryPositionId: targetContainer.secondaryPositionId,
        };
      });
    };

    const close = (): void => {
      emit('close');
    };
    const update = async (): Promise<void> => {
      try {
        const targetContainer = getActuallySelectedContainer();
        if (!targetContainer) {
          return;
        }

        const conditionalStatements = generateCopies(props.selectedConditionalStatements, targetContainer);

        await conditionalStatementApi.bulkCreate(props.reportId, props.componentId, conditionalStatements);
        emit('updated', '条件付き書式をコピーしました。');
      } catch (err: any) {
        const errStatus = err.response.status;
        const errData = err.response?.data ?? {};
        if ([403, 404].includes(errStatus)) {
          const msg = '操作権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 409 && errData.reason === 'position_not_exist') {
          const msg = '条件付き書式のコピーに失敗しました。コンポーネントが編集されています。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 409 && errData.reason === 'exceed_max_for_position') {
          const msg = '条件付き書式のコピー先に十分な空きがありません。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else {
          const msg = '条件付き書式のコピーに失敗しました。管理者に連絡してください。';
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err);
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false;
      state.isReady = true;
    });

    return {
      props,
      state,
      omitString,
      selectContainer,
      close,
      update,
    };
  },
});
