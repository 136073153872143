import { Link } from 'src/models/new/link'
import { RecordId } from 'src/util/recordId'

export type LinkResponseData = {
  id: RecordId
  name: string
  url: string
  sequential_order: number
}

export const convertFromLinkResponseData = (data: LinkResponseData): Link => {
  return {
    id: data.id,
    name: data.name,
    url: data.url,
    sequentialOrder: data.sequential_order,
  }
}
