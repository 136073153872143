
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive, watch } from 'vue'
import { vvGetError, vvGetErrorObject, vvHasError, vvReset } from 'src/util/vee_validate'
import {
  constructPeriod,
  isPeriodDays,
  isPeriodThisMonth,
  isPeriodThisWeek,
  isPeriodWithoutValue,
  isPeriodWithValue,
  Period,
  PERIOD_UNIT_LABELS,
  PERIOD_YEARS,
  PeriodUnit,
} from 'src/business/period'
import {
  clearMetricsListComponentMainMetrics,
  MetricsListComponent,
  specifyMetricsListComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent'
import {
  getAvailablePeriodUnitsFromTimeSpan,
  METRICS_TRANSITION_COMPONENT_DISP_DAYS_REWIND_PERIOD_UNIT_LABELS,
  MetricsTransitionComponentRewindPeriodUnit,
} from 'src/models/new/Component/MetricsComponent/metricsTransitionComponentProperties'
import { TIME_SPAN_LOCAL_WORDS, TimeSpan } from 'src/business/timeSpan'
import InputError from 'src/components/InputError.vue'

const MAX_PERIOD_VALUE = 31
const MAX_DISP_REWIND_PERIOD_VALUE = 366
const DISP_DAYS_REWIND_PERIOD_UNIT_OPTIONS = Object.keys(METRICS_TRANSITION_COMPONENT_DISP_DAYS_REWIND_PERIOD_UNIT_LABELS).map(el => {
  return {
    value: el,
    label: METRICS_TRANSITION_COMPONENT_DISP_DAYS_REWIND_PERIOD_UNIT_LABELS[el as MetricsTransitionComponentRewindPeriodUnit],
  }
})
type PeriodUnitOption = { value: PeriodUnit, label: string }
const PERIOD_UNIT_OPTIONS: PeriodUnitOption[] = Object.keys(PERIOD_UNIT_LABELS).map(el => {
  return {
    value: el as PeriodUnit,
    label: `${PERIOD_UNIT_LABELS[el as PeriodUnit]}分`,
  }
})

const DEFAULT_DATE_FORMAT = 'MM/dd'
const DEFAULT_DATE_FORMAT_FOR_PERIOD_UNIT_YEARS = 'yyyy'

const TIME_SPAN_OPTIONS = Object.keys(TIME_SPAN_LOCAL_WORDS).map(el => {
  return {
    value: el as TimeSpan,
    label: TIME_SPAN_LOCAL_WORDS[el as TimeSpan],
  }
})

interface State {
  hasError: boolean
  validations: Record<string, object>
  forceIsTodayDisplayedTrue: boolean
  component: MetricsListComponent
  isPeriodRequireValue: boolean
  periodMetaData: { unit: PeriodUnit | null, value: number | null }
  dispDaysRewindPeriodMetaData: { unit: MetricsTransitionComponentRewindPeriodUnit | null, value: number | null }
  showConfirmationModal: boolean
  // 確認モーダルを挟む際に一時的に選択された基準周期を保持する
  timeSpanOnConfirmation: TimeSpan | null
  // 選択可能な表示期間
  availablePeriodUnits: PeriodUnit[]
  periodUnitOptions: PeriodUnitOption[]
}

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<MetricsListComponent>,
      required: true,
    },
    dominantTimeSpan: {
      type: String as PropType<TimeSpan>,
      required: true,
    },
    onDominantTimeSpanChanged: {
      type: Function as PropType<(timeSpan: TimeSpan) => void>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      hasError: computed(() => vvHasError(root)),
      validations: computed(() => {
        const validations = {
          name: { required: true, max: 50 },
          periodUnit: { required: true },
          dateFormat: {
            required: true,
            max: 7,
            dateFormatExpression: true,
          },
          dispDaysRewindPeriodValue: {
            required: !!state.dispDaysRewindPeriodMetaData.unit,
            numeric: true,
            min_value: 1,
            max_value: MAX_DISP_REWIND_PERIOD_VALUE,
          },
        }
        if (state.isPeriodRequireValue) {
          Object.assign(validations, {
            periodValue: {
              required: true,
              numeric: true,
              min_value: 1,
              max_value: MAX_PERIOD_VALUE,
            },
          })
        }
        if (state.dispDaysRewindPeriodMetaData.value) {
          Object.assign(validations, { dispDaysRewindPeriodUnit: { required: true } })
        }
        return validations
      }),
      component: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
      // periodはモデルの制約と入力インターフェースが異なるため、メタデータを組み合わせて扱う
      periodMetaData: { unit: null, value: null },
      isPeriodRequireValue: computed(() => !isPeriodWithoutValue(state.periodMetaData as Period)),
      forceIsTodayDisplayedTrue: computed(() => {
        const period = state.periodMetaData as Period
        return isPeriodThisWeek(period) || isPeriodThisMonth(period) || isPeriodDays(period)
      }),
      // dispDaysRewindPeriodはモデルの制約と入力インターフェースが異なるため、メタデータを組み合わせて扱う
      dispDaysRewindPeriodMetaData: { unit: null, value: null },
      showConfirmationModal: false,
      timeSpanOnConfirmation: null,
      availablePeriodUnits: computed(() => {
        if (!props.dominantTimeSpan) return []
        return getAvailablePeriodUnitsFromTimeSpan(props.dominantTimeSpan)
      }),
      periodUnitOptions: computed(() => {
        return [...PERIOD_UNIT_OPTIONS.filter(el => state.availablePeriodUnits.includes(el.value))]
      }),
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)

    const updatePeriod = (): void => {
      if (state.periodMetaData.unit) {
        try {
          state.component.period = constructPeriod(state.periodMetaData.unit, state.periodMetaData.value)
        } catch {
          // periodを更新しない
        }
      }
    }

    const onPeriodUnitChange = (): void => {
      if (state.forceIsTodayDisplayedTrue) {
        state.component.isTodayDisplayed = true
      }
      if (!state.isPeriodRequireValue) {
        state.periodMetaData = { unit: state.periodMetaData.unit, value: null }
      }
      if (state.periodMetaData.unit === PERIOD_YEARS) {
        state.component.dateFormat = DEFAULT_DATE_FORMAT_FOR_PERIOD_UNIT_YEARS
      } else {
        state.component.dateFormat = DEFAULT_DATE_FORMAT
      }
      vvReset(root, 'periodValue')
      updatePeriod()
    }

    const onPeriodValueChange = (): void => {
      if (state.periodMetaData.value && state.periodMetaData.value > MAX_PERIOD_VALUE) {
        state.periodMetaData.value = MAX_PERIOD_VALUE
      } else if (state.periodMetaData.value && state.periodMetaData.value <= 0) {
        state.periodMetaData.value = 1
      }

      updatePeriod()
    }

    const updateDispDaysRewindPeriod = (): void => {
      if (state.dispDaysRewindPeriodMetaData.unit && state.dispDaysRewindPeriodMetaData.value) {
        state.component.dispDaysRewindPeriod = {
          unit: state.dispDaysRewindPeriodMetaData.unit!,
          value: state.dispDaysRewindPeriodMetaData.value!,
        }
      } else {
        state.component.dispDaysRewindPeriod = null
      }
    }

    const onDispDaysRewindPeriodUnitChange = (): void => {
      vvReset(root, 'dispDaysRewindPeriodValue')
      updateDispDaysRewindPeriod()
    }

    const onDispDaysRewindPeriodValueChange = (): void => {
      if (state.dispDaysRewindPeriodMetaData.value &&
        state.dispDaysRewindPeriodMetaData.value > MAX_DISP_REWIND_PERIOD_VALUE) {
        state.dispDaysRewindPeriodMetaData.value = MAX_DISP_REWIND_PERIOD_VALUE
      } else if (state.dispDaysRewindPeriodMetaData.value && state.dispDaysRewindPeriodMetaData.value <= 0) {
        state.dispDaysRewindPeriodMetaData.value = 1
      }

      updateDispDaysRewindPeriod()
    }

    const onIsVerticalChange = (): void => {
      const head = state.component.headers.head.layout
      const side = state.component.headers.side.layout

      state.component = {
        ...state.component,
        headers: {
          head: {
            ...state.component.headers.head,
            layout: side,
          },
          side: {
            ...state.component.headers.side,
            layout: head,
          },
        },
      }
    }

    const tryToChangeTimeSpan = async(timeSpan: TimeSpan): Promise<void> => {
      const specifiedDominantTimeSpan = specifyMetricsListComponentDominantTimeSpan(state.component)

      if (!specifiedDominantTimeSpan || specifiedDominantTimeSpan === timeSpan) {
        props.onDominantTimeSpanChanged(timeSpan)
      } else {
        state.timeSpanOnConfirmation = timeSpan
        state.showConfirmationModal = true
      }
    }

    const confirmToConfirmation = (): void => {
      state.showConfirmationModal = false
      if (state.timeSpanOnConfirmation) {
        // 主メトリクスのみリセット
        state.component = clearMetricsListComponentMainMetrics(state.component)
        props.onDominantTimeSpanChanged(state.timeSpanOnConfirmation)
      }
      state.timeSpanOnConfirmation = null
    }

    const cancelToConfirmation = (): void => {
      state.showConfirmationModal = false
      state.timeSpanOnConfirmation = null
    }

    const updatePeriodMetaData = (): void => {
      // unitが未設定の場合は初期値をセット
      if (!state.periodMetaData.unit) {
        state.periodMetaData = {
          unit: state.component.period.unit,
          value: isPeriodWithValue(state.component.period) ? state.component.period.value : null,
        }
        return
      }

      // 選択不可な表示期間が設定されている場合は選択可能な表示期間の中からデフォでセットする
      if (!state.availablePeriodUnits.includes(state.periodMetaData.unit)) {
        state.periodMetaData = {
          unit: state.availablePeriodUnits[0],
          value: null,
        }
        updatePeriod()
      }
    }

    watch(() => ([state.availablePeriodUnits]), () => {
      updatePeriodMetaData()
    })

    onMounted(() => {
      updatePeriodMetaData()
      state.dispDaysRewindPeriodMetaData = {
        unit: state.component.dispDaysRewindPeriod?.unit ?? null,
        value: state.component.dispDaysRewindPeriod?.value ?? null,
      }
    })

    return {
      state,
      props,
      getError,
      getErrorObject,
      MAX_PERIOD_VALUE,
      PERIOD_UNIT_OPTIONS,
      DISP_DAYS_REWIND_PERIOD_UNIT_OPTIONS,
      onPeriodUnitChange,
      onPeriodValueChange,
      onDispDaysRewindPeriodUnitChange,
      onDispDaysRewindPeriodValueChange,
      onIsVerticalChange,
      TIME_SPAN_OPTIONS,
      tryToChangeTimeSpan,
      confirmToConfirmation,
      cancelToConfirmation,
    }
  },
})
