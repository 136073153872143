import type { Workplace } from 'src/models/new/workplace';
import workplaceApi from 'src/apis/masters/workplace';
import { generateInjectionKey } from './logics/generateInjectionKey';
import { useAsyncResources, useAsyncResourcesProvider } from './useAsyncResources';

type UseReportsProviderArgs = {
  onLoad?: () => unknown;
};
type UseReportsProviderResult = {
  workplacesRef: ReturnType<typeof useAsyncResourcesProvider<Workplace>>['resources'];
};

type UseReportsResult = {
  workplacesRef: ReturnType<typeof useAsyncResources<Workplace>>['resources'];
};

const key = generateInjectionKey('workplaces');

const fetchWorkplaces = async () => {
  const result = await workplaceApi.index();
  return result.sort((a: Workplace, b: Workplace) => (a.sequentialOrder > b.sequentialOrder ? 1 : -1));
};

export const useWorkplacesProvider = ({ onLoad }: UseReportsProviderArgs = {}): UseReportsProviderResult => {
  const { resources: workplacesRef } = useAsyncResourcesProvider<Workplace>({
    key,
    fetcher: fetchWorkplaces,
    onLoad,
  });

  return {
    workplacesRef,
  };
};

export const useWorkplaces = (): UseReportsResult => {
  const { resources: workplacesRef } = useAsyncResources<Workplace>({ key });

  return {
    workplacesRef,
  };
};
