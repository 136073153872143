import { DecimalString } from 'src/models/common'
import { LogiCoredataWorkplaceResponseData } from '../LogiCoredataWorkplace/logiCoredataWorkplaceResponseData'
import { LogiCoredataBudgetGroup } from 'src/models/new/logiCoredataBudgetGroup'

export interface LogiCoredataBudgetGroupResponseData {
  id: number
  workplace_id: number
  workplace?: LogiCoredataWorkplaceResponseData
  name: string
  target_profit_rate: DecimalString
  disp_order: number
  is_enabled: boolean
}

export const convertFromLogiCoredataBudgetGroupResponseData = (data: LogiCoredataBudgetGroupResponseData): LogiCoredataBudgetGroup => {
  return {
    id: data.id,
    name: data.name,
    logiCoredataWorkplaceId: data.workplace_id,
  }
}
