
import { defineComponent, PropType } from 'vue'
import { ComponentLink } from 'src/models/new/Link/componentLink'
import LinkDeleteConfirmModal from 'src/views/Dashboard/Settings/Reports/shared/components/LinkDeleteConfirmModal/LinkDeleteConfirmModal.vue'
import { useComponentLinkDeleter } from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkDeleteConfirmModal/composables/useComponentLinkDeleter'

export default defineComponent({
  components: {
    LinkDeleteConfirmModal,
  },
  props: {
    componentLink: {
      type: Object as PropType<ComponentLink>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
  },
  emits: ['updated', 'close', 'failed'],
  setup(props, { emit }) {
    const {
      deleteComponentLink,
    } = useComponentLinkDeleter()

    const execute = async(): Promise<void> => {
      await deleteComponentLink(props.reportId, props.componentId, props.componentLink)
    }

    const onClose = () => emit('close')

    return {
      props,
      execute,
      onClose,
    }
  },
})
