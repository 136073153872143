import axios from 'axios';
import { buildBackendUrl } from 'src/apis/util/url';
import { ReportLink } from 'src/models/new/Link/reportLink';
import {
  ReportLinkResponseData,
  convertFromReportLinkResponseData,
} from 'src/models/api/Link/ReportLink/reportLinkResponseData';
import { convertToReportLinkCreateRequestParameters } from 'src/models/api/Link/ReportLink/reportLinkCreateRequestParameters';
import { convertToReportLinkUpdateRequestParameters } from 'src/models/api/Link/ReportLink/reportLinkUpdateRequestParameters';
import { convertToLinkBulkUpdateSequentialOrderRequestParameters } from 'src/models/api/Link/linkBulkUpdateSequentialOrderRequestParameters';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (reportId: number): string => `/masters/reports/${reportId}/report_links`;

export default {
  async index(reportId: number): Promise<ReportLink[]> {
    const url = buildBackendUrl(buildBasePath(reportId));
    const { data: resData } = await axios.get<MultiDataResponse<ReportLinkResponseData>>(url);
    return resData.result.map(convertFromReportLinkResponseData);
  },
  async create(reportId: number, reportLink: ReportLink): Promise<{}> {
    const params = convertToReportLinkCreateRequestParameters(reportLink);
    const url = buildBackendUrl(buildBasePath(reportId));
    await axios.post(url, params);
    return {};
  },
  async update(reportId: number, reportLink: ReportLink): Promise<{}> {
    const params = convertToReportLinkUpdateRequestParameters(reportLink);
    const url = buildBackendUrl(buildBasePath(reportId), params.id);
    await axios.put(url, params);
    return {};
  },
  async destroy(reportId: number, id: number): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), id);
    await axios.delete(url);
    return {};
  },
  async bulkUpdateSequentialOrder(reportId: number, data: ReportLink[]): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId), 'bulk_update_sequential_order');
    const params = convertToLinkBulkUpdateSequentialOrderRequestParameters(data);
    await axios.put(url, params);
    return {};
  },
};
