import PrintLayout from 'src/views/Print/Layout/PrintLayout.vue';

const routes = [
  {
    path: '',
    component: PrintLayout,
    children: [
      {
        path: 'reports/:reportId',
        name: 'PrintReportValuesDetail',
        component: require('src/views/Print/ReportValues/Detail').default,
      },
      // // URLが"print/..."で有効なパスにマッチしないケースは'*'にマッチするが
      // "print"の場合は'*'にマッチしない為、別途リダイレクトを設定している
      {
        path: '',
        redirect: { name: 'Login' },
      },
      // catch all
      {
        path: '*',
        redirect: { name: 'Login' },
      },
    ],
  },
];

export default routes;
