
import { PropType, computed, defineComponent, reactive, watch } from 'vue';
import Chart from 'chart.js';
import HorizontalBarChart from 'src/components/UIComponents/Charts/HorizontalBarChart.vue';
import {
  datasetFromHorizontalBarItems,
  addPluginForLineAnnotation,
  legendLabelsFromHorizontalBarItems,
  getOptionForHorizontalBarGraph,
  CustomHorizontalBarGraphOptions,
  VerticalLineAnnotationOptions,
  buildVerticalLineAnnotationOptions,
  CustomHorizontalBarChartDataDataset,
  getCustomScaleLabelsForHorizontalBarGraph,
} from 'src/util/chart';
import { ChartDataLabel, ChartScaleLabel } from 'src/components/UIComponents/Charts/shared';
import {
  MetricsBarGraphComponent,
  applyConditionalStatementToMetricsBarGraphComponentColor,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';
import { HorizontalBarChartOptions } from 'src/util/Chart/horizontalBarChart';
import { deepMerge } from 'src/util/object';

type State = {
  title: string;
  labels: ChartDataLabel[];
  datasets: CustomHorizontalBarChartDataDataset[];
  options: HorizontalBarChartOptions | (HorizontalBarChartOptions & VerticalLineAnnotationOptions);
  plugins: Chart.PluginServiceRegistrationOptions[];
  scaleLabels: ChartScaleLabel[];
  height: string;
};

export default defineComponent({
  components: {
    HorizontalBarChart,
  },
  props: {
    component: {
      type: Object as PropType<MetricsBarGraphComponent>,
      required: true,
    },
  },
  setup(props) {
    const generateChartItems = (component: MetricsBarGraphComponent) => {
      return component.data.bars.map((el) => {
        const overrideHashedColor = applyConditionalStatementToMetricsBarGraphComponentColor(
          component,
          el.sequentialOrder,
        );
        return {
          label: el.alias || el.metrics.name,
          value: el.metrics.value,
          decimalPlaces: el.metrics.decimalPlaces,
          scaling: el.metrics.scaling,
          color: overrideHashedColor ? (overrideHashedColor as string) : el.color,
        };
      });
    };

    const generateDatasets = (component: MetricsBarGraphComponent): CustomHorizontalBarChartDataDataset[] => {
      const chartItems = generateChartItems(component);
      const dataset = datasetFromHorizontalBarItems(chartItems);
      return [dataset];
    };

    const state: State = reactive({
      title: computed(() => props.component.name),
      labels: computed(() => generateLabels(props.component)),
      // データセットは算出プロパティで渡してはならず、onMounted内ではなくsetup内で代入する必要がある
      // setup内で値が与えられない場合グラフがレンダリングされず、算出プロパティを与えると内部的に書き換えが発生して無限ループに陥る
      // オブジェクトは引き継いでいないはずだが、詳しい原因は不明
      datasets: generateDatasets(props.component),
      options: computed(() => generateOptions(props.component)),
      plugins: computed(() => generatePlugins(props.component)),
      scaleLabels: computed(() => generateScaleLabels(props.component)),
      // コンポーネントの1行あたりの高さが100pxで複数行の場合は間に10pxのマージンが入る
      // 行間のマージン1つ分、2行分のタイトルの高さ、タイトル下のマージン、カードのパディング合計との差をグラフの高さとする
      height: computed(() => `${110 * props.component.height - 110}px`),
    });

    const generateLabels = (component: MetricsBarGraphComponent): ChartDataLabel[] => {
      const chartItems = generateChartItems(component);
      return legendLabelsFromHorizontalBarItems(chartItems);
    };

    const generateOptions = (
      component: MetricsBarGraphComponent,
    ): CustomHorizontalBarGraphOptions | (CustomHorizontalBarGraphOptions & VerticalLineAnnotationOptions) => {
      const options = getOptionForHorizontalBarGraph();
      if (component.data.reference) {
        const verticalLineAnnotationOptions = buildVerticalLineAnnotationOptions(
          component.data.reference.metrics.value,
          component.data.reference.metrics.decimalPlaces,
          component.data.reference.metrics.scaling,
          component.data.reference.label,
          component.data.reference.color,
        );
        return deepMerge(options, verticalLineAnnotationOptions);
      } else {
        return options;
      }
    };

    const generatePlugins = (_: MetricsBarGraphComponent): Chart.PluginServiceRegistrationOptions[] => {
      return addPluginForLineAnnotation([]);
    };

    const generateScaleLabels = (component: MetricsBarGraphComponent): ChartScaleLabel[] => {
      return getCustomScaleLabelsForHorizontalBarGraph(component.unit);
    };

    watch(
      () => props.component,
      (component) => {
        state.datasets = generateDatasets(component);
      },
    );

    return {
      state,
      props,
    };
  },
});
