export const DATE_FNS_DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_FNS_DATE_SLASH_FORMAT = 'yyyy/MM/dd'
export const DATE_FNS_DATE_SHORT_SLASH_FORMAT = 'MM/dd'
export const DATE_FNS_TIME_FORMAT = 'HH:mm:ss'
export const DATE_FNS_DATE_TIME_FORMAT = `${DATE_FNS_DATE_FORMAT} ${DATE_FNS_TIME_FORMAT}`

export const SYSTEM_DATE_FORMAT = DATE_FNS_DATE_FORMAT
export const SYSTEM_DATETIME_FORMAT = DATE_FNS_DATE_TIME_FORMAT
// システム共通の詳細な時刻情報を表すフォーマットはrailsに従い、iso8601とする
export const SYSTEM_DATETIME_STRICT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
