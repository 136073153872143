import { Metrics, MetricsType, internal } from 'src/models/new/metrics'
import {
  AGGREGATE_FUNCTION_AVG,
  AGGREGATE_FUNCTION_COUNT,
  AGGREGATE_FUNCTION_LOCAL_WORDS,
  AGGREGATE_FUNCTION_MAX,
  AGGREGATE_FUNCTION_MIN,
  AGGREGATE_FUNCTION_SUM,
  AggregateFunction
} from 'src/business/aggregateFunction'
import { TIME_SPAN_DAILY } from 'src/business/timeSpan'

const DIRECT_INPUT = internal.DIRECT_INPUT
const LOGIMETER = internal.LOGIMETER
const LOGIBOARD = internal.LOGIBOARD
const CALC = internal.CALC
const SUMMARY = internal.SUMMARY
const CROSS = internal.CROSS
const EXT_CROSS = internal.EXT_CROSS

export type BundledMetrics = Metrics & {
  targetMetricsIds: number[]
  aggrFunc: AggregateFunction
  isZeroIncluded: boolean
  isBorderless: boolean
}
// EXT_CROSSならばisBorderless=trueである
// 二重管理になってしまっているが、isBorderlessがあった方が判定が早い一方でEXT_CROSSは画面上CROSSと別項目なので仕方がない
export const isMetricsBundledMetrics = (metrics: Metrics): metrics is BundledMetrics => {
  return metrics.metricsType === CROSS || metrics.metricsType === EXT_CROSS
}
export const isMetricsBundledBorderless = (metrics: BundledMetrics): boolean => metrics.isBorderless

export const constructEmptyBundledMetrics = (): BundledMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: CROSS,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    targetMetricsIds: [],
    aggrFunc: AGGREGATE_FUNCTION_SUM,
    isZeroIncluded: false,
    isBorderless: false,
    updatedAt: null,
  }
}

export const constructEmptyBundledBorderlessMetrics = (): BundledMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: EXT_CROSS,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    targetMetricsIds: [],
    aggrFunc: AGGREGATE_FUNCTION_SUM,
    isZeroIncluded: false,
    isBorderless: true,
    updatedAt: null,
  }
}

export const BUNDLED_METRICS_MAX_METRICS_COUNT = 30

export const BUNDLED_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS: Record<AggregateFunction, string> = Object.fromEntries(
  Object.keys(AGGREGATE_FUNCTION_LOCAL_WORDS).filter(el => {
    return [
      AGGREGATE_FUNCTION_COUNT,
      AGGREGATE_FUNCTION_SUM,
      AGGREGATE_FUNCTION_AVG,
      AGGREGATE_FUNCTION_MIN,
      AGGREGATE_FUNCTION_MAX,
    ].includes(el)
  }).map(el => {
    if (el === AGGREGATE_FUNCTION_COUNT) return [el as AggregateFunction, '横断メトリクス数']
    return [el as AggregateFunction, AGGREGATE_FUNCTION_LOCAL_WORDS[el as AggregateFunction]]
  })
) as Record<AggregateFunction, string>

export const BUNDLED_METRICS_REFERABLE_METRICS_TYPES = [
  DIRECT_INPUT,
  LOGIMETER,
  LOGIBOARD,
  SUMMARY,
]

const BUNDLED_BORDERLESS_METRICS_REFERABLE_METRICS_TYPES = [
  DIRECT_INPUT,
  LOGIMETER,
  LOGIBOARD,
  SUMMARY,
  CALC,
  CROSS,
  EXT_CROSS,
]

export const getBundledMetricsReferableMetricsTypes = (isBorderless: boolean): MetricsType[] => {
  return isBorderless
    ? BUNDLED_BORDERLESS_METRICS_REFERABLE_METRICS_TYPES
    : BUNDLED_METRICS_REFERABLE_METRICS_TYPES
}
