
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import conversionProgramApi from 'src/apis/conversionProgram';
import conversionProgramStatusApi from 'src/apis/conversionProgramStatus';
import { ConversionProgram } from 'src/models/conversionProgram';
import {
  ConversionProgramStatus,
  getExecuteStatusColor,
  DATA_CONVERSION_STATUS_IN_PROGRESS,
  DATA_CONVERSION_STATUS_COMPLETED,
  DATA_CONVERSION_STATUS_FAILED,
  REQUESTS_COMPLETION_STATUS_IN_PROGRESS,
  REQUESTS_COMPLETION_STATUS_COMPLETED,
  REQUESTS_COMPLETION_STATUS_FAILED,
} from 'src/models/conversionProgramStatus';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { formatDate } from 'src/util/datetime';
import { ERROR_REASON_MAX_PROCESSING_JOBS } from 'src/consts';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';

const CONVERSION_PROGRAM_MESSAGE_UPLOAD_CSV = 'CSVデータのアップロードを開始しました';
const ITEM_PER_PAGE = 50;

type ConversionProgramStatusEx = ConversionProgramStatus & {
  dtDisp: string;
  lastRequestDate: string | null;
  isInProgressExecuteStatus: boolean;
  isCompletedExecuteStatus: boolean;
  isFailedExecuteStatus: boolean;
  isResultViewable: boolean;
};

interface State {
  pageName: string | null;
  userId: number | null;
  list: ConversionProgram[];
  statusList: ConversionProgramStatusEx[];
  hasList: boolean;
  hasStatusList: boolean;
  selectedConversionProgram: ConversionProgram | null;
  hasPageAccessRole: boolean;
  dataLoadState: { availableTotal: number; loadedSlices: number };
}

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    list: [],
    statusList: [],
    hasList: computed(() => {
      return state.list.length > 0;
    }),
    hasStatusList: computed(() => {
      return state.statusList.length > 0;
    }),
    selectedConversionProgram: null,
    hasPageAccessRole: true,
    dataLoadState: { availableTotal: 0, loadedSlices: 0 },
  });
  return state;
}

export default defineComponent({
  components: {
    PaginationContainer: usePaginationContainer<ConversionProgram>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'データ変換');
    const state = setupState(root);

    async function loadConversionPrograms(): Promise<void> {
      state.dataLoadState.loadedSlices = 1;

      try {
        const response = await conversionProgramApi.index({ page: 1 });
        state.list = response.result;
        state.dataLoadState.availableTotal = response.pagination.total;
        state.statusList = [];
      } catch (err: any) {
        const errStatus = err.response?.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    async function loadConversionProgramsNextSlice(): Promise<void> {
      state.dataLoadState.loadedSlices += 1;

      try {
        const response = await conversionProgramApi.index({ page: state.dataLoadState.loadedSlices });
        state.list = [...state.list, ...response.result];
        state.dataLoadState.availableTotal = response.pagination.total;
        state.statusList = [];
      } catch (err: any) {
        const errStatus = err.response?.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    async function getStatusList(): Promise<void> {
      if (!state.selectedConversionProgram) {
        return;
      }
      try {
        const response = await conversionProgramStatusApi.index(state.selectedConversionProgram.id);
        state.statusList = response.map(convertConversionProgramStatus);
      } catch (err: any) {
        const errStatus = err.response?.status;
        const errRes = err.response?.data || {};
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        } else {
          notifyError1(root, errRes, { err });
        }
      }
    }

    function convertConversionProgramStatus(elem: ConversionProgramStatus): ConversionProgramStatusEx {
      return {
        ...elem,
        dtDisp: formatDate(elem.dt, 'yyyy/MM/dd'),
        lastRequestDate: elem.lastRequestTime !== null ? formatDate(elem.lastRequestTime, 'yyyy-MM-dd') : null,
        isInProgressExecuteStatus: elem.dataConversionStatus === DATA_CONVERSION_STATUS_IN_PROGRESS,
        isCompletedExecuteStatus: elem.dataConversionStatus === DATA_CONVERSION_STATUS_COMPLETED,
        isFailedExecuteStatus: elem.dataConversionStatus === DATA_CONVERSION_STATUS_FAILED,
        isResultViewable: hasRequested(elem),
      };
    }

    function hasRequested(elem: ConversionProgramStatus): boolean {
      if (
        elem.requestsCompletionStatus === REQUESTS_COMPLETION_STATUS_IN_PROGRESS ||
        elem.requestsCompletionStatus === REQUESTS_COMPLETION_STATUS_COMPLETED ||
        elem.requestsCompletionStatus === REQUESTS_COMPLETION_STATUS_FAILED
      ) {
        return true;
      }
      return false;
    }

    async function selectConversionProgram(conversionProgram: ConversionProgram): Promise<void> {
      state.selectedConversionProgram = conversionProgram;
      await getStatusList();
    }

    function openFileDialog(conversionProgramId: string): void {
      const element: HTMLElement = document.getElementById(`csvInput-${conversionProgramId}`) as HTMLElement;
      element.click();
    }

    async function onCsvUpload(conversionProgram: ConversionProgram, e: any): Promise<void> {
      try {
        const data = new FormData();
        data.append('csv', e.target.files[0]);
        await conversionProgramStatusApi.create(conversionProgram.id, data);
        notifySuccess1(root, CONVERSION_PROGRAM_MESSAGE_UPLOAD_CSV);
        await selectConversionProgram(conversionProgram);
      } catch (err: any) {
        const errStatus: number = err.response?.status;
        const errRes = err.response?.data || {};
        if (errStatus === 400 && errRes.reason === ERROR_REASON_MAX_PROCESSING_JOBS) {
          const msg = 'お客様の一括処理のリクエスト制限数を超えています。しばらくしてから再度実施ください。';
          notifyError1(root, msg, { err });
        } else {
          const msg = `${state.pageName}の処理に失敗しました。管理者に連絡してください。` + `(ERR: ${state.pageName})`;
          notifyError1(root, msg, { err });
        }
      }
    }

    onMounted(async () => {
      await Promise.all([ensureUserAndMasters(root), loadConversionPrograms()]);
      if (state.hasList) {
        await selectConversionProgram(state.list[0]);
      }
    });

    return {
      getExecuteStatusColor,
      onCsvUpload,
      openFileDialog,
      state,
      selectConversionProgram,
      loadConversionProgramsNextSlice,
      ITEM_PER_PAGE,
    };
  },
});
