
import { computed, defineComponent, getCurrentInstance, PropType, reactive } from 'vue'
import { vvGetError, vvGetErrorObject, vvHasError } from 'src/util/vee_validate'
import {
  clearMetricsPieChartData,
  isMetricsPieChartComponentDisplayValueAsNumber,
  isMetricsPieChartComponentDisplayValueAsRate,
  METRICS_PIE_CHART_COMPONENT_DISPLAY_PATTERN_LOCAL_WORDS,
  MetricsPieChartComponent,
  MetricsPieChartDisplayPattern,
  specifyMetricsPieChartComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent'
import { TIME_SPAN_LOCAL_WORDS, TimeSpan } from 'src/business/timeSpan'
import InputError from 'src/components/InputError.vue'

type TimeSpanOption = { value: TimeSpan | null, label: string }

const TIME_SPAN_OPTIONS: TimeSpanOption[] = Object.keys(TIME_SPAN_LOCAL_WORDS).map(key => {
  return {
    value: key as TimeSpan,
    label: TIME_SPAN_LOCAL_WORDS[key as TimeSpan],
  }
})

interface State {
  component: MetricsPieChartComponent
  hasError: boolean
  validations: Record<string, object>
  isUnitInputDisabled: boolean
  displayDummyUnit: boolean
  showConfirmationModal: boolean
  // 確認モーダルを挟む際に一時的に選択された基準周期を保持する
  timeSpanOnConfirmation: TimeSpan | null
}

const DisplayPatternOptions = Object.keys(METRICS_PIE_CHART_COMPONENT_DISPLAY_PATTERN_LOCAL_WORDS).map(el => {
  return { value: el, label: METRICS_PIE_CHART_COMPONENT_DISPLAY_PATTERN_LOCAL_WORDS[el as MetricsPieChartDisplayPattern] }
})

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<MetricsPieChartComponent>,
      required: true,
    },
    dominantTimeSpan: {
      type: String as PropType<TimeSpan>,
      required: true,
    },
    onDominantTimeSpanChanged: {
      type: Function as PropType<(timeSpan: TimeSpan) => void>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      component: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
      hasError: computed(() => vvHasError(root)),
      validations: computed(() => {
        const validations = {
          name: { required: true, max: 50 },
          displayAs: { required: true },
        }
        if (!state.isUnitInputDisabled) {
          Object.assign(validations, { required: false, max: 10 })
        }

        return validations
      }),
      isUnitInputDisabled: computed(() => !isMetricsPieChartComponentDisplayValueAsNumber(state.component)),
      displayDummyUnit: computed(() => isMetricsPieChartComponentDisplayValueAsRate(state.component)),
      showConfirmationModal: false,
      timeSpanOnConfirmation: null,
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)

    const onChangeDisplayAs = () :void => {
      if (state.isUnitInputDisabled) {
        state.component.displayValueSettings.unit = null
      }
    }

    const tryToChangeTimeSpan = (timeSpan: TimeSpan): void => {
      const specifiedDominantTimeSpan = specifyMetricsPieChartComponentDominantTimeSpan(state.component)

      if (!specifiedDominantTimeSpan || specifiedDominantTimeSpan === timeSpan) {
        props.onDominantTimeSpanChanged(timeSpan)
      } else {
        state.timeSpanOnConfirmation = timeSpan
        state.showConfirmationModal = true
      }
    }

    const confirmToConfirmation = (): void => {
      state.showConfirmationModal = false
      if (state.timeSpanOnConfirmation) {
        state.component = clearMetricsPieChartData(state.component)
        props.onDominantTimeSpanChanged(state.timeSpanOnConfirmation)
      }
      state.timeSpanOnConfirmation = null
    }

    const cancelToConfirmation = (): void => {
      state.showConfirmationModal = false
      state.timeSpanOnConfirmation = null
    }

    return {
      state,
      props,
      getError,
      getErrorObject,
      DisplayPatternOptions,
      close,
      onChangeDisplayAs,
      tryToChangeTimeSpan,
      confirmToConfirmation,
      cancelToConfirmation,
      TIME_SPAN_OPTIONS,
    }
  },
})
