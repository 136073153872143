import { ComponentResponseData, extractLastSearchedDate } from 'src/models/api/Report/Component/componentResponseData';
import { TIME_SPAN_DAILY, TimeSpan, isTimeSpanLarger } from 'src/business/timeSpan';
import { ComponentType, internal as componentInternal } from 'src/models/new/component';
import { convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData';
import { buildConditionalStatementProperties } from 'src/models/api/Report/Component/propertyBuilders/buildConditionalStatementProperties';
import { buildBaseComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildBaseComponentProperties';
import {
  MetricsPieChartComponent,
  MetricsPieChartPieElement,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import { formatDate } from 'src/util/datetime';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';

const METRICS_GRAPH_PIE_CHART = componentInternal.METRICS_GRAPH_PIE_CHART;

const searchDominantTimeSpan = (data: ComponentResponseData): TimeSpan => {
  // ビジネスロジック上基準となるTimeSpamを持つべきだが
  // そのフィールドがないためリストに存在する参考値でないメトリクスの最も大きな周期を検索している
  return data.graph_component!.metrics_graph_components.reduce((largestTimeSpan: TimeSpan, el) => {
    return isTimeSpanLarger(el.metrics.time_span, largestTimeSpan) ? el.metrics.time_span : largestTimeSpan;
  }, TIME_SPAN_DAILY);
};

const buildPieElements = (data: ComponentResponseData, closingDate: Date | null): MetricsPieChartPieElement[] => {
  const getValueOfMetrics = (
    obj: { metrics_id: number; metrics: { time_span: string } },
    closingDate: Date,
  ): string | number | null => {
    return (
      data.graph_component!.metrics_values_idx_dt[formatDate(closingDate, SYSTEM_DATE_FORMAT)]?.find(
        (el) => el.metrics_id === obj.metrics_id,
      )?.value ?? null
    );
  };

  return data
    .graph_component!.metrics_graph_components.sort((a, b) => {
      if (!closingDate) {
        return a.registration_order - b.registration_order;
      }
      return Number(getValueOfMetrics(a, closingDate) || 0) - Number(getValueOfMetrics(b, closingDate) || 0);
    })
    .map((el, index) => {
      const metrics = convertFromMetricsResponseData(el.metrics, {});
      if (closingDate) {
        const valueOfMetrics = getValueOfMetrics(el, closingDate);
        metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;
        metrics.closingDate = closingDate;
      }
      const pie = {
        alias: el.disp_name,
        arrangedOrder: index + 1,
        internalOrder: el.registration_order,
        color: el.disp_color,
        metrics,
        conditionalStatements: data.component_conditional_statements
          .filter((statement) => {
            return statement.column_number === el.registration_order;
          })
          .map((statement) =>
            buildConditionalStatementProperties(
              statement,
              data.graph_component!.metrics_value_searched_dates_map ?? null,
            ),
          ),
      };
      return pie;
    });
};

export const buildMetricsPieChartComponentProperties = (
  data: ComponentResponseData,
  referenceDate: Date | null,
): MetricsPieChartComponent => {
  const componentType: ComponentType = METRICS_GRAPH_PIE_CHART;
  const dominantTimeSpan = searchDominantTimeSpan(data);

  const closingDates = data.graph_component!.metrics_value_searched_dates_map ?? null;
  const closingDate = closingDates ? extractLastSearchedDate(closingDates, dominantTimeSpan) : null;

  return {
    ...buildBaseComponentProperties(data, componentType),
    referenceDate: referenceDate,
    displayValueSettings: {
      displayAs: data.graph_component!.disp_content,
      unit: data.graph_component!.unit_disp_name1,
    },
    data: buildPieElements(data, closingDate),
  };
};
