import axios from 'axios';
import { MetricsBreakdownRequestParameters } from 'src/models/api/Metrics/metricsBreakdownRequestParameters';
import { convertFromMetricsBreakdownResponse, MetricsBreakdown } from 'src/models/api/Metrics/metricsBreakdownResponse';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/metrics';

export default {
  async breakdown(params: MetricsBreakdownRequestParameters): Promise<MetricsBreakdown> {
    const url = buildBackendUrl(buildBasePath(), params.id, 'breakdown');
    const { id, ...queryParams } = params;
    const { data: resData } = await axios.get(url, { params: queryParams });
    return convertFromMetricsBreakdownResponse(resData);
  },
};
