
import Vue, { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue'
import { UpdateWordTarget, UpdateWordTargetCategoryIdentifier, updateWordTargetToLabel } from 'src/views/Dashboard/Settings/Reports/ReplaceWords/logics/UpdateWordTarget'
import { Section, sectionToNumberedLabel } from 'src/models/new/section'

export type UpdateWordTargetListRowViewModel = UpdateWordTarget<UpdateWordTargetCategoryIdentifier> & {
  isChecked: boolean
}

type State = {
  sectionNameView: string
  typeView: string
}

const setupState = (root: Vue): State => {
  const state: State = reactive({
    sectionNameView: computed(() => {
      const section = root.$props.row.section as Section
      return sectionToNumberedLabel(section)
    }),
    typeView: computed(() => updateWordTargetToLabel(root.$props.row as UpdateWordTarget<UpdateWordTargetCategoryIdentifier>)),
  })
  return state
}

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<UpdateWordTargetListRowViewModel>,
      required: true,
    },
    onCheckboxChange: {
      type: Function as PropType<(updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>, checked: boolean) => void>,
      required: true,
    },
    onUpdateValueChange: {
      type: Function as PropType<(updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>, value: string) => void>,
      required: true,
    },
  },

  setup(props) {
    const root = getCurrentInstance()!.proxy
    const state = setupState(root)

    const toggleCheckbox = (checked: boolean) => {
      props.onCheckboxChange(props.row, checked)
    }
    const updateValue = (value: string) => {
      props.onUpdateValueChange(props.row, value)
    }

    return {
      props,
      state,
      toggleCheckbox,
      updateValue,
    }
  },
})
