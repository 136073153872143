import { LoginUser } from 'src/models/new/User/loginUser';
import { computed, ComputedRef, getCurrentInstance } from 'vue';

const emptyLoginUser: LoginUser = {
  id: 0,
  company: {
    id: 0,
    name: '',
  },
  email: '',
  familyName: '',
  firstName: '',
  reportAccessGroups: [],
  metricsAccessGroups: [],
  isSuperAdmin: false,
  reportGrantedByAccessGroups: [],
};

export const useLoginUser = () => {
  const vue = getCurrentInstance()!.proxy;
  const loginUserRef: ComputedRef<LoginUser> = computed(() => vue.$store.getters['loginUser/data'] || emptyLoginUser);

  const refreshLoginUser = async (): Promise<void> => {
    // ひとまずensureUserRefreshAndMastersと同じような処理を行っている
    const promises = [vue.$store.dispatch('user/getMeRefresh'), window.master.$promise];
    await Promise.all(promises);
    await vue.$store.dispatch('loginUser/refreshData');
  };

  return {
    loginUserRef,
    refreshLoginUser,
  };
};
