import { MetricsGraphComponentConditionalStatement } from 'src/models/new/ConditionalStatement/metricsGraphComponentConditionalStatement'
import { MetricsGraphComponent, MetricsGraphReferenceForBorderLine } from '../metricsGraphComponent'
import { Metrics } from 'src/models/new/metrics'
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementThresholdReference,
  thresholdValueFromConditionalStatement,
} from 'src/models/new/conditionalStatement'
import { compareWithComparator } from 'src/util/comparator'
import { Component, internal } from 'src/models/new/component'
import { hashedColorFromGraphDecoration } from 'src/models/new/ConditionalStatement/Decorations/graphDecoration'
import { isTimeSpanLarger, TimeSpan } from 'src/business/timeSpan'

const METRICS_GRAPH_BAR_GRAPH = internal.METRICS_GRAPH_BAR_GRAPH

export type MetricsBarGraphBarElement = {
  sequentialOrder: number
  alias: string | null
  color: string
  metrics: Metrics
  conditionalStatements: MetricsGraphComponentConditionalStatement[]
}
export type MetricsBarGraphComponent = MetricsGraphComponent & {
  unit: string | null
  data: {
    bars: MetricsBarGraphBarElement[]
    reference: MetricsGraphReferenceForBorderLine | null
  }
}

export const METRICS_BAR_GRAPH_COMPONENT_MAX_BAR_ELEMENTS = 8

export const isComponentTypeMetricsBarGraph = (component: Component): component is MetricsBarGraphComponent => component.componentType === METRICS_GRAPH_BAR_GRAPH

const DEFAULT_WIDTH = 2
const DEFAULT_HEIGHT = 2

export const constructEmptyMetricsBarGraphComponent = (): MetricsBarGraphComponent => {
  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_GRAPH_BAR_GRAPH,
    abscissa: 0,
    ordinate: 0,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    referenceDate: null,
    name: '',
    unit: null,
    data: {
      bars: [],
      reference: null,
    },
  }
}

const getBaseValue = (
  component: MetricsBarGraphComponent,
  bar: MetricsBarGraphBarElement,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) return bar.metrics.value ?? null
  if (isConditionalStatementBaseReference(conditionalStatement)) {
    return component.data.reference?.metrics.value ?? null
  }
  return baseValueFromConditionalStatement(conditionalStatement)
}

const getThresholdValue = (
  component: MetricsBarGraphComponent,
  _bar: MetricsBarGraphBarElement,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementThresholdReference(conditionalStatement)) {
    return component.data.reference?.metrics.value ?? null
  }
  return thresholdValueFromConditionalStatement(conditionalStatement)
}

const extractPriorTargetDecoration = (component: MetricsBarGraphComponent, sequentialOrder: number): MetricsGraphComponentConditionalStatement | null => {
  const bar = component.data.bars.find(el => el.sequentialOrder === sequentialOrder)
  return bar?.conditionalStatements.sort((a, b) => b.priority - a.priority)
    .reduce((
      found: MetricsGraphComponentConditionalStatement | null,
      conditionalStatement: MetricsGraphComponentConditionalStatement,
    ) => {
      const base = getBaseValue(component, bar, conditionalStatement)
      if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
        return compareWithComparator(base, conditionalStatement.comparator, null)
          ? conditionalStatement
          : null
      }
      const threshold = getThresholdValue(component, bar, conditionalStatement)
      if (base === null || threshold === null) return found
      if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) return found
      return conditionalStatement
    }, null) ?? null
}

export const applyConditionalStatementToMetricsBarGraphComponentColor = (
  component: MetricsBarGraphComponent, sequentialOrder: number): string | null => {
  if (!component.referenceDate) return null
  const decoration = extractPriorTargetDecoration(component, sequentialOrder)?.decoration
  return decoration ? hashedColorFromGraphDecoration(decoration) : null
}

export const labelFromMetricsBarGraphBarElement = (barElement: MetricsBarGraphBarElement): string => {
  return barElement.alias || barElement.metrics.name
}

export const specifyMetricsBarGraphComponentDominantTimeSpan = (component: MetricsBarGraphComponent): TimeSpan | null => {
  return component.data.bars.reduce((dominantTimeSpan: TimeSpan | null, el: MetricsBarGraphBarElement) => {
    if (!el.metrics) return dominantTimeSpan
    const timeSpan = el.metrics!.timeSpan
    if (!dominantTimeSpan) return timeSpan
    return isTimeSpanLarger(timeSpan, dominantTimeSpan) ? timeSpan : dominantTimeSpan
  }, null)
}

export const clearMetricsBarGraphBars = (component: MetricsBarGraphComponent): MetricsBarGraphComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      bars: [],
    },
  }
}
