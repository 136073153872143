import { CompanyViewSetting } from 'src/models/new/companyViewSetting'
import { RecordId } from 'src/util/recordId'

export type CompanyViewSettingResponse = {
  id: RecordId
  home_report_id: number | null
}

export const convertFromCompanyViewSettingResponse = (
  companyViewSetting: CompanyViewSettingResponse
): CompanyViewSetting => {
  return {
    id: companyViewSetting.id,
    homeReportId: companyViewSetting.home_report_id,
  }
}
