
import { computed, defineComponent, PropType, reactive } from 'vue'
import { ComponentLink } from 'src/models/new/Link/componentLink'
import LinkFormModal from 'src/views/Dashboard/Settings/Reports/shared/components/LinkFormModal/LinkFormModal.vue'
import { useComponentLinkCreator } from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkFormModal/composables/useComponentLinkCreator'
import { useComponentLinkUpdater } from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkFormModal/composables/useComponentLinkUpdater'

type State = {
  componentLink: ComponentLink
}

export default defineComponent({
  components: {
    LinkFormModal,
  },
  props: {
    value: {
      type: Object as PropType<ComponentLink>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'failed'],
  setup(props, { emit }) {
    const {
      createComponentLink,
    } = useComponentLinkCreator()
    const create = async(): Promise<void> => await createComponentLink(props.reportId, props.componentId, props.value)

    const {
      updateComponentLink,
    } = useComponentLinkUpdater()
    const update = async(): Promise<void> => await updateComponentLink(props.reportId, props.componentId, props.value)

    const state: State = reactive({
      componentLink: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', { ...value })
        },
      }),
    })

    const onClose = () => emit('close')

    return {
      props,
      state,
      create,
      update,
      onClose,
    }
  },
})
