import { MetricsType, internal as metricsInternal } from 'src/models/new/metrics';
import { TimeSpan } from 'src/business/timeSpan';

const DIRECT_INPUT = metricsInternal.DIRECT_INPUT;
const LOGIMETER = metricsInternal.LOGIMETER;
const LOGIBOARD = metricsInternal.LOGIBOARD;
const CALC = metricsInternal.CALC;
const CROSS = metricsInternal.CROSS;
const SUMMARY = metricsInternal.SUMMARY;
const EXT_CROSS = metricsInternal.EXT_CROSS;
const REFERENCE = metricsInternal.REFERENCE;

const FRONT_METRICS_TYPE_DIRECT_INPUT = DIRECT_INPUT;
const FRONT_METRICS_TYPE_LOGIMETER = LOGIMETER;
const FRONT_METRICS_TYPE_LOGIBOARD = LOGIBOARD;
const FRONT_METRICS_TYPE_CALC = CALC;
const FRONT_METRICS_TYPE_CROSS = CROSS;
const FRONT_METRICS_TYPE_SUMMARY = SUMMARY;
const FRONT_METRICS_TYPE_EXT_CROSS = EXT_CROSS;
const FRONT_METRICS_TYPE_REFERENCE = REFERENCE;

type FrontMetricsType =
  | typeof FRONT_METRICS_TYPE_DIRECT_INPUT
  | typeof FRONT_METRICS_TYPE_LOGIMETER
  | typeof FRONT_METRICS_TYPE_LOGIBOARD
  | typeof FRONT_METRICS_TYPE_CALC
  | typeof FRONT_METRICS_TYPE_CROSS
  | typeof FRONT_METRICS_TYPE_SUMMARY
  | typeof FRONT_METRICS_TYPE_EXT_CROSS
  | typeof FRONT_METRICS_TYPE_REFERENCE;

export type MetricsIndexRequestParameters = {
  name?: string | null;
  logiscope_workplace_id?: number | null;
  metrics_types?: MetricsType[] | null;
  time_spans?: TimeSpan[] | null;
  include_disabled?: boolean;
  exclude_id?: number | null;
  page: number | null;
  ids?: number[] | null;
};

type LegacyMetricsIndexRequestParameters = {
  name?: string | null;
  logiscope_workplace_id?: number | null;
  front_metrics_types?: FrontMetricsType[] | null;
  time_spans?: TimeSpan[] | null;
  include_disabled?: boolean;
  exclude_id?: number | null;
  page: number | null;
  ids?: number[] | null;
};

export const convertMetricsIndexRequestParametersToLegacy = (
  params: MetricsIndexRequestParameters,
): LegacyMetricsIndexRequestParameters => {
  const { metrics_types, ...newParams } = params;
  return metrics_types
    ? { ...newParams, front_metrics_types: metrics_types.map(convertMetricsTypeToFrontMetricsType) }
    : { ...newParams };
};

export const convertMetricsTypeToFrontMetricsType = (metricsType: MetricsType): FrontMetricsType => {
  switch (metricsType) {
    case DIRECT_INPUT:
      return FRONT_METRICS_TYPE_DIRECT_INPUT;
    case LOGIMETER:
      return FRONT_METRICS_TYPE_LOGIMETER;
    case LOGIBOARD:
      return FRONT_METRICS_TYPE_LOGIBOARD;
    case SUMMARY:
      return FRONT_METRICS_TYPE_SUMMARY;
    case CALC:
      return FRONT_METRICS_TYPE_CALC;
    case CROSS:
      return FRONT_METRICS_TYPE_CROSS;
    case EXT_CROSS:
      return FRONT_METRICS_TYPE_EXT_CROSS;
    case REFERENCE:
      return FRONT_METRICS_TYPE_REFERENCE;
  }
};

export const internal = {
  FRONT_METRICS_TYPE_DIRECT_INPUT,
  FRONT_METRICS_TYPE_LOGIMETER,
  FRONT_METRICS_TYPE_LOGIBOARD,
  FRONT_METRICS_TYPE_CALC,
  FRONT_METRICS_TYPE_CROSS,
  FRONT_METRICS_TYPE_SUMMARY,
  FRONT_METRICS_TYPE_EXT_CROSS,
  FRONT_METRICS_TYPE_REFERENCE,
} as const;
