import { ReportAccessGroup } from 'src/models/new/AccessGroup/reportAccessGroup';
import { AccessGroupRole } from 'src/models/new/accessGroup';

export type ReportAccessGroupCreateOuUpdateRequestParameters = {
  name: string;
  role: AccessGroupRole;
  granted_to_access_groups: { id: number }[];
};

export const convertToReportAccessGroupCreateOrUpdateResponse = (
  accessGroup: ReportAccessGroup,
): ReportAccessGroupCreateOuUpdateRequestParameters => {
  return {
    name: accessGroup.name,
    role: accessGroup.role,
    granted_to_access_groups: accessGroup.grantTo.map((el) => ({ id: el.id })),
  };
};
