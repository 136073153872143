import { TimeSpan } from 'src/business/timeSpan';
import { formatValue } from 'src/util/text_decorator';
import { Scaling } from 'src/business/scaling';
import { MetricsPartialInformation } from './metricsPartialInformation';

const DIRECT_INPUT = 'direct_input';
const LOGIMETER = 'logimeter';
const LOGIBOARD = 'logiboard';
const CALC = 'calc';
const CROSS = 'cross';
const SUMMARY = 'summary';
const EXT_CROSS = 'ext_cross';
const REFERENCE = 'reference';

export type MetricsType =
  | typeof DIRECT_INPUT
  | typeof LOGIMETER
  | typeof LOGIBOARD
  | typeof CALC
  | typeof CROSS
  | typeof SUMMARY
  | typeof EXT_CROSS
  | typeof REFERENCE;

export const METRICS_TYPE_LOCAL_WORDS = {
  [DIRECT_INPUT]: '直接入力',
  [LOGIMETER]: 'ロジメーター',
  [LOGIBOARD]: 'ロジボード',
  [CALC]: '四則演算',
  [CROSS]: '集計',
  [SUMMARY]: '期間集計',
  [EXT_CROSS]: 'センター横断',
  [REFERENCE]: '過去値',
};

export const METRICS_TYPE_IMAGE_COLORS = {
  [DIRECT_INPUT]: '3b3838',
  [LOGIMETER]: '000066',
  [LOGIBOARD]: 'b4c7e7',
  [CALC]: '99cc00',
  [CROSS]: '33cccc',
  [SUMMARY]: 'ed7d31',
  [EXT_CROSS]: 'fa1e43',
  [REFERENCE]: 'ffc000',
};

export type Metrics = {
  id: number;
  workplaceId: number;
  metricsType: MetricsType;
  name: string;
  timeSpan: TimeSpan;
  decimalPlaces: number;
  scaling: Scaling;
  unit: string | null;
  isEnabled: boolean;
  closingDate: Date | null;
  value: number | null;
  accessGroupIds: number[];
  updatedAt: Date | null;
};

export const METRICS_MAX_CHAIN = 8;
export const METRICS_ACCESS_GROUP_MAX_COUNT = 10;

export const METRICS_TYPES: MetricsType[] = [
  DIRECT_INPUT,
  LOGIMETER,
  LOGIBOARD,
  CALC,
  CROSS,
  SUMMARY,
  EXT_CROSS,
  REFERENCE,
];

export const metricsToTypeLocalWord = (metrics: Metrics): string => METRICS_TYPE_LOCAL_WORDS[metrics.metricsType];
export const metricsToTypeImageColor = (metrics: Metrics): string => METRICS_TYPE_IMAGE_COLORS[metrics.metricsType];

export const isMetricsReferBorderless = (metrics: Metrics): boolean => {
  return metrics.metricsType === CALC || metrics.metricsType === EXT_CROSS;
};

export const formatMetricsValue = (metrics: Metrics): string => {
  if (metrics.value === null) {
    return '';
  }

  return formatValue(metrics.value, metrics.decimalPlaces, metrics.scaling);
};

export const metricsToPartialInformation = (metrics: Metrics): MetricsPartialInformation => {
  return {
    id: metrics.id,
    metricsType: metrics.metricsType,
    name: metrics.name,
    workplaceId: metrics.workplaceId,
    timeSpan: metrics.timeSpan,
  };
};

export const internal = {
  DIRECT_INPUT,
  LOGIMETER,
  LOGIBOARD,
  CALC,
  CROSS,
  SUMMARY,
  EXT_CROSS,
  REFERENCE,
} as const;

export const isMetricsIndependent = (metrics: Metrics): boolean => {
  return metrics.metricsType === DIRECT_INPUT || metrics.metricsType === LOGIMETER || metrics.metricsType === LOGIBOARD;
};

export const constructEmptyMetrics = (): Metrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: DIRECT_INPUT,
    name: '',
    timeSpan: 'daily',
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    updatedAt: null,
  };
};
