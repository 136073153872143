
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue';
import MetricsTableBaseForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsTableFormModal/MetricsTableBaseForm/index.vue';
import MetricsTableForm, {
  TableDataSetCell,
  EMPTY_CELL_STRUCTURE,
} from 'src/views/Dashboard/Settings/Reports/Form/components/shared/MetricsTableForm/index.vue';
import { vvHasError, vvValidate } from 'src/util/vee_validate';
import {
  MetricsTableComponent,
  MetricsTableComponentTableCell,
  getMaxTableBodyCell,
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { HeaderCell } from 'src/util/dataHeader';
import { transpose } from 'src/util/array';
import { Section } from 'src/models/new/section';
import { getBottomOrdinate, isOverBoundary, isOverlappedWithOther } from 'src/models/new/component';
import componentApi from 'src/apis/masters/component';
import { notifySuccess1 } from 'src/hooks/notificationHook';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INCLUDED_FORBIDDEN_METRICS,
  ERROR_REASON_METRICS_NOT_FOUND,
} from 'src/consts';
import { isRecordIdMeansNotPersisted } from 'src/util/recordId';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useSharedValue } from 'src/composables/useSharedValue';

interface State {
  isLoaded: boolean;
  hasError: boolean;
  showMetricsTableForm: boolean;
  component: MetricsTableComponent;
  isComponentNew: boolean;
  tableDataSet: TableDataSetCell[][];
  modalClass: 'modal-wide3' | 'modal-mid';
  modalTitle: string;
}

const CONFIRM_METRICS_EVENT_KEY = 'confirmSelectMetricsForm';
const CANCEL_METRICS_EVENT_KEY = 'cancelSelectMetricsForm';
const SHOW_SELECT_METRICS_FORM_KEY = 'showSelectMetricsForm';

export default defineComponent({
  components: {
    MetricsTableForm,
    MetricsTableBaseForm,
  },
  props: {
    value: {
      type: Object as PropType<MetricsTableComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isLoaded: false,
      hasError: computed(() => vvHasError(root)),
      showMetricsTableForm: false,
      component: computed({
        get() {
          return props.value;
        },
        set(value) {
          emit('input', value);
        },
      }),
      isComponentNew: computed(() => isRecordIdMeansNotPersisted(state.component.id)),
      tableDataSet: computed({
        get() {
          const headDepth = state.component.headers.head.layout.depth;
          const sideDepth = state.component.headers.side.layout.depth;
          const headBreadth = state.component.headers.head.layout.breadth;
          const sideBreadth = state.component.headers.side.layout.breadth;

          // テーブル左上の空領域を作成
          const tableDataSet: TableDataSetCell[][] = new Array(headDepth).fill(null).map((_) => {
            return new Array(sideDepth).fill(null).map((_) => {
              return { ...EMPTY_CELL_STRUCTURE, isTopHeader: true, isLeftHeader: true };
            });
          });

          const headData = state.component.headers.head.layout.data;
          // 上部ヘッダー領域を作成
          for (let tableRow = 1; tableRow <= headDepth; tableRow++) {
            tableDataSet[tableRow - 1] = tableDataSet[tableRow - 1].concat(
              headData
                .filter((el) => el.level === tableRow)
                .sort((a, b) => a.position - b.position)
                .map((el) => {
                  return {
                    ...EMPTY_CELL_STRUCTURE,
                    isTopHeader: true,
                    label: el.value || '',
                  };
                }),
            );
          }

          const sideData = state.component.headers.side.layout.data;
          // 左部ヘッダー領域を作成
          for (let tableRow = headDepth + 1; tableRow <= headDepth + sideBreadth; tableRow++) {
            tableDataSet[tableRow - 1] = sideData
              .filter((el) => el.position === tableRow - headDepth)
              .sort((a, b) => a.level - b.level)
              .map((el) => {
                return {
                  ...EMPTY_CELL_STRUCTURE,
                  isLeftHeader: true,
                  label: el.value || '',
                };
              });
          }

          // この変数の形状は明確にlistとtableで異なる
          const contents = state.component.data;
          // データ領域を作成
          for (let tableRow = headDepth + 1; tableRow <= headDepth + sideBreadth; tableRow++) {
            // 既に左部ヘッダー領域を作成した際に行ごと配列は作成されているため、空配列の追加は行わない
            for (let tableColumn = sideDepth + 1; tableColumn <= sideDepth + headBreadth; tableColumn++) {
              const cell = contents.find((el) => {
                return el.row === tableRow - headDepth && el.column === tableColumn - sideDepth;
              });
              tableDataSet[tableRow - 1].push({
                ...EMPTY_CELL_STRUCTURE,
                metrics: cell?.metrics ?? null,
                conditionalStatements: cell?.conditionalStatements || [],
              });
            }
          }

          return tableDataSet;
        },
        set(values: TableDataSetCell[][]) {
          const headerHeadDepth = values.filter((el) => el[0].isTopHeader).length;
          const headerSideDepth = values[0].filter((el) => el.isLeftHeader).length;
          const headerHeadTableDataSetCells = values.slice(0, headerHeadDepth).map((el) => el.slice(headerSideDepth));
          const headerSideTableDataSetCells = values.slice(headerHeadDepth).map((el) => el.slice(0, headerSideDepth));
          const dataTableDataSetCells = values.slice(headerHeadDepth).map((el) => el.slice(headerSideDepth));

          // 上部ヘッダー部分の変更を反映
          const newHeaderHeadData: HeaderCell[] = headerHeadTableDataSetCells
            .map((row, rowIndex) => {
              const level = rowIndex + 1;
              let span = 1;
              return row
                .reverse()
                .map((tableDataSetCell, reverseColumnIndex) => {
                  const position = row.length - reverseColumnIndex;
                  // 空文字の場合にnullへ変換する
                  const value = tableDataSetCell.label || null;
                  const isNoSpan = !value && position === 2;
                  const headerCell = {
                    level: level,
                    position: position,
                    span: !isNoSpan ? span : 0,
                    value: value,
                  };
                  if (isNoSpan) {
                    span++;
                  }
                  return headerCell;
                })
                .sort((a, b) => a.position - b.position);
            })
            .flat();

          // 左部ヘッダー部分の変更を反映
          const newHeaderSideData: HeaderCell[] = transpose(headerSideTableDataSetCells)
            .map((row, rowIndex) => {
              const level = rowIndex + 1;
              let span = 1;
              return row
                .reverse()
                .map((tableDataSetCell, reverseColumnIndex) => {
                  const position = row.length - reverseColumnIndex;
                  // 空文字の場合にnullへ変換する
                  const value = tableDataSetCell.label || null;
                  const isNoSpan = !value && position === 2;
                  const headerCell = {
                    level: level,
                    position: position,
                    span: !isNoSpan ? span : 0,
                    value: value,
                  };
                  if (isNoSpan) {
                    span++;
                  }
                  return headerCell;
                })
                .sort((a, b) => a.position - b.position);
            })
            .flat();

          // データ部分の変更を反映
          const newData: MetricsTableComponentTableCell[] = [];
          dataTableDataSetCells.forEach((row, rowIndex) => {
            const rowNumber = rowIndex + 1;
            row.forEach((cell, columnIndex) => {
              const columnNumber = columnIndex + 1;
              const currentObject = state.component.data.find((el) => {
                return el.row === rowNumber && el.column === columnNumber;
              });

              const newDataElement = {
                row: rowNumber,
                column: columnNumber,
                metrics: null,
                conditionalStatements: [],
              };
              if (cell.metrics) {
                newData.push({
                  ...newDataElement,
                  metrics: cell.metrics,
                  conditionalStatements: currentObject?.conditionalStatements || [],
                });
              }
            });
          });

          state.component = {
            ...state.component,
            headers: {
              head: {
                ...state.component.headers.head,
                layout: {
                  depth: newHeaderHeadData.reduce((max, el) => Math.max(max, el.level), 0),
                  breadth: newHeaderHeadData.reduce((max, el) => Math.max(max, el.position), 0),
                  data: newHeaderHeadData,
                },
              },
              side: {
                ...state.component.headers.side,
                layout: {
                  depth: newHeaderSideData.reduce((max, el) => Math.max(max, el.level), 0),
                  breadth: newHeaderSideData.reduce((max, el) => Math.max(max, el.position), 0),
                  data: newHeaderSideData,
                },
              },
            },
            data: newData,
          };
        },
      }),
      modalClass: computed(() => (state.showMetricsTableForm ? 'modal-wide3' : 'modal-mid')),
      modalTitle: computed(() => {
        if (state.showMetricsTableForm) {
          return 'メトリクスの選択';
        }
        return `表の${state.isComponentNew ? '追加' : '編集'}`;
      }),
    });

    async function openTableEditModal(): Promise<void> {
      if (!(await vvValidate(root))) {
        return;
      }
      state.showMetricsTableForm = true;
    }

    function openFirstModal(): void {
      state.showMetricsTableForm = false;
    }

    const submit = async (): Promise<void> => {
      // 配置場所が重なる場合や新しく生成した場合は一番下に配置する
      if (
        isOverlappedWithOther(state.component, props.section) ||
        isOverBoundary(state.component) ||
        !state.component.id
      ) {
        state.component.ordinate = getBottomOrdinate(props.section);
      }
      const component = {
        ...state.component,
        sectionId: props.section.id,
      };
      const operationWord = state.isComponentNew ? '追加' : '更新';
      try {
        if (state.isComponentNew) {
          const { id } = await componentApi.create(props.reportId, component);
          component.id = id;
        } else {
          await componentApi.update(props.reportId, component);
        }
        state.component = structuredClone(component);
        emit('updated');
        notifySuccess1(root, `コンポーネントを${operationWord}しました。`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          if (errRes.reason === ERROR_REASON_INCLUDED_FORBIDDEN_METRICS) {
            emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
          } else {
            const msg = 'アクセスする権限がありません。管理者にお問合せください。';
            emit('shouldReport', ERROR_GROUP_USER, msg, err);
          }
        } else if (errStatus === 404) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          emit(
            'shouldReport',
            ERROR_GROUP_USER,
            '選択したメトリクスは存在しません。ページを更新し、再度お試しください。',
            err,
          );
        } else if (errStatus === 409) {
          const msg = 'コンポーネントの追加に失敗しました。レポートが編集されています。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else {
          const msg = `コンポーネントの${operationWord}に失敗しました。管理者に連絡してください。`;
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err);
        }
      }
    };

    const close = (): void => {
      emit('close');
    };

    const { value: showSelectMetricsForm } = useSharedValue<boolean>(SHOW_SELECT_METRICS_FORM_KEY);
    const { triggerer: confirmEventTriggerer } = useSimpleEvent(CONFIRM_METRICS_EVENT_KEY);
    const { triggerer: cancelEventTriggerer } = useSimpleEvent(CANCEL_METRICS_EVENT_KEY);

    const buttonLabel = computed(() => (showSelectMetricsForm.value ? '設定' : '更新'));

    const confirm = (): void => {
      if (showSelectMetricsForm.value) {
        confirmEventTriggerer.trigger();
      } else {
        submit();
      }
    };

    const cancel = (): void => {
      if (showSelectMetricsForm.value) {
        cancelEventTriggerer.trigger();
      } else {
        close();
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      openFirstModal();
      state.isLoaded = true;
    });

    return {
      state,
      props,
      getMaxTableBodyCell,
      openTableEditModal,
      close,
      CONFIRM_METRICS_EVENT_KEY,
      CANCEL_METRICS_EVENT_KEY,
      SHOW_SELECT_METRICS_FORM_KEY,
      buttonLabel,
      confirm,
      cancel,
    };
  },
});
