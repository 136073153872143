import type { LogiCoredataWorkplace } from 'src/models/new/logiCoredataWorkplace';
import logiCoredataWorkplaceApi from 'src/apis/masters/logiCoredataWorkplace';
import { generateInjectionKey } from './logics/generateInjectionKey';
import { useAsyncResources, useAsyncResourcesProvider } from './useAsyncResources';

type UseLogiCoredataWorkplacesProviderArgs = {
  onLoad?: () => unknown;
};
type UseLogiCoredataWorkplacesProviderResult = {
  logiCoredataWorkplacesRef: ReturnType<typeof useAsyncResources<LogiCoredataWorkplace>>['resources'];
};

type UseLogiCoredataWorkplacesResult = {
  logiCoredataWorkplacesRef: ReturnType<typeof useAsyncResources<LogiCoredataWorkplace>>['resources'];
};

const key = generateInjectionKey('logiCoredataWorkplaces');

const fetchLogiCoredataWorkplaces = async () => {
  const result = await logiCoredataWorkplaceApi.index();
  return result.sort((a: LogiCoredataWorkplace, b: LogiCoredataWorkplace) => (a.id > b.id ? 1 : -1));
};

export const useLogiCoredataWorkplacesProvider = ({
  onLoad,
}: UseLogiCoredataWorkplacesProviderArgs = {}): UseLogiCoredataWorkplacesProviderResult => {
  const { resources: logiCoredataWorkplacesRef } = useAsyncResourcesProvider<LogiCoredataWorkplace>({
    key,
    fetcher: fetchLogiCoredataWorkplaces,
    onLoad,
  });

  return {
    logiCoredataWorkplacesRef,
  };
};

export const useLogiCoredataWorkplaces = (): UseLogiCoredataWorkplacesResult => {
  const { resources: logiCoredataWorkplacesRef } = useAsyncResources<LogiCoredataWorkplace>({ key });

  return {
    logiCoredataWorkplacesRef,
  };
};
