import { ReportLink } from 'src/models/new/Link/reportLink'
import {
  LinkUpdateRequestParameters,
  convertToLinkUpdateRequestParameters,
} from 'src/models/api/Link/linkUpdateRequestParameters'

export type ReportLinkUpdateRequestParameters = LinkUpdateRequestParameters

export const convertToReportLinkUpdateRequestParameters = (link: ReportLink): ReportLinkUpdateRequestParameters => {
  return convertToLinkUpdateRequestParameters(link)
}
