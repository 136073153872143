
import { METRICS_MAX_CHAIN } from 'src/models/new/metrics'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {
      METRICS_MAX_CHAIN,
    }
  },
})
