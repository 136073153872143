import axios from 'axios';
import { DataSourceKey } from 'src/models/dataSourceKey';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/data_source_keys';

export default {
  async index(): Promise<DataSourceKey[]> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return resData;
  },
};
