// アプリケーションにおけるアイコン種別の論理名と対応するクラスを紐づけ、変換するモジュール
// 現状アイコンのコードとクラス名が一致しているので使わなくても表示されるが
// Icon型はアプリケーションのアイコン種別を表す型であり、クラス名とは別物として扱うべきとして作成した
const SUN = 'fa-sun'
const CLOUD_SUN = 'fa-cloud-sun'
const CLOUD = 'fa-cloud'
const CLOUD_RAIN = 'fa-cloud-rain'
const CLOUD_SHOWERS_HEAVY = 'fa-cloud-showers-heavy'
const ARROW_UP = 'fa-arrow-up'
const ARROW_RIGHT = 'fa-arrow-right'
const ARROW_DOWN = 'fa-arrow-down'
const ARROW_LEFT = 'fa-arrow-left'
const CIRCLE = 'fa-circle'
const TRIANGLE = 'fa-triangle'
const TIMES = 'fa-times'
const QUESTION = 'fa-question'
const EXCLAMATION = 'fa-exclamation'
const YEN = 'fa-yen-sign'
const BOXES = 'fa-boxes'
const AWARD = 'fa-award'
const CUBE = 'fa-cube'
const USER = 'fa-user'
const CLOCK = 'fa-clock'
const PERCENTAGE = 'fa-percentage'

export type Icon = typeof SUN | typeof CLOUD_SUN | typeof CLOUD | typeof CLOUD_RAIN |
typeof CLOUD_SHOWERS_HEAVY | typeof ARROW_UP | typeof ARROW_RIGHT | typeof ARROW_DOWN |
typeof ARROW_LEFT | typeof CIRCLE | typeof TRIANGLE | typeof TIMES | typeof QUESTION |
typeof EXCLAMATION | typeof YEN | typeof BOXES | typeof AWARD | typeof CUBE |
typeof USER | typeof CLOCK | typeof PERCENTAGE

export const iconClasses: Record<Icon, string> = {
  [SUN]: 'fa-sun',
  [CLOUD_SUN]: 'fa-cloud-sun',
  [CLOUD]: 'fa-cloud',
  [CLOUD_RAIN]: 'fa-cloud-rain',
  [CLOUD_SHOWERS_HEAVY]: 'fa-cloud-showers-heavy',
  [ARROW_UP]: 'fa-arrow-up',
  [ARROW_RIGHT]: 'fa-arrow-right',
  [ARROW_DOWN]: 'fa-arrow-down',
  [ARROW_LEFT]: 'fa-arrow-left',
  [CIRCLE]: 'fa-circle',
  [TRIANGLE]: 'fa-triangle',
  [TIMES]: 'fa-times',
  [QUESTION]: 'fa-question',
  [EXCLAMATION]: 'fa-exclamation',
  [YEN]: 'fa-yen-sign',
  [BOXES]: 'fa-boxes',
  [AWARD]: 'fa-award',
  [CUBE]: 'fa-cube',
  [USER]: 'fa-user',
  [CLOCK]: 'fa-clock',
  [PERCENTAGE]: 'fa-percentage',
}

export const classFromIcon = (icon: Icon) => {
  return iconClasses[icon]
}
