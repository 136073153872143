import { RecordId } from 'src/util/recordId'
import { CompanyViewSettingResponseData } from '../api/companyViewSettingResponseData'

export type CompanyViewSetting = {
  id: RecordId
  homeReportId: RecordId | null
}

export const convertFromCompanyViewSettingResponseData = (data: CompanyViewSettingResponseData): CompanyViewSetting => {
  return {
    id: data.id,
    homeReportId: data.home_report_id,
  }
}
