
import Vue, { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import {
  UpdateMetricsTarget,
  UpdateMetricsTargetCategoryIdentifier,
  updateMetricsTargetToLabel,
} from 'src/views/Dashboard/Settings/Reports/ReplaceMetrics/logics/UpdateMetricsTarget';
import { Section, sectionToNumberedLabel } from 'src/models/new/section';
import { timeSpanToLocalWord } from 'src/business/timeSpan';

export type UpdateMetricsTargetListRowViewModel = UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier> & {
  isChecked: boolean;
  originalWorkplaceName: string;
  updateWorkplaceName: string;
};

type State = {
  sectionNameView: string;
  typeView: string;
};

const setupState = (root: Vue): State => {
  const state: State = reactive({
    sectionNameView: computed(() => {
      const section = root.$props.row.section as Section;
      return sectionToNumberedLabel(section);
    }),
    typeView: computed(() =>
      updateMetricsTargetToLabel(root.$props.row as UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>),
    ),
  });
  return state;
};

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<UpdateMetricsTargetListRowViewModel>,
      required: true,
    },
    onCheckboxChange: {
      type: Function as PropType<
        (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>, checked: boolean) => void
      >,
      required: true,
    },
    onMetricsSelectClicked: {
      type: Function as PropType<(updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>) => void>,
      required: true,
    },
  },

  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);
    const toggleCheckbox = (checked: boolean) => {
      props.onCheckboxChange(props.row, checked);
    };
    const selectMetrics = () => {
      props.onMetricsSelectClicked(props.row);
    };

    return {
      props,
      state,
      timeSpanToLocalWord,
      toggleCheckbox,
      selectMetrics,
    };
  },
});
