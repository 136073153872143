import { MetricsGraphComponentConditionalStatement } from 'src/models/new/ConditionalStatement/metricsGraphComponentConditionalStatement'
import { MetricsGraphComponent } from '../metricsGraphComponent'
import { Metrics } from 'src/models/new/metrics'
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  thresholdValueFromConditionalStatement
} from 'src/models/new/conditionalStatement'
import { compareWithComparator } from 'src/util/comparator'
import { Component, internal } from 'src/models/new/component'
import { hashedColorFromGraphDecoration } from 'src/models/new/ConditionalStatement/Decorations/graphDecoration'
import { isTimeSpanLarger, TimeSpan } from 'src/business/timeSpan'

const METRICS_GRAPH_PIE_CHART = internal.METRICS_GRAPH_PIE_CHART

const NUM = 'num'
const RATE = 'rate'
const HIDDEN = 'hidden'

export type MetricsPieChartDisplayPattern = typeof NUM | typeof RATE | typeof HIDDEN
type DisplayValueSettings = {
  displayAs: MetricsPieChartDisplayPattern
  unit: string | null
}
export type MetricsPieChartPieElement = {
  alias: string | null
  arrangedOrder: number
  internalOrder: number
  color: string
  metrics: Metrics
  conditionalStatements: MetricsGraphComponentConditionalStatement[]
}
export type MetricsPieChartComponent = MetricsGraphComponent & {
  displayValueSettings: DisplayValueSettings
  data: MetricsPieChartPieElement[]
}

export const METRICS_PIE_CHART_COMPONENT_DISPLAY_PATTERN_LOCAL_WORDS = {
  [NUM]: '実数値',
  [RATE]: '割合',
  [HIDDEN]: '非表示',
}

export const METRICS_PIE_CHART_COMPONENT_MAX_PIE_ELEMENTS = 8

export const isComponentTypeMetricsPieChart = (component: Component): component is MetricsPieChartComponent => {
  return component.componentType === METRICS_GRAPH_PIE_CHART
}

export const constructEmptyMetricsPieChartComponent = (): MetricsPieChartComponent => {
  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_GRAPH_PIE_CHART,
    abscissa: 0,
    ordinate: 0,
    width: 2,
    height: 4,
    referenceDate: null,
    name: '',
    displayValueSettings: {
      displayAs: 'num',
      unit: null,
    },
    data: [],
  }
}

// 使用していない変数があるが、他のコンポーネントとインターフェースを揃えている
const getBaseValue = (
  _component: MetricsPieChartComponent,
  pie: MetricsPieChartPieElement,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) return pie.metrics.value ?? null
  return baseValueFromConditionalStatement(conditionalStatement)
}

const getThresholdValue = (
  _component: MetricsPieChartComponent,
  _pie: MetricsPieChartPieElement,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  return thresholdValueFromConditionalStatement(conditionalStatement)
}

const extractPriorTargetDecoration = (component: MetricsPieChartComponent, internalOrder: number): MetricsGraphComponentConditionalStatement | null => {
  const pie = component.data.find(el => el.internalOrder === internalOrder)
  return pie?.conditionalStatements.sort((a, b) => b.priority - a.priority)
    .reduce((found: MetricsGraphComponentConditionalStatement | null, conditionalStatement: MetricsGraphComponentConditionalStatement) => {
      if (found) return found
      const base = getBaseValue(component, pie, conditionalStatement)
      if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
        return compareWithComparator(base, conditionalStatement.comparator, null)
          ? conditionalStatement
          : null
      }
      const threshold = getThresholdValue(component, pie, conditionalStatement)
      if (base === null || threshold === null) return found
      if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) return found
      return conditionalStatement
    }, null) ?? null
}

export const applyConditionalStatementToPieChartComponentColor = (component: MetricsPieChartComponent, internalOrder: number): string | null => {
  if (!component.referenceDate) return null
  const decoration = extractPriorTargetDecoration(component, internalOrder)?.decoration
  return decoration ? hashedColorFromGraphDecoration(decoration) : null
}

export const isMetricsPieChartComponentDisplayValueAsNumber = (component: MetricsPieChartComponent): boolean => {
  return component.displayValueSettings.displayAs === NUM
}
export const isMetricsPieChartComponentDisplayValueAsRate = (component: MetricsPieChartComponent): boolean => {
  return component.displayValueSettings.displayAs === RATE
}
export const isMetricsPieChartComponentDisplayValueAsHidden = (component: MetricsPieChartComponent): boolean => {
  return component.displayValueSettings.displayAs === HIDDEN
}

export const labelFromMetricsPieChartPieElement = (pieElement: MetricsPieChartPieElement): string => {
  return pieElement.alias || pieElement.metrics.name
}

export const specifyMetricsPieChartComponentDominantTimeSpan = (component: MetricsPieChartComponent): TimeSpan | null => {
  return component.data.reduce((dominantTimeSpan: TimeSpan | null, el: MetricsPieChartPieElement) => {
    if (!el.metrics) return dominantTimeSpan
    const timeSpan = el.metrics!.timeSpan
    if (!dominantTimeSpan) return timeSpan
    return isTimeSpanLarger(timeSpan, dominantTimeSpan) ? timeSpan : dominantTimeSpan
  }, null)
}

export const clearMetricsPieChartData = (component: MetricsPieChartComponent): MetricsPieChartComponent => {
  return {
    ...component,
    data: [],
  }
}
