import { Metrics, MetricsType, internal } from 'src/models/new/metrics';
import {
  TIME_SPAN_DAILY,
  TIME_SPAN_WEEKLY,
  TIME_SPAN_MONTHLY,
  TIME_SPAN_YEARLY,
  TimeSpan,
} from 'src/business/timeSpan';
import {
  AGGREGATE_FUNCTION_COUNT,
  AGGREGATE_FUNCTION_LOCAL_WORDS,
  AGGREGATE_FUNCTION_SUM,
  AggregateFunction,
} from 'src/business/aggregateFunction';
import { DayOfWeek } from 'src/util/week';

const DIRECT_INPUT = internal.DIRECT_INPUT;
const LOGIMETER = internal.LOGIMETER;
const LOGIBOARD = internal.LOGIBOARD;
const CALC = internal.CALC;
const SUMMARY = internal.SUMMARY;
const CROSS = internal.CROSS;
const EXT_CROSS = internal.EXT_CROSS;

type WeekDaySettings = Record<DayOfWeek, boolean>;
export type SummaryMetrics = Metrics & {
  targetMetricsId: number;
  aggrFunc: AggregateFunction;
  isZeroIncluded: boolean;
  weekDays: WeekDaySettings;
};

export const isMetricsSummaryMetrics = (metrics: Metrics): metrics is SummaryMetrics => {
  return metrics.metricsType === SUMMARY;
};

export const constructEmptySummaryMetrics = (): SummaryMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: SUMMARY,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    targetMetricsId: 0,
    aggrFunc: AGGREGATE_FUNCTION_SUM,
    isZeroIncluded: false,
    weekDays: {
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
    },
    updatedAt: null,
  };
};

export const SUMMARY_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS: Record<AggregateFunction, string> = Object.fromEntries(
  Object.keys(AGGREGATE_FUNCTION_LOCAL_WORDS).map((el) => {
    if (el === AGGREGATE_FUNCTION_COUNT) {
      return [el as AggregateFunction, '集計対象日数'];
    }
    return [el as AggregateFunction, AGGREGATE_FUNCTION_LOCAL_WORDS[el as AggregateFunction]];
  }),
) as Record<AggregateFunction, string>;

const SUMMARY_METRICS_REFERABLE_METRICS_TYPES = [DIRECT_INPUT, LOGIMETER, LOGIBOARD, CALC, SUMMARY, CROSS, EXT_CROSS];

export const getSummaryMetricsReferableMetricsTypes = (): MetricsType[] => SUMMARY_METRICS_REFERABLE_METRICS_TYPES;

export const availableTargetTimeSpanFromSummaryMetrics = (metrics: SummaryMetrics): TimeSpan => {
  switch (metrics.timeSpan) {
    case TIME_SPAN_WEEKLY:
      return TIME_SPAN_DAILY;
    case TIME_SPAN_MONTHLY:
      return TIME_SPAN_DAILY;
    case TIME_SPAN_YEARLY:
      return TIME_SPAN_MONTHLY;
    default:
      console.error(`invalid timeSpan ${metrics.timeSpan} for SummaryMetrics.`, metrics);
      return TIME_SPAN_DAILY;
  }
};
