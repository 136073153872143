import { internal } from 'src/models/new/accessGroup';
import { AccessGroupResponseData } from 'src/models/api/AccessGroup/accessGroupResponseData';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';

const METRICS = internal.METRICS;

export type MetricsAccessGroupResponseData = AccessGroupResponseData;

export const convertFromMetricsAccessGroupResponseData = (
  response: MetricsAccessGroupResponseData,
): MetricsAccessGroup => {
  return {
    id: response.id,
    name: response.name,
    type: METRICS,
    role: response.role,
    searchId: response.search_id,
  };
};
