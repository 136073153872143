
import { setPageName } from 'src/hooks/displayPageNameHook';
import reportAccessGroupApi from 'src/apis/masters/reportAccessGroup';
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { vvGetError, vvGetErrorObject, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import { requestTimeout } from 'src/util/request_animation_frame';
import { ERROR_REASON_IN_USE } from 'src/consts';
import { ReportAccessGroup, constructEmptyReportAccessGroup } from 'src/models/new/AccessGroup/reportAccessGroup';
import {
  AccessGroupRole,
  isAccessGroupAdmin,
  isAccessGroupManager,
  ACCESS_GROUP_ROLE_NAMES,
  USER_SELECTABLE_ACCESS_GROUP_ROLES,
  roleNameFromAccessGroup,
  GRANTABLE_ACCESS_GROUP_ROLES,
} from 'src/models/new/accessGroup';
import { getTemporaryRecordId, isRecordIdValid } from 'src/util/recordId';
import InputError from 'src/components/InputError.vue';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';
import GrantToInput from 'src/views/Dashboard/Settings/ReportAccessGroups/components/GrantToInput.vue';

interface State {
  pageName: string | null;
  userId: number | null;
  showSaveModal: boolean;
  showDeleteModal: boolean;

  hasError: boolean;
  validations: Record<string, object>;

  reportAccessGroups: ReportAccessGroup[];

  selectedReportAccessGroup: ReportAccessGroup | null;
  selectedReportAccessGroupIsNew: boolean;
  roleOptions: { value: AccessGroupRole; label: string }[];

  hasPageAccessRole: boolean;
  isSaving: boolean;
}

const setupState = (root: Vue): State => {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    reportAccessGroups: [],
    showSaveModal: false,
    selectedReportAccessGroup: null,
    selectedReportAccessGroupIsNew: computed(() => {
      return !!state.selectedReportAccessGroup && !isRecordIdValid(state.selectedReportAccessGroup.id);
    }),
    roleOptions: computed(() => {
      const availableRoles =
        !!state.selectedReportAccessGroup && state.selectedReportAccessGroup.grantTo.length > 0
          ? GRANTABLE_ACCESS_GROUP_ROLES
          : USER_SELECTABLE_ACCESS_GROUP_ROLES;
      return Object.keys(ACCESS_GROUP_ROLE_NAMES)
        .filter((el) => availableRoles.includes(el as AccessGroupRole))
        .map((el) => ({ value: el as AccessGroupRole, label: ACCESS_GROUP_ROLE_NAMES[el as AccessGroupRole] }));
    }),
    validations: {
      name: { required: true, max: 20 },
      role: { required: true },
    },
    hasError: computed(() => vvHasError(root)),
    showDeleteModal: false,
    hasPageAccessRole: true,
    isSaving: false,
  });

  return state;
};

const ITEM_PER_PAGE = 50;

export default defineComponent({
  components: {
    InputError,
    GrantToInput,
    PaginationContainer: usePaginationContainer<ReportAccessGroup>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'レポートグループ');
    const state = setupState(root);

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);
    const clearErrors = (): void => {
      vvReset(root);
    };

    async function getList(page: number = 1): Promise<void> {
      try {
        const response = await reportAccessGroupApi.index();
        state.reportAccessGroups = response.result;
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    const createNewReportAccessGroup = (): void => {
      const reportAccessGroup = constructEmptyReportAccessGroup();
      reportAccessGroup.id = getTemporaryRecordId();
      openSaveModal(reportAccessGroup);
    };

    const openSaveModal = (reportAccessGroup: ReportAccessGroup): void => {
      clearErrors();
      state.selectedReportAccessGroup = reactive(structuredClone(reportAccessGroup));
      state.showSaveModal = true;
    };

    const closeSaveModal = (): void => {
      clearErrors();
      state.showSaveModal = false;
    };

    async function saveItem(): Promise<void> {
      // 二重処理防止
      if (state.isSaving) {
        return;
      }
      state.isSaving = true;
      requestTimeout(() => {
        state.isSaving = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      try {
        if (state.selectedReportAccessGroupIsNew) {
          // create処理
          await reportAccessGroupApi.create(state.selectedReportAccessGroup!);
          closeSaveModal();
          notifySuccess1(root, 'レポートグループを作成しました');
        } else {
          // update処理
          await reportAccessGroupApi.update(state.selectedReportAccessGroup!.id, state.selectedReportAccessGroup!);
          closeSaveModal();
          notifySuccess1(root, 'レポートグループを更新しました');
        }
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
        } else {
          const msg = '保存に失敗しました。管理者に連絡してください。';
          notifyError1(root, msg, { err });
        }
      } finally {
        await getList();
      }
    }

    const openDeleteModal = (reportAccessGroup: ReportAccessGroup): void => {
      state.selectedReportAccessGroup = reportAccessGroup;
      state.showDeleteModal = true;
    };

    const closeDeleteModal = (): void => {
      state.showDeleteModal = false;
    };

    const deleteItem = async (): Promise<void> => {
      try {
        await reportAccessGroupApi.destroy(state.selectedReportAccessGroup!.id);
        await getList();
        closeDeleteModal();
        // TODO: 要検討、どのレポートグループを削除したかも表示した方が良い
        notifySuccess1(root, 'レポートグループを削除しました');
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_IN_USE) {
          const msg = 'すでに使われているレポートグループです。削除できません。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            'レポートグループの削除に失敗しました。管理者に連絡してください。' +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    onMounted(async () => {
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);
      await getList();
    });

    return {
      ITEM_PER_PAGE,
      state,
      getError,
      getErrorObject,
      createNewReportAccessGroup,
      openSaveModal,
      closeSaveModal,
      isAccessGroupAdmin,
      roleNameFromAccessGroup,
      saveItem,
      deleteItem,
      openDeleteModal,
      closeDeleteModal,
      isAccessGroupManager,
    };
  },
});
