import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { LoginUser } from 'src/models/new/User/loginUser';
import userApi from 'src/apis/user';
import { getPromiseOfGetMe, setPromiseOfGetMe } from 'src/store/dataFetchCaches/promiseOfGetMe';
import { LoginUserResponseData, convertFromLoginUserResponseData } from 'src/models/api/loginUserResponseData';

type State = LoginUser;

// the definition of initial state is required.
const state: State = {
  id: 0,
  company: {
    id: 0,
    name: '',
  },
  email: '',
  familyName: '',
  firstName: '',
  reportAccessGroups: [],
  metricsAccessGroups: [],
  isSuperAdmin: false,
  reportGrantedByAccessGroups: [],
};

type GetData = {
  data: (state: State) => LoginUser;
};
const getters: GetterTree<State, any> & GetData = {
  data: (state) => structuredClone(state),
};

const FETCH_DATA = 'fetchData';
const REFRESH_DATA = 'refreshData';

type FetchData = {
  [FETCH_DATA]: (injectee: ActionContext<State, any>) => Promise<void>;
};
type RefreshData = {
  [REFRESH_DATA]: (injectee: ActionContext<State, any>) => Promise<void>;
};

const actions: ActionTree<State, any> & FetchData & RefreshData = {
  [FETCH_DATA]: async ({ commit }) => {
    if (!getPromiseOfGetMe()) {
      setPromiseOfGetMe(userApi.getMe());
    }

    getPromiseOfGetMe()!.then(({ data }) => {
      commit(SET_DATA, { data });
    });
  },
  [REFRESH_DATA]: async ({ commit }) => {
    setPromiseOfGetMe(userApi.getMe());

    getPromiseOfGetMe()!.then(({ data }) => {
      commit(SET_DATA, { data });
    });
  },
};

const SET_DATA = 'setData';

type SetDataPayload = { data: LoginUserResponseData };
type SetData = {
  [SET_DATA]: (state: State, payload: SetDataPayload) => void;
};

const mutations: MutationTree<State> & SetData = {
  [SET_DATA](state, { data }) {
    Object.assign(state, convertFromLoginUserResponseData(data));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
