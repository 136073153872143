import { useFormModal } from 'src/composables/useFormModal'
import { ReportLink, constructEmptyReportLink } from 'src/models/new/Link/reportLink'

export const useReportLinkFormModal = () => {
  const { isFormModalShown, formTargetRef, openFormModalWithValue, closeFormModal } = useFormModal<ReportLink>()
  const formTargetReportLinkRef = formTargetRef

  const createNewReportLink = () => {
    openFormModalWithValue(constructEmptyReportLink())
  }

  return {
    isFormModalShown,
    createNewReportLink,
    formTargetReportLinkRef,
    openFormModalWithValue,
    closeFormModal,
  }
}
