/**
 * オブジェクトのキーと値が全て一致するかを判定する
 * ただし、値がオブジェクトや関数の場合には対応しない
 */
export const isSameObjects = (a: Record<any, unknown>, b: Record<any, unknown>): boolean => {
  const aKeys = Object.keys(a) as (keyof typeof a)[];
  for (const k of aKeys) {
    if (a[k] !== b[k]) {
      return false;
    }
  }
  const bKeys = Object.keys(b) as (keyof typeof b)[];
  for (const k of bKeys) {
    if (b[k] !== a[k]) {
      return false;
    }
  }
  return true;
};

const isDate = (subject: unknown): subject is Date => Object.prototype.toString.call(subject) === '[object Date]';

export const isActuallyObject = (subject: unknown): subject is Record<any, unknown> =>
  typeof subject === 'object' && subject !== null && !isDate(subject) && !Array.isArray(subject);

// オブジェクトを複製する
// structuredCloneと異なり、関数なども複製することができる
export const deepClone = <T extends Record<any, any>>(obj: T): T => {
  const keys = Object.keys(obj) as (keyof T)[];
  const clone = {} as T;
  keys.forEach((key) => {
    const target = obj[key];
    // typeof(null) is 'object'
    if (!isActuallyObject(target) && !Array.isArray(target)) {
      // undefined, null, string, number, boolean, symbol, function, bigint, Date
      clone[key] = target;
    } else if (Array.isArray(target)) {
      // array
      clone[key] = [...target.map((el: unknown) => (isActuallyObject(el) ? deepClone(el) : el))] as T[keyof T];
    } else {
      // object
      clone[key] = deepClone(target as object) as T[keyof T];
    }
  });

  return clone;
};

export const deepMerge = <T extends Record<any, any>, S extends Record<any, any>>(a: T, b: S): T & S => {
  const merged: Partial<T & S> = deepClone(a);
  const bKeys = Object.keys(b) as Array<Extract<keyof S, string>>;
  bKeys.forEach((k) => {
    if (merged[k] === undefined) {
      if (isActuallyObject(b[k])) {
        merged[k] = deepClone(b[k] as object) as (T & S)[Extract<keyof S, string>];
      } else if (Array.isArray(b[k])) {
        merged[k] = [...(b[k] as any[])] as (T & S)[Extract<keyof S, string>];
      } else {
        merged[k] = b[k] as (T & S)[Extract<keyof S, string>];
      }
    } else if (isActuallyObject(merged[k]) && isActuallyObject(b[k])) {
      // merged[k] & b[k] both: object
      merged[k] = deepMerge(merged[k] as object, b[k] as object) as (T & S)[Extract<keyof S, string>];
    } else if (Array.isArray(merged[k]) && Array.isArray(b[k])) {
      // merged[k] & b[k]: array
      merged[k] = [
        ...(b[k] as any[]).map((el, index) => {
          if ((merged[k] as any[])[index]) {
            return isActuallyObject(el) ? deepMerge((merged[k] as any[])[index], el) : el;
          } else {
            return isActuallyObject(el) ? deepClone(el) : el;
          }
        }),
      ] as (T & S)[Extract<keyof S, string>];
    } else if (Array.isArray(b[k])) {
      // b[k]: array
      merged[k] = [...(b[k] as any[]).map((el) => (isActuallyObject(el) ? deepClone(el) : el))] as (T & S)[Extract<
        keyof S,
        string
      >];
    } else {
      // b[k]: undefined, null, string, number, boolean, symbol, function, bigint, Date
      merged[k] = b[k] as (T & S)[Extract<keyof S, string>];
    }
  });

  return merged as T & S;
};
