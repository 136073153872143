import { useEnumOptions } from 'src/composables/useEnumOptions'
import { AggregateFunction } from 'src/business/aggregateFunction'
import { BUNDLED_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS } from 'src/models/new/Metrics/bundledMetrics'

export const useBundledMetricsAggregateFunctionOptions = () => {
  const {
    options,
    filterOptions,
  } = useEnumOptions<AggregateFunction>(BUNDLED_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS)

  return {
    options,
    filterOptions,
  }
}
