import { ComponentLink } from 'src/models/new/Link/componentLink'
import {
  LinkUpdateRequestParameters,
  convertToLinkUpdateRequestParameters,
} from 'src/models/api/Link/linkUpdateRequestParameters'

export type ComponentLinkUpdateRequestParameters = LinkUpdateRequestParameters

export const convertToComponentLinkUpdateRequestParameters = (link: ComponentLink): ComponentLinkUpdateRequestParameters => {
  return convertToLinkUpdateRequestParameters(link)
}
