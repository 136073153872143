
import { Section, sectionToNumberedLabel } from 'src/models/new/section';
import { PropType, computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import componentApi from 'src/apis/masters/component';
import { Component } from 'src/models/new/component';
import { Report } from 'src/models/new/report';
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts';
import { omitString } from 'src/util/text_decorator';
import { STORED_PARAMETERS_PAGE_TEMPORARY, getStoredParameters, setStoredParameters } from 'src/util/storedParameters';

const PARAMETER_KEY = 'copyComponentModal';
type StoreParameters = {
  section?: Section;
};

type State = {
  isReady: boolean;
  selectedSection: Section | null;
  wordToSearch: string;
  sectionsToShow: Section[];
};

export default defineComponent({
  props: {
    component: {
      type: Object as PropType<Component>,
      required: true,
    },
    report: {
      type: Object as PropType<Report>,
      required: true,
    },
  },
  emits: ['close', 'copied', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isReady: false,
      wordToSearch: '',
      sectionsToShow: computed(() => {
        return props.report.sections.filter((el) => sectionToNumberedLabel(el).includes(state.wordToSearch));
      }),
      selectedSection: null,
    });

    const selectSection = (section: Section) => {
      state.selectedSection = section;
    };

    const close = () => {
      emit('close');
    };

    // 内部的に選択中の設置領域は検索文字列の変更によっては直ちに変更されないようにしている
    // そのため、選択中の設置領域が表示されていないケースがあり、この状態はユーザーにとって
    // どの設置領域も選択していない状態と見なす
    // この関数ではユーザー視点で実質的に選択している設置領域を取得する
    const getActuallySelectedSection = (): Section | null => {
      if (!state.selectedSection) {
        return null;
      }

      return state.sectionsToShow.find((el) => el.id === state.selectedSection?.id) ?? null;
    };

    const copy = async (): Promise<void> => {
      if (getActuallySelectedSection() === null) {
        return;
      }

      const storeParameters: StoreParameters = { section: state.selectedSection! };
      setStoredParameters(root, storeParameters, STORED_PARAMETERS_PAGE_TEMPORARY, PARAMETER_KEY);

      try {
        await componentApi.copy(props.report.id, props.component.id, { sectionId: state.selectedSection!.id });
        emit('copied');
      } catch (err: any) {
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 409) {
          const msg = 'コンポーネントのコピーに失敗しました。レポートが編集されています。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else {
          emit('shouldReport', ERROR_GROUP_SYSTEM, null, err);
        }
      }
    };

    onMounted(() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false;

      const storedParameters = getStoredParameters<StoreParameters>(
        root,
        STORED_PARAMETERS_PAGE_TEMPORARY,
        PARAMETER_KEY,
      );
      if (storedParameters.section) {
        const storedSectionId = storedParameters.section.id;
        if (props.report.sections.find((el) => el.id === storedSectionId)) {
          state.selectedSection = storedParameters.section;
        } else {
          setStoredParameters(root, {}, STORED_PARAMETERS_PAGE_TEMPORARY, PARAMETER_KEY);
          state.selectedSection = null;
        }
      } else {
        state.selectedSection = null;
      }

      state.isReady = true;
    });

    return {
      props,
      state,
      selectSection,
      omitString,
      sectionToNumberedLabel,
      close,
      copy,
    };
  },
});
