import { useUrlStoredParameters } from './useUrlStoredParameters';

/*
 * useUrlQueryAsSearchParameters
 * Record<string, string | string[] | number | number[] | boolean | null>型の検索パラメータオブジェクトと
 * Record<string, string | number>型のURLクエリパラメータオブジェクトをプロキシする
 *
 * getUrlStoredParameters(Proxy)はURLクエリパラメータから取得した値を解析して返すため、
 * コンポーザブルフックを呼び出す際に与えたテンプレートを参考にする
 * テンプレートのキーに対する値がstringやnumberの場合はstringやnumberを返すことができるが
 * テンプレートの値がnullだとstringとnumberの区別がつかない為、テンプレートとして与えることはできない
 * なお、配列に関してはstringのケースとnumberのケースを判別するためにテンプレートの配列に値が必要であり
 * 空配列を渡してしまうとstring[]の形で返ってしまう為、少なくともnumber[]を想定している場合は必ず要素を1つ以上含めること
 *
 * また、テンプレートが含む値の型がstringではなく'yes' | 'no'のようなユニオンである場合等は、テンプレートの定義に工夫が必要である
 * 例えば以下の様にconstアサーションを使う
 * const searchParametersTemplate = {
 *   foo: 'yes',
 * } as const;
 *
 * なお、オブジェクト自体を丸ごとconstにしてしまうと、プロパティの一部が配列型の時勝手にreadonlyになってしまうので
 * const searchParametersTemplate = {
 *   foo: 'yes' as const,
 *   bar: ['hello', 'world'],
 * };
 * の様に、配列を含む場合はプロパティ単位でconstアサーションを書く必要がある
 * ただし、このように型を定義したとしてもURLを直接書き変えられた場合は型に違反する値が入り込む可能性を排除できない為
 * 呼び出し側は戻り値に範囲がある場合、必ずacceptableValueMapによってキー毎に許容する値を指定する
 */

type SearchParameters = Record<string, string | string[] | number | number[] | boolean | null>;

type QueryStringCasted<T> = T extends Array<unknown> ? string[] : string;

type GenericQueryParameter<T> = {
  [K in keyof T as null extends T[K] ? K : never]?: QueryStringCasted<Exclude<T[K], null>>;
} & {
  [K in keyof T as null extends T[K] ? never : K]: QueryStringCasted<T[K]>;
};

type SearchParametersTemplate<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

type UseUrlQueryAsSearchParametersArgs<T> = {
  searchParametersTemplate: SearchParametersTemplate<T>;
  defaultSearchParameters: T;
  acceptableValueMap: { [key in keyof T]?: T[keyof T] extends Array<unknown> ? T[keyof T] : T[keyof T][] };
};

type UseUrlQueryAsSearchParametersResult<T> = {
  getUrlStoredParameters: () => T;
  setUrlStoredParameters: (searchParameters: T) => void;
};

const isNumeric = (value: string): boolean => !isNaN(Number(value));

const isNumberArray = (value: unknown[]): value is number[] => {
  return value.every((v) => typeof v === 'number');
};

export const useUrlQueryAsSearchParameters = <T extends SearchParameters>({
  searchParametersTemplate,
  defaultSearchParameters,
  acceptableValueMap,
}: UseUrlQueryAsSearchParametersArgs<T>): UseUrlQueryAsSearchParametersResult<T> => {
  const { getUrlStoredParameters, setUrlStoredParameters } = useUrlStoredParameters<GenericQueryParameter<T>>();

  const isAcceptableValue = (key: keyof T, value: T[keyof T]): boolean => {
    const acceptableValues = acceptableValueMap[key];
    if (acceptableValues === undefined) {
      return true;
    }
    if (!Array.isArray(acceptableValues)) {
      return false;
    }

    if (Array.isArray(value)) {
      // string[] | number[] は配列を含むユニオンという扱いで、配列そのものではなくeveryを持たないと解析されることがある
      // 1つの配列であるとしたアサーションをかけるため、unknown[]にキャストする
      return (value as unknown[]).every((v) => acceptableValues.includes(v as T[keyof T]));
    } else {
      return acceptableValues.includes(value);
    }
  };

  const castFromQueryStringValue = (
    key: string,
    value: string,
  ): string | string[] | number | number[] | boolean | undefined => {
    const templateValueForKey = searchParametersTemplate[key as keyof T];
    if (!Array.isArray(templateValueForKey)) {
      if (typeof templateValueForKey === 'string') {
        return value;
      } else if (typeof templateValueForKey === 'number') {
        if (!isNumeric(value)) {
          // テンプレートに反して値がnumericではない場合は有効な値を返さない
          return undefined;
        }
        return Number(value);
      } else {
        if (value !== 'true' && value !== 'false') {
          // テンプレートに反して値がtrueでもfalseでもない場合は有効な値を返さない
          return undefined;
        }
        return value === 'true';
      }
    } else {
      // テンプレートから該当のキーに対する値がnumber[]型であることが確認でき、かつ全ての要素が数値である場合のみ数値に変換
      // number[]型であることが確認できない場合は変換せずに返す
      // カンマ区切りでない場合は全体を1つの要素と見做して配列にして返す
      const splitted = value.includes(',') ? value.split(',') : [value];

      if (isNumberArray(templateValueForKey)) {
        if (splitted.some((el) => !isNumeric(el))) {
          // テンプレートからnumber[]型であることが確認されたが、数値でない要素が含まれている場合はキー自体を返さない
          return undefined;
        }
        return splitted.map(Number);
      } else {
        return splitted;
      }
    }
  };

  const getUrlStoredParametersProxy = (): T => {
    const storedParameters = getUrlStoredParameters();

    return Object.entries<string>(storedParameters).reduce((searchParameters, [key, value]) => {
      const convertedValue = castFromQueryStringValue(key, value);
      if (convertedValue === undefined) {
        return searchParameters;
      }
      if (!isAcceptableValue(key, value as T[keyof T])) {
        return searchParameters;
      }

      searchParameters[key as keyof T] = convertedValue as T[keyof T];
      return searchParameters;
    }, defaultSearchParameters);
  };

  const castToQueryStringValue = (value: string | number | boolean | string[] | number[]): string => {
    if (Array.isArray(value)) {
      return value.join(',');
    } else if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    } else if (typeof value === 'number') {
      return String(value);
    } else {
      return value;
    }
  };

  const setUrlStoredParametersProxy = (searchParameters: T): void => {
    const queryFormedParameters: GenericQueryParameter<T> = Object.entries(searchParameters).reduce<
      GenericQueryParameter<T>
    >((queryParameters, [key, value]) => {
      if (value === null || value === '') {
        return queryParameters;
      }

      const formattedValue = castToQueryStringValue(value);
      queryParameters[key as keyof GenericQueryParameter<T>] =
        formattedValue as GenericQueryParameter<T>[keyof GenericQueryParameter<T>];

      return queryParameters;
    }, {} as GenericQueryParameter<T>);
    setUrlStoredParameters(queryFormedParameters);
  };

  return {
    getUrlStoredParameters: getUrlStoredParametersProxy,
    setUrlStoredParameters: setUrlStoredParametersProxy,
  };
};
