
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue'
import { vvHasError, vvValidate } from 'src/util/vee_validate'
import MetricsGraphSelectItemForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/MetricsGraphSelectItemForm.vue'
import { ComponentType, getBottomOrdinate, isOverBoundary, isOverlappedWithOther } from 'src/models/new/component'
import workplaceApi from 'src/apis/masters/workplace'
import { Workplace } from 'src/models/new/workplace'
import { TIME_SPAN_DAILY, TimeSpan } from 'src/business/timeSpan'
import GraphTypeSelectForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/GraphTypeSelectForm.vue'
import {
  COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH,
  COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH,
  COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART,
  COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH,
  MetricsGraphComponent,
} from 'src/models/new/Component/MetricsComponent/metricsGraphComponent'
import MetricsPieChartForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/MetricsPieChartForm.vue'
import MetricsBarGraphForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/MetricsBarGraphForm.vue'
import MetricsGroupedGraphForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/MetricsGroupedGraphForm.vue'
import MetricsTransitionGraphForm from 'src/views/Dashboard/Settings/Reports/Form/components/MetricsGraphFormModal/MetricsTransitionGraphForm.vue'
import {
  constructEmptyMetricsPieChartComponent,
  isComponentTypeMetricsPieChart,
  specifyMetricsPieChartComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent'
import {
  constructEmptyMetricsBarGraphComponent,
  isComponentTypeMetricsBarGraph,
  MetricsBarGraphComponent,
  specifyMetricsBarGraphComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent'
import {
  constructEmptyMetricsGroupedGraphComponent,
  isComponentTypeMetricsGroupedGraph,
  MetricsGroupedGraphComponent,
  specifyMetricsGroupedGraphComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent'
import {
  constructEmptyMetricsTransitionGraphComponent,
  isComponentTypeMetricsTransitionGraph,
  MetricsTransitionGraphComponent,
  specifyMetricsTransitionGraphComponentDominantTimeSpan,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent'
import { DATE_FNS_DATE_SHORT_SLASH_FORMAT } from 'src/util/Datetime/format'
import { Section } from 'src/models/new/section'
import componentApi from 'src/apis/masters/component'
import { notifySuccess1 } from 'src/hooks/notificationHook'
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INCLUDED_FORBIDDEN_METRICS,
  ERROR_REASON_METRICS_NOT_FOUND,
} from 'src/consts'
import { isRecordIdValid } from 'src/util/recordId'

interface State {
  isLoaded: boolean
  component: MetricsGraphComponent
  // 編集時にグラフタイプを変更する場合はこのフラグをtrueにし、変更対象をoriginalComponentから
  // 新たに生成したnewComponentに切り替える
  isOriginalKept: boolean
  // 親コンポーネントから渡されるprops.valueをコピーしたもの
  // そのためグラフコンポーネントは変更がリアルタイムに親コンポーネントに反映されない
  // newComponentが変更対象となった場合との整合性を取るために直接編集しない設計とした
  // また、グラフタイプ選択モーダルに戻ってきた場合はisOriginalKeptが切り替わると同時にprops.valueを再コピーする
  // newComponentの場合にはグラフタイプ選択モーダルに戻ってきた場合にnewComponentを破棄するため、挙動を揃える
  originalComponent: MetricsGraphComponent | null
  // isOriginalKeptがtrueの場合にoriginalComponentを編集対象から外し、新たにnewComponentを生成する
  // グラフタイプが変更された場合は既存のnewComponentを破棄し、新たに空の状態で生成する
  newComponent: MetricsGraphComponent | null
  componentIsNew: boolean
  showGraphTypeSelectionForm: boolean
  showPieChartForm: boolean
  showBarGraphForm: boolean
  showGroupedGraphForm: boolean
  showTransitionGraphForm: boolean
  showGraphSettingForm: boolean
  // 実質的には!showGraphTypeSelectionForm && !showGraphSettingFormと同義なので、実際には参照しなくても構わない
  showMetricsSelectionForm: boolean
  hasError: boolean
  modalClass: 'modal-mid' | 'modal-wide2' | 'modal-wide3'
  modalTitle: string

  workplaceOptions: Workplace[]
  selectedComponentType: ComponentType | null

  // いくつかの種類のグラフにおいて設定される周期で、選択可能なメトリクスを制限する
  dominantTimeSpan: TimeSpan
}

export default defineComponent({
  components: {
    MetricsGraphSelectItemForm,
    GraphTypeSelectForm,
    MetricsPieChartForm,
    MetricsBarGraphForm,
    MetricsGroupedGraphForm,
    MetricsTransitionGraphForm,
  },
  props: {
    value: {
      type: Object as PropType<MetricsGraphComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      component: computed({
        get() {
          return state.isOriginalKept ? state.newComponent! : state.originalComponent!
        },
        set(value) {
          if (state.isOriginalKept) {
            state.newComponent = value
          } else {
            state.originalComponent = value
          }
        },
      }),
      isOriginalKept: false,
      originalComponent: null,
      newComponent: null,
      componentIsNew: computed(() => !state.originalComponent?.name), // nameは必須項目なので判定に利用
      showGraphTypeSelectionForm: true,
      showMetricsSelectionForm: false,
      showPieChartForm: false,
      showBarGraphForm: false,
      showGroupedGraphForm: false,
      showTransitionGraphForm: false,
      showGraphSettingForm: computed(() => {
        return state.showPieChartForm || state.showBarGraphForm ||
          state.showGroupedGraphForm || state.showTransitionGraphForm
      }),
      hasError: computed(() => vvHasError(root)),
      modalClass: computed(() => {
        if (state.showGraphTypeSelectionForm) return 'modal-wide2'
        if (state.showMetricsSelectionForm) return 'modal-wide3'
        if (state.showPieChartForm) return 'modal-mid'
        if (state.showBarGraphForm) return 'modal-mid'
        if (state.showGroupedGraphForm) return 'modal-mid'
        if (state.showTransitionGraphForm) return 'modal-wide2'
        return 'modal-mid'
      }),
      modalTitle: computed(() => {
        if (state.showMetricsSelectionForm) return 'メトリクスの選択'
        return `グラフの${state.componentIsNew ? '追加' : '編集'}`
      }),
      isLoaded: false,
      workplaceOptions: [],
      selectedComponentType: null,

      dominantTimeSpan: TIME_SPAN_DAILY,
    })

    const initializeNewComponent = (componentType: ComponentType): void => {
      const inheritedProperties: Pick<MetricsGraphComponent, 'id' | 'abscissa' | 'ordinate'> = {
        id: props.value.id,
        abscissa: props.value.abscissa,
        ordinate: props.value.ordinate,
      }

      // モデルの空モデル生成ロジックから空モデルを生成する
      // 入力のデフォルト値を調整したい場合はここで値を上書きする
      switch (componentType) {
        case COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART:
          state.newComponent = {
            ...constructEmptyMetricsPieChartComponent(),
            ...inheritedProperties,
          }
          break
        case COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH:
          state.newComponent = {
            ...constructEmptyMetricsBarGraphComponent(),
            ...inheritedProperties,
          } as MetricsBarGraphComponent
          break
        case COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH:
          state.newComponent = {
            ...constructEmptyMetricsGroupedGraphComponent(),
            ...inheritedProperties,
          } as MetricsGroupedGraphComponent
          break
        case COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH:
          state.newComponent = {
            ...constructEmptyMetricsTransitionGraphComponent(),
            ...inheritedProperties,
            dateFormat: DATE_FNS_DATE_SHORT_SLASH_FORMAT,
          } as MetricsTransitionGraphComponent
          break
        default:
          // 通常はこの条件に当たらないが、入ってきた場合はオリジナルのコンポーネントをコピーしてエラーを防ぐ
          state.newComponent = structuredClone(state.originalComponent)
      }
    }

    const updateDominantTimeSpan = () => {
      let specifiedDominantTimeSpan = null
      if (isComponentTypeMetricsGroupedGraph(state.component)) {
        specifiedDominantTimeSpan = specifyMetricsGroupedGraphComponentDominantTimeSpan(state.component)
      } else if (isComponentTypeMetricsTransitionGraph(state.component)) {
        specifiedDominantTimeSpan = specifyMetricsTransitionGraphComponentDominantTimeSpan(state.component)
      } else if (isComponentTypeMetricsPieChart(state.component)) {
        specifiedDominantTimeSpan = specifyMetricsPieChartComponentDominantTimeSpan(state.component)
      } else if (isComponentTypeMetricsBarGraph(state.component)) {
        specifiedDominantTimeSpan = specifyMetricsBarGraphComponentDominantTimeSpan(state.component)
      }
      state.dominantTimeSpan = specifiedDominantTimeSpan ?? TIME_SPAN_DAILY
    }

    const onComponentTypeChanged = (componentType: ComponentType): void => {
      const originalComponentType = props.value.componentType
      state.isOriginalKept = componentType !== originalComponentType

      if (state.isOriginalKept) {
        initializeNewComponent(componentType)
      }

      // state.componentの向き先が変わることがあるので、支配的な周期を再度算出する
      updateDominantTimeSpan()
    }

    const onDominantTimeSpanChanged = (timeSpan: TimeSpan): void => {
      // if (state.component)
      state.dominantTimeSpan = timeSpan
    }

    const onSelectMetrics = async(): Promise<void> => {
      submit()
    }

    const goToGraphTypeSelectionForm = () => {
      state.showMetricsSelectionForm = false
      state.showGraphTypeSelectionForm = true
      state.showPieChartForm = false
      state.showBarGraphForm = false
      state.showGroupedGraphForm = false
      state.showTransitionGraphForm = false
    }

    const goToGraphSettingForm = (): void => {
      state.showMetricsSelectionForm = false
      state.showGraphTypeSelectionForm = false
      state.showPieChartForm = false
      state.showBarGraphForm = false
      state.showGroupedGraphForm = false
      state.showTransitionGraphForm = false

      switch (state.selectedComponentType) {
        case COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART:
          state.showPieChartForm = true
          break
        case COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH:
          state.showBarGraphForm = true
          break
        case COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH:
          state.showGroupedGraphForm = true
          break
        case COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH:
          state.showTransitionGraphForm = true
          break
        default:
          break
      }
    }

    const goToMetricsSelectionForm = async(): Promise<void> => {
      if (!(await vvValidate(root))) return

      state.showMetricsSelectionForm = true
      state.showGraphTypeSelectionForm = false
      state.showPieChartForm = false
      state.showBarGraphForm = false
      state.showGroupedGraphForm = false
      state.showTransitionGraphForm = false
    }

    // コンポーネントの追加・更新
    const submit = async(): Promise<void> => {
      if (!(await vvValidate(root))) return

      // 配置場所が重なる場合や新しく生成した場合は一番下に配置する
      if (isOverlappedWithOther(state.component, props.section) || isOverBoundary(state.component) || !state.component.id) {
        state.component.ordinate = getBottomOrdinate(props.section)
      }
      const component = {
        ...state.component,
        sectionId: props.section.id,
      }
      const operationWord = isRecordIdValid(state.component.id) ? '更新' : '追加'
      try {
        if (isRecordIdValid(state.component.id)) {
          await componentApi.update(props.reportId, component)
        } else {
          const { id } = await componentApi.create(props.reportId, component)
          component.id = id
        }
        state.component = structuredClone(component)
        emit('input', state.component)
        emit('updated')
        notifySuccess1(root, `コンポーネントを${operationWord}しました。`)
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 403) {
          if (errRes.reason === ERROR_REASON_INCLUDED_FORBIDDEN_METRICS) {
            emit('shouldReport', ERROR_GROUP_USER, errRes.message, err)
          } else {
            const msg = 'アクセスする権限がありません。管理者にお問合せください。'
            emit('shouldReport', ERROR_GROUP_USER, msg, err)
          }
        } else if (errStatus === 404) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          emit('shouldReport', ERROR_GROUP_USER, '選択したメトリクスは存在しません。ページを更新し、再度お試しください。', err)
        } else if (errStatus === 409) {
          const msg = 'コンポーネントの追加に失敗しました。レポートが編集されています。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else {
          const msg = `コンポーネントの${operationWord}に失敗しました。管理者に連絡してください。`
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err)
        }
      }
    }

    const close = (): void => {
      emit('close')
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false

      state.originalComponent = structuredClone(props.value)
      state.isOriginalKept = false
      state.newComponent = null
      // originalComponentが支配的な周期を持ちメトリクスを既に含んでいる場合、ここでstate.dominantTimeSpanが変更される
      updateDominantTimeSpan()

      if (isComponentTypeMetricsPieChart(state.component)) {
        state.selectedComponentType = COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART
      } else if (isComponentTypeMetricsBarGraph(state.component)) {
        state.selectedComponentType = COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH
      } else if (isComponentTypeMetricsGroupedGraph(state.component)) {
        state.selectedComponentType = COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH
      } else if (isComponentTypeMetricsTransitionGraph(state.component)) {
        state.selectedComponentType = COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH
      } else {
        // 新規の場合が該当
        state.selectedComponentType = null
        // 最初に該当タイプが存在しない場合は、受け取ったコンポーネントは使わずに新規で作成する空のコンポーネントのみ使用する
        state.isOriginalKept = true
      }

      state.workplaceOptions = await workplaceApi.index()

      goToGraphTypeSelectionForm()

      state.isLoaded = true
    })

    return {
      state,
      props,
      onSelectMetrics,
      goToGraphTypeSelectionForm,
      goToGraphSettingForm,
      goToMetricsSelectionForm,
      onComponentTypeChanged,
      onDominantTimeSpanChanged,
      submit,
      close,
    }
  },
})
