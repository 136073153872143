
import Vue, { PropType, computed, defineComponent, getCurrentInstance, onMounted, reactive, watch } from 'vue';
import { vvGetError, vvGetErrorObject, vvValidate } from 'src/util/vee_validate';
import { Section } from 'src/models/new/section';
import { GridItemData, GridLayout, GridItem } from 'vue-grid-layout';
import ComponentView from 'src/views/Dashboard/Settings/Reports/Form/components/SectionView/ComponentView.vue';
import { Component, layoutOfComponent } from 'src/models/new/component';
import sectionApi from 'src/apis/masters/section';
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts';
import { notifySuccess1 } from 'src/hooks/notificationHook';
import {
  isComponentTypeMetricsCard,
  METRICS_CARD_COMPONENT_MAX_HEIGHT,
  METRICS_CARD_COMPONENT_MIN_HEIGHT,
  METRICS_CARD_COMPONENT_MAX_WIDTH,
  METRICS_CARD_COMPONENT_MIN_WIDTH,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import {
  isComponentTypeComment,
  COMMENT_COMPONENT_MAX_HEIGHT,
  COMMENT_COMPONENT_MIN_HEIGHT,
  COMMENT_COMPONENT_MAX_WIDTH,
  COMMENT_COMPONENT_MIN_WIDTH,
} from 'src/models/new/Component/commentComponent';
import {
  isComponentTypeMetricsGraph,
  METRICS_GRAPH_COMPONENT_MAX_HEIGHT,
  METRICS_GRAPH_COMPONENT_MIN_HEIGHT,
  METRICS_GRAPH_COMPONENT_MAX_WIDTH,
  METRICS_GRAPH_COMPONENT_MIN_WIDTH,
} from 'src/models/new/Component/MetricsComponent/metricsGraphComponent';
import {
  isComponentTypeMetricsList,
  METRICS_LIST_COMPONENT_MAX_HEIGHT,
  METRICS_LIST_COMPONENT_MIN_HEIGHT,
  METRICS_LIST_COMPONENT_MAX_WIDTH,
  METRICS_LIST_COMPONENT_MIN_WIDTH,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import {
  isComponentTypeMetricsTable,
  METRICS_TABLE_COMPONENT_MAX_HEIGHT,
  METRICS_TABLE_COMPONENT_MIN_HEIGHT,
  METRICS_TABLE_COMPONENT_MAX_WIDTH,
  METRICS_TABLE_COMPONENT_MIN_WIDTH,
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent';

interface State {
  validations: Record<string, object> | {};
  gridLayoutItems: GridItemData[];
}

const setupState = (root: Vue): State => {
  const state: State = reactive({
    validations: computed(() => {
      if (root.$props.suppressFormValidation) {
        return {};
      }
      return {
        sectionName: { max: 50 },
      };
    }),
    gridLayoutItems: [],
  });
  return state;
};

export default defineComponent({
  components: {
    GridLayout,
    GridItem,
    ComponentView,
  },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
    mutate: {
      type: Function as PropType<(section: Section, next: Section | null) => void>,
      required: true,
    },
    delete: {
      type: Function as PropType<(section: Section) => void>,
      required: true,
    },
    createComponent: {
      type: Function as PropType<(section: Section) => void>,
      required: true,
    },
    editComponent: {
      type: Function as PropType<(section: Section, component: Component) => void>,
      required: true,
    },
    deleteComponent: {
      type: Function as PropType<(section: Section, component: Component) => void>,
      required: true,
    },
    copyComponent: {
      type: Function as PropType<(section: Section, component: Component) => void>,
      required: true,
    },
    suppressFormValidation: {
      type: Boolean,
      default: false,
    },
    isLayoutEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    // コンポーネントの移動時のイベント
    const onGridItemMoved = (i: string): void => {
      // 移動したitemを取得
      // (x,yの座標は引数で受け取ることができるが、移動先でitemと重なった場合かつ下に自動配置された場合の値がおかしくなるためgridLayoutItemsから取得するようにしている)
      const movedGridItem = state.gridLayoutItems.find((item) => item.i === i);
      if (!movedGridItem) {
        return;
      }

      const component = props.section.components.find((component) => {
        return component.id === Number(movedGridItem.i);
      })!;
      component.abscissa = movedGridItem.x;
      component.ordinate = movedGridItem.y;

      props.mutate(props.section, {
        ...props.section,
        components: props.section.components.map((el) => (el.id === component.id ? component : el)),
      });
    };

    const isGridItemResizable = (gridItem: GridItemData): boolean => {
      if (!props.isLayoutEditMode) {
        return false;
      }

      const component = getComponentFromGridItem(gridItem);
      return !isComponentTypeMetricsCard(component);
    };

    const gridItemMaxHeight = (gridItem: GridItemData): number => {
      const component = getComponentFromGridItem(gridItem);
      if (isComponentTypeMetricsCard(component)) {
        return METRICS_CARD_COMPONENT_MAX_HEIGHT;
      } else if (isComponentTypeComment(component)) {
        return COMMENT_COMPONENT_MAX_HEIGHT;
      } else if (isComponentTypeMetricsGraph(component)) {
        return METRICS_GRAPH_COMPONENT_MAX_HEIGHT;
      } else if (isComponentTypeMetricsList(component)) {
        return METRICS_LIST_COMPONENT_MAX_HEIGHT;
      } else if (isComponentTypeMetricsTable(component)) {
        return METRICS_TABLE_COMPONENT_MAX_HEIGHT;
      }
      return 1;
    };

    const gridItemMinHeight = (gridItem: GridItemData): number => {
      const component = getComponentFromGridItem(gridItem);
      if (isComponentTypeMetricsCard(component)) {
        return METRICS_CARD_COMPONENT_MIN_HEIGHT;
      } else if (isComponentTypeComment(component)) {
        return COMMENT_COMPONENT_MIN_HEIGHT;
      } else if (isComponentTypeMetricsGraph(component)) {
        return METRICS_GRAPH_COMPONENT_MIN_HEIGHT;
      } else if (isComponentTypeMetricsList(component)) {
        return METRICS_LIST_COMPONENT_MIN_HEIGHT;
      } else if (isComponentTypeMetricsTable(component)) {
        return METRICS_TABLE_COMPONENT_MIN_HEIGHT;
      }
      return 1;
    };

    const gridItemMaxWidth = (gridItem: GridItemData): number => {
      const component = getComponentFromGridItem(gridItem);
      if (isComponentTypeMetricsCard(component)) {
        return METRICS_CARD_COMPONENT_MAX_WIDTH;
      } else if (isComponentTypeComment(component)) {
        return COMMENT_COMPONENT_MAX_WIDTH;
      } else if (isComponentTypeMetricsGraph(component)) {
        return METRICS_GRAPH_COMPONENT_MAX_WIDTH;
      } else if (isComponentTypeMetricsList(component)) {
        return METRICS_LIST_COMPONENT_MAX_WIDTH;
      } else if (isComponentTypeMetricsTable(component)) {
        return METRICS_TABLE_COMPONENT_MAX_WIDTH;
      }
      return 1;
    };

    const gridItemMinWidth = (gridItem: GridItemData): number => {
      const component = getComponentFromGridItem(gridItem);
      if (isComponentTypeMetricsCard(component)) {
        return METRICS_CARD_COMPONENT_MIN_WIDTH;
      } else if (isComponentTypeComment(component)) {
        return COMMENT_COMPONENT_MIN_WIDTH;
      } else if (isComponentTypeMetricsGraph(component)) {
        return METRICS_GRAPH_COMPONENT_MIN_WIDTH;
      } else if (isComponentTypeMetricsList(component)) {
        return METRICS_LIST_COMPONENT_MIN_WIDTH;
      } else if (isComponentTypeMetricsTable(component)) {
        return METRICS_TABLE_COMPONENT_MIN_WIDTH;
      }
      return 1;
    };

    const onGridItemResized = (i: string, newH: number, newW: number): void => {
      const component = props.section.components.find((component) => {
        return component.id === Number(i);
      })!;
      component.height = newH;
      component.width = newW;

      props.mutate(props.section, {
        ...props.section,
        components: props.section.components.map((el) => (el.id === component.id ? component : el)),
      });
    };

    const onAddComponentClick = (): void => {
      props.createComponent(props.section);
    };

    // セクションの削除
    const del = (): void => {
      props.delete(props.section);
    };

    const updateSectionName = async (name: string | null): Promise<void> => {
      if (!(await vvValidate(root))) {
        return;
      }

      const section = { ...props.section, name: name };
      try {
        await sectionApi.update(props.reportId, section);
        notifySuccess1(root, 'セクション名を更新しました');
      } catch (err: any) {
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else {
          const msg = 'セクション名の更新に失敗しました。管理者に連絡してください。';
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err);
        }
      }
    };

    const onComponentDelete = (component: Component): void => {
      props.deleteComponent(props.section, component);
    };
    const onComponentEdit = (component: Component): void => {
      props.editComponent(props.section, component);
    };
    const onComponentCopy = (component: Component): void => {
      props.copyComponent(props.section, component);
    };

    const getComponentFromGridItem = (gridItem: GridItemData): Component => {
      return props.section.components.find((el) => el.id === Number(gridItem.i))!;
    };

    onMounted(async () => {
      const section: Section = root.$props.section;
      state.gridLayoutItems = section.components.map((component) => {
        return layoutOfComponent(component);
      });

      // TODO: データロードを分離する場合、このタイミングでコンポーネントを取得する
    });

    // Vue Grid Layoutの仕様上、算出プロパティでアイテムを生成した場合にドラッグによる移動を検知できない
    watch(
      () => props.section,
      (section) => {
        state.gridLayoutItems = section.components.map((component) => {
          return layoutOfComponent(component);
        });
      },
    );

    return {
      props,
      state,
      getError,
      getErrorObject,
      del,
      onGridItemMoved,
      isGridItemResizable,
      gridItemMaxHeight,
      gridItemMinHeight,
      gridItemMaxWidth,
      gridItemMinWidth,
      onGridItemResized,
      onComponentEdit,
      onComponentDelete,
      onComponentCopy,
      getComponentFromGridItem,
      onAddComponentClick,
      updateSectionName,
    };
  },
});
