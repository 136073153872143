import { RecordId } from 'src/util/recordId'
import { UserViewSetting } from 'src/models/new/userViewSetting'

export type UserViewSettingResponse = {
  id: RecordId
  home_report_id: number | null
}

export const convertFromUserViewSettingResponse = (
  userViewSetting: UserViewSettingResponse,
): UserViewSetting => {
  return {
    id: userViewSetting.id,
    homeReportId: userViewSetting.home_report_id,
  }
}
