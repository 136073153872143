import { isMetricsReferBorderless, type Metrics, type MetricsType } from 'src/models/new/metrics';
import {
  getCalculatedMetricsReferableMetricsTypes,
  isMetricsCalculatedMetrics,
} from 'src/models/new/Metrics/calculatedMetrics';
import { getBundledMetricsReferableMetricsTypes, isMetricsBundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import {
  getSummaryMetricsReferableMetricsTypes,
  isMetricsSummaryMetrics,
  availableTargetTimeSpanFromSummaryMetrics,
} from 'src/models/new/Metrics/summaryMetrics';
import {
  getReferenceMetricsReferableMetricsTypes,
  isMetricsReferenceMetrics,
} from 'src/models/new/Metrics/referenceMetrics';
import { useSelectMetricsForm } from 'src/components/NewSelectItemForm/composables/useSelectMetricsForm';

const getAllowedWorkplaceIds = (metrics: Metrics) => {
  return isMetricsReferBorderless(metrics) ? undefined : [metrics.workplaceId];
};

const getAllowedMetricsTypes = (metrics: Metrics): MetricsType[] | undefined => {
  if (isMetricsCalculatedMetrics(metrics)) {
    return getCalculatedMetricsReferableMetricsTypes();
  } else if (isMetricsBundledMetrics(metrics)) {
    return getBundledMetricsReferableMetricsTypes(metrics.isBorderless);
  } else if (isMetricsSummaryMetrics(metrics)) {
    return getSummaryMetricsReferableMetricsTypes();
  } else if (isMetricsReferenceMetrics(metrics)) {
    return getReferenceMetricsReferableMetricsTypes();
  } else {
    return undefined;
  }
};

const getAllowedTimeSpans = (metrics: Metrics) => {
  return isMetricsSummaryMetrics(metrics) ? [availableTargetTimeSpanFromSummaryMetrics(metrics)] : [metrics.timeSpan];
};

export const useSelectMetricsOnMetricsForm = (
  currentSelectedMetricsList: Metrics[],
  selectEventKey: string,
  handleSelectMetrics: (metricsList: Metrics[]) => void,
  metrics: Metrics,
) => {
  const allowedWorkplaceIds = getAllowedWorkplaceIds(metrics);
  const allowedMetricsTypes = getAllowedMetricsTypes(metrics);
  const allowedTimeSpans = getAllowedTimeSpans(metrics);

  const allowedOptionMap = {
    workplaceIds: allowedWorkplaceIds,
    metricsTypes: allowedMetricsTypes,
    timeSpans: allowedTimeSpans,
  };

  const searchMetricsFormMode = isMetricsBundledMetrics(metrics) && metrics.isBorderless ? 'mode2' : 'mode1';

  return useSelectMetricsForm(currentSelectedMetricsList, selectEventKey, handleSelectMetrics, {
    mode: searchMetricsFormMode,
    allowedOptionMap,
    defaultParams: searchMetricsFormMode === 'mode1' ? { workplaceId: metrics.workplaceId } : {},
  });
};
