import { getCurrentInstance } from 'vue';
import {
  Parameters,
  STORED_PARAMETERS_PAGE_TEMPORARY,
  getStoredParameters,
  setStoredParameters,
} from 'src/util/storedParameters';

export const usePageTemporaryStoredParameters = <T extends Parameters>(key: string) => {
  const root = getCurrentInstance()?.proxy!;
  const getPageTemporaryStoredParameters = (): T => {
    return getStoredParameters<T>(root, STORED_PARAMETERS_PAGE_TEMPORARY, key);
  };
  const setPageTemporaryStoredParameters = (params: T): void => {
    setStoredParameters(root, { ...params }, STORED_PARAMETERS_PAGE_TEMPORARY, key);
  };

  return {
    getPageTemporaryStoredParameters,
    setPageTemporaryStoredParameters,
  };
};
