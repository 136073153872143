const SUN = 'sun'
const MON = 'mon'
const TUE = 'tue'
const WED = 'wed'
const THU = 'thu'
const FRI = 'fri'
const SAT = 'sat'

export type DayOfWeek = typeof SUN | typeof MON | typeof TUE | typeof WED | typeof THU | typeof FRI | typeof SAT

export const DAY_OF_WEEK_LOCAL_WORDS = {
  [SUN]: '日曜日',
  [MON]: '月曜日',
  [TUE]: '火曜日',
  [WED]: '水曜日',
  [THU]: '木曜日',
  [FRI]: '金曜日',
  [SAT]: '土曜日',
}
export const DAY_OF_WEEK_NUMBERS = {
  [SUN]: 0,
  [MON]: 1,
  [TUE]: 2,
  [WED]: 3,
  [THU]: 4,
  [FRI]: 5,
  [SAT]: 6,
}

export const dayOfWeekToNumber = (dayOfWeek: DayOfWeek): number => DAY_OF_WEEK_NUMBERS[dayOfWeek]
export const dayOfWeekFromNumber = (number: number): DayOfWeek => {
  const daysOfWeek = Object.keys(DAY_OF_WEEK_NUMBERS) as DayOfWeek[]
  return daysOfWeek.find(el => DAY_OF_WEEK_NUMBERS[el] === number) as DayOfWeek
}
export const dayOfWeekToLocalWord = (dayOfWeek: DayOfWeek): string => DAY_OF_WEEK_LOCAL_WORDS[dayOfWeek]
