import { CompanyViewSetting } from 'src/models/new/companyViewSetting'

export type CompanyViewSettingUpdateRequestParams = {
  home_report_id: number | null
}

export const convertToCompanyViewSettingUpdateRequestParameters = (
  companyViewSetting: CompanyViewSetting
): CompanyViewSettingUpdateRequestParams => {
  return {
    home_report_id: companyViewSetting.homeReportId,
  }
}
