import { constructMinimumEmptyMatrixHeaders, MatrixHeaders } from 'src/util/dataHeader';
import { Metrics } from '../../metrics';
import { MetricsComponent } from '../metricsComponent';
import { MetricsTableComponentConditionalStatement } from '../../ConditionalStatement/metricsTableComponentConditionalStatement';
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementTargetBackground,
  isConditionalStatementTargetIcon,
  isConditionalStatementTargetText,
  thresholdValueFromConditionalStatement,
} from '../../conditionalStatement';
import { CSSProperties } from 'vue/types/jsx';
import { compareWithComparator } from 'src/util/comparator';
import {
  classFromIconDecoration,
  IconDecoration,
  styleFromIconDecoration,
} from '../../ConditionalStatement/Decorations/iconDecoration';
import { Component, internal } from 'src/models/new/component';
import { styleFromTextDecoration, TextDecoration } from '../../ConditionalStatement/Decorations/textDecoration';
import {
  BackgroundDecoration,
  styleFromBackgroundDecoration,
} from '../../ConditionalStatement/Decorations/backgroundDecoration';
import type { CsvRow } from 'src/components/UIComponents/Buttons/CsvDownload/types';
import { generateComponentCsv } from 'src/models/new/Component/MetricsComponent/shared/componentCsvGenerator';

const METRICS_TABLE = internal.METRICS_TABLE;

// TODO: デバッグ用で制限緩和中(終わったら36に戻す)
// export const MAX_TABLE_BODY_CELL = 36
const MAX_TABLE_BODY_CELL = 99999;
export const getMaxTableBodyCell = (): number => {
  return MAX_TABLE_BODY_CELL;
};

export const METRICS_TABLE_COMPONENT_MIN_WIDTH = 1;
export const METRICS_TABLE_COMPONENT_MAX_WIDTH = 6;
export const METRICS_TABLE_COMPONENT_DEFAULT_WIDTH = 6;
export const METRICS_TABLE_COMPONENT_MIN_HEIGHT = 1;
export const METRICS_TABLE_COMPONENT_MAX_HEIGHT = 12;
export const METRICS_TABLE_COMPONENT_DEFAULT_HEIGHT = 2;

export type MetricsTableComponentTableCell = {
  row: number;
  column: number;
  metrics: Metrics;
  conditionalStatements: MetricsTableComponentConditionalStatement[];
};

export type MetricsTableComponent = MetricsComponent & {
  headers: MatrixHeaders;
  isUnitIndividualDisplayed: boolean;
  rows: number; // TODO: 利用しなかったので必要かどうか確認する
  columns: number; // TODO: 利用しなかったので必要かどうか確認する
  data: MetricsTableComponentTableCell[];
};

export const constructEmptyMetricsTableComponent = (): MetricsTableComponent => {
  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_TABLE,
    abscissa: 0,
    ordinate: 0,
    width: METRICS_TABLE_COMPONENT_DEFAULT_WIDTH,
    height: METRICS_TABLE_COMPONENT_DEFAULT_HEIGHT,
    referenceDate: null,
    name: '',
    headers: constructMinimumEmptyMatrixHeaders(),
    isUnitIndividualDisplayed: false,
    rows: 1,
    columns: 1,
    data: [],
  };
};

export const isComponentTypeMetricsTable = (component: Component): component is MetricsTableComponent => {
  return component.componentType === METRICS_TABLE;
};

// 使用していない変数があるが、他のコンポーネントとインターフェースを揃えている
const getBaseValue = (
  _component: MetricsTableComponent,
  cell: MetricsTableComponentTableCell,
  conditionalStatement: MetricsTableComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) return cell.metrics.value ?? null;
  return baseValueFromConditionalStatement(conditionalStatement);
};

const getThresholdValue = (
  _component: MetricsTableComponent,
  _cell: MetricsTableComponentTableCell,
  conditionalStatement: MetricsTableComponentConditionalStatement,
): number | null => {
  return thresholdValueFromConditionalStatement(conditionalStatement);
};

const extractPriorTargetDecoration = (
  component: MetricsTableComponent,
  row: number,
  column: number,
  targetCheckFunction: (conditionalStatement: MetricsTableComponentConditionalStatement) => boolean,
): MetricsTableComponentConditionalStatement | null => {
  const cell = component.data.find((el) => el.row === row && el.column === column);
  return (
    cell?.conditionalStatements
      .filter((el) => targetCheckFunction(el))
      .sort((a, b) => b.priority - a.priority)
      .reduce(
        (
          found: MetricsTableComponentConditionalStatement | null,
          conditionalStatement: MetricsTableComponentConditionalStatement,
        ) => {
          if (found) return found;
          const base = getBaseValue(component, cell, conditionalStatement);
          if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
            return compareWithComparator(base, conditionalStatement.comparator, null) ? conditionalStatement : null;
          }
          const threshold = getThresholdValue(component, cell, conditionalStatement);
          if (base === null || threshold === null) return found;
          if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) return found;
          return conditionalStatement;
        },
        null,
      ) ?? null
  );
};

export const applyConditionalStatementToMetricsTableComponentTextStyle = (
  component: MetricsTableComponent,
  row: number,
  column: number,
): CSSProperties => {
  if (!component.referenceDate) return {};
  const decoration = extractPriorTargetDecoration(component, row, column, isConditionalStatementTargetText)?.decoration;
  return decoration ? styleFromTextDecoration(decoration as TextDecoration) : {};
};
export const applyConditionalStatementToMetricsTableComponentIconStyle = (
  component: MetricsTableComponent,
  row: number,
  column: number,
): CSSProperties => {
  if (!component.referenceDate) return {};
  const decoration = extractPriorTargetDecoration(component, row, column, isConditionalStatementTargetIcon)?.decoration;
  return decoration ? styleFromIconDecoration(decoration as IconDecoration) : {};
};
export const applyConditionalStatementToMetricsTableComponentIconClass = (
  component: MetricsTableComponent,
  row: number,
  column: number,
): string | null => {
  if (!component.referenceDate) return null;
  const decoration = extractPriorTargetDecoration(component, row, column, isConditionalStatementTargetIcon)
    ?.decoration as IconDecoration | null;
  return decoration ? classFromIconDecoration(decoration as IconDecoration) : null;
};
export const applyConditionalStatementToMetricsTableComponentBackgroundStyle = (
  component: MetricsTableComponent,
  row: number,
  column: number,
): CSSProperties => {
  if (!component.referenceDate) return {};
  const decoration = extractPriorTargetDecoration(
    component,
    row,
    column,
    isConditionalStatementTargetBackground,
  )?.decoration;
  return decoration ? styleFromBackgroundDecoration(decoration as BackgroundDecoration) : {};
};

export const generateCsvFromMetricsTableComponent = (
  component: MetricsTableComponent,
  emptyCellValue: string = '',
): CsvRow[] => {
  const head = component.headers.head.layout;
  const side = component.headers.side.layout;
  const contents = component.data;
  return generateComponentCsv(head, side, contents, emptyCellValue);
};
