import { ConditionalStatementWithContainerPosition } from 'src/models/new/conditionalStatement'
import { ConditionalStatementCreateRequestParameters, convertToConditionalStatementCreateRequestParameters } from './conditionalStatementCreateRequestParameters'

export type ConditionalStatementBulkCreateRequestParameters = {
  data: ConditionalStatementCreateRequestParameters[]
}

export const convertToConditionalStatementBulkCreateRequestParameters = (
  statements: ConditionalStatementWithContainerPosition<any>[],
): ConditionalStatementBulkCreateRequestParameters => {
  return {
    data: statements.map(convertToConditionalStatementCreateRequestParameters)
  }
}
