
import {
  computed,
  defineComponent,
  InjectionKey,
  PropType,
  provide,
  readonly,
  ref,
  Ref,
  SetupContext,
  watch,
} from 'vue';
import { SortDirection, SortSpec } from 'src/components/UIComponents/Sorter/types';

export const GET_CURRENT_SORT_DIRECTIONS_KEY: InjectionKey<() => Readonly<Ref<SortDirection[]>>> = Symbol(
  'injection key of currentSortDirections',
);
export const ON_SORT_FUNC_KEY: InjectionKey<(sortDirection: SortDirection) => void> =
  Symbol('injection key of onSort Func');
export const GET_IS_SORTABLE_KEY: InjectionKey<() => Readonly<Ref<boolean>>> = Symbol('injection key of isSortable');

function compareFuncDefault(a: any, b: any): number {
  const aConv = isNaN(a) ? a : +a;
  const bConv = isNaN(b) ? b : +b;
  return aConv < bConv ? -1 : aConv > bConv ? 1 : 0;
}

function sortBy<T extends Record<string, any>>(
  srcArr: T[],
  sortSpecs: SortSpec[],
  sortDirections: SortDirection[],
): T[] {
  const resultArr = srcArr.slice();
  if (sortDirections.length === 0) {
    return resultArr;
  }
  resultArr.sort((aObj, bObj): number => {
    for (const sortDirection of sortDirections) {
      // convert dotted string to object
      const propertyChain = sortDirection.key.split('.').filter((e) => !!e); // allow empty key for identity
      const aValue = propertyChain.reduce(
        (obj, prop) => (obj !== null && obj !== undefined ? obj[prop] : null),
        aObj,
      ) as any;
      const bValue = propertyChain.reduce(
        (obj, prop) => (obj !== null && obj !== undefined ? obj[prop] : null),
        bObj,
      ) as any;

      // ソート定義にcompareFuncが設定されている場合はそれを使う
      const sortSpecFunc = sortSpecs.find((e) => e.key === sortDirection.key)?.compareFunc;
      const compareFunc = sortSpecFunc ?? compareFuncDefault;
      const compResult = compareFunc(aValue, bValue) * (sortDirection.asc ? 1 : -1);
      // 0ではない値が出たらこの組み合わせについてそれ以降のソートを実施する必要はない
      if (compResult !== 0) {
        return compResult;
      }
    }
    return 0;
  });
  return resultArr;
}

// oldを元にしつつ、newで入ってきたものが優先となるようにする.
// oldとnewでkeyのかぶりがあればoldから取り除きnewを優先させる.
// 最終的に結果をmaxSortDirectionsToKeep個で切り詰める.
const maxSortDirectionsToKeep = 5;
function mergeSortDirections(oldDirections: SortDirection[], newDirections: SortDirection[]): SortDirection[] {
  const ret: SortDirection[] = oldDirections.slice().reverse();
  newDirections
    .slice()
    .reverse()
    .forEach((newDirection) => {
      const duplicateIdx = ret.findIndex((e) => e.key === newDirection.key);
      if (duplicateIdx !== -1) {
        ret.splice(duplicateIdx, 1);
      }
      ret.push(newDirection);
    });
  return ret.reverse().slice(0, maxSortDirectionsToKeep);
}

// https://logaretm.com/blog/generically-typed-vue-components/
// このコンポーネントが出力するソート済み配列の型をprops.listの型と同じにしたい.
// (まぁ少なくともWebStormだとtemplate.htmlでsortedListの型を見るとTになってしまっているのだが...)
class SortContextFactory<T extends Record<string, any>> {
  define() {
    return defineComponent({
      name: 'SortContext',
      props: {
        list: {
          type: Array as PropType<T[]>,
          required: true,
        },
        // ソート定義(compareFuncとkeyの組み合わせ)
        sortSpecs: {
          type: Array as PropType<SortSpec[]>,
          default: () => [],
        },
        isSortable: {
          type: Boolean as PropType<boolean>,
          default: true,
        },
      },
      setup(props, _context: SetupContext) {
        const getDefaultSortDirection = (sortSpecs: SortSpec[]): SortDirection[] => {
          return sortSpecs.map((spec) => ({ key: spec.key, asc: true }));
        };

        // ソート基準(keyとascの組み合わせ)
        const currentSortDirections = ref<SortDirection[]>(getDefaultSortDirection(props.sortSpecs));

        const sortedList = ref<T[]>([]) as Ref<T[]>;
        const isSortable = computed(() => {
          return props.isSortable;
        });

        const updateSortedList = (srcList: T[]): void => {
          const sortDirections = currentSortDirections.value;
          sortedList.value = sortBy(srcList, props.sortSpecs, sortDirections);
        };

        const onReSort = (newSortDirection: SortDirection): void => {
          if (!isSortable.value) {
            return;
          }
          currentSortDirections.value = mergeSortDirections(currentSortDirections.value, [newSortDirection]);
          updateSortedList(sortedList.value);
        };

        watch(
          () => props.list,
          (list) => {
            if (list.length === 0) {
              return;
            }
            if (!isSortable.value) {
              currentSortDirections.value = [];
              sortedList.value = list;
              return;
            }
            updateSortedList(list);
          },
          { immediate: true },
        );

        watch(
          () => props.sortSpecs,
          (sortSpecs) => {
            currentSortDirections.value = getDefaultSortDirection(sortSpecs);
            if (!isSortable.value) {
              return;
            }
            updateSortedList(sortedList.value);
          },
        );

        provide(GET_CURRENT_SORT_DIRECTIONS_KEY, () => readonly(currentSortDirections));
        provide(ON_SORT_FUNC_KEY, onReSort);
        provide(GET_IS_SORTABLE_KEY, () => readonly(isSortable));

        return {
          sortedList,
        };
      },
    });
  }
}

// componentに必要なrender() は default export に付加されるようなので、一旦型指定なしで呼び出したものを何かに入れて
// export default しておく
const main = new SortContextFactory().define();

export function useSortContext<T extends Record<string, any>>() {
  // 呼び出し側はこちらをimportして使う. 呼び出された時にその型で無理やりキャストしてやれば型情報付きで戻せる
  return main as ReturnType<SortContextFactory<T>['define']>;
}

export default main;
