
import { SEARCH_MAX_DATE_RANGE_1_WEEK } from 'src/consts';
import type { Workplace } from 'src/models/new/workplace';
import workplaceApi from 'src/apis/masters/workplace';
import DateSelector from 'src/components/Workplace/DateSelector.vue';
import { computed, defineComponent, onMounted, type PropType, reactive, watch, ref } from 'vue';
import { METRICS_TYPE_LOCAL_WORDS, type MetricsType } from 'src/models/new/metrics';
import { TIME_SPAN_LOCAL_WORDS, type TimeSpan } from 'src/business/timeSpan';
import { startOfToday } from 'src/util/datetime';

export type MetricsSearchPanelMode = 'mode1' | 'mode2';

export type SearchState = {
  name: string | null;
  workplaceId: number | null;
  timeSpan: TimeSpan | null;
  metricsType: MetricsType | null;
};
export type SearchStateWithDates = SearchState & {
  startDate: Date | null;
  endDate: Date | null;
};
const isSearchStateWithDates = (value: SearchState): value is SearchStateWithDates => {
  return 'startDate' in value && 'endDate' in value;
};
export type SearchStateWithIncludeDisabled = SearchState & {
  includeDisabled: boolean;
};
const isSearchStateWithIncludeDisabled = (value: SearchState): value is SearchStateWithIncludeDisabled => {
  return 'includeDisabled' in value;
};

type MetricsTypeOption = {
  value: MetricsType;
  label: string;
};

const METRICS_TYPE_OPTIONS: MetricsTypeOption[] = Object.keys(METRICS_TYPE_LOCAL_WORDS).map((key) => {
  return {
    value: key as MetricsType,
    label: METRICS_TYPE_LOCAL_WORDS[key as MetricsType],
  };
});

export type TimeSpanOption = {
  value: TimeSpan;
  label: string;
};

const TIME_SPAN_OPTIONS: TimeSpanOption[] = Object.keys(TIME_SPAN_LOCAL_WORDS).map((key) => {
  return {
    value: key as TimeSpan,
    label: TIME_SPAN_LOCAL_WORDS[key as TimeSpan],
  };
});

type State = {
  searchParams: SearchState;
  panelMode: MetricsSearchPanelMode;
  withDateSelector: boolean;
};

export default defineComponent({
  components: {
    DateSelector,
  },
  props: {
    // 検索条件初期値、途中で親コンポーネントから変更した場合は強制的に再代入できる
    value: {
      type: Object as PropType<SearchState>,
      default: () => {
        return {
          name: null,
          workplaceId: null,
          timeSpan: null,
          metricsType: null,
        };
      },
    },
    withDateRange: {
      type: Boolean,
      default: false,
    },
    withIncludeDisabled: {
      type: Boolean,
      default: false,
    },
    forcePanelModeTo: {
      type: String as PropType<MetricsSearchPanelMode>,
      default: null,
    },
    optWorkplaceCandidates: {
      type: Array as PropType<Workplace[]>,
    },
    optMetricsTypes: {
      type: Array as PropType<MetricsTypeOption[]>,
    },
    optTimeSpans: {
      type: Array as PropType<TimeSpanOption[]>,
    },
    searchImmediately: {
      type: Boolean,
    },
  },
  emits: ['input', 'on-search'],
  setup(props, { emit }) {
    const state: State = reactive({
      searchParams: computed({
        get() {
          return props.value;
        },
        set(value) {
          emit('input', value);
        },
      }),
      panelMode: props.forcePanelModeTo ?? 'mode1',
      withDateSelector: computed(() => {
        return (
          props.withDateRange &&
          isSearchStateWithDates(state.searchParams) &&
          state.searchParams.startDate !== null &&
          state.searchParams.endDate !== null
        );
      }),
    });

    const setDefaultSearchParams = (): void => {
      if (
        props.withDateRange &&
        (!isSearchStateWithDates(state.searchParams) || !state.searchParams.startDate || !state.searchParams.endDate)
      ) {
        const today = startOfToday();
        Object.assign(state.searchParams, {
          startDate: today,
          endDate: today,
        });
      }
      if (props.withIncludeDisabled && !isSearchStateWithIncludeDisabled(state.searchParams)) {
        Object.assign(state.searchParams, {
          includeDisabled: false,
        });
      }
    };

    const metricsTypes = computed(() => props.optMetricsTypes ?? METRICS_TYPE_OPTIONS);
    const timeSpans = computed(() => props.optTimeSpans ?? TIME_SPAN_OPTIONS);
    // FIXME: useWorkplaceOptionsへの切り替え
    const workplaceCandidates = ref<Workplace[]>([]);

    const isSearchButtonEnabled = computed<boolean>(() => {
      if (state.panelMode === 'mode1') {
        // タイプと周期が必須
        if (state.searchParams.metricsType === null) {
          return false;
        }
        if (state.searchParams.timeSpan === null) {
          return false;
        }
      } else {
        // 集計用センターが必須
        if (state.searchParams.workplaceId === null) {
          return false;
        }
      }
      // 日付範囲入力を有効にした場合は日付範囲が必須
      if (props.withDateRange && isSearchStateWithDates(state.searchParams)) {
        if (state.searchParams.startDate === null) {
          return false;
        }
        if (state.searchParams.endDate === null) {
          return false;
        }
      }
      return true;
    });

    const onSearch = (): void => {
      emit('on-search');
    };

    onMounted(async () => {
      if (props.optWorkplaceCandidates) {
        workplaceCandidates.value = props.optWorkplaceCandidates;
        watch(
          () => [props.optWorkplaceCandidates],
          () => {
            workplaceCandidates.value = props.optWorkplaceCandidates || [];
          },
        );
      } else {
        workplaceCandidates.value = await workplaceApi.index();
      }

      setDefaultSearchParams();
      if (props.searchImmediately) {
        onSearch();
      }
    });

    watch(
      () => [props.value],
      () => {
        setDefaultSearchParams();
      },
      { deep: true },
    );

    return {
      state,
      metricsTypes,
      workplaceCandidates,
      timeSpans,
      isSearchButtonEnabled,
      onSearch,
      SEARCH_MAX_DATE_RANGE_1_WEEK,
      isSearchStateWithDates,
    };
  },
});
