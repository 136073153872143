
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate';
import { computed, defineComponent, getCurrentInstance, onMounted, type PropType, reactive } from 'vue';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INVALID,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND,
  ERROR_REASON_METRICS_NOT_FOUND,
  ERROR_REASON_NAME_NOT_UNIQUE,
} from 'src/consts';
import {
  type SummaryMetrics,
  SUMMARY_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS,
} from 'src/models/new/Metrics/summaryMetrics';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import SelectSingleMetricsOnMetricsForm from 'src/views/Dashboard/Settings/Metrics/components/shared/SelectSingleMetricsOnMetricsForm.vue';
import MaxChainNotification from 'src/views/Dashboard/Settings/Metrics/components/shared/MaxChainNotification.vue';
import { requestTimeout } from 'src/util/request_animation_frame';
import workplaceApi from 'src/apis/masters/workplace';
import metricsApi from 'src/apis/masters/metrics';
import { isRecordIdValid, validRecordIdOrNull } from 'src/util/recordId';
import type { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import { type Metrics, metricsToTypeLocalWord, METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import { type TimeSpan, TIME_SPAN_DAILY, TIME_SPAN_LOCAL_WORDS, timeSpanToLocalWord } from 'src/business/timeSpan';
import type { Workplace } from 'src/models/new/workplace';
import { DAY_OF_WEEK_LOCAL_WORDS, type DayOfWeek } from 'src/util/week';
import type { AggregateFunction } from 'src/business/aggregateFunction';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useScalingOptions } from 'src/composables/options/useScalingOptions';
import { useNotifiers } from 'src/composables/useNotifiers';

type DowOption = { value: string; label: string };
const CLOSING_DAY_OF_WEEK_OPTIONS: DowOption[] = Object.keys(DAY_OF_WEEK_LOCAL_WORDS).map((el) => {
  return {
    value: el as DayOfWeek,
    label: DAY_OF_WEEK_LOCAL_WORDS[el as DayOfWeek],
  };
});
type AggregateFunctionOption = { value: AggregateFunction; label: string };
const AGGREGATE_FUNCTION_OPTIONS: AggregateFunctionOption[] = Object.keys(
  SUMMARY_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS,
).map((el) => {
  return {
    value: el as AggregateFunction,
    label: SUMMARY_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS[el as AggregateFunction],
  };
});

const FILTERED_TIME_SPAN_OPTIONS = Object.keys(TIME_SPAN_LOCAL_WORDS)
  .map((el) => {
    return {
      value: el as TimeSpan,
      label: TIME_SPAN_LOCAL_WORDS[el as TimeSpan],
    };
  })
  .filter((el) => el.value !== TIME_SPAN_DAILY);

interface State {
  // 各種マスタデータのロード完了を示すフラグ
  isLoaded: boolean;
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showMetricsAccessGroupSelectForm: boolean;
  showTargetMetricsSelectForm: boolean;
  // TODO: VeeValidate用のオプションを型付けする: 公式の型は個々のバリデーションタイプごとの型がなく、使うバリデーションタイプを列挙する必要がある
  validations: Record<string, Object>;
  hasError: boolean;
  metrics: SummaryMetrics;
  metricsIsNew: boolean;
  userSelectMetrics: Metrics | null;
  userSelectMetricsAccessGroups: MetricsAccessGroup[];
  // 時間があればworkplaceも新モデルに置き換える
  workplaceOptions: Workplace[];
  // プロパティとv-modelの接続を調整する
  validWorkplaceIdOrNull: number | null;
  weekDays: DayOfWeek[];
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';
const CONFIRM_METRICS_EVENT_KEY = 'confirmSelectMetricsForm';

export default defineComponent({
  components: {
    SelectAccessGroupForm,
    SelectSingleMetricsOnMetricsForm,
    MaxChainNotification,
    InputError,
    MetricsInputErrors,
  },
  props: {
    value: {
      type: Object as PropType<SummaryMetrics>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isLoaded: false,
      isRequesting: false,
      showMetricsAccessGroupSelectForm: false,
      showTargetMetricsSelectForm: false,
      validations: computed(() => {
        return {
          name: { required: true, max: 50 },
          workplaceId: { required: true },
          unit: { required: false, max: 10 },
          decimalPlaces: { required: true, min_value: 0, max_value: 2, numeric: true },
          targetMetricsId: { required: true, min_value: 1 },
          aggrFunc: { required: true },
          weekDays: { required: true },
        };
      }),
      hasError: computed(() => vvHasError(root)),
      metrics: computed({
        get() {
          return props.value;
        },
        set(next) {
          emit('input', structuredClone(next));
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      userSelectMetrics: null,
      userSelectMetricsAccessGroups: computed({
        get(): MetricsAccessGroup[] {
          return metricsAccessGroupsOnMetricsWithoutAdmin.value;
        },
        set(value: MetricsAccessGroup[]) {
          metricsAccessGroupsOnMetrics.value = metricsAccessGroupOnMetricsWithAdmin.value
            ? [metricsAccessGroupOnMetricsWithAdmin.value, ...value]
            : value;
        },
      }),
      validWorkplaceIdOrNull: computed({
        get() {
          return validRecordIdOrNull(state.metrics.workplaceId);
        },
        set(value) {
          state.metrics.workplaceId = value || 0;
        },
      }),
      workplaceOptions: [],
      weekDays: computed({
        get(): DayOfWeek[] {
          return (Object.keys(state.metrics.weekDays) as DayOfWeek[]).filter((el) => state.metrics.weekDays[el]);
        },
        set(values: DayOfWeek[]) {
          const weekDays: Record<DayOfWeek, boolean> = {
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
          };
          values.forEach((dow) => {
            weekDays[dow] = true;
          });
          state.metrics.weekDays = weekDays;
        },
      }),
    });

    const { notifyError } = useNotifiers();

    const {
      metricsAccessGroupsOnMetrics,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      metricsAccessGroupOnMetricsWithAdmin,
      loadMetricsAccessGroupsOnMetrics,
    } = useMetricsAccessGroupsOfMetrics();

    const { options: scalingOptions } = useScalingOptions();

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    const setWorkplaceOptions = async () => {
      state.workplaceOptions = await workplaceApi.index();

      // 設定されてない場合は一番上のセンターを初期値とする
      if (state.workplaceOptions.length > 0 && !state.validWorkplaceIdOrNull) {
        state.validWorkplaceIdOrNull = state.workplaceOptions[0].id;
      }
    };

    const setTargetMetrics = async () => {
      if (!state.metrics.targetMetricsId) {
        return;
      }
      state.userSelectMetrics = await metricsApi.show(state.metrics.targetMetricsId);
    };

    const goToAccessGroupSelectForm = (): void => {
      state.showMetricsAccessGroupSelectForm = true;
    };
    const goToTargetMetricsSelectForm = (): void => {
      state.showTargetMetricsSelectForm = true;
    };
    const backToMainForm = (): void => {
      state.showMetricsAccessGroupSelectForm = false;
      state.showTargetMetricsSelectForm = false;
    };

    const setSelectedAccessGroups = (metricsAccessGroups: MetricsAccessGroup[]): void => {
      state.userSelectMetricsAccessGroups = metricsAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmAccessGroupEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerAccessGroupConfirmEvent = () => {
      confirmAccessGroupEventTriggerer.trigger();
    };

    const setSelectedMetrics = (metrics: Metrics | null): void => {
      state.metrics.targetMetricsId = metrics?.id ?? 0;
      state.userSelectMetrics = metrics;
      backToMainForm();
    };

    const { triggerer: confirmMetricsEventTriggerer } = useSimpleEvent(CONFIRM_METRICS_EVENT_KEY);

    const triggerMetricsConfirmEvent = () => {
      confirmMetricsEventTriggerer.trigger();
    };

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      const metrics: SummaryMetrics = {
        ...state.metrics,
        accessGroupIds: state.userSelectMetricsAccessGroups.map((metricsAccessGroup) => metricsAccessGroup.id),
      };
      const operationWord = state.metricsIsNew ? '作成' : '更新';
      try {
        if (state.metricsIsNew) {
          await metricsApi.create(metrics);
        } else {
          await metricsApi.update(metrics);
        }
        emit('updated', `${state.metrics.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = state.metricsIsNew ? 'ERR00001' : 'ERR00002';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          const msg = '選択したメトリクスは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したメトリクスグループは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND) {
          const msg = '選択した集計用センターは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) {
          notifyError(errRes.message, ERROR_GROUP_USER, err);
        } else if (errStatus === 409 && errRes.reason === ERROR_REASON_NAME_NOT_UNIQUE) {
          const msg = '入力されたメトリクス名は既に使用されています';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else {
          const msg = `メトリクスの${operationWord}に失敗しました。管理者に連絡してください。`;
          notifyError(msg, ERROR_GROUP_SYSTEM, err, errId);
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsAccessGroupSelectForm = false;

      await Promise.all([loadMetricsAccessGroupsOnMetrics(state.metrics), setWorkplaceOptions(), setTargetMetrics()]);

      state.isLoaded = true;
    });

    return {
      props,
      state,
      AGGREGATE_FUNCTION_OPTIONS,
      FILTERED_TIME_SPAN_OPTIONS,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      CLOSING_DAY_OF_WEEK_OPTIONS,
      getError,
      getErrorObject,
      goToAccessGroupSelectForm,
      goToTargetMetricsSelectForm,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      setSelectedMetrics,
      CONFIRM_METRICS_EVENT_KEY,
      triggerAccessGroupConfirmEvent,
      triggerMetricsConfirmEvent,
      save,
      close,
      metricsToTypeLocalWord,
      timeSpanToLocalWord,
      scalingOptions,
    };
  },
});
