import axios from 'axios';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';
import { convertFromMetricsResponseData, MetricsValueResponseData } from 'src/models/api/Metrics/metricsResponseData';
import { Metrics } from 'src/models/new/metrics';
import {
  convertMetricsValueIndexRequestParametersToLegacy,
  MetricsValueIndexRequestParameters,
} from 'src/models/api/Metrics/MetricsValue/metricsValueIndexRequestParameters';
import { buildBackendUrl } from 'src/apis/util/url';
import { MetricsValueUpsertRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueUpsertRequestParameters';
import { MetricsValueCheckAuthorizationRequestParameters } from 'src/models/api/Metrics/MetricsValue/metricsValueCheckAuthorizationRequestParameters';

const buildBasePath = (): string => '/metrics_values';

export default {
  async index(data: MetricsValueIndexRequestParameters): Promise<{ result: Metrics[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url, { params: convertMetricsValueIndexRequestParametersToLegacy(data) });
    return {
      result: (resData.result as MetricsValueResponseData[]).map((v) =>
        convertFromMetricsResponseData(v.metrics, v, true),
      ),
      pagination: convertFromPagination(resData.pagination),
    };
  },
  async upsert(data: MetricsValueUpsertRequestParameters): Promise<Metrics> {
    const url = buildBackendUrl(buildBasePath(), 'upsert');
    const { data: resData } = await axios.post(url, data);
    return convertFromMetricsResponseData(resData.metrics, resData, true);
  },
  async checkAuthorization(data: MetricsValueCheckAuthorizationRequestParameters): Promise<{}> {
    // 権限があるかどうかを確認するメソッドなので、400とかが返らなければOK
    const url = buildBackendUrl(buildBasePath(), 'check_authorization');
    const { data: resData } = await axios.get(url, { params: data });
    return resData;
  },
};
