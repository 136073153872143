import axios from 'axios';
import { convertToUserViewSettingRequestParameters } from 'src/models/api/userViewSettingRequest';
import { convertFromUserViewSettingResponse } from 'src/models/api/userViewSettingResponse';
import { UserViewSetting } from 'src/models/new/userViewSetting';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/user_view_setting';

export default {
  async show(): Promise<UserViewSetting> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return convertFromUserViewSettingResponse(resData);
  },
  async upsert(data: UserViewSetting): Promise<Object> {
    const params = convertToUserViewSettingRequestParameters(data);
    const url = buildBackendUrl(buildBasePath(), 'upsert');
    await axios.put(url, params);
    return {};
  },
};
