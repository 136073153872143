import {
  MetricsGraphComponentConditionalStatement,
} from 'src/models/new/ConditionalStatement/metricsGraphComponentConditionalStatement'
import { MetricsGraphComponent, MetricsGraphReferenceForBorderLine } from '../metricsGraphComponent'
import { Metrics } from 'src/models/new/metrics'
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementThresholdReference,
  thresholdValueFromConditionalStatement,
} from 'src/models/new/conditionalStatement'
import { compareWithComparator } from 'src/util/comparator'
import {
  GRAPH_LAYOUT_SCALE_POSITION_LEFT,
  GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
  GraphLayout,
  GraphLayoutScalePosition,
  switchGraphLayoutPlotTypes,
} from 'src/business/graphLayout'
import { MetricsTransitionComponentProperties } from '../metricsTransitionComponentProperties'
import { Component, internal } from 'src/models/new/component'
import { constructPeriod, PERIOD_THIS_WEEK } from 'src/business/period'
import { isTimeSpanLarger, TimeSpan } from 'src/business/timeSpan'
import { SYSTEM_DATETIME_FORMAT } from 'src/util/Datetime/format'
import { hashedColorFromGraphDecoration } from 'src/models/new/ConditionalStatement/Decorations/graphDecoration'

const METRICS_GRAPH_TRANSITION_GRAPH = internal.METRICS_GRAPH_TRANSITION_GRAPH

export type MetricsTransitionGraphPlot = {
  closingDate: Date
  metrics: Metrics
  conditionalStatements: MetricsGraphComponentConditionalStatement[]
}

export type MetricsTransitionGraph = GraphLayout & {
  label: string | null
  color: string
  metrics: Metrics | null
  conditionalStatements: MetricsGraphComponentConditionalStatement[]
  plots: MetricsTransitionGraphPlot[] | null
}

export const isMetricsTransitionGraphPlotTypeBar = (graph: MetricsTransitionGraph): boolean => {
  return graph.plotType === 'bar'
}
export const isMetricsTransitionGraphPlotTypeLine = (graph: MetricsTransitionGraph): boolean => {
  return graph.plotType === 'line'
}
export const isMetricsTransitionGraphAcceptingConditionalStatements = (graph: MetricsTransitionGraph): boolean => {
  return !isMetricsTransitionGraphPlotTypeLine(graph)
}

export type MetricsTransitionGraphComponent = MetricsGraphComponent & MetricsTransitionComponentProperties & {
  closingDates: Date[]
  data: {
    graphs: MetricsTransitionGraph[]
    reference: MetricsGraphReferenceForBorderLine | null
  }
  scaleUnits: Record<GraphLayoutScalePosition, string | null>
}

export const isComponentTypeMetricsTransitionGraph = (component: Component): component is MetricsTransitionGraphComponent => {
  return component.componentType === METRICS_GRAPH_TRANSITION_GRAPH
}

const DEFAULT_WIDTH = 2
const DEFAULT_HEIGHT = 2

export const constructEmptyMetricsTransitionGraphComponent = (): MetricsTransitionGraphComponent => {
  const emptyGraph: MetricsTransitionGraph = {
    scale: GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
    number: 1,
    plotType: 'bar',
    label: null,
    color: '',
    metrics: null,
    conditionalStatements: [],
    plots: null,
  }

  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_GRAPH_TRANSITION_GRAPH,
    abscissa: 0,
    ordinate: 0,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    referenceDate: null,
    name: '',
    period: constructPeriod(PERIOD_THIS_WEEK),
    isTodayDisplayed: true,
    isFutureDisplayed: false,
    isDescendingDateOrder: false,
    dateFormat: SYSTEM_DATETIME_FORMAT,
    dispDaysRewindPeriod: null,
    closingDates: [],
    data: {
      graphs: [
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_LEFT,
          number: 1,
          plotType: 'bar',
        },
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_LEFT,
          number: 2,
          plotType: 'bar',
        },
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
          number: 1,
          plotType: 'line',
        },
        {
          ...structuredClone(emptyGraph),
          scale: GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
          number: 2,
          plotType: 'line',
        },
      ],
      reference: null,
    },
    scaleUnits: {
      left: null,
      right: null,
    },
  }
}

const getBaseValue = (
  component: MetricsTransitionGraphComponent,
  plot: MetricsTransitionGraphPlot,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) return plot.metrics.value ?? null
  if (isConditionalStatementBaseReference(conditionalStatement)) {
    return component.data.reference?.metrics.value ?? null
  }
  return baseValueFromConditionalStatement(conditionalStatement, plot.closingDate)
}

const getThresholdValue = (
  component: MetricsTransitionGraphComponent,
  plot: MetricsTransitionGraphPlot,
  conditionalStatement: MetricsGraphComponentConditionalStatement,
): number | null => {
  if (isConditionalStatementThresholdReference(conditionalStatement)) {
    return component.data.reference?.metrics.value ?? null
  }
  return thresholdValueFromConditionalStatement(conditionalStatement, plot.closingDate)
}

const extractPriorTargetDecoration = (
  component: MetricsTransitionGraphComponent, graph: GraphLayout, plotOrder: number,
): MetricsGraphComponentConditionalStatement | null => {
  const plot = component.data.graphs
    .find(el => el.scale === graph.scale && el.number === graph.number)?.plots?.[plotOrder - 1]
  return plot?.conditionalStatements
    .sort((a, b) => b.priority - a.priority)
    .reduce((found: MetricsGraphComponentConditionalStatement | null, conditionalStatement: MetricsGraphComponentConditionalStatement) => {
      if (found) return found
      const base = getBaseValue(component, plot, conditionalStatement)
      if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
        return compareWithComparator(base, conditionalStatement.comparator, null)
          ? conditionalStatement
          : null
      }
      const threshold = getThresholdValue(component, plot, conditionalStatement)
      if (base === null || threshold === null) return found
      if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) return found
      return conditionalStatement
    }, null) ?? null
}

export const applyConditionalStatementToMetricsTransitionGraphComponentColor = (
  component: MetricsTransitionGraphComponent, graph: GraphLayout, plotOrder: number): string | null => {
  if (!component.referenceDate) return null
  const decoration = extractPriorTargetDecoration(component, graph, plotOrder)?.decoration
  return decoration ? hashedColorFromGraphDecoration(decoration) : null
}

export const switchMetricsTransitionGraphComponentPlotType = (
  component: MetricsTransitionGraphComponent,
  graphLayout: GraphLayout,
): MetricsTransitionGraphComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      graphs: switchGraphLayoutPlotTypes(component.data.graphs, graphLayout) as MetricsTransitionGraph[],
    },
  }
}

export const specifyMetricsTransitionGraphComponentDominantTimeSpan = (component: MetricsTransitionGraphComponent): TimeSpan | null => {
  return component.data.graphs
    .reduce((dominantTimeSpan: TimeSpan | null, graph: MetricsTransitionGraph) => {
      if (!graph.metrics) return dominantTimeSpan
      const timeSpan = graph.metrics!.timeSpan
      if (!dominantTimeSpan) return timeSpan
      return isTimeSpanLarger(timeSpan, dominantTimeSpan) ? timeSpan : dominantTimeSpan
    }, null)
}

// plotsには参考値は含まない
// グラフのplotに当たる設定を全て削除した状態のコンポーネントを返す
export const clearTransitionGroupedGraphPlot = (component: MetricsTransitionGraphComponent): MetricsTransitionGraphComponent => {
  return {
    ...component,
    data: {
      ...component.data,
      graphs: component.data.graphs.map(graph => {
        return {
          ...graph,
          label: null,
          color: '',
          metrics: null,
          conditionalStatements: [],
          plots: null,
        }
      }),
    },
  }
}
