
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import userApi from 'src/apis/masters/user';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { User } from 'src/models/user';
import { vvValidate } from 'src/util/vee_validate';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import { requestTimeout } from 'src/util/request_animation_frame';
import { ERROR_REASON_CANNOT_REMOVE_ADMIN_ROLE, ERROR_REASON_INVALID } from 'src/consts';
import { AccessGroup } from 'src/models/new/accessGroup';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { convertFromMetricsAccessGroupResponseData } from 'src/models/api/AccessGroup/MetricsAccessGroup/metricsAccessGroupResponseData';
import { convertFromReportAccessGroupResponseData } from 'src/models/api/AccessGroup/ReportAccessGroup/reportAccessGroupResponseData';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';
import { METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import { REPORT_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/report';

type SaveCandidate = {
  id?: number;
  fullName: string;
  metricsAccessGroups: AccessGroup[];
  reportAccessGroups: AccessGroup[];
};

interface State {
  list: User[];
  showSaveModal: boolean;
  saveCandidate: SaveCandidate;
  hasPageAccessRole: boolean;
  hasMetricsAdminRole: boolean;
  hasReportAdminRole: boolean;
  isProcessingResult: boolean;
  // アクセスグループ選択モーダル
  showMetricsAccessGroupSelectionModal: boolean;
  showReportAccessGroupSelectionModal: boolean;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    list: [],
    showSaveModal: false,
    saveCandidate: {
      fullName: '',
      metricsAccessGroups: [],
      reportAccessGroups: [],
    },
    metricsAccessGroups: [],
    reportAccessGroups: [],
    hasPageAccessRole: true,
    hasMetricsAdminRole: computed(() => root.$store.getters['user/hasMetricsAdminRole']),
    hasReportAdminRole: computed(() => root.$store.getters['user/hasReportAdminRole']),
    isProcessingResult: false,
    // アクセスグループ選択モーダル
    showMetricsAccessGroupSelectionModal: false,
    showReportAccessGroupSelectionModal: false,
  });

  return state;
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';
const ITEM_PER_PAGE = 50;

export default defineComponent({
  components: {
    SelectAccessGroupForm,
    PaginationContainer: usePaginationContainer<User>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'ユーザー設定');
    const state = setupState(root);

    function openSaveModal(user: User) {
      state.saveCandidate = {
        id: user.id,
        fullName: user.full_name,
        metricsAccessGroups:
          user.metrics_access_groups?.map((metricsAccessGroup) => {
            return convertFromMetricsAccessGroupResponseData(metricsAccessGroup);
          }) ?? [],
        reportAccessGroups:
          user.report_access_groups?.map((reportAccessGroup) => {
            return convertFromReportAccessGroupResponseData(reportAccessGroup);
          }) ?? [],
      };
      state.showSaveModal = true;
      if (!state.hasMetricsAdminRole) {
        state.saveCandidate.metricsAccessGroups = [];
      }
      if (!state.hasReportAdminRole) {
        state.saveCandidate.reportAccessGroups = [];
      }
    }

    function closeSaveModal() {
      closeAccessGroupSelectionModal();
      state.showSaveModal = false;
    }

    async function getList(): Promise<void> {
      try {
        const response = await userApi.index();
        state.list = response.result;
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    async function saveItem(): Promise<void> {
      // 二重処理防止
      if (state.isProcessingResult) {
        return;
      }
      state.isProcessingResult = true;
      requestTimeout(() => {
        state.isProcessingResult = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      try {
        const params = {
          metrics_access_groups: state.saveCandidate.metricsAccessGroups.map((metricsAccessGroup) => {
            return { id: metricsAccessGroup.id };
          }),
          report_access_groups: state.saveCandidate.reportAccessGroups.map((reportAccessGroup) => {
            return { id: reportAccessGroup.id };
          }),
        };
        await userApi.update(state.saveCandidate.id!, params);
        await getList();
        closeSaveModal();
        notifySuccess1(root, 'グループを更新しました');
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          if (errRes.reason === ERROR_REASON_CANNOT_REMOVE_ADMIN_ROLE) {
            notifyError1(root, '自身の管理グループは削除できません。', { err });
          } else {
            notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
          }
        } else {
          if (errRes.reason === ERROR_REASON_INVALID) {
            notifyError1(root, errRes.message, { err });
          } else {
            const msg = '更新に失敗しました。管理者に連絡してください。';
            notifyError1(root, msg, { err });
          }
        }
      }
    }

    // アクセスグループ
    function openMetricsAccessGroupSelectionModal(): void {
      state.showMetricsAccessGroupSelectionModal = true;
    }

    function openReportAccessGroupSelectionModal(): void {
      state.showReportAccessGroupSelectionModal = true;
    }

    function closeAccessGroupSelectionModal(): void {
      state.showMetricsAccessGroupSelectionModal = false;
      state.showReportAccessGroupSelectionModal = false;
    }

    const setSelectedMetricsAccessGroups = (accessGroups: Array<AccessGroup>): void => {
      state.saveCandidate.metricsAccessGroups = [...accessGroups];
      closeAccessGroupSelectionModal();
    };

    const setSelectedReportAccessGroups = (accessGroups: Array<AccessGroup>): void => {
      state.saveCandidate.reportAccessGroups = [...accessGroups];
      closeAccessGroupSelectionModal();
    };

    const { triggerer: confirmEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerConfirmEvent = () => {
      confirmEventTriggerer.trigger();
    };

    onMounted(async () => {
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);
      await getList();
    });

    return {
      ITEM_PER_PAGE,
      state,
      openSaveModal,
      closeSaveModal,
      saveItem,
      openMetricsAccessGroupSelectionModal,
      openReportAccessGroupSelectionModal,
      closeAccessGroupSelectionModal,
      setSelectedMetricsAccessGroups,
      setSelectedReportAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerConfirmEvent,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      REPORT_ACCESS_GROUP_MAX_COUNT,
    };
  },
});
