import { computed, type ComputedRef } from 'vue';
import metricsAccessGroupApi from 'src/apis/masters/metricsAccessGroup';
import type { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import type { Metrics } from 'src/models/new/metrics';
import { isAccessGroupAdmin } from 'src/models/new/accessGroup';
import { useAsyncResourcesContext, useDynamicAsyncResources } from 'src/composables/asyncResources/useAsyncResources';
import { generateInjectionKey } from 'src/composables/asyncResources/logics/generateInjectionKey';

// 本当はメトリクスごとのアクセスグループではなく、ユーザーが参照可能なレコード全てProvideして
// idの入力を受けて対応するリソースを取り出せる形にしたい
// しかし、現状ではindexエンドポイントから得られるデータが最大50件しかないので、全てを取ってくることができない
// そこでとりあえず1つのメトリクスに属しているアクセスグループのみ共有するようにした

type MetricsAccessGroupsOnMetricsResult = {
  metricsAccessGroupsOnMetrics: ReturnType<typeof useDynamicAsyncResources<MetricsAccessGroup, Metrics>>['resources'];
  metricsAccessGroupsOnMetricsWithoutAdmin: ComputedRef<MetricsAccessGroup[]>;
  metricsAccessGroupOnMetricsWithAdmin: ComputedRef<MetricsAccessGroup | null>;
  loadMetricsAccessGroupsOnMetrics: (metrics: Metrics) => Promise<void>;
};

const key = generateInjectionKey('metricsAccessGroupsOnMetrics');

const fetchMetricsAccessGroups = async (metrics: Metrics) => {
  const loadedMetricsAccessGroups = await metricsAccessGroupApi.byMetrics(metrics);
  return loadedMetricsAccessGroups.sort((a, b) => (a.id > b.id ? 1 : -1));
};

export const useMetricsAccessGroupsOfMetricsContext = () => {
  useAsyncResourcesContext<MetricsAccessGroup, Metrics>({ key, fetcher: fetchMetricsAccessGroups });
};

export const useMetricsAccessGroupsOfMetrics = (): MetricsAccessGroupsOnMetricsResult => {
  const { resources: metricsAccessGroupsOnMetrics, load: loadMetricsAccessGroupsOnMetrics } = useDynamicAsyncResources<
    MetricsAccessGroup,
    Metrics
  >({ key });

  const metricsAccessGroupsOnMetricsWithoutAdmin = computed((): MetricsAccessGroup[] => {
    return metricsAccessGroupsOnMetrics.value.filter((el: MetricsAccessGroup) => !isAccessGroupAdmin(el));
  });

  const metricsAccessGroupOnMetricsWithAdmin = computed((): MetricsAccessGroup | null => {
    return metricsAccessGroupsOnMetrics.value.find((el) => isAccessGroupAdmin(el)) ?? null;
  });

  return {
    metricsAccessGroupsOnMetrics,
    metricsAccessGroupsOnMetricsWithoutAdmin,
    metricsAccessGroupOnMetricsWithAdmin,
    loadMetricsAccessGroupsOnMetrics,
  };
};
