import { strLength } from './text_decorator';

export function getFontSizeBasedOnLength(
  text: string,
  lengthBorderFirst: number,
  lengthBorderSecond: number,
  ratio: number = 1,
): string {
  const length = strLength(text);
  let size;
  if (length < lengthBorderFirst) {
    size = 1 * ratio;
  } else if (length < lengthBorderSecond) {
    size = 0.85 * ratio;
  } else {
    size = 0.8 * ratio;
  }
  return `${size}em`;
}

export const calculateFontSizeForNumericString = (text: string, maxFontSize: number, containerWidth: number) => {
  const numNumbers = text.replace(/[^0-9]/g, '').length;
  const numSymbols = text.replace(/[^,.-]/g, '').length;
  if (numNumbers + numSymbols !== text.length) {
    // 数値、カンマ、ドット、負号以外の文字が含まれている場合は、最大フォントサイズを返す
    return maxFontSize;
  }

  // フォントサイズ1pxあたりの数値、カンマ、ドット、負号のおおよその幅を元に文字列全体の幅を概算する
  const numberWidth = 0.6;
  const symbolWidth = 0.4;
  const totalWidth = numNumbers * numberWidth + numSymbols * symbolWidth;

  return Math.min(maxFontSize, containerWidth / totalWidth);
};
