import { TimeInteger } from 'src/models/common';

// TimeInteger関連の操作を分離
export function dateToTimeInteger(dt: Date): TimeInteger {
  const h = dt.getHours();
  const m = dt.getMinutes();
  const s = dt.getSeconds();
  return h * 10000 + m * 100 + s;
}

export function ensureTimeInteger(t: any): TimeInteger {
  if (t instanceof Date) {
    t = dateToTimeInteger(t);
  }
  if (isNaN(t)) {
    return NaN;
  }
  return t;
}

export function packToTimeInteger(h: number, m: number, s: number): TimeInteger {
  return Math.floor(h) * 10000 + Math.floor(m) * 100 + Math.floor(s);
}

export function packToTimeIntegerWithGuard(_h: any, _m: any, _s: any): TimeInteger | null {
  function toValidNumberOrNull(x: any): number | null {
    if (x === null || x === undefined) {
      return null;
    }
    const num = parseInt(x);
    return isNaN(num) ? null : num;
  }
  const h = toValidNumberOrNull(_h);
  const m = toValidNumberOrNull(_m);
  const s = toValidNumberOrNull(_s);
  if (h === null || m === null || s === null) {
    return null;
  }
  return packToTimeInteger(h, m, s);
}

export function unpackTimeIntegerToString(timeInt: TimeInteger): [string, string, string] {
  const [h, m, s] = unpackTimeInteger(timeInt);
  const hStr = ('0' + h).slice(-2);
  const mStr = ('0' + m).slice(-2);
  const sStr = ('0' + s).slice(-2);
  return [hStr, mStr, sStr];
}

export function unpackTimeInteger(timeInt: TimeInteger): [number, number, number] {
  let tmp = Math.floor(timeInt);
  const h = Math.floor(timeInt / 10000);
  tmp -= h * 10000;
  const m = Math.floor(tmp / 100);
  const s = tmp % 100;
  return [h, m, s];
}

export function timeDifferenceInSeconds(t1_: any, t2_: any): number {
  const t1 = ensureTimeInteger(t1_);
  const t2 = ensureTimeInteger(t2_);
  const [h1, m1, s1] = unpackTimeInteger(t1);
  const [h2, m2, s2] = unpackTimeInteger(t2);
  const sumSec1 = h1 * 3600 + m1 * 60 + s1;
  const sumSec2 = h2 * 3600 + m2 * 60 + s2;
  return sumSec2 - sumSec1;
}

export function secondsToTimeInteger(seconds: number, noDigitGuard: boolean = false): TimeInteger {
  // 一旦正に戻してから計算
  const sign = seconds < 0 ? -1 : 1;
  seconds *= sign;

  let h = Math.floor(seconds / 3600);
  if (!noDigitGuard) {
    h = Math.min(h, 99); // digit guard
  }
  const tmp = seconds % 3600;
  const m = Math.floor(tmp / 60);
  const s = tmp % 60;

  const mStr = ('0' + m).slice(-2);
  const sStr = ('0' + s).slice(-2);
  return parseInt(`${h}${mStr}${sStr}`) * sign;
}

export function secondsToTimeIntegerWithNoDigitGuard(seconds: number): TimeInteger {
  return secondsToTimeInteger(seconds, true);
}

export function timeIntegerToSeconds(tm: TimeInteger): number {
  // 一旦正に戻してから計算
  const sign = tm < 0 ? -1 : 1;
  tm *= sign;

  const h = Math.floor(tm / 10000);
  const tmp = tm % 10000;
  const m = Math.floor(tmp / 100);
  const s = tmp % 100;

  return (h * 3600 + m * 60 + s) * sign;
}

export function timeIntegerToFiveMinutes(tm: TimeInteger): number {
  const time = Math.ceil(timeIntegerToSeconds(tm) / 60 / 5) * 60 * 5;
  return time;
}

export function timeIntegerToFiveMinutesString(tm: TimeInteger): string {
  const time = timeIntegerToFiveMinutes(tm);
  const timeArr = unpackTimeIntegerToString(secondsToTimeInteger(time, true));
  return timeArr[0] + ':' + timeArr[1];
}

export function timeIntegerAdd(tm1: TimeInteger, tm2: TimeInteger): TimeInteger {
  const sec1 = timeIntegerToSeconds(tm1);
  const sec2 = timeIntegerToSeconds(tm2);
  return secondsToTimeInteger(sec1 + sec2);
}
