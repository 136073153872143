
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import { createSidebarStore, SidebarLink, SidebarStore } from 'src/components/UIComponents/Sidebar/createSidebarStore'
import { resolvedSidebarLinksMap } from './sidebarLinkGroups'
import { requestTimeout } from 'src/util/request_animation_frame'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import { getSidebarLinkGroupNameFromRoute } from 'src/hooks/routeHook'
import { Route } from 'vue-router'
import { defineComponent, getCurrentInstance, reactive, ref } from 'vue'

function deepCopy<T>(x: T): T {
  return JSON.parse(JSON.stringify(x))
}

interface State {
  sidebarStore: SidebarStore
  appTitle: string
  appLogo: string
  homeRouteInfo: {
    name: string
  },
}

function setupState(): State {
  const sidebarStore = createSidebarStore()
  const initialSbLinkGroupName = 'top'
  sidebarStore.sidebarLinks = deepCopy(resolvedSidebarLinksMap[initialSbLinkGroupName])
  return reactive({
    sidebarStore: sidebarStore,
    appTitle: 'Logiscope',
    appLogo: '/static/img/logiscope/logo.png',
    homeRouteInfo: {
      name: 'ReportValues',
    },
  })
}

export default defineComponent({
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  setup(_props) {
    const root = getCurrentInstance()!.proxy
    const state = setupState()
    const refMainWrapper = ref<HTMLElement>()

    const updateSidebarItems = (to: Route, _from?: Route) => {
      state.sidebarStore.sidebarLinks = []
      ensureUserAndMasters(root).then(() => {
        // サイドバー表示切替
        const linkGroupName = getSidebarLinkGroupNameFromRoute(to)
        const sidebarLinks: SidebarLink[] = resolvedSidebarLinksMap[linkGroupName]
        // if (!sidebarLinks) {
        //   sidebarLinks = resolveLinkGroupData(deepCopy(unresolvedSidebarLinksMap[linkGroupName]), to)
        // }
        // sidebarLinks = sidebarLinks.filter(ent => {
        //   if (ent.requireWorkplaceIsWmsCsv) {
        //     return root.$store.getters['workplace/isWmsCsv']
        //   }
        //   return true
        // })

        state.sidebarStore.sidebarLinks = sidebarLinks
      })
    }
    const scrollToTop = (_to: Route, _from: Route) => {
      // 250msはヒューリスティックな値なので、完璧ではない.
      // 前の画面がfadeoutした後且つ次の画面がfadeinしてくる前のタイミングを
      // うまく狙おうとした値.
      requestTimeout(() => {
        if (refMainWrapper.value) {
          refMainWrapper.value.scrollTop = 0
        }
      }, 250)
    }
    root.$router.afterEach(updateSidebarItems)
    root.$router.afterEach(scrollToTop)
    updateSidebarItems(root.$route)

    function toggleSidebar() {
      if (state.sidebarStore.showSidebar) {
        state.sidebarStore.displaySidebar(false)
      }
    }

    return {
      state,
      toggleSidebar,
      refMainWrapper,
    }
  },
})
