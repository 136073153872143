import axios from 'axios';
import qs from 'qs';
import { Report } from 'src/models/new/report';
import { convertFromReportResponseData, ReportResponseData } from 'src/models/api/Report/reportResponseData';
import { ReportIndexRequestParameters } from 'src/models/api/Report/reportIndexRequestParameters';
import { convertToReportCreateRequestParameters } from 'src/models/api/Report/reportCreateRequestParameters';
import { convertToReportUpdateRequestParameters } from 'src/models/api/Report/reportUpdateResponseParameters';
import { convertFromReportCopyResponseData } from 'src/models/api/Report/reportCopyResponseData';
import { convertFromReportCreateResponseData } from 'src/models/api/Report/reportCreateResponseData';
import { RecordId } from 'src/util/recordId';
import { ReportDeepUpdateWordsRequestParameters } from 'src/models/api/Report/reportDeepUpdateWordsRequestParameters';
import { ReportDeepUpdateMetricsRequestParameters } from 'src/models/api/Report/reportDeepUpdateMetricsRequestParameters';
import { buildBackendUrl } from 'src/apis/util/url';
import { PaginatedMultiDataResponse } from 'src/models/api/shared/multiDataResponse';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';

const buildBasePath = (): string => '/masters/reports';

export default {
  async index(data: ReportIndexRequestParameters): Promise<{ result: Report[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<ReportResponseData>>(url, {
      params: data,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
    return {
      result: resData.result.map(convertFromReportResponseData),
      pagination: convertFromPagination(resData.pagination),
    };
  },
  async show(id: number): Promise<Report> {
    const url = buildBackendUrl(buildBasePath(), id);
    const { data: resData } = await axios.get<ReportResponseData>(url);
    return convertFromReportResponseData(resData);
  },
  async delete(id: number): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), id);
    await axios.delete(url);
    return {};
  },
  async create(params: Report): Promise<{ id: RecordId }> {
    const url = buildBackendUrl(buildBasePath());
    const convertedParams = convertToReportCreateRequestParameters(params);
    const { data: resData } = await axios.post(url, convertedParams);
    return convertFromReportCreateResponseData(resData);
  },
  async update(params: Report): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), params.id);
    const convertedParams = convertToReportUpdateRequestParameters(params);
    await axios.put(url, convertedParams);
    return {};
  },
  async copy(id: number): Promise<{ id: RecordId }> {
    const url = buildBackendUrl(buildBasePath(), id, 'copy');
    const { data: resData } = await axios.post(url);
    return convertFromReportCopyResponseData(resData);
  },
  async deepUpdateWords(params: ReportDeepUpdateWordsRequestParameters): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), params.id, 'deep_update_words');
    await axios.put(url, params);
    return {};
  },
  async deepUpdateMetrics(params: ReportDeepUpdateMetricsRequestParameters): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), params.id, 'deep_update_metrics');
    await axios.put(url, params);
    return {};
  },
};
