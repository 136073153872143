
import { computed, defineComponent, onMounted, PropType, reactive, watch } from 'vue';
import Draggable from 'vuedraggable';
import { omitString } from 'src/util/text_decorator';
import SelectSingleMetricsForm from 'src/components/NewSelectItemForm/SelectSingleMetricsForm.vue';
import InputWarn from 'src/components/InputWarn.vue';
import { ALL_TIME_SPANS, TimeSpan, timeSpanToLocalWord } from 'src/business/timeSpan';
import workplaceApi from 'src/apis/masters/workplace';
import { Workplace } from 'src/models/new/workplace';
import { Metrics } from 'src/models/new/metrics';
import { MetricsListComponentConditionalStatement } from 'src/models/new/ConditionalStatement/metricsListComponentConditionalStatement';
import { MetricsTableComponentConditionalStatement } from 'src/models/new/ConditionalStatement/metricsTableComponentConditionalStatement';
import { swap } from 'src/util/array';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useSharedValue } from 'src/composables/useSharedValue';

export type TableDataSetCell = {
  label: string;
  isTopHeader: boolean;
  isLeftHeader: boolean;
  isMoving: boolean;
  metrics: Metrics | null;
  allowedMetricsTimeSpans: TimeSpan[];
  isFixed: boolean;
  conditionalStatements: MetricsListComponentConditionalStatement | MetricsTableComponentConditionalStatement[];
};
export const EMPTY_CELL_STRUCTURE: TableDataSetCell = {
  label: '',
  isTopHeader: false,
  isLeftHeader: false,
  isFixed: false,
  isMoving: false,
  allowedMetricsTimeSpans: ALL_TIME_SPANS,
  metrics: null,
  conditionalStatements: [],
};
type TargetCell = {
  rowIndex: number;
  cellIndex: number;
};

interface State {
  showSelectMetricsForm: boolean;
  // メトリクス設定インターフェースの中核となるテーブルビューモデル
  tableDataSet: TableDataSetCell[][] | null;
  draggableColumnIndexes: number[];
  draggableRowIndexes: number[];
  tableWidth: number;
  tableHeight: number;
  headerHeadDepth: number;
  headerSideDepth: number;
  tableBodyWidth: number;
  tableBodyHeight: number;
  dataCellCount: number;
  isUnshiftHeaderHeadAllowed: boolean;
  isUnshiftHeaderSideAllowed: boolean;
  isAppendRowAllowed: boolean;
  isAppendColumnAllowed: boolean;
  // メトリクス設定フォーム展開中に標的としているセル
  targetCell: TargetCell | null;
  metricsInTargetCell: Metrics | null;
  allowedMetricsTimeSpans: TimeSpan[];
  workplaceOptions: Workplace[];
  duplicateMetricsIds: number[];
}

export default defineComponent({
  components: {
    Draggable,
    SelectSingleMetricsForm,
    InputWarn,
  },
  props: {
    value: {
      type: Array as PropType<TableDataSetCell[][]>,
      required: true,
    },
    maxHeaderHeadDepth: {
      type: Number,
      default: 3,
    },
    maxHeaderSideDepth: {
      type: Number,
      default: 3,
    },
    maxTableBodyWidth: {
      type: Number,
      default: Infinity,
    },
    maxTableBodyHeight: {
      type: Number,
      default: Infinity,
    },
    maxTableBodyCell: {
      type: Number,
      default: Infinity,
    },
    mutateNewCell: {
      type: Function as PropType<(cell: TableDataSetCell, columnNumber: number, rowNumber: number) => TableDataSetCell>,
      default: (cell: TableDataSetCell, _columnNumber: number = 0, _rowNumber: number = 0) => cell,
    },
    confirmEventKey: {
      type: String,
      required: true,
    },
    cancelEventKey: {
      type: String,
      required: true,
    },
    showFormKey: {
      type: String,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const state: State = reactive({
      showSelectMetricsForm: false,
      // 算出プロパティにするとdraggableが動作しなくなる為、propsを直接保存する
      tableDataSet: props.value,
      draggableColumnIndexes: computed({
        get() {
          return state.tableDataSet ? [...state.tableDataSet[0].keys()].map((i) => ++i) : [];
        },
        set(_) {
          // draggableのv-modelに使用しており代入を受けるが、代入される値が元の値と変化しないため処理を行わない
        },
      }),
      draggableRowIndexes: computed({
        get() {
          return state.tableDataSet ? [...state.tableDataSet!.keys()].map((i) => ++i) : [];
        },
        set(_) {
          // draggableのv-modelに使用しており代入を受けるが、代入される値が元の値と変化しないため処理を行わない
        },
      }),
      tableWidth: computed(() => (state.tableDataSet ? state.tableDataSet[0].length : 0)),
      tableHeight: computed(() => (state.tableDataSet ? state.tableDataSet.length : 0)),
      headerSideDepth: computed(() => {
        return state.tableDataSet ? state.tableDataSet[0].filter((el) => el.isLeftHeader).length : 0;
      }),
      headerHeadDepth: computed(() => {
        return state.tableDataSet ? state.tableDataSet.filter((el) => el[0].isTopHeader).length : 0;
      }),
      tableBodyWidth: computed(() => state.tableWidth - state.headerSideDepth),
      tableBodyHeight: computed(() => state.tableHeight - state.headerHeadDepth),
      dataCellCount: computed(() => state.tableBodyWidth * state.tableBodyHeight),
      isUnshiftHeaderSideAllowed: computed(() => state.headerSideDepth < props.maxHeaderSideDepth),
      isUnshiftHeaderHeadAllowed: computed(() => state.headerHeadDepth < props.maxHeaderHeadDepth),
      isAppendColumnAllowed: computed(() => {
        return (
          state.tableBodyWidth < props.maxTableBodyWidth &&
          state.dataCellCount + state.tableBodyHeight <= props.maxTableBodyCell
        );
      }),
      isAppendRowAllowed: computed(() => {
        return (
          state.tableBodyHeight < props.maxTableBodyHeight &&
          state.dataCellCount + state.tableBodyWidth <= props.maxTableBodyCell
        );
      }),
      targetCell: null,
      metricsInTargetCell: computed({
        get(): Metrics | null {
          if (state.tableDataSet === null || state.targetCell === null) {
            return null;
          }
          return state.tableDataSet[state.targetCell.rowIndex][state.targetCell.cellIndex].metrics;
        },
        set(metrics: Metrics | null) {
          if (state.tableDataSet === null || state.targetCell === null) {
            throw Error('Bad setter call.');
          }
          state.tableDataSet[state.targetCell.rowIndex][state.targetCell.cellIndex].metrics = metrics;
        },
      }),
      allowedMetricsTimeSpans: computed(() => {
        return state.tableDataSet![state.targetCell!.rowIndex][state.targetCell!.cellIndex].allowedMetricsTimeSpans;
      }),
      workplaceOptions: [],
      duplicateMetricsIds: computed(() => {
        if (!state.tableDataSet) {
          return [];
        }
        const metricsIds = state.tableDataSet
          .flat()
          .filter((cell) => cell.metrics)
          .map((cell) => cell.metrics!.id);
        const countsById = metricsIds.reduce((countMap: Record<number, number>, id: number) => {
          return { ...countMap, [id]: (countMap[id] || 0) + 1 };
        }, {});
        return Object.keys(countsById)
          .map(Number)
          .filter((id: number) => countsById[id] > 1);
      }),
    });

    const IS_MOVING_CLASS = 'is-moving';

    const syncModel = (): void => {
      emit('input', state.tableDataSet);
    };

    const onStartMoveColumn = (e: any): void => {
      // ドラッグ要素に移動中のスタイルを追加
      e.item.classList.add(IS_MOVING_CLASS);
      e.clone.classList.add(IS_MOVING_CLASS);
      // テーブルセルの移動中フラグを有効化
      state.tableDataSet = state.tableDataSet!.map((row) => [...updateRowMovingStatus(row, e.oldDraggableIndex)]);
    };
    function onEndMoveColumn(e: any): void {
      // ドラッグ要素から移動中のスタイルを削除
      e.item.classList.remove(IS_MOVING_CLASS);
      e.clone.classList.remove(IS_MOVING_CLASS);
      // テーブルセルの移動中フラグを無効化
      state.tableDataSet = state.tableDataSet!.map((row) => [...resetRowMovingStatus(row)]);
      syncModel();
    }
    function onMoveColumn(e: any): boolean {
      const index1 = e.dragged.cellIndex;
      const index2 = e.draggedContext.futureIndex;
      if (
        state.tableDataSet!.some((row) => row[index1].isFixed) ||
        state.tableDataSet!.some((row) => row[index2].isFixed) ||
        state.tableDataSet!.some((row) => row[index1].isLeftHeader !== row[index2].isLeftHeader)
      ) {
        return false;
      }
      // FIXME: どうもswapのせいでmoveイベントのfutureIndexがバグってしまっている様子
      // 描画更新の為に配列のメモリを新しくするが、そのことで画面を往復するとfutureIndexが正しく取得できていない
      state.tableDataSet = state.tableDataSet!.map((row) => swap(row, index1, index2));
      return true;
    }

    const onStartMoveRow = (e: any): void => {
      // ドラッグ要素のスタイル変更
      e.item.classList.add(IS_MOVING_CLASS);
      e.clone.classList.add(IS_MOVING_CLASS);
      // テーブルのstate変更
      state.tableDataSet = updateColumnMovingStatus(state.tableDataSet!, e.oldDraggableIndex);
    };
    const onEndMoveRow = (e: any): void => {
      // ドラッグ要素のスタイル変更
      e.item.classList.remove(IS_MOVING_CLASS);
      e.clone.classList.remove(IS_MOVING_CLASS);
      // テーブルのstate変更
      state.tableDataSet = resetColumnMovingStatus(state.tableDataSet!);
      syncModel();
    };
    const onMoveRow = (e: any): boolean => {
      const index1 = e.dragged.cellIndex;
      const index2 = e.draggedContext.futureIndex;
      if (
        state.tableDataSet![index1]!.some((cell) => cell.isFixed) ||
        state.tableDataSet![index2]!.some((cell) => cell.isFixed) ||
        state.tableDataSet![index1][0].isTopHeader !== state.tableDataSet![index2][0].isTopHeader
      ) {
        return false;
      }
      state.tableDataSet = swap(state.tableDataSet!, index1, index2);
      return true;
    };

    // FIXME: 行と列でインターフェースが異なる
    const updateRowMovingStatus = (row: TableDataSetCell[], rowNo: number): TableDataSetCell[] => {
      return [...row.map((cell, index) => ({ ...cell, isMoving: index === rowNo }))];
    };
    const resetRowMovingStatus = (row: TableDataSetCell[]): TableDataSetCell[] => {
      return [...row.map((cell) => ({ ...cell, isMoving: false }))];
    };

    const updateColumnMovingStatus = (tableDataSet: TableDataSetCell[][], columnNo: number): TableDataSetCell[][] => {
      return [
        ...tableDataSet.map((row, index) =>
          row.map((cell) => {
            return { ...cell, isMoving: index === columnNo };
          }),
        ),
      ];
    };
    const resetColumnMovingStatus = (tableDataSet: TableDataSetCell[][]): TableDataSetCell[][] => {
      return [...tableDataSet.map((row) => row.map((cell) => ({ ...cell, isMoving: false })))];
    };

    const unshiftHeaderSideColumn = (): void => {
      if (!state.isUnshiftHeaderSideAllowed) {
        return;
      }

      state.tableDataSet = state.tableDataSet!.map((row, rowIndex) => [
        props.mutateNewCell(
          {
            ...EMPTY_CELL_STRUCTURE,
            isTopHeader: rowIndex < state.headerHeadDepth,
            isLeftHeader: true,
          },
          0,
          0,
        ),
        ...row,
      ]);
      syncModel();
    };

    const appendColumn = (): void => {
      if (!state.isAppendColumnAllowed) {
        return;
      }

      state.tableDataSet = state.tableDataSet!.map((row, rowIndex) => {
        const isTopHeader = rowIndex < state.headerHeadDepth;
        const columnNumber = isTopHeader ? 0 : state.tableBodyWidth + 1;
        const rowNumber = isTopHeader ? 0 : rowIndex - state.headerHeadDepth + 1;
        return [
          ...row,
          props.mutateNewCell(
            {
              ...EMPTY_CELL_STRUCTURE,
              isTopHeader: isTopHeader,
              isLeftHeader: false,
            },
            columnNumber,
            rowNumber,
          ),
        ];
      });
      syncModel();
    };

    const unshiftHeaderHeadRow = (): void => {
      if (!state.isUnshiftHeaderHeadAllowed) {
        return;
      }

      state.tableDataSet = [
        new Array(state.tableWidth).fill(null).map((_, columnIndex) => {
          return props.mutateNewCell(
            {
              ...EMPTY_CELL_STRUCTURE,
              isTopHeader: true,
              isLeftHeader: columnIndex < state.headerSideDepth,
            },
            0,
            0,
          );
        }),
        ...state.tableDataSet!,
      ];
      syncModel();
    };

    const appendRow = (): void => {
      if (!state.isAppendRowAllowed) {
        return;
      }

      state.tableDataSet = [
        ...state.tableDataSet!,
        new Array(state.tableWidth).fill(null).map((_, columnIndex) => {
          const isLeftHeader = columnIndex < state.headerSideDepth;
          const columnNumber = isLeftHeader ? 0 : columnIndex - state.headerSideDepth + 1;
          const rowNumber = isLeftHeader ? 0 : state.tableBodyHeight + 1;
          return props.mutateNewCell(
            {
              ...EMPTY_CELL_STRUCTURE,
              isTopHeader: false,
              isLeftHeader: isLeftHeader,
            },
            columnNumber,
            rowNumber,
          );
        }),
      ];
      syncModel();
    };

    const isRemoveColumnAllowed = (index: number): boolean => {
      if (state.tableDataSet!.some((el) => el[index].isFixed)) {
        return false;
      }

      return state.tableDataSet![0][index].isLeftHeader ? state.headerSideDepth > 1 : state.tableBodyWidth > 1;
    };

    const removeColumn = (index: number): void => {
      state.tableDataSet = state.tableDataSet!.map((row) => row.filter((_, columnIndex) => columnIndex !== index));
      syncModel();
    };

    function isRemoveRowAllowed(index: number): boolean {
      if (state.tableDataSet![index].some((el) => el.isFixed)) {
        return false;
      }

      return state.tableDataSet![index][0].isTopHeader ? state.headerHeadDepth > 1 : state.tableBodyHeight > 1;
    }

    const removeRow = (index: number): void => {
      state.tableDataSet!.splice(index, 1);
      syncModel();
    };

    const openMetricsSelectionModal = (_: any, rowIndex: number, cellIndex: number): void => {
      state.targetCell = { rowIndex, cellIndex };
      state.showSelectMetricsForm = true;
    };

    const closeMetricsSelectionModal = (): void => {
      state.targetCell = null;
      state.showSelectMetricsForm = false;
    };

    const { value: showSelectMetricsForm } = useSharedValue<boolean>(props.showFormKey);

    showSelectMetricsForm.value = state.showSelectMetricsForm;

    watch(
      () => state.showSelectMetricsForm,
      () => {
        showSelectMetricsForm.value = state.showSelectMetricsForm;
      },
    );

    const { listener: cancelEventListener } = useSimpleEvent(props.cancelEventKey);

    cancelEventListener.attach(closeMetricsSelectionModal);

    const setSelectedMetrics = async (metrics: Metrics): Promise<void> => {
      state.metricsInTargetCell = metrics;
      syncModel();
      closeMetricsSelectionModal();
    };

    const onInputLabel = (): void => {
      syncModel();
    };

    onMounted(async () => {
      state.workplaceOptions = await workplaceApi.index();
    });

    return {
      state,
      props,
      omitString,
      onStartMoveColumn,
      onEndMoveColumn,
      onStartMoveRow,
      onEndMoveRow,
      onMoveColumn,
      onMoveRow,
      unshiftHeaderSideColumn,
      unshiftHeaderHeadRow,
      appendColumn,
      appendRow,
      removeRow,
      removeColumn,
      isRemoveColumnAllowed,
      isRemoveRowAllowed,
      onInputLabel,
      openMetricsSelectionModal,
      closeMetricsSelectionModal,
      setSelectedMetrics,
      timeSpanToLocalWord,
    };
  },
});
