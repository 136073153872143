import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'

const localStorageName = 'logiscope'

type State = {
  serverToken: string | null,
}

// the definition of initial state is required.
const state: State = {
  serverToken: null,
}

// ソースをロードしたタイミングでlocalStorageから値を取得する
// この初回ロード以降はStore上の値更新をlocalStorageに同期し、localStorageから取り出すことはない
try {
  const config = JSON.parse(localStorage.getItem(localStorageName) || '{}')
  const keys = Object.keys(state) as Array<keyof State>
  keys.forEach(k => {
    state[k] = config[k]
  })
} catch (e) {}

const getters: GetterTree<State, any> = {
  serverToken: state => state.serverToken,
}

const GET = 'get'
const SET = 'set'

type Get = {
  [GET]: (injectee: ActionContext<State, any>, payload: { key: keyof State }) => void
}
type Set = {
  [SET]: (injectee: ActionContext<State, any>, payload: { key: keyof State, val: any }) => void
}

const SET_LOCAL_STORAGE = 'SET_LOCAL_STORAGE'

type SetLocalStorage = {
  [SET_LOCAL_STORAGE]: (state: State, payload: { data: Partial<State> }) => void
}

const mutations: MutationTree<State> & SetLocalStorage = {
  [SET_LOCAL_STORAGE](state, { data }) {
    const keys = Object.keys(state) as Array<keyof State>
    keys.forEach(k => {
      state[k] = data[k] ?? null
    })
    localStorage.setItem(localStorageName, JSON.stringify(state))
  },
}

const actions: ActionTree<State, any> & Get & Set = {
  [GET]: ({ state }, { key }) => {
    return Promise.resolve(state[key])
  },
  [SET]: ({ commit }, { key, val }) => {
    const data = { [key]: val }
    commit(SET_LOCAL_STORAGE, { data })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
