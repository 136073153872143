import axios, { AxiosResponse } from 'axios';
import { UserResponse } from 'src/models/api/userResponse';
import { LegacyLoginUserResponseData } from 'src/models/api/loginUserResponseData';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/users';

export default {
  // FIXME: 引数はanyではなくemail, passwordで固定だと思われるが、異なるパターンがあるのか不明
  login(data: any): Promise<AxiosResponse<UserResponse>> {
    const url = buildBackendUrl(buildBasePath(), 'login.json');
    return axios.post(url, { user: data });
  },
  logout(): Promise<AxiosResponse<{}>> {
    const url = buildBackendUrl(buildBasePath(), 'logout.json');
    return axios.delete(url);
  },
  getMe(): Promise<AxiosResponse<LegacyLoginUserResponseData>> {
    const url = buildBackendUrl(buildBasePath(), 'me');
    // TODO: 最終的には変換関数を噛ませて戻り値をLoginUser型にする
    // vuexストアモジュールのuserをloginUserへ完全移行して廃止する必要がある
    return axios.get(url);
  },
  // FIXME: anyを避ける
  sendResetPasswordEmail(data: any): Promise<AxiosResponse<{}>> {
    const url = buildBackendUrl(buildBasePath(), 'send_reset_password_email');
    return axios.post(url, data);
  },
  // FIXME: anyを避ける
  resetPassword(data: any): Promise<AxiosResponse<{}>> {
    const url = buildBackendUrl(buildBasePath(), 'reset_password');
    return axios.post(url, data);
  },
};
