import axios from 'axios';
import { buildBackendUrl } from 'src/apis/util/url';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import {
  ComponentLinkResponseData,
  convertFromComponentLinkResponseData,
} from 'src/models/api/Link/ComponentLink/componentLinkResponseData';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (reportId: number, componentId: number): string =>
  `/reports/${reportId}/components/${componentId}/component_links`;

export default {
  async index(reportId: number, componentId: number): Promise<ComponentLink[]> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId));
    const { data: resData } = await axios.get<MultiDataResponse<ComponentLinkResponseData>>(url);
    return resData.result.map(convertFromComponentLinkResponseData);
  },
};
