import { render, staticRenderFns } from "./MetricsDataTable.vue?vue&type=template&id=7860db2c&scoped=true&"
import script from "./MetricsDataTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MetricsDataTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MetricsDataTable.vue?vue&type=style&index=0&id=7860db2c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7860db2c",
  null
  
)

export default component.exports