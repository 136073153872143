import { HeaderStructure, MatrixHeaders, renderComputedHeaderFromLayout } from 'src/util/dataHeader';
import { ComponentResponseData, SearchedDateMap, extractLastSearchedDate } from '../componentResponseData';
import { TIME_SPAN_DAILY, TimeSpan, isTimeSpanLarger } from 'src/business/timeSpan';
import {
  MetricsListComponent,
  MetricsListComponentComputedListCell,
  MetricsListComponentLayoutListCell,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import { ComponentType, internal as componentInternal } from 'src/models/new/component';
import { constructPeriod } from 'src/business/period';
import { formatDate, parseYmdDate, rewindDate } from 'src/util/datetime';
import { convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData';
import { buildConditionalStatementProperties } from './buildConditionalStatementProperties';
import { buildBaseComponentProperties } from './buildBaseComponentProperties';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';

const METRICS_LIST = componentInternal.METRICS_LIST;

const buildHeaderStructure = (matrix: string[][], shouldBreakAt: number | null = null): HeaderStructure => {
  const isPositionFirst = (position: number) => position === 1;
  const shouldBreak = (position: number) => position === shouldBreakAt;
  const isIncludedInOtherCell = (value: string | null, position: number) => {
    return value === null && !isPositionFirst(position) && !shouldBreak(position);
  };

  return {
    depth: matrix.length || 0,
    breadth: matrix[0]?.length || 0,
    data:
      matrix
        .map((layer, layerIndex) => {
          let currentSpan = 1;
          // layerを直接逆順にしてしまうと、戻り値としては最後に再度reverseするので帳尻が合うが、
          // 再度のreverseは複製に対して行われるので反転したままになってしまう
          return [...layer]
            .reverse()
            .map((cellString, reverseIndex) => {
              const value = cellString !== '' ? cellString : null;
              const position = matrix[0]!.length - reverseIndex;
              const cell = {
                level: layerIndex + 1,
                position: position,
                span: isIncludedInOtherCell(value, position) ? 0 : currentSpan,
                value: value,
              };
              if (isIncludedInOtherCell(value, position)) {
                currentSpan++;
              } else {
                currentSpan = 1;
              }
              return cell;
            })
            .reverse();
        })
        .flat() || [],
  };
};

const searchDominantTimeSpan = (data: ComponentResponseData): TimeSpan => {
  // リストコンポーネントはビジネスロジック上基準となるTimeSpamを持つべきだが
  // そのフィールドがないためリストに存在する参考値でないメトリクスの最も大きな周期を検索している
  return data
    .list_component!.metrics_list_components.filter((el) => !el.is_sub_metrics)!
    .reduce((largestTimeSpan: TimeSpan, el) => {
      return isTimeSpanLarger(el.metrics.time_span, largestTimeSpan) ? el.metrics.time_span : largestTimeSpan;
    }, TIME_SPAN_DAILY);
};

const buildHeaderHeadLayout = (data: ComponentResponseData): HeaderStructure => {
  // 垂直リストでない場合、レイアウトの2項目目がサブメトリクスの先頭と判断する
  // 垂直リストの場合はメインメトリクスとサブメトリクスの境界はない
  const headSubMetricsBeginningPosition = data.list_component!.is_vertical ? null : 2;
  return buildHeaderStructure(data.list_component!.header.cols, headSubMetricsBeginningPosition);
};

const buildHeaderSideLayout = (data: ComponentResponseData): HeaderStructure => {
  // ヘッダの行列を転置する
  // 現在バックエンドでは左部ヘッダも縦横と配列が対応するようになっているが、これを深さと先頭からの位置の情報に変換する
  const invertedHeaderRows = [[]] as string[][];
  data.list_component!.header.rows.forEach((cells) => {
    cells.forEach((cell, index) => {
      invertedHeaderRows[index] ||= [];
      invertedHeaderRows[index]?.push(cell);
    });
  });
  // 垂直リストの場合、レイアウトの2項目目がサブメトリクスの先頭と判断する
  // 垂直リストでない場合はメインメトリクスとサブメトリクスの境界はない
  const sideSubMetricsBeginningPosition = data.list_component!.is_vertical ? 2 : null;
  return buildHeaderStructure(invertedHeaderRows, sideSubMetricsBeginningPosition);
};

// FIXME: ロジックが誤っており、subMetricsNumberが1より大きくならない
// これは現行仕様上同じsequentialOrderに対してsubMetricsが複数存在しないため問題が発生しないが、ロジックとして不適当
const buildDataLayoutCells = (data: ComponentResponseData): MetricsListComponentLayoutListCell[] => {
  return data.list_component!.metrics_list_components.map((el) => {
    const subMetricsNumberOrderMap = {} as { [key: number]: number };
    const isSubMetrics = el.is_sub_metrics;
    const sequentialOrder = el.sequential_order;
    const anotherAxisOrder = el.is_sub_metrics ? 2 : 1;

    subMetricsNumberOrderMap[sequentialOrder] ||= 1;

    const subMetricsNumber = isSubMetrics ? subMetricsNumberOrderMap[sequentialOrder] ?? null : null;
    const cell = {
      sequentialOrder,
      isSubMetrics,
      subMetricsNumber,
      metrics: convertFromMetricsResponseData(el.metrics, {}),
      conditionalStatements: data.component_conditional_statements
        .filter((statement) => statement.column_number === anotherAxisOrder && statement.row_number === sequentialOrder)
        .map((statement) =>
          buildConditionalStatementProperties(statement, data.list_component!.metrics_value_searched_dates_map),
        ),
    };
    if (subMetricsNumber !== null) {
      subMetricsNumberOrderMap[sequentialOrder] = subMetricsNumber + 1;
    }
    return cell;
  });
};

const computeDataCells = (
  data: ComponentResponseData,
  layoutCells: MetricsListComponentLayoutListCell[],
  closingDates: Date[],
  closingDateMap: SearchedDateMap,
): MetricsListComponentComputedListCell[] => {
  return layoutCells
    .map((cell) => {
      if (!cell.isSubMetrics) {
        // 主メトリクスの場合は日付リストに合わせて値を作成する
        return closingDates.map((date, index) => {
          const position = index + 1;
          const metrics = structuredClone(cell.metrics);

          // layout側には入っていないmetricsのclosingDateとvalueを補完する
          // 値がDBに登録されていない場合はmetrics_values_idx_metrics_idにデータが送られないため、オプショナルチェイニングを用いる
          const valueOfMetrics =
            data.list_component!.metrics_values_idx_metrics_id[cell.metrics.id]?.find(
              (el) => el.dt === formatDate(date, SYSTEM_DATE_FORMAT),
            )?.value ?? null;
          metrics.closingDate = date;
          metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;

          const conditionalStatements = cell.conditionalStatements.map((statement) => {
            return { ...statement, column: position };
          });

          const base = { metrics, closingDate: date, conditionalStatements };

          return !data.list_component!.is_vertical
            ? { ...base, row: cell.sequentialOrder, column: position }
            : { ...base, row: position, column: cell.sequentialOrder };
        });
      } else {
        // 参考値の場合は最も新しい日付の値を取得する
        // レコードが存在しない場合、最も新しい日付のメトリクス値が存在しない場合がありうる
        // また、同じメトリクスで他の日付の値が存在することがありうる
        // これはコンポーネントの内のいずれかの主メトリクスと同じメトリクスを使用している場合に発生する
        // この2つが重なった場合を考慮して、日付をキーにして値を検索する必要がある
        const timeSpan = cell.metrics.timeSpan;
        const closingDate = extractLastSearchedDate(closingDateMap, timeSpan);
        const position = cell.subMetricsNumber! + closingDates.length;
        const metrics = structuredClone(cell.metrics);
        const valueOfMetrics =
          data.list_component!.metrics_values_idx_metrics_id[cell.metrics.id]?.find(
            (el) => el.dt === formatDate(closingDate, SYSTEM_DATE_FORMAT),
          )?.value ?? null;
        metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;
        metrics.closingDate = closingDate;

        const conditionalStatements = cell.conditionalStatements.map((statement) => {
          return { ...statement, column: position };
        });

        const base = { metrics, closingDate, conditionalStatements };

        return [
          !data.list_component!.is_vertical
            ? { ...base, row: cell.sequentialOrder, column: position }
            : { ...base, row: position, column: cell.sequentialOrder },
        ];
      }
    })
    .flat();
};

export const buildMetricsListComponentProperties = (
  data: ComponentResponseData,
  referenceDate: Date | null,
): MetricsListComponent => {
  const componentType: ComponentType = METRICS_LIST;
  const period = constructPeriod(data.list_component!.period_unit, data.list_component!.period_value);
  // 内部的に持っている締め日リスト
  const dominantTimeSpan = searchDominantTimeSpan(data);
  const closingDateMap = data.list_component!.metrics_value_searched_dates_map ?? null;
  const closingDates = closingDateMap ? closingDateMap[dominantTimeSpan]?.map((el) => parseYmdDate(el)) ?? [] : [];

  // ヘッダの日付表示に用いる締め日リスト
  const shouldRewindHeaderDates =
    data.list_component!.disp_days_rewind_value && data.list_component!.disp_days_rewind_unit;
  const rewindedClosingDates = shouldRewindHeaderDates
    ? closingDates.map((el) =>
        rewindDate(el, data.list_component!.disp_days_rewind_value!, data.list_component!.disp_days_rewind_unit!),
      )
    : closingDates;

  const dispDaysRewindPeriod = shouldRewindHeaderDates
    ? { unit: data.list_component!.disp_days_rewind_unit!, value: data.list_component!.disp_days_rewind_value! }
    : null;

  const isDescendingDateOrder = data.list_component!.is_descend;

  const computeOptions = {
    dayHeader: {
      dates: !isDescendingDateOrder ? rewindedClosingDates : [...rewindedClosingDates].reverse(),
      format: data.list_component!.date_format,
    },
  };

  const headLayout = buildHeaderHeadLayout(data);
  const headComputed = renderComputedHeaderFromLayout(headLayout, computeOptions);
  const sideLayout = buildHeaderSideLayout(data);
  const sideComputed = renderComputedHeaderFromLayout(sideLayout, computeOptions);

  const headers = {
    head: {
      layout: headLayout,
      computed: headComputed,
    },
    side: {
      layout: sideLayout,
      computed: sideComputed,
    },
  } as MatrixHeaders;

  const dataLayoutCells = buildDataLayoutCells(data);
  // リストコンポーネントのデータレンダリングはヘッダの算出と独立して行う
  // referenceDateがnullの場合は空配列となる
  // referenceDateの有無とclosingDateMapの有無が連動していることが前提
  const dataComputedCells = referenceDate
    ? computeDataCells(
        data,
        dataLayoutCells,
        !isDescendingDateOrder ? closingDates : [...closingDates].reverse(),
        closingDateMap!,
      )
    : [];

  return {
    ...buildBaseComponentProperties(data, componentType),
    referenceDate,
    period,
    isTodayDisplayed: data.list_component!.is_today_displayed,
    isFutureDisplayed: data.list_component!.is_future_displayed,
    isDescendingDateOrder,
    dateFormat: data.list_component!.date_format,
    dispDaysRewindPeriod,
    isVertical: data.list_component!.is_vertical,
    headers,
    isUnitIndividualDisplayed: data.list_component!.is_unit_indivisual_displayed,
    closingDates,
    data: {
      layout: dataLayoutCells,
      computed: dataComputedCells,
    },
  };
};
