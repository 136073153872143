import {
  PERIOD_DAYS,
  PERIOD_MONTHS,
  Period,
  PeriodUnit,
  PERIOD_THIS_WEEK,
  PERIOD_THIS_MONTH, PERIOD_WEEKS, PERIOD_THIS_YEAR, PERIOD_YEARS
} from 'src/business/period'
import { TIME_SPAN_DAILY, TIME_SPAN_MONTHLY, TIME_SPAN_WEEKLY, TIME_SPAN_YEARLY, TimeSpan } from 'src/business/timeSpan'

export type MetricsTransitionComponentRewindPeriodUnit = typeof PERIOD_DAYS | typeof PERIOD_MONTHS
export type MetricsTransitionComponentRewindPeriod = {
  unit: MetricsTransitionComponentRewindPeriodUnit
  value: number
}

export type MetricsTransitionComponentProperties = {
  period: Period
  isTodayDisplayed: boolean
  isFutureDisplayed: boolean
  isDescendingDateOrder: boolean
  dateFormat: string
  dispDaysRewindPeriod: MetricsTransitionComponentRewindPeriod | null
}

export const METRICS_TRANSITION_COMPONENT_DISP_DAYS_REWIND_PERIOD_UNIT_LABELS: Record<MetricsTransitionComponentRewindPeriodUnit, string> = {
  [PERIOD_DAYS]: '日前',
  [PERIOD_MONTHS]: 'ヶ月前',
}

// 周期による選択可能な表示期間
const PERIOD_UNIT_TIME_SPAN_MAP: Record<TimeSpan, PeriodUnit[]> = {
  [TIME_SPAN_DAILY]: [PERIOD_THIS_WEEK, PERIOD_THIS_MONTH, PERIOD_DAYS],
  [TIME_SPAN_WEEKLY]: [PERIOD_THIS_MONTH, PERIOD_WEEKS],
  [TIME_SPAN_MONTHLY]: [PERIOD_THIS_YEAR, PERIOD_MONTHS],
  [TIME_SPAN_YEARLY]: [PERIOD_YEARS],
}

export const getAvailablePeriodUnitsFromTimeSpan = (timeSpan: TimeSpan): PeriodUnit[] => {
  return PERIOD_UNIT_TIME_SPAN_MAP[timeSpan]
}
