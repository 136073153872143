import { ConditionalStatementWithContainerPosition, Decoration } from 'src/models/new/conditionalStatement'
import { ConditionalStatementUpsertBaseRequestParameters, convertToConditionalStatementUpsertBaseRequestParameters } from './conditionalStatementUpsertBaseRequestParameters'

export type ConditionalStatementCreateRequestParameters = ConditionalStatementUpsertBaseRequestParameters & {
  primary_position_id: number
  secondary_position_id: number
}

export const convertToConditionalStatementCreateRequestParameters = (
  statement: ConditionalStatementWithContainerPosition<Decoration>
): ConditionalStatementCreateRequestParameters => {
  return {
    ...convertToConditionalStatementUpsertBaseRequestParameters(statement),
    primary_position_id: statement.primaryPositionId,
    secondary_position_id: statement.secondaryPositionId,
  }
}
