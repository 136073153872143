import { ref } from 'vue';
import { ZendeskArticleIndexParameters } from 'src/models/api/zendesk/Article/articleIndexRequestParameters';
import articleApi from 'src/apis/zendesk/article';

export type ZendeskArticle = {
  title: string;
  htmlUrl: string;
  createdAt: string;
};

export const useAnnouncementArticles = () => {
  const announcementArticles = ref<ZendeskArticle[]>([]);

  const filterWithin31Days = (articles: ZendeskArticle[]): ZendeskArticle[] => {
    const withinDays = 31;
    // 直近X日以内の記事のみを返す
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const timestampThreshold = today.getTime() - 86400 * withinDays * 1000;
    return articles.filter((e) => new Date(e.createdAt).getTime() > timestampThreshold);
  };

  const fetchAnnouncementArticles = async (): Promise<void> => {
    const params = { sort_by: 'created_at', sort_order: 'desc' } as ZendeskArticleIndexParameters;
    const articles = await articleApi.index(params);
    announcementArticles.value = filterWithin31Days(articles);
  };

  return {
    announcementArticles,
    fetchAnnouncementArticles,
  };
};
