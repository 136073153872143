
import Vue, { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import { GridItemData, GridLayout, GridItem } from 'vue-grid-layout';
import { Component, layoutOfComponent } from 'src/models/new/component';
import { Report } from 'src/models/new/report';
import { Section } from 'src/models/new/section';
import ComponentView from 'src/views/Dashboard/ReportValues/Detail/components/SectionView/ComponentView.vue';

interface State {
  gridLayoutItems: GridItemData[];
}

function setupState(root: Vue): State {
  const state: State = reactive({
    gridLayoutItems: computed(() => {
      const section: Section = root.$props.section;
      return section.components.map((component) => layoutOfComponent(component));
    }),
  });

  return state;
}

export default defineComponent({
  components: {
    GridLayout,
    GridItem,
    ComponentView,
  },
  props: {
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
    report: {
      type: Object as PropType<Report>,
      required: true,
    },
    openEditValueModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    openEditCommentModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const name = 'SectionView';
    const state = setupState(root);

    const getComponentFromGridItem = (gridItem: GridItemData): Component => {
      return props.section.components.find((el) => el.id === Number(gridItem.i))!;
    };

    return {
      name,
      props,
      state,
      layoutOfComponent,
      getComponentFromGridItem,
    };
  },
});
