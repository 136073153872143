import axios from 'axios';
import { convertToConditionalStatementBulkCreateRequestParameters } from 'src/models/api/Report/Component/ConditionalStatement/conditionalStatementBulkCreateRequestParameters';
import { convertToConditionalStatementBulkUpdatePriorityRequestParameters } from 'src/models/api/Report/Component/ConditionalStatement/conditionalStatementBulkUpdatePriorityRequestParameters';
import { convertToConditionalStatementCreateRequestParameters } from 'src/models/api/Report/Component/ConditionalStatement/conditionalStatementCreateRequestParameters';
import { convertFromConditionalStatementResponseData } from 'src/models/api/Report/Component/ConditionalStatement/conditionalStatementResponseData';
import { convertToConditionalStatementUpdateRequestParameters } from 'src/models/api/Report/Component/ConditionalStatement/conditionalStatementUpdateRequestParameters';
import { ConditionalStatementWithContainerPosition } from 'src/models/new/conditionalStatement';
import { RecordId } from 'src/util/recordId';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (reportId: number, componentId: number): string => {
  return `/masters/reports/${reportId}/components/${componentId}/component_conditional_statements`;
};

export default {
  async index(reportId: RecordId, componentId: RecordId): Promise<ConditionalStatementWithContainerPosition<any>[]> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId));
    const { data: resData } = await axios.get(url);
    return resData.map(convertFromConditionalStatementResponseData);
  },
  async create(
    reportId: RecordId,
    componentId: RecordId,
    data: ConditionalStatementWithContainerPosition<any>,
  ): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId));
    const params = convertToConditionalStatementCreateRequestParameters(data);
    await axios.post(url, params);
    return {};
  },
  async update(
    reportId: RecordId,
    componentId: RecordId,
    data: ConditionalStatementWithContainerPosition<any>,
  ): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId), data.id);
    const params = convertToConditionalStatementUpdateRequestParameters(data);
    await axios.put(url, params);
    return {};
  },
  async delete(reportId: RecordId, componentId: RecordId, id: RecordId): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId), id);
    await axios.delete(url);
    return {};
  },
  async bulkCreate(
    reportId: RecordId,
    componentId: RecordId,
    data: ConditionalStatementWithContainerPosition<any>[],
  ): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId), 'bulk_create');
    const params = convertToConditionalStatementBulkCreateRequestParameters(data);
    await axios.post(url, params);
    return {};
  },
  async bulkUpdatePriority(
    reportId: RecordId,
    componentId: RecordId,
    primaryPositionId: number,
    secondaryPositionId: number,
    data: ConditionalStatementWithContainerPosition<any>[],
  ): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId), 'bulk_update_priority');
    const params = convertToConditionalStatementBulkUpdatePriorityRequestParameters(
      primaryPositionId,
      secondaryPositionId,
      data,
    );
    await axios.put(url, params);
    return {};
  },
};
