import { ref, onMounted } from 'vue';
import metricsApi from 'src/apis/masters/metrics';
import type { MetricsIndexRequestParameters } from 'src/models/api/Metrics/metricsIndexRequestParameters';
import type { Metrics } from 'src/models/new/metrics';
import { useWorkplaceOptions } from 'src/composables/options/useWorkplaceOptions';
import { useSelectItemForm } from 'src/composables/useSelectItemForm';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useSelectedItems } from 'src/components/NewSelectItemForm/composables/useSelectedItems';
import { useSearchMetricsForm, type SearchMetricsParams, type SearchMetricsConfig } from './useSearchMetricsForm';
import { usePageTemporaryStoredParameters } from 'src/composables/usePageTemporaryStoredParameters';

export const useSelectMetricsForm = (
  currentSelectedMetricsList: Metrics[],
  selectEventKey: string,
  handleSelectMetrics: (metricsList: Metrics[]) => void,
  config: SearchMetricsConfig,
) => {
  const { workplaceOptions, metricsTypeOptions, timeSpanOptions, formItems, defaultParams } =
    useSearchMetricsForm(config);

  const { getPageTemporaryStoredParameters, setPageTemporaryStoredParameters } =
    usePageTemporaryStoredParameters<SearchMetricsParams>('SelectMetricsForm');

  const storedParams = ref<SearchMetricsParams>();

  const requiredSelectKeys = formItems
    .filter((item) => item.required && item.inputMode === 'select')
    .map((item) => item.searchKey);

  const isValidStoredParams = (storedParams: SearchMetricsParams) => {
    if (storedParams.workplaceId === null && requiredSelectKeys.includes('workplaceId')) {
      return false;
    } else if (storedParams.metricsType === null && requiredSelectKeys.includes('metricsType')) {
      return false;
    } else if (storedParams.timeSpan === null && requiredSelectKeys.includes('timeSpan')) {
      return false;
    } else if (
      storedParams.workplaceId !== null &&
      !workplaceOptions.value.map((option) => option.value).includes(storedParams.workplaceId)
    ) {
      return false;
    } else if (
      storedParams.metricsType !== null &&
      !metricsTypeOptions.value.map((option) => option.value).includes(storedParams.metricsType)
    ) {
      return false;
    } else if (
      storedParams.timeSpan !== null &&
      !timeSpanOptions.value.map((option) => option.value).includes(storedParams.timeSpan)
    ) {
      return false;
    }
    return true;
  };

  const { options: allWorkplaceOptions } = useWorkplaceOptions();

  const convertParams = (params: SearchMetricsParams): MetricsIndexRequestParameters => {
    const { workplaceId, name, metricsType, timeSpan } = params;
    let logiscopeWorkplaceId: MetricsIndexRequestParameters['logiscope_workplace_id'] = null;
    if (workplaceId !== null) {
      logiscopeWorkplaceId = workplaceId;
    } else if (workplaceOptions.value.length < allWorkplaceOptions.value.length) {
      // FIXME: noUncheckedIndexedAccess有効化に伴う暫定対応
      // workplaceOptions.value[0] => workplaceOptions.value[0]!
      logiscopeWorkplaceId = workplaceOptions.value[0]!.value;
      if (workplaceOptions.value.length >= 2) {
        console.error(
          'allowedOptionMap.workplaceIdsに複数のIDが指定されていますが、使用されるのは先頭の1つだけです。設定を確認してください。',
        );
      }
    }
    return {
      page: null,
      name,
      logiscope_workplace_id: logiscopeWorkplaceId,
      metrics_types: metricsType !== null ? [metricsType] : config.allowedOptionMap.metricsTypes ?? [],
      time_spans: timeSpan !== null ? [timeSpan] : config.allowedOptionMap.timeSpans ?? [],
    };
  };

  const {
    fetchParams,
    selectableItems: selectableMetricsList,
    dataLoadState,
    load,
    loadNext,
  } = useSelectItemForm<Metrics, MetricsIndexRequestParameters, SearchMetricsParams>(
    metricsApi.index,
    defaultParams,
    convertParams,
  );

  const onSubmit = () => {
    load();
    setPageTemporaryStoredParameters(fetchParams.value);
  };

  const { selectedItems: selectedMetricsList, set } = useSelectedItems<Metrics>();
  set(currentSelectedMetricsList);

  const { listener: selectEventListener } = useSimpleEvent(selectEventKey);

  selectEventListener.attach(() => {
    handleSelectMetrics(selectedMetricsList.value);
  });

  onMounted(() => {
    storedParams.value = getPageTemporaryStoredParameters();
    if (isValidStoredParams(storedParams.value)) {
      fetchParams.value = storedParams.value;
    }
    load();
  });

  return {
    fetchParams,
    formItems,
    onSubmit,
    loadNext,
    selectableMetricsList,
    selectedMetricsList,
    dataLoadState,
  };
};
