import { ReportLink } from 'src/models/new/Link/reportLink';
import reportLinkApi from 'src/apis/masters/reportLink';
import { computed, reactive, ref } from 'vue';
import { useUrlPathParameters } from 'src/composables/useUrlPathParameters';
import { REPORT_MAX_REPORT_LINKS } from 'src/models/new/report';

const urlPathParametersTemplate = {
  reportId: 0,
};

export const useReportLinks = () => {
  const reportLinksRef = ref<ReportLink[]>([]);

  const { urlPathParameters } = useUrlPathParameters(urlPathParametersTemplate);

  const loadReportLinks = async () => {
    const reportId = urlPathParameters.value.reportId;
    if (reportId) {
      reportLinksRef.value = await reportLinkApi.index(reportId);
    } else {
      console.error('reportId is not in url params');
    }
  };

  const reportLinksState = reactive({
    isEmpty: computed(() => reportLinksRef.value.length === 0),
    isFull: computed(() => reportLinksRef.value.length >= REPORT_MAX_REPORT_LINKS),
  });

  return {
    reportLinksRef,
    loadReportLinks,
    reportLinksState,
  };
};
