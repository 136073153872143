import { ref, Ref } from 'vue';
import { Pagination } from 'src/models/api/shared/paginationResponseData';
import { Item } from 'src/components/NewSelectItemForm/types/item';

export const useSelectItemForm = <
  I extends Item,
  FetcherParams extends Record<string, any>,
  FetchParams extends Record<string, any> = FetcherParams,
>(
  fetcher: (params: FetcherParams) => Promise<{ result: Array<I>; pagination?: Pagination }>,
  defaultParams: FetchParams,
  convertParams: (params: FetchParams) => FetcherParams,
  options: { lazyLoad: boolean } = { lazyLoad: true },
) => {
  const fetchParams = ref<FetchParams>(defaultParams);
  const selectableItems = ref<Array<I>>([]) as Ref<Array<I>>;
  const dataLoadState = ref<{ availableTotal: number; loadedSlices: number }>({ availableTotal: 0, loadedSlices: 0 });
  const lastFetchParams = ref<FetchParams>(defaultParams);

  const fetch = async (params: FetchParams): Promise<void> => {
    const convertedParams = convertParams(params);
    let response;
    try {
      response = await fetcher(
        options.lazyLoad ? { ...convertedParams, page: dataLoadState.value.loadedSlices + 1 } : convertedParams,
      );
    } catch (error) {
      return;
    }

    dataLoadState.value.loadedSlices += 1;

    if (options.lazyLoad && dataLoadState.value.loadedSlices > 1) {
      selectableItems.value = [...selectableItems.value, ...response.result];
    } else {
      selectableItems.value = response.result;
    }

    if (options.lazyLoad && response.pagination) {
      dataLoadState.value.availableTotal = response.pagination.total;
    } else {
      dataLoadState.value.availableTotal = response.result.length;
    }
  };

  const load = async (): Promise<void> => {
    dataLoadState.value.loadedSlices = 0;
    if (options.lazyLoad) {
      lastFetchParams.value = fetchParams.value;
    }
    await fetch(fetchParams.value);
  };

  const loadNext = async (): Promise<void> => {
    if (!options.lazyLoad) {
      return;
    }
    await fetch(lastFetchParams.value);
  };

  return {
    fetchParams,
    selectableItems,
    dataLoadState,
    load,
    loadNext,
  };
};
