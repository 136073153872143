import axios from 'axios';
import { DataFetchStatus } from 'src/models/dataFetchStatus';
import { buildBackendUrl } from 'src/apis/util/url';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';
import { DataFetchStatusIndexRequestParameters } from 'src/models/api/DataFetchStatus/dataFetchStatusIndexRequestParameters';
import { DataFetchStatusCreateByManualRequestParameters } from 'src/models/api/DataFetchStatus/dataFetchStatusCreateByManualRequestParameters';
import {
  convertDataFetchStatusResponseData,
  DataFetchStatusResponseData,
} from 'src/models/api/DataFetchStatus/dataFetchStatusResponseData';
import { PaginatedMultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (): string => '/data_fetch_statuses';

export default {
  async index(
    data: DataFetchStatusIndexRequestParameters,
  ): Promise<{ result: DataFetchStatus[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<DataFetchStatusResponseData>>(url, {
      params: data,
    });
    return {
      result: resData.result.map(convertDataFetchStatusResponseData),
      pagination: convertFromPagination(resData.pagination),
    };
  },
  async createByManual(params: DataFetchStatusCreateByManualRequestParameters): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), 'create_by_manual');
    const { data: resData } = await axios.post(url, params);
    return resData;
  },
  async createByCsv(data: FormData): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), 'create_by_csv');
    const headers = { 'content-type': 'multipart/form-data' };
    const { data: resData } = await axios.post(url, data, { headers });
    return resData;
  },
};
