import { getCurrentInstance } from 'vue';
import {
  QueryParameters,
  STORED_PARAMETERS_URL,
  getStoredParameters,
  setStoredParameters,
} from 'src/util/storedParameters';

export const useUrlStoredParameters = <T extends QueryParameters>() => {
  const root = getCurrentInstance()?.proxy!;
  const getUrlStoredParameters = (): T => {
    return getStoredParameters<T>(root, STORED_PARAMETERS_URL);
  };
  const setUrlStoredParameters = (params: T): void => {
    setStoredParameters(root, { ...params }, STORED_PARAMETERS_URL);
  };

  return {
    getUrlStoredParameters,
    setUrlStoredParameters,
  };
};
