
import { defineComponent, onMounted, PropType, reactive } from 'vue'
import { Link } from 'src/models/new/link'

type State = {
  isReady: boolean
}

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<Link>,
      required: true,
    },
    execute: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const state: State = reactive({
      isReady: false,
    })

    const close = (): void => emit('close')

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false
      state.isReady = true
    })

    return {
      props,
      state,
      close,
    }
  },
})
