import { RecordId } from 'src/util/recordId';
import { AccessGroupCoreParameters, AccessGroupRole } from 'src/models/new/accessGroup';
import { LoginUser } from 'src/models/new/User/loginUser';

// LoginUser専用の省略型のアクセスグループレスポンス
type SimpleAccessGroupResponseData = {
  id: RecordId;
  role: AccessGroupRole;
};

type SimpleReportAccessGroupResponseData = SimpleAccessGroupResponseData;
type SimpleMetricsAccessGroupResponseData = SimpleAccessGroupResponseData;

// TODO: 将来的にはこちらの形式にAPIを変更したいが
// vuexストアモジュールのuserをloginUserへ完全移行して廃止する必要がある
export type LoginUserResponseData = {
  id: number;
  email: string;
  family_name: string;
  first_name: string;
  company: {
    id: number;
    name: string;
  };
  is_super_admin: boolean;
  report_access_groups: SimpleReportAccessGroupResponseData[];
  metrics_access_groups: SimpleMetricsAccessGroupResponseData[];
  report_granted_by_access_groups: SimpleReportAccessGroupResponseData[];
};

// 将来的にはこちらの形式にAPIを変更したい
// 定義場所は暫定
type ServerDataResponseData = {
  env: string;
  logi_coredata_url: string;
  now_obj: {
    is_real_now: boolean;
    now: string;
  };
};

// こちらは、現行の型を記録している
export type LegacyLoginUserResponseData = LoginUserResponseData &
  ServerDataResponseData & {
    can_use_logiscope: true;
    company: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
    };
    company_id: number;
    full_name: string;
    has_metrics_admin_role: boolean;
    has_metrics_gt_manager_role: boolean;
    has_metrics_gt_read_write_role: boolean;
    has_report_admin_role: boolean;
    has_report_gt_manager_role: boolean;
    has_role_admin: boolean;
    has_role_member: boolean;
    has_role_super_admin: boolean;
    is_admin: boolean;
    is_member: boolean;
    job_title: string;
    role: string;
  };

const convertFromAccessGroupData = (data: SimpleAccessGroupResponseData): AccessGroupCoreParameters => {
  return {
    id: data.id,
    role: data.role,
  };
};

export const convertFromLoginUserResponseData = (data: LoginUserResponseData): LoginUser => {
  return {
    id: data.id,
    email: data.email,
    familyName: data.family_name,
    firstName: data.first_name,
    company: {
      id: data.company.id,
      name: data.company.name,
    },
    isSuperAdmin: data.is_super_admin,
    reportAccessGroups: data.report_access_groups.map(convertFromAccessGroupData),
    metricsAccessGroups: data.metrics_access_groups.map(convertFromAccessGroupData),
    reportGrantedByAccessGroups: data.report_granted_by_access_groups.map(convertFromAccessGroupData),
  };
};
