export const DATA_CONVERSION_STATUS_FAILED = 'failed';
export const DATA_CONVERSION_STATUS_IN_PROGRESS = 'in_progress';
export const DATA_CONVERSION_STATUS_COMPLETED = 'completed';
export type DATA_CONVERSION_STATUS =
  | typeof DATA_CONVERSION_STATUS_FAILED
  | typeof DATA_CONVERSION_STATUS_IN_PROGRESS
  | typeof DATA_CONVERSION_STATUS_COMPLETED;

export const DATA_CONVERSION_STATUS_COLOR_MAP = {
  [DATA_CONVERSION_STATUS_FAILED]: 'ed7d31',
  [DATA_CONVERSION_STATUS_IN_PROGRESS]: 'ffc000',
  [DATA_CONVERSION_STATUS_COMPLETED]: '70ad47',
};

export const REQUESTS_COMPLETION_STATUS_FAILED = 'failed';
export const REQUESTS_COMPLETION_STATUS_IN_PROGRESS = 'in_progress';
export const REQUESTS_COMPLETION_STATUS_COMPLETED = 'completed';
export type REQUESTS_COMPLETION_STATUS =
  | typeof REQUESTS_COMPLETION_STATUS_FAILED
  | typeof REQUESTS_COMPLETION_STATUS_IN_PROGRESS
  | typeof REQUESTS_COMPLETION_STATUS_COMPLETED;

// 特に画面上に表示していないので今のところREQUESTS_COMPLETION_STATUS_COLOR_MAPは不要

export type ConversionProgramStatus = {
  id: number;
  dt: Date;
  fileName: string;
  dataConversionStatus: DATA_CONVERSION_STATUS;
  dataConversionStatusText: string;
  requestsCompletionStatus: REQUESTS_COMPLETION_STATUS;
  errorMessage: string | null;
  lastRequestTime: Date | null;
};

export function getExecuteStatusColor(ConversionProgramStatus: Partial<ConversionProgramStatus>) {
  return DATA_CONVERSION_STATUS_COLOR_MAP[ConversionProgramStatus.dataConversionStatus!];
}
