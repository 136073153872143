import { computed, getCurrentInstance, ref } from 'vue';

/*
 * usage:
 *   <SomeContent
 *     v-if="!isPrintMode"
 *   />
 *
 *   <SomeButton
 *     @click="unlessPrintMode(onClickHandler)"
 *   />
 */
export const usePrintMode = () => {
  const vueInstance = getCurrentInstance()!.proxy;

  const isPrintMode = computed(() => vueInstance.$route.path.startsWith('/print'));
  const unlessPrintMode = (callback: () => unknown) => {
    if (isPrintMode.value) {
      return;
    }
    callback();
  };

  return {
    isPrintMode,
    unlessPrintMode,
  };
};
