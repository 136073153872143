
import { PropType, computed, defineComponent, getCurrentInstance, inject, onMounted, reactive } from 'vue'
import { UpdateMetricsTarget, UpdateMetricsTargetCategoryIdentifier, isUpdateMetricsTargetWithImmutableTimeSpan } from '../logics/UpdateMetricsTarget'
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate'
import metricsApi from 'src/apis/masters/metrics'
import workplaceApi from 'src/apis/masters/workplace'
import { Workplace } from 'src/models/new/workplace'
import { TIME_SPAN_LOCAL_WORDS, TimeSpan } from 'src/business/timeSpan'
import { concat } from 'lodash'
import { MetricsCollectWithUniqueKeySetsRequestParameters } from 'src/models/api/Metrics/metricsCollectWithUniqueKeySetsRequestParameters'
import InputError from 'src/components/InputError.vue'

type workplaceOption = { value: number | null, label: string }
type TimeSpanOption = { value: TimeSpan | null, label: string }

const TIME_SPAN_OPTIONS: TimeSpanOption[] = Object.keys(TIME_SPAN_LOCAL_WORDS).map(el => {
  return {
    value: el as TimeSpan | null,
    label: TIME_SPAN_LOCAL_WORDS[el as TimeSpan],
  }
})
const TARGET_TIME_SPAM_OPTIONS = concat([{ value: null, label: '全て対象' }], TIME_SPAN_OPTIONS)
const ALTERNATIVE_TIME_SPAM_OPTIONS = concat([{ value: null, label: '変更しない' }], TIME_SPAN_OPTIONS)

type State = {
  isReady: boolean

  workplaces: Workplace[]
  targetWorkplaceOptions: workplaceOption[]
  alternativeWorkplaceOptions: workplaceOption[]

  isAlternativeTimeSpanUnselectable: boolean

  targetPartialName: string
  targetWorkplaceId: number | null
  targetTimeSpan: TimeSpan | null
  alternativePartialName: string
  alternativeWorkplaceId: number | null
  alternativeTimeSpan: TimeSpan | null

  validations: Record<string, Object>
  hasError: boolean
  // targetPartialNameはエラーメッセージとフォームのスタイル変更のパターンが複雑で、専用に状態を定義する
  targetPartialNameError: string | null
}

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    updateTargets: {
      type: Array as PropType<UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[]>,
      required: true,
    },
    onReplaced: {
      type: Function as PropType<(updateTargets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[]) => void>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      isReady: false,

      workplaces: [],
      targetWorkplaceOptions: computed(() => {
        const options = state.workplaces.map((workplace) => {
          return { value: workplace.id as number | null, label: workplace.name }
        })
        options.unshift({ value: null, label: '全て対象' })
        return options
      }),
      alternativeWorkplaceOptions: computed(() => {
        const options = state.workplaces.map((workplace) => {
          return { value: workplace.id as number | null, label: workplace.name }
        })
        options.unshift({ value: null, label: '変更しない' })
        return options
      }),

      isAlternativeTimeSpanUnselectable: computed(() => {
        return props.updateTargets.some(el => isUpdateMetricsTargetWithImmutableTimeSpan(el))
      }),

      targetPartialName: '',
      targetWorkplaceId: null,
      targetTimeSpan: null,
      alternativePartialName: '',
      alternativeWorkplaceId: null,
      alternativeTimeSpan: null,

      validations: computed(() => {
        return {
          targetPartialName: {
            // 変更後の要素名が入力されている場合は置換対象となる文字列が必要
            // また、変更後の集計センターと周期がいずれも空の場合は置換先の文字列が必須なので、同時にこちらも必要になる
            required: !!state.alternativePartialName,
          },
          alternativePartialName: {
            // 変更後の変更後の集計センターと周期と同時に空にすることはできない
            required: !state.alternativeWorkplaceId && !state.alternativeTimeSpan,
          },
        }
      }),

      hasError: computed(() => vvHasError(root)),
      targetPartialNameError: computed(() => {
        return getError('targetPartialName') ||
          // 入力されていない場合、直接のエラー原因はalternativePartialName側になるが
          // alternativePartialName側もエラーであるならばtargetPartialNameもどの道入力必須であるのでフォームにはエラー判定を付ける
          // なお、vee-validateの仕様上文字列を返さなければならないのでalternativePartialNameのエラーがフォームに当たるが
          // LSの実装フレームワークにおいては文字列の内容自体は利用されないので特に問題はない
          (!state.targetPartialName ? getError('alternativePartialName') : '')
      }),
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)

    const isTargetToReplace = (updateTarget: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): boolean => {
      if (state.targetPartialName && !updateTarget.originalValue.name.match(state.targetPartialName)) return false
      if (state.targetWorkplaceId !== null && updateTarget.originalValue.workplaceId !== state.targetWorkplaceId) return false
      if (state.targetTimeSpan !== null && updateTarget.originalValue.timeSpan !== state.targetTimeSpan) return false
      return true
    }
    const getReplacedName = (originalName: string): string => {
      // 変更後の要素名が入力されていない場合は置換を行わず、元のメトリクス名を返す
      if (!state.alternativePartialName) return originalName

      return originalName.replace(state.targetPartialName, state.alternativePartialName)
    }
    const getReplacedWorkplaceId = (originalWorkplaceId: number): number => {
      return state.alternativeWorkplaceId !== null ? state.alternativeWorkplaceId : originalWorkplaceId
    }
    const getReplacedTimeSpan = (originalTimeSpan: TimeSpan): TimeSpan => {
      return state.alternativeTimeSpan !== null ? state.alternativeTimeSpan : originalTimeSpan
    }
    const replace = async(): Promise<void> => {
      if (!(await vvValidate(root))) return

      const params: MetricsCollectWithUniqueKeySetsRequestParameters = { queries: [] }

      props.updateTargets.forEach((updateTarget) => {
        if (!isTargetToReplace(updateTarget)) return

        params.queries.push({
          name: getReplacedName(updateTarget.originalValue.name),
          logiscope_workplace_id: getReplacedWorkplaceId(updateTarget.originalValue.workplaceId),
          time_span: getReplacedTimeSpan(updateTarget.originalValue.timeSpan),
        })
      })

      // 変更が発生しない場合はリクエストせずに閉じる
      if (params.queries.length === 0) {
        close()
        return
      }

      const metricsPartialInformation = await metricsApi.collectWithUniqueKeySets(params)

      const replacedUpdateTargets = props.updateTargets.map(updateTarget => {
        if (!isTargetToReplace(updateTarget)) return { ...updateTarget, updateValue: null }

        const replacedPartialInformation = metricsPartialInformation.find(el => {
          return el.name === getReplacedName(updateTarget.originalValue.name) &&
            el.workplaceId === getReplacedWorkplaceId(updateTarget.originalValue.workplaceId) &&
            el.timeSpan === getReplacedTimeSpan(updateTarget.originalValue.timeSpan)
        }) ?? null

        // 置換した結果取得されたメトリクスが元のメトリクスと同じ場合は置換対象としない
        if (replacedPartialInformation?.id === updateTarget.originalValue.id) {
          return { ...updateTarget, updateValue: null }
        }

        return { ...updateTarget, updateValue: replacedPartialInformation }
      })

      props.onReplaced(replacedUpdateTargets)
    }

    const close = (): void => {
      emit('close')
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false

      state.targetPartialName = ''
      state.targetWorkplaceId = null
      state.targetTimeSpan = null
      state.alternativePartialName = ''
      state.alternativeWorkplaceId = null
      state.alternativeTimeSpan = null

      state.workplaces = inject<() => Workplace[]>('injectWorkplaces', () => [])()
      // 親からのprovideがない場合や、取得に失敗している場合にはAPIから取得する
      if (state.workplaces.length === 0) {
        state.workplaces = await workplaceApi.index()
      }

      state.isReady = true
    })

    return {
      props,
      state,
      TARGET_TIME_SPAM_OPTIONS,
      ALTERNATIVE_TIME_SPAM_OPTIONS,
      getError,
      getErrorObject,
      replace,
      close,
    }
  },
})
