import {
  ReportDeepUpdateMetricsComponentParameters,
  ReportDeepUpdateMetricsRequestParameters,
} from 'src/models/api/Report/reportDeepUpdateMetricsRequestParameters'
import { MetricsBarGraphComponent, isComponentTypeMetricsBarGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent'
import { MetricsGroupedGraphComponent, isComponentTypeMetricsGroupedGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent'
import { MetricsPieChartComponent, isComponentTypeMetricsPieChart } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent'
import { MetricsTransitionGraphComponent, isComponentTypeMetricsTransitionGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent'
import { MetricsCardComponent, isComponentTypeMetricsCard, isMetricsCardComponentCompleted } from 'src/models/new/Component/MetricsComponent/metricsCardComponent'
import { MetricsListComponent, isComponentTypeMetricsList } from 'src/models/new/Component/MetricsComponent/metricsListComponent'
import { MetricsTableComponent, isComponentTypeMetricsTable } from 'src/models/new/Component/MetricsComponent/metricsTableComponent'
import { Component } from 'src/models/new/component'
import { Metrics } from 'src/models/new/metrics'
import { MetricsPartialInformation } from 'src/models/new/metricsPartialInformation'
import { Report } from 'src/models/new/report'
import { Section } from 'src/models/new/section'
import { isSameObjects } from 'src/util/object'

const CARD_MAIN = 'cardMain'
const CARD_REFERENCE = 'cardReference'
const TABLE_MAIN = 'tableMain'
const LIST_MAIN = 'listMain'
const LIST_REFERENCE = 'listReference'
const PIE_CHART_MAIN = 'pieChartMain'
const BAR_GRAPH_MAIN = 'barGraphMain'
const BAR_GRAPH_REFERENCE = 'barGraphReference'
const GROUPED_GRAPH_MAIN = 'groupedGraphMain'
const GROUPED_GRAPH_REFERENCE = 'groupedGraphReference'
const TRANSITION_GRAPH_MAIN = 'transitionGraphMain'
const TRANSITION_GRAPH_REFERENCE = 'transitionGraphReference'

export type UpdateMetricsTargetCategory = typeof CARD_MAIN | typeof CARD_REFERENCE | typeof TABLE_MAIN |
  typeof LIST_MAIN | typeof LIST_REFERENCE | typeof PIE_CHART_MAIN | typeof BAR_GRAPH_MAIN | typeof BAR_GRAPH_REFERENCE |
  typeof GROUPED_GRAPH_MAIN | typeof GROUPED_GRAPH_REFERENCE | typeof TRANSITION_GRAPH_MAIN | typeof TRANSITION_GRAPH_REFERENCE

export type CardMainIdentifier = {}
export type CardReferenceIdentifier = {}
export type TableMainIdentifier = {
  row: number
  column: number
}
type OrderedIdentifier = {
  order: number
}
export type ListMainIdentifier = OrderedIdentifier
export type ListReferenceIdentifier = OrderedIdentifier
export type PieChartMainIdentifier = OrderedIdentifier
export type BarGraphMainIdentifier = OrderedIdentifier
export type BarGraphReferenceIdentifier = {}
type GraphIdentifier = {
  scale: 'left' | 'right'
  number: number
}
type PlotIdentifier = GraphIdentifier & {
  group: number
}
export type GroupedGraphMainIdentifier = PlotIdentifier
export type GroupedGraphReferenceIdentifier = {}
export type TransitionGraphMainIdentifier = GraphIdentifier
export type TransitionGraphReferenceIdentifier = {}

export type UpdateMetricsTargetCategoryIdentifier = CardMainIdentifier | CardReferenceIdentifier | TableMainIdentifier |
  ListMainIdentifier | ListReferenceIdentifier | PieChartMainIdentifier | BarGraphMainIdentifier | BarGraphReferenceIdentifier |
  GroupedGraphMainIdentifier | GroupedGraphReferenceIdentifier | TransitionGraphMainIdentifier | TransitionGraphReferenceIdentifier

export type UpdateMetricsTarget<PI> = {
  section: Section
  component: Component
  category: UpdateMetricsTargetCategory
  originalValue: Metrics
  updateValue: MetricsPartialInformation | null
  positionIdentifier: PI
}

const CATEGORY_LABELS: Record<UpdateMetricsTargetCategory, string> = {
  [CARD_MAIN]: '主メトリクス',
  [CARD_REFERENCE]: '参考値',
  [TABLE_MAIN]: 'セル要素',
  [LIST_MAIN]: '主メトリクス',
  [LIST_REFERENCE]: '参考値',
  [PIE_CHART_MAIN]: '円グラフ要素',
  [BAR_GRAPH_MAIN]: '横棒',
  [BAR_GRAPH_REFERENCE]: '参考値',
  [GROUPED_GRAPH_MAIN]: '縦棒',
  [GROUPED_GRAPH_REFERENCE]: '参考値',
  [TRANSITION_GRAPH_MAIN]: 'グラフ',
  [TRANSITION_GRAPH_REFERENCE]: '参考値',
}

export const updateMetricsTargetToLabel = (target: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): string => {
  const getPositionString = (target: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): string | null => {
    if (isComponentTypeMetricsTable(target.component)) {
      if ('row' in target.positionIdentifier && 'column' in target.positionIdentifier) {
        return `${target.positionIdentifier.row}行目-${target.positionIdentifier.column}列目`
      }
    } else if (isComponentTypeMetricsList(target.component)) {
      if ('order' in target.positionIdentifier) {
        return `${target.positionIdentifier.order}${!target.component.isVertical ? '行' : '列'}目`
      }
    } else if (isComponentTypeMetricsPieChart(target.component) || isComponentTypeMetricsBarGraph(target.component)) {
      if ('order' in target.positionIdentifier) {
        return `${target.positionIdentifier.order}番目`
      }
    } else if (isComponentTypeMetricsGroupedGraph(target.component)) {
      if ('scale' in target.positionIdentifier && 'number' in target.positionIdentifier && 'group' in target.positionIdentifier) {
        if (target.positionIdentifier.scale === 'left') {
          return `左軸${target.positionIdentifier.number}のグループ${target.positionIdentifier.group}`
        } else if (target.positionIdentifier.scale === 'right') {
          return `右軸${target.positionIdentifier.number}のグループ${target.positionIdentifier.group}`
        }
      }
    } else if (isComponentTypeMetricsTransitionGraph(target.component)) {
      if ('scale' in target.positionIdentifier && 'number' in target.positionIdentifier) {
        if (target.positionIdentifier.scale === 'left') {
          return `左軸${target.positionIdentifier.number}`
        } else if (target.positionIdentifier.scale === 'right') {
          return `右軸${target.positionIdentifier.number}`
        }
      }
    }
    return null
  }
  const positionString = getPositionString(target)

  return positionString
    ? `${CATEGORY_LABELS[target.category]}（${positionString}）`
    : CATEGORY_LABELS[target.category]
}

export const isSameUpdateMetricsTargetCategoryIdentifiers = (
  a: UpdateMetricsTargetCategoryIdentifier,
  b: UpdateMetricsTargetCategoryIdentifier,
): boolean => isSameObjects(a, b)

// FIXME: Sectionも渡しているが、本当はComponentだけで良いようにできるはず
export const updateMetricsTargetsFromComponent = (component: Component, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  if (isComponentTypeMetricsCard(component) && isMetricsCardComponentCompleted(component)) {
    return updateMetricsTargetsFromMetricsCardComponent(component, section)
  } else if (isComponentTypeMetricsTable(component)) {
    return updateMetricsTargetsFromMetricsTableComponent(component, section)
  } else if (isComponentTypeMetricsList(component)) {
    return updateMetricsTargetsFromMetricsListComponent(component, section)
  } else if (isComponentTypeMetricsPieChart(component)) {
    return updateMetricsTargetsFromMetricsPieChartComponent(component, section)
  } else if (isComponentTypeMetricsBarGraph(component)) {
    return updateMetricsTargetsFromMetricsBarGraphComponent(component, section)
  } else if (isComponentTypeMetricsGroupedGraph(component)) {
    return updateMetricsTargetsFromMetricsGroupedGraphComponent(component, section)
  } else if (isComponentTypeMetricsTransitionGraph(component)) {
    return updateMetricsTargetsFromMetricsTransitionGraphComponent(component, section)
  }

  return []
}

const updateMetricsTargetsFromMetricsCardComponent = (component: MetricsCardComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  const targets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] = []

  const mainTarget = {
    section,
    component,
    category: CARD_MAIN as UpdateMetricsTargetCategory,
    originalValue: component.metrics,
    updateValue: null,
    positionIdentifier: {},
  }
  targets.push(mainTarget)

  if (component.subMetrics) {
    const referenceTarget = {
      section,
      component,
      category: CARD_REFERENCE as UpdateMetricsTargetCategory,
      originalValue: component.subMetrics,
      updateValue: null,
      positionIdentifier: {},
    }
    targets.push(referenceTarget)
  }

  return targets
}

const updateMetricsTargetsFromMetricsTableComponent = (component: MetricsTableComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  return component.data.map(el => {
    return {
      section,
      component,
      category: TABLE_MAIN as UpdateMetricsTargetCategory,
      originalValue: el.metrics,
      updateValue: null,
      positionIdentifier: { row: el.row, column: el.column },
    }
  })
}

const updateMetricsTargetsFromMetricsListComponent = (component: MetricsListComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  return component.data.layout.map(el => {
    return {
      section,
      component,
      category: (!el.isSubMetrics ? LIST_MAIN : LIST_REFERENCE) as UpdateMetricsTargetCategory,
      originalValue: el.metrics,
      updateValue: null,
      positionIdentifier: { order: el.sequentialOrder },
    }
  })
}

const updateMetricsTargetsFromMetricsPieChartComponent = (component: MetricsPieChartComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  return component.data.map(el => {
    return {
      section,
      component,
      category: PIE_CHART_MAIN as UpdateMetricsTargetCategory,
      originalValue: el.metrics,
      updateValue: null,
      positionIdentifier: { order: el.internalOrder },
    }
  })
}

const updateMetricsTargetsFromMetricsBarGraphComponent = (component: MetricsBarGraphComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  const targets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] = []

  const mainTargets = component.data.bars.map(el => {
    return {
      section,
      component,
      category: BAR_GRAPH_MAIN as UpdateMetricsTargetCategory,
      originalValue: el.metrics,
      updateValue: null,
      positionIdentifier: { order: el.sequentialOrder },
    }
  })
  targets.push(...mainTargets)

  if (component.data.reference) {
    const referenceTarget = {
      section,
      component,
      category: BAR_GRAPH_REFERENCE as UpdateMetricsTargetCategory,
      originalValue: component.data.reference.metrics,
      updateValue: null,
      positionIdentifier: {},
    }
    targets.push(referenceTarget)
  }

  return targets
}

const updateMetricsTargetsFromMetricsGroupedGraphComponent = (component: MetricsGroupedGraphComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  const targets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] = []

  const mainTargets = component.data.graphs.map(graph => {
    return graph.plots.map(el => {
      return {
        section,
        component,
        category: GROUPED_GRAPH_MAIN as UpdateMetricsTargetCategory,
        originalValue: el.metrics,
        updateValue: null,
        positionIdentifier: { group: el.group, scale: graph.scale, number: graph.number },
      }
    })
  }).flat()
  targets.push(...mainTargets)

  if (component.data.reference) {
    const referenceTarget = {
      section,
      component,
      category: BAR_GRAPH_REFERENCE as UpdateMetricsTargetCategory,
      originalValue: component.data.reference.metrics,
      updateValue: null,
      positionIdentifier: {},
    }
    targets.push(referenceTarget)
  }

  return targets
}

const updateMetricsTargetsFromMetricsTransitionGraphComponent = (component: MetricsTransitionGraphComponent, section: Section): UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] => {
  const targets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[] = []

  const mainTargets = component.data.graphs
    .filter(el => el.metrics)
    .map(el => {
      return {
        section,
        component,
        category: TRANSITION_GRAPH_MAIN as UpdateMetricsTargetCategory,
        originalValue: el.metrics!,
        updateValue: null,
        positionIdentifier: { scale: el.scale, number: el.number },
      }
    })
  targets.push(...mainTargets)

  if (component.data.reference) {
    const referenceTarget = {
      section,
      component,
      category: TRANSITION_GRAPH_REFERENCE as UpdateMetricsTargetCategory,
      originalValue: component.data.reference.metrics,
      updateValue: null,
      positionIdentifier: {},
    }
    targets.push(referenceTarget)
  }

  return targets
}

const convertGraphIdentifierToGraphNo = (identifier: GraphIdentifier): 'l1' | 'l2' | 'r1' | 'r2' => {
  return (identifier.scale === 'left' ? `l${identifier.number}` : `r${identifier.number}`) as 'l1' | 'l2' | 'r1' | 'r2'
}

export const convertUpdateTargetsToDeepUpdateMetricsRequestParameters = (
  updateTargets: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>[],
  report: Report,
): ReportDeepUpdateMetricsRequestParameters => {
  const componentParamsList: ReportDeepUpdateMetricsComponentParameters[] = updateTargets
    .reduce((paramsList, updateTarget) => {
      // updateTargetがセットされていない箇所はリクエストパラメータに含めない
      if (!updateTarget.updateValue) return paramsList

      const foundParams = paramsList.find(params => params.id === updateTarget.component.id)
      const isFirstToComponentId = !foundParams

      const params = isFirstToComponentId ? { id: updateTarget.component.id } : foundParams

      const cardCategories = [
        CARD_MAIN,
        CARD_REFERENCE,
      ]
      const tableCategories = [
        TABLE_MAIN,
      ]
      const listCategories = [
        LIST_MAIN,
        LIST_REFERENCE,
      ]
      const graphCategories = [
        PIE_CHART_MAIN,
        BAR_GRAPH_MAIN,
        BAR_GRAPH_REFERENCE,
        GROUPED_GRAPH_MAIN,
        GROUPED_GRAPH_REFERENCE,
        TRANSITION_GRAPH_MAIN,
        TRANSITION_GRAPH_REFERENCE,
      ]
      if (cardCategories.includes(updateTarget.category) && !params.single_value_component) {
        params.single_value_component = {}
      } else if (tableCategories.includes(updateTarget.category) && !params.table_component) {
        params.table_component = {}
      } else if (listCategories.includes(updateTarget.category) && !params.list_component) {
        params.list_component = {}
      } else if (graphCategories.includes(updateTarget.category) && !params.graph_component) {
        params.graph_component = {}
      }

      if (updateTarget.category === CARD_MAIN && params.single_value_component) {
        params.single_value_component.metrics_id = updateTarget.updateValue.id
      } else if (updateTarget.category === CARD_REFERENCE && params.single_value_component) {
        params.single_value_component.sub_metrics_id = updateTarget.updateValue.id
      } else if (updateTarget.category === TABLE_MAIN && params.table_component) {
        if ('row' in updateTarget.positionIdentifier && 'column' in updateTarget.positionIdentifier) {
          if (!params.table_component.metrics_table_components) {
            params.table_component.metrics_table_components = []
          }
          params.table_component.metrics_table_components.push({
            row_number: updateTarget.positionIdentifier.row,
            column_number: updateTarget.positionIdentifier.column,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === LIST_MAIN && params.list_component) {
        if ('order' in updateTarget.positionIdentifier) {
          if (!params.list_component.metrics_list_components) {
            params.list_component.metrics_list_components = []
          }
          params.list_component.metrics_list_components.push({
            sequential_order: updateTarget.positionIdentifier.order,
            is_sub_metrics: false,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === LIST_REFERENCE && params.list_component) {
        if ('order' in updateTarget.positionIdentifier) {
          if (!params.list_component.metrics_list_components) {
            params.list_component.metrics_list_components = []
          }
          params.list_component.metrics_list_components.push({
            sequential_order: updateTarget.positionIdentifier.order,
            is_sub_metrics: true,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === PIE_CHART_MAIN && params.graph_component) {
        if ('order' in updateTarget.positionIdentifier) {
          if (!params.graph_component.metrics_graph_components) {
            params.graph_component.metrics_graph_components = []
          }
          params.graph_component.metrics_graph_components.push({
            graph_no: 'l1',
            registration_order: updateTarget.positionIdentifier.order,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === BAR_GRAPH_MAIN && params.graph_component) {
        if ('order' in updateTarget.positionIdentifier) {
          if (!params.graph_component.metrics_graph_components) {
            params.graph_component.metrics_graph_components = []
          }
          params.graph_component.metrics_graph_components.push({
            graph_no: 'l1',
            registration_order: updateTarget.positionIdentifier.order,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === BAR_GRAPH_REFERENCE && params.graph_component) {
        params.graph_component.sub_metrics_id = updateTarget.updateValue.id
      } else if (updateTarget.category === GROUPED_GRAPH_MAIN && params.graph_component) {
        if ('scale' in updateTarget.positionIdentifier && 'number' in updateTarget.positionIdentifier && 'group' in updateTarget.positionIdentifier) {
          if (!params.graph_component.metrics_graph_components) {
            params.graph_component.metrics_graph_components = []
          }
          params.graph_component.metrics_graph_components.push({
            graph_no: convertGraphIdentifierToGraphNo(updateTarget.positionIdentifier),
            registration_order: updateTarget.positionIdentifier.group,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === GROUPED_GRAPH_REFERENCE && params.graph_component) {
        params.graph_component.sub_metrics_id = updateTarget.updateValue.id
      } else if (updateTarget.category === TRANSITION_GRAPH_MAIN && params.graph_component) {
        if ('scale' in updateTarget.positionIdentifier && 'number' in updateTarget.positionIdentifier) {
          if (!params.graph_component.metrics_graph_components) {
            params.graph_component.metrics_graph_components = []
          }
          params.graph_component.metrics_graph_components.push({
            graph_no: convertGraphIdentifierToGraphNo(updateTarget.positionIdentifier),
            registration_order: 1,
            metrics_id: updateTarget.updateValue.id,
          })
        }
      } else if (updateTarget.category === TRANSITION_GRAPH_REFERENCE && params.graph_component) {
        params.graph_component.sub_metrics_id = updateTarget.updateValue.id
      }

      if (isFirstToComponentId) {
        paramsList.push(params)
      }

      return paramsList
    }, [] as ReportDeepUpdateMetricsComponentParameters[])

  return {
    id: report.id,
    components: componentParamsList,
  }
}

export const isUpdateMetricsTargetWithImmutableTimeSpan = (target: UpdateMetricsTarget<UpdateMetricsTargetCategoryIdentifier>): boolean => {
  // メトリクスリストコンポーネント、メトリクスグループグラフコンポーネント、メトリクス推移グラフコンポーネントは
  // 主メトリクスが周期を共有する性質を持っているため、一括置換においては周期の変更を許可しない
  // 部分的に置換対象として選択した場合に周期が置換されるとエラーになってしまうか壊れたデータができてしまう為
  return [
    LIST_MAIN,
    GROUPED_GRAPH_MAIN,
    TRANSITION_GRAPH_MAIN,
  ].includes(target.category)
}
