import axios from 'axios';
import { buildBackendUrl } from 'src/apis/util/url';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import {
  ComponentLinkResponseData,
  convertFromComponentLinkResponseData,
} from 'src/models/api/Link/ComponentLink/componentLinkResponseData';
import { convertToComponentLinkCreateRequestParameters } from 'src/models/api/Link/ComponentLink/componentLinkCreateRequestParameters';
import { convertToComponentLinkUpdateRequestParameters } from 'src/models/api/Link/ComponentLink/componentLinkUpdateRequestParameters';
import { convertToLinkBulkUpdateSequentialOrderRequestParameters } from 'src/models/api/Link/linkBulkUpdateSequentialOrderRequestParameters';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (reportId: number, componentId: number): string =>
  `/masters/reports/${reportId}/components/${componentId}/component_links`;

export default {
  async index(reportId: number, componentId: number): Promise<ComponentLink[]> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId));
    const { data: resData } = await axios.get<MultiDataResponse<ComponentLinkResponseData>>(url);
    return resData.result.map(convertFromComponentLinkResponseData);
  },
  async create(reportId: number, componentId: number, componentLink: ComponentLink): Promise<{}> {
    const params = convertToComponentLinkCreateRequestParameters(componentLink);
    const url = buildBackendUrl(buildBasePath(reportId, componentId));
    await axios.post(url, params);
    return {};
  },
  async update(reportId: number, componentId: number, componentLink: ComponentLink): Promise<{}> {
    const params = convertToComponentLinkUpdateRequestParameters(componentLink);
    const url = buildBackendUrl(buildBasePath(reportId, componentId), params.id);
    await axios.put(url, params);
    return {};
  },
  async destroy(reportId: number, componentId: number, id: number): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId), id);
    await axios.delete(url);
    return {};
  },
  async bulkUpdateSequentialOrder(reportId: number, componentId: number, data: ComponentLink[]): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(reportId, componentId), 'bulk_update_sequential_order');
    const params = convertToLinkBulkUpdateSequentialOrderRequestParameters(data);
    await axios.put(url, params);
    return {};
  },
};
