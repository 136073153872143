import {
  MASTER_CSV_JOB_STATUS_TARGET_MASTER,
  MASTER_CSV_JOB_STATUS_EXECUTE_STATUS,
  MASTER_CSV_JOB_STATUS_EXECUTE_TYPE,
  MasterCsvJobStatus,
} from 'src/models/masterCsvJobStatus';
import { DateString } from 'src/models/common';
import { parseDatetime } from 'src/util/Datetime/parse';

export type MasterCsvJobStatusResponseData = {
  id: number;
  company_id: number;
  start_time: DateString;
  end_time: DateString | null;
  target_master: MASTER_CSV_JOB_STATUS_TARGET_MASTER;
  execute_type: MASTER_CSV_JOB_STATUS_EXECUTE_TYPE;
  execute_status: MASTER_CSV_JOB_STATUS_EXECUTE_STATUS;
  target_master_text: string;
  execute_status_text: string;
  execute_type_text: string;
  error_message: string | null;
  file_name: string;
  is_downloadable: boolean;
};

export const convertMasterCsvJobStatusResponseData = (data: MasterCsvJobStatusResponseData): MasterCsvJobStatus => {
  return {
    id: data.id,
    companyId: data.company_id,
    startTime: parseDatetime(data.start_time),
    endTime: data.end_time ? parseDatetime(data.end_time) : null,
    targetMaster: data.target_master,
    executeType: data.execute_type,
    executeStatus: data.execute_status,
    targetMasterText: data.target_master_text,
    executeStatusText: data.execute_status_text,
    executeTypeText: data.execute_type_text,
    errorMessage: data.error_message,
    fileName: data.file_name,
    isDownloadable: data.is_downloadable,
  };
};
