import { onMounted, onUnmounted, ref, Ref, unref } from 'vue';

export const useResizeObserver = (target: HTMLElement | Ref<HTMLElement | null>, callback: () => unknown) => {
  const observer = new ResizeObserver(callback);
  // アンマウント時に監視を確実に解除する必要がある為
  // targetはマウントまでは確定せずに変更を待機するが、マウント以降は変更しても無視する
  const targetElementAtMount = ref<HTMLElement | null>(null);

  onMounted(() => {
    targetElementAtMount.value = unref(target);
    if (targetElementAtMount.value !== null) {
      observer.observe(targetElementAtMount.value);
    }
  });

  onUnmounted(() => {
    if (targetElementAtMount.value !== null) {
      observer.unobserve(targetElementAtMount.value);
    }
  });
};
