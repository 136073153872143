export const transpose = (matrix: any[][]) => {
  const sampleRow = matrix[0];
  if (sampleRow === undefined) {
    return [];
  }
  return sampleRow.map((_, columnIndex) => matrix.map((row) => row[columnIndex]));
};

export const swap = (array: any[], index1: number, index2: number): any[] => {
  const newArray = [...array];
  newArray[index1] = array[index2];
  newArray[index2] = array[index1];
  return newArray;
};
