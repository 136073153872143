import { Workplace } from 'src/models/new/workplace'
import { useWorkplaces } from 'src/composables/asyncResources/useWorkplaces'
import { useResourceOptions } from 'src/composables/useResourceOptions'
import { RecordId } from 'src/util/recordId'

export const useWorkplaceOptions = () => {
  const {
    options,
    setResources,
    filterOptions,
  } = useResourceOptions<Workplace>('name')

  const {
    workplacesRef,
  } = useWorkplaces()

  setResources(workplacesRef.value)

  return {
    options,
    filterOptions,
  }
}
