import { LogiSystemDataMetricsQueryParameter } from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import { DataSourceParameterInput } from './types';
import { computed, inject, provide, ref, Ref } from 'vue';

const defaultDataSourceParameterInput: DataSourceParameterInput = {
  parameterId: null,
  values: [],
};

type InjectionValue = {
  inputs: Ref<DataSourceParameterInput[]>;
  maxLength: Ref<number>;
};

export type UseDataSourceParameterInputFormAdjustmentArgs = {
  inputs: Ref<DataSourceParameterInput[]>;
};

export type UseDataSourceParameterInputFormAdjustmentResult = {
  initialize: (logiSystemDataMetricsQueryParameters: LogiSystemDataMetricsQueryParameter[]) => void;
  clear: () => void;
  pop: () => void;
  push: () => void;
};

const key = Symbol('dataSourceParameterInputFormAdjustment');

// 引数は同じProvide/Inject可能なスコープの呼び出し元の間では共有されている前提で、最初に渡された値のみ利用される
export const useDataSourceParameterInputFormAdjustment = (args: UseDataSourceParameterInputFormAdjustmentArgs) => {
  const injected = inject<InjectionValue | null>(key, null);

  const inputs = injected !== null ? injected.inputs : args.inputs;
  const maxLength = injected !== null ? injected.maxLength : ref(0);

  if (injected === null) {
    provide(key, {
      inputs,
      maxLength,
    });
  }

  const initialize = (logiSystemDataMetricsQueryParameters: LogiSystemDataMetricsQueryParameter[]) => {
    const newInputs: DataSourceParameterInput[] = [];
    logiSystemDataMetricsQueryParameters.forEach((parameter) => {
      const index = newInputs.findIndex((input) => input.parameterId === parameter.parameterId);
      if (index !== -1) {
        newInputs[index]!.values.push(parameter.value);
      } else {
        newInputs.push({ parameterId: parameter.parameterId, values: [parameter.value] });
      }
    });
    inputs.value = newInputs;
    setMaxLength(1);
  };

  const setMaxLength = (value: number) => {
    maxLength.value = value;
  };

  const isFullLength = computed(() => inputs.value.length >= maxLength.value);

  const clear = () => {
    inputs.value = [structuredClone(defaultDataSourceParameterInput)];
  };

  const pop = () => {
    inputs.value.pop();
  };

  const push = () => {
    inputs.value.push(structuredClone(defaultDataSourceParameterInput));
  };

  return { initialize, setMaxLength, isFullLength, clear, pop, push };
};
