import { FieldError, MapObject } from 'vee-validate'
import { vvGetErrorObject, vvHasError } from '../vee_validate'
import Vue, { ComputedRef, computed } from 'vue'

export class EachValidationResult {
  public readonly key: string = ''
  private _vue: Vue = new Vue()
  private _error: ComputedRef<FieldError | null> = computed(() => vvGetErrorObject(this._vue, this.key))

  constructor(vue: Vue, key: string) {
    this._vue = vue
    this.key = key
  }

  get isValid(): boolean {
    return !this._error.value
  }

  get isInvalid(): boolean {
    return !this.isValid
  }

  get error(): FieldError | null {
    return this._error.value
  }
}

export class ValidationResult {
  private _vue: Vue = new Vue()
  private _validations: MapObject = {}
  private _eachResult: ComputedRef<EachValidationResult[]> = computed(() => {
    return Object.keys(this._validations).map(key => new EachValidationResult(this._vue, key))
  })

  constructor(vue: Vue, validations: MapObject) {
    this._vue = vue
    this._validations = validations
  }

  get isAllValid(): boolean {
    // EachValidationResultはvalidationsを与えない限り生成されないので、存在するフォーム全てが
    // 検証されている事を確認する場合はvvHasError()によってvueインスタンス上のエラー全てを調べる
    return !vvHasError(this._vue)
  }

  get isAnyInvalid(): boolean {
    return !this.isAllValid
  }

  private getEachResultObject(key: string): EachValidationResult | null {
    return this._eachResult.value.find(el => el.key === key) ?? null
  }

  isValid(key: string): boolean {
    const eachValidationResult = this.getEachResultObject(key)
    return !!eachValidationResult && eachValidationResult.isValid
  }

  isInvalid(key: string): boolean {
    const eachValidationResult = this.getEachResultObject(key)
    return !!eachValidationResult && eachValidationResult.isInvalid
  }

  getViolatedRule(key: string): string | null {
    const eachValidationResult = this.getEachResultObject(key)
    return eachValidationResult?.error?.rule ?? null
  }
}
