
import Vue, { defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, reactive } from 'vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import dataFetchStatusApi from 'src/apis/dataFetchStatus';
import { DataFetchStatus, getExecuteStatusColor } from 'src/models/dataFetchStatus';
import masterCsvJobStatusApi from 'src/apis/masterCsvJobStatus';
import { MasterCsvJobStatus } from 'src/models/masterCsvJobStatus';
import { requestTimeout, clearRequestTimeout, TimeoutID } from 'src/util/request_animation_frame';
import DateSelector from 'src/components/Workplace/DateSelector.vue';
import { notifyError1 } from 'src/hooks/notificationHook';
import { ERROR_REASON_INVALID_PARAMS } from 'src/consts';
import { formatDate, subMonths } from 'src/util/datetime';
import { Dictionary } from 'vue-router/types/router';
import { isValid, isBefore, startOfToday } from 'date-fns';
import { DATE_FNS_TIME_FORMAT, SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import DataFetchStatusList from 'src/views/Dashboard/DataFetchStatuses/DataFetchStatusList.vue';
import MasterCsvJobStatusList from 'src/views/Dashboard/DataFetchStatuses/MasterCsvJobStatusList.vue';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';

type Tab = 'dataFetchStatus' | 'masterCsvJobStatus';

type TabOption = {
  value: Tab;
  label: string;
};

const TAB_OPTIONS: TabOption[] = [
  {
    value: 'dataFetchStatus',
    label: 'データ更新',
  },
  {
    value: 'masterCsvJobStatus',
    label: 'マスタ入出力',
  },
];
const ITEM_PER_PAGE = 50;

interface State {
  isLoaded: boolean;
  // DateSelectorがpropsで日付を動的に変更できるインターフェースではない為、初期値が確定したかどうかのフラグを用意
  // 初期値が確定するまでDateSelectorを表示しない
  isSearchParameterReady: boolean;
  pageName: string | null;
  search: {
    startDate: Date;
    endDate: Date;
  };
  dataFetchStatuses: DataFetchStatus[];
  masterCsvJobStatuses: MasterCsvJobStatus[];

  loop: TimeoutID | null;
  lastUpdatedAt: string;

  hasPageAccessRole: boolean;

  dataLoadState: { availableTotal: number; loadedSlices: number };
  selectedTab: Tab;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    isLoaded: false,
    isSearchParameterReady: false,
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    search: {
      startDate: startOfToday(),
      endDate: startOfToday(),
    },
    dataFetchStatuses: [],
    masterCsvJobStatuses: [],
    clearSearchSelected: null,

    loop: null,
    lastUpdatedAt: '',
    hasPageAccessRole: true,

    dataLoadState: { availableTotal: 0, loadedSlices: 0 },
    selectedTab: 'dataFetchStatus',
  });
  return state;
}

export default defineComponent({
  components: {
    DateSelector,
    DataFetchStatusList,
    MasterCsvJobStatusList,
    PaginationContainer: usePaginationContainer<MasterCsvJobStatus>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, '作成状況');
    const state = setupState(root);

    const setStartDate = (date: Date): void => {
      state.search.startDate = date;
    };

    const setEndDate = (date: Date): void => {
      state.search.endDate = date;
    };

    const loadDataSlice = async (sliceNumber: number): Promise<void> => {
      // タブ切り替えによって切り替え前のデータおよびページングをクリア、表示を防ぐ
      state.masterCsvJobStatuses = [];
      state.dataFetchStatuses = [];

      try {
        let response;
        const queryParams = {
          start_dt: formatDate(state.search.startDate, SYSTEM_DATE_FORMAT),
          end_dt: formatDate(state.search.endDate, SYSTEM_DATE_FORMAT),
          page: sliceNumber,
        };
        if (state.selectedTab === 'dataFetchStatus') {
          response = await dataFetchStatusApi.index(queryParams);
          state.dataFetchStatuses = response.result;
        } else {
          response = await masterCsvJobStatusApi.index(queryParams);
          state.masterCsvJobStatuses = response.result;
        }
        state.dataLoadState.availableTotal = response.pagination.total;

        clearRequestTimeout(state.loop);
        state.lastUpdatedAt = formatDate(new Date(), DATE_FNS_TIME_FORMAT);
        state.loop = requestTimeout(() => loadDataSlice(sliceNumber), 60 * 1000);
        state.hasPageAccessRole = true;
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID_PARAMS) {
          notifyError1(root, errRes.message, { err });
        } else {
          notifyError1(root, errRes, { err });
        }
      }
    };

    const loadData = async (): Promise<void> => {
      loadDataSlice(1);
      state.dataLoadState.loadedSlices = 1;
    };

    const loadNextSliceData = async (): Promise<void> => {
      loadDataSlice(state.dataLoadState.loadedSlices + 1);
      state.dataLoadState.loadedSlices += 1;
    };

    const initializeDate = (): void => {
      // データ変換画面からの遷移時にクエリパラメータを引き継ぐ
      const routeQuery: Dictionary<unknown> = root.$route.query;
      if ('date' in routeQuery && typeof routeQuery.date === 'string' && routeQuery.date.match(/\d{4}-\d{2}-\d{2}/)) {
        const queryDate = new Date(routeQuery.date);
        if (isValid(queryDate) && isBefore(queryDate, new Date())) {
          setStartDate(queryDate);
          setEndDate(queryDate);
          return;
        }
      }
      setStartDate(startOfToday());
      setEndDate(startOfToday());
    };

    const changeTab = (tabValue: Tab): void => {
      state.selectedTab = tabValue;
      loadData();
    };

    const availableOldestDate = subMonths(startOfToday(), 1);

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.isSearchParameterReady = false;

      initializeDate();

      state.isSearchParameterReady = true;

      await Promise.all([ensureUserAndMasters(root), loadData()]);

      state.isLoaded = true;
    });

    onBeforeUnmount(async () => {
      clearRequestTimeout(state.loop);
    });

    return {
      state,
      availableOldestDate,
      setStartDate,
      setEndDate,
      getExecuteStatusColor,
      changeTab,
      TAB_OPTIONS,
      loadData,
      loadNextSliceData,
      ITEM_PER_PAGE,
    };
  },
});
