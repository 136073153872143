
import { PropType, defineComponent } from 'vue';
import TooltipContent from 'src/components/UIComponents/Tooltip/TooltipContent/TooltipContent.vue';
import type { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/types';
import { CSSProperties } from 'vue/types/jsx';
type Placement = 'top' | 'bottom' | 'left' | 'right';
/* 基本的にpointerを使うがpointerにしたくない場合はinheritを指定、それ以外は対応しない */
type CursorStyle = 'pointer' | 'inherit';

export default defineComponent({
  components: {
    TooltipContent,
  },
  props: {
    menus: {
      type: Array as PropType<TooltipMenuSettings[]>,
      required: true,
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'right',
    },
    // ツールチップの基準になる要素が小さいとき、ツールチップの横幅を制限しないと変なはみ出し方をする
    // このプロパティで横幅以外も変更できるが、ツールチップ全体のスタイルだけ設定できるようにしているのはそのため
    tooltipStyle: {
      type: Object as PropType<CSSProperties>,
      default: () => ({}),
    },
    beforeOpen: {
      type: Function as PropType<() => void>,
      default: () => () => {},
    },
    cursor: {
      type: String as PropType<CursorStyle>,
      default: 'pointer',
    },
    btnClasses: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // v-popoverはdiv.v-popover > div.trigger > (slot)の形でパースされる
    // v-popoverに@clickを設定してもpopoverの開閉イベントで上書きされてしまう為
    // slotの直下にクリック領域を設定し、バインドしたイベントを発火してからdiv.triggerをクリックさせる
    const onClick = async (e: Event) => {
      e.stopPropagation();

      if (props.disabled) {
        return;
      }

      const popoverTrigger = (e.currentTarget as HTMLElement).closest('.trigger') as HTMLElement;
      const popoverController = popoverTrigger.closest('.v-popover') as HTMLElement;

      const isOpen = popoverController.classList.contains('open');

      if (!isOpen) {
        await props.beforeOpen();
      }

      popoverTrigger.click();
    };

    return {
      props,
      onClick,
    };
  },
});
