
import { PropType, computed, defineComponent, getCurrentInstance, onMounted, reactive, watch } from 'vue'
import SelectItemForm, {
  ITEM_TYPE_METRICS,
  PartitionOption,
  SelectItemFormExtraProperty,
  SelectItemFormExtraSyncProperty,
  SelectItemFormItem,
} from 'src/components/SelectItemForm/index.vue'
import { MetricsGraphComponent, MetricsGraphReferenceForBorderLine } from 'src/models/new/Component/MetricsComponent/metricsGraphComponent'
import { TimeSpan, ALL_TIME_SPANS } from 'src/business/timeSpan'
import { Workplace } from 'src/models/new/workplace'
import {
  METRICS_PIE_CHART_COMPONENT_MAX_PIE_ELEMENTS,
  MetricsPieChartComponent,
  MetricsPieChartPieElement,
  isComponentTypeMetricsPieChart,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent'
import {
  MetricsTransitionGraphComponent,
  isComponentTypeMetricsTransitionGraph,
  switchMetricsTransitionGraphComponentPlotType,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent'
import { GraphLayoutScalePosition } from 'src/business/graphLayout'
import { vvGetError, vvGetErrorObject, vvReset, vvValidate } from 'src/util/vee_validate'
import { MapObject } from 'vee-validate'
import { Metrics } from 'src/models/new/metrics'
import { notifyError1 } from 'src/hooks/notificationHook'
import {
  METRICS_BAR_GRAPH_COMPONENT_MAX_BAR_ELEMENTS,
  MetricsBarGraphBarElement,
  MetricsBarGraphComponent,
  isComponentTypeMetricsBarGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent'
import {
  METRICS_GROUPED_GRAPH_COMPONENT_MAX_GROUPS,
  MetricsGroupedGraphComponent,
  MetricsGroupedGraphPlot,
  isComponentTypeMetricsGroupedGraph,
  switchMetricsGroupedGraphComponentPlotType,
  syncMetricsGroupedGraphComponentLinePlotColors,
  syncMetricsGroupedGraphGroupedLabel,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent'
import InputError from 'src/components/InputError.vue'

// TODO: 長い&グラフの種類ごとに処理が異なるので、グラフの種類ごとにコンポーネントを分割する

type PlotGraphPartitionOption = PartitionOption & {
  scale: GraphLayoutScalePosition | null
  number: number | null
  scaleName: string | null
}

type CustomSelectItemFormItem = SelectItemFormItem & {
  xExtraProperties: {
    name: string
    color: string
    order: number
  }
}

const SINGLE_GRAPH_WITH_REFERENCE_PARTITION_OPTIONS: PartitionOption[] = [
  { label: 'グラフ', value: 'graph' },
  { label: '参考値', value: 'reference' },
]
// FIXME: ベタ書きしてしまったが、左軸・右軸という表現はGraphLayoutのモデルに定義されている
// 可能ならモデルの提供する値を使用する
const PLOT_GRAPH_PARTITION_OPTIONS: PlotGraphPartitionOption[] = [
  { label: '左軸1', value: 'left1', scale: 'left', number: 1, scaleName: '左軸' },
  { label: '左軸2', value: 'left2', scale: 'left', number: 2, scaleName: '左軸' },
  { label: '右軸1', value: 'right1', scale: 'right', number: 1, scaleName: '右軸' },
  { label: '右軸2', value: 'right2', scale: 'right', number: 2, scaleName: '右軸' },
  { label: '参考値', value: 'reference', scale: null, number: null, scaleName: null },
]

const isPartitionOptionPlotGraphPartitionOption = (option: PartitionOption): option is PlotGraphPartitionOption => {
  return 'scale' in option && 'number' in option && 'scaleName' in option
}

const PLOT_GRAPH_TYPE_OPTIONS = [
  { label: '棒線', value: 'bar' },
  { label: '折れ線', value: 'line' },
]

type State = {
  isReady: boolean
  metricsGraphComponent: MetricsGraphComponent | null
  partitions: PartitionOption[]
  showExtraForm: boolean
  enabledExtraProperties: SelectItemFormExtraProperty[]
  syncedExtraProperties: SelectItemFormExtraSyncProperty[]
  extraPropertiesValidations: MapObject
  timeSpans: TimeSpan[] | undefined
  selectedPartition: PartitionOption | null
  itemLength: number
  validations: Record<string, object>

  selectedItems: (Metrics & CustomSelectItemFormItem)[]

  legendLabel: string | null
  plotType: 'bar' | 'line'
  scaleUnit: string | null
}

export default defineComponent({
  components: {
    SelectItemForm,
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<MetricsGraphComponent>,
      required: true,
    },
    backToForward: {
      type: Function as PropType<() => void>,
      required: true,
    },
    closeModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onUpdate: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    btnText: {
      type: String as PropType<string>,
      required: false,
      default: '設定',
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    workplaceCandidates: {
      type: Array as PropType<Workplace[]>,
      required: false,
    },
    dominantTimeSpan: {
      type: String as PropType<TimeSpan | null>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      isReady: false,
      metricsGraphComponent: null,
      partitions: computed(() => {
        const metricsGraphComponent = state.metricsGraphComponent
        if (!metricsGraphComponent) return []
        if (isComponentTypeMetricsBarGraph(metricsGraphComponent)) {
          return SINGLE_GRAPH_WITH_REFERENCE_PARTITION_OPTIONS
        } else if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
          return PLOT_GRAPH_PARTITION_OPTIONS
        } else if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
          return PLOT_GRAPH_PARTITION_OPTIONS
        } else {
          return []
        }
      }),
      showExtraForm: computed(() => {
        const metricsGraphComponent = state.metricsGraphComponent
        if (!metricsGraphComponent) return false
        if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
          return state.selectedPartition?.value !== 'reference'
        }
        if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
          return state.selectedPartition?.value !== 'reference'
        }
        return false
      }),
      enabledExtraProperties: computed((): SelectItemFormExtraProperty[] => {
        const metricsGraphComponent = state.metricsGraphComponent
        if (!metricsGraphComponent) return []
        if (isComponentTypeMetricsPieChart(metricsGraphComponent)) return ['name', 'color', 'order']
        if (isComponentTypeMetricsBarGraph(metricsGraphComponent)) return ['name', 'color', 'order']
        if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
          return state.selectedPartition?.value === 'reference'
            ? ['name', 'color']
            : ['name', 'color', 'order']
        }
        if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
          return state.selectedPartition?.value === 'reference' ? ['name', 'color'] : ['color']
        }
        return []
      }),
      // FIXME: ここからしばらくGroupedGraphとTransitionGraphの処理が重複している
      // 双方に共通の型を持たせることで解消できる部分が大きい
      syncedExtraProperties: computed((): SelectItemFormExtraSyncProperty[] => {
        const metricsGraphComponent = state.metricsGraphComponent
        if (!metricsGraphComponent) return []
        const partition = state.selectedPartition
        if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
          if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return []
          const graph = metricsGraphComponent.data.graphs.find(el => {
            return el.scale === partition.scale && el.number === partition.number
          })
          // 参考値の場合はgraphがundefinedになり、その場合も同期プロパティは設定なしでよい
          if (graph?.plotType === 'line') return ['color']
        }
        if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
          if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return []
          const graph = metricsGraphComponent.data.graphs.find(el => {
            return el.scale === partition.scale && el.number === partition.number
          })
          // 参考値の場合はgraphがundefinedになり、その場合も同期プロパティは設定なしでよい
          if (graph?.plotType === 'line') return ['color']
        }
        return []
      }),
      timeSpans: computed(() => {
        if (!state.metricsGraphComponent) return ALL_TIME_SPANS

        if (state.selectedPartition?.value !== 'reference') {
          // 通常、dominantTimeSpanは必ず存在する
          if (!props.dominantTimeSpan) return ALL_TIME_SPANS
          return [props.dominantTimeSpan]
        }
        return ALL_TIME_SPANS
      }),
      selectedPartition: null,
      itemLength: computed(() => {
        const metricsGraphComponent = state.metricsGraphComponent
        if (!metricsGraphComponent) return 0
        if (isComponentTypeMetricsPieChart(metricsGraphComponent)) return METRICS_PIE_CHART_COMPONENT_MAX_PIE_ELEMENTS
        if (isComponentTypeMetricsBarGraph(metricsGraphComponent)) {
          return state.selectedPartition?.value !== 'reference' ? METRICS_BAR_GRAPH_COMPONENT_MAX_BAR_ELEMENTS : 1
        }
        if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
          return state.selectedPartition?.value !== 'reference' ? METRICS_GROUPED_GRAPH_COMPONENT_MAX_GROUPS : 1
        }
        if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) return 1
        return 0
      }),
      validations: computed(() => {
        if (!state.showExtraForm) return {} as Record<string, object>
        const computeIsLabelRequired = () => {
          if (!state.metricsGraphComponent) return false
          if (isComponentTypeMetricsGroupedGraph(state.metricsGraphComponent)) {
            const partition = state.selectedPartition
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) throw new Error('invalid partition')
            return partition.number === 1 && !!state.metricsGraphComponent.data.graphs.find(
              el => el.scale === partition.scale && el.number === 2,
            )?.label
          }
          if (isComponentTypeMetricsTransitionGraph(state.metricsGraphComponent)) {
            const partition = state.selectedPartition
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) throw new Error('invalid partition')
            return partition.number === 1 && !!state.metricsGraphComponent.data.graphs.find(
              el => el.scale === partition.scale && el.number === 2,
            )?.label
          }
          return false
        }
        return {
          legendLabel: {
            required: computeIsLabelRequired(),
            max: 50,
          },
          // FIXME: グラフ軸の単位が10文字以内であることは関連する何らかのモデルにロジックを持たせた方が良い
          scaleUnit: { required: false, max: 10 },
        }
      }),
      selectedItems: [],
      legendLabel: computed({
        get() {
          const metricsGraphComponent = state.metricsGraphComponent
          if (!metricsGraphComponent) return null
          if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
            return metricsGraphComponent.data.graphs.find(el => {
              return `${el.scale}${el.number}` === state.selectedPartition?.value
            })?.label || null
          }
          if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
            return metricsGraphComponent.data.graphs.find(el => {
              return `${el.scale}${el.number}` === state.selectedPartition?.value
            })?.label || null
          }
          return null
        },
        set(value) {
          const metricsGraphComponent = state.metricsGraphComponent
          if (!metricsGraphComponent) return
          if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
            const graph = metricsGraphComponent.data.graphs.find(el => {
              const partition = state.selectedPartition
              if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return false
              return el.scale === partition?.scale && el.number === partition.number
            })
            if (graph) {
              graph.label = value
            }
          }
          if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
            const graph = metricsGraphComponent.data.graphs.find(el => {
              const partition = state.selectedPartition
              if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return false
              return el.scale === partition?.scale && el.number === partition.number
            })
            if (graph) {
              graph.label = value
            }
          }
        },
      }),
      plotType: computed({
        get() {
          // 型がnullを許容しないよう、extraFormを使用しない場合はdummyDefault入れている
          // extraFormを使用する場合のみこのstateが効果を発揮するため、そうでない場合は意識しなくてよい
          const dummyDefault = 'bar'
          const metricsGraphComponent = state.metricsGraphComponent
          if (!metricsGraphComponent) return dummyDefault
          if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
            return metricsGraphComponent.data.graphs.find(el => {
              return `${el.scale}${el.number}` === state.selectedPartition?.value
            })?.plotType || dummyDefault
          }
          if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
            return metricsGraphComponent.data.graphs.find(el => {
              return `${el.scale}${el.number}` === state.selectedPartition?.value
            })?.plotType || dummyDefault
          }
          return dummyDefault
        },
        set(value) {
          const metricsGraphComponent = state.metricsGraphComponent!
          const partition = state.selectedPartition
          if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return
            state.metricsGraphComponent = switchMetricsGroupedGraphComponentPlotType(
              metricsGraphComponent,
              { scale: partition.scale!, number: partition.number!, plotType: value },
            )
          }
          if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return
            state.metricsGraphComponent = switchMetricsTransitionGraphComponentPlotType(
              metricsGraphComponent,
              { scale: partition.scale!, number: partition.number!, plotType: value },
            )
          }
        },
      }),
      scaleUnit: computed({
        get() {
          const metricsGraphComponent = state.metricsGraphComponent
          if (!metricsGraphComponent) return null
          if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
            const partition = state.selectedPartition
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return null
            return metricsGraphComponent.scaleUnits[partition!.scale!] || null
          }
          if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
            const partition = state.selectedPartition
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return null
            return metricsGraphComponent.scaleUnits[partition!.scale!] || null
          }
          return null
        },
        set(value) {
          if (!state.metricsGraphComponent) return
          if (isComponentTypeMetricsGroupedGraph(state.metricsGraphComponent)) {
            const partition = state.selectedPartition
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return
            state.metricsGraphComponent.scaleUnits = {
              left: partition?.scale === 'left' ? value : state.metricsGraphComponent.scaleUnits.left,
              right: partition?.scale === 'right' ? value : state.metricsGraphComponent.scaleUnits.right,
            }
          }
          if (isComponentTypeMetricsTransitionGraph(state.metricsGraphComponent)) {
            const partition = state.selectedPartition
            if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return
            state.metricsGraphComponent.scaleUnits = {
              left: partition?.scale === 'left' ? value : state.metricsGraphComponent.scaleUnits.left,
              right: partition?.scale === 'right' ? value : state.metricsGraphComponent.scaleUnits.right,
            }
          }
        },
      }),
      extraPropertiesValidations: computed((): MapObject => {
        const validations = {}
        if (state.metricsGraphComponent) {
          if (
            isComponentTypeMetricsPieChart(state.metricsGraphComponent) ||
            isComponentTypeMetricsBarGraph(state.metricsGraphComponent) ||
            isComponentTypeMetricsGroupedGraph(state.metricsGraphComponent)
          ) {
            Object.assign(validations, { name: { required: true, max: 10 } })
          }
        }
        return validations
      }),
    })

    watch(() => state.partitions, () => {
      state.selectedPartition = state.partitions[0] ?? null
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)
    const clearErrors = (): void => {
      vvReset(root)
    }

    const selectedItemsFromMetricsPieChartComponent = (metricsGraphComponent: MetricsPieChartComponent): (Metrics & CustomSelectItemFormItem)[] => {
      return metricsGraphComponent.data.map(el => {
        return {
          ...el.metrics,
          xExtraProperties: {
            name: el.alias || '',
            color: el.color,
            order: el.internalOrder,
          },
        }
      })
    }

    const selectedItemsFromMetricsBarGraphComponent = (metricsGraphComponent: MetricsBarGraphComponent): (Metrics & CustomSelectItemFormItem)[] => {
      if (state.selectedPartition?.value === 'reference') {
        return metricsGraphComponent.data.reference
          ? [{
              ...metricsGraphComponent.data.reference!.metrics,
              xExtraProperties: {
                name: metricsGraphComponent.data.reference!.label || '',
                color: metricsGraphComponent.data.reference?.color || '',
                order: 0,
              },
            }]
          : []
      }
      return metricsGraphComponent.data.bars.map(el => {
        return {
          ...el.metrics,
          xExtraProperties: {
            name: el.alias || '',
            color: el.color,
            order: el.sequentialOrder,
          },
        }
      })
    }

    const selectedItemsFromMetricsGroupedGraphComponent = (metricsGraphComponent: MetricsGroupedGraphComponent): (Metrics & CustomSelectItemFormItem)[] => {
      const partition = state.selectedPartition
      if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return []
      if (partition.value === 'reference') {
        return metricsGraphComponent.data.reference
          ? [{
              ...metricsGraphComponent.data.reference!.metrics,
              xExtraProperties: {
                name: metricsGraphComponent.data.reference!.label || '',
                color: metricsGraphComponent.data.reference?.color || '',
                order: 0,
              },
            }]
          : []
      }
      const graph = metricsGraphComponent.data.graphs.find(el => {
        return el.scale === partition.scale &&
          el.number === partition.number
      })!
      return graph.plots.map(el => {
        return {
          ...el.metrics,
          xExtraProperties: {
            name: el.label || '',
            color: el.color || '',
            order: el.group,
          },
        }
      })
    }

    const selectedItemsFromMetricsTransitionGraphComponent = (metricsGraphComponent: MetricsTransitionGraphComponent): (Metrics & CustomSelectItemFormItem)[] => {
      const partition = state.selectedPartition
      if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return []
      if (partition.value === 'reference') {
        return metricsGraphComponent.data.reference
          ? [{
              ...metricsGraphComponent.data.reference!.metrics,
              xExtraProperties: {
                name: metricsGraphComponent.data.reference!.label || '',
                color: metricsGraphComponent.data.reference?.color || '',
                order: 0,
              },
            }]
          : []
      }
      const graph = metricsGraphComponent.data.graphs.find(el => {
        return el.scale === partition.scale &&
          el.number === partition.number
      })!
      return graph.metrics
        ? [{
            ...graph.metrics,
            xExtraProperties: {
              name: '',
              color: graph.color || '',
              order: 0,
            },
          }]
        : []
    }

    const setSelectedItems = (): void => {
      const metricsGraphComponent = state.metricsGraphComponent!

      if (isComponentTypeMetricsPieChart(metricsGraphComponent)) {
        state.selectedItems = selectedItemsFromMetricsPieChartComponent(metricsGraphComponent)
      } else if (isComponentTypeMetricsBarGraph(metricsGraphComponent)) {
        state.selectedItems = selectedItemsFromMetricsBarGraphComponent(metricsGraphComponent)
      } else if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
        state.selectedItems = selectedItemsFromMetricsGroupedGraphComponent(metricsGraphComponent)
      } else if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
        state.selectedItems = selectedItemsFromMetricsTransitionGraphComponent(metricsGraphComponent)
      } else {
        state.selectedItems = []
      }
    }

    const selectedItemToPieElement = (selectedItem: (Metrics & CustomSelectItemFormItem)): MetricsPieChartPieElement => {
      const { xExtraProperties, ...metrics } = selectedItem
      return {
        alias: xExtraProperties.name || null,
        // マスタ編集時は必ずarrangedOrder = internalOrder
        arrangedOrder: xExtraProperties.order,
        internalOrder: xExtraProperties.order,
        color: xExtraProperties.color,
        metrics,
        conditionalStatements: [],
      }
    }

    const selectedItemToBarElement = (selectedItem: (Metrics & CustomSelectItemFormItem)): MetricsBarGraphBarElement => {
      const { xExtraProperties, ...metrics } = selectedItem
      return {
        alias: xExtraProperties.name || null,
        sequentialOrder: xExtraProperties.order,
        color: xExtraProperties.color,
        metrics,
        conditionalStatements: [],
      }
    }

    const selectedItemToGroupedGraphPlot = (selectedItem: (Metrics & CustomSelectItemFormItem)): MetricsGroupedGraphPlot => {
      const { xExtraProperties, ...metrics } = selectedItem

      return {
        metrics,
        group: xExtraProperties.order,
        label: xExtraProperties.name || null,
        color: xExtraProperties.color,
        conditionalStatements: [],
      }
    }

    const selectedItemToReference = (selectedItem: (Metrics & CustomSelectItemFormItem)): MetricsGraphReferenceForBorderLine => {
      const { xExtraProperties, ...metrics } = selectedItem
      return {
        metrics,
        label: xExtraProperties.name || null,
        color: xExtraProperties.color,
        referenceDate: null,
        value: null,
      }
    }

    const updateComponentFromSelectedItems = (selectedItems: (Metrics & CustomSelectItemFormItem)[]): void => {
      const metricsGraphComponent = state.metricsGraphComponent!
      if (isComponentTypeMetricsPieChart(metricsGraphComponent!)) {
        const conditionalStatementsList = [...metricsGraphComponent.data]
          .sort((a, b) => a.internalOrder - b.internalOrder)
          .map(el => el.conditionalStatements)

        metricsGraphComponent.data = selectedItems
          .sort((a, b) => a.xExtraProperties.order - b.xExtraProperties.order)
          .map(el => {
            return {
              ...selectedItemToPieElement(el),
              conditionalStatements: conditionalStatementsList[el.xExtraProperties.order - 1] || [],
            }
          })
      } else if (isComponentTypeMetricsBarGraph(metricsGraphComponent!)) {
        if (state.selectedPartition?.value === 'reference') {
          metricsGraphComponent.data.reference = selectedItems.length > 0
            ? selectedItemToReference(selectedItems[0])
            : null
        } else {
          const conditionalStatementsList = [...metricsGraphComponent.data.bars]
            .sort((a, b) => a.sequentialOrder - b.sequentialOrder)
            .map(el => el.conditionalStatements)

          metricsGraphComponent.data.bars = selectedItems.map(el => {
            return {
              ...selectedItemToBarElement(el),
              conditionalStatements: conditionalStatementsList[el.xExtraProperties.order - 1] || [],
            }
          })
        }
      } else if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent!)) {
        const partition = state.selectedPartition
        if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return
        if (partition.value === 'reference') {
          metricsGraphComponent.data.reference = selectedItems.length > 0
            ? selectedItemToReference(selectedItems[0])
            : null
        } else {
          const conditionalStatementsList = metricsGraphComponent.data.graphs.find(el => {
            return el.scale === partition.scale && el.number === partition.number
          })!.plots.map(el => el.conditionalStatements)
          metricsGraphComponent.data.graphs = metricsGraphComponent.data.graphs.map(graph => {
            if (graph.scale !== partition.scale || graph.number !== partition.number) return graph
            return {
              ...graph,
              plots: selectedItems.map(el => {
                return {
                  ...selectedItemToGroupedGraphPlot(el),
                  conditionalStatements: conditionalStatementsList[el.xExtraProperties.order - 1] || [],
                }
              }),
            }
          })
        }
      } else if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent!)) {
        const partition = state.selectedPartition
        if (!partition || !isPartitionOptionPlotGraphPartitionOption(partition)) return
        if (partition.value === 'reference') {
          metricsGraphComponent.data.reference = selectedItems.length > 0
            ? selectedItemToReference(selectedItems[0])
            : null
        } else {
          if (selectedItems.length === 0) {
            metricsGraphComponent.data.graphs = metricsGraphComponent.data.graphs.map(el => {
              if (el.scale !== partition.scale || el.number !== partition.number) return el
              return { ...el, metrics: null, conditionalStatements: [] }
            })
          } else {
            const { xExtraProperties, ...metrics } = selectedItems[0]
            const conditionalStatements = metricsGraphComponent.data.graphs.find(el => {
              return el.scale === partition.scale && el.number === partition.number
            })!.conditionalStatements
            metricsGraphComponent.data.graphs = metricsGraphComponent.data.graphs.map(el => {
              if (el.scale === partition.scale && el.number === partition.number) {
                return {
                  ...el,
                  metrics,
                  conditionalStatements: conditionalStatements,
                  color: xExtraProperties.color,
                }
              } else {
                return el
              }
            })
          }
        }
      }
    }

    const isMetricsWithInconsistentTimeSpan = (item: SelectItemFormItem, currentItems: SelectItemFormItem[]): boolean => {
      if (state.selectedPartition?.value === 'reference') return false
      const currentTimeSpan = currentItems.length > 0 ? currentItems[0].timeSpan : null
      return !!currentTimeSpan && item.timeSpan !== currentTimeSpan
    }

    const onPlotTypeChange = () => {
      // partitionの切り替えやフォームの確定時に同様の効果の処理が実行されるが
      // このケースでは大本のmetricsGraphComponentを書き換える計算は行う必要がない
      // selectedItemのみを書き換えている動きを取るため、コードが共通でない
      if (isComponentTypeMetricsGroupedGraph(state.metricsGraphComponent!)) {
        if (state.selectedPartition?.value === 'reference') return
        if (state.plotType !== 'line') return
        const selectedItems = state.selectedItems
        if (selectedItems.length === 0) return
        const color = selectedItems[0].xExtraProperties.color

        state.selectedItems = state.selectedItems.map(el => {
          return {
            ...el,
            xExtraProperties: {
              ...el.xExtraProperties,
              color,
            },
          }
        })
      }
    }

    const onItemAdded = (selectedItems: (Metrics & CustomSelectItemFormItem)[]): void => {
      const metricsGraphComponent = state.metricsGraphComponent!

      if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
        const addedItemOrder = selectedItems[selectedItems.length - 1].xExtraProperties.order
        const label = metricsGraphComponent.data.graphs
          .reduce((label, graph) => {
            return label || graph.plots.find(plot => plot.group === addedItemOrder)?.label || ''
          }, '')
        state.selectedItems = [...selectedItems.map(el => {
          return el.xExtraProperties.order === addedItemOrder
            ? { ...el, xExtraProperties: { ...el.xExtraProperties, name: label } }
            : el
        })]
      }
    }

    const organizeAfterUpdateComponent = (): void => {
      const partition = state.selectedPartition

      if (isComponentTypeMetricsGroupedGraph(state.metricsGraphComponent!)) {
        if (!partition) throw Error
        if (partition.value === 'reference') return
        if (!isPartitionOptionPlotGraphPartitionOption(partition)) throw Error
        state.metricsGraphComponent = syncMetricsGroupedGraphGroupedLabel(
          state.metricsGraphComponent,
          { scale: partition.scale!, number: partition.number!, plotType: state.plotType },
        )
        state.metricsGraphComponent = syncMetricsGroupedGraphComponentLinePlotColors(
          state.metricsGraphComponent as MetricsGroupedGraphComponent,
        )
      }
    }

    const onPartitionSelect = async(
      partition: PlotGraphPartitionOption,
      selectedItems: (Metrics & CustomSelectItemFormItem)[],
    ): Promise<void> => {
      if (!await vvValidate(root)) return
      updateComponentFromSelectedItems(selectedItems as (Metrics & CustomSelectItemFormItem)[])
      organizeAfterUpdateComponent()
      state.selectedPartition = partition
      setSelectedItems()
      clearErrors()
    }

    const onConfirm = async(selectedItems: (Metrics & CustomSelectItemFormItem)[]): Promise<void> => {
      if (!await vvValidate(root)) return
      updateComponentFromSelectedItems(selectedItems as (Metrics & CustomSelectItemFormItem)[])
      organizeAfterUpdateComponent()

      const metricsGraphComponent = state.metricsGraphComponent!
      if (isComponentTypeMetricsPieChart(metricsGraphComponent)) {
        // TODO: 「メトリクスが正しくセットされているか」の判定は各モデルの役割として移動させる
        if (metricsGraphComponent.data.length === 0) {
          notifyError1(root, 'メトリクスを選択してください。')
          return
        }
      } else if (isComponentTypeMetricsBarGraph(metricsGraphComponent)) {
        if (metricsGraphComponent.data.bars.length === 0) {
          notifyError1(root, 'メトリクスを選択してください。')
          return
        }
      } else if (isComponentTypeMetricsGroupedGraph(metricsGraphComponent)) {
        const plotCount = metricsGraphComponent.data.graphs
          .find(el => el.scale === 'left' && el.number === 1)!.plots.length
        if (plotCount === 0) {
          notifyError1(root, '左軸1のメトリクスを選択してください。')
          return
        } else if (metricsGraphComponent.data.graphs.some(el => el.plots.length !== plotCount && el.plots.length !== 0)) {
          notifyError1(root, 'メトリクスの選択数が異なっています。')
          return
        }
      } else if (isComponentTypeMetricsTransitionGraph(metricsGraphComponent)) {
        if (!metricsGraphComponent.data.graphs.find(el => el.scale === 'left' && el.number === 1)!.metrics) {
          notifyError1(root, '左軸1のメトリクスを選択してください。')
          return
        }
      }

      emit('input', { ...metricsGraphComponent })
      props.onUpdate()
    }

    const computeDuplicatedMetricsIds = (selectedItems: (Metrics & CustomSelectItemFormItem)[]): number[] => {
      if (!state.metricsGraphComponent) return []
      if (!state.selectedPartition) return []

      // 円グラフはpartitionが無いため重複しない
      if (isComponentTypeMetricsPieChart(state.metricsGraphComponent)) return []

      // この時点では現在のpartitionの選択が反映されていないため、
      // - 現在のpartitionを除外する
      // - 現在の選択状態(=引数のselectedItems)を追加する
      const metricsIds: number[] = []
      if (isComponentTypeMetricsBarGraph(state.metricsGraphComponent)) {
        if (state.selectedPartition.value === 'reference') {
          metricsIds.push(...state.metricsGraphComponent.data.bars.filter(graph => graph.metrics).map(graph => graph.metrics!.id))
        } else {
          if (state.metricsGraphComponent.data.reference) {
            metricsIds.push(state.metricsGraphComponent.data.reference.metrics.id)
          }
        }
      } else if (isComponentTypeMetricsGroupedGraph(state.metricsGraphComponent)) {
        const graphs = state.metricsGraphComponent.data.graphs.filter(graph => graph.scale + graph.number !== state.selectedPartition!.value)
        metricsIds.push(...graphs.map(graph => graph.plots.map(plot => plot.metrics.id)).flat())
        if (state.selectedPartition.value !== 'reference' && state.metricsGraphComponent.data.reference) {
          metricsIds.push(state.metricsGraphComponent.data.reference.metrics.id)
        }
      } else if (isComponentTypeMetricsTransitionGraph(state.metricsGraphComponent)) {
        const graphs = state.metricsGraphComponent.data.graphs.filter(graph => graph.scale + graph.number !== state.selectedPartition!.value)
        metricsIds.push(...graphs.filter(graph => graph.metrics).map(graph => graph.metrics!.id))
        if (state.selectedPartition.value !== 'reference' && state.metricsGraphComponent.data.reference) {
          metricsIds.push(state.metricsGraphComponent.data.reference.metrics.id)
        }
      }
      metricsIds.push(...selectedItems.map(item => item.id))

      const countsById = metricsIds.reduce((countMap: Record<number, number>, id: number) => {
        return { ...countMap, [id]: (countMap[id] || 0) + 1 }
      }, {})
      return Object.keys(countsById).map(Number).filter((id: number) => countsById[id] > 1)
    }

    onMounted(() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false

      state.metricsGraphComponent = props.value
      state.selectedPartition = state.partitions.length > 0 ? state.partitions[0] : null
      setSelectedItems()

      state.isReady = true
    })

    return {
      state,
      props,
      ITEM_TYPE_METRICS,
      PLOT_GRAPH_PARTITION_OPTIONS,
      PLOT_GRAPH_TYPE_OPTIONS,
      getError,
      getErrorObject,
      isMetricsWithInconsistentTimeSpan,
      onPlotTypeChange,
      onItemAdded,
      onPartitionSelect,
      onConfirm,
      updateComponentFromSelectedItems,
      computeDuplicatedMetricsIds,
    }
  },
})
