import { ConditionalStatementWithContainerPosition } from 'src/models/new/conditionalStatement'

export type ConditionalStatementBulkUpdatePriorityRequestParameters = {
  primary_position_id: number
  secondary_position_id: number
  targets: {
    id: number
    priority: number
  }[]
}

export const convertToConditionalStatementBulkUpdatePriorityRequestParameters = (
  primaryPositionId: number,
  secondaryPositionId: number,
  statements: ConditionalStatementWithContainerPosition<any>[],
): ConditionalStatementBulkUpdatePriorityRequestParameters => {
  const targets = statements.map((statement) => {
    return {
      id: statement.id,
      priority: statement.priority,
    }
  })

  return {
    primary_position_id: primaryPositionId,
    secondary_position_id: secondaryPositionId,
    targets,
  }
}
