
import { Component } from 'src/models/new/component'
import { Report } from 'src/models/new/report'
import { sectionToNumberedLabel } from 'src/models/new/section'
import Vue, { PropType, computed, defineComponent, getCurrentInstance, reactive } from 'vue'

export type ComponentElementSearchParams = {
  sectionIds: number[]
  componentIds: number[]
  keyword: string | null
}

type NumberOption = {
  value: number
  label: string
}

interface State {
  sectionOptions: NumberOption[]
  componentOptions: NumberOption[]
}

function setupState(root : Vue): State {
  const state: State = reactive({
    sectionOptions: computed(() => {
      const report: Report = root.$props.report
      return (report?.sections ?? []).map(el => {
        return {
          value: el.id,
          label: sectionToNumberedLabel(el),
        }
      })
    }),
    componentOptions: computed(() => {
      const report: Report = root.$props.report
      const components: (Component & { sectionId: number })[] = root.$props.components
      const searchPramas: ComponentElementSearchParams = root.$props.value
      return components.filter(el => {
        return !searchPramas.sectionIds ||
          searchPramas.sectionIds.includes(el.sectionId)
      }).map(el => {
        const section = report.sections.find(section => section.id === el.sectionId)!
        return {
          value: el.id,
          label: `${section.sequentialOrder}: ${el.name}`,
        }
      })
    }),
  })

  return state
}

export default defineComponent({
  components: {
  },
  props: {
    value: {
      type: Object as PropType<ComponentElementSearchParams>,
      required: true,
    },
    report: {
      type: Object as PropType<Report>,
      required: true,
    },
    components: {
      type: Array as PropType<(Component & { sectionId: number })[]>,
      required: true,
    },
    onSearch: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state = setupState(root)

    const onSectionChange = (values: number[]): void => {
      const searchParams = {
        ...props.value,
        componentIds: (props.value.componentIds ?? []).filter(el => {
          const component = props.components.find(component => component.id === el)!
          return values.includes(component.sectionId)
        }),
      }
      emit('input', searchParams)
    }

    return {
      state,
      props,
      onSectionChange,
    }
  },
})

