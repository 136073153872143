
import { defineComponent, PropType } from 'vue';
import MetricsBadge from 'src/components/MetricsBadge.vue';
import { Metrics } from 'src/models/new/metrics';
import {
  constructEmptyDirectInputMetrics,
  isMetricsDirectInputMetrics,
} from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import {
  constructEmptyLogimeterDataMetrics,
  isMetricsLogimeterDataMetrics,
} from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logimeterDataMetrics';
import { constructEmptyCalculatedMetrics, isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics';
import {
  constructEmptyBundledBorderlessMetrics,
  constructEmptyBundledMetrics,
  isMetricsBundledBorderless,
  isMetricsBundledMetrics,
} from 'src/models/new/Metrics/bundledMetrics';
import { constructEmptySummaryMetrics, isMetricsSummaryMetrics } from 'src/models/new/Metrics/summaryMetrics';
import { constructEmptyReferenceMetrics, isMetricsReferenceMetrics } from 'src/models/new/Metrics/referenceMetrics';

const DESCRIPTION_DIRECT_INPUT = '手入力またはCSVにより直接データを登録するメトリクス。';
const DESCRIPTION_LOGIMETER = 'KURANDOロジメーターから、作業工数や出勤時間などのデータを自動取り込みするメトリクス。';
const DESCRIPTION_CALCULATED_METRICS = 'すでに作成されたメトリクスに対して四則演算を行います。';
const DESCRIPTION_BUNDLED_METRICS = 'すでに作成された複数のメトリクスを集計し、合計や平均などを算出します。';
const DESCRIPTION_SUMMARY_METRICS = 'すでに作成されたメトリクスを期間で集計し、合計や平均などを算出します。';
const DESCRIPTION_BUNDLED_BORDERLESS_METRICS = '複数の集計用センターを横断したメトリクスを作成したい場合に利用します。';
const DESCRIPTION_REFERENCE_METRICS =
  'すでに作成されたメトリクスについて、現在と過去の値を比較したい場合に利用します。';

export default defineComponent({
  components: {
    MetricsBadge,
  },
  props: {
    onSelect: {
      type: Function as PropType<(skeleton: Metrics) => void>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const getSkeletons = (): Metrics[] => {
      return [
        constructEmptyDirectInputMetrics(),
        constructEmptyLogimeterDataMetrics(),
        constructEmptyCalculatedMetrics(),
        constructEmptyBundledMetrics(),
        constructEmptySummaryMetrics(),
        constructEmptyBundledBorderlessMetrics(),
        constructEmptyReferenceMetrics(),
      ];
    };

    const getMetricsTypeDescription = (metrics: Metrics): string => {
      if (isMetricsDirectInputMetrics(metrics)) {
        return DESCRIPTION_DIRECT_INPUT;
      }
      if (isMetricsLogimeterDataMetrics(metrics)) {
        return DESCRIPTION_LOGIMETER;
      }
      if (isMetricsCalculatedMetrics(metrics)) {
        return DESCRIPTION_CALCULATED_METRICS;
      }
      if (isMetricsBundledMetrics(metrics) && !isMetricsBundledBorderless(metrics)) {
        return DESCRIPTION_BUNDLED_METRICS;
      }
      if (isMetricsSummaryMetrics(metrics)) {
        return DESCRIPTION_SUMMARY_METRICS;
      }
      if (isMetricsBundledMetrics(metrics) && isMetricsBundledBorderless(metrics)) {
        return DESCRIPTION_BUNDLED_BORDERLESS_METRICS;
      }
      if (isMetricsReferenceMetrics(metrics)) {
        return DESCRIPTION_REFERENCE_METRICS;
      }
      return '';
    };

    const close = () => {
      emit('close');
    };

    return {
      props,
      getSkeletons,
      getMetricsTypeDescription,
      close,
    };
  },
});
