import { isSameObjects } from '../object';

export const STORED_PARAMETERS_URL = 'url';
export const STORED_PARAMETERS_PAGE_TEMPORARY = 'pageTemporary';
export type StoredParametersStoreMode = typeof STORED_PARAMETERS_URL | typeof STORED_PARAMETERS_PAGE_TEMPORARY;

const DEFAULT_KEY = 'default';

export type QueryParameters = Record<string, string>;
/**
 * Parameter's Key must be Lower CamelCased string.
 * Ex. { timeSpan: 'weekly', sectionNumber: '5' }
 */
export type Parameters = QueryParameters | Record<string, string | number | null | object>;

const isParametersQueryParameters = (params: Parameters): params is QueryParameters => {
  return Object.values(params).every((el) => typeof el === 'string' || typeof el === 'number');
};

const convertKeysToKebab = (params: QueryParameters): QueryParameters => {
  return Object.keys(params).reduce((newParams, key) => {
    newParams[key.replace(/([A-Z])/g, '-$1').toLowerCase()] = params[key]!;
    return newParams;
  }, {} as QueryParameters);
};

const convertKeysFromKebab = (params: QueryParameters): QueryParameters => {
  return Object.keys(params).reduce((newParams, key) => {
    newParams[key.replace(/-([a-z])/g, (_, g) => g.toUpperCase())] = params[key]!;
    return newParams;
  }, {} as QueryParameters);
};

const setUrlStoredParameters = (vue: Vue, params: QueryParameters) => {
  const path = vue.$route.path;
  const query = convertKeysToKebab(params) as Record<string, string>;
  // 現在のURLと完全に一致する場合はNavigationDuplicatedを避けるため何もしない
  if (!isSameObjects(query, vue.$route.query)) {
    vue.$router.replace({ path, query });
  }
};

const setPageTemporaryStoredParameters = (vue: Vue, key: string, params: Parameters) => {
  vue.$store.commit('storedParameters/setByKey', { key, value: params });
};

export const setStoredParameters = (
  vue: Vue,
  params: Parameters,
  mode: StoredParametersStoreMode,
  key: string | null = DEFAULT_KEY,
) => {
  switch (mode) {
    case STORED_PARAMETERS_URL:
      if (!isParametersQueryParameters(params)) {
        throw new Error('All query parameters must be string or number.');
      }
      setUrlStoredParameters(vue, params);
      break;
    case STORED_PARAMETERS_PAGE_TEMPORARY:
      if (!key) {
        throw new Error('Key must be specified.');
      }
      setPageTemporaryStoredParameters(vue, key, params);
      break;
  }
};

const getUrlStoredParameters = (vue: Vue): QueryParameters => {
  // QueryParameterはキーをstring、値をstringとしているが、Route.queryは
  // 値がnullの場合や配列の場合を許容しており、このモジュールではそれらのケースがあった時パラメータから除外する
  const query = vue.$route.query;
  const params = Object.keys(query).reduce((params, key) => {
    const value = query[key];
    if (typeof value === 'string') {
      params[key] = value;
    }
    return params;
  }, {} as QueryParameters);
  // クエリ上のキーはケバブケースを想定をし、キャメルケースに変換する
  return convertKeysFromKebab(params);
};

const getPageTemporaryStoredParameters = (vue: Vue, key: string): Parameters => {
  return vue.$store.getters['storedParameters/byKey'](key);
};

export const getStoredParameters = <T extends Parameters>(
  vue: Vue,
  mode: StoredParametersStoreMode,
  key: string | null = DEFAULT_KEY,
): T => {
  switch (mode) {
    case STORED_PARAMETERS_URL:
      return getUrlStoredParameters(vue) as T;
    case STORED_PARAMETERS_PAGE_TEMPORARY:
      if (!key) {
        throw new Error('Key must be specified.');
      }
      return getPageTemporaryStoredParameters(vue, key) as T;
  }
};

const initializeUrlStoredParameters = (vue: Vue) => {
  const path = vue.$route.path;
  vue.$router.replace({ path });
};

const initializePageTemporaryStoredParameters = (vue: Vue) => {
  return vue.$store.commit('storedParameters/clear');
};

// 初期化処理にはキー指定が存在しないので注意
export const initializeStoredParameters = (vue: Vue, mode: StoredParametersStoreMode) => {
  switch (mode) {
    case STORED_PARAMETERS_URL:
      return initializeUrlStoredParameters(vue);
    case STORED_PARAMETERS_PAGE_TEMPORARY:
      return initializePageTemporaryStoredParameters(vue);
  }
};
