export type Link = {
  id: number
  name: string
  url: string
  sequentialOrder: number
}

export const constructEmptyLink = (): Link => {
  return {
    id: 0,
    name: '',
    url: '',
    // 空で生成した場合はダミー値を与える
    sequentialOrder: 0,
  }
}
