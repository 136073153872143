<template>
  <div class="sidebar" :class="{'hover-expand': shouldHoverExpand}"
       :data-color="backgroundColor"
       :data-active-color="activeColor">
    <div class="logo">
      <a class="simple-text logo-mini" @click="goHome"
         aria-label="sidebar mini logo">
          <div class="logo-img">
            <img :src="logo" alt="" :style="logoStyle">
          </div>
      </a>
      <a class="simple-text logo-normal">
        {{ title }}
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot>

      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarStore.sidebarLinks"
                        :key="link.name + index"
                        :link="link" :sidebar-store="sidebarStore">

            <sidebar-item v-for="(subLink, index) in link.children"
                          :key="subLink.name + index"
                          :link="subLink" :sidebar-store="sidebarStore">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'
import SidebarItem from 'src/components/UIComponents/Sidebar/SidebarItem'

export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Vue PDP Pro',
      description: 'Sidebar title',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        const acceptedValues = ['white', 'brown', 'black']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar background color (white|brown|black)',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar active text color (primary|info|success|warning|danger)',
    },
    logo: {
      type: String,
      default: '/static/img/vue-logo.png',
      description: 'Sidebar Logo',
    },
    logoStyle: {
      type: String,
      default: '',
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    sidebarStore: {
      type: Object,
      required: true,
    },
    shouldHoverExpand: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  methods: {
    async initScrollBarAsync() {
      const isWindows = navigator.platform.startsWith('Win')
      if (!isWindows) {
        return
      }
      const PerfectScroll = await import('perfect-scrollbar')
      PerfectScroll.initialize(this.$refs.sidebarScrollArea)
    },
    async goHome() {
      // クエリパラメータやハッシュ等も同一性の判定に含め、遷移時に削除する
      if (this.$router.currentRoute.fullPath === '/') return
      this.$router.push({ name: 'Home', query: undefined, hash: '' })
    },
  },
  mounted() {
    this.initScrollBarAsync()
  },
  beforeDestroy() {
    if (this.sidebarStore.showSidebar) {
      // eslint-disable-next-line vue/no-mutating-props
      this.sidebarStore.showSidebar = false
    }
  },
  components: { SidebarItem },
}
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu{
    display: none;
  }
}
.logo .logo-mini {
  cursor: pointer;
}
</style>
