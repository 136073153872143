import { Link } from 'src/models/new/link'

export type LinkBulkUpdateSequentialOrderRequestParameters = {
  targets: {
    id: number
    sequential_order: number
  }[]
}

export const convertToLinkBulkUpdateSequentialOrderRequestParameters = (links: Link[]): LinkBulkUpdateSequentialOrderRequestParameters => {
  const targets = links.map((link) => {
    return {
      id: link.id,
      sequential_order: link.sequentialOrder,
    }
  })

  return { targets }
}
