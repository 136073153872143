import { AGGREGATE_FUNCTION_SUM } from 'src/business/aggregateFunction'
import { TIME_SPAN_DAILY } from 'src/business/timeSpan'
import { Metrics, internal } from 'src/models/new/metrics'
import { LogiSystemDataMetrics } from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics'

const LOGIMETER = internal.LOGIMETER

export type LogimeterDataMetrics = LogiSystemDataMetrics

export const isMetricsLogimeterDataMetrics = (metrics: Metrics): metrics is LogimeterDataMetrics => {
  return metrics.metricsType === LOGIMETER
}

export const constructEmptyLogimeterDataMetrics = (): LogimeterDataMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: LOGIMETER,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    logiDataSourceId: 0,
    aggrFunc: AGGREGATE_FUNCTION_SUM,
    queryParameters: [],
    budgetGroupId: 0,
    updatedAt: null,
  }
}
