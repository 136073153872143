
import { computed, defineComponent, PropType, reactive } from 'vue';
import { isRecordIdValid, validRecordIdOrNull } from 'src/util/recordId';
import { BundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import { useScalingOptions } from 'src/composables/options/useScalingOptions';
import { useBundledMetricsAggregateFunctionOptions } from 'src/composables/options/useBundledMetricsAggregateFunctionOptions';
import { useTimeSpanOptions } from 'src/composables/options/useTimeSpanOptions';
import { useWorkplaceOptions } from 'src/composables/options/useWorkplaceOptions';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useValidator } from 'src/composables/useValidator';

interface State {
  metrics: BundledMetrics;
  metricsIsNew: boolean;
  // プロパティとv-modelの接続を調整する
  validWorkplaceIdOrNull: number | null;
}

export default defineComponent({
  components: {
    InputError,
    MetricsInputErrors,
  },
  props: {
    value: {
      type: Object as PropType<BundledMetrics>,
      required: true,
    },
    onAccessGroupSelectClick: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    onSettingsConflictsMetrics: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const state: State = reactive({
      metrics: computed({
        get(): BundledMetrics {
          return props.value;
        },
        set(next: BundledMetrics) {
          emit('input', structuredClone(next));
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      validWorkplaceIdOrNull: computed({
        get() {
          return validRecordIdOrNull(state.metrics.workplaceId);
        },
        set(value) {
          state.metrics.workplaceId = value || 0;
        },
      }),
    });

    const { options: aggregateFunctionOptions } = useBundledMetricsAggregateFunctionOptions();

    const { options: scalingOptions } = useScalingOptions();

    const { options: timeSpanOptions } = useTimeSpanOptions();

    const { options: workplaceOptions } = useWorkplaceOptions();

    const { metricsAccessGroupsOnMetricsWithoutAdmin } = useMetricsAccessGroupsOfMetrics();

    const { validations, setValidation, validationResult } = useValidator();

    setValidation({
      name: { required: true, max: 50 },
      workplaceId: { required: true },
      unit: { required: false, max: 10 },
      decimalPlaces: {
        required: true,
        min_value: 0,
        max_value: 2,
        numeric: true,
      },
    });

    const goToAccessGroupSelectForm = (): void => {
      props.onAccessGroupSelectClick();
    };

    return {
      props,
      state,
      aggregateFunctionOptions,
      goToAccessGroupSelectForm,
      scalingOptions,
      timeSpanOptions,
      workplaceOptions,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      validations,
      validationResult,
    };
  },
});
