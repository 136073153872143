import { render, staticRenderFns } from "./SelectedItems.vue?vue&type=template&id=137f3f24&scoped=true&"
import script from "./SelectedItems.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SelectedItems.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SelectedItems.vue?vue&type=style&index=0&id=137f3f24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137f3f24",
  null
  
)

export default component.exports