import { isActuallyObject } from 'src/util/object';

const toSnakeCase = (string: string): string => {
  return string
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/^_/, '');
};

// 型が不明瞭になる為、内部的な処理では使用を避けて明示的にキー名を記述して変換すること
// スネークケースのキーを要求するWebAPIへのリクエスト時に利用する
export const convertKeysToSnakeCase = (obj: Record<any, unknown>): Record<string, unknown> => {
  const keys = Object.keys(obj);
  const clone = {} as Record<string, unknown>;
  keys.forEach((key) => {
    const target = obj[key];
    if (!isActuallyObject(target) && !Array.isArray(target)) {
      // undefined, null, string, number, boolean, symbol, function, bigint, Date
      clone[toSnakeCase(key)] = target;
    } else if (Array.isArray(target)) {
      // array
      clone[toSnakeCase(key)] = [...target.map((el) => (isActuallyObject(el) ? convertKeysToSnakeCase(el) : el))];
    } else {
      // object
      clone[key] = convertKeysToSnakeCase(target);
    }
  });

  return clone;
};
