
import { defineComponent } from 'vue'
import InputError from 'src/components/InputError.vue'
import { useValidator } from 'src/composables/useValidator'

export default defineComponent({
  components: {
    InputError,
  },
  setup() {
    const {
      setValidation,
      validationResult,
    } = useValidator()

    // setValidationで設定したオブジェクトに含まれるキーに対してエラーチェックを行うが
    // 具体的な設定値には関心を持つ必要が無いのでキーとダミー値(true)をセットしている
    // 暫定的にこの形を取っているが、このコンポーネントを利用しているすべての親コンポーネントでuseValidatorの利用に置き換えた場合
    // 親コンポーネントからvalidationResultを受け取るように変更することができる
    // このコンポーネント自体はバリデーション結果をレンダリングするだけのものなので、validationResultをpropsで
    // 受け取る形式にしても違和感なく、setValidationの変な利用方法もしなくてよくなる
    setValidation({
      name: true,
      workplaceId: true,
      unit: true,
      decimalPlaces: true,
    })

    return {
      validationResult,
    }
  },
})
