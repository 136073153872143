import { ComponentResponseData } from '../componentResponseData'
import { ComponentType, internal as componentInternal } from 'src/models/new/component'
import { buildBaseComponentProperties } from './buildBaseComponentProperties'
import { CommentComponent } from 'src/models/new/Component/commentComponent'

const COMMENT = componentInternal.COMMENT

export const buildCommentComponentProperties = (data: ComponentResponseData): CommentComponent => {
  const componentType: ComponentType = COMMENT

  return {
    ...buildBaseComponentProperties(data, componentType),
    placeholder: data.comment_component!.placeholder,
    value: data.comment_component!.target_comment_component_value?.content || ''
  }
}
