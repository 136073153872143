import Chart, { ChartTooltipItem, ChartTooltipOptions } from 'chart.js'
import { ExtendedChartData } from 'src/util/Chart/shared'

// datasetsに含まれるdatasetが1つだけの場合に使用する
export const tooltipsDefaultOptions: ChartTooltipOptions = {
  // Axis
  // No description in Chart.js documentation
  // axis: undefined,

  // Enabled
  // default: true
  // enabled: true,

  // Custom tooltip
  // default: undefined
  // custom: undefined

  // Mode
  // See: https://www.chartjs.org/docs/2.9.4/general/interactions/modes.html#interaction-modes
  // default: 'nearest'
  mode: 'nearest',

  // Intersect
  // default: true
  // intersect: true,

  // BackgroundColor
  // default: 'rgba(0, 0, 0, 0.8)'
  // backgroundColor: 'rgba(0, 0, 0, 0.8)',

  // Title Align
  // default: 'left'
  titleAlign: 'left',

  // Title Font Family
  // default: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
  // titleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

  // Title Font Size
  // default: 12
  // titleFontSize: 12,

  // Title Font Style
  // default: 'bold'
  // titleFontStyle: 'bold',

  // Title Font Color
  // default: '#fff'
  // titleFontColor: '#ffffff',

  // Title Spacing
  // default: 2
  // titleSpacing: 2,

  // Title Margin Bottom
  // default: 6
  // titleMarginBottom: 6,

  // Body Align
  // default: 'left'
  // bodyAlign: 'left',

  // Body Font Family
  // default: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
  // bodyFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

  // Body Font Size
  // default: 12
  // bodyFontSize: 12,

  // Body Font Style
  // default: 'normal'
  // bodyFontStyle: 'normal',

  // Body Font Color
  // default: '#fff'
  // bodyFontColor: '#ffffff',

  // Body Spacing
  // default: 2
  // bodySpacing: 2,

  // Footer Align
  // default: 'left'
  // footerAlign: 'left',

  // Footer Font Family
  // default: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
  // footerFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

  // Footer Font Size
  // default: 12
  // footerFontSize: 12,

  // Footer Font Style
  // default: 'bold'
  // footerFontStyle: 'bold',

  // Footer Font Color
  // default: '#fff'
  // footerFontColor: '#ffffff',

  // Footer Spacing
  // default: 2
  // footerSpacing: 2,

  // Footer Margin Top
  // default: 6
  // footerMarginTop: 6,

  // X Padding
  // default: 6
  // xPadding: 6,

  // Y Padding
  // default: 6
  // yPadding: 6,

  // Caret Padding
  // default: 2
  // caretPadding: 2,

  // Caret Size
  // default: 5
  // caretSize: 5,

  // Corner Radius
  // default: 6
  // cornerRadius: 6,

  // Multi Key Background
  // default: '#fff'
  // multiKeyBackground: '#ffffff',

  // Callbacks
  // See: https://chartjs.org/docs/2.9.4/configuration/tooltip.html#tooltip-callbacks
  // default: undefined
  callbacks: {
    title: (_tooltipItems: ChartTooltipItem[], _chartData: ExtendedChartData): string => '',
    label: (tooltipItem: ChartTooltipItem, chartData: ExtendedChartData): string => {
      const dataset = chartData.datasets![tooltipItem.datasetIndex!]
      // ExtendedChartDataはプロット用の値の他に実際の値を持つことがある
      // 実際の値が別途存在する場合は、そちらをツールチップ上の表示に使用する
      const data = dataset?.actualData ?? dataset?.data
      const value = data![tooltipItem.index!] ?? null
      const labels = chartData.labels ?? null
      const label = chartData.datasets![tooltipItem.datasetIndex!]?.label ?? (labels ? labels[tooltipItem.index!] ?? null : null)

      const toLocal = (value: number) => value.toLocaleString('ja-JP')
      const makeValueView = (value: number | number[] | Chart.ChartPoint | null): string => {
        if (value === null) {
          return '-'
        } else if (Array.isArray(value)) {
          // このケースは想定されないが、もし配列が渡された場合はカンマ区切りの文字列に変換する
          return value.map(el => toLocal(el)).join(', ')
        } else if (typeof value === 'number') {
          // 通常のパターン、デフォルトでカンマ区切りの数値に変換する
          return toLocal(value)
        } else {
          // Bubble chartの場合
          value as Chart.ChartPoint
          const x = value.x
          const y = value.y
          return `x: ${typeof (x) === 'number' ? toLocal(x) : x}, x: ${typeof (y) === 'number' ? toLocal(y) : y}`
        }
      }

      const valueView = makeValueView(value)

      return label ? `${label}: ${valueView}` : valueView
    },
  },

  // Filter
  // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#filter-callback
  // default: undefined
  // filter: (item: ChartTooltipItem, data: ChartData) => true,

  // Item Sort
  // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#sort-callback
  // default: undefined
  // itemSort: (itemA: ChartTooltipItem, itemB: ChartTooltipItem, data?: ChartData) => 0,

  // Position
  // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#position-modes
  // default: 'average'
  // position: 'average',

  // Caret Padding
  // default: 2
  // caretPadding: 2,

  // Display Colors (color box beside label)
  // default: true
  // displayColors: true,

  // Border Color
  // default: 'rgba(0, 0, 0, 0)'
  // borderColor: 'rgba(0, 0, 0, 0)',

  // Border Width
  // default: 0
  // borderWidth: 0,

  // Text Directions
  // default: undefined
  // rtl: undefined,
  // default: undefined
  // textDirection: undefined,
}
