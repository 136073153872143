
import { type SetupContext, computed, defineComponent, getCurrentInstance, reactive, nextTick } from 'vue';
import { SEARCH_MAX_DATE_RANGE_1_MONTH } from 'src/consts';
import { notifyError1 } from 'src/hooks/notificationHook';
import { addDays, endOfToday, subDays } from 'date-fns';
import {
  differenceInDays,
  startOfToday,
  endOfIsoWeek,
  startOfIsoWeek,
  endOfMonth,
  startOfMonth,
  subWeeks,
  subMonths,
  addWeeks,
  addMonths,
  earliest,
  latest,
} from 'src/util/datetime';
import { DateRange } from 'src/util/Datetime/dateRange';

type Period =
  | 'today'
  | 'this_week'
  | 'this_month'
  | 'yesterday'
  | 'last_week'
  | 'last_month'
  | 'tomorrow'
  | 'next_week'
  | 'next_month';

type PeriodRangeMap = Record<Period, DateRange>;

type PeriodOption = {
  key: Period;
  label: string;
};

type DatePickerOptions = {
  disabledDate?: (date: Date) => boolean;
};

type State = {
  today: Date;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  datePickerOptions: DatePickerOptions;
  periodOptions: PeriodOption[];
  selectedPeriod: Period | null;
};

const buildPeriodRangeMap = (today: Date): PeriodRangeMap => {
  const startOfWeek = startOfIsoWeek(today);
  const endOfWeek = endOfIsoWeek(today);
  return {
    today: { startDate: today, endDate: today },
    this_week: { startDate: startOfWeek, endDate: endOfWeek },
    this_month: { startDate: startOfMonth(today), endDate: endOfMonth(today) },
    yesterday: { startDate: subDays(today, 1), endDate: subDays(today, 1) },
    last_week: { startDate: subWeeks(startOfWeek, 1), endDate: subWeeks(endOfWeek, 1) },
    last_month: { startDate: startOfMonth(subMonths(today, 1)), endDate: endOfMonth(subMonths(today, 1)) },
    tomorrow: { startDate: addDays(today, 1), endDate: addDays(today, 1) },
    next_week: { startDate: addWeeks(startOfWeek, 1), endDate: addWeeks(endOfWeek, 1) },
    next_month: { startDate: startOfMonth(addMonths(today, 1)), endDate: endOfMonth(addMonths(today, 1)) },
  };
};

export default defineComponent({
  props: {
    startDate: {
      type: Date,
      required: false,
    },
    endDate: {
      type: Date,
      required: false,
    },
    displayDateOption: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchDateRangeMax: {
      type: Number,
      required: false,
      default: SEARCH_MAX_DATE_RANGE_1_MONTH,
    },
    excludeFuture: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableOldestDate: {
      type: Date,
      required: false,
    },
  },
  emits: ['on-start-date-change', 'on-end-date-change'],
  setup(props, context: SetupContext) {
    const root = getCurrentInstance()!.proxy;
    // コンポーネントが生成された後で日付が変更されても各種状態は更新しないので注意
    // 利用側で日付をまたいだ時に更新したいのであれば一旦コンポーネントを破棄して再生成すること
    const today = startOfToday();
    const state: State = reactive({
      today: today,
      startDate: computed({
        get: () => props.startDate ?? today,
        set: (date: Date) => {
          context.emit('on-start-date-change', date);
        },
      }),
      endDate: computed({
        get: () => props.endDate ?? today,
        set: (date: Date) => {
          context.emit('on-end-date-change', date);
        },
      }),
      minDate: computed(() => props.availableOldestDate ?? new Date(0, 1, 1)),
      maxDate: computed(() => (props.excludeFuture ? endOfToday() : new Date(9999, 12, 31))),
      datePickerOptions: computed(() => {
        return { disabledDate: (date: Date) => date < state.minDate || date > state.maxDate };
      }),
      periodOptions: computed(() => computePeriodOptions()),
      selectedPeriod: null,
    });

    const periodRangeMap = buildPeriodRangeMap(today);

    const computePeriodOptions = (): PeriodOption[] => {
      const allOptions: PeriodOption[] = [
        { key: 'today', label: '今日' },
        { key: 'this_week', label: '今週' },
        { key: 'this_month', label: '今月' },
        { key: 'yesterday', label: '昨日' },
        { key: 'last_week', label: '先週' },
        { key: 'last_month', label: '先月' },
        { key: 'tomorrow', label: '明日' },
        { key: 'next_week', label: '来週' },
        { key: 'next_month', label: '来月' },
      ];

      return allOptions.filter((periodOption) => {
        const periodRange = periodRangeMap[periodOption.key];
        return (
          !isDateRangeMaxExceeded(periodRange.startDate, periodRange.endDate) &&
          periodRange.startDate <= state.maxDate &&
          periodRange.endDate >= state.minDate
        );
      });
    };

    const isDateRangeMaxExceeded = (startDate: Date, endDate: Date): boolean => {
      return differenceInDays(startDate, endDate) > props.searchDateRangeMax;
    };

    const onStartDateChange = () => {
      state.selectedPeriod = null;

      // 親コンポーネントにEmitしてから自身のstateがv-model経由で更新されるのを待つ
      nextTick(() => {
        if (state.endDate < state.startDate) {
          notifyError1(root, '開始日を終了日よりも前の日に指定してください');
        } else if (isDateRangeMaxExceeded(state.startDate, state.endDate)) {
          state.endDate = addDays(state.startDate, props.searchDateRangeMax);
        }
      });
    };

    const onEndDateChange = () => {
      state.selectedPeriod = null;

      // 親コンポーネントにEmitしてから自身のstateがv-model経由で更新されるのを待つ
      nextTick(() => {
        if (state.endDate < state.startDate) {
          notifyError1(root, '開始日を終了日よりも前の日に指定してください');
        } else if (isDateRangeMaxExceeded(state.startDate, state.endDate)) {
          state.startDate = subDays(state.endDate, props.searchDateRangeMax);
        }
      });
    };

    function onDateOptionChange() {
      if (state.selectedPeriod === null) {
        return;
      }
      const periodRange = periodRangeMap[state.selectedPeriod];
      state.startDate = latest(periodRange.startDate, state.minDate);
      state.endDate = earliest(periodRange.endDate, state.maxDate);
    }

    return {
      props,
      state,
      onStartDateChange,
      onEndDateChange,
      onDateOptionChange,
    };
  },
});
