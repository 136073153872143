
import { Metrics, metricsToTypeImageColor } from 'src/models/new/metrics'
import { PropType, defineComponent } from 'vue'
import { CSSProperties } from 'vue/types/jsx'

export default defineComponent({
  props: {
    metrics: {
      type: Object as PropType<Metrics>,
      required: true,
    },
  },

  setup(props) {
    const name = 'MetricsTypeRoundPoint'

    const styleFromMetrics = (metrics: Metrics): Pick<CSSProperties, 'backgroundColor'> => {
      const color = `#${metricsToTypeImageColor(metrics)}`
      return { backgroundColor: color }
    }

    return { name, props, styleFromMetrics }
  },
})
