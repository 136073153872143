import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import { AccessGroupRole } from 'src/models/new/accessGroup';

export type MetricsAccessGroupCreateOuUpdateRequestParameters = {
  name: string;
  role: AccessGroupRole;
};

export const convertToMetricsAccessGroupCreateOrUpdateResponse = (
  accessGroup: MetricsAccessGroup,
): MetricsAccessGroupCreateOuUpdateRequestParameters => {
  return {
    name: accessGroup.name,
    role: accessGroup.role,
  };
};
