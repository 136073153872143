import { useDeleteConfirmModal } from 'src/composables/useDeleteConfirmModal'
import { ReportLink } from 'src/models/new/Link/reportLink'

export const useReportLinkDeleteConfirmModal = () => {
  const { isDeleteConfirmModalShown, deleteTargetRef, openDeleteConfirmModalWithValue, closeDeleteConfirmModal } = useDeleteConfirmModal<ReportLink>()
  const deleteTargetReportLinkRef = deleteTargetRef

  return {
    isDeleteConfirmModalShown,
    deleteTargetReportLinkRef,
    openDeleteConfirmModalWithValue,
    closeDeleteConfirmModal,
  }
}
