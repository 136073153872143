export namespace UserConfigurableColor {
  export type Color = {
    name: string;
    // ハッシュ記号を除いたカラーコード Ex.白 = ffffff
    code: string;
  };

  const getColors = (group: string): Color[] => {
    const colorSet = window.master.lovs.color_set;
    if (!colorSet) {
      return [];
    }
    const configs = colorSet.vals.filter((e) => e.group === group);
    return configs.map((config) => {
      return { name: config.name || '', code: config.key || '' };
    });
  };

  const DARK = 'dark';
  const LIGHT = 'light';

  export const darkColors = (): Color[] => getColors(DARK);
  export const lightColors = (): Color[] => getColors(LIGHT);
}
