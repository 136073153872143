import { CommentComponent } from 'src/models/new/Component/commentComponent'
import { Report } from 'src/models/new/report'
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format'
import { formatDate } from 'src/util/datetime'

export type ComponentUpdateCommentRequestParameters = {
  id: number
  report_id: number
  dt: string
  content: string | null
}

export const convertToComponentUpdateCommentRequestParameters = (
  report: Report,
  component: CommentComponent,
): ComponentUpdateCommentRequestParameters => {
  if (!report.closingDate) {
    console.error('report should include closingDate')
  }
  return {
    id: component.id,
    report_id: report.id,
    content: component.value,
    dt: report.closingDate ? formatDate(report.closingDate!, SYSTEM_DATE_FORMAT) : '',
  }
}
