import { ComponentResponseData } from '../componentResponseData'
import { Component, ComponentType } from 'src/models/new/component'

export const buildBaseComponentProperties = (data: ComponentResponseData, componentType: ComponentType): Component => {
  return {
    id: data.id,
    sectionId: data.section_id,
    name: data.name,
    componentType: componentType,
    abscissa: data.abscissa,
    ordinate: data.ordinate,
    width: data.width,
    height: data.height,
    includesLinks: data.includes_links,
  }
}
