import { UserViewSetting } from 'src/models/new/userViewSetting'

export type UserViewSettingRequestParams = {
  home_report_id: number | null
}

export const convertToUserViewSettingRequestParameters = (
  userViewSetting: UserViewSetting,
): UserViewSettingRequestParams => {
  return {
    home_report_id: userViewSetting.homeReportId,
  }
}
