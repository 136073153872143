import axios from 'axios'
import { isGuestPage } from 'src/hooks/routeHook'

export function setupAxios(root: Vue, loginHref: string = '/login'): void {
  // token
  const token = root.$store.getters['localStorage/serverToken']
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`
  }

  // go to login if 401 is returned on response
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      let st = 400
      if (error.response) {
        st = error.response.status
      }
      if (st === 401 && !isGuestPage(root.$router.currentRoute.name)) {
        root.$router.push(loginHref)
      }
      return Promise.reject(error)
    }
  )
}
