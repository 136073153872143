import type { LogiCoredataBudgetGroup } from 'src/models/new/logiCoredataBudgetGroup';
import LogiCoredataBudgetGroupApi from 'src/apis/masters/logiCoredataBudgetGroup';
import { generateInjectionKey } from './logics/generateInjectionKey';
import { useAsyncResources, useAsyncResourcesProvider } from './useAsyncResources';

type UseLogiCoredataBudgetGroupProviderArgs = {
  onLoad?: () => unknown;
};
type UseLogiCoredataBudgetGroupProviderResult = {
  logiCoredataBudgetGroupsRef: ReturnType<typeof useAsyncResources<LogiCoredataBudgetGroup>>['resources'];
};

type UseLogiCoredataBudgetGroupResult = {
  logiCoredataBudgetGroupsRef: ReturnType<typeof useAsyncResources<LogiCoredataBudgetGroup>>['resources'];
};

const key = generateInjectionKey('logiCoredataBudgetGroups');

const fetchLogiCoredataBudgetGroups = async () => {
  const result = await LogiCoredataBudgetGroupApi.index();
  return result.sort((a: LogiCoredataBudgetGroup, b: LogiCoredataBudgetGroup) => (a.id > b.id ? 1 : -1));
};

export const useLogiCoredataBudgetGroupsProvider = ({
  onLoad,
}: UseLogiCoredataBudgetGroupProviderArgs = {}): UseLogiCoredataBudgetGroupProviderResult => {
  const { resources: logiCoredataBudgetGroupsRef } = useAsyncResourcesProvider<LogiCoredataBudgetGroup>({
    key,
    fetcher: fetchLogiCoredataBudgetGroups,
    onLoad,
  });

  return {
    logiCoredataBudgetGroupsRef,
  };
};

export const useLogiCoredataBudgetGroups = (): UseLogiCoredataBudgetGroupResult => {
  const { resources: logiCoredataBudgetGroupsRef } = useAsyncResources<LogiCoredataBudgetGroup>({ key });

  return {
    logiCoredataBudgetGroupsRef,
  };
};
