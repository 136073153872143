
import { defineComponent, SetupContext, ref, watch, PropType, Ref, computed } from 'vue';

// SortContext.vueと同様にgenericsを使って型情報を外から渡せるようにする
// https://logaretm.com/blog/generically-typed-vue-components/
class PaginationContainerFactory<T extends Record<string, any>> {
  define() {
    return defineComponent({
      name: 'PaginationContainer',
      props: {
        list: {
          type: Array as PropType<T[]>,
          required: true,
        },
        perPage: {
          type: Number,
          default: 10,
        },
        total: {
          type: Number,
          required: true,
        },
        lazyLoadHandler: {
          type: Function as PropType<() => Promise<void>>,
          required: false,
          default: () => {},
        },
      },
      setup(props, context: SetupContext) {
        const slicedList = ref<T[]>([]) as Ref<T[]>;
        const currentPage = ref<number>(1);
        const isLazyLoading = ref<boolean>(false);
        const isMultiplePages = computed(() => props.total > props.perPage);
        const pageCount = computed(() => Math.ceil(props.total / props.perPage));

        const sliceList = (list: T[], perPage: number, currentPage: number): T[] => {
          const startIdx = perPage * Math.max(currentPage - 1, 0);
          const listSliceIndices = [startIdx, startIdx + perPage];

          return list.slice(...listSliceIndices);
        };

        const changePage = (value: number): void => {
          currentPage.value = value;
          slicedList.value = sliceList(props.list, props.perPage, currentPage.value);

          // 追加ロード
          if (currentPage.value > 1 && slicedList.value.length === 0) {
            isLazyLoading.value = true;
            props.lazyLoadHandler();
          }
        };

        watch(
          () => [props.list],
          ([list]) => {
            if (isLazyLoading.value) {
              // 追加ロードの場合は更新されたリストを元にsliceListを取得する
              isLazyLoading.value = false;
              slicedList.value = sliceList(list, props.perPage, currentPage.value);
            } else {
              // 上記以外(検索実行時、データ追加削除時)はcurrentPage=1で最初のページに戻す
              changePage(1);
            }
          },
          { immediate: true },
        );

        return {
          slicedList,
          currentPage,
          isMultiplePages,
          pageCount,
          changePage,
        };
      },
    });
  }
}

const main = new PaginationContainerFactory().define();

export function usePaginationContainer<T extends Record<string, any>>() {
  return main as ReturnType<PaginationContainerFactory<T>['define']>;
}

export default main;
