import { useFormModal } from 'src/composables/useFormModal'
import { ComponentLink, constructEmptyComponentLink } from 'src/models/new/Link/componentLink'

export const useComponentLinkFormModal = () => {
  const { isFormModalShown, formTargetRef, openFormModalWithValue, closeFormModal } = useFormModal<ComponentLink>()
  const formTargetComponentLinkRef = formTargetRef

  const createNewComponentLink = () => {
    openFormModalWithValue(constructEmptyComponentLink())
  }

  return {
    isFormModalShown,
    createNewComponentLink,
    formTargetComponentLinkRef,
    openFormModalWithValue,
    closeFormModal,
  }
}
