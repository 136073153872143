import axios from 'axios';
import { LogiCoredataWorkplaceIndexRequestParameters } from 'src/models/api/LogiCoredataWorkplace/logiCoredataWorkplaceIndexRequestPrameters';
import {
  LogiCoredataWorkplaceResponseData,
  convertFromLogiCoredataWorkplaceResponseData,
} from 'src/models/api/LogiCoredataWorkplace/logiCoredataWorkplaceResponseData';
import { LogiCoredataWorkplace } from 'src/models/new/logiCoredataWorkplace';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/workplaces';

export default {
  async index(params: LogiCoredataWorkplaceIndexRequestParameters = {}): Promise<LogiCoredataWorkplace[]> {
    const url = buildBackendUrl(buildBasePath());
    const { data } = await axios.get(url, { params: params });
    return (data as LogiCoredataWorkplaceResponseData[]).map(convertFromLogiCoredataWorkplaceResponseData);
  },
};
