
import { defineComponent, onMounted, PropType, reactive, watch } from 'vue'
import { Link } from 'src/models/new/link'
import Draggable from 'vuedraggable'
import LinkListRow from 'src/views/Dashboard/Settings/Reports/shared/components/LinkList/LinkListRow/LinkListRow.vue'

type State = {
  // draggableによって変更を受けるので、props.linkItemsをマウント時に複製する
  linkItems: Link[]
}

export default defineComponent({
  components: {
    Draggable,
    LinkListRow,
  },
  props: {
    linkItems: {
      type: Array as PropType<Link[]>,
      required: true,
    },
    edit: {
      type: Function as PropType<(link: Link) => Promise<void>>,
      required: true,
    },
    del: {
      type: Function as PropType<(link: Link) => Promise<void>>,
      required: true,
    },
    updateOrder: {
      type: Function as PropType<(linkItems: Link[]) => Promise<void>>,
      required: true,
    },
  },
  setup(props) {
    const state: State = reactive({
      linkItems: props.linkItems,
    })

    const setOrderedLinkItems = (linkItems: Link[]) => {
      state.linkItems = [...linkItems].sort((a, b) => a.sequentialOrder - b.sequentialOrder)
    }

    watch(() => props.linkItems, (linkItems) => {
      setOrderedLinkItems(linkItems)
    })

    const onOrderChanged = () => {
      const orderedLinkItems = state.linkItems.map((el, index) => {
        el.sequentialOrder = index + 1
        return el
      })
      props.updateOrder(orderedLinkItems)
    }

    onMounted(() => {
      setOrderedLinkItems(props.linkItems)
    })

    return {
      props,
      state,
      onOrderChanged,
    }
  },
})
