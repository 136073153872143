
import { defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import AppLink from 'src/components/UIComponents/AppLink.vue';
import companyViewSettingApi from 'src/apis/companyViewSetting';
import userViewSettingApi from 'src/apis/userViewSetting';
import ReportDetailPage from 'src/views/Dashboard/ReportValues/Detail/index.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { AxiosError } from 'axios';
import { formatDate } from 'src/util/datetime';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import { provideReportValuesDetailPageContext } from 'src/views/Dashboard/ReportValues/Detail/composables/provideReportValuesDetailPageContext';
import Announcements from 'src/views/Dashboard/Home/Components/Announcements.vue';

type State = {
  isLoaded: boolean;
  showHomeReport: boolean;
  reportId: number | null;
  hasReportReadRole: boolean;
};

export default defineComponent({
  components: {
    Announcements,
    AppLink,
    ReportDetailPage,
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'ホーム');
    const state: State = reactive({
      isLoaded: false,
      showHomeReport: false,
      reportId: null,
      hasReportReadRole: true,
    });

    provideReportValuesDetailPageContext({
      pageName: 'ホーム',
      getReportId: () => state.reportId,
      onFailedToGetReport: (err: AxiosError) => {
        if (err.response?.status === 403) {
          // 403の場合、レポートページをアンマウントして権限無しのメッセージを表示
          state.hasReportReadRole = false;
          state.showHomeReport = false;
        } else if (err.response?.status === 404) {
          // 403の場合、レポートページをアンマウントしてホームレポート設定なしのメッセージを表示
          state.showHomeReport = false;
        }
        // それ以外はアンマウントせず、単にレポート画面がレンダリングされていない状態にする
      },
      onDateChange: (date: Date) => {
        root.$router.push({
          query: {
            dt: formatDate(date, SYSTEM_DATE_FORMAT),
          },
        });
      },
    });

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showHomeReport = false;
      state.hasReportReadRole = true;

      try {
        const userViewSetting = await userViewSettingApi.show();
        if (userViewSetting.homeReportId) {
          state.showHomeReport = true;
          state.reportId = userViewSetting.homeReportId;
          state.isLoaded = true;
          return;
        }

        const companyViewSetting = await companyViewSettingApi.show();
        if (companyViewSetting?.homeReportId) {
          state.showHomeReport = true;
          state.reportId = companyViewSetting.homeReportId;
        }

        state.isLoaded = true;
      } catch (e) {
        // 基本的に設定が無いことはあり得ないが、レコードがない場合の対策
        // 何らかの理由でレスポンスが得られなかった場合も同様
        // このケースではisLoadedをtrueにせず、画面に何も表示されないようにする
      }
    });

    return {
      state,
    };
  },
});
