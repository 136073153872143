import { useEnumOptions } from 'src/composables/useEnumOptions'
import { SCALING_LABELS, Scaling } from 'src/business/scaling'

export const useScalingOptions = () => {
  const {
    options,
    filterOptions,
  } = useEnumOptions<Scaling>(SCALING_LABELS)

  return {
    options,
    filterOptions,
  }
}
