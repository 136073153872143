
import { Metrics, metricsToTypeImageColor, metricsToTypeLocalWord } from 'src/models/new/metrics'
import { PropType, defineComponent } from 'vue'
import { CSSProperties } from 'vue/types/jsx'

export default defineComponent({
  props: {
    metrics: {
      type: Object as PropType<Metrics>,
      required: true,
    },
  },

  setup(props) {
    const name = 'MetricsBadge'

    const styleFromMetrics = (metrics: Metrics): Pick<CSSProperties, 'backgroundColor' | 'borderColor'> => {
      const color = `#${metricsToTypeImageColor(metrics)}`
      return { backgroundColor: color, borderColor: color }
    }

    return { name, props, styleFromMetrics, metricsToTypeLocalWord }
  },
})
