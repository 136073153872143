import { render, staticRenderFns } from "./UpdateMetricsValueModal.vue?vue&type=template&id=17a0c851&scoped=true&"
import script from "./UpdateMetricsValueModal.vue?vue&type=script&lang=ts&"
export * from "./UpdateMetricsValueModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a0c851",
  null
  
)

export default component.exports