export const GT = 'gt';
export const GTE = 'gte';
export const LT = 'lt';
export const LTE = 'lte';
export const EQ = 'eq';
export const IS_NULL = 'is_null';

export type Comparator = typeof GT | typeof GTE | typeof LT | typeof LTE | typeof EQ | typeof IS_NULL;

export const compareWithComparator = (
  a: number | null | undefined,
  comparator: Comparator,
  b: number | null | undefined,
): boolean => {
  const isNullOrUndefined = (value: any): value is null | undefined => {
    return value === null || value === undefined;
  };

  switch (comparator) {
    case GT:
      return !isNullOrUndefined(a) && !isNullOrUndefined(b) && a > b;
    case GTE:
      return !isNullOrUndefined(a) && !isNullOrUndefined(b) && a >= b;
    case LT:
      return !isNullOrUndefined(a) && !isNullOrUndefined(b) && a < b;
    case LTE:
      return !isNullOrUndefined(a) && !isNullOrUndefined(b) && a <= b;
    case EQ:
      return !isNullOrUndefined(a) && !isNullOrUndefined(b) && a === b;
    case IS_NULL:
      if (!isNullOrUndefined(b)) {
        console.debug(`The latter value will not used when comparator is "${comparator}"`);
      }
      return isNullOrUndefined(a);
    default:
      console.error(`Given comparator "${comparator}" is not valid.`);
      return false;
  }
};

export const COMPARATOR_LABELS: Record<Comparator, string> = {
  [GT]: '>',
  [GTE]: '>=',
  [LT]: '<',
  [LTE]: '<=',
  [EQ]: '=',
  [IS_NULL]: '値なし',
};

export const labelFromComparator = (comparator: Comparator): string => {
  return COMPARATOR_LABELS[comparator];
};

export const isComparatorIsNull = (comparator: Comparator): comparator is typeof IS_NULL => {
  return comparator === IS_NULL;
};
