import axios from 'axios';
import { User } from 'src/models/user';
import { buildBackendUrl } from 'src/apis/util/url';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (): string => '/masters/users';

// TODO: 型定義をmodels/api名前空間の別ファイルに分離する

type accessGroupParams = {
  metrics_access_groups: { id: number }[];
  report_access_groups: { id: number }[];
};

export default {
  async index(): Promise<{ result: User[] }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<MultiDataResponse<User>>(url);
    return resData;
  },

  async update(id: number, accessGroupParams: accessGroupParams): Promise<User[]> {
    const url = buildBackendUrl(buildBasePath(), id);
    const { data: resData } = await axios.put(url, accessGroupParams);
    return resData;
  },
};
