
import { defineComponent, onMounted, ref } from 'vue';
import { useAnnouncementArticles } from 'src/views/Dashboard/Home/Components/composables/useAnnouncementArticles';

export default defineComponent({
  setup() {
    const isReady = ref(false);
    const { announcementArticles, fetchAnnouncementArticles } = useAnnouncementArticles();

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      isReady.value = false;
      await fetchAnnouncementArticles();
      isReady.value = true;
    });

    return {
      announcementArticles,
      isReady,
    };
  },
});
