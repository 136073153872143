import { useReports } from 'src/composables/asyncResources/useReports';
import { Link } from 'src/models/new/link';
import { customUrlToReportId, isUrlCustomReportUrl } from 'src/business/customUrl';

// FIXME: useReportsはデフォルトで最初の1000件しか取得しないので、該当のレポートが取得できない場合がある
// 呼び出し側で繰り返しロードするのか、このモジュールの中で取得するのか検討して必要なデータを取得する
const urlToReportName = (url: string): string | null => {
  const reportId = customUrlToReportId(url);
  if (!reportId) {
    return null;
  }

  const { reportsRef } = useReports();
  const report = reportsRef.value.find((report) => report.id === reportId);
  return report?.name ?? null;
};

export const useLinkViewBuilder = () => {
  const linkViewBuilder = (link: Link): string => {
    if (isUrlCustomReportUrl(link.url)) {
      const reportName = urlToReportName(link.url);
      return reportName ? `レポート: ${reportName}` : '';
    } else {
      return link.url;
    }
  };

  return {
    linkViewBuilder,
  };
};
