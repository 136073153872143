import { LegacyMetricsResponse, convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData'
import { TextDecorationFontWeight } from 'src/models/new/ConditionalStatement/Decorations/textDecoration'
import {
  CONDITIONAL_STATEMENT_COMPARABLE_SELF,
  CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE,
  ConditionalStatementWithContainerPosition,
  Decoration,
} from 'src/models/new/conditionalStatement'
import { Metrics } from 'src/models/new/metrics'
import { Comparator } from 'src/util/comparator'
import { Icon } from 'src/util/icon'

type DecorationResponseData = {
  version: number
  target: 'icon' | 'text' | 'background' | 'graph'
  settings: {
    color : string
    font_weight?: TextDecorationFontWeight
    icon?: Icon
  }
}

// マスタ用のレスポンスであるため、metricsListは存在しない
// コンポーネントの閲覧時にAPIを作成する場合は別途型定義すること
export type ConditionalStatementResponseData = {
  id: number
  primary_position_id: number
  secondary_position_id: number
  base_type: 'main_metrics' | 'sub_metrics' | 'other_metrics' | 'const'
  base_metrics: LegacyMetricsResponse | null
  base_const: number | null
  comparator: Comparator
  threshold_type: 'sub_metrics' | 'other_metrics' | 'const' | null
  threshold_metrics: LegacyMetricsResponse | null
  threshold_const: number | null
  decoration: DecorationResponseData
  priority: number,
}

const computeConditionBase = (
  data: ConditionalStatementResponseData
): number | Metrics | typeof CONDITIONAL_STATEMENT_COMPARABLE_SELF | typeof CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE => {
  switch (data.base_type) {
    case 'main_metrics':
      return CONDITIONAL_STATEMENT_COMPARABLE_SELF
    case 'sub_metrics':
      return CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
    case 'other_metrics': {
      // base_metricsはbaseのタイプがother_metricsであってもキーが存在しないことがありうる
      // キーが存在しないのは例えば指定していたメトリクスが削除されたような場合である
      // そのような場合は定数0がセットされていたものとして扱う
      // キーが存在するが値が空の状態は想定していないが、その場合も定数0がセットされていたものとして扱う
      return data.base_metrics ? convertFromMetricsResponseData(data.base_metrics, {}) : 0
    }
    case 'const':
      return Number(data.base_const!)
    default:
      throw Error(`No such base type: ${data.base_type}`)
  }
}

const computeConditionThreshold = (
  data: ConditionalStatementResponseData
): number | Metrics | typeof CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE | null => {
  if (data.comparator === 'is_null') return null

  switch (data.threshold_type) {
    case 'sub_metrics':
      return CONDITIONAL_STATEMENT_COMPARABLE_REFERENCE
    case 'other_metrics': {
      // threshold_metricsはthresholdのタイプがother_metricsであってもキーが存在しないことがありうる
      // キーが存在しないのは例えば指定していたメトリクスが削除されたような場合である
      // そのような場合は定数0がセットされていたものとして扱う
      // キーが存在するが値が空の状態は想定していないが、その場合も定数0がセットされていたものとして扱う
      return data.threshold_metrics ? convertFromMetricsResponseData(data.threshold_metrics, {}) : 0
    }
    case 'const':
      return Number(data.threshold_const!)
    default:
      throw Error(`No such threshold type: ${data.threshold_type}`)
  }
}

// 実際のところcolor等は必ず値が返ってくると想定してよいが
// データが破損して入っていなかった場合を考慮してフォールバック値を設定している
const convertDecorationData = (data: DecorationResponseData): Decoration => {
  switch (data.target) {
    case 'text':
      return {
        target: data.target,
        settings: {
          color: data.settings.color ?? '3b3838',
          fontWeight: data.settings.font_weight ?? 'normal',
        }
      }
    case 'background':
      return {
        target: data.target,
        settings: {
          color: data.settings.color ?? '3b3838',
        }
      }
    case 'icon':
      return {
        target: data.target,
        settings: {
          color: data.settings.color ?? '3b3838',
          icon: data.settings.icon ?? 'fa-sun',
        }
      }
    case 'graph':
      return {
        target: data.target,
        settings: {
          color: data.settings.color ?? '3b3838',
        }
      }
    default:
      throw Error(`No such decoration target: ${data.target}`)
  }
}

export const convertFromConditionalStatementResponseData = (
  data: ConditionalStatementResponseData
): ConditionalStatementWithContainerPosition<any> => {
  return {
    id: data.id,
    primaryPositionId: data.primary_position_id,
    secondaryPositionId: data.secondary_position_id,
    base: computeConditionBase(data),
    threshold: computeConditionThreshold(data),
    comparator: data.comparator,
    decoration: convertDecorationData(data.decoration),
    priority: data.priority,
    metricsList: null,
  }
}
