import { AnnotationConfig } from 'chartjs-plugin-annotation'
import { CHART_BASE_COLOR } from './shared'

// デフォルトは最初の1本目に関してのみ適用されるので、複数本の線を表示したい場合はデフォルトを参照して独自に設定すること

// See: https://www.chartjs.org/chartjs-plugin-annotation/0.5.7/
export const verticalLineAnnotationPluginDefaultOptions: AnnotationConfig = {
  annotations: [
    {
      type: 'line',
      mode: 'vertical',
      // In default, the axis for vertical line is x-axis-1 and for horizontal is y-axis-1
      scaleID: 'x-axis-1',
      // Set to -Infinity as dummy data.
      value: -Infinity,
      label: {
        backgroundColor: CHART_BASE_COLOR,
        fontColor: '#ffffff',
      },
    },
  ],
}

export const horizontalLineAnnotationPluginDefaultOptions: AnnotationConfig = {
  annotations: [
    {
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-1',
      value: -Infinity,
      label: {
        backgroundColor: CHART_BASE_COLOR,
        fontColor: '#ffffff',
      },
    },
  ],
}
