
import { setPageName } from 'src/hooks/displayPageNameHook';
import metricsAccessGroupApi from 'src/apis/masters/metricsAccessGroup';
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { vvGetError, vvGetErrorObject, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import { requestTimeout } from 'src/util/request_animation_frame';
import { ERROR_REASON_IN_USE } from 'src/consts';
import { MetricsAccessGroup, constructEmptyMetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import {
  AccessGroupRole,
  isAccessGroupAdmin,
  ACCESS_GROUP_ROLE_NAMES,
  USER_SELECTABLE_ACCESS_GROUP_ROLES,
  roleNameFromAccessGroup,
} from 'src/models/new/accessGroup';
import { getTemporaryRecordId, isRecordIdValid } from 'src/util/recordId';
import InputError from 'src/components/InputError.vue';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';

interface State {
  pageName: string | null;
  userId: number | null;
  showSaveModal: boolean;
  showDeleteModal: boolean;

  hasError: boolean;
  validations: Record<string, object>;

  metricsAccessGroups: MetricsAccessGroup[];

  selectedMetricsAccessGroup: MetricsAccessGroup | null;
  selectedMetricsAccessGroupIsNew: boolean;
  roleOptions: { value: AccessGroupRole; label: string }[];

  hasPageAccessRole: boolean;
  isSaving: boolean;
}

const setupState = (root: Vue): State => {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    metricsAccessGroups: [],
    showSaveModal: false,
    selectedMetricsAccessGroup: null,
    selectedMetricsAccessGroupIsNew: computed(() => {
      return !!state.selectedMetricsAccessGroup && !isRecordIdValid(state.selectedMetricsAccessGroup.id);
    }),
    roleOptions: computed(() => {
      return Object.keys(ACCESS_GROUP_ROLE_NAMES)
        .filter((el) => USER_SELECTABLE_ACCESS_GROUP_ROLES.includes(el as AccessGroupRole))
        .map((el) => ({ value: el as AccessGroupRole, label: ACCESS_GROUP_ROLE_NAMES[el as AccessGroupRole] }));
    }),
    validations: {
      name: { required: true, max: 20 },
      role: { required: true },
    },
    hasError: computed(() => vvHasError(root)),
    showDeleteModal: false,
    hasPageAccessRole: true,
    isSaving: false,
  });

  return state;
};

const ITEM_PER_PAGE = 50;

export default defineComponent({
  components: {
    InputError,
    PaginationContainer: usePaginationContainer<MetricsAccessGroup>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'メトリクスグループ');
    const state = setupState(root);

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);
    const clearErrors = (): void => {
      vvReset(root);
    };

    async function getList(): Promise<void> {
      try {
        const response = await metricsAccessGroupApi.index();
        state.metricsAccessGroups = response.result;
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    const createNewMetricsAccessGroup = (): void => {
      const metricsAccessGroup = constructEmptyMetricsAccessGroup();
      metricsAccessGroup.id = getTemporaryRecordId();
      openSaveModal(metricsAccessGroup);
    };

    const openSaveModal = (metricsAccessGroup: MetricsAccessGroup): void => {
      clearErrors();
      state.selectedMetricsAccessGroup = reactive(structuredClone(metricsAccessGroup));
      state.showSaveModal = true;
    };
    const closeSaveModal = (): void => {
      clearErrors();
      state.showSaveModal = false;
    };

    async function saveItem(): Promise<void> {
      // 二重処理防止
      if (state.isSaving) {
        return;
      }
      state.isSaving = true;
      requestTimeout(() => {
        state.isSaving = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      try {
        if (state.selectedMetricsAccessGroupIsNew) {
          // create処理
          await metricsAccessGroupApi.create(state.selectedMetricsAccessGroup!);
          closeSaveModal();
          notifySuccess1(root, 'メトリクスグループを作成しました');
        } else {
          // update処理
          await metricsAccessGroupApi.update(state.selectedMetricsAccessGroup!.id, state.selectedMetricsAccessGroup!);
          closeSaveModal();
          notifySuccess1(root, 'メトリクスグループを更新しました');
        }
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
        } else {
          const msg = '保存に失敗しました。管理者に連絡してください。';
          notifyError1(root, msg, { err });
        }
      } finally {
        await getList();
      }
    }

    const openDeleteModal = (metricsAccessGroup: MetricsAccessGroup): void => {
      state.selectedMetricsAccessGroup = metricsAccessGroup;
      state.showDeleteModal = true;
    };

    const closeDeleteModal = (): void => {
      state.showDeleteModal = false;
    };

    const deleteItem = async (): Promise<void> => {
      try {
        await metricsAccessGroupApi.destroy(state.selectedMetricsAccessGroup!.id);
        await getList();
        closeDeleteModal();
        // TODO: 要検討、どのメトリクスグループを削除したかも表示した方が良い
        notifySuccess1(root, 'メトリクスグループを削除しました');
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_IN_USE) {
          const msg = 'すでに使われているメトリクスグループです。削除できません。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            'メトリクスグループの削除に失敗しました。管理者に連絡してください。' +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    onMounted(async () => {
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);
      await getList();
    });

    return {
      ITEM_PER_PAGE,
      state,
      getError,
      getErrorObject,
      createNewMetricsAccessGroup,
      openSaveModal,
      closeSaveModal,
      isAccessGroupAdmin,
      roleNameFromAccessGroup,
      saveItem,
      deleteItem,
      openDeleteModal,
      closeDeleteModal,
    };
  },
});
