
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
import { isRecordIdMeansNotPersisted } from 'src/util/recordId'
import LinkFormTabBar from 'src/views/Dashboard/Settings/Reports/shared/components/LinkFormModal/LinkFormTabBar.vue'
import LinkWithReportForm from 'src/views/Dashboard/Settings/Reports/shared/components/LinkFormModal/LinkWithReportForm.vue'
import LinkWithUrlForm from 'src/views/Dashboard/Settings/Reports/shared/components/LinkFormModal/LinkWithUrlForm.vue'
import { Link } from 'src/models/new/link'
import { useLinkType } from './composables/useLinkType'
import { useValidator } from 'src/composables/useValidator'

type State = {
  isReady: boolean
  link: Link
}

export default defineComponent({
  components: {
    LinkFormTabBar,
    LinkWithReportForm,
    LinkWithUrlForm,
  },
  props: {
    value: {
      type: Object as PropType<Link>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    create: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    update: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },
  emits: ['input', 'close'],
  setup(props, { emit }) {
    const {
      linkType,
      initializeLinkType,
    } = useLinkType()

    const {
      validationResult,
      ensureValidation,
      resetValidationResult,
    } = useValidator()

    const close = () => emit('close')
    const register = async(): Promise<void> => {
      if (!(await ensureValidation())) return
      resetValidationResult()

      isRecordIdMeansNotPersisted(state.link.id) ? props.create() : props.update()
    }

    const state: State = reactive({
      isReady: false,
      link: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', { ...value })
        },
      }),
      selectedLinkType: 'report',
    })

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false

      initializeLinkType(props.value)

      state.isReady = true
    })

    return {
      props,
      state,
      linkType,
      validationResult,
      close,
      register,
    }
  },
})
