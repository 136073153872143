import axios from 'axios';
import { ConversionProgramStatus } from 'src/models/conversionProgramStatus';
import { StringOrNumber } from 'src/models/common';
import { buildBackendUrl } from 'src/apis/util/url';
import {
  ConversionProgramStatusResponseData,
  convertConversionProgramStatusResponseData,
} from 'src/models/api/ConversionProgramStatus/conversionProgramStatusResponseData';

// FIXME: stringは本来必要なく、numberを要求すべき
const buildBasePath = (conversionProgramId: StringOrNumber): string => {
  return `/conversion_programs/${conversionProgramId}/conversion_program_statuses`;
};

export default {
  async index(conversionProgramId: StringOrNumber): Promise<ConversionProgramStatus[]> {
    const url = buildBackendUrl(buildBasePath(conversionProgramId));
    const { data: resData } = await axios.get<ConversionProgramStatusResponseData[]>(url);
    return resData.map(convertConversionProgramStatusResponseData);
  },
  // 今のところファイルアップロード以外でレコードが作られるパターンがないので、
  // createとしている
  async create(conversionProgramId: StringOrNumber, data: FormData): Promise<Record<string, string>> {
    const url = buildBackendUrl(buildBasePath(conversionProgramId));
    const headers = { 'content-type': 'multipart/form-data' };
    const { data: resData } = await axios.post(url, data, { headers });
    return resData;
  },
};
