
import { defineComponent, onMounted, reactive } from 'vue';
import ReportLinkList from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkList/ReportLinkList.vue';
import ReportLinkDeleteConfirmModal from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkDeleteConfirmModal/ReportLinkDeleteConfirmModal.vue';
import ReportLinkFormModal from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkFormModal/ReportLinkFormModal.vue';
import { useReportsProvider } from 'src/composables/asyncResources/useReports';
import AppLink from 'src/components/UIComponents/AppLink.vue';
import { useReportLinkFormModal } from 'src/views/Dashboard/Settings/Reports/Links/composables/useReportLinkFormModal';
import { useReportLinkDeleteConfirmModal } from 'src/views/Dashboard/Settings/Reports/Links/composables/useReportLinkDeleteConfirmModal';
import { useUrlPathParameters } from 'src/composables/useUrlPathParameters';
import { useReportLinks } from './composables/useReportLinks';
import { usePageName } from 'src/composables/usePageName';
import { useReport } from 'src/views/Dashboard/Settings/Reports/composables/useReport';

const urlPathParametersTemplate = {
  reportId: 0,
};

type State = {
  isLoaded: boolean;
};

export default defineComponent({
  components: {
    AppLink,
    ReportLinkList,
    ReportLinkFormModal,
    ReportLinkDeleteConfirmModal,
  },
  setup() {
    const { setPageName } = usePageName();
    setPageName('レポート設定');

    useReportsProvider();

    const { urlPathParameters } = useUrlPathParameters(urlPathParametersTemplate);

    const { reportRef, loadReport } = useReport();

    const { reportLinksRef, loadReportLinks, reportLinksState } = useReportLinks();

    const { isFormModalShown, createNewReportLink, formTargetReportLinkRef, openFormModalWithValue, closeFormModal } =
      useReportLinkFormModal();

    const editSelectedReportLink = openFormModalWithValue;

    const onUpdated = () => {
      loadReportLinks();
      closeFormModal();
    };

    const {
      isDeleteConfirmModalShown,
      deleteTargetReportLinkRef,
      openDeleteConfirmModalWithValue,
      closeDeleteConfirmModal,
    } = useReportLinkDeleteConfirmModal();

    const deleteSelectedReportLink = openDeleteConfirmModalWithValue;

    const onDeleted = () => {
      loadReportLinks();
      closeDeleteConfirmModal();
    };

    const onOrderUpdated = () => {
      loadReportLinks();
    };

    const onFailed = () => {
      loadReportLinks();
    };

    const state: State = reactive({
      isLoaded: false,
    });

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;

      closeFormModal();
      closeDeleteConfirmModal();

      await Promise.all([loadReport(), loadReportLinks()]);

      state.isLoaded = true;
    });

    return {
      state,
      urlPathParameters,
      reportLinksRef,
      reportLinksState,
      reportRef,
      onOrderUpdated,
      createNewReportLink,
      isFormModalShown,
      formTargetReportLinkRef,
      editSelectedReportLink,
      closeFormModal,
      isDeleteConfirmModalShown,
      deleteTargetReportLinkRef,
      deleteSelectedReportLink,
      closeDeleteConfirmModal,
      onUpdated,
      onDeleted,
      onFailed,
    };
  },
});
