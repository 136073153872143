import { UnwrapRef, ref } from 'vue'

export const useDeleteConfirmModal = <T>() => {
  const isDeleteConfirmModalShown = ref<boolean>(false)
  const deleteTargetRef = ref<T | null>(null)

  const openDeleteConfirmModalWithValue = (value: T) => {
    // UnwrapRef<T>は結局Tと同じだが、ref.valueが代入を受け付ける型がUnwrapRef<T>に限定されている
    deleteTargetRef.value = { ...value } as UnwrapRef<T>
    isDeleteConfirmModalShown.value = true
  }

  const closeDeleteConfirmModal = () => {
    isDeleteConfirmModalShown.value = false
    deleteTargetRef.value = null
  }

  return {
    deleteTargetRef,
    isDeleteConfirmModalShown,
    openDeleteConfirmModalWithValue,
    closeDeleteConfirmModal,
  }
}
