import { UnwrapRef, ref } from 'vue'

export const useFormModal = <T>() => {
  const isFormModalShown = ref<boolean>(false)
  const formTargetRef = ref<T | null>(null)

  const openFormModalWithValue = (value: T) => {
    // UnwrapRef<T>は結局Tと同じだが、ref.valueが代入を受け付ける型がUnwrapRef<T>に限定されている
    formTargetRef.value = { ...value } as UnwrapRef<T>
    isFormModalShown.value = true
  }

  const closeFormModal = () => {
    isFormModalShown.value = false
    formTargetRef.value = null
  }

  return {
    isFormModalShown,
    formTargetRef,
    openFormModalWithValue,
    closeFormModal,
  }
}
