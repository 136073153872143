import { GetterTree, MutationTree } from 'vuex'

type State = {
  object: Record<string, object>
}

// the definition of initial state is required.
const state: State = {
  object: {},
}

type GetByKey = {
  byKey: (state: State) => (key: string) => object
}
const getters: GetterTree<State, any> & GetByKey = {
  byKey: state => (key) => ({ ...state.object[key] }),
}

const SET_BY_KEY = 'setByKey'
const DELETE_BY_KEY = 'deleteByKey'
const CLEAR = 'clear'

type SetByKeyValue = {
  key: string
  value: object
}
type SetByKey = {
  [SET_BY_KEY]: (state: State, keyValueObject: SetByKeyValue) => void
}
type DeleteByKey = {
  [DELETE_BY_KEY]: (state: State, key: string) => void
}
type Clear = {
  [CLEAR]: (state: State) => void
}
const mutations: MutationTree<State> & SetByKey & DeleteByKey & Clear = {
  [SET_BY_KEY]: (state, keyValueObject) => {
    state.object = { ...Object.assign(state.object, { [keyValueObject.key]: keyValueObject.value }) }
  },
  [DELETE_BY_KEY]: (state, key) => {
    delete state.object[key]
  },
  [CLEAR]: (state) => {
    state.object = {}
  }
}

export const getStoredParameterStoreSettings = () => {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
  }
}
