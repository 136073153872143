
import { PropType, defineComponent } from 'vue';
import GeneralLink from 'src/components/UIComponents/GeneralLink.vue';
import TooltipCell from './TooltipCell.vue';
import type { TooltipMenuSettings } from '../types';

export default defineComponent({
  components: {
    GeneralLink,
    TooltipCell,
  },
  props: {
    menus: {
      type: Array as PropType<TooltipMenuSettings[]>,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
