import axios from 'axios';
import { CompanyViewSetting, convertFromCompanyViewSettingResponseData } from 'src/models/new/companyViewSetting';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/company_view_settings';

export default {
  async show(): Promise<CompanyViewSetting> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return convertFromCompanyViewSettingResponseData(resData);
  },
};
