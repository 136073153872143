const METRICS = 'metrics';
const REPORT = 'report';

export type AccessGroupType = typeof METRICS | typeof REPORT;

const ADMIN = 'admin';
const MANAGER = 'manager';
const READ_WRITE = 'read_write';
const READ_ONLY = 'read_only';

export type AccessGroupRole = typeof ADMIN | typeof MANAGER | typeof READ_WRITE | typeof READ_ONLY;
export const USER_SELECTABLE_ACCESS_GROUP_ROLES: AccessGroupRole[] = [MANAGER, READ_WRITE, READ_ONLY];
export const GRANTABLE_ACCESS_GROUP_ROLES: AccessGroupRole[] = [READ_WRITE, READ_ONLY];

/**
 * SearchId is readonly string with uuid format.
 */
export type AccessGroupSearchId = string;

export const isAccessGroupSearchIdValid = (searchId: string): searchId is AccessGroupSearchId => {
  return /[-0-9a-f]{36}/.test(searchId);
};

// 通常はAccessGroup型を利用するが、例外的にログインユーザーに付随する情報はAccessGroupCoreParameters型を利用する
// ログインユーザーのデータ取得が頻繁に発生する為、最小限の型を定義してバックエンド側で取得するデータを最小限にしている
export type AccessGroupCoreParameters = {
  id: number;
  role: AccessGroupRole;
};

export type GrantedToAccessGroup = {
  id: number;
  name: string;
};

export type AccessGroup = AccessGroupCoreParameters & {
  name: string;
  type: AccessGroupType;
  searchId: AccessGroupSearchId;
};

// AccessGroupはAccessGroupCoreParametersを包含するのでAccessGroupCoreParametersのみの指定と変わらないが
// 基本的にはAccessGroup型で利用する為、理解のしやすさを考慮してAccessGroup型を併記している
export const isAccessGroupAdmin = (accessGroup: AccessGroup | AccessGroupCoreParameters): boolean => {
  return accessGroup.role === ADMIN;
};
export const isAccessGroupManager = (accessGroup: AccessGroup | AccessGroupCoreParameters): boolean => {
  return accessGroup.role === MANAGER;
};
export const isAccessGroupReadWrite = (accessGroup: AccessGroup | AccessGroupCoreParameters): boolean => {
  return accessGroup.role === READ_WRITE;
};
export const isAccessGroupReadOnly = (accessGroup: AccessGroup | AccessGroupCoreParameters): boolean => {
  return accessGroup.role === READ_ONLY;
};
export const ACCESS_GROUP_ROLE_NAMES: Record<AccessGroupRole, string> = {
  [ADMIN]: '管理者',
  [MANAGER]: '設定',
  [READ_WRITE]: '書き込み',
  [READ_ONLY]: '閲覧',
};

export function roleNameFromAccessGroup(accessGroup: AccessGroup): string {
  return ACCESS_GROUP_ROLE_NAMES[accessGroup.role];
}

export const internal = {
  METRICS,
  REPORT,
  ADMIN,
  MANAGER,
  READ_WRITE,
  READ_ONLY,
} as const;
