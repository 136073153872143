import { ComputedRef, computed, getCurrentInstance, ref } from 'vue';

/**
 * useUrlPathParameters
 * Usage:
 *
 * Declare template of url parameters you want to use in your component.
 *    const urlPathParametersTemplate = {
 *      reportId: 0,
 *    }
 *
 * Hand template into composable hook.
 *    const { urlPathParameters } = useUrlPathParameters(urlPathParametersTemplate)
 *
 * urlPathParameters is well typed and reactive.
 */

type UrlPathParamsTemplate = Record<string, string | number>;

export const useUrlPathParameters = <T extends UrlPathParamsTemplate>(template: T) => {
  const vue = getCurrentInstance()!.proxy;
  const urlPathParameters: ComputedRef<T> = computed(() => {
    const declaration = template;
    const params = Object.keys(declaration) as Array<keyof T>;
    return Object.fromEntries(
      params.map((param) => {
        const rawValue = vue.$route.params[param as string];
        const value = typeof declaration[param] === 'number' ? Number(rawValue) : rawValue;
        return [param, value];
      }),
    ) as T;
  });

  return {
    urlPathParameters,
  };
};
