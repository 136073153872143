
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const name = 'AccessDeniedMessageCard'

    return { name }
  },
})
