import { type Ref, ref } from 'vue';
import type { Component } from 'src/models/new/component';
import {
  generateCsvFromMetricsTableComponent,
  isComponentTypeMetricsTable,
  MetricsTableComponent,
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import {
  generateCsvFromMetricsListComponent,
  isComponentTypeMetricsList,
  MetricsListComponent,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import type { Report } from 'src/models/new/report';
import type { Section } from 'src/models/new/section';
import type { CsvRow } from 'src/components/UIComponents/Buttons/CsvDownload/types';
import { formatDate } from 'src/util/datetime';

type UseCsvDownloadResult = {
  csvData: Ref<CsvRow[]>;
  csvFileName: Ref<string>;
  setupCsvData: (component: MetricsTableComponent | MetricsListComponent) => void;
  setCsvFileName: (args: { component: Component; section: Section; report: Report }) => void;
};

export const useCsvDownload = (): UseCsvDownloadResult => {
  const csvData: Ref<CsvRow[]> = ref([[]]);
  const csvFileName = ref('');

  const setupCsvData = (component: MetricsTableComponent | MetricsListComponent): void => {
    if (isComponentTypeMetricsTable(component)) {
      csvData.value = generateCsvFromMetricsTableComponent(component);
    } else if (isComponentTypeMetricsList(component)) {
      csvData.value = generateCsvFromMetricsListComponent(component);
    } else {
      throw Error('component type is incorrect.');
    }
  };

  const setCsvFileName = ({
    component,
    section,
    report,
  }: {
    component: Component;
    section: Section;
    report: Report;
  }): void => {
    const timeStamp = formatDate(new Date(), 'yyyyMMddHHmmss');
    csvFileName.value = `${report.name}_${section.name ?? ''}_${component.name}_${timeStamp}.csv`;
  };

  return {
    csvData,
    csvFileName,
    setupCsvData,
    setCsvFileName,
  };
};
