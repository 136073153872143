
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook'
import { Report } from 'src/models/new/report'
import reportApi from 'src/apis/masters/report'
import { Component } from 'src/models/new/component'
import ComponentElementSearchPanel, { ComponentElementSearchParams } from 'src/components/ComponentElementSearchPanel/index.vue'
import {
  UpdateWordTarget,
  UpdateWordTargetCategory,
  UpdateWordTargetCategoryIdentifier,
  convertUpdateTargetsToDeepUpdateWordsRequestParameters,
  isSameUpdateWordTargetCategoryIdentifiers,
  updateWordTargetsFromComponent,
} from 'src/views/Dashboard/Settings/Reports/ReplaceWords/logics/UpdateWordTarget'
import UpdateWordTargetListRow, { UpdateWordTargetListRowViewModel } from 'src/views/Dashboard/Settings/Reports/ReplaceWords/components/UpdateWordTargetListRow.vue'
import ReplaceWordModal from 'src/views/Dashboard/Settings/Reports/ReplaceWords/components/ReplaceWordModal.vue'
import AppLink from 'src/components/UIComponents/AppLink.vue'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER, ErrorGroup } from 'src/consts'

type CheckboxStatus = {
  componentId: number
  category: UpdateWordTargetCategory
  positionIdentifier: UpdateWordTargetCategoryIdentifier
  isChecked: boolean
}

interface State {
  userId: number
  pageName: string
  isLoaded: boolean
  hasReportGtManagerRole: boolean

  urlParams: { reportId: number }

  report: Report | null

  // 本当はreport.componentsにしたいが、他のコミットとの兼ね合いでReportモデルがまだ変更しにくいので一旦分けて記述する
  components: (Component & { sectionId: number })[]
  updateTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[]

  isMasterCheckboxChecked: boolean
  checkboxStatuses: CheckboxStatus[]
  updateTargetListRowViewModels: UpdateWordTargetListRowViewModel[]

  showReplaceModal: boolean
  checkedUpdateTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[]

  isUpdateValuePresent: boolean

  searchParams: ComponentElementSearchParams
}

function setupState(root : Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', [
      'pageName',
    ]),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    isLoaded: false,
    hasReportGtManagerRole: computed(() => root.$store.getters['user/hasReportGtManagerRole']),

    urlParams: computed(() => {
      return {
        reportId: Number(root.$route.params.reportId),
        componentId: Number(root.$route.params.componentId),
      }
    }),

    report: null,
    components: [],
    updateTargets: [],

    isMasterCheckboxChecked: false,
    checkboxStatuses: [],
    isCheckedAny: computed(() => state.checkboxStatuses.some(el => el.isChecked)),
    updateTargetListRowViewModels: computed(() => {
      return state.updateTargets.map(el => {
        const checkboxStatus = state.checkboxStatuses.find(status => {
          return status.componentId === el.component.id &&
            status.category === el.category &&
            isSameUpdateWordTargetCategoryIdentifiers(status.positionIdentifier, el.positionIdentifier)
        })!
        return { ...el, isChecked: checkboxStatus.isChecked }
      })
    }),

    showReplaceModal: false,
    checkedUpdateTargets: computed(() => {
      return state.updateTargets.filter(el => {
        const checkboxStatus = state.checkboxStatuses.find(status => {
          return status.componentId === el.component.id &&
            status.category === el.category &&
            isSameUpdateWordTargetCategoryIdentifiers(status.positionIdentifier, el.positionIdentifier)
        })!
        return checkboxStatus.isChecked
      })
    }),

    isUpdateValuePresent: computed(() => {
      return state.updateTargets.some(el => el.updateValue)
    }),

    searchParams: {
      sectionIds: [],
      componentIds: [],
      keyword: null,
    },

  })
  return state
}

export default defineComponent({
  components: {
    AppLink,
    ComponentElementSearchPanel,
    UpdateWordTargetListRow,
    ReplaceWordModal,
  },
  setup() {
    const root = getCurrentInstance()!.proxy
    setPageName(root, 'レポート設定')
    const state = setupState(root)

    const arrangeUpdateTargetViewModels = (updateTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[]) => {
      state.updateTargets = updateTargets

      state.isMasterCheckboxChecked = false

      const initialCheckState = false
      state.checkboxStatuses = state.updateTargets.map(el => {
        return {
          componentId: el.component.id,
          category: el.category,
          positionIdentifier: el.positionIdentifier,
          isChecked: initialCheckState,
        }
      })
    }

    const initializeUpdateTargetViewModels = (components: (Component & { sectionId: number })[]) => {
      const updateTargets = components.map(el => {
        const section = state.report!.sections.find(section => section.id === el.sectionId)!
        return updateWordTargetsFromComponent(el, section)
      }).flat()

      arrangeUpdateTargetViewModels(updateTargets)
    }

    const onSearch = () => {
      // 検索パラメータはいずれも未入力の場合は絞り込み条件に用いない
      const matchSection = (updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>): boolean => {
        if (state.searchParams.sectionIds.length === 0) return true
        // 内部はOR条件
        return state.searchParams.sectionIds.includes(updateTarget.section.id)
      }
      const matchComponent = (updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>): boolean => {
        if (state.searchParams.componentIds.length === 0) return true
        // 内部はOR条件
        return state.searchParams.componentIds.includes(updateTarget.component.id)
      }
      const matchKeyword = (updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>): boolean => {
        if (!state.searchParams.keyword) return true
        return (updateTarget.originalValue ?? '').includes(state.searchParams.keyword)
      }

      const updateTargets = state.components.map(el => {
        const section = state.report!.sections.find(section => section.id === el.sectionId)!
        return updateWordTargetsFromComponent(el, section)
      }).flat().filter(el => matchSection(el) && matchComponent(el) && matchKeyword(el))
      arrangeUpdateTargetViewModels(updateTargets)
    }

    const onCheckboxChange = (updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>, checked: boolean) => {
      const checkboxStatus = state.checkboxStatuses.find(status => {
        return status.componentId === updateTarget.component.id &&
          status.category === updateTarget.category &&
          isSameUpdateWordTargetCategoryIdentifiers(status.positionIdentifier, updateTarget.positionIdentifier)
      })!
      checkboxStatus.isChecked = checked

      state.isMasterCheckboxChecked = state.checkboxStatuses.every(el => el.isChecked)
    }
    const onMasterCheckboxChange = (checked: boolean) => {
      state.isMasterCheckboxChecked = checked
      state.checkboxStatuses = state.checkboxStatuses.map(el => {
        return { ...el, isChecked: checked }
      })
    }

    const onUpdateValueChange = (updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>, value: string) => {
      state.updateTargets = state.updateTargets.map(el => {
        return el.component.id === updateTarget.component.id &&
          el.category === updateTarget.category &&
          isSameUpdateWordTargetCategoryIdentifiers(el.positionIdentifier, updateTarget.positionIdentifier)
          ? { ...el, updateValue: value }
          : el
      })
    }

    const openReplaceModal = () => {
      state.showReplaceModal = true
    }
    const closeReplaceModal = () => {
      state.showReplaceModal = false
    }

    const onReplaced = (updateTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[]) => {
      state.updateTargets = state.updateTargets.map(el => {
        const replacedUpdateTarget = updateTargets.find(target => {
          return el.component.id === target.component.id &&
            el.category === target.category &&
            isSameUpdateWordTargetCategoryIdentifiers(el.positionIdentifier, target.positionIdentifier)
        })
        return replacedUpdateTarget ?? el
      })
      closeReplaceModal()
    }

    const loadReport = async(): Promise<void> => {
      state.report = await reportApi.show(state.urlParams.reportId)
    }
    const loadComponents = async(): Promise<void> => {
      if (!state.report) return
      state.components = state.report!.sections.reduce((components, section) => {
        return components.concat(section.components.map(el => {
          return { ...el, sectionId: section.id }
        }))
      }, [] as (Component & { sectionId: number })[])
    }
    const loadModels = async(): Promise<void> => {
      await loadReport()
      await loadComponents()
    }

    const update = async(): Promise<void> => {
      try {
        const params = convertUpdateTargetsToDeepUpdateWordsRequestParameters(state.updateTargets, state.report!, state.components)
        await reportApi.deepUpdateWords(params)
        await loadModels()
        onSearch()
        notifySuccess1(root, '対象テキストを一括変更しました。')
      } catch (err: any) {
        const errStatus = err.response.status
        if ([403, 404].includes(errStatus)) {
          const msg = '操作権限がありません。管理者にお問合せください。'
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else if (errStatus === 409) {
          // 以下のケースが該当
          // - 表やリストの行数・列数が変更されている場合にヘッダーが編集対象になっている
          // - グラフ要素のラベルを変更対象としたときに、該当のグラフ要素が存在しない
          // グラフ要素の並び替えは検知できないので、並び替えたが順番が対応するグラフ要素が残っている場合ラベルは上書きになる
          const msg = '対象テキストの一括変更に失敗しました。編集された箇所があります。'
          await loadModels()
          onSearch()
          reportError(ERROR_GROUP_USER, msg, err, '')
        } else {
          const msg = '対象テキストの一括変更に失敗しました。管理者に連絡してください。'
          reportError(ERROR_GROUP_SYSTEM, msg, err, '')
        }
      }
    }

    const reportError = async(errorGroup: ErrorGroup, message: string, error: any, errorId: string) => {
      const formattedMessage = errorGroup === ERROR_GROUP_SYSTEM
        ? `${message} (ERR: ${state.pageName} ${errorId ? ` ${errorId}` : ''}, user_id:${state.userId})`
        : message

      notifyError1(root, formattedMessage, error)
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false

      await Promise.all([
        // ログインユーザー情報をAPIで再取得
        ensureUserRefreshAndMasters(root),
        loadReport(),
      ])
      // 現状はレポートの中から取り出す処理になるのでレポートのロードと並行処理にはできない
      // 後のコミットでコンポーネントレベルで取得できるようになれば並行処理にできるはずである
      // 並行にする場合はloadModelsを並列処理に変更して、loadReportとloadComponentsの代わりにloadModelsを呼び出す
      await loadComponents()

      initializeUpdateTargetViewModels(state.components)

      state.showReplaceModal = false

      state.isLoaded = true
    })

    return {
      state,
      onSearch,
      onCheckboxChange,
      onMasterCheckboxChange,
      onUpdateValueChange,
      openReplaceModal,
      closeReplaceModal,
      onReplaced,
      update,
    }
  },
})

