import { ERROR_GROUP_SYSTEM, ErrorGroup } from 'src/consts'
import { Component, getCurrentInstance } from 'vue'
import { usePageName } from './usePageName'
import { useLoginUser } from './useLoginUser'
import { NotifyOptions } from 'vue-notifyjs'

// アプリケーションからオーバーライド可能なオプション値を定義
// ただし、基本的にはオーバーライドせずに使うことを推奨する
export type NotificationOptions = {
  verticalAlign?: string
  horizontalAlign?: string
  timeout?: number
  icon?: string
  component?: Component
}

const defaultOptions: NotifyOptions = {
  verticalAlign: 'top', // top | bottom
  horizontalAlign: 'center', // right | center | left
}

const defaultSuccessOptions: NotifyOptions = {
  type: 'info',
  timeout: 3 * 1000,
}

const defaultErrorOptions: NotifyOptions = {
  type: 'danger',
  timeout: 60 * 1000,
  icon: 'fas fa-exclamation-triangle',
}

const doNotify = (vue: Vue, message: string, options: NotifyOptions = {}) => {
  // なぜかvue.$notifyと呼ぶとコンパイルエラー. vue-notifyjs.d.tsで定義はしてるはずだが.
  (vue as any).$notify({ ...defaultOptions, ...options, message })
}

export const useNotifiers = () => {
  const vue = getCurrentInstance()!.proxy

  const notifySuccess = async(message: string, options: NotificationOptions = {}) => {
    doNotify(vue, message, { ...defaultSuccessOptions, ...options })
  }

  const {
    pageNameRef,
  } = usePageName()

  const {
    loginUserRef,
  } = useLoginUser()

  const notifyError = async(message: string, errorGroup: ErrorGroup, error?: Error, errorId?: string, options: NotificationOptions = {}) => {
    const formattedMessage = errorGroup === ERROR_GROUP_SYSTEM
      ? `${message} (ERR: ${pageNameRef.value} ${errorId ?? ''}, user_id:${loginUserRef.value.id})`
      : message

    console.error(message)
    if (error) {
      console.error(error)
    }

    doNotify(vue, formattedMessage, { ...defaultErrorOptions, ...options })
  }

  return {
    notifySuccess,
    notifyError,
  }
}
