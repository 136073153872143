import axios from 'axios';
import {
  ArticleIndexResponseData,
  convertFromArticleResponseData,
} from 'src/models/api/zendesk/Article/articleResponseData';
import { ZendeskArticleIndexParameters } from 'src/models/api/zendesk/Article/articleIndexRequestParameters';
import { ZendeskArticle } from 'src/views/Dashboard/Home/Components/composables/useAnnouncementArticles';

export default {
  async index(data: ZendeskArticleIndexParameters): Promise<ZendeskArticle[]> {
    const url = 'https://support.logiscope.io/api/v2/help_center/ja/categories/29595693152665/articles.json';
    const { data: resData } = await axios.get<ArticleIndexResponseData>(url, { params: data });
    return resData.articles.slice(0, 3).map((e) => {
      return convertFromArticleResponseData({
        title: e.title,
        created_at: e.created_at.slice(0, 10),
        html_url: e.html_url,
      });
    });
  },
};
