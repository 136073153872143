import {
  ReportDeepUpdateWordsComponentParameters,
  ReportDeepUpdateWordsHeaderParameters,
  ReportDeepUpdateWordsRequestParameters,
} from 'src/models/api/Report/reportDeepUpdateWordsRequestParameters';
import {
  MetricsBarGraphComponent,
  isComponentTypeMetricsBarGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';
import {
  MetricsGroupedGraphComponent,
  isComponentTypeMetricsGroupedGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import {
  MetricsPieChartComponent,
  isComponentTypeMetricsPieChart,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import {
  MetricsTransitionGraphComponent,
  isComponentTypeMetricsTransitionGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent';
import {
  MetricsCardComponent,
  isComponentTypeMetricsCard,
  isMetricsCardComponentCompleted,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import {
  MetricsListComponent,
  isComponentTypeMetricsList,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import {
  MetricsTableComponent,
  isComponentTypeMetricsTable,
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { CommentComponent, isComponentTypeComment } from 'src/models/new/Component/commentComponent';
import { Component } from 'src/models/new/component';
import { Report } from 'src/models/new/report';
import { Section } from 'src/models/new/section';
import { transpose } from 'src/util/array';
import { HeaderStructure, isDataHeaderKeyWord } from 'src/util/dataHeader';
import { isSameObjects } from 'src/util/object';

const COMPONENT_NAME = 'componentName';
const CARD_UNIT_NAME = 'cardUnitName';
const CARD_REFERENCE_NAME = 'cardReferenceName';
const TABLE_HEADER_LABEL = 'tableHeaderLabel';
const LIST_HEADER_LABEL = 'listHeaderLabel';
const PIE_CHART_ALIAS = 'pieChartAlias';
const BAR_GRAPH_ALIAS = 'barGraphAlias';
const BAR_GRAPH_REFERENCE_LABEL = 'barGraphReferenceLabel';
const BAR_GRAPH_UNIT = 'barGraphUnit';
const GROUPED_GRAPH_SCALE_UNIT = 'groupedGraphScaleUnit';
const GROUPED_GRAPH_GRAPH_LABEL = 'groupedGraphGraphLabel';
const GROUPED_GRAPH_GROUP_LABEL = 'groupedGraphGroupLabel';
const GROUPED_GRAPH_REFERENCE_LABEL = 'groupedGraphReferenceLabel';
const TRANSITION_GRAPH_SCALE_UNIT = 'transitionGraphScaleUnit';
const TRANSITION_GRAPH_GRAPH_LABEL = 'transitionGraphGraphLabel';
const TRANSITION_GRAPH_REFERENCE_LABEL = 'transitionGraphReferenceLabel';
const COMMENT_PLACEHOLDER = 'commentPlaceHolder';

export type UpdateWordTargetCategory =
  | typeof COMPONENT_NAME
  | typeof CARD_UNIT_NAME
  | typeof CARD_REFERENCE_NAME
  | typeof TABLE_HEADER_LABEL
  | typeof LIST_HEADER_LABEL
  | typeof PIE_CHART_ALIAS
  | typeof BAR_GRAPH_ALIAS
  | typeof BAR_GRAPH_REFERENCE_LABEL
  | typeof BAR_GRAPH_UNIT
  | typeof GROUPED_GRAPH_SCALE_UNIT
  | typeof GROUPED_GRAPH_GRAPH_LABEL
  | typeof GROUPED_GRAPH_GROUP_LABEL
  | typeof GROUPED_GRAPH_REFERENCE_LABEL
  | typeof TRANSITION_GRAPH_SCALE_UNIT
  | typeof TRANSITION_GRAPH_GRAPH_LABEL
  | typeof TRANSITION_GRAPH_REFERENCE_LABEL
  | typeof COMMENT_PLACEHOLDER;

export type ComponentNameIdentifier = {};
export type CardUnitNameIdentifier = {};
export type CardReferenceNameIdentifier = {};
type HeaderLabelIdentifier = {
  alignment: 'side' | 'head';
  level: number;
  position: number;
};
export type TableHeaderLabelIdentifier = HeaderLabelIdentifier;
export type ListHeaderLabelIdentifier = HeaderLabelIdentifier;
type OrderedElementIdentifier = {
  order: number;
};
export type PieChartAliasIdentifier = OrderedElementIdentifier;
export type BarGraphAliasIdentifier = OrderedElementIdentifier;
export type BarGraphReferenceLabelIdentifier = {};
export type BarGraphUnitIdentifier = {};
type ScaleIdentifier = {
  scale: 'left' | 'right';
};
export type GroupedGraphScaleUnitIdentifier = ScaleIdentifier;
type GraphIdentifier = ScaleIdentifier & {
  number: number;
};
export type GroupedGraphGraphLabelIdentifier = GraphIdentifier;
export type groupedGraphGroupLabelIdentifier = OrderedElementIdentifier;
export type GroupedGraphReferenceLabelIdentifier = {};
export type TransitionGraphScaleUnitIdentifier = ScaleIdentifier;
export type TransitionGraphGraphLabelIdentifier = GraphIdentifier;
export type TransitionGraphReferenceLabelIdentifier = {};
export type CommentPlaceHolderIdentifier = {};

export type UpdateWordTargetCategoryIdentifier =
  | ComponentNameIdentifier
  | CardReferenceNameIdentifier
  | TableHeaderLabelIdentifier
  | ListHeaderLabelIdentifier
  | PieChartAliasIdentifier
  | BarGraphAliasIdentifier
  | BarGraphReferenceLabelIdentifier
  | BarGraphUnitIdentifier
  | GroupedGraphScaleUnitIdentifier
  | GroupedGraphGraphLabelIdentifier
  | groupedGraphGroupLabelIdentifier
  | GroupedGraphReferenceLabelIdentifier
  | TransitionGraphScaleUnitIdentifier
  | TransitionGraphGraphLabelIdentifier
  | TransitionGraphReferenceLabelIdentifier
  | CommentPlaceHolderIdentifier;

export type UpdateWordTarget<PI> = {
  section: Section;
  component: Component;
  category: UpdateWordTargetCategory;
  originalValue: string | null;
  updateValue: string | null;
  positionIdentifier: PI;
};

const CATEGORY_LABELS: Record<UpdateWordTargetCategory, string> = {
  [COMPONENT_NAME]: 'タイトル',
  [CARD_UNIT_NAME]: '単位',
  [CARD_REFERENCE_NAME]: '参考値表示名',
  [TABLE_HEADER_LABEL]: 'ヘッダー',
  [LIST_HEADER_LABEL]: 'ヘッダー',
  [PIE_CHART_ALIAS]: 'グラフ要素名',
  [BAR_GRAPH_ALIAS]: 'グラフ要素名',
  [BAR_GRAPH_REFERENCE_LABEL]: '参考値表示名',
  [BAR_GRAPH_UNIT]: '単位',
  [GROUPED_GRAPH_SCALE_UNIT]: '単位',
  [GROUPED_GRAPH_GRAPH_LABEL]: 'グラフ名',
  [GROUPED_GRAPH_GROUP_LABEL]: 'グループ名',
  [GROUPED_GRAPH_REFERENCE_LABEL]: '参考値表示名',
  [TRANSITION_GRAPH_SCALE_UNIT]: '単位',
  [TRANSITION_GRAPH_GRAPH_LABEL]: 'グラフ名',
  [TRANSITION_GRAPH_REFERENCE_LABEL]: '参考値表示名',
  [COMMENT_PLACEHOLDER]: 'プレイスホルダー',
};

export const updateWordTargetToLabel = (target: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>): string => {
  const getPositionString = (target: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>): string | null => {
    if (isComponentTypeMetricsTable(target.component) || isComponentTypeMetricsList(target.component)) {
      if (
        'alignment' in target.positionIdentifier &&
        'level' in target.positionIdentifier &&
        'position' in target.positionIdentifier
      ) {
        if (target.positionIdentifier.alignment === 'head') {
          return `上部${target.positionIdentifier.level}行目-${target.positionIdentifier.position}番目`;
        } else if (target.positionIdentifier.alignment === 'side') {
          return `左部${target.positionIdentifier.level}列目-${target.positionIdentifier.position}番目`;
        }
      }
    } else if (isComponentTypeMetricsPieChart(target.component) || isComponentTypeMetricsBarGraph(target.component)) {
      if ('order' in target.positionIdentifier) {
        return `${target.positionIdentifier.order}番目`;
      }
    } else if (isComponentTypeMetricsGroupedGraph(target.component)) {
      if ('scale' in target.positionIdentifier && 'number' in target.positionIdentifier) {
        if (target.positionIdentifier.scale === 'left') {
          return `左軸${target.positionIdentifier.number}`;
        } else if (target.positionIdentifier.scale === 'right') {
          return `右軸${target.positionIdentifier.number}`;
        }
      } else if ('scale' in target.positionIdentifier) {
        if (target.positionIdentifier.scale === 'left') {
          return '左軸';
        } else if (target.positionIdentifier.scale === 'right') {
          return '右軸';
        }
      } else if ('order' in target.positionIdentifier) {
        return `${target.positionIdentifier.order}番目`;
      }
    } else if (isComponentTypeMetricsTransitionGraph(target.component)) {
      if ('scale' in target.positionIdentifier && 'number' in target.positionIdentifier) {
        if (target.positionIdentifier.scale === 'left') {
          return `左軸${target.positionIdentifier.number}`;
        } else if (target.positionIdentifier.scale === 'right') {
          return `右軸${target.positionIdentifier.number}`;
        }
      } else if ('scale' in target.positionIdentifier) {
        if (target.positionIdentifier.scale === 'left') {
          return '左軸';
        } else if (target.positionIdentifier.scale === 'right') {
          return '右軸';
        }
      }
    }
    return null;
  };
  const positionString = getPositionString(target);

  return positionString ? `${CATEGORY_LABELS[target.category]}（${positionString}）` : CATEGORY_LABELS[target.category];
};

export const isSameUpdateWordTargetCategoryIdentifiers = (
  a: UpdateWordTargetCategoryIdentifier,
  b: UpdateWordTargetCategoryIdentifier,
): boolean => isSameObjects(a, b);

// FIXME: Sectionも渡しているが、本当はComponentだけで良いようにできるはず
export const updateWordTargetsFromComponent = (
  component: Component,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  if (isComponentTypeMetricsCard(component) && isMetricsCardComponentCompleted(component)) {
    return updateWordTargetsFromMetricsCardComponent(component, section);
  } else if (isComponentTypeMetricsTable(component)) {
    return updateWordTargetsFromMetricsTableComponent(component, section);
  } else if (isComponentTypeMetricsList(component)) {
    return updateWordTargetsFromMetricsListComponent(component, section);
  } else if (isComponentTypeMetricsPieChart(component)) {
    return updateWordTargetsFromMetricsPieChartComponent(component, section);
  } else if (isComponentTypeMetricsBarGraph(component)) {
    return updateWordTargetsFromMetricsBarGraphComponent(component, section);
  } else if (isComponentTypeMetricsGroupedGraph(component)) {
    return updateWordTargetsFromMetricsGroupedGraphComponent(component, section);
  } else if (isComponentTypeMetricsTransitionGraph(component)) {
    return updateWordTargetsFromMetricsTransitionGraphComponent(component, section);
  } else if (isComponentTypeComment(component)) {
    return updateWordTargetsFromCommentComponent(component, section);
  }

  return [];
};

const updateWordTargetsFromMetricsCardComponent = (
  component: MetricsCardComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  // TODO: 値のない箇所は取り扱わないようにする？
  return [
    {
      section,
      component,
      category: COMPONENT_NAME,
      originalValue: component.name,
      updateValue: null,
      positionIdentifier: {},
    },
    {
      section,
      component,
      category: CARD_UNIT_NAME,
      originalValue: component.unit,
      updateValue: null,
      positionIdentifier: {},
    },
    {
      section,
      component,
      category: CARD_REFERENCE_NAME,
      originalValue: component.subMetricsName,
      updateValue: null,
      positionIdentifier: {},
    },
  ];
};

const updateWordTargetsFromMetricsTableComponent = (
  component: MetricsTableComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  const headerHeadTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = component.headers.head.layout.data
    .filter((el) => el.value && !isDataHeaderKeyWord(el.value))
    .map((el) => {
      return {
        section,
        component,
        category: TABLE_HEADER_LABEL,
        originalValue: el.value,
        updateValue: null,
        positionIdentifier: {
          alignment: 'head',
          level: el.level,
          position: el.position,
        },
      };
    });

  const headerSideTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = component.headers.side.layout.data
    .filter((el) => el.value && !isDataHeaderKeyWord(el.value))
    .map((el) => {
      return {
        section,
        component,
        category: TABLE_HEADER_LABEL,
        originalValue: el.value,
        updateValue: null,
        positionIdentifier: {
          alignment: 'side',
          level: el.level,
          position: el.position,
        },
      };
    });

  return [
    {
      section,
      component,
      category: COMPONENT_NAME,
      originalValue: component.name,
      updateValue: null,
      positionIdentifier: {},
    },
    ...headerHeadTargets,
    ...headerSideTargets,
  ];
};

const updateWordTargetsFromMetricsListComponent = (
  component: MetricsListComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  const headerHeadTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = component.headers.head.layout.data
    .filter((el) => el.value && !isDataHeaderKeyWord(el.value))
    .map((el) => {
      return {
        section,
        component,
        category: LIST_HEADER_LABEL,
        originalValue: el.value,
        updateValue: null,
        positionIdentifier: {
          alignment: 'head',
          level: el.level,
          position: el.position,
        },
      };
    });

  const headerSideTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = component.headers.side.layout.data
    .filter((el) => el.value && !isDataHeaderKeyWord(el.value))
    .map((el) => {
      return {
        section,
        component,
        category: LIST_HEADER_LABEL,
        originalValue: el.value,
        updateValue: null,
        positionIdentifier: {
          alignment: 'side',
          level: el.level,
          position: el.position,
        },
      };
    });

  return [
    {
      section,
      component,
      category: COMPONENT_NAME,
      originalValue: component.name,
      updateValue: null,
      positionIdentifier: {},
    },
    ...headerHeadTargets,
    ...headerSideTargets,
  ];
};

const updateWordTargetsFromMetricsPieChartComponent = (
  component: MetricsPieChartComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  const pieTargets: UpdateWordTarget<PieChartAliasIdentifier>[] = component.data.map((el) => {
    return {
      section,
      component,
      category: PIE_CHART_ALIAS,
      originalValue: el.alias,
      updateValue: null,
      positionIdentifier: {
        order: el.internalOrder,
      },
    };
  });

  return [
    {
      section,
      component,
      category: COMPONENT_NAME,
      originalValue: component.name,
      updateValue: null,
      positionIdentifier: {},
    },
    ...pieTargets,
  ];
};

const updateWordTargetsFromMetricsBarGraphComponent = (
  component: MetricsBarGraphComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  const targets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = [];

  const titleTarget = {
    section,
    component,
    category: COMPONENT_NAME as UpdateWordTargetCategory,
    originalValue: component.name,
    updateValue: null,
    positionIdentifier: {},
  };
  targets.push(titleTarget);

  const barTargets: UpdateWordTarget<BarGraphAliasIdentifier>[] = component.data.bars.map((el) => {
    return {
      section,
      component,
      category: BAR_GRAPH_ALIAS,
      originalValue: el.alias,
      updateValue: null,
      positionIdentifier: {
        order: el.sequentialOrder,
      },
    };
  });
  targets.push(...barTargets);

  const unitTarget = {
    section,
    component,
    category: BAR_GRAPH_UNIT as UpdateWordTargetCategory,
    originalValue: component.unit,
    updateValue: null,
    positionIdentifier: {},
  };
  targets.push(unitTarget);

  if (component.data.reference) {
    const referenceTarget = {
      section,
      component,
      category: BAR_GRAPH_REFERENCE_LABEL as UpdateWordTargetCategory,
      originalValue: component.data.reference.label,
      updateValue: null,
      positionIdentifier: {},
    };
    targets.push(referenceTarget);
  }

  return targets;
};

const updateWordTargetsFromMetricsGroupedGraphComponent = (
  component: MetricsGroupedGraphComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  const targets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = [];

  const titleTarget = {
    section,
    component,
    category: COMPONENT_NAME as UpdateWordTargetCategory,
    originalValue: component.name,
    updateValue: null,
    positionIdentifier: {},
  };
  targets.push(titleTarget);

  const graphLabelTargets: UpdateWordTarget<GroupedGraphGraphLabelIdentifier>[] = component.data.graphs.map((el) => {
    return {
      section,
      component,
      category: GROUPED_GRAPH_GRAPH_LABEL,
      originalValue: el.label,
      updateValue: null,
      positionIdentifier: {
        scale: el.scale,
        number: el.number,
      },
    };
  });
  targets.push(...graphLabelTargets);

  const groupLabelTargets: UpdateWordTarget<groupedGraphGroupLabelIdentifier>[] = component.data.graphs.reduce(
    (targets, graph) => {
      if (targets.length > 0) {
        return targets;
      }

      return graph.plots.map((el) => {
        return {
          section,
          component,
          category: GROUPED_GRAPH_GROUP_LABEL,
          originalValue: el.label,
          updateValue: null,
          positionIdentifier: {
            order: el.group,
          },
        };
      });
    },
    [] as UpdateWordTarget<groupedGraphGroupLabelIdentifier>[],
  );
  targets.push(...groupLabelTargets);

  const scaleUnitTargets: UpdateWordTarget<GroupedGraphScaleUnitIdentifier>[] = [
    {
      section,
      component,
      category: GROUPED_GRAPH_SCALE_UNIT,
      originalValue: component.scaleUnits.left,
      updateValue: null,
      positionIdentifier: {
        scale: 'left',
      },
    },
    {
      section,
      component,
      category: GROUPED_GRAPH_SCALE_UNIT,
      originalValue: component.scaleUnits.right,
      updateValue: null,
      positionIdentifier: {
        scale: 'right',
      },
    },
  ];
  targets.push(...scaleUnitTargets);

  if (component.data.reference) {
    const referenceTarget = {
      section,
      component,
      category: GROUPED_GRAPH_REFERENCE_LABEL as UpdateWordTargetCategory,
      originalValue: component.data.reference.label,
      updateValue: null,
      positionIdentifier: {},
    };
    targets.push(referenceTarget);
  }

  return targets;
};

const updateWordTargetsFromMetricsTransitionGraphComponent = (
  component: MetricsTransitionGraphComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  const targets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] = [];

  const titleTarget = {
    section,
    component,
    category: COMPONENT_NAME as UpdateWordTargetCategory,
    originalValue: component.name,
    updateValue: null,
    positionIdentifier: {},
  };
  targets.push(titleTarget);

  const graphLabelTargets: UpdateWordTarget<TransitionGraphGraphLabelIdentifier>[] = component.data.graphs.map((el) => {
    return {
      section,
      component,
      category: TRANSITION_GRAPH_GRAPH_LABEL,
      originalValue: el.label,
      updateValue: null,
      positionIdentifier: {
        scale: el.scale,
        number: el.number,
      },
    };
  });
  targets.push(...graphLabelTargets);

  const scaleUnitTargets: UpdateWordTarget<TransitionGraphScaleUnitIdentifier>[] = [
    {
      section,
      component,
      category: TRANSITION_GRAPH_SCALE_UNIT,
      originalValue: component.scaleUnits.left,
      updateValue: null,
      positionIdentifier: {
        scale: 'left',
      },
    },
    {
      section,
      component,
      category: TRANSITION_GRAPH_SCALE_UNIT,
      originalValue: component.scaleUnits.right,
      updateValue: null,
      positionIdentifier: {
        scale: 'right',
      },
    },
  ];
  targets.push(...scaleUnitTargets);

  if (component.data.reference) {
    const referenceTarget = {
      section,
      component,
      category: TRANSITION_GRAPH_REFERENCE_LABEL as UpdateWordTargetCategory,
      originalValue: component.data.reference.label,
      updateValue: null,
      positionIdentifier: {},
    };
    targets.push(referenceTarget);
  }

  return targets;
};

const updateWordTargetsFromCommentComponent = (
  component: CommentComponent,
  section: Section,
): UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[] => {
  return [
    {
      section,
      component,
      category: COMPONENT_NAME,
      originalValue: component.name,
      updateValue: null,
      positionIdentifier: {},
    },
    {
      section,
      component,
      category: COMMENT_PLACEHOLDER,
      originalValue: component.placeholder,
      updateValue: null,
      positionIdentifier: {},
    },
  ];
};

export const convertUpdateTargetsToDeepUpdateWordsRequestParameters = (
  updateTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[],
  report: Report,
  // メトリクステーブルコンポーネントとメトリクスリストコンポーネントのヘッダーは一部変更の場合に
  // 元のヘッダーをコピーして変更を差し込む必要があり、その為にコンポーネントのリストを渡す必要がある
  components: Component[],
): ReportDeepUpdateWordsRequestParameters => {
  const componentParamsList: ReportDeepUpdateWordsComponentParameters[] = updateTargets.reduce(
    (paramsList, updateTarget) => {
      // updateTargetがセットされていない箇所はリクエストパラメータに含めない
      // 空文字である場合も更新対象としないこととしている
      if (!updateTarget.updateValue) {
        return paramsList;
      }

      const foundParams = paramsList.find((params) => params.id === updateTarget.component.id);
      const isFirstToComponentId = !foundParams;

      const params = isFirstToComponentId ? { id: updateTarget.component.id } : foundParams;

      const cardCategories = [CARD_UNIT_NAME, CARD_REFERENCE_NAME];
      const tableCategories = [TABLE_HEADER_LABEL];
      const listCategories = [LIST_HEADER_LABEL];
      const graphCategories = [
        PIE_CHART_ALIAS,
        BAR_GRAPH_ALIAS,
        BAR_GRAPH_REFERENCE_LABEL,
        BAR_GRAPH_UNIT,
        GROUPED_GRAPH_SCALE_UNIT,
        GROUPED_GRAPH_GRAPH_LABEL,
        GROUPED_GRAPH_GROUP_LABEL,
        GROUPED_GRAPH_REFERENCE_LABEL,
        TRANSITION_GRAPH_SCALE_UNIT,
        TRANSITION_GRAPH_GRAPH_LABEL,
        TRANSITION_GRAPH_REFERENCE_LABEL,
      ];
      const commentCategories = [COMMENT_PLACEHOLDER];
      if (cardCategories.includes(updateTarget.category) && !params.single_value_component) {
        params.single_value_component = {};
      } else if (tableCategories.includes(updateTarget.category) && !params.table_component) {
        params.table_component = {};
      } else if (listCategories.includes(updateTarget.category) && !params.list_component) {
        params.list_component = {};
      } else if (graphCategories.includes(updateTarget.category) && !params.graph_component) {
        params.graph_component = {};
      } else if (commentCategories.includes(updateTarget.category) && !params.comment_component) {
        params.comment_component = {};
      }

      if (updateTarget.category === COMPONENT_NAME) {
        params.name = updateTarget.updateValue;
      } else if (updateTarget.category === CARD_UNIT_NAME && params.single_value_component) {
        params.single_value_component.unit_disp_name = updateTarget.updateValue;
      } else if (updateTarget.category === CARD_REFERENCE_NAME && params.single_value_component) {
        params.single_value_component.sub_disp_name = updateTarget.updateValue;
      } else if (updateTarget.category === TABLE_HEADER_LABEL && params.table_component) {
        if (!params.table_component.header) {
          params.table_component.header = getOriginalHeader(updateTarget, components);
        }
        if ('alignment' in updateTarget.positionIdentifier) {
          const level = updateTarget.positionIdentifier.level;
          const position = updateTarget.positionIdentifier.position;
          // headerのインデックスに対する要素は、存在する分だけがupdateTargetに含まれるため`!`でアクセスして問題ない
          if (updateTarget.positionIdentifier.alignment === 'head') {
            params.table_component.header.cols[level - 1]![position - 1]! = updateTarget.updateValue;
          } else if (updateTarget.positionIdentifier.alignment === 'side') {
            params.table_component.header.rows[position - 1]![level - 1]! = updateTarget.updateValue;
          }
        }
      } else if (updateTarget.category === LIST_HEADER_LABEL && params.list_component) {
        if (!params.list_component.header) {
          params.list_component.header = getOriginalHeader(updateTarget, components);
        }
        if ('alignment' in updateTarget.positionIdentifier) {
          const level = updateTarget.positionIdentifier.level;
          const position = updateTarget.positionIdentifier.position;
          // headerのインデックスに対する要素は、存在する分だけがupdateTargetに含まれるため`!`でアクセスして問題ない
          if (updateTarget.positionIdentifier.alignment === 'head') {
            params.list_component.header.cols[level - 1]![position - 1]! = updateTarget.updateValue;
          } else if (updateTarget.positionIdentifier.alignment === 'side') {
            params.list_component.header.rows[position - 1]![level - 1]! = updateTarget.updateValue;
          }
        }
      } else if (updateTarget.category === PIE_CHART_ALIAS && params.graph_component) {
        if ('order' in updateTarget.positionIdentifier) {
          const positionIdentifier = updateTarget.positionIdentifier;
          const foundInnerParams = params.graph_component.metrics_graph_components?.find((innerParams) => {
            return innerParams.registration_order === positionIdentifier.order;
          });
          const isFirstToComponentId = !foundInnerParams;
          const innerParams = isFirstToComponentId
            ? // FIXME: 'l1' | 'l2' | 'r1' | 'r2' を型定義して使用すると、少なくとも'l1' as 'l1'は避けられる
              // 現状では'l1'が'l1' | 'l2' | 'r1' | 'r2'に内包されていることを静的解析が判断できていない
              { registration_order: updateTarget.positionIdentifier.order, graph_no: 'l1' as 'l1' }
            : foundInnerParams;
          innerParams.disp_name = updateTarget.updateValue;

          if (isFirstToComponentId) {
            if (!params.graph_component.metrics_graph_components) {
              params.graph_component.metrics_graph_components = [];
            }
            params.graph_component.metrics_graph_components.push(innerParams);
          }
        }
      } else if (updateTarget.category === BAR_GRAPH_ALIAS && params.graph_component) {
        if ('order' in updateTarget.positionIdentifier) {
          const positionIdentifier = updateTarget.positionIdentifier;
          const foundInnerParams = params.graph_component.metrics_graph_components?.find((innerParams) => {
            return innerParams.registration_order === positionIdentifier.order;
          });
          const isFirstToComponentId = !foundInnerParams;
          const innerParams = isFirstToComponentId
            ? // FIXME: 'l1' | 'l2' | 'r1' | 'r2' を型定義して使用すると、少なくとも'l1' as 'l1'は避けられる
              // 現状では'l1'が'l1' | 'l2' | 'r1' | 'r2'に内包されていることを静的解析が判断できていない
              { registration_order: updateTarget.positionIdentifier.order, graph_no: 'l1' as 'l1' }
            : foundInnerParams;
          innerParams.disp_name = updateTarget.updateValue;

          if (isFirstToComponentId) {
            if (!params.graph_component.metrics_graph_components) {
              params.graph_component.metrics_graph_components = [];
            }
            params.graph_component.metrics_graph_components.push(innerParams);
          }
        }
      } else if (updateTarget.category === BAR_GRAPH_REFERENCE_LABEL && params.graph_component) {
        params.graph_component.sub_disp_name = updateTarget.updateValue;
      } else if (updateTarget.category === BAR_GRAPH_UNIT && params.graph_component) {
        params.graph_component.unit_disp_name1 = updateTarget.updateValue;
      } else if (updateTarget.category === GROUPED_GRAPH_SCALE_UNIT && params.graph_component) {
        if ('scale' in updateTarget.positionIdentifier) {
          if (updateTarget.positionIdentifier.scale === 'left') {
            params.graph_component.unit_disp_name1 = updateTarget.updateValue;
          } else if (updateTarget.positionIdentifier.scale === 'right') {
            params.graph_component.unit_disp_name2 = updateTarget.updateValue;
          }
        }
      } else if (updateTarget.category === GROUPED_GRAPH_GRAPH_LABEL && params.graph_component) {
        if ('scale' in updateTarget.positionIdentifier && 'number' in updateTarget.positionIdentifier) {
          if (updateTarget.positionIdentifier.scale === 'left' && updateTarget.positionIdentifier.number === 1) {
            params.graph_component.legend_name_l1 = updateTarget.updateValue;
          } else if (updateTarget.positionIdentifier.scale === 'left' && updateTarget.positionIdentifier.number === 2) {
            params.graph_component.legend_name_l2 = updateTarget.updateValue;
          } else if (
            updateTarget.positionIdentifier.scale === 'right' &&
            updateTarget.positionIdentifier.number === 1
          ) {
            params.graph_component.legend_name_r1 = updateTarget.updateValue;
          } else if (
            updateTarget.positionIdentifier.scale === 'right' &&
            updateTarget.positionIdentifier.number === 2
          ) {
            params.graph_component.legend_name_r2 = updateTarget.updateValue;
          }
        }
      } else if (updateTarget.category === GROUPED_GRAPH_GROUP_LABEL && params.graph_component) {
        if ('order' in updateTarget.positionIdentifier) {
          const positionIdentifier = updateTarget.positionIdentifier;
          const component = components.find((el) => el.id === updateTarget.component.id);
          if (component && isComponentTypeMetricsGroupedGraph(component)) {
            const graphsInUse = component.data.graphs.filter((el) => el.plots.length > 0);
            const foundInnerParamsList = params.graph_component.metrics_graph_components?.filter((innerParams) => {
              return innerParams.registration_order === positionIdentifier.order;
            });
            const isFirstToComponentId = !foundInnerParamsList || foundInnerParamsList.length === 0;
            const innerParamsList = isFirstToComponentId
              ? graphsInUse.map((el) => {
                  // numberが3以上になるケースが型判別上引っかかるが、実際には発生しないためアサーションをかける
                  const graph_no = (el.scale === 'left' ? `l${el.number}` : `r${el.number}`) as
                    | 'l1'
                    | 'l2'
                    | 'r1'
                    | 'r2';
                  // updateTarget.updateValueはnullのケースはループの最初に弾いているが、静的解析に失敗している
                  return {
                    registration_order: positionIdentifier.order,
                    graph_no,
                    disp_name: updateTarget.updateValue!,
                  };
                })
              : foundInnerParamsList.map((el) => {
                  // updateTarget.updateValueはnullのケースはループの最初に弾いているが、静的解析に失敗している
                  return { ...el, disp_name: updateTarget.updateValue! };
                });

            if (params.graph_component.metrics_graph_components) {
              params.graph_component.metrics_graph_components = params.graph_component.metrics_graph_components
                .filter((el) => {
                  return innerParamsList.every((innerParams) => {
                    return (
                      innerParams.graph_no !== el.graph_no || innerParams.registration_order !== el.registration_order
                    );
                  });
                })
                .concat(innerParamsList);
            } else {
              params.graph_component.metrics_graph_components = innerParamsList;
            }
          }
        }
      } else if (updateTarget.category === GROUPED_GRAPH_REFERENCE_LABEL && params.graph_component) {
        params.graph_component.sub_disp_name = updateTarget.updateValue;
      } else if (updateTarget.category === TRANSITION_GRAPH_SCALE_UNIT && params.graph_component) {
        if ('scale' in updateTarget.positionIdentifier) {
          if (updateTarget.positionIdentifier.scale === 'left') {
            params.graph_component.unit_disp_name1 = updateTarget.updateValue;
          } else if (updateTarget.positionIdentifier.scale === 'right') {
            params.graph_component.unit_disp_name2 = updateTarget.updateValue;
          }
        }
      } else if (updateTarget.category === TRANSITION_GRAPH_GRAPH_LABEL && params.graph_component) {
        if ('scale' in updateTarget.positionIdentifier && 'number' in updateTarget.positionIdentifier) {
          if (updateTarget.positionIdentifier.scale === 'left' && updateTarget.positionIdentifier.number === 1) {
            params.graph_component.legend_name_l1 = updateTarget.updateValue;
          } else if (updateTarget.positionIdentifier.scale === 'left' && updateTarget.positionIdentifier.number === 2) {
            params.graph_component.legend_name_l2 = updateTarget.updateValue;
          } else if (
            updateTarget.positionIdentifier.scale === 'right' &&
            updateTarget.positionIdentifier.number === 1
          ) {
            params.graph_component.legend_name_r1 = updateTarget.updateValue;
          } else if (
            updateTarget.positionIdentifier.scale === 'right' &&
            updateTarget.positionIdentifier.number === 2
          ) {
            params.graph_component.legend_name_r2 = updateTarget.updateValue;
          }
        }
      } else if (updateTarget.category === TRANSITION_GRAPH_REFERENCE_LABEL && params.graph_component) {
        params.graph_component.sub_disp_name = updateTarget.updateValue;
      } else if (updateTarget.category === COMMENT_PLACEHOLDER) {
        params.comment_component!.placeholder = updateTarget.updateValue;
      }

      if (isFirstToComponentId) {
        paramsList.push(params);
      }

      return paramsList;
    },
    [] as ReportDeepUpdateWordsComponentParameters[],
  );

  return {
    id: report.id,
    components: componentParamsList,
  };
};

const getOriginalHeader = (
  updateTarget: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>,
  components: Component[],
): ReportDeepUpdateWordsHeaderParameters => {
  const component = components.find((el) => el.id === updateTarget.component.id)!;

  if (!component || (!isComponentTypeMetricsTable(component) && !isComponentTypeMetricsList(component))) {
    throw new Error('Logical error.');
  }

  // TODO: 作業のコンフリクトを避けるために直接記述したが
  // componentCreateRequestParametersに移植されていたらそれを呼び出すようにする
  const convertHeaderStructureToArray = (header: HeaderStructure): string[][] => {
    const array = new Array(header.depth).fill(null).map((_) => new Array(header.breadth).fill('')) as string[][];
    header.data.forEach((el) => {
      // 必ずlevel < depth, position < breadth であるため、`!`でアクセスして問題ない
      // この前提が崩れている時は参照エラーか代入エラーが生じる
      array[el.level - 1]![el.position - 1]! = el.value || '';
    });

    return array;
  };

  return {
    cols: convertHeaderStructureToArray(component.headers.head.layout),
    rows: transpose(convertHeaderStructureToArray(component.headers.side.layout)),
  };
};
