import { MetricsType } from 'src/models/new/metrics'
import { TimeSpan } from 'src/business/timeSpan'
import { convertMetricsTypeToFrontMetricsType, internal } from 'src/models/api/Metrics/metricsIndexRequestParameters'

const FRONT_METRICS_TYPE_DIRECT_INPUT = internal.FRONT_METRICS_TYPE_DIRECT_INPUT
const FRONT_METRICS_TYPE_LOGIMETER = internal.FRONT_METRICS_TYPE_LOGIMETER
const FRONT_METRICS_TYPE_LOGIBOARD = internal.FRONT_METRICS_TYPE_LOGIBOARD
const FRONT_METRICS_TYPE_CALC = internal.FRONT_METRICS_TYPE_CALC
const FRONT_METRICS_TYPE_CROSS = internal.FRONT_METRICS_TYPE_CROSS
const FRONT_METRICS_TYPE_SUMMARY = internal.FRONT_METRICS_TYPE_SUMMARY
const FRONT_METRICS_TYPE_EXT_CROSS = internal.FRONT_METRICS_TYPE_EXT_CROSS
const FRONT_METRICS_TYPE_REFERENCE = internal.FRONT_METRICS_TYPE_REFERENCE

type FrontMetricsType = typeof FRONT_METRICS_TYPE_DIRECT_INPUT |
  typeof FRONT_METRICS_TYPE_LOGIMETER |
  typeof FRONT_METRICS_TYPE_LOGIBOARD |
  typeof FRONT_METRICS_TYPE_CALC |
  typeof FRONT_METRICS_TYPE_CROSS |
  typeof FRONT_METRICS_TYPE_SUMMARY |
  typeof FRONT_METRICS_TYPE_EXT_CROSS |
  typeof FRONT_METRICS_TYPE_REFERENCE

export type MetricsValueIndexRequestParameters = {
  start_dt: string | null
  end_dt: string | null
  name?: string | null
  logiscope_workplace_id?: number | null
  metrics_type?: MetricsType | null
  time_span?: TimeSpan | null
  page: number | null
}

type LegacyMetricsValueIndexRequestParameters = {
  start_dt: string | null
  end_dt: string | null
  name?: string | null
  logiscope_workplace_id?: number | null
  front_metrics_type?: FrontMetricsType | null
  time_span?: TimeSpan | null
  page: number | null
}

export const convertMetricsValueIndexRequestParametersToLegacy = (params: MetricsValueIndexRequestParameters): LegacyMetricsValueIndexRequestParameters => {
  const { metrics_type, ...newParams } = params
  return metrics_type
    ? { ...newParams, front_metrics_type: convertMetricsTypeToFrontMetricsType(metrics_type) }
    : { ...newParams }
}
