import { getCurrentInstance } from 'vue'
import { ReportLink } from 'src/models/new/Link/reportLink'
import reportLinkApi from 'src/apis/masters/reportLink'
import { useNotifiers } from 'src/composables/useNotifiers'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts'

export const useReportLinkOrderUpdater = () => {
  const vue = getCurrentInstance()!.proxy

  const {
    notifyError,
  } = useNotifiers()

  const updateReportLinkOrder = async(reportId: number, reportLinks: ReportLink[]): Promise<void> => {
    try {
      await reportLinkApi.bulkUpdateSequentialOrder(reportId, reportLinks)
      vue.$emit('updated')
      // 並び順の変更に関しては成功してもトーストを表示しない
    } catch (error: any) {
      const status = error.response.status
      if ([403, 404].includes(status)) {
        const message = '操作権限がありません。管理者にお問合せください。'
        notifyError(message, ERROR_GROUP_USER, error)
      } else if (status === 409) {
        const message = 'リンクの並び替えに失敗しました。リンクが編集されています。'
        notifyError(message, ERROR_GROUP_USER, error)
      } else {
        const message = 'リンクの並び替えに失敗しました。'
        notifyError(message, ERROR_GROUP_SYSTEM, error)
      }
      vue.$emit('failed')
    }
  }

  return {
    updateReportLinkOrder,
  }
}
