
import { computed, defineComponent, onMounted, PropType, reactive, watch } from 'vue';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INVALID,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND,
  ERROR_REASON_METRICS_NOT_FOUND,
  ERROR_REASON_NAME_NOT_UNIQUE,
} from 'src/consts';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import metricsApi from 'src/apis/masters/metrics';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import SelectMultipleMetricsOnMetricsForm from 'src/views/Dashboard/Settings/Metrics/components/shared/SelectMultipleMetricsOnMetricsForm.vue';
import MaxChainNotification from 'src/views/Dashboard/Settings/Metrics/components/shared/MaxChainNotification.vue';
import { requestTimeout } from 'src/util/request_animation_frame';
import { isRecordIdValid } from 'src/util/recordId';
import { BundledMetrics, BUNDLED_METRICS_MAX_METRICS_COUNT } from 'src/models/new/Metrics/bundledMetrics';
import { Metrics, metricsToTypeLocalWord, METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import BundledMetricsBaseForm from 'src/views/Dashboard/Settings/Metrics/components/BundledMetricsFormModal/BundledMetricsBaseForm.vue';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useValidator } from 'src/composables/useValidator';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useNotifiers } from 'src/composables/useNotifiers';

interface State {
  // 各種マスタデータのロード完了を示すフラグ
  isLoaded: boolean;
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showMetricsAccessGroupSelectForm: boolean;
  showTargetMetricsSelectForm: boolean;
  metrics: BundledMetrics;
  metricsIsNew: boolean;
  metricsTypeLocalWord: string;
  userSelectMetricsAccessGroups: Array<MetricsAccessGroup>;
  userSelectMetricsList: Metrics[];
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';
const CONFIRM_METRICS_EVENT_KEY = 'confirmSelectMetricsForm';

export default defineComponent({
  components: {
    SelectMultipleMetricsOnMetricsForm,
    SelectAccessGroupForm,
    MaxChainNotification,
    BundledMetricsBaseForm,
    InputError,
    MetricsInputErrors,
  },
  props: {
    value: {
      type: Object as PropType<BundledMetrics>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close'],
  setup(props, { emit }) {
    const state: State = reactive({
      isLoaded: false,
      isRequesting: false,
      showMetricsAccessGroupSelectForm: false,
      showTargetMetricsSelectForm: false,
      metrics: computed({
        get(): BundledMetrics {
          return props.value;
        },
        set(next: BundledMetrics) {
          emit('input', structuredClone(next));
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      metricsTypeLocalWord: computed(() => metricsToTypeLocalWord(state.metrics)),
      userSelectMetricsAccessGroups: computed({
        get(): Array<MetricsAccessGroup> {
          return metricsAccessGroupsOnMetricsWithoutAdmin.value;
        },
        set(value: Array<MetricsAccessGroup>) {
          metricsAccessGroupsOnMetrics.value = metricsAccessGroupOnMetricsWithAdmin.value
            ? [metricsAccessGroupOnMetricsWithAdmin.value, ...value]
            : value;
        },
      }),
      userSelectMetricsList: [],
    });

    const { notifyError } = useNotifiers();

    const {
      metricsAccessGroupsOnMetrics,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      metricsAccessGroupOnMetricsWithAdmin,
      loadMetricsAccessGroupsOnMetrics,
    } = useMetricsAccessGroupsOfMetrics();

    const { validationResult, ensureValidation, resetValidationResult } = useValidator();

    const setTargetMetricsList = async (): Promise<void> => {
      if (!state.metrics.targetMetricsIds || state.metrics.targetMetricsIds.length === 0) {
        return;
      }

      const params = { ids: state.metrics.targetMetricsIds, include_disabled: false, page: null };
      state.userSelectMetricsList = await metricsApi.indexByIds(params);
    };

    const goToAccessGroupSelectForm = (): void => {
      state.showMetricsAccessGroupSelectForm = true;
    };

    const goToTargetMetricsSelectForm = async (): Promise<void> => {
      if (!(await ensureValidation())) {
        return;
      }

      state.showTargetMetricsSelectForm = true;
    };
    const backToMainForm = (): void => {
      state.showMetricsAccessGroupSelectForm = false;
      state.showTargetMetricsSelectForm = false;
    };

    const setSelectedAccessGroups = (metricsAccessGroups: Array<MetricsAccessGroup>): void => {
      state.userSelectMetricsAccessGroups = metricsAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmAccessGroupEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerAccessGroupConfirmEvent = () => {
      confirmAccessGroupEventTriggerer.trigger();
    };

    const setSelectedMetrics = (metricsList: Metrics[]): void => {
      state.userSelectMetricsList = metricsList;
    };

    function resetSelectedMetrics(): void {
      state.userSelectMetricsList = [];
    }

    const { triggerer: confirmMetricsEventTriggerer } = useSimpleEvent(CONFIRM_METRICS_EVENT_KEY);

    const triggerMetricsConfirmEvent = () => {
      confirmMetricsEventTriggerer.trigger();
    };

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      if (!(await ensureValidation())) {
        return;
      }
      resetValidationResult();

      const metrics: BundledMetrics = {
        ...state.metrics,
        accessGroupIds: state.userSelectMetricsAccessGroups.map((metricsAccessGroup) => metricsAccessGroup.id),
      };
      const operationWord = state.metricsIsNew ? '作成' : '更新';
      try {
        if (state.metricsIsNew) {
          await metricsApi.create(metrics);
        } else {
          await metricsApi.update(metrics);
        }
        emit('updated', `${state.metrics.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = state.metricsIsNew ? 'ERR00001' : 'ERR00002';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          const msg = '選択したメトリクスは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したメトリクスグループは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND) {
          const msg = '選択した集計用センターは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) {
          notifyError(errRes.message, ERROR_GROUP_USER, err);
        } else if (errStatus === 409 && errRes.reason === ERROR_REASON_NAME_NOT_UNIQUE) {
          const msg = '入力されたメトリクス名は既に使用されています';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else {
          const msg = `メトリクスの${operationWord}に失敗しました。管理者に連絡してください。`;
          notifyError(msg, ERROR_GROUP_SYSTEM, err, errId);
        }
      }
    };

    const onSelectMetrics = async (metricsList: Metrics[]): Promise<void> => {
      if (metricsList.length === 0) {
        notifyError('メトリクスを選択してください');
        return;
      }

      if (
        state.metrics.isBorderless &&
        metricsList.every((metrics) => metrics.workplaceId === state.metrics.workplaceId)
      ) {
        notifyError('自集計センター以外のメトリクスが含まれていません');
        return;
      }

      setSelectedMetrics(metricsList);
      await save();
    };

    watch(
      () => [state.userSelectMetricsList],
      () => {
        state.metrics.targetMetricsIds =
          state.userSelectMetricsList.length > 0 ? state.userSelectMetricsList.map((metrics) => metrics.id) : [];
      },
    );

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsAccessGroupSelectForm = false;

      await Promise.all([setTargetMetricsList(), loadMetricsAccessGroupsOnMetrics(state.metrics)]);

      state.isLoaded = true;
    });

    return {
      props,
      state,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      BUNDLED_METRICS_MAX_METRICS_COUNT,
      goToAccessGroupSelectForm,
      goToTargetMetricsSelectForm,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerAccessGroupConfirmEvent,
      CONFIRM_METRICS_EVENT_KEY,
      triggerMetricsConfirmEvent,
      resetSelectedMetrics,
      onSelectMetrics,
      close,
      validationResult,
    };
  },
});
