import { render, staticRenderFns } from "./MetricsBarGraphForm.vue?vue&type=template&id=11989f78&scoped=true&"
import script from "./MetricsBarGraphForm.vue?vue&type=script&lang=ts&"
export * from "./MetricsBarGraphForm.vue?vue&type=script&lang=ts&"
import style0 from "../../style.scss?vue&type=style&index=0&id=11989f78&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11989f78",
  null
  
)

export default component.exports