
import { computed, defineComponent, PropType, reactive } from 'vue'
import { Icon, iconClasses } from 'src/util/icon'

const ICONS = Object.keys(iconClasses) as Icon[]

type State = {
  selectedIcon: Icon | null
}

export default defineComponent({
  props: {
    value: {
      type: String as PropType<Icon | null>,
      required: true,
    },
    onConfirmed: {
      type: Function as PropType<(icon: Icon | null) => void>,
      required: true,
    },
    onCanceled: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const state: State = reactive({
      selectedIcon: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
    })

    const selectIcon = (icon: Icon): void => {
      state.selectedIcon = icon
    }

    return {
      props,
      state,
      ICONS,
      selectIcon,
    }
  },
})
