
import { PropType, computed, defineComponent, reactive, watch } from 'vue';
import Chart from 'chart.js';
import BarLineChart from 'src/components/UIComponents/Charts/BarLineChart.vue';
import {
  addPluginForLineAnnotation,
  lineDatasetFromBarGraphItem,
  barDatasetFromBarGraphItem,
  CustomLineGraphDataDataset,
  CustomBarGraphDataDataset,
  CustomBarLineGraphOptions,
  HorizontalLineAnnotationOptions,
  buildHorizontalLineAnnotationOptions,
  getOptionForBarLineGraph,
  getCustomScaleLabelsForBarLineGraph,
} from 'src/util/chart';
import { ChartDataLabel, ChartScaleLabel } from 'src/components/UIComponents/Charts/shared';
import {
  MetricsTransitionGraphComponent,
  applyConditionalStatementToMetricsTransitionGraphComponentColor,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent';
import { orderFromGraphLayout } from 'src/business/graphLayout';
import { formatDate, rewindDate } from 'src/util/datetime';
import { deepMerge } from 'src/util/object';

type State = {
  title: string;
  labels: ChartDataLabel[];
  datasets: Array<CustomBarGraphDataDataset | CustomLineGraphDataDataset>;
  options: CustomBarLineGraphOptions | (CustomBarLineGraphOptions & HorizontalLineAnnotationOptions);
  plugins: Chart.PluginServiceRegistrationOptions[];
  scaleLabels: ChartScaleLabel[];
  height: string;
};

export default defineComponent({
  components: {
    BarLineChart,
  },
  props: {
    component: {
      type: Object as PropType<MetricsTransitionGraphComponent>,
      required: true,
    },
  },
  setup(props) {
    const generateDatasets = (
      component: MetricsTransitionGraphComponent,
    ): Array<CustomBarGraphDataDataset | CustomLineGraphDataDataset> => {
      return component.data.graphs
        .filter((el) => el.metrics)
        .sort((a, b) => {
          return orderFromGraphLayout(a) - orderFromGraphLayout(b);
        })
        .map((graph, index) => {
          const graphItems = graph.plots!.map((plot, index) => {
            const plotOrder = index + 1;
            const overrideHashedColor = applyConditionalStatementToMetricsTransitionGraphComponentColor(
              component,
              graph,
              plotOrder,
            );
            return {
              value: plot.metrics.value,
              decimalPlaces: plot.metrics.decimalPlaces,
              scaling: plot.metrics.scaling,
              color: overrideHashedColor ? (overrideHashedColor as string) : graph.color,
              originalColor: graph.color,
              // optionsで上書きされ実際には使用されないが、型の都合上ダミー値をセットしている
              // See also: src/assets/src/util/Chart/custom/shared.ts
              label: '',
            };
          });
          const settings = {
            order: index + 1,
            label: graph.label || graph.metrics!.name,
            scale: graph.scale,
          };
          return graph.plotType === 'line'
            ? lineDatasetFromBarGraphItem(graphItems, settings)
            : barDatasetFromBarGraphItem(graphItems, settings);
        });
    };

    const state: State = reactive({
      title: computed(() => props.component.name),
      labels: computed(() => generateLabels(props.component)),
      // データセットは算出プロパティで渡してはならず、onMounted内ではなくsetup内で代入する必要がある
      // setup内で値が与えられない場合グラフがレンダリングされず、算出プロパティを与えると内部的に書き換えが発生して無限ループに陥る
      // オブジェクトは引き継いでいないはずだが、詳しい原因は不明
      datasets: generateDatasets(props.component),
      options: computed(() => generateOptions(props.component)),
      plugins: computed(() => generatePlugins(props.component)),
      scaleLabels: computed(() => generateScaleLabels(props.component)),
      // コンポーネントの1行あたりの高さが100pxで複数行の場合は間に10pxのマージンが入る
      // 行間のマージン1つ分、2行分のタイトルの高さ、タイトル下のマージン、カードのパディング合計との差をグラフの高さとする
      height: computed(() => `${110 * props.component.height - 110}px`),
    });

    const generateLabels = (component: MetricsTransitionGraphComponent): ChartDataLabel[] => {
      const orderedClosingDates = !component.isDescendingDateOrder
        ? component.closingDates
        : [...component.closingDates].reverse();
      return orderedClosingDates.map((el) => {
        const date = component.dispDaysRewindPeriod
          ? rewindDate(el, component.dispDaysRewindPeriod?.value, component.dispDaysRewindPeriod?.unit)
          : el;
        return formatDate(date, component.dateFormat);
      });
    };

    const generateOptions = (
      component: MetricsTransitionGraphComponent,
    ): CustomBarLineGraphOptions | (CustomBarLineGraphOptions & HorizontalLineAnnotationOptions) => {
      const options = getOptionForBarLineGraph();
      if (component.data.reference) {
        const verticalLineAnnotationOptions = buildHorizontalLineAnnotationOptions(
          component.data.reference.metrics.value,
          component.data.reference.metrics.decimalPlaces,
          component.data.reference.metrics.scaling,
          component.data.reference.label,
          component.data.reference.color,
        );
        return deepMerge(options, verticalLineAnnotationOptions);
      } else {
        return options;
      }
    };

    const generatePlugins = (_: MetricsTransitionGraphComponent): Chart.PluginServiceRegistrationOptions[] => {
      return addPluginForLineAnnotation([]);
    };

    const generateScaleLabels = (component: MetricsTransitionGraphComponent): ChartScaleLabel[] => {
      return getCustomScaleLabelsForBarLineGraph(component.scaleUnits.left, component.scaleUnits.right);
    };

    watch(
      () => props.component,
      (component) => {
        state.datasets = generateDatasets(component);
      },
    );

    return {
      state,
      props,
    };
  },
});
