import axios from 'axios';
import { Report } from 'src/models/new/report';
import { ReportAccessGroup } from 'src/models/new/AccessGroup/reportAccessGroup';
import { ReportAccessGroupIndexRequestParameters } from 'src/models/api/AccessGroup/ReportAccessGroup/reportAccessGroupIndexRequestParameters';
import {
  convertFromReportAccessGroupResponseData,
  ReportAccessGroupResponseData,
} from 'src/models/api/AccessGroup/ReportAccessGroup/reportAccessGroupResponseData';
import { convertToReportAccessGroupCreateOrUpdateResponse } from 'src/models/api/AccessGroup/ReportAccessGroup/reportAccessGroupCreateOrUpdateRequestParameters';
import { buildBackendUrl } from 'src/apis/util/url';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (): string => '/masters/report_access_groups';

export default {
  async index(data: ReportAccessGroupIndexRequestParameters): Promise<{ result: ReportAccessGroup[] }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<MultiDataResponse<ReportAccessGroupResponseData>>(url, {
      params: data,
    });
    return {
      result: resData.result.map(convertFromReportAccessGroupResponseData),
    };
  },
  async byReport(report: Report): Promise<ReportAccessGroup[]> {
    const url = buildBackendUrl(buildBasePath(), 'by_report', report.id);
    const { data: resData } = await axios.get(url);
    return (resData as ReportAccessGroupResponseData[]).map(convertFromReportAccessGroupResponseData);
  },
  async create(accessGroup: ReportAccessGroup): Promise<{}> {
    const url = buildBackendUrl(buildBasePath());
    await axios.post(url, convertToReportAccessGroupCreateOrUpdateResponse(accessGroup));
    return {};
  },
  async update(id: number, accessGroup: ReportAccessGroup): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), id);
    await axios.put(url, convertToReportAccessGroupCreateOrUpdateResponse(accessGroup));
    return {};
  },
  async destroy(id: number): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), id);
    await axios.delete(url);
    return {};
  },
  async findBySearchId(searchId: string): Promise<ReportAccessGroup> {
    const url = buildBackendUrl(buildBasePath(), 'find_by_search_id', searchId);
    const { data: resData } = await axios.get<ReportAccessGroupResponseData>(url);

    return convertFromReportAccessGroupResponseData(resData);
  },
};
