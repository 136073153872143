import { ComponentResponseData, extractLastSearchedDate } from 'src/models/api/Report/Component/componentResponseData';
import { TIME_SPAN_DAILY, TimeSpan, isTimeSpanLarger } from 'src/business/timeSpan';
import { ComponentType, internal as componentInternal } from 'src/models/new/component';
import { convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData';
import { buildConditionalStatementProperties } from 'src/models/api/Report/Component/propertyBuilders/buildConditionalStatementProperties';
import { buildBaseComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildBaseComponentProperties';
import { formatDate } from 'src/util/datetime';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import {
  MetricsBarGraphBarElement,
  MetricsBarGraphComponent,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';

const METRICS_GRAPH_BAR_GRAPH = componentInternal.METRICS_GRAPH_BAR_GRAPH;

const searchDominantTimeSpan = (data: ComponentResponseData): TimeSpan => {
  // ビジネスロジック上基準となるTimeSpamを持つべきだが
  // そのフィールドがないためリストに存在する参考値でないメトリクスの最も大きな周期を検索している
  return data.graph_component!.metrics_graph_components.reduce((largestTimeSpan: TimeSpan, el) => {
    return isTimeSpanLarger(el.metrics.time_span, largestTimeSpan) ? el.metrics.time_span : largestTimeSpan;
  }, TIME_SPAN_DAILY);
};

const buildBarElements = (data: ComponentResponseData, closingDate: Date | null): MetricsBarGraphBarElement[] => {
  const getValueOfMetrics = (
    obj: { metrics_id: number; metrics: { time_span: string } },
    closingDate: Date,
  ): string | number | null => {
    return (
      data.graph_component!.metrics_values_idx_dt[formatDate(closingDate, SYSTEM_DATE_FORMAT)]?.find(
        (el) => el.metrics_id === obj.metrics_id,
      )?.value ?? null
    );
  };
  return data
    .graph_component!.metrics_graph_components.sort((a, b) => a.registration_order - b.registration_order)
    .map((el, index) => {
      const metrics = convertFromMetricsResponseData(el.metrics, {});
      if (closingDate) {
        const valueOfMetrics = getValueOfMetrics(el, closingDate);
        metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;
        metrics.closingDate = closingDate;
      }
      const bar = {
        alias: el.disp_name,
        sequentialOrder: index + 1,
        color: el.disp_color,
        metrics,
        conditionalStatements: data.component_conditional_statements
          .filter((statement) => {
            return statement.column_number === el.registration_order;
          })
          .map((statement) =>
            buildConditionalStatementProperties(statement, data.graph_component!.metrics_value_searched_dates_map),
          ),
      };
      return bar;
    });
};

export const buildMetricsBarGraphComponentProperties = (
  data: ComponentResponseData,
  referenceDate: Date | null,
): MetricsBarGraphComponent => {
  const componentType: ComponentType = METRICS_GRAPH_BAR_GRAPH;
  const dominantTimeSpan = searchDominantTimeSpan(data);

  const closingDateMap = data.graph_component!.metrics_value_searched_dates_map ?? null;
  const closingDate = closingDateMap ? extractLastSearchedDate(closingDateMap, dominantTimeSpan) : null;

  const bars = buildBarElements(data, closingDate);

  const referenceMetricsData = data.graph_component!.sub_metrics;
  const referenceMetrics = referenceMetricsData ? convertFromMetricsResponseData(referenceMetricsData, {}) : null;

  if (referenceMetrics && closingDateMap) {
    const closingDate = extractLastSearchedDate(closingDateMap, referenceMetrics.timeSpan);
    referenceMetrics.closingDate = closingDate;
    const valueOfMetrics =
      data.graph_component!.metrics_values_idx_dt[formatDate(closingDate, SYSTEM_DATE_FORMAT)]?.find(
        (el) => el.metrics_id === referenceMetrics.id,
      )?.value ?? null;
    referenceMetrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;
  }

  const reference = referenceMetrics
    ? {
        label: data.graph_component!.sub_disp_name,
        color: data.graph_component!.sub_disp_color!,
        metrics: referenceMetrics,
        referenceDate: referenceDate,
        value: referenceMetrics?.value ?? null,
      }
    : null;

  return {
    ...buildBaseComponentProperties(data, componentType),
    referenceDate: referenceDate,
    unit: data.graph_component!.unit_disp_name1,
    data: {
      reference: reference,
      bars,
    },
  };
};
