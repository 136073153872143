
import Vue, { defineComponent, getCurrentInstance, onBeforeUnmount, reactive } from 'vue'
import mitt from 'src/util/mitt'
import { isGuestPage } from 'src/hooks/routeHook'
import { setupAxios } from 'src/hooks/axiosHook'
import { loadUserAndMasters } from 'src/hooks/masterHook'
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'

interface UpdateShowSideBarEvent {
  showSidebar: boolean
}

export default defineComponent({
  name: 'app',
  setup() {
    const root = getCurrentInstance()!.proxy
    const state = reactive({
      showSidebar: true,
    })
    const sidebarEventBus = mitt()
    const updateShowSidebarFunc = (obj?: UpdateShowSideBarEvent) => {
      if (!obj) { return }
      state.showSidebar = obj.showSidebar
    }
    sidebarEventBus.on<UpdateShowSideBarEvent>('updateShowSidebar', updateShowSidebarFunc)
    Vue.prototype.$sidebarEventBus = sidebarEventBus

    setupAxios(root)
    loadUserAndMasters(root)

    if (!isGuestPage(root.$route.name)) {
      root.$store.dispatch('user/getMe')
        .then(async(user) => {
          // logiscope利用不可ユーザーの場合はログアウト
          if (!user.can_use_logiscope) {
            await root.$router.push({ name: 'Login' })
          }
        })
        .catch(() => {
          root.$router.push({ name: 'Login' })
        })
    }

    onBeforeUnmount(() => {
      sidebarEventBus.off<UpdateShowSideBarEvent>('updateShowSidebar', updateShowSidebarFunc)
    })

    return {
      state,
    }
  },
})
