import axios from 'axios';
import { MasterCsvJobStatus } from 'src/models/masterCsvJobStatus';
import { buildBackendUrl } from 'src/apis/util/url';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';
import { MasterCsvJobStatusIndexRequestParameters } from 'src/models/api/MasterCsvJobStatus/masterCsvJobStatusIndexRequestParameters';
import {
  convertMasterCsvJobStatusResponseData,
  MasterCsvJobStatusResponseData,
} from 'src/models/api/MasterCsvJobStatus/masterCsvJobStatusResponseData';
import { PaginatedMultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (): string => '/master_csv_job_statuses';

export default {
  async index(
    data: MasterCsvJobStatusIndexRequestParameters,
  ): Promise<{ result: MasterCsvJobStatus[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<MasterCsvJobStatusResponseData>>(url, {
      params: data,
    });
    return {
      result: resData.result.map(convertMasterCsvJobStatusResponseData),
      pagination: convertFromPagination(resData.pagination),
    };
  },
  async download(id: number): Promise<string> {
    const url = buildBackendUrl(buildBasePath(), id, 'download_file');
    const { data: resData } = await axios.get(url);
    return resData;
  },
};
