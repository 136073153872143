import axios from 'axios';
import { convertToCompanyViewSettingUpdateRequestParameters } from 'src/models/api/companyViewSettingUpdateRequestParameters';
import { convertFromCompanyViewSettingResponse } from 'src/models/api/companyViewSettingResponse';
import { CompanyViewSetting } from 'src/models/new/companyViewSetting';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/company_view_setting';

export default {
  async show(): Promise<CompanyViewSetting> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return convertFromCompanyViewSettingResponse(resData);
  },
  async update(data: CompanyViewSetting): Promise<Object> {
    const params = convertToCompanyViewSettingUpdateRequestParameters(data);
    const url = buildBackendUrl(buildBasePath());
    await axios.put(url, params);
    return {};
  },
};
