import { ComponentResponseData, extractLastSearchedDate } from 'src/models/api/Report/Component/componentResponseData';
import { TIME_SPAN_DAILY, TimeSpan, isTimeSpanLarger } from 'src/business/timeSpan';
import { ComponentType, internal as componentInternal } from 'src/models/new/component';
import { convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData';
import { buildConditionalStatementProperties } from 'src/models/api/Report/Component/propertyBuilders/buildConditionalStatementProperties';
import { buildBaseComponentProperties } from 'src/models/api/Report/Component/propertyBuilders/buildBaseComponentProperties';
import { formatDate } from 'src/util/datetime';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import {
  MetricsGroupedGraphComponent,
  constructEmptyMetricsGroupedGraphComponent,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import {
  GRAPH_LAYOUT_SCALE_POSITION_LEFT,
  GRAPH_LAYOUT_SCALE_POSITION_RIGHT,
  GraphLayoutScalePosition,
} from 'src/business/graphLayout';

const METRICS_GRAPH_GROUPED_GRAPH = componentInternal.METRICS_GRAPH_GROUPED_GRAPH;

const searchDominantTimeSpan = (data: ComponentResponseData): TimeSpan => {
  // ビジネスロジック上基準となるTimeSpamを持つべきだが
  // そのフィールドがないためリストに存在する参考値でないメトリクスの最も大きな周期を検索している
  return data.graph_component!.metrics_graph_components.reduce((largestTimeSpan: TimeSpan, el) => {
    return isTimeSpanLarger(el.metrics.time_span, largestTimeSpan) ? el.metrics.time_span : largestTimeSpan;
  }, TIME_SPAN_DAILY);
};

const computeScalePositionFromGraphNo = (graphNo: string): GraphLayoutScalePosition => {
  if (graphNo === 'l1' || graphNo === 'l2') {
    return GRAPH_LAYOUT_SCALE_POSITION_LEFT;
  }
  if (graphNo === 'r1' || graphNo === 'r2') {
    return GRAPH_LAYOUT_SCALE_POSITION_RIGHT;
  }
  throw new Error(`Invalid graphNo: ${graphNo}`);
};

const computeNumberFromGraphNo = (graphNo: string): number => {
  if (graphNo === 'l1' || graphNo === 'r1') {
    return 1;
  }
  if (graphNo === 'l2' || graphNo === 'r2') {
    return 2;
  }
  throw new Error(`Invalid graphNo: ${graphNo}`);
};

export const buildMetricsGroupedGraphComponentProperties = (
  data: ComponentResponseData,
  referenceDate: Date | null,
): MetricsGroupedGraphComponent => {
  const componentType: ComponentType = METRICS_GRAPH_GROUPED_GRAPH;
  const dominantTimeSpan = searchDominantTimeSpan(data);

  const closingDateMap = data.graph_component!.metrics_value_searched_dates_map ?? null;
  const closingDate = closingDateMap ? extractLastSearchedDate(closingDateMap, dominantTimeSpan) : null;

  const metricsGroupedGraphs = constructEmptyMetricsGroupedGraphComponent().data.graphs;

  metricsGroupedGraphs.forEach((graph) => {
    const targetData = data
      .graph_component!.metrics_graph_components.filter((el) => {
        return (
          computeScalePositionFromGraphNo(el.graph_no) === graph.scale &&
          computeNumberFromGraphNo(el.graph_no) === graph.number
        );
      })
      .sort((a, b) => a.registration_order - b.registration_order);

    const statementRowNumber = graph.scale === GRAPH_LAYOUT_SCALE_POSITION_LEFT ? graph.number : graph.number + 2;

    const plots = targetData.map((el) => {
      const group = el.registration_order;

      const metrics = convertFromMetricsResponseData(el.metrics, {});
      if (closingDate) {
        metrics.closingDate = closingDate;
        const valueOfMetrics =
          data.graph_component!.metrics_values_idx_dt[formatDate(closingDate, SYSTEM_DATE_FORMAT)]?.find(
            (el) => el.metrics_id === metrics.id,
          )?.value ?? null;
        metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;
      }

      return {
        group,
        label: el.disp_name,
        color: el.disp_color,
        metrics: metrics,
        conditionalStatements: data.component_conditional_statements
          .filter((statement) => {
            return statement.row_number === statementRowNumber && statement.column_number === group;
          })
          .map((statement) => buildConditionalStatementProperties(statement, closingDateMap)),
      };
    });

    graph.plots = plots;

    if (graph.scale === 'left' && graph.number === 1) {
      graph.label = data.graph_component!.legend_name_l1;
    } else if (graph.scale === 'left' && graph.number === 2) {
      graph.label = data.graph_component!.legend_name_l2;
    } else if (graph.scale === 'right' && graph.number === 1) {
      graph.label = data.graph_component!.legend_name_r1;
    } else if (graph.scale === 'right' && graph.number === 2) {
      graph.label = data.graph_component!.legend_name_r2;
    }

    if (graph.scale === 'left') {
      graph.plotType = data.graph_component!.graph_type1 as 'bar' | 'line';
    } else if (graph.scale === 'right') {
      graph.plotType = data.graph_component!.graph_type2 as 'bar' | 'line';
    }
  });

  const referenceMetricsData = data.graph_component!.sub_metrics;
  const referenceMetrics = referenceMetricsData ? convertFromMetricsResponseData(referenceMetricsData, {}) : null;

  if (referenceMetrics && closingDateMap) {
    const closingDate = extractLastSearchedDate(closingDateMap, referenceMetrics.timeSpan);
    referenceMetrics.closingDate = closingDate;
    const valueOfMetrics =
      data.graph_component!.metrics_values_idx_dt[formatDate(closingDate, SYSTEM_DATE_FORMAT)]?.find(
        (el) => el.metrics_id === referenceMetrics.id,
      )?.value ?? null;
    referenceMetrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null;
  }

  const reference = referenceMetrics
    ? {
        label: data.graph_component!.sub_disp_name,
        color: data.graph_component!.sub_disp_color!,
        metrics: referenceMetrics,
        referenceDate,
        value: referenceMetrics?.value ?? null,
      }
    : null;

  return {
    ...buildBaseComponentProperties(data, componentType),
    referenceDate,
    data: {
      graphs: metricsGroupedGraphs,
      reference,
    },
    scaleUnits: {
      left: data.graph_component!.unit_disp_name1,
      right: data.graph_component!.unit_disp_name2,
    },
  };
};
