export const OPERATOR_PLUS = '+'
export const OPERATOR_MINUS = '-'
export const OPERATOR_MULTIPLY = '*'
export const OPERATOR_DIVIDE = '/'

export type Operator = typeof OPERATOR_PLUS | typeof OPERATOR_MINUS | typeof OPERATOR_MULTIPLY | typeof OPERATOR_DIVIDE

type OperatorsType = Operator[]
export const OPERATORS: OperatorsType = [OPERATOR_PLUS, OPERATOR_MINUS, OPERATOR_MULTIPLY, OPERATOR_DIVIDE]

// 現在フロント側でオペレータ文字列型を使って計算をすることはないが、必要なら以下実装する
