
import { computed, defineComponent, PropType, reactive } from 'vue'
import { Link } from 'src/models/new/link'
import { useValidator } from 'src/composables/useValidator'
import InputError from 'src/components/InputError.vue'

type State = {
  reportLink: Link
}

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<Link>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const {
      validations,
      setValidation,
      validationResult,
    } = useValidator()
    setValidation({
      name: { required: true, max: 20 },
      url: { required: true, max: 2048, safeUrl: true },
    })

    const state: State = reactive({
      reportLink: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', { ...value })
        },
      }),
    })

    return {
      props,
      state,
      validations,
      validationResult,
    }
  },
})
