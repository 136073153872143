import { Component, internal } from 'src/models/new/component'
import { MetricsComponent } from 'src/models/new/Component/metricsComponent'
import { Metrics } from 'src/models/new/metrics'

const METRICS_GRAPH = internal.METRICS_GRAPH
const METRICS_GRAPH_PIE_CHART = internal.METRICS_GRAPH_PIE_CHART
const METRICS_GRAPH_BAR_GRAPH = internal.METRICS_GRAPH_BAR_GRAPH
const METRICS_GRAPH_GROUPED_GRAPH = internal.METRICS_GRAPH_GROUPED_GRAPH
const METRICS_GRAPH_TRANSITION_GRAPH = internal.METRICS_GRAPH_TRANSITION_GRAPH

export type MetricsGraphComponent = MetricsComponent

export const isComponentTypeMetricsGraph = (component: Component): component is MetricsGraphComponent => {
  return component.componentType.split('.')[0] === METRICS_GRAPH
}

// メトリクスグラフコンポーネントのみコンポーネントタイプを直接利用する必要があるため、例外的に公開する
export const COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART = METRICS_GRAPH_PIE_CHART
export const COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH = METRICS_GRAPH_BAR_GRAPH
export const COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH = METRICS_GRAPH_GROUPED_GRAPH
export const COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH = METRICS_GRAPH_TRANSITION_GRAPH

export const METRICS_GRAPH_COMPONENT_MIN_WIDTH = 1
export const METRICS_GRAPH_COMPONENT_MAX_WIDTH = 6
export const METRICS_GRAPH_COMPONENT_MIN_HEIGHT = 2
export const METRICS_GRAPH_COMPONENT_MAX_HEIGHT = 4

/**
 * メトリクスグラフコンポーネントのベース
 * 実際には、この型を実在のデータとして直接扱うことはないが、グラフタイプが確定する前の型として利用してよい
 */
export const constructEmptyMetricsGraphComponent = (): MetricsGraphComponent => {
  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_GRAPH,
    abscissa: 0,
    ordinate: 0,
    width: METRICS_GRAPH_COMPONENT_MIN_WIDTH,
    height: METRICS_GRAPH_COMPONENT_MIN_HEIGHT,
    referenceDate: null,
    name: '',
  }
}

// プロット系のグラフ内にボーダーラインを表示する場合に使用可能な参考値オブジェクトの型
// 用いられる箇所については各コンポーネントによって異なるが、型は共通とする
export type MetricsGraphReferenceForBorderLine = {
  label: string | null
  color: string
  metrics: Metrics
  // 使わなくなった可能性がある
  referenceDate: Date | null
  value: number | null
}
