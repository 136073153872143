
import { computed, defineComponent, PropType, reactive } from 'vue'
import { ComponentType } from 'src/models/new/component'
import {
  COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART,
  COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH,
  COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH,
  COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH,
} from 'src/models/new/Component/MetricsComponent/metricsGraphComponent'

interface State {
  componentType: ComponentType | null
}

export default defineComponent({
  // v-modelでコンポーネントタイプ(metrics_graph.***)のみ受け取る
  // グラフコンポーネントの編集モーダルは、変更前とコンポーネントタイプが異なる場合は全く新規のコンポーネントを編集対象とし。
  // 変更前とコンポーネントタイプが等しい場合は選択されたコンポーネントモデルを編集する仕様になっている
  // そのため、このフォームではコンポーネントモデルを受け取って直接編集してはならない
  props: {
    value: {
      type: String as PropType<ComponentType | null>,
      default: null,
    },
    onChanged: {
      type: Function as PropType<(componentType: ComponentType) => void>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const state: State = reactive({
      componentType: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
    })

    const setComponentType = (componentType: ComponentType): void => {
      state.componentType = componentType
      props.onChanged(componentType)
    }

    return {
      state,
      props,
      COMPONENT_TYPE_METRICS_GRAPH_PIE_CHART,
      COMPONENT_TYPE_METRICS_GRAPH_BAR_GRAPH,
      COMPONENT_TYPE_METRICS_GRAPH_GROUPED_GRAPH,
      COMPONENT_TYPE_METRICS_GRAPH_TRANSITION_GRAPH,
      setComponentType,
    }
  },
})
