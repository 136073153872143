
import { PropType, computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue'
import { UpdateWordTarget, UpdateWordTargetCategoryIdentifier } from '../logics/UpdateWordTarget'
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate'
import InputError from 'src/components/InputError.vue'

type State = {
  isReady: boolean

  targetWord: string
  alternativeWord: string

  validations: Record<string, Object>
  hasError: boolean
}

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    updateTargets: {
      type: Array as PropType<UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[]>,
      required: true,
    },
    onReplaced: {
      type: Function as PropType<(updateTargets: UpdateWordTarget<UpdateWordTargetCategoryIdentifier>[]) => void>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      isReady: false,

      targetWord: '',
      alternativeWord: '',

      validations: {
        targetWord: { required: true },
      },
      hasError: computed(() => vvHasError(root)),
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)

    const replace = async(): Promise<void> => {
      if (!(await vvValidate(root))) { return }

      const replacedUpdateTargets = props.updateTargets.map((updateTarget) => {
        const originalValue = updateTarget.originalValue ?? ''
        const replacedValue = originalValue.replace(state.targetWord, state.alternativeWord)
        // 置換による変更が発生しなかった場合、updateValueはnullとする
        // 1度置換を実行した後に別のキーワードで置換を実行した場合、後の処理で置換が行われなかったケースで
        // updateValueをnullに戻す意図もある
        const updateValue = replacedValue === originalValue ? null : replacedValue

        return { ...updateTarget, updateValue }
      })
      props.onReplaced(replacedUpdateTargets)
    }

    const close = (): void => {
      emit('close')
    }

    onMounted(async() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false

      state.targetWord = ''
      state.alternativeWord = ''

      state.isReady = true
    })

    return {
      props,
      state,
      getError,
      getErrorObject,
      replace,
      close,
    }
  },
})
