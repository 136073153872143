import {
  AggregateFunction,
  AGGREGATE_FUNCTION_MEDIAN,
  AGGREGATE_FUNCTION_LOCAL_WORDS,
  AGGREGATE_FUNCTION_COUNT,
  AGGREGATE_FUNCTION_SUM,
  AGGREGATE_FUNCTION_AVG,
  AGGREGATE_FUNCTION_MIN,
  AGGREGATE_FUNCTION_MAX,
} from 'src/business/aggregateFunction'
import { BasicMetrics } from '../basicMetrics'

export const LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS = 40

/**
 * Query parameter value always treated as a string though it is numeric
 * in most of the cases, like "10", because the value will be handled as model id
 * of Logi-system application by the system.
 * Do not cast this into number.
 * To obtain real value compatible with the id, ask the system for conversion list.
 */
export type LogiSystemDataQueryParameterValue = string
export type LogiSystemDataMetricsQueryParameter = {
  parameterId: number
  value: LogiSystemDataQueryParameterValue
}
export type LogiSystemDataMetricsAggregationFunction = Exclude<AggregateFunction, typeof AGGREGATE_FUNCTION_MEDIAN>
export type LogiSystemDataMetrics = BasicMetrics & {
  logiDataSourceId: number
  aggrFunc: LogiSystemDataMetricsAggregationFunction
  queryParameters: LogiSystemDataMetricsQueryParameter[]
  budgetGroupId: number
}

export const LOGI_SYSTEM_DATA_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS: Record<AggregateFunction, string> = Object.fromEntries(
  Object.keys(AGGREGATE_FUNCTION_LOCAL_WORDS).filter(el => {
    return [
      AGGREGATE_FUNCTION_SUM,
      AGGREGATE_FUNCTION_AVG,
      AGGREGATE_FUNCTION_MIN,
      AGGREGATE_FUNCTION_MAX,
      AGGREGATE_FUNCTION_COUNT,
    ].includes(el)
  }).map(el => {
    if (el === AGGREGATE_FUNCTION_COUNT) return [el as AggregateFunction, 'カウント']
    return [el as AggregateFunction, AGGREGATE_FUNCTION_LOCAL_WORDS[el as AggregateFunction]]
  }),
) as Record<AggregateFunction, string>
