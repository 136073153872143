
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue';
import { vvGetError, vvGetErrorObject, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { omitString } from 'src/util/text_decorator';
import SelectSingleMetricsForm from 'src/components/NewSelectItemForm/SelectSingleMetricsForm.vue';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { timeSpanToLocalWord } from 'src/business/timeSpan';
import { useWorkplaceOptions } from 'src/composables/options/useWorkplaceOptions';
import {
  MetricsCardComponent,
  MetricsCardComponentWithEmptyMetrics,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import { Metrics } from 'src/models/new/metrics';
import componentApi from 'src/apis/masters/component';
import { notifySuccess1 } from 'src/hooks/notificationHook';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INCLUDED_FORBIDDEN_METRICS,
  ERROR_REASON_METRICS_NOT_FOUND,
} from 'src/consts';
import { Section } from 'src/models/new/section';
import { getBottomOrdinate, isOverBoundary, isOverlappedWithOther } from 'src/models/new/component';
import { isRecordIdMeansNotPersisted } from 'src/util/recordId';
import InputError from 'src/components/InputError.vue';

type CardDataElement = 'metrics' | 'subMetrics';

interface State {
  component: MetricsCardComponent | MetricsCardComponentWithEmptyMetrics;
  isComponentNew: boolean;
  hasError: boolean;
  isLoaded: boolean;
  validations: Record<string, object>;
  showMetricsSelectionForm: boolean;
  selectedCardDataElement: CardDataElement | null;
  modalTitle: string;
  userSelectMetrics: Metrics | null;
}

const CONFIRM_METRICS_EVENT_KEY = 'confirmSelectMetricsForm';

export default defineComponent({
  components: {
    SelectSingleMetricsForm,
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<MetricsCardComponent | MetricsCardComponentWithEmptyMetrics>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
  },
  emits: ['input', 'close', 'updated', 'shouldReport'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isReady: false,
      component: computed({
        get() {
          return props.value;
        },
        set(value) {
          emit('input', value);
        },
      }),
      isComponentNew: computed(() => isRecordIdMeansNotPersisted(state.component.id)),
      hasError: computed(() => vvHasError(root)),
      isLoaded: false,
      validations: {
        metricsId: { required: true },
        name: { required: true, max: 50 },
        subMetricsName: { required: false, max: 10 },
        unit: { required: false, max: 10 },
      },
      showMetricsSelectionForm: false,
      selectedCardDataElement: null,
      modalTitle: computed(() => {
        if (state.showMetricsSelectionForm) {
          return 'メトリクスの選択';
        }
        return `カードの${state.isComponentNew ? '追加' : '編集'}`;
      }),
      MetricsId: computed({
        get() {
          return state.component.metrics?.id ?? null;
        },
        set(_) {
          // バリデーションのためにv-modelへセットしているためセッターが呼ばれる可能性があるが、変更はかけない
        },
      }),
      userSelectMetrics: computed({
        get(): Metrics | null {
          if (state.selectedCardDataElement === 'metrics' && state.component.metrics !== null) {
            return state.component.metrics;
          }
          if (state.selectedCardDataElement === 'subMetrics' && state.component.subMetrics !== null) {
            return state.component.subMetrics;
          }
          return null;
        },
        set(metrics: Metrics | null) {
          if (state.selectedCardDataElement === 'metrics') {
            state.component.metrics = metrics;
          } else if (state.selectedCardDataElement === 'subMetrics') {
            state.component.subMetrics = metrics;
          }
        },
      }),
    });

    const { options: workplaceOptions } = useWorkplaceOptions();

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    const clearErrors = (): void => {
      vvReset(root);
    };

    async function openMetricsSelectionModal(targetElement: CardDataElement): Promise<void> {
      state.selectedCardDataElement = targetElement;
      state.showMetricsSelectionForm = true;
    }

    function closeMetricsSelectionForm(): void {
      clearErrors();
      state.selectedCardDataElement = null;
      state.showMetricsSelectionForm = false;
    }

    async function setSelectedMetrics(metrics: Metrics): Promise<void> {
      state.userSelectMetrics = metrics;
      closeMetricsSelectionForm();
    }

    const { triggerer: confirmEventTriggerer } = useSimpleEvent(CONFIRM_METRICS_EVENT_KEY);

    const triggerConfirmEvent = () => {
      confirmEventTriggerer.trigger();
    };

    const submit = async (): Promise<void> => {
      if (!(await vvValidate(root))) {
        return;
      }

      // 配置場所が重なる場合や新しく生成した場合は一番下に配置する
      if (
        isOverlappedWithOther(state.component, props.section) ||
        isOverBoundary(state.component) ||
        !state.component.id
      ) {
        state.component.ordinate = getBottomOrdinate(props.section);
      }
      const component = {
        ...state.component,
        sectionId: props.section.id,
      };
      const operationWord = state.isComponentNew ? '追加' : '更新';
      try {
        if (state.isComponentNew) {
          const { id } = await componentApi.create(props.reportId, component);
          component.id = id;
        } else {
          await componentApi.update(props.reportId, component);
        }
        state.component = structuredClone(component);
        emit('updated');
        notifySuccess1(root, `コンポーネントを${operationWord}しました。`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 403) {
          if (errRes.reason === ERROR_REASON_INCLUDED_FORBIDDEN_METRICS) {
            emit('shouldReport', ERROR_GROUP_USER, errRes.message, err);
          } else {
            const msg = 'アクセスする権限がありません。管理者にお問合せください。';
            emit('shouldReport', ERROR_GROUP_USER, msg, err);
          }
        } else if (errStatus === 404) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          emit(
            'shouldReport',
            ERROR_GROUP_USER,
            '選択したメトリクスは存在しません。ページを更新し、再度お試しください。',
            err,
          );
        } else if (errStatus === 409) {
          const msg = 'コンポーネントの追加に失敗しました。レポートが編集されています。';
          emit('shouldReport', ERROR_GROUP_USER, msg, err);
        } else {
          const msg = `コンポーネントの${operationWord}に失敗しました。管理者に連絡してください。`;
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err);
        }
      }
    };

    const close = (): void => {
      emit('close');
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsSelectionForm = false;
      state.isLoaded = true;
    });

    return {
      state,
      workplaceOptions,
      props,
      getError,
      getErrorObject,
      omitString,
      openMetricsSelectionModal,
      setSelectedMetrics,
      closeMetricsSelectionForm,
      CONFIRM_METRICS_EVENT_KEY,
      triggerConfirmEvent,
      submit,
      close,
      timeSpanToLocalWord,
    };
  },
});
