import { CSSProperties } from 'vue/types/jsx'
import { Decoration } from '../../conditionalStatement'

export const DECORATION_TARGET_TEXT = 'text'

const NORMAL = 'normal'
const BOLD = 'bold'

export type TextDecorationFontWeight = typeof NORMAL | typeof BOLD

export const TEXT_DECORATION_FONT_WEIGHT_LOCAL_WORDS = {
  [NORMAL]: '標準',
  [BOLD]: '太字',
}

export type TextDecoration = {
  target: typeof DECORATION_TARGET_TEXT
  settings: {
    color: string
    fontWeight: TextDecorationFontWeight
  }
}

export const isDecorationTextDecoration = (decoration: Decoration): decoration is TextDecoration => {
  return decoration.target === DECORATION_TARGET_TEXT
}

export const styleFromTextDecoration = (decoration: TextDecoration): Pick<CSSProperties, 'color' | 'fontWeight'> => {
  return {
    color: `#${decoration.settings.color}`,
    fontWeight: decoration.settings.fontWeight,
  }
}

export const textDecorationToFontWeightLabel = (textDecoration: TextDecoration): string => {
  return TEXT_DECORATION_FONT_WEIGHT_LOCAL_WORDS[textDecoration.settings.fontWeight]
}
