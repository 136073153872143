import axios from 'axios';
import { DataSourceParameterValue } from 'src/models/dataSourceParameterValue';
import { buildBackendUrl } from 'src/apis/util/url';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';
import {
  convertFromDataSourceParameterValueResponseData,
  DataSourceParameterValueResponseData,
} from 'src/models/api/DataSourceParameterValue/dataSourceParameterValueResponseData';
import { DataSourceParameterValueIndexRequestParameters } from 'src/models/api/DataSourceParameterValue/dataSourceParameterValueIndexRequestParameters';

const buildBasePath = (): string => '/masters/data_source_parameter_values';

export default {
  async index(data: DataSourceParameterValueIndexRequestParameters): Promise<DataSourceParameterValue[] | null> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<
      MultiDataResponse<DataSourceParameterValueResponseData> | { result: null }
    >(url, {
      params: data,
    });
    return resData.result?.map(convertFromDataSourceParameterValueResponseData) ?? null;
  },
};
