import { Section } from 'src/models/new/section'
import {
  convertToSectionCreateRequestParameters,
  SectionCreateRequestParameters
} from 'src/models/api/Report/Section/sectionCreateRequestParameters'

type SectionUpdateRequestParameters = SectionCreateRequestParameters & {
  id: number
}

export const convertToSectionUpdateResponse = (section: Section): SectionUpdateRequestParameters => {
  const params = convertToSectionCreateRequestParameters(section)
  return {
    ...params,
    id: section.id,
  }
}
