
import { computed, defineComponent, getCurrentInstance, PropType, reactive } from 'vue'
import { vvGetError, vvGetErrorObject, vvHasError } from 'src/util/vee_validate'
import { MetricsTableComponent } from 'src/models/new/Component/MetricsComponent/metricsTableComponent'
import InputError from 'src/components/InputError.vue'

interface State {
  hasError: boolean
  validations: Record<string, object>
  component: MetricsTableComponent
}

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<MetricsTableComponent>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      component: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
      hasError: computed(() => vvHasError(root)),
      validations: {
        name: { required: true, max: 50 },
      },
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)

    return {
      state,
      props,
      getError,
      getErrorObject,
    }
  },
})
