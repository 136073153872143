
import { computed, defineComponent, PropType, reactive, watch } from 'vue';
import { GRANT_TO_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/AccessGroup/reportAccessGroup';
import reportAccessGroupApi from 'src/apis/masters/reportAccessGroup';
import { isAccessGroupManager, isAccessGroupSearchIdValid, GrantedToAccessGroup } from 'src/models/new/accessGroup';
import InputError from 'src/components/InputError.vue';

interface State {
  grantTo: GrantedToAccessGroup[];
  searchIdInput: string;
  grantingReportAccessGroupSearchError: string;
  isAddingGrantingReportAccessGroupsAllowed: boolean;
}

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<GrantedToAccessGroup[]>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputError,
  },
  emits: ['input'],
  setup(props, { emit }) {
    const state: State = reactive({
      grantTo: computed({
        get(): GrantedToAccessGroup[] {
          return props.value;
        },
        set(next: GrantedToAccessGroup[]) {
          emit('input', structuredClone(next));
        },
      }),
      searchIdInput: '',
      grantingReportAccessGroupSearchError: '',
      isAddingGrantingReportAccessGroupsAllowed: computed(() => state.grantTo.length < GRANT_TO_ACCESS_GROUP_MAX_COUNT),
    });

    const searchReportAccessGroup = async (): Promise<void> => {
      state.grantingReportAccessGroupSearchError = '';
      if (!state.isAddingGrantingReportAccessGroupsAllowed) {
        state.grantingReportAccessGroupSearchError = `選択可能な社外レポートグループIDは${GRANT_TO_ACCESS_GROUP_MAX_COUNT}件までです`;
        return;
      }
      if (!isAccessGroupSearchIdValid(state.searchIdInput)) {
        state.grantingReportAccessGroupSearchError =
          '権限を供与する対象の社外レポートグループIDを正しく入力してください';
        return;
      }
      try {
        const reportAccessGroupToGrant = await reportAccessGroupApi.findBySearchId(state.searchIdInput);

        // レスポンスがエラーステータス以外で返ってきた場合は、入力をリセットする
        state.searchIdInput = '';
        if (state.grantTo.some((el) => el.id === reportAccessGroupToGrant.id)) {
          state.grantingReportAccessGroupSearchError = 'すでに社外レポートグループIDとして追加されています';
          return;
        }
        state.grantTo = [...state.grantTo, { id: reportAccessGroupToGrant.id, name: reportAccessGroupToGrant.name }];
      } catch (e) {
        state.grantingReportAccessGroupSearchError = '社外レポートグループIDのレポートグループが見つかりません';
      }
    };

    const removeGrantingAccessGroup = (targetGroup: GrantedToAccessGroup): void => {
      state.grantTo = state.grantTo.filter((group) => group.id !== targetGroup.id);
    };

    watch(
      () => [state.grantTo],
      () => {
        state.searchIdInput = '';
        state.grantingReportAccessGroupSearchError = '';
      },
    );

    return {
      state,
      searchReportAccessGroup,
      removeGrantingAccessGroup,
      isAccessGroupManager,
    };
  },
});
