import { ConversionProgram } from 'src/models/conversionProgram';

export type ConversionProgramResponseData = {
  id: number;
  company_id: number;
  name: string;
  class_name: string;
};

export const convertConversionProgramResponseData = (data: ConversionProgramResponseData): ConversionProgram => {
  return {
    id: data.id,
    companyId: data.company_id,
    name: data.name,
    className: data.class_name,
  };
};
