import { Component } from 'src/models/new/component'
import {
  convertToComponentCreateRequestParameters,
  ComponentCreateRequestParameters
} from 'src/models/api/Report/Component/componentCreateRequestParameters'
import { RecordId } from 'src/util/recordId'

export type ComponentUpdateRequestParameters = ComponentCreateRequestParameters & { id: RecordId }

export const convertToComponentUpdateRequestParameters = (component: Component): ComponentUpdateRequestParameters => {
  return { ...convertToComponentCreateRequestParameters(component), id: component.id }
}
