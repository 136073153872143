import axios from 'axios';
import qs from 'qs';
import { Report } from 'src/models/new/report';
import { ReportTransactionIndexRequestParameters } from 'src/models/api/Report/reportTransactionIndexRequestParameters';
import { convertFromReportResponseData, ReportResponseData } from 'src/models/api/Report/reportResponseData';
import { buildBackendUrl } from 'src/apis/util/url';
import { ReportTransactionShowRequestParameters } from 'src/models/api/Report/reportTransactionShowRequestParameters';
import { PaginatedMultiDataResponse } from 'src/models/api/shared/multiDataResponse';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';

const buildBasePath = (): string => '/reports';

export default {
  async index(data: ReportTransactionIndexRequestParameters): Promise<{ result: Report[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<ReportResponseData>>(url, {
      params: data,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
    return {
      result: resData.result.map(convertFromReportResponseData),
      pagination: convertFromPagination(resData.pagination),
    };
  },
  async show(params: ReportTransactionShowRequestParameters): Promise<Report> {
    const url = buildBackendUrl(buildBasePath(), params.id);
    const { id, ...queryParams } = params;
    const { data: resData } = await axios.get<ReportResponseData>(url, { params: queryParams });
    return convertFromReportResponseData(resData);
  },
};
