
import { Component } from 'src/models/new/component'
import { PropType, defineComponent, onMounted, reactive } from 'vue'

type State = {
  isReady: boolean
}

export default defineComponent({
  props: {
    component: {
      type: Object as PropType<Component>,
      required: true,
    },
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const state: State = reactive({
      isReady: false,
    })

    const close = () => {
      emit('close')
    }
    const confirm = () => {
      emit('confirm')
    }

    onMounted(() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false
      state.isReady = true
    })

    return {
      props,
      state,
      close,
      confirm,
    }
  },
})
