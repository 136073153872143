import { render, staticRenderFns } from "./DecorationField.vue?vue&type=template&id=5475e84c&scoped=true&"
import script from "./DecorationField.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DecorationField.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DecorationField.vue?vue&type=style&index=0&id=5475e84c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5475e84c",
  null
  
)

export default component.exports