// eslint-disable-next-line prefer-const
/**
 * @type {Promise<any> | null}
 */
let promiseOrNull = null

export const getPromiseOfGetMe = () => promiseOrNull
export const setPromiseOfGetMe = (/** @type {Promise<any> | null} */ promise) => {
  promiseOrNull = promise
}
