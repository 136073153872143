
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue'
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate'
import { CommentComponent } from 'src/models/new/Component/commentComponent'
import componentApi from 'src/apis/masters/component'
import { notifySuccess1 } from 'src/hooks/notificationHook'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts'
import { getBottomOrdinate, isOverBoundary, isOverlappedWithOther } from 'src/models/new/component'
import { Section } from 'src/models/new/section'
import { isRecordIdMeansNotPersisted } from 'src/util/recordId'
import InputError from 'src/components/InputError.vue'

interface State {
  isReady: boolean
  component: CommentComponent
  isComponentNew: boolean
  hasError: boolean
  validations: Record<string, object>
  modalTitle: string
}

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<CommentComponent>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close', 'shouldReport'],
  setup(props, { emit }) {
    // 元々rootオブジェクトとこのコンポーネントのvueインスタンスが定義されていたが、バリデーションの仕組み上不要なはず
    const root = getCurrentInstance()!.proxy
    const state: State = reactive({
      isReady: false,
      component: computed({
        get() {
          return props.value
        },
        set(value) {
          emit('input', value)
        },
      }),
      isComponentNew: computed(() => isRecordIdMeansNotPersisted(state.component.id)),
      validations: {
        name: { required: true, max: 50 },
        placeholder: { max: 100 },
      },
      hasError: computed(() => vvHasError(root)),
      modalTitle: computed(() => `コメント欄の${state.isComponentNew ? '追加' : '編集'}`),
    })

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName)
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName)

    const submit = async(): Promise<void> => {
      if (!await vvValidate(root)) return

      // 配置場所が重なる場合や新しく生成した場合は一番下に配置する
      if (isOverlappedWithOther(state.component, props.section) || isOverBoundary(state.component) || !state.component.id) {
        state.component.ordinate = getBottomOrdinate(props.section)
      }
      const component = {
        ...state.component,
        sectionId: props.section.id,
      }
      const operationWord = state.isComponentNew ? '追加' : '更新'
      try {
        if (state.isComponentNew) {
          const { id } = await componentApi.create(props.reportId, component)
          component.id = id
        } else {
          await componentApi.update(props.reportId, component)
        }
        state.component = structuredClone(component)
        emit('updated')
        notifySuccess1(root, `コンポーネントを${operationWord}しました。`)
      } catch (err: any) {
        const errStatus = err.response.status
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else if (errStatus === 409) {
          const msg = 'コンポーネントの追加に失敗しました。レポートが編集されています。'
          emit('shouldReport', ERROR_GROUP_USER, msg, err)
        } else {
          const msg = `コンポーネントの${operationWord}に失敗しました。管理者に連絡してください。`
          emit('shouldReport', ERROR_GROUP_SYSTEM, msg, err)
        }
      }
    }

    const close = (): void => {
      emit('close')
    }

    onMounted(() => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isReady = false
      state.isReady = true
    })

    return {
      state,
      props,
      getError,
      getErrorObject,
      submit,
      close,
    }
  },
})
