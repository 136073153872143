
import Vue, { computed, defineComponent, getCurrentInstance, reactive } from 'vue';
import { Route } from 'vue-router';
import { Navbar } from 'src/components/UIComponents';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { Workplace } from 'src/models/workplace';

interface State {
  showNavbar: boolean;
  workplace: Workplace | null;
  workplaceId: string;
  fullName: string;
  env: string;
  pageName: string;
  isDev: boolean;
  isStg: boolean;
  isDemo: boolean;
  isQA: boolean;
  useLogiAuth: boolean;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    showNavbar: false,
    workplace: null,
    workplaceId: computed(() => root.$route.params.workplaceId),
    ...wrappedMapGetters(root.$store, 'user', ['fullName', 'env']),
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    isDev: computed(() => state.env === 'development'),
    isStg: computed(() => state.env === 'staging' || state.env === 'stg'),
    isDemo: computed(() => state.env === 'demo'),
    isQA: computed(() => state.env.startsWith('qa')),
    useLogiAuth: process.env.VUE_APP_USE_LOGI_AUTH === 'true',
  });
  return state;
}

export default defineComponent({
  components: { Navbar },
  props: {
    sidebarStore: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const state = setupState(root);

    // function toggleSidebar() {
    //   props.sidebarStore.displaySidebar(!props.sidebarStore.showSidebar)
    // }
    // function hideSidebar() {
    //   props.sidebarStore.displaySidebar(false)
    // }
    // function minimizeSidebar() {
    //   props.sidebarStore.toggleMinimize()
    // }
    // function toggleNavbar() {
    //   state.showNavbar = !state.showNavbar
    // }
    async function logout() {
      await root.$store.dispatch('user/logout');
      // want to explicitly reload
      if (state.useLogiAuth) {
        location.href = '/users/saml/idp_logout';
      } else {
        location.href = '/login';
      }
    }
    async function updateInternalWorkplaceInfo() {
      if (!state.workplaceId) {
        state.workplace = null;
      } else if (!state.workplace || state.workplace.id !== parseInt(state.workplaceId)) {
        state.workplace = await root.$store.dispatch('workplace/fetchWorkplaceById', {
          workplaceId: state.workplaceId,
        });
      }
    }

    async function afterRouteChange(_toRoute: Route) {
      await updateInternalWorkplaceInfo();
    }

    ensureUserAndMasters(root).then(() => {
      afterRouteChange(root.$route);
      root.$router.afterEach((to, _from) => {
        afterRouteChange(to);
      });
    });

    return {
      state,
      logout,
    };
  },
});
