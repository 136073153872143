export const GRAPH_LAYOUT_SCALE_POSITION_RIGHT = 'right'
export const GRAPH_LAYOUT_SCALE_POSITION_LEFT = 'left'

export type GraphLayoutScalePosition = typeof GRAPH_LAYOUT_SCALE_POSITION_RIGHT | typeof GRAPH_LAYOUT_SCALE_POSITION_LEFT
type PlotType = Extract<Chart.ChartType, 'bar' | 'line'>
export type GraphLayout = {
  scale: GraphLayoutScalePosition
  number: number
  plotType: PlotType
}

export const GRAPH_LAYOUT_SCALE_POSITION_NAMES = {
  [GRAPH_LAYOUT_SCALE_POSITION_LEFT]: '左軸',
  [GRAPH_LAYOUT_SCALE_POSITION_RIGHT]: '右軸',
}

export const isGraphLayoutPlotTypeBar = (graphLayout: GraphLayout): boolean => {
  return graphLayout.plotType === 'bar'
}
export const isGraphLayoutPlotTypeLine = (graphLayout: GraphLayout): boolean => {
  return graphLayout.plotType === 'line'
}
export const isGraphLayoutAcceptingConditionalStatements = (graphLayout: GraphLayout): boolean => {
  return !isGraphLayoutPlotTypeLine(graphLayout)
}

export const orderFromGraphLayout = (graphLayout: GraphLayout): number => {
  if (graphLayout.scale === 'left' && graphLayout.number === 1) {
    return 1
  } else if (graphLayout.scale === 'left' && graphLayout.number === 2) {
    return 2
  } else if (graphLayout.scale === 'right' && graphLayout.number === 1) {
    return 3
  } else if (graphLayout.scale === 'right' && graphLayout.number === 2) {
    return 4
  }
  throw new Error('invalid graph layout')
}

export const switchGraphLayoutPlotTypes = (graphLayouts: GraphLayout[], changed: GraphLayout): GraphLayout[] => {
  return graphLayouts.map(el => {
    if (el.scale === changed.scale) {
      return { ...el, plotType: changed.plotType }
    } else {
      return { ...el, plotType: changed.plotType === 'bar' ? 'line' : 'bar' }
    }
  })
}
