
import { computed, defineComponent, PropType, reactive } from 'vue';
import { Report } from 'src/models/new/report';
import { reportToCustomUrl, customUrlToReportId } from 'src/business/customUrl';
import { useReports } from 'src/composables/asyncResources/useReports';
import { Link } from 'src/models/new/link';
import { useValidator } from 'src/composables/useValidator';
import InputError from 'src/components/InputError.vue';

type State = {
  isLoaded: boolean;
  reportLink: Link;
  selectedReportId: number | null;
};

export default defineComponent({
  components: {
    InputError,
  },
  props: {
    value: {
      type: Object as PropType<Link>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { validations, setValidation, validationResult } = useValidator();
    setValidation({
      name: { required: true, max: 20 },
      targetReportId: { required: true },
    });

    const state: State = reactive({
      isLoaded: false,
      reportLink: computed({
        get() {
          return props.value;
        },
        set(value) {
          emit('input', { ...value });
        },
      }),
      selectedReportId: computed({
        get() {
          const val = customUrlToReportId(state.reportLink.url);
          return val ?? null;
        },
        set(value) {
          const report = reportsRef.value.find((report: Report) => report.id === value);
          if (!report) {
            return;
          }

          const url = reportToCustomUrl(report);
          state.reportLink.url = url;
        },
      }),
    });

    const { reportsRef } = useReports();

    return {
      props,
      state,
      reportsRef,
      validations,
      validationResult,
    };
  },
});
