import { render, staticRenderFns } from "./Collapse.vue?vue&type=template&id=a3c78a62&scoped=true&"
import script from "./Collapse.vue?vue&type=script&lang=js&"
export * from "./Collapse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3c78a62",
  null
  
)

export default component.exports