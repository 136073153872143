import { Location, Route } from 'vue-router';
import { SidebarLink, UnresolvedSidebarLink } from 'src/components/UIComponents/Sidebar/createSidebarStore';

export function isGuestPage(routeName?: string | null): boolean {
  if (!routeName) {
    return false;
  }
  const arr = ['Login', 'PasswordReset', 'ManualLogin'];
  return arr.indexOf(routeName) !== -1;
}

export function getSidebarLinkGroupNameFromRoute(route: Route) {
  // const routePath = route.path
  // const routeName = route.name
  const ret = 'top';
  // 先頭がworkplaceだったら大体Workplaceグループ
  // if (routePath.startsWith('/workplace')) {
  //   if (routeName === 'WorkplaceSelect') {
  //     // センター選択はトップグループ
  //     ret = 'top'
  //   } else {
  //     ret = 'workplace'
  //   }
  // }
  return ret;
}

export function resolveLinkGroupData(linkGroup: UnresolvedSidebarLink[], routeTo: Route): SidebarLink[] {
  return linkGroup.map((sbl: UnresolvedSidebarLink) => {
    const ret: SidebarLink = Object.assign({ path: '', activePath: '' }, sbl);
    // 候補の中からpathを決定する
    ret.path = sbl.pathCandMap.default;
    if (routeTo.name && sbl.pathCandMap[routeTo.name]) {
      ret.path = sbl.pathCandMap[routeTo.name]!;
    }
    // あとで使うかもしれないのでdefaultPathもついでに置換.
    let defaultPath = sbl.pathCandMap.default;

    // 動的項目を抽出、置換.
    const dynamicPathParams = ret.path
      .split('/')
      .filter((e) => e.match(/^:(\w|\d)+$/))
      .map((e) => e.slice(1));
    dynamicPathParams.forEach((param) => {
      if (routeTo.params[param]) {
        ret.path = ret.path!.replace(`:${param}`, routeTo.params[param]!);
        defaultPath = defaultPath.replace(`:${param}`, routeTo.params[param]!);
      } else if (param === 'date') {
        // dateについては、なかったらtodayにしといてやる.
        ret.path = ret.path!.replace(`:${param}`, 'today');
        defaultPath = defaultPath.replace(`:${param}`, 'today');
      }
    });
    // 矢印は出しておきたいがリンク先は常に固定したいケースがあったので、
    // リンク先(path)とアクティブ判定(activePath)を分けた.
    // ret.activePath = ret.path
    if (ret.alwaysLinkToDefault) {
      // この機能を使用する場合、同一pathCandMap内の
      // defaultPath以外のpathは、defaultPathに設定されているparamを全て
      // 含んでいなければならない.
      // (そうしないと置換されてないパラメータが残ってしまう)
      ret.path = defaultPath;
    }
    return ret;
  });
}

export function getTopbarModeFromRouteName(routeName: string) {
  let mode = 'mode1';
  if (routeName.startsWith('Workplace')) {
    if (
      routeName === 'WorkplaceSelect' ||
      routeName === 'WorkplaceMonthlyCalendar' ||
      routeName === 'WorkplaceFeatureMap'
    ) {
      // 日付なし
      mode = 'mode1';
    } else {
      // 日付selectを表示
      mode = 'mode2';
    }
  } else if (routeName.startsWith('Results')) {
    if (routeName === 'ResultsSelect') {
      // 日付なし
      mode = 'mode1';
    } else {
      // 日付range selectを表示
      mode = 'mode3';
    }
  } else if (routeName.startsWith('Analytics')) {
    if (routeName === 'AnalyticsCSV' || routeName === 'AnalyticsSelect') {
      // 日付なし
      mode = 'mode1';
    } else if (routeName === 'AnalyticsWorkhours2') {
      // 日付select
      mode = 'mode2';
    } else {
      // 日付range selectを表示
      mode = 'mode3';
    }
  }
  return mode;
}

export async function routerShowNextPage(vueInstance: Vue, evt: MouseEvent | KeyboardEvent, routeObj: Location) {
  if (evt.ctrlKey || evt.metaKey) {
    const obj = vueInstance.$router.resolve(routeObj);
    window.open(obj.href, '_blank');
  } else {
    const currentRoute = vueInstance.$router.currentRoute;
    if (currentRoute.name === routeObj.name) {
      // prevent 'Navigation Duplicated' error.
      const nextRoute = vueInstance.$router.resolve(routeObj).route;
      const areAllParamsSame = Object.entries(currentRoute.params).every(([k, v]) => {
        return v === nextRoute.params[k];
      });
      const areAllQueriesSame = Object.entries(currentRoute.query).every(([k, v]) => {
        return v === nextRoute.query[k];
      });
      if (areAllParamsSame && areAllQueriesSame) {
        return;
      }
    }
    await vueInstance.$router.push(routeObj);
  }
}
