import actionLogApi from 'src/apis/actionLog/actionLog';
import { ActionLogCreateParameters } from 'src/models/api/actionLog/actionLogCreateParameters';
import type { ActionType } from 'src/models/api/actionLog/actionType';

export const useActionLog = (actionType: ActionType) => {
  const sendActionLog = (params: ActionLogCreateParameters<typeof actionType>) => {
    // 失敗しても操作の継続上問題はない為エラーは無視する
    try {
      actionLogApi.create(actionType, params);
    } catch {}
  };

  return {
    sendActionLog,
  };
};
