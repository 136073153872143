import { isUrlCustomReportUrl, normalizeReportSchemeUrl } from 'src/business/customUrl/reports'
import { isSafeUrl } from 'src/util/url'
export * from 'src/business/customUrl/reports'

export const isSafeOrCustomUrl = (url: string): boolean => {
  return (
    isSafeUrl(url) ||
    isUrlCustomReportUrl(url)
  )
}

export const normalizeUrl = (url: string): string => {
  if (!isSafeOrCustomUrl(url)) {
    console.error('Sanitize unsafe URL:', url)
    return ''
  }

  if (isSafeUrl(url)) {
    return url
  }

  if (isUrlCustomReportUrl(url)) {
    return normalizeReportSchemeUrl(url)
  }

  console.error('Sanitize broken custom URL:', url)
  return ''
}
