import axios from 'axios';
import { convertToComponentUpdateCommentRequestParameters } from 'src/models/api/Report/Component/componentUpdateCommentRequestParameters';
import { Report } from 'src/models/new/report';
import { CommentComponent } from 'src/models/new/Component/commentComponent';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (reportId: number, id: number): string => `/reports/${reportId}/components/${id}`;

export default {
  async updateComment(report: Report, component: CommentComponent): Promise<{}> {
    const params = convertToComponentUpdateCommentRequestParameters(report, component);
    const url = buildBackendUrl(buildBasePath(params.report_id, params.id), 'update_comment');
    await axios.post(url, params);
    return {};
  },
};
