export const AGGREGATE_FUNCTION_SUM = 'sum'
export const AGGREGATE_FUNCTION_AVG = 'avg'
export const AGGREGATE_FUNCTION_MIN = 'min'
export const AGGREGATE_FUNCTION_MAX = 'max'
export const AGGREGATE_FUNCTION_MEDIAN = 'median'
export const AGGREGATE_FUNCTION_COUNT = 'count'

export type AggregateFunction = typeof AGGREGATE_FUNCTION_COUNT | typeof AGGREGATE_FUNCTION_SUM | typeof AGGREGATE_FUNCTION_AVG | typeof AGGREGATE_FUNCTION_MIN |
  typeof AGGREGATE_FUNCTION_MAX | typeof AGGREGATE_FUNCTION_MEDIAN

export const AGGREGATE_FUNCTION_LOCAL_WORDS: Record<AggregateFunction, string> = {
  [AGGREGATE_FUNCTION_SUM]: '合計',
  [AGGREGATE_FUNCTION_AVG]: '平均',
  [AGGREGATE_FUNCTION_MIN]: '最小値',
  [AGGREGATE_FUNCTION_MAX]: '最大値',
  [AGGREGATE_FUNCTION_MEDIAN]: '中央値',
  [AGGREGATE_FUNCTION_COUNT]: '件数',
}
