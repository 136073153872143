import { TimeSpan } from 'src/business/timeSpan';
import { Report } from 'src/models/new/report';

export type ReportCreateRequestParameters = {
  name: string;
  time_span: TimeSpan;
  theme_color: string;
  is_enabled: boolean;
  report_access_groups: {
    id: number;
  }[];
};

export const convertToReportCreateRequestParameters = (report: Report): ReportCreateRequestParameters => {
  return {
    name: report.name,
    time_span: report.timeSpan,
    theme_color: report.themeColor,
    is_enabled: report.isEnabled,
    report_access_groups: report.accessGroupIds.map((el) => {
      return { id: el };
    }),
  };
};
