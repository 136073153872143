
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import { formatMetricsValue, Metrics } from 'src/models/new/metrics'
import metricsApi from 'src/apis/metrics'
import MetricsBadge from 'src/components/MetricsBadge.vue'
import { timeSpanToLocalWord } from 'src/business/timeSpan'
import workplaceApi from 'src/apis/masters/workplace'
import {
  isMetricsSummaryMetrics,
  SUMMARY_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS,
} from 'src/models/new/Metrics/summaryMetrics'
import { isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics'
import {
  BUNDLED_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS,
  isMetricsBundledMetrics,
} from 'src/models/new/Metrics/bundledMetrics'
import {
  isMetricsReferenceMetrics,
  isReferenceMetricsOffsetPeriodDay,
  isReferenceMetricsOffsetPeriodMonth,
  isReferenceMetricsOffsetPeriodWeek,
  isReferenceMetricsOffsetPeriodYear,
} from 'src/models/new/Metrics/referenceMetrics'
import { isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics'
import { formatDate, parseYmdDate } from 'src/util/Datetime/parse'
import { Workplace } from 'src/models/new/workplace'
import { DAY_OF_WEEK_LOCAL_WORDS } from 'src/util/week'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { ERROR_GROUP_SYSTEM, ErrorGroup } from 'src/consts'
import UpdateMetricsValueModal from 'src/views/Dashboard/ReportValues/Detail/components/UpdateMetricsValueModal.vue'
import { dateRangeFromEndDate, dateRangeToLocalWord } from 'src/util/Datetime/dateRange'
import { DATE_FNS_DATE_TIME_FORMAT, SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format'
import MetricsTypeRoundPoint from 'src/components/MetricsTypeRoundPoint.vue'

type ExtendedMetrics = Metrics & {
  workplaceName: string
  spanDisp: string
  formattedValue: string
  updatedAtDisp: string
  isFutureClosingDate: boolean
}

interface State {
  isLoaded: boolean
  pageName: string | null
  metricsId: string | null
  date: Date
  metrics: Metrics | null
  extendedMetrics: ExtendedMetrics | null
  targetMetricsItems: Metrics[]
  extendedTargetMetricsItems: ExtendedMetrics[]
  workplaceOptions: Workplace[]
  // 内訳メトリクス一覧に閲覧権限がないメトリクスが含まれているかどうかの判定
  isMetricsForbidden: boolean
  includeForbidden: boolean
  showEditValueModal: boolean
  editValueLabel: string | null
  focusedMetrics: Metrics | null
}

const generateExtendedMetrics = (metrics: Metrics, workplaceOptions: Workplace[]): ExtendedMetrics => {
  const dateRange = dateRangeFromEndDate(metrics.closingDate!, metrics.timeSpan)
  return {
    ...metrics,
    workplaceName: workplaceOptions.find(el => el.id === metrics.workplaceId)?.name || '',
    spanDisp: dateRangeToLocalWord(dateRange),
    formattedValue: formatMetricsValue(metrics),
    updatedAtDisp: metrics.updatedAt ? formatDate(metrics.updatedAt, DATE_FNS_DATE_TIME_FORMAT) : '',
    isFutureClosingDate: dateRange.startDate > new Date(),
  }
}

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', [
      'pageName',
    ]),
    isLoaded: false,
    metricsId: computed(() => root.$route.params.metricsId),
    date: computed(() => {
      if (!root.$route.query.dt) return new Date()
      return parseYmdDate(root.$route.query.dt as string)
    }),
    metrics: null,
    extendedMetrics: computed(() => {
      if (!state.metrics || state.workplaceOptions.length === 0) return null
      return generateExtendedMetrics(state.metrics, state.workplaceOptions)
    }),
    targetMetricsItems: [],
    extendedTargetMetricsItems: computed(() => {
      if (state.workplaceOptions.length === 0) return []
      return state.targetMetricsItems.map(metrics => generateExtendedMetrics(metrics, state.workplaceOptions))
    }),
    workplaceOptions: [],
    isMetricsForbidden: false,
    includeForbidden: false,
    showEditValueModal: false,
    editValueLabel: null,
    focusedMetrics: null,
  })
  return state
}

export default defineComponent({
  components: {
    MetricsBadge,
    UpdateMetricsValueModal,
    MetricsTypeRoundPoint,
  },
  setup() {
    const root = getCurrentInstance()!.proxy
    setPageName(root, 'メトリクス内訳')
    const state = setupState(root)

    const back = () => {
      root.$router.back()
    }

    const getWorkplaceOptions = async(): Promise<void> => {
      state.workplaceOptions = await workplaceApi.index()
    }

    const getMetrics = async(): Promise<void> => {
      try {
        const params = {
          id: Number(state.metricsId),
          dt: formatDate(state.date, SYSTEM_DATE_FORMAT),
        }
        const response = await metricsApi.breakdown(params)
        if (!response.metrics) return

        state.metrics = response.metrics
        state.targetMetricsItems = response.targetMetricsList
        state.includeForbidden = response.includeForbidden
      } catch (err: any) {
        const errStatus = err.response.status
        if (errStatus === 403) {
          state.isMetricsForbidden = true
        }
        state.metrics = null
        state.targetMetricsItems = []
      }
    }

    const load = async(): Promise<void> => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false
      await Promise.all([
        getWorkplaceOptions(),
        getMetrics(),
      ])
      state.isLoaded = true
    }

    const processingMethod = (metrics: ExtendedMetrics): string => {
      if (isMetricsSummaryMetrics(metrics)) {
        return SUMMARY_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS[metrics.aggrFunc]
      } else if (isMetricsBundledMetrics(metrics)) {
        return BUNDLED_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS[metrics.aggrFunc]
      } else if (isMetricsCalculatedMetrics(metrics)) {
        const formulaList: string[] = []
        const operandTexts = ['A', 'B', 'C']
        metrics.operands.forEach((operand, i) => {
          if (!operand.metricsId && !operand.constant) return

          formulaList.push(operand.metricsId ? operandTexts.shift()! : String(operand.constant))
          const operator = metrics.operators[i]
          if (operator !== null) {
            formulaList.push(operator)
          }
        })
        return formulaList.join(' ')
      } else if (isMetricsReferenceMetrics(metrics)) {
        if (isReferenceMetricsOffsetPeriodDay(metrics.offsetPeriod)) {
          return `${metrics.offsetPeriod.value}日前`
        } else if (isReferenceMetricsOffsetPeriodWeek(metrics.offsetPeriod)) {
          const offsetText = `${metrics.offsetPeriod.value}週前`
          const dowText = metrics.referencePointDow ? `${DAY_OF_WEEK_LOCAL_WORDS[metrics.referencePointDow]}` : null
          return dowText ? `${offsetText}の${dowText}` : offsetText
        } else if (isReferenceMetricsOffsetPeriodMonth(metrics.offsetPeriod)) {
          const offsetText = `${metrics.offsetPeriod.value}ヶ月前`
          const dayText = metrics.referencePointDay ? `${metrics.referencePointDay}日` : null
          return dayText ? `${offsetText}の${dayText}` : offsetText
        } else if (isReferenceMetricsOffsetPeriodYear(metrics.offsetPeriod)) {
          const offsetText = `${metrics.offsetPeriod.value}年前`
          const monthText = metrics.referencePointMonth ? `${metrics.referencePointMonth}月` : null
          const dayText = metrics.referencePointMonth && metrics.referencePointDay ? `${metrics.referencePointDay}日` : null
          if (monthText && dayText) {
            return `${offsetText}の${monthText}${dayText}`
          } else if (monthText) {
            return `${offsetText}の${monthText}`
          } else {
            return offsetText
          }
        }
      }

      return ''
    }

    const isBreakdownAccessible = (metrics: Metrics): boolean => {
      return isMetricsSummaryMetrics(metrics) ||
        isMetricsCalculatedMetrics(metrics) ||
        isMetricsReferenceMetrics(metrics) ||
        isMetricsBundledMetrics(metrics)
    }

    const isDisplayProcessingMethod = (metrics: Metrics): boolean => {
      return isMetricsSummaryMetrics(metrics) ||
        isMetricsCalculatedMetrics(metrics) ||
        isMetricsReferenceMetrics(metrics) ||
        isMetricsBundledMetrics(metrics)
    }

    const onMetricsEditButtonClicked = (metrics: Metrics):void => {
      state.focusedMetrics = structuredClone(metrics)
      state.editValueLabel = metrics.name
      state.showEditValueModal = true
    }

    const closeEditValueModal = (): void => {
      state.showEditValueModal = false
      state.editValueLabel = null
      state.focusedMetrics = null
    }

    const completeEditValue = async(message: string): Promise<void> => {
      await load()
      closeEditValueModal()
      notifySuccess1(root, message)
    }

    const reportError = async(errorGroup: ErrorGroup, message: string, error: any): Promise<void> => {
      const formattedMessage = errorGroup === ERROR_GROUP_SYSTEM
        ? message + `(ERR: ${state.pageName})`
        : message

      notifyError1(root, formattedMessage, error)
    }

    const onBreakdownButtonClicked = async(metrics: Metrics): Promise<void> => {
      if (!metrics.closingDate) throw Error('Metrics closingDate is required.')

      await root.$router.push({
        name: 'MetricsBreakdown',
        params: {
          metricsId: String(metrics.id),
        },
        query: { dt: formatDate(metrics.closingDate, 'yyyy-MM-dd') },
      })
      await load()
    }

    onMounted(async() => {
      await ensureUserAndMasters(root)
      await load()

      // backした時に再読み込みする
      window.onpopstate = async() => {
        if (!state.metricsId) return

        await load()
      }
    })

    return {
      state,
      back,
      timeSpanToLocalWord,
      processingMethod,
      isDisplayProcessingMethod,
      isMetricsDirectInputMetrics,
      onMetricsEditButtonClicked,
      onBreakdownButtonClicked,
      closeEditValueModal,
      completeEditValue,
      reportError,
      isBreakdownAccessible,
    }
  },
})

