import { Ref, inject, provide, ref } from 'vue';

export const useSharedValue = <T>(uniqueName: string) => {
  const injectedValue = inject<Ref<T> | null>(uniqueName, null);
  const value = injectedValue !== null ? injectedValue : ref<T>();

  if (injectedValue === null) {
    provide(uniqueName, value);
  }

  return {
    value,
  };
};
