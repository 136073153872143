
import { defineComponent, PropType } from 'vue'
import LinkList from 'src/views/Dashboard/Settings/Reports/shared/components/LinkList/LinkList.vue'
import { ReportLink } from 'src/models/new/Link/reportLink'
import { useReportLinkOrderUpdater } from 'src/views/Dashboard/Settings/Reports/Links/components/ReportLinkList/composables/useReportLinkOrderUpdater'

export default defineComponent({
  components: {
    LinkList,
  },
  props: {
    reportLinks: {
      type: Array as PropType<ReportLink[]>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    edit: {
      type: Function as PropType<(link: ReportLink) => Promise<void>>,
      required: true,
    },
    del: {
      type: Function as PropType<(link: ReportLink) => Promise<void>>,
      required: true,
    },
  },
  emits: ['updated', 'failed'],
  setup(props) {
    const {
      updateReportLinkOrder,
    } = useReportLinkOrderUpdater()

    const updateOrder = async(reportLinks: ReportLink[]) => {
      await updateReportLinkOrder(props.reportId, reportLinks)
    }

    return {
      props,
      updateOrder,
    }
  },
})
