
import { timeSpanToLocalWord } from 'src/business/timeSpan';
import type { LovEntry } from 'src/models/master';
import type { IconDecoration } from 'src/models/new/ConditionalStatement/Decorations/iconDecoration';
import {
  textDecorationToFontWeightLabel,
  type TextDecoration,
} from 'src/models/new/ConditionalStatement/Decorations/textDecoration';
import {
  isConditionalStatementTargetText,
  isConditionalStatementTargetIcon,
  ConditionalStatementToTargetLabel,
  isConditionalStatementBaseConstant,
  isConditionalStatementBaseMetrics,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementThresholdConstant,
  isConditionalStatementThresholdMetrics,
  isConditionalStatementThresholdReference,
  type Decoration,
  type ConditionalStatementWithContainerPosition,
} from 'src/models/new/conditionalStatement';
import type { Metrics } from 'src/models/new/metrics';
import type { Workplace } from 'src/models/new/workplace';
import { labelFromComparator } from 'src/util/comparator';
import { classFromIcon } from 'src/util/icon';
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive, type PropType, type Ref } from 'vue';
import { useWorkplaces } from 'src/composables/asyncResources/useWorkplaces';

type State = {
  colors: LovEntry[];
  isBaseMetrics: boolean;
  baseView: string;
  comparatorView: string;
  isThresholdMetrics: boolean;
  thresholdView: string;
  isTargetText: boolean;
  isTargetIcon: boolean;
  targetView: string;
  colorView: string;
  fontWeightView: string;
  iconClass: string;
};

const setupState = (root: Vue, workplacesRef: Ref<Workplace[]>): State => {
  const state: State = reactive({
    colors: [],
    isBaseMetrics: computed(() => isConditionalStatementBaseMetrics(root.$props.conditionalStatement)),
    baseView: computed(() => {
      const conditionalStatement: ConditionalStatementWithContainerPosition<any> = root.$props.conditionalStatement;
      if (isConditionalStatementBaseMetrics(conditionalStatement)) {
        const metrics = conditionalStatement.base as Metrics;
        const workplace = workplacesRef.value.find((workplace) => workplace.id === metrics.workplaceId);
        return `${workplace?.name} ${timeSpanToLocalWord(metrics.timeSpan)} ${metrics.name}`;
      } else if (isConditionalStatementBaseConstant(conditionalStatement)) {
        return String(conditionalStatement.base);
      } else if (isConditionalStatementBaseSelf(conditionalStatement)) {
        return '自身のメトリクス';
      } else if (isConditionalStatementBaseReference(conditionalStatement)) {
        return '参考値';
      }
      return '';
    }),
    comparatorView: computed(() => {
      const conditionalStatement: ConditionalStatementWithContainerPosition<any> = root.$props.conditionalStatement;
      return labelFromComparator(conditionalStatement.comparator);
    }),
    isThresholdMetrics: computed(() => isConditionalStatementThresholdMetrics(root.$props.conditionalStatement)),
    thresholdView: computed(() => {
      const conditionalStatement: ConditionalStatementWithContainerPosition<any> = root.$props.conditionalStatement;
      if (isConditionalStatementThresholdMetrics(conditionalStatement)) {
        const metrics = conditionalStatement.threshold as Metrics;
        const workplace = workplacesRef.value.find((workplace) => workplace.id === metrics.workplaceId);
        return `${workplace?.name} ${timeSpanToLocalWord(metrics.timeSpan)} ${metrics.name}`;
      } else if (isConditionalStatementThresholdConstant(conditionalStatement)) {
        return String(conditionalStatement.threshold);
      } else if (isConditionalStatementThresholdReference(conditionalStatement)) {
        return '参考値';
      }
      return '';
    }),
    isTargetText: computed(() => isConditionalStatementTargetText(root.$props.conditionalStatement)),
    isTargetIcon: computed(() => isConditionalStatementTargetIcon(root.$props.conditionalStatement)),
    targetView: computed(() => ConditionalStatementToTargetLabel(root.$props.conditionalStatement)),
    colorView: computed(() => {
      const conditionalStatement: ConditionalStatementWithContainerPosition<Decoration> =
        root.$props.conditionalStatement;
      return state.colors.find((el) => el.key === conditionalStatement.decoration.settings.color)?.name ?? '';
    }),
    fontWeightView: computed(() => {
      if (!state.isTargetText) {
        return '';
      }
      const conditionalStatement: ConditionalStatementWithContainerPosition<TextDecoration> =
        root.$props.conditionalStatement;
      return textDecorationToFontWeightLabel(conditionalStatement.decoration);
    }),
    iconClass: computed(() => {
      if (!state.isTargetIcon) {
        return '';
      }
      const conditionalStatement: ConditionalStatementWithContainerPosition<IconDecoration> =
        root.$props.conditionalStatement;
      return classFromIcon(conditionalStatement.decoration.settings.icon);
    }),
  });
  return state;
};

export default defineComponent({
  props: {
    conditionalStatement: {
      type: Object as PropType<ConditionalStatementWithContainerPosition<any>>,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: true,
    },
    onCheckboxChange: {
      type: Function as PropType<
        (conditionalStatement: ConditionalStatementWithContainerPosition<any>, checked: boolean) => void
      >,
      required: true,
    },
    onEditClicked: {
      type: Function as PropType<(conditionalStatement: ConditionalStatementWithContainerPosition<any>) => void>,
      required: true,
    },
    onDeleteClicked: {
      type: Function as PropType<(conditionalStatement: ConditionalStatementWithContainerPosition<any>) => void>,
      required: true,
    },
  },

  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const { workplacesRef } = useWorkplaces();
    const state = setupState(root, workplacesRef);

    const COLORS = window.master.lovs.color_set.vals.filter((e) => e.group === 'dark');

    const toggleCheckbox = (checked: boolean) => {
      props.onCheckboxChange(props.conditionalStatement, checked);
    };

    const editClicked = () => {
      props.onEditClicked(props.conditionalStatement);
    };

    const deleteClicked = () => {
      props.onDeleteClicked(props.conditionalStatement);
    };

    onMounted(() => {
      state.colors = COLORS;
    });

    return {
      props,
      state,
      toggleCheckbox,
      editClicked,
      deleteClicked,
    };
  },
});
