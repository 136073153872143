const ONE = 'one';
const THOUSAND = 'thousand';
const TEN_THOUSAND = 'ten_thousand';
const MILLION = 'million';
export type Scaling = typeof ONE | typeof THOUSAND | typeof TEN_THOUSAND | typeof MILLION;

export const SCALING_LABELS = {
  [ONE]: '一',
  [THOUSAND]: '千',
  [TEN_THOUSAND]: '万',
  [MILLION]: '百万',
};

export const convertScaling = (num: number, scaling: Scaling): number => {
  switch (scaling) {
    case ONE:
      return num;
    case THOUSAND:
      return num / 1000;
    case TEN_THOUSAND:
      return num / 10000;
    case MILLION:
      return num / 1000000;
    default:
      throw new Error(`Unexpected Scaling: ${scaling}`);
  }
};
