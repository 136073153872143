import type { ComponentLink } from 'src/models/new/Link/componentLink';
import componentLinkApi from 'src/apis/masters/componentLink';
import { computed, reactive, ref } from 'vue';
import { useUrlPathParameters } from 'src/composables/useUrlPathParameters';
import { COMPONENT_MAX_COMPONENT_LINKS } from 'src/models/new/component';

const urlPathParametersTemplate = {
  reportId: 0,
  componentId: 0,
};

export const useComponentLinks = () => {
  const componentLinksRef = ref<ComponentLink[]>([]);

  const { urlPathParameters } = useUrlPathParameters(urlPathParametersTemplate);

  const loadComponentLinks = async () => {
    const reportId = urlPathParameters.value.reportId;
    const componentId = urlPathParameters.value.componentId;
    if (reportId && componentId) {
      componentLinksRef.value = await componentLinkApi.index(reportId, componentId);
    } else {
      console.error('reportId or componentId is not in url params');
    }
  };

  const componentLinksState = reactive({
    isEmpty: computed(() => componentLinksRef.value.length === 0),
    isFull: computed(() => componentLinksRef.value.length >= COMPONENT_MAX_COMPONENT_LINKS),
  });

  return {
    componentLinksRef,
    loadComponentLinks,
    componentLinksState,
  };
};
