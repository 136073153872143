import { DataFetchStatus, EXECUTE_STATUS, EXECUTE_TYPE } from 'src/models/dataFetchStatus';
import { DateString } from 'src/models/common';
import { parseDatetime, parseYmdDate } from 'src/util/Datetime/parse';

export type DataFetchStatusResponseData = {
  id: number;
  company_id: number;
  start_time: DateString;
  end_time: DateString | null;
  execute_type: EXECUTE_TYPE;
  execute_status: EXECUTE_STATUS;
  execute_status_text: string;
  execute_type_text: string;
  metrics_start_dt: DateString | null;
  metrics_end_dt: DateString | null;
  metrics_names: string | null;
  error_message: string | null;
  csv_name: string;
};

export const convertDataFetchStatusResponseData = (data: DataFetchStatusResponseData): DataFetchStatus => {
  return {
    id: data.id,
    companyId: data.id,
    startTime: parseDatetime(data.start_time),
    endTime: data.end_time ? parseDatetime(data.end_time) : null,
    executeType: data.execute_type,
    executeStatus: data.execute_status,
    executeStatusText: data.execute_status_text,
    executeTypeText: data.execute_type_text,
    metricsStartDt: data.metrics_start_dt ? parseYmdDate(data.metrics_start_dt) : null,
    metricsEndDt: data.metrics_end_dt ? parseYmdDate(data.metrics_end_dt) : null,
    metricsNames: data.metrics_names,
    errorMessage: data.error_message,
  };
};
