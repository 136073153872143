import { Ref, inject, provide, ref, watch } from 'vue';

export type SimpleEventTriggerer = {
  trigger: () => void;
};
export type SimpleEventListener = {
  attach: (handler: () => void | Promise<void>) => void;
};
type UseSimpleEvent = {
  listener: SimpleEventListener;
  triggerer: SimpleEventTriggerer;
};

export const useSimpleEvent = (uniqueName: string): UseSimpleEvent => {
  const injectedCount = inject<Ref<number> | null>(uniqueName, null);
  const count = injectedCount ?? ref<number>(0);

  if (injectedCount === null) {
    provide(uniqueName, count);
  }

  const attach = (handler: () => void | Promise<void>) => {
    watch(count, handler);
  };

  const listener = {
    attach,
  };

  const trigger = () => {
    count.value += 1;
  };

  const triggerer = {
    trigger,
  };

  return {
    listener,
    triggerer,
  };
};
