export type convertToComponentCopyRequestParameters = {
  section_id: number
}

export const convertToComponentCopyRequestParameters = (
  params: { sectionId: number }
): convertToComponentCopyRequestParameters => {
  return {
    section_id: params.sectionId
  }
}
