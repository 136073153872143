import { GRAPH_LAYOUT_SCALE_POSITION_NAMES, isGraphLayoutAcceptingConditionalStatements, orderFromGraphLayout } from 'src/business/graphLayout'
import { isComponentTypeMetricsBarGraph, labelFromMetricsBarGraphBarElement } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent'
import { isComponentTypeMetricsGroupedGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent'
import { isComponentTypeMetricsPieChart, labelFromMetricsPieChartPieElement } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent'
import { isComponentTypeMetricsTransitionGraph } from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent'
import { isComponentTypeMetricsCard, isMetricsCardComponentCompleted } from 'src/models/new/Component/MetricsComponent/metricsCardComponent'
import { isComponentTypeMetricsList } from 'src/models/new/Component/MetricsComponent/metricsListComponent'
import { isComponentTypeMetricsTable } from 'src/models/new/Component/MetricsComponent/metricsTableComponent'
import { Component } from 'src/models/new/component'
import { ContainerPositionIdentifier } from 'src/models/new/conditionalStatement'
import { Metrics } from 'src/models/new/metrics'

export type ConditionalStatementContainer = ContainerPositionIdentifier & {
  primaryPositionId: number
  secondaryPositionId: number
  label: {
    position: string
    name: string
  }
  availableBaseTypeOptions: { value: string, label: string }[]
  availableThresholdTypeOptions: { value: string, label: string }[]
  availableTargetOptions: { value: string, label: string }[]
  metrics: Metrics
}

const optionSelf = { value: 'self', label: '自身のメトリクス' }
const optionReference = { value: 'reference', label: '参考値' }
const optionConstant = { value: 'constant', label: '定数' }
const optionMetrics = { value: 'metrics', label: 'メトリクス' }

const optionTargetText = { value: 'text', label: 'テキスト' }
const optionTargetIcon = { value: 'icon', label: 'アイコン' }
const optionTargetBackground = { value: 'background', label: '背景' }
const optionTargetGraph = { value: 'graph', label: 'グラフ' }

export const getContainersFromComponent = (component: Component): ConditionalStatementContainer[] => {
  if (isComponentTypeMetricsCard(component) && isMetricsCardComponentCompleted(component)) {
    return [{
      primaryPositionId: 1,
      secondaryPositionId: 1,
      label: {
        position: '主メトリクス',
        name: component.metrics.name,
      },
      availableBaseTypeOptions: [optionSelf, optionReference, optionConstant, optionMetrics],
      availableThresholdTypeOptions: [optionReference, optionConstant, optionMetrics],
      availableTargetOptions: [optionTargetText, optionTargetIcon, optionTargetBackground],
      metrics: component.metrics,
    }]
  } else if (isComponentTypeMetricsList(component)) {
    const data = [...component.data.layout]
    return data.sort((a, b) => {
      return a.sequentialOrder === b.sequentialOrder
        ? (a.subMetricsNumber || 0) - (b.subMetricsNumber || 0)
        : a.sequentialOrder - b.sequentialOrder
    }).map(el => {
      const mainDirection = component.isVertical ? '列' : '行'
      return {
        primaryPositionId: el.sequentialOrder,
        secondaryPositionId: el.isSubMetrics ? el.subMetricsNumber! + 1 : 1,
        label: {
          position: `${el.sequentialOrder}${mainDirection}目の${el.isSubMetrics ? '参考値' : '主メトリクス'}`,
          name: el.metrics.name,
        },
        availableBaseTypeOptions: el.isSubMetrics
          ? [optionSelf, optionConstant, optionMetrics]
          : [optionSelf, optionReference, optionConstant, optionMetrics],
        availableThresholdTypeOptions: el.isSubMetrics
          ? [optionConstant, optionMetrics]
          : [optionReference, optionConstant, optionMetrics],
        availableTargetOptions: [optionTargetText, optionTargetIcon, optionTargetBackground],
        metrics: el.metrics,
      }
    })
  } else if (isComponentTypeMetricsTable(component)) {
    const data = [...component.data]
    return data.sort((a, b) => {
      return a.row === b.row ? (a.column || 0) - (b.column || 0) : a.row - b.row
    }).map(el => {
      return {
        primaryPositionId: el.row,
        secondaryPositionId: el.column,
        label: {
          position: `${el.row}行目${el.column}列目`,
          name: el.metrics.name,
        },
        availableBaseTypeOptions: [optionSelf, optionConstant, optionMetrics],
        availableThresholdTypeOptions: [optionConstant, optionMetrics],
        availableTargetOptions: [optionTargetText, optionTargetIcon, optionTargetBackground],
        metrics: el.metrics,
      }
    })
  } else if (isComponentTypeMetricsPieChart(component)) {
    const data = [...component.data]
    return data.sort((a, b) => a.internalOrder - b.internalOrder).map(el => {
      return {
        primaryPositionId: 1,
        secondaryPositionId: el.internalOrder,
        label: {
          position: `${el.internalOrder}番目の要素`,
          name: labelFromMetricsPieChartPieElement(el),
        },
        availableBaseTypeOptions: [optionSelf, optionConstant, optionMetrics],
        availableThresholdTypeOptions: [optionConstant, optionMetrics],
        availableTargetOptions: [optionTargetGraph],
        metrics: el.metrics,
      }
    })
  } else if (isComponentTypeMetricsBarGraph(component)) {
    const bars = [...component.data.bars]
    return bars.sort((a, b) => a.sequentialOrder - b.sequentialOrder).map(el => {
      return {
        primaryPositionId: 1,
        secondaryPositionId: el.sequentialOrder,
        label: {
          position: `${el.sequentialOrder}番目の要素`,
          name: labelFromMetricsBarGraphBarElement(el),
        },
        availableBaseTypeOptions: [optionSelf, optionReference, optionConstant, optionMetrics],
        availableThresholdTypeOptions: [optionReference, optionConstant, optionMetrics],
        availableTargetOptions: [optionTargetGraph],
        metrics: el.metrics,
      }
    })
  } else if (isComponentTypeMetricsGroupedGraph(component)) {
    const graphs = [...component.data.graphs]
    return graphs.filter(graph => isGraphLayoutAcceptingConditionalStatements(graph))
      .sort((a, b) => orderFromGraphLayout(a) - orderFromGraphLayout(b))
      .map(graph => {
        return graph.plots.map(plot => {
          return {
            primaryPositionId: orderFromGraphLayout(graph),
            secondaryPositionId: plot.group,
            label: {
              position: `${GRAPH_LAYOUT_SCALE_POSITION_NAMES[graph.scale]}${graph.number}のグループ${plot.group}`,
              name: `${plot.label || plot.metrics.name}`,
            },
            availableBaseTypeOptions: [optionSelf, optionReference, optionConstant, optionMetrics],
            availableThresholdTypeOptions: [optionReference, optionConstant, optionMetrics],
            availableTargetOptions: [optionTargetGraph],
            metrics: plot.metrics,
          }
        }).flat()
      }).flat()
  } else if (isComponentTypeMetricsTransitionGraph(component)) {
    const graphs = [...component.data.graphs]
    return graphs.filter(el => {
      return el.metrics && isGraphLayoutAcceptingConditionalStatements(el)
    }).sort((a, b) => {
      return orderFromGraphLayout(a) - orderFromGraphLayout(b)
    }).map(el => {
      return {
        primaryPositionId: orderFromGraphLayout(el),
        secondaryPositionId: 1,
        label: {
          position: `${GRAPH_LAYOUT_SCALE_POSITION_NAMES[el.scale]}${el.number}`,
          name: `${el.label || el.metrics!.name}`,

        },
        availableBaseTypeOptions: [optionSelf, optionReference, optionConstant, optionMetrics],
        availableThresholdTypeOptions: [optionReference, optionConstant, optionMetrics],
        availableTargetOptions: [optionTargetGraph],
        metrics: el.metrics!,
      }
    })
  }

  // コンポーネントタイプが想定外の場合は空の配列を返す
  return []
}
