import { validRecordIdOrNull } from 'src/util/recordId'
import { ShallowReactive, WatchStopHandle, ref, watch } from 'vue'

export const useRecordIdProxy = () => {
  const proxyValue = ref<number | null>(null)

  const watchStopHandles: WatchStopHandle[] = []

  const bind = <T>(reactiveObject: ShallowReactive<T>, key: keyof T) => {
    watchStopHandles.forEach((watchStopHandle) => watchStopHandle())
    watchStopHandles.length = 0

    // FIXME: 型的に安全な書き方にできなかったので、値をチェックして型が違ったらバインドしない対処を取った
    if (typeof reactiveObject[key] !== 'number') return

    proxyValue.value = validRecordIdOrNull(reactiveObject[key] as number)

    watchStopHandles.push(
      watch(reactiveObject, (newTargetValue) => {
        proxyValue.value = validRecordIdOrNull(newTargetValue[key] as number)
      }),
    )
    watchStopHandles.push(
      watch(proxyValue, (newProxyValue) => {
        reactiveObject[key] = (newProxyValue ?? 0) as ShallowReactive<T>[keyof T]
      }),
    )
  }

  return {
    proxyValue,
    bind,
  }
}
