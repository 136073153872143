import { Metrics, internal } from 'src/models/new/metrics'
import { LogiSystemDataMetrics } from '../logiSystemDataMetrics'
import { TIME_SPAN_DAILY } from 'src/business/timeSpan'
import { AGGREGATE_FUNCTION_SUM } from 'src/business/aggregateFunction'

const LOGIBOARD = internal.LOGIBOARD

export type LogiboardDataMetrics = LogiSystemDataMetrics

export const isMetricsLogiboardDataMetrics = (metrics: Metrics): metrics is LogiboardDataMetrics => {
  return metrics.metricsType === LOGIBOARD
}

export const constructEmptyLogiboardDataMetrics = (): LogiboardDataMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: LOGIBOARD,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    logiDataSourceId: 0,
    aggrFunc: AGGREGATE_FUNCTION_SUM,
    queryParameters: [],
    budgetGroupId: 0,
    updatedAt: null,
  }
}
