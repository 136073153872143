import axios from 'axios';
import { UserViewSetting } from 'src/models/new/userViewSetting';
import { convertFromUserViewSettingResponse } from 'src/models/api/userViewSettingResponse';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/user_view_settings';

export default {
  async show(): Promise<UserViewSetting> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return convertFromUserViewSettingResponse(resData);
  },
};
