import { AccessGroup, internal } from '../accessGroup';

const METRICS = internal.METRICS;
const READ_ONLY = internal.READ_ONLY;

export type MetricsAccessGroup = AccessGroup;

export const constructEmptyMetricsAccessGroup = (): MetricsAccessGroup => {
  return {
    id: 0,
    name: '',
    type: METRICS,
    role: READ_ONLY,
    searchId: '',
  };
};
