import { computed, getCurrentInstance, ref } from 'vue';
import { ComponentLink } from 'src/models/new/Link/componentLink';
import componentLinkApi from 'src/apis/componentLink';
import type { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/types';

export const useComponentLinkTooltip = ({ reportId, componentId }: { reportId: number; componentId: number }) => {
  const componentLinks = ref<ComponentLink[]>([]);

  const loadComponentLinks = async () => {
    componentLinks.value = (await componentLinkApi.index(reportId, componentId)).sort(
      (a, b) => a.sequentialOrder - b.sequentialOrder,
    );
  };

  const onBeforeLinkTooltipOpen = () => {
    loadComponentLinks();
  };

  const vueInstance = getCurrentInstance()!.proxy;

  const componentLinkTooltipMenus = computed<TooltipMenuSettings[]>(() => {
    return componentLinks.value.map((el) => {
      return {
        text: el.name,
        link: {
          to: {
            path: el.url,
            query: { dt: vueInstance.$route.query.dt },
          },
          target: '_blank',
        },
      };
    });
  });

  return {
    componentLinkTooltipMenus,
    onBeforeLinkTooltipOpen,
  };
};
