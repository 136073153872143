import { DecimalString } from 'src/models/common'
import { LogiCoredataWorkplace } from 'src/models/new/logiCoredataWorkplace'

export type LogiCoredataWorkplaceResponseData = {
  id: number
  company_id: number
  name: string
  address: string
  cost_calc_type: string
  contract_type: string
  code_reader_type: string
  overtime_midnight_extra_rate: DecimalString
  holiday_midnight_extra_rate: DecimalString
  use_budget_group: boolean
  use_activity_group: boolean
  use_staff_label: boolean
  use_staff_agency: boolean
  use_shift_time: boolean
  use_appropriation_dt: boolean
  use_head_count: boolean
  use_multiple_activity_unit: boolean
  use_activity_memo: boolean
  use_create_and_update_info: boolean
  use_activity_time_overlap_check: boolean
  created_at: string
  updated_at: string
}

export const convertFromLogiCoredataWorkplaceResponseData = (data: LogiCoredataWorkplaceResponseData): LogiCoredataWorkplace => {
  return {
    id: data.id,
    name: data.name,
  }
}
