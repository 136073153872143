import { RecordId } from 'src/util/recordId'
import { Section } from 'src/models/new/section'

export type SectionResponseData = {
  id: RecordId
  name: string | null
  disp_order: number
}

// コンポーネントは日付を利用するが、日付を持っているのはレポートである為引数を介して受け渡す
export const convertFromSectionResponseData = (response: SectionResponseData): Section => {
  return {
    id: response.id,
    name: response.name,
    sequentialOrder: response.disp_order,
    // バックエンドのレスポンスを変更してからフロントエンドをリファクタリングするまでの暫定処置
    // ダミーで空の配列を与え、呼び出し元で適切な値を与える
    components: [],
  }
}
