import { getCurrentInstance } from 'vue'
import { ReportLink } from 'src/models/new/Link/reportLink'
import reportLinkApi from 'src/apis/masters/reportLink'
import { useNotifiers } from 'src/composables/useNotifiers'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts'

export const useReportLinkCreator = () => {
  const vue = getCurrentInstance()!.proxy

  const {
    notifySuccess,
    notifyError,
  } = useNotifiers()

  const createReportLink = async(reportId: number, reportLink: ReportLink): Promise<void> => {
    try {
      await reportLinkApi.create(reportId, reportLink)
      notifySuccess('リンクを作成しました。')
      vue.$emit('updated')
    } catch (error: any) {
      const status = error.response.status
      if ([403, 404].includes(status)) {
        const message = '作成権限がありません。管理者にお問合せください。'
        notifyError(message, ERROR_GROUP_USER, error)
      } else if (status === 409) {
        const message = 'リンクの作成に失敗しました。レポートが編集されています。'
        notifyError(message, ERROR_GROUP_USER, error)
      } else {
        const message = 'リンクの作成に失敗しました。'
        notifyError(message, ERROR_GROUP_SYSTEM, error)
      }
      vue.$emit('failed')
    }
  }

  return {
    createReportLink,
  }
}
