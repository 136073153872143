
import { computed, defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
import type { MapObject } from 'vee-validate';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import closingDateApi from 'src/apis/masters/closingDate';
import companyViewSettingApi from 'src/apis/masters/companyViewSetting';
import type { LogiscopeCompanyDetail } from 'src/models/logiscopeCompanyDetail';
import type { CompanyViewSetting } from 'src/models/new/companyViewSetting';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { DAY_OF_WEEK_LOCAL_WORDS, type DayOfWeek, dayOfWeekToNumber } from 'src/util/week';
import { isLoginUserReportAdmin } from 'src/models/new/User/loginUser';
import ClosingSettingsTable from './components/ClosingSettingsTable.vue';
import ViewSettingsTable from './components/ViewSettingsTable.vue';
import { useReportsProvider } from 'src/composables/asyncResources/useReports';

type DayOfWeekOption = {
  value: number;
  label: string;
};

// FIXME: 曜日はアプリケーション内ではDayOfWeek型のEnum文字列だが
// テーブルからの変換層がまだできていないのでvalueはnumberにしている
// 変換層を実装する際にはnumberで扱う処理を残しておく必要はない
const DAY_OF_WEEK_OPTIONS: DayOfWeekOption[] = Object.keys(DAY_OF_WEEK_LOCAL_WORDS).map((el) => {
  return {
    value: dayOfWeekToNumber(el as DayOfWeek),
    label: DAY_OF_WEEK_LOCAL_WORDS[el as DayOfWeek],
  };
});

type State = {
  pageName: string | null;
  isLoaded: boolean;
  isEtceteraLoaded: boolean;
  isReportsLoaded: boolean;
  userId: number | null;
  logiscopeCompanyDetail: LogiscopeCompanyDetail | null;
  companyViewSetting: CompanyViewSetting | null;
  companyViewSettingCandidate: CompanyViewSetting | null;
  showSaveModal: boolean;

  validations: MapObject;
  hasPageAccessRole: boolean;
  showEditButton: boolean;
};

const getValidationMap = (): object => {
  return {
    accounting_month: { required: true, numeric: true, max: 2, min_value: 1, max_value: 12 },
    closing_date: { required: true, numeric: true, max: 2, min_value: 1, max_value: 31 },
    start_day: { required: true, numeric: true, max: 2, min_value: 1, max_value: 6 },
  };
};

export default defineComponent({
  components: {
    ClosingSettingsTable,
    ViewSettingsTable,
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, '会社設定');

    const state: State = reactive({
      ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
      isLoaded: computed(() => state.isEtceteraLoaded && state.isReportsLoaded),
      isEtceteraLoaded: false,
      isReportsLoaded: false,
      userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
      logiscopeCompanyDetail: null,
      companyViewSetting: null,
      companyViewSettingCandidate: null,
      showSaveModal: false,
      validations: getValidationMap(),
      hasPageAccessRole: true,
      showEditButton: false,
    });

    const loadLogiscopeCompanyDetail = async (): Promise<void> => {
      try {
        state.logiscopeCompanyDetail = await closingDateApi.show();
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    };

    const onReportsLoad = (): void => {
      state.isReportsLoaded = true;
    };
    // FIXME: 1000件を越えた場合にロードされたレポートがホームレポートを含まない可能性があり、設計を見直すべき
    const { reportsRef } = useReportsProvider({ onLoad: onReportsLoad });

    const loadCompanyViewSetting = async (): Promise<void> => {
      try {
        state.companyViewSetting = await companyViewSettingApi.show();
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    };

    const openSaveModal = (): void => {
      state.companyViewSettingCandidate = structuredClone(state.companyViewSetting);
      state.showSaveModal = true;
    };

    const closeSaveModal = (): void => {
      state.showSaveModal = false;
    };

    const save = async (): Promise<void> => {
      try {
        if (state.companyViewSettingCandidate) {
          await companyViewSettingApi.update(state.companyViewSettingCandidate);
        }
        await loadCompanyViewSetting();
        closeSaveModal();
        notifySuccess1(root, '会社設定を更新しました');
      } catch (err: any) {
        const errId = 'ERR00002';
        const errStatus = err.response.status;
        if (errStatus === 403) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
        } else {
          const msg =
            `${state.pageName}の更新に失敗しました。管理者に連絡してください。` +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    onMounted(async () => {
      state.isEtceteraLoaded = false;
      state.isReportsLoaded = false;

      await Promise.all([ensureUserAndMasters(root), loadLogiscopeCompanyDetail(), loadCompanyViewSetting()]);
      const loginUser = root.$store.getters['loginUser/data'];
      state.showEditButton = isLoginUserReportAdmin(loginUser);
      state.isEtceteraLoaded = true;
    });

    return {
      state,
      DAY_OF_WEEK_OPTIONS,
      reportsRef,
      openSaveModal,
      closeSaveModal,
      save,
    };
  },
});
