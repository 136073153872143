export type RecordId = number

type EntityWithId = {
  id: RecordId
}

export const isRecordIdValid = (recordId: RecordId): boolean => Number.isInteger(recordId) && recordId > 0
export const isRecordIdMeansNotPersisted = (recordId: RecordId): boolean => recordId <= 0
// 主にレンダリングの際にrequiredであるidフィールドをnullに変換するために用いる
export const validRecordIdOrNull = (recordId: RecordId): RecordId | null => {
  return isRecordIdValid(recordId) ? recordId : null
}
export const getTemporaryRecordId = (entities: EntityWithId[] | null = null): RecordId => {
  if (!entities) return 0
  return entities.reduce((min, el) => Math.min(min, el.id), 1) - 1
}
