import { Component, internal } from '../component'

const COMMENT = internal.COMMENT

export const COMMENT_COMPONENT_MIN_WIDTH = 1
export const COMMENT_COMPONENT_MAX_WIDTH = 6
export const COMMENT_COMPONENT_MIN_HEIGHT = 1
export const COMMENT_COMPONENT_MAX_HEIGHT = 1

export type CommentComponent = Component & {
  placeholder: string | null
  value: string | null
}

export const isComponentTypeComment = (component: Component): component is CommentComponent => {
  return component.componentType === COMMENT
}

export const constructEmptyCommentComponent = (): CommentComponent => {
  return {
    id: 0,
    sectionId: 0,
    name: '',
    componentType: COMMENT,
    abscissa: 0,
    ordinate: 0,
    width: COMMENT_COMPONENT_MIN_WIDTH,
    height: COMMENT_COMPONENT_MIN_HEIGHT,
    placeholder: null,
    value: null,
  }
}
