import {
  ConversionProgramStatus,
  DATA_CONVERSION_STATUS,
  REQUESTS_COMPLETION_STATUS,
} from 'src/models/conversionProgramStatus';
import { DateString } from 'src/models/common';
import { parseDatetime, parseYmdDate } from 'src/util/Datetime/parse';

export type ConversionProgramStatusResponseData = {
  id: number;
  dt: DateString;
  file_name: string;
  data_conversion_status: DATA_CONVERSION_STATUS;
  data_conversion_status_text: string;
  requests_completion_status: REQUESTS_COMPLETION_STATUS;
  error_message: string | null;
  last_request_time: DateString | null;
};

export const convertConversionProgramStatusResponseData = (
  data: ConversionProgramStatusResponseData,
): ConversionProgramStatus => {
  return {
    id: data.id,
    dt: parseYmdDate(data.dt),
    fileName: data.file_name,
    dataConversionStatus: data.data_conversion_status,
    dataConversionStatusText: data.data_conversion_status_text,
    requestsCompletionStatus: data.requests_completion_status,
    errorMessage: data.error_message,
    lastRequestTime: data.last_request_time ? parseDatetime(data.last_request_time) : null,
  };
};
