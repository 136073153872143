import { Metrics } from 'src/models/new/metrics';
import {
  MetricsCreateRequestParameters,
  convertToMetricsCreateRequestParameters,
} from './metricsCreateRequestParameters';

export type MetricsUpdateRequestParameters = MetricsCreateRequestParameters & {
  id: number;
};

export const convertToMetricsUpdateRequestParameters = (metrics: Metrics): MetricsUpdateRequestParameters => {
  const params = convertToMetricsCreateRequestParameters(metrics);
  return {
    ...params,
    id: metrics.id,
  };
};
