import { DateString } from 'src/models/common'
import { format, parse } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { SYSTEM_DATETIME_STRICT_FORMAT, SYSTEM_DATE_FORMAT } from './format'

// 参考: 年-月-日 時:分:秒 => yyyy-MM-dd HH:mm:ss
export function formatDate(dt: Date, formatString: string): string {
  return format(dt, formatString, { locale: ja })
}

// railsが返却したDatetime型をパースする時に利用する.
// formatStringのデフォルトは、railsがdatetimeを返却する時のデフォルトである iso8601
export function parseDatetime(str: DateString, formatString = SYSTEM_DATETIME_STRICT_FORMAT): Date {
  return parse(str, formatString, new Date())
}

// railsが返却したDate型をパースする時に利用する.
export function parseYmdDate(str: DateString): Date {
  return parse(str, SYSTEM_DATE_FORMAT, new Date())
}
