import { Section } from 'src/models/new/section'

export type SectionCreateRequestParameters = {
  name: string | null
  disp_order: number
}

export const convertToSectionCreateRequestParameters = (section: Section): SectionCreateRequestParameters => {
  return {
    name: section.name || null,
    disp_order: section.sequentialOrder,
  }
}
