import axios from 'axios';
import { WorkplaceBulkUpdateDispOrderRequestParameters } from 'src/models/api/Workplace/workplaceBulkUpdateDispOrderRequestParameters';
import { WorkplaceCreateOrUpdateRequestParameters } from 'src/models/api/Workplace/workplaceCreateOrUpdateRequestParameters';
import {
  convertFromWorkplaceResponseData,
  WorkplaceResponseData,
} from 'src/models/api/Workplace/workplaceResponseData';
import { Workplace } from 'src/models/new/workplace';
import { buildBackendUrl } from 'src/apis/util/url';

const buildBasePath = (): string => '/masters/logiscope_workplaces';

export default {
  async index(): Promise<Workplace[]> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return (resData as WorkplaceResponseData[]).map(convertFromWorkplaceResponseData);
  },
  async create(reqParams: WorkplaceCreateOrUpdateRequestParameters): Promise<Workplace> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.post(url, reqParams);
    return convertFromWorkplaceResponseData(resData as WorkplaceResponseData);
  },
  async update(id: number, reqParams: WorkplaceCreateOrUpdateRequestParameters): Promise<Workplace> {
    const url = buildBackendUrl(buildBasePath(), id);
    const { data: resData } = await axios.put(url, reqParams);
    return convertFromWorkplaceResponseData(resData as WorkplaceResponseData);
  },
  async destroy(id: number): Promise<Workplace> {
    const url = buildBackendUrl(buildBasePath(), id);
    const { data: resData } = await axios.delete(url);
    return convertFromWorkplaceResponseData(resData as WorkplaceResponseData);
  },
  async bulkUpdateDispOrder(reqParams: WorkplaceBulkUpdateDispOrderRequestParameters): Promise<Workplace[]> {
    const url = buildBackendUrl(buildBasePath(), 'bulk_update_disp_order');
    const { data: resData } = await axios.post(url, reqParams);
    return (resData as WorkplaceResponseData[]).map(convertFromWorkplaceResponseData);
  },
};
