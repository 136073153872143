
import { defineComponent, PropType } from 'vue'
import LinkList from 'src/views/Dashboard/Settings/Reports/shared/components/LinkList/LinkList.vue'
import { ComponentLink } from 'src/models/new/Link/componentLink'
import { useComponentLinkOrderUpdater } from 'src/views/Dashboard/Settings/Reports/Components/Links/components/ComponentLinkList/composables/useComponentLinkOrderUpdater'

export default defineComponent({
  components: {
    LinkList,
  },
  props: {
    componentLinks: {
      type: Array as PropType<ComponentLink[]>,
      required: true,
    },
    reportId: {
      type: Number,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
    edit: {
      type: Function as PropType<(link: ComponentLink) => Promise<void>>,
      required: true,
    },
    del: {
      type: Function as PropType<(link: ComponentLink) => Promise<void>>,
      required: true,
    },
  },
  emits: ['updated', 'failed'],
  setup(props) {
    const {
      updateComponentLinkOrder,
    } = useComponentLinkOrderUpdater()

    const updateOrder = async(componentLinks: ComponentLink[]) => {
      await updateComponentLinkOrder(props.reportId, props.componentId, componentLinks)
    }

    return {
      props,
      updateOrder,
    }
  },
})
