export const TIME_SPAN_DAILY = 'daily';
export const TIME_SPAN_WEEKLY = 'weekly';
export const TIME_SPAN_MONTHLY = 'monthly';
export const TIME_SPAN_YEARLY = 'yearly';

export type TimeSpan =
  | typeof TIME_SPAN_DAILY
  | typeof TIME_SPAN_WEEKLY
  | typeof TIME_SPAN_MONTHLY
  | typeof TIME_SPAN_YEARLY;

export const isTimeSpanDaily = (timeSpan: TimeSpan): boolean => timeSpan === TIME_SPAN_DAILY;
export const isTimeSpanWeekly = (timeSpan: TimeSpan): boolean => timeSpan === TIME_SPAN_WEEKLY;
export const isTimeSpanMonthly = (timeSpan: TimeSpan): boolean => timeSpan === TIME_SPAN_MONTHLY;
export const isTimeSpanYearly = (timeSpan: TimeSpan): boolean => timeSpan === TIME_SPAN_YEARLY;

export const TIME_SPAN_LOCAL_WORDS = {
  [TIME_SPAN_DAILY]: '日次',
  [TIME_SPAN_WEEKLY]: '週次',
  [TIME_SPAN_MONTHLY]: '月次',
  [TIME_SPAN_YEARLY]: '年次',
};

export const ALL_TIME_SPANS: TimeSpan[] = [TIME_SPAN_DAILY, TIME_SPAN_WEEKLY, TIME_SPAN_MONTHLY, TIME_SPAN_YEARLY];

export const timeSpanToLocalWord = (timeSpan: TimeSpan): string => TIME_SPAN_LOCAL_WORDS[timeSpan];

export const getSmallerTimeSpans = (timeSpan: TimeSpan): TimeSpan[] => {
  switch (timeSpan) {
    case TIME_SPAN_DAILY:
      return [];
    case TIME_SPAN_WEEKLY:
      return [TIME_SPAN_DAILY];
    case TIME_SPAN_MONTHLY:
      return [TIME_SPAN_DAILY, TIME_SPAN_WEEKLY];
    case TIME_SPAN_YEARLY:
      return [TIME_SPAN_DAILY, TIME_SPAN_WEEKLY, TIME_SPAN_MONTHLY];
    default:
      throw new Error(`Unexpected timeSpan: ${timeSpan}`);
  }
};

export const isTimeSpanLarger = (a: TimeSpan, b: TimeSpan): boolean => getSmallerTimeSpans(a).includes(b);

// time_spanのソート用
export const compareFuncTimeSpan = (a: TimeSpan, b: TimeSpan): number => {
  const timeSpanSortOrderMap: Record<TimeSpan, number> = { daily: 10, weekly: 20, monthly: 30, yearly: 40 };
  return timeSpanSortOrderMap[a] - timeSpanSortOrderMap[b];
};
