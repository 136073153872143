import { type Ref, inject, provide, ref, watch } from 'vue';
import { generateInjectionKey } from 'src/composables/asyncResources/logics/generateInjectionKey';
import { UserConfigurableColor } from 'src/util/userConfigurableColor';
import Color = UserConfigurableColor.Color;

type UseColorProviderResult = {
  darkColors: Ref<Color[]>;
  lightColors: Ref<Color[]>;
  realizeLoaded: () => void;
};

type UseColorResult = {
  darkColors: Ref<Color[]>;
  lightColors: Ref<Color[]>;
};

const key = generateInjectionKey('colors');

export const useColorProvider = (): UseColorProviderResult => {
  const darkColors = ref([]) as Ref<Color[]>;
  const lightColors = ref([]) as Ref<Color[]>;
  const isLoaded = ref(false) as Ref<boolean>;

  const realizeLoaded = (): void => {
    isLoaded.value = true;
    darkColors.value = UserConfigurableColor.darkColors();
    lightColors.value = UserConfigurableColor.lightColors();
  };

  provide(key, {
    darkColors,
    lightColors,
  });

  return {
    darkColors,
    lightColors,
    // FIXME:
    // ロードが完了したことを手動で伝えるかなりイマイチなインターフェース
    // 既存のモジュールとうまく組み合わせてより合理的なインターフェースができればそれに越したことはない
    realizeLoaded,
  };
};

export const useColor = (): UseColorResult => {
  const injection = inject(key, null) as UseColorResult | null;
  if (!injection) {
    console.error(`${key.toString()} is not provided`);
    return {
      darkColors: ref<Color[]>([]) as Ref<Color[]>,
      lightColors: ref<Color[]>([]) as Ref<Color[]>,
    };
  }
  const darkColors = injection.darkColors;
  const lightColors = injection.lightColors;

  return {
    darkColors,
    lightColors,
  };
};
