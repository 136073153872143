import { ComponentResponseData, extractLastSearchedDate } from '../componentResponseData'
import { MetricsCardComponent } from 'src/models/new/Component/MetricsComponent/metricsCardComponent'
import { ComponentType, internal as componentInternal } from 'src/models/new/component'
import { Metrics } from 'src/models/new/metrics'
import { convertFromMetricsResponseData } from 'src/models/api/Metrics/metricsResponseData'
import { buildBaseComponentProperties } from './buildBaseComponentProperties'
import { buildConditionalStatementProperties } from './buildConditionalStatementProperties'

const METRICS_CARD = componentInternal.METRICS_CARD

export const buildMetricsCardComponentProperties = (data: ComponentResponseData, referenceDate: Date | null): MetricsCardComponent => {
  const componentType: ComponentType = METRICS_CARD

  // 編集用にデータをロードした場合は存在しない
  // closingDateMapがnullの場合は編集用で、valueやclosingDateが存在しないものとして扱う
  const closingDateMap = data.single_value_component!.metrics_value_searched_dates_map ?? null

  const metrics: Metrics = convertFromMetricsResponseData(data.single_value_component!.metrics, {})

  if (closingDateMap) {
    // レスポンスの値は日付が文字列の為、代わりに値を補う場合も文字列に変換する
    // target_metrics_valueはレコードが存在しない場合レスポンスに含まれない
    const valueOfMetrics = data.single_value_component!.target_metrics_value?.value ?? null
    metrics.value = valueOfMetrics !== null ? Number(valueOfMetrics) : null
    metrics.closingDate = extractLastSearchedDate(closingDateMap, metrics.timeSpan)
  }

  const subMetrics: Metrics | null = data.single_value_component!.sub_metrics
    ? convertFromMetricsResponseData(data.single_value_component!.sub_metrics, {})
    : null

  if (subMetrics && closingDateMap) {
    const valueOfSubMetrics = data.single_value_component!.target_sub_metrics_value?.value ?? null
    subMetrics.value = valueOfSubMetrics ? Number(valueOfSubMetrics) : null
    subMetrics.closingDate = extractLastSearchedDate(closingDateMap, subMetrics.timeSpan)
  }

  const conditionalStatements = data.component_conditional_statements.map(statement => {
    return buildConditionalStatementProperties(statement, closingDateMap)
  })

  return {
    ...buildBaseComponentProperties(data, componentType),
    referenceDate: referenceDate,
    unit: data.single_value_component!.unit_disp_name as string,
    subMetricsName: data.single_value_component!.sub_disp_name,
    metrics: metrics,
    subMetrics: subMetrics,
    conditionalStatements,
  }
}
