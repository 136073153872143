import * as DateFns from 'date-fns';
import { PeriodUnitWithValue } from 'src/business/period';

export function rewindDate(dt: Date, rewindValue: number, rewindUnit: PeriodUnitWithValue): Date {
  switch (rewindUnit) {
    case 'years':
      return DateFns.subYears(dt, rewindValue);
    case 'months':
      return DateFns.subMonths(dt, rewindValue);
    case 'weeks':
      return DateFns.subWeeks(dt, rewindValue);
    case 'days':
      return DateFns.subDays(dt, rewindValue);
  }
}

const isSameDay = (dateLeft: Date, dateRight: Date): boolean => DateFns.isSameDay(dateLeft, dateRight);
const earliest = (...dates: Date[]) => DateFns.min(dates);
const latest = (...dates: Date[]) => DateFns.max(dates);

const startOfDay = (date: Date): Date => DateFns.startOfDay(date);
const endOfDay = (date: Date): Date => DateFns.endOfDay(date);
const startOfToday = (): Date => DateFns.startOfToday();
const endOfToday = (): Date => DateFns.endOfToday();
const addDays = (date: Date, amount: number): Date => DateFns.addDays(date, amount);
const subDays = (date: Date, amount: number): Date => DateFns.subDays(date, amount);

const differenceInDays = (dateLeft: Date, dateRight: Date): number => DateFns.differenceInDays(dateLeft, dateRight);

/**
 * ISO week: 月曜から始まる週で、ISOの規格により週番号が決まる
 */
const startOfIsoWeek = (date: Date): Date => {
  return DateFns.isMonday(date) ? date : DateFns.previousMonday(date);
};
const endOfIsoWeek = (date: Date): Date => {
  return DateFns.isSunday(date) ? date : DateFns.nextSunday(date);
};
const addWeeks = (date: Date, amount: number): Date => DateFns.addWeeks(date, amount);
const subWeeks = (date: Date, amount: number): Date => DateFns.subWeeks(date, amount);

const isSameMonth = (dateLeft: Date, dateRight: Date): boolean => DateFns.isSameMonth(dateLeft, dateRight);
const startOfMonth = (date: Date): Date => DateFns.startOfMonth(date);
const endOfMonth = (date: Date): Date => DateFns.endOfMonth(date);
const addMonths = (date: Date, amount: number): Date => DateFns.addMonths(date, amount);
const subMonths = (date: Date, amount: number): Date => DateFns.subMonths(date, amount);

const startOfYear = (date: Date): Date => DateFns.startOfYear(date);
const endOfYear = (date: Date): Date => DateFns.endOfYear(date);
const addYears = (date: Date, amount: number): Date => DateFns.addYears(date, amount);
const subYears = (date: Date, amount: number): Date => DateFns.subYears(date, amount);

export {
  isSameDay,
  earliest,
  latest,
  startOfDay,
  endOfDay,
  startOfToday,
  endOfToday,
  addDays,
  subDays,
  differenceInDays,
  startOfIsoWeek,
  endOfIsoWeek,
  addWeeks,
  subWeeks,
  isSameMonth,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  startOfYear,
  endOfYear,
  addYears,
  subYears,
};
