import { SidebarLink } from 'src/components/UIComponents/Sidebar/createSidebarStore'

export const resolvedSidebarLinksMap: Record<string, SidebarLink[]> = {
  top: [
    {
      name: 'ホーム',
      nameMini: 'ホーム',
      icon: 'fal fa-home',
      // FIXME: Vue Routerを使用した実装に対応させる
      path: '/',
    },
    {
      name: 'レポート',
      nameMini: 'レポート',
      icon: 'fal fa-file-spreadsheet',
      path: '/reports',
    },
    {
      name: 'メトリクス',
      nameMini: 'メトリクス',
      icon: 'fal fa-ruler-triangle',
      path: '/metrics-values',
    },
    {
      name: 'データ変換',
      nameMini: 'データ変換',
      icon: 'fal fa-sync-alt',
      path: '/conversion-programs',
    },
    {
      name: '作成状況',
      nameMini: '作成状況',
      icon: 'fal fa-hourglass-half',
      path: '/data-fetch-statuses',
    },
    {
      name: '設定',
      nameMini: '設定',
      icon: 'fal fa-cog',
      path: '/settings',
    },
  ],
}
