import { RecordId } from 'src/util/recordId';
import { TimeSpan } from 'src/business/timeSpan';
import { Report } from 'src/models/new/report';
import { SectionResponseData, convertFromSectionResponseData } from 'src/models/api/Report/Section/sectionResponseData';
import {
  ComponentResponseData,
  convertFromComponentResponseData,
} from 'src/models/api/Report/Component/componentResponseData';
import { parseYmdDate } from 'src/util/datetime';

export type ReportResponseData = {
  id: RecordId;
  company_id: number;
  name: string;
  time_span: TimeSpan;
  theme_color: string;
  is_enabled: boolean;
  access_group_ids: number[];
  sections?: SectionResponseData[];
  components?: ComponentResponseData[];
  includes_links?: boolean;
  closing_date?: string;
  starting_date?: string;
};

export const convertFromReportResponseData = (response: ReportResponseData): Report => {
  const startingDate = response.starting_date ? parseYmdDate(response.starting_date) : null;
  const closingDate = response.closing_date ? parseYmdDate(response.closing_date) : null;

  const components = response.components?.map((el) => convertFromComponentResponseData(el, closingDate)) ?? [];
  const sections =
    response.sections?.map(convertFromSectionResponseData).map((el) => {
      return {
        ...el,
        components: components.filter((component) => component.sectionId === el.id),
      };
    }) ?? [];

  return {
    id: response.id,
    name: response.name,
    timeSpan: response.time_span,
    themeColor: response.theme_color,
    isEnabled: response.is_enabled,
    sections,
    accessGroupIds: response.access_group_ids,
    includesLinks: response.includes_links,
    startingDate,
    closingDate,
  };
};
