import { getCurrentInstance } from 'vue';
import { isMetricsIndependent, type Metrics } from 'src/models/new/metrics';
import { isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import { formatDate } from 'src/util/datetime';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/types';

type UseMetricsTooltipProps<T extends Record<string, number>> = {
  onMetricsEditButtonClick: (position: T) => unknown;
  metricsFinder: (position: T) => Metrics | undefined;
};

type UseMetricsTooltipResult<T extends Record<string, number>> = {
  generateMetricsTooltipMenus: (position: T) => TooltipMenuSettings[];
};

export const useMetricsTooltip = <T extends Record<string, number>>({
  onMetricsEditButtonClick,
  metricsFinder,
}: UseMetricsTooltipProps<T>): UseMetricsTooltipResult<T> => {
  const vueInstance = getCurrentInstance()!.proxy;

  const onBreakdownButtonClick = (metrics: Metrics): void => {
    if (!metrics.closingDate) {
      console.error('Metrics closingDate is required.');
      return;
    }

    vueInstance.$router.push({
      name: 'MetricsBreakdown',
      params: {
        metricsId: String(metrics.id),
      },
      query: { dt: formatDate(metrics.closingDate, 'yyyy-MM-dd') },
    });
  };

  const generateMetricsTooltipMenus = (position: T): TooltipMenuSettings[] => {
    const metrics = metricsFinder(position);
    if (metrics === undefined) {
      return [];
    }

    return [
      {
        text: '入力',
        iconClasses: 'fal fa-pen',
        onClick: () => {
          if (!isMetricsDirectInputMetrics(metrics)) {
            return;
          }
          onMetricsEditButtonClick(position);
        },
        disabled: !isMetricsDirectInputMetrics(metrics),
      },
      {
        text: '内訳',
        iconClasses: 'fal fa-code-branch fa-rotate-90',
        onClick: () => {
          if (isMetricsIndependent(metrics)) {
            return;
          }
          onBreakdownButtonClick(metrics);
        },
        disabled: isMetricsIndependent(metrics),
      },
    ];
  };

  return {
    generateMetricsTooltipMenus,
  };
};
