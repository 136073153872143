import {
  TimeSpan,
  isTimeSpanDaily,
  isTimeSpanMonthly,
  isTimeSpanWeekly,
  isTimeSpanYearly,
  TIME_SPAN_DAILY,
  TIME_SPAN_WEEKLY,
  TIME_SPAN_MONTHLY,
  TIME_SPAN_YEARLY,
} from 'src/business/timeSpan'

export const PERIOD_THIS_WEEK = 'this_week'
export const PERIOD_THIS_MONTH = 'this_month'
export const PERIOD_THIS_YEAR = 'this_year'
export const PERIOD_DAYS = 'days'
export const PERIOD_WEEKS = 'weeks'
export const PERIOD_MONTHS = 'months'
export const PERIOD_YEARS = 'years'

export type PeriodUnitWithoutValue = typeof PERIOD_THIS_WEEK |
  typeof PERIOD_THIS_MONTH | typeof PERIOD_THIS_YEAR
export type PeriodUnitWithValue = typeof PERIOD_DAYS | typeof PERIOD_WEEKS |
  typeof PERIOD_MONTHS | typeof PERIOD_YEARS
export type PeriodUnit = PeriodUnitWithoutValue | PeriodUnitWithValue
export type PeriodWithoutValue = {
  unit: PeriodUnitWithoutValue
}
export type PeriodWithValue = {
  unit: PeriodUnitWithValue
  value: number
}
export type Period = PeriodWithoutValue | PeriodWithValue

export const constructPeriod = (unit: PeriodUnit, value: number | null = null): Period => {
  if (isPeriodUnitWithoutValue(unit)) return { unit } as PeriodWithoutValue
  if (value === null) throw Error(`Period unit ${unit} requires a value.`)
  return { unit, value: value! } as PeriodWithValue
}

export const isPeriodThisWeek = (period: Period): boolean => period.unit === PERIOD_THIS_WEEK
export const isPeriodThisMonth = (period: Period): boolean => period.unit === PERIOD_THIS_MONTH
export const isPeriodThisYear = (period: Period): boolean => period.unit === PERIOD_THIS_YEAR
export const isPeriodDays = (period: Period): boolean => period.unit === PERIOD_DAYS
export const isPeriodWeeks = (period: Period): boolean => period.unit === PERIOD_WEEKS
export const isPeriodMonths = (period: Period): boolean => period.unit === PERIOD_MONTHS
export const isPeriodYears = (period: Period): boolean => period.unit === PERIOD_YEARS

const isPeriodUnitWithoutValue = (unit: PeriodUnit): boolean => {
  return [PERIOD_THIS_WEEK, PERIOD_THIS_MONTH, PERIOD_THIS_YEAR].includes(unit)
}

export const isPeriodWithoutValue = (period: Period): period is PeriodWithoutValue => {
  return isPeriodUnitWithoutValue(period.unit)
}

const isPeriodUnitWithValue = (unit: PeriodUnit): boolean => !isPeriodUnitWithoutValue(unit)

export const isPeriodWithValue = (period: Period): period is PeriodWithValue => {
  return isPeriodUnitWithValue(period.unit)
}

export const PERIOD_UNIT_LABELS: Record<PeriodUnit, string> = {
  [PERIOD_THIS_WEEK]: '今週',
  [PERIOD_THIS_MONTH]: '今月',
  [PERIOD_THIS_YEAR]: '今年度',
  [PERIOD_DAYS]: '...日',
  [PERIOD_WEEKS]: '...週間',
  [PERIOD_MONTHS]: '...ヶ月',
  [PERIOD_YEARS]: '...年',
}

// Period一般のロジックとして実装されている
// その中でもサポートしない周期との組み合わせが設定されているが
// 推移メトリクス系のロジックではより選択可能な範囲が狭まっているため、個別のロジックは別途参照すること
// あくまで推定する為のロジックであり、返される数字は超えないが一致しない可能性はあるものとして利用する
export const estimateDateCountInPeriod = (period: Period, timeSpan: TimeSpan): number => {
  if (isPeriodWithoutValue(period)) {
    if (isPeriodThisWeek(period)) {
      if (isTimeSpanDaily(timeSpan)) return 7
      if (isTimeSpanWeekly(timeSpan)) return 1
      throw Error(`Time span: ${timeSpan} is not supported for period unit: ${period.unit}`)
    } else if (isPeriodThisMonth(period)) {
      if (isTimeSpanDaily(timeSpan)) return 31
      // 実際にはサポート対象外かもしれないが、使用する場合は5とする
      if (isTimeSpanWeekly(timeSpan)) return Math.ceil(31 / 7)
      if (isTimeSpanMonthly(timeSpan)) return 1
      throw Error(`Time span: ${timeSpan} is not supported for period unit: ${period.unit}`)
    } else if (isPeriodThisYear(period)) {
      // 閏年を考慮して366とする
      if (isTimeSpanDaily(timeSpan)) return 366
      // 実際にはサポート対象外かもしれないが、使用する場合は53とする
      if (isTimeSpanWeekly(timeSpan)) return Math.ceil(366 / 7)
      if (isTimeSpanMonthly(timeSpan)) return 12
      if (isTimeSpanYearly(timeSpan)) return 1
      throw Error(`Invalid time span: ${timeSpan}`)
    }
  } else {
    if (isPeriodDays(period)) {
      if (isTimeSpanDaily(timeSpan)) return period.value
      // 例えばperiod.valueが7の場合は周期がweeklyでも問題ないが、より大きな周期はサポート外とする
      throw Error(`Time span: ${timeSpan} is not supported for period unit: ${period.unit}`)
    }
    if (isPeriodWeeks(period)) {
      if (isTimeSpanDaily(timeSpan)) return period.value * 7
      if (isTimeSpanWeekly(timeSpan)) return period.value
      throw Error(`Time span: ${timeSpan} is not supported for period unit: ${period.unit}`)
    }
    if (isPeriodMonths(period)) {
      if (isTimeSpanDaily(timeSpan)) return period.value * 31
      if (isTimeSpanWeekly(timeSpan)) return Math.ceil(period.value * 31 / 7)
      if (isTimeSpanMonthly(timeSpan)) return period.value
      throw Error(`Time span: ${timeSpan} is not supported for period unit: ${period.unit}`)
    }
    if (isPeriodYears(period)) {
      if (isTimeSpanDaily(timeSpan)) return period.value * 366
      if (isTimeSpanWeekly(timeSpan)) return Math.ceil(period.value * 366 / 7)
      if (isTimeSpanMonthly(timeSpan)) return period.value * 12
      if (isTimeSpanYearly(timeSpan)) return period.value
      throw Error(`Invalid time span: ${timeSpan}`)
    }
  }
  throw Error(`Invalid period: ${period}`)
}
