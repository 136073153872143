
import Chart from 'chart.js'
import { deepMerge } from 'src/util/object'
import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { Pie } from 'vue-chartjs'
import {
  CHART_BASE_COLOR,
  ChartDataLabel,
  ChartElement,
  ChartPlugin,
  RelativePixelPosition,
  buildChartData,
  isIncludingDatalabelsPlugin,
  replaceStagedDataset,
  wrapOnChartClick,
} from 'src/components/UIComponents/Charts/shared'
import { PieChartDataDataset, PieChartOptions } from 'src/util/chart'
import { datalabelsPluginDefaultOptions } from 'src/components/UIComponents/Charts/datalabelsPluginDefaultOptions'
import { tooltipsDefaultOptions } from 'src/components/UIComponents/Charts//tooltipsDefaultOptions'

const defaultOptions: Chart.ChartOptions = {
  responsive: true,
  responsiveAnimationDuration: 0,
  aspectRatio: 2,
  maintainAspectRatio: false,
  title: {
    display: false,
  },
  legend: {
    display: true,
  },
  cutoutPercentage: 0,
  circumference: 2 * Math.PI,
  rotation: -0.5 * Math.PI,
  defaultColor: CHART_BASE_COLOR,
  tooltips: tooltipsDefaultOptions,
}

type State = {
  styles: {
    height: string
    width: string
  }
}

export default defineComponent({
  components: {
    Pie,
  },
  props: {
    labels: {
      type: Array as PropType<ChartDataLabel[]>,
      required: true,
      description: 'Chart labels. Not reactive.',
    },
    datasets: {
      type: Array as PropType<PieChartDataDataset[]>,
      required: true,
      description: 'Chart datasets. Reactive. Do not use computed value.',
    },
    options: {
      type: Object as PropType<PieChartOptions>,
      required: false,
      description: 'Chart options. Half reactive (scrap and build new chart after mutation).',
    },
    plugins: {
      type: Array as PropType<ChartPlugin[]>,
      required: false,
      description: 'Chart plugins. Not reactive.',
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    onClick: {
      type: Function as PropType<(event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void>,
      required: false,
      description: 'Event which is fired when chart is clicked.',
    },
  },
  setup(props) {
    const myChart = ref(null as Pie | null)

    const state: State = reactive({
      styles: computed(() => ({ height: props.height, width: props.width })),
    })

    const setEnabledPluginDefaultOptions = (plugins: ChartPlugin[], options: Chart.ChartOptions): Chart.ChartOptions => {
      if (!options.plugins) {
        options.plugins = {}
      }

      if (isIncludingDatalabelsPlugin(plugins)) {
        Object.assign(options.plugins, { datalabels: datalabelsPluginDefaultOptions })
      }

      return options
    }

    const buildChartOptions = (
      options: PieChartOptions,
      datasets: PieChartDataDataset[],
      plugins?: ChartPlugin[],
      onClick?: (event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void,
    ): Chart.ChartOptions => {
      const chartOptions = deepMerge(
        setEnabledPluginDefaultOptions(plugins ?? [], defaultOptions),
        options ?? {},
      )
      if (onClick) {
        Object.assign(chartOptions, { onClick: wrapOnChartClick(onClick) })
      }

      return chartOptions
    }

    onMounted(async() => {
      const chartData = buildChartData(props.labels, props.datasets)
      const chartOptions = buildChartOptions(
        props.options ?? {},
        props.datasets,
        props.plugins,
        props.onClick,
      )

      // Vue-chartjs 4.x のマニュアルではpropsでdataとoptionsを渡せばよいことになっているが、上手くいかない
      // refでインスタンスを取得してrenderChartを実行するとグラフが描かれた
      myChart.value!.renderChart(chartData, chartOptions)
    })

    // datasetsはdataの要素数が一定である条件の中でインタラクティブに変更されるものとして想定する
    watch(() => props.datasets, (datasets) => {
      myChart.value!.$data._chart.data.datasets.forEach((el: PieChartDataDataset, index: number) => {
        replaceStagedDataset(el, datasets[index])
      })
      myChart.value!.$data._chart.update()
    }, { deep: true })

    // optionsはインタラクティブな変更を受け付ける
    // ただし、datasetsとは異なりグラフを最初から描き直す
    watch(() => props.options, (options) => {
      const chartData = buildChartData(props.labels, props.datasets)
      const chartOptions = buildChartOptions(
        options ?? {},
        props.datasets,
        props.plugins,
        props.onClick,
      )
      myChart.value!.renderChart(chartData, chartOptions)
    }, { deep: true })

    return {
      props,
      state,
      myChart,
    }
  },
})
