
import { defineComponent, computed, getCurrentInstance, type PropType } from 'vue';
import { SYSTEM_MAX_VALUE } from 'src/consts';
import { omitString } from 'src/util/text_decorator';
import type { Metrics } from 'src/models/new/metrics';
import { vvGetError } from 'src/util/vee_validate';

type ConditionOperandType = 'self' | 'reference' | 'metrics' | 'constant';

type Value<T extends ConditionOperandType> = {
  type: T;
  metrics: Metrics | null;
  value: number | null;
};

class ConditionOperandFieldFactory<T extends ConditionOperandType> {
  define() {
    return defineComponent({
      props: {
        value: {
          type: Object as PropType<Value<T>>,
          required: true,
        },
        typeOptions: {
          type: Array as PropType<{ value: T; label: string }[]>,
          required: true,
        },
        showSelectMetricsModal: {
          type: Function as PropType<() => void>,
          required: true,
        },
        metricsFieldName: {
          type: String as PropType<string>,
          required: true,
        },
        valueFieldName: {
          type: String as PropType<string>,
          required: true,
        },
      },
      emits: ['input'],
      setup(props, { emit }) {
        const updateType = (type: string) => {
          // typeが変更された場合はmetricsとvalueを空にする
          emit('input', { type, metrics: null, value: null });
        };

        const updateValue = (value: string) => {
          let updatedValue = value === '' ? null : Number(value);
          if (updatedValue !== null && updatedValue < -SYSTEM_MAX_VALUE) {
            updatedValue = -SYSTEM_MAX_VALUE;
          } else if (updatedValue !== null && updatedValue > SYSTEM_MAX_VALUE) {
            updatedValue = SYSTEM_MAX_VALUE;
          }
          emit('input', { ...props.value, value: updatedValue });
        };

        const showMetricsInput = computed(() => {
          return props.value.type === 'metrics';
        });

        const showValueInput = computed(() => {
          return props.value.type === 'constant';
        });

        const displayMetricsName = computed(() => {
          return props.value.metrics ? omitString(props.value.metrics.name) : '選択';
        });

        const validations = computed(() => {
          switch (props.value.type) {
            case 'metrics':
              return { [props.metricsFieldName]: { required: true } };
            case 'constant':
              return { [props.valueFieldName]: { required: true, floatWithDecimalPlaces: true } };
            default:
              return {};
          }
        });

        const root = getCurrentInstance()!.proxy.$root!;
        const getError = (fieldName: string): string | null => vvGetError(root, fieldName);

        const hasMetricsError = computed(() => {
          return getError(props.metricsFieldName) !== null;
        });

        const valueError = computed(() => {
          return getError(props.valueFieldName);
        });

        return {
          props,
          updateType,
          updateValue,
          showMetricsInput,
          showValueInput,
          displayMetricsName,
          validations,
          hasMetricsError,
          valueError,
        };
      },
    });
  }
}

const main = new ConditionOperandFieldFactory().define();

export function useConditionOperandField<T extends ConditionOperandType>() {
  return main as ReturnType<ConditionOperandFieldFactory<T>['define']>;
}

export default main;
