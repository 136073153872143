import { Link } from 'src/models/new/link'

export type LinkUpsertBaseRequestParameters = {
  name: string
  url: string
}

export const convertToLinkUpsertBaseRequestParameters = (link: Link): LinkUpsertBaseRequestParameters => {
  return {
    name: link.name,
    url: link.url,
  }
}
