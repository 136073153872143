import { convertScaling, Scaling } from 'src/business/scaling'

// FIXME: labelを持っているが、BarLineGraphItemの場合はこのlabelは使用されない
// もっとよい型の構造があるのではないか？
export type ChartItem = {
  value: number | null
  scaling: Scaling
  decimalPlaces: number
  color: string
  label: string
}

export const formatChartValue = (numeric: number | string): string => {
  return numeric.toLocaleString('ja-JP')
}

export const convertChartValue = (numeric: number, decimalPlaces: number, scaling: Scaling): number => {
  return parseFloat(convertScaling(numeric, scaling).toFixed(decimalPlaces))
}

export const getValueOnGraphFromGraphItem = (item: ChartItem): number => {
  const chartValue = !Number.isNaN(item.value) ? Number(item.value) : 0
  return convertChartValue(chartValue, item.decimalPlaces, item.scaling)
}
