import { Ref, inject, provide, ref } from 'vue';
import { Item } from '../types/item';

const key = Symbol('selectedItems');

export const useSelectedItems = <I extends Item>() => {
  const injected = inject<Ref<I[]> | null>(key, null);
  const selectedItems = injected ?? (ref<I[]>([]) as Ref<I[]>);
  if (injected === null) {
    provide(key, selectedItems);
  }

  const set = (items: I[]) => {
    selectedItems.value = [...items];
  };

  const add = (item: I) => {
    selectedItems.value = [...selectedItems.value, item];
  };

  const remove = (item: I) => {
    selectedItems.value = selectedItems.value.filter((i) => i.id !== item.id);
  };

  const includes = (item: I) => {
    return selectedItems.value.some((i) => i.id === item.id);
  };

  return {
    selectedItems,
    set,
    add,
    remove,
    includes,
  };
};
