import { Decoration } from '../../conditionalStatement'

export const DECORATION_TARGET_GRAPH = 'graph'

export type GraphDecoration = {
  target: typeof DECORATION_TARGET_GRAPH
  settings: {
    color: string
  }
}

export const isDecorationGraphDecoration = (decoration: Decoration): decoration is GraphDecoration => {
  return decoration.target === DECORATION_TARGET_GRAPH
}

export const hashedColorFromGraphDecoration = (decoration: GraphDecoration): string => {
  return `#${decoration.settings.color}`
}
