// NOTE: 現時点ではmetricsを直接操作するAPIは存在しないが、今後実装が予定されている
// 実装の際は現在のreportの中に含まれる形式と異なることが想定されるが、その際こちらの実装も変更する

import type { TimeSpan } from 'src/business/timeSpan';
import type { LogiboardDataMetrics } from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logiboardDataMetrics';
import type { LogimeterDataMetrics } from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logimeterDataMetrics';
import type { DirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import type {
  LogiSystemDataMetricsAggregationFunction,
  LogiSystemDataMetricsQueryParameter,
} from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import { type Metrics, type MetricsType, internal as metricsInternal } from 'src/models/new/metrics';
import type { Scaling } from 'src/business/scaling';
import { parseDatetime } from 'src/util/datetime';
import type { LegacyMetricsValueResponseData } from 'src/models/api/Report/Component/componentResponseData';
import type { Operator } from 'src/util/operator';
import type { CalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics';
import type { BundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import { type AggregateFunction, AGGREGATE_FUNCTION_SUM } from 'src/business/aggregateFunction';
import type { SummaryMetrics } from 'src/models/new/Metrics/summaryMetrics';
import {
  type ReferenceMetrics,
  type ReferenceMetricsOffsetPeriod,
  type OffsetPeriodUnit,
  isReferenceMetricsOffsetPeriodDay,
  isReferenceMetricsOffsetPeriodMonth,
  isReferenceMetricsOffsetPeriodWeek,
  isReferenceMetricsOffsetPeriodYear,
  internal as referenceMetricsInternal,
} from 'src/models/new/Metrics/referenceMetrics';
import type { WorkplaceResponseData } from 'src/models/api/Workplace/workplaceResponseData';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import type { DayOfWeek } from 'src/util/week';
import type { DateString } from 'src/models/common';

const DIRECT_INPUT = metricsInternal.DIRECT_INPUT;
const LOGIMETER = metricsInternal.LOGIMETER;
const LOGIBOARD = metricsInternal.LOGIBOARD;
const CALC = metricsInternal.CALC;
const CROSS = metricsInternal.CROSS;
const SUMMARY = metricsInternal.SUMMARY;
const EXT_CROSS = metricsInternal.EXT_CROSS;
const REFERENCE = metricsInternal.REFERENCE;

// 現行のレスポンス
type DirectInputMetricsResponseProperties = {
  default_value: number | null;
  // 他にもついてくるが、参照しないので省略
};
type LogimeterDataMetricsResponseProperties = {
  data_source_key_id: number;
  aggr_func: LogiSystemDataMetricsAggregationFunction;
  basic_metrics_parameters: {
    parameterId: number;
    // 実際には数値表記の文字列が飛んできて、ロジメーターから変換表を貰って表記を作る
    value: string;
  }[];
  target_budget_group_id: number;
  // 他にもついてくるが、参照しないので省略
};
type LogiboardDataMetricsResponseProperties = LogimeterDataMetricsResponseProperties;
type CalculatedMetricsResponseProperties = {
  id: number;
  metrics_id: number;
  operand1_id: number | null;
  operand1_value: string | null;
  operand2_id: number | null;
  operand2_value: string | null;
  operand3_id: number | null;
  operand3_value: string | null;
  operator1: Operator | null;
  operator2: Operator | null;
  // 他にもついてくるが、ひとまずは参照しないので省略
};
// レポートのvalue取得の場合、追加のプロパティが何も存在しない
type AggregatedMetricsResponseProperties = {
  aggr_func: AggregateFunction;
  is_zero_included: boolean;
  cross_border_metrics_details: {
    target_metrics_id: number;
    target_metrics: {
      // TODO: フロントでは使っていないので廃止予定(バックエンドから返さないようにもする)
      id: number;
      // 他にもついてくるが、ひとまずは参照しないので省略
    };
    // 他にもついてくるが、参照しないので省略
  }[];
  // 他にもついてくるが、参照しないので省略
};
// レポートのvalue取得の場合、追加のプロパティが何も存在しない
type SummaryMetricsResponseProperties = {
  aggr_func: AggregateFunction;
  is_zero_included: boolean;
  id: number;
  metrics_id: number;
  target_metrics: Object;
  target_metrics_id: number;
  wday: Record<DayOfWeek, boolean>;
};
const DAY = referenceMetricsInternal.DAY;
const WEEK = referenceMetricsInternal.WEEK;
const MONTH = referenceMetricsInternal.MONTH;
const YEAR = referenceMetricsInternal.YEAR;
const DOW = referenceMetricsInternal.DOW;
type ReferenceMetricsProperties = {
  target_metrics_id: number;
  offset_anchor_unit: Extract<OffsetPeriodUnit, typeof WEEK | typeof MONTH | typeof YEAR> | null;
  offset_anchor_diff: number | null;
  // 現在の実装ではunit1, value1が必ず値が入る項目となっている
  offset_unit1: OffsetPeriodUnit | typeof DOW | null;
  offset_value1: number;
  offset_unit2: Extract<OffsetPeriodUnit, typeof DAY> | null;
  offset_value2: number | null;
  // 他にもついてくるが、参照しないので省略
};

export type LegacyMetricsResponse = {
  id: number;
  metrics_type: string;
  front_metrics_type: MetricsType;
  basic_metrics:
    | DirectInputMetricsResponseProperties
    | LogimeterDataMetricsResponseProperties
    | LogiboardDataMetricsResponseProperties
    | undefined;
  calc_metrics: CalculatedMetricsResponseProperties | undefined;
  cross_border_metrics: AggregatedMetricsResponseProperties | undefined;
  summary_metrics: SummaryMetricsResponseProperties | undefined;
  reference_metrics: ReferenceMetricsProperties | undefined;
  includes_ext_in_chain: any; // 使用予定なし?
  is_enabled: boolean;
  logiscope_workplace_id: number;
  logiscope_workplace: WorkplaceResponseData;
  name: string;
  decimal_places: number;
  scaling: Scaling;
  time_span: TimeSpan;
  unit_name: string | null;
  metrics_access_groups: {
    id: number;
    // 他にもついてくるが、参照しないので省略
  }[];
  value: string | number | null | undefined; // 数値に変換する
  value_updated_at: DateString | null | undefined;
  closing_date: DateString | undefined;
  // 他にもついてくるが、参照しないので省略
};

// 改修後のレスポンス
// TODO: APIに手を入れられる段階になったら定義する
export type MetricsResponseData = LegacyMetricsResponse;
export type MetricsValueResponseData = LegacyMetricsValueResponseData;

// 引数名はresponseだが、当面はReportApiのレスポンスに含まれるmetricsを渡して変換する
// 現時点でreportValueのレスポンスに含まれるmetricsはvalueを持たないため別で受け取るが、最終的には引数から削除する
//
// onlyBaseとあるが、valueがあるときにtrueを受け取っている…？
export const convertFromMetricsResponseData = (
  response: LegacyMetricsResponse,
  metricsValue: any,
  onlyBase?: boolean,
): Metrics => {
  const metricsType = response.front_metrics_type;

  // TODO: API側でmetrics_valueを返さずに、responseに必要な値をセットした状態で返すようにする。
  // TODO: かつ本メソッドでmetricsValueを引数で受け取らずに下記のアサイン処理も削除する。
  // metricsValueが渡ってきた場合はresponse(metrics)にトラン用プロパティに値をセットする
  if (metricsValue) {
    response.value = metricsValue.value;
    response.value_updated_at = metricsValue.updated_at;
    response.closing_date = metricsValue.dt;
  }

  // メトリクスタイプ別のデータが含まれていない場合はBaseのみbuildする
  if (onlyBase) {
    return buildBaseMetricsProperties(response, metricsType);
  }

  switch (metricsType) {
    case DIRECT_INPUT:
      return buildDirectInputMetricsProperties(response);
    case LOGIMETER:
      return buildLogimeterMetricsProperties(response);
    case LOGIBOARD:
      return buildLogiboardMetricsProperties(response);
    case CALC:
      return buildCalculatedMetricsProperties(response);
    case CROSS:
      return buildBundledMetricsProperties(response, CROSS);
    case EXT_CROSS:
      return buildBundledMetricsProperties(response, EXT_CROSS);
    case SUMMARY:
      return buildSummaryMetricsProperties(response);
    case REFERENCE:
      return buildReferenceMetricsProperties(response);
    default:
      throw Error(`No such metrics type: ${metricsType}`);
  }
};

// 現時点でreportValueのレスポンスに含まれるmetricsはvalueを持たないため別で受け取るが、最終的には引数から削除する
// FIXME: metricsValueの型が前後の処理と合っていない、またLegacyMetricsValueResponseDataの型が合っていない: valueはnullの可能性がある
const buildBaseMetricsProperties = (response: LegacyMetricsResponse, metricsType: MetricsType): Metrics => {
  return {
    id: response.id,
    workplaceId: response.logiscope_workplace_id,
    metricsType: metricsType,
    name: response.name,
    timeSpan: response.time_span,
    decimalPlaces: response.decimal_places,
    scaling: response.scaling,
    unit: response.unit_name,
    isEnabled: response.is_enabled,
    closingDate: response.closing_date ? parseDatetime(response.closing_date, SYSTEM_DATE_FORMAT) : null,
    // valueが文字列で返ってきているので数値に変換する
    value: response.value === undefined || response.value === null ? null : Number(response.value),
    // レスポンスに含まれない場合は空のダミー配列を返す
    // 現状、レポートの閲覧側では値が含まれていないが、accessGroupIdsは編集側でしか使用しない
    // TODO: APIを修正してnullの可能性を排除する
    accessGroupIds: response.metrics_access_groups?.map((el) => el.id) || [],
    updatedAt: response.value_updated_at ? parseDatetime(response.value_updated_at) : null,
  };
};

const buildDirectInputMetricsProperties = (response: LegacyMetricsResponse): DirectInputMetrics => {
  if (!response.basic_metrics) {
    throw Error('Invalid response structure.');
  }
  return {
    ...buildBaseMetricsProperties(response, DIRECT_INPUT),
    // defaultValueが文字列で返ってきているので数値に変換する
    defaultValue: (response.basic_metrics as DirectInputMetricsResponseProperties).default_value
      ? Number((response.basic_metrics as DirectInputMetricsResponseProperties).default_value)
      : null,
  };
};

const buildLogimeterMetricsProperties = (response: LegacyMetricsResponse): LogimeterDataMetrics => {
  if (!response.basic_metrics) {
    throw Error('Invalid response structure.');
  }
  return {
    ...buildBaseMetricsProperties(response, LOGIMETER),
    logiDataSourceId: (response.basic_metrics as LogimeterDataMetricsResponseProperties).data_source_key_id,
    aggrFunc: (response.basic_metrics as LogiboardDataMetricsResponseProperties).aggr_func,
    // 現状、レポート側から取得したレスポンスにはこの値が存在しない
    // 実際に使われることもないが、判別方法なく型が変わるので取り扱いがコンテキスト依存になる
    // 変えるなら別物の型として定義する
    queryParameters:
      (response.basic_metrics as LogimeterDataMetricsResponseProperties).basic_metrics_parameters?.map((el: any) => {
        return {
          parameterId: el.data_source_parameter_id,
          // 数値の文字列に変換されて来ている?
          value: el.value as string,
        };
      }) || ([] as LogiSystemDataMetricsQueryParameter[]),
    budgetGroupId: (response.basic_metrics as LogimeterDataMetricsResponseProperties).target_budget_group_id,
  };
};

const buildLogiboardMetricsProperties = (response: LegacyMetricsResponse): LogiboardDataMetrics => {
  if (!response.basic_metrics) {
    throw Error('Invalid response structure.');
  }
  return {
    ...buildBaseMetricsProperties(response, LOGIBOARD),
    // 以下、ロジメーターデータメトリクスと同じ
    logiDataSourceId: (response.basic_metrics as LogiboardDataMetricsResponseProperties).data_source_key_id,
    aggrFunc: (response.basic_metrics as LogiboardDataMetricsResponseProperties).aggr_func,
    queryParameters:
      (response.basic_metrics as LogiboardDataMetricsResponseProperties).basic_metrics_parameters?.map((el: any) => {
        return {
          parameterId: el.data_source_parameter_id,
          value: el.value as string,
        };
      }) || ([] as LogiSystemDataMetricsQueryParameter[]),
    budgetGroupId: (response.basic_metrics as LogiboardDataMetricsResponseProperties).target_budget_group_id,
  };
};

const buildCalculatedMetricsProperties = (response: LegacyMetricsResponse): CalculatedMetrics => {
  // 本当はエラーにすべきだが、現在レポートのvalue取得では値が入っていないのでエラーを返さない
  // if (!response.calc_metrics) throw Error('Invalid response structure.')
  if (!response.calc_metrics) {
    return {
      ...buildBaseMetricsProperties(response, CALC),
      operands: [],
      operators: [],
    };
  }

  const operands = [];
  const operators: Operator[] = [];
  let isFormulaEnd = false;

  if (response.calc_metrics.operand1_id) {
    operands.push({ metricsId: response.calc_metrics.operand1_id, constant: null });
  } else if (response.calc_metrics.operand1_value !== null) {
    operands.push({ metricsId: null, constant: Number(response.calc_metrics.operand1_value) });
  } else {
    // レスポンスが画一化されたらエラーを返すのが正しい
    // 現状はレポートのvalueから取得するとundefinedになっているので、エラーは投げない
    // Error('Calculated metrics should contain at least 1 operand.')
  }
  if (response.calc_metrics.operator1) {
    operators.push(response.calc_metrics.operator1);
  } else {
    Error('Operator1 is not set.');
    isFormulaEnd = true;
  }
  if (!isFormulaEnd) {
    if (response.calc_metrics.operand2_id) {
      operands.push({ metricsId: response.calc_metrics.operand2_id, constant: null });
    } else if (response.calc_metrics.operand2_value !== null) {
      operands.push({ metricsId: null, constant: Number(response.calc_metrics.operand2_value) });
    } else {
      Error('No operand found at the behind of operator.');
    }
    if (response.calc_metrics.operator2) {
      operators.push(response.calc_metrics.operator2);
    } else {
      isFormulaEnd = true;
    }
  }
  if (!isFormulaEnd) {
    if (response.calc_metrics.operand3_id) {
      operands.push({ metricsId: response.calc_metrics.operand3_id, constant: null });
    } else if (response.calc_metrics.operand3_value !== null) {
      operands.push({ metricsId: null, constant: Number(response.calc_metrics.operand3_value) });
    } else {
      Error('No operand found at the behind of operator.');
    }
  }

  // 構造を保証する
  while (operands.length < 3) {
    operands.push({ metricsId: null, constant: null });
  }

  return {
    ...buildBaseMetricsProperties(response, CALC),
    operands: operands,
    operators: operators,
  };
};

const buildBundledMetricsProperties = (response: LegacyMetricsResponse, metricsType: MetricsType): BundledMetrics => {
  // 本当はエラーにすべきだが、現在レポートのvalue取得では値が入っていないのでエラーを返さない
  // if (!response.cross_border_metrics) throw Error('Invalid response structure.')

  return {
    ...buildBaseMetricsProperties(response, metricsType),
    // TODO: 右辺のダミー値はレスポンス修正後に削除
    targetMetricsIds:
      response.cross_border_metrics?.cross_border_metrics_details.map((el) => el.target_metrics_id) || [],
    aggrFunc: response.cross_border_metrics?.aggr_func || AGGREGATE_FUNCTION_SUM,
    isZeroIncluded: response.cross_border_metrics?.is_zero_included || false,
    isBorderless: metricsType === EXT_CROSS,
  };
};

// TODO: 数値と曜日の変換はある程度汎用的なロジックなので、utilかbusinessに移動させる
const dayOfWeekNumbers: Record<number, DayOfWeek> = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
};
const buildSummaryMetricsProperties = (response: LegacyMetricsResponse): SummaryMetrics => {
  // 本当はエラーにすべきだが、現在レポートのvalue取得では値が入っていないのでエラーを返さない
  // if (!response.summary_metrics) throw Error('Invalid response structure.')

  return {
    ...buildBaseMetricsProperties(response, SUMMARY),
    // 現在レポートのvalue取得では値がないのでダミー値をセットする
    // TODO: 右辺のダミー値はレスポンスを修正した場合削除
    targetMetricsId: response.summary_metrics?.target_metrics_id || 1,
    aggrFunc: response.summary_metrics?.aggr_func || AGGREGATE_FUNCTION_SUM,
    isZeroIncluded: response.summary_metrics?.is_zero_included || false,
    weekDays: response.summary_metrics?.wday || {
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
    },
  };
};

type ReferenceProperties = {
  offsetPeriod: ReferenceMetricsOffsetPeriod;
  referencePointMonth: number | null;
  referencePointDay: number | null;
  referencePointDow: DayOfWeek | null;
};
const convertReferenceDBFieldsToProperties = (props: ReferenceMetricsProperties): ReferenceProperties => {
  if (props.offset_anchor_unit) {
    const offsetPeriod = {
      unit: props.offset_anchor_unit,
      value: props.offset_anchor_diff,
    } as ReferenceMetricsOffsetPeriod;
    if (isReferenceMetricsOffsetPeriodDay(offsetPeriod)) {
      return {
        offsetPeriod: offsetPeriod,
        referencePointMonth: null,
        referencePointDay: null,
        referencePointDow: null,
      };
    } else if (isReferenceMetricsOffsetPeriodWeek(offsetPeriod)) {
      return {
        offsetPeriod: offsetPeriod,
        referencePointMonth: null,
        referencePointDay: null,
        referencePointDow: dayOfWeekNumbers[props.offset_value1] ?? null,
      };
    } else if (isReferenceMetricsOffsetPeriodMonth(offsetPeriod)) {
      return {
        offsetPeriod: offsetPeriod,
        referencePointMonth: null,
        referencePointDay: props.offset_value1,
        referencePointDow: null,
      };
    } else if (isReferenceMetricsOffsetPeriodYear(offsetPeriod)) {
      return {
        offsetPeriod: offsetPeriod,
        referencePointMonth: props.offset_value1,
        referencePointDay: props.offset_value2,
        referencePointDow: null,
      };
    }
    throw Error(`No such offset period unit: ${offsetPeriod.unit}`);
  } else {
    const offsetPeriod = {
      unit: props.offset_unit1,
      value: props.offset_value1,
    } as ReferenceMetricsOffsetPeriod;
    return {
      offsetPeriod: offsetPeriod,
      referencePointMonth: null,
      referencePointDay: null,
      referencePointDow: null,
    };
  }
};
const buildReferenceMetricsProperties = (response: LegacyMetricsResponse): ReferenceMetrics => {
  // 本当はエラーにすべきだが、現在レポートのvalue取得では値が入っていないのでエラーを返さない
  // if (!response.reference_metrics) throw Error('Invalid response structure.')
  const referenceProperties = response.reference_metrics
    ? convertReferenceDBFieldsToProperties(response.reference_metrics)
    : // 以下はダミー値
      {
        offsetPeriod: { unit: DAY, value: 1 } as ReferenceMetricsOffsetPeriod,
        referencePointMonth: null,
        referencePointDay: null,
        referencePointDow: null,
      };

  return {
    ...buildBaseMetricsProperties(response, REFERENCE),
    targetMetricsId: response.reference_metrics?.target_metrics_id || 1,
    ...referenceProperties,
  };
};
