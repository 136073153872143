import { CSSProperties } from 'vue/types/jsx'
import {
  MetricsCardComponentConditionalStatement,
} from '../../ConditionalStatement/metricsCardComponentConditionalStatement'
import { Metrics } from '../../metrics'
import { MetricsComponent } from '../metricsComponent'
import {
  baseValueFromConditionalStatement,
  isConditionalStatementBaseReference,
  isConditionalStatementBaseSelf,
  isConditionalStatementComparatorIsNull,
  isConditionalStatementTargetBackground,
  isConditionalStatementTargetIcon,
  isConditionalStatementTargetText,
  isConditionalStatementThresholdReference,
  thresholdValueFromConditionalStatement,
} from '../../conditionalStatement'
import { compareWithComparator } from 'src/util/comparator'
import {
  IconDecoration,
  classFromIconDecoration,
  styleFromIconDecoration,
} from '../../ConditionalStatement/Decorations/iconDecoration'
import { Component, internal } from '../../component'
import { TextDecoration, styleFromTextDecoration } from '../../ConditionalStatement/Decorations/textDecoration'
import { BackgroundDecoration, styleFromBackgroundDecoration } from '../../ConditionalStatement/Decorations/backgroundDecoration'

const METRICS_CARD = internal.METRICS_CARD

export const METRICS_CARD_COMPONENT_MIN_WIDTH = 1
export const METRICS_CARD_COMPONENT_MAX_WIDTH = 1
export const METRICS_CARD_COMPONENT_MIN_HEIGHT = 1
export const METRICS_CARD_COMPONENT_MAX_HEIGHT = 1

export type MetricsCardComponent = MetricsComponent & {
  unit: string | null
  subMetricsName: string | null
  metrics: Metrics
  subMetrics: Metrics | null
  conditionalStatements: MetricsCardComponentConditionalStatement[]
}
// メトリクスカードコンポーネントはmetricsが必須の型であるため、空の状態のコンポーネントを表現するために定義
// 空の状態でなくなった場合はmetricsのnullチェックを行い、MetricsCardComponentにキャストする
export type MetricsCardComponentWithEmptyMetrics = Omit<MetricsCardComponent, 'metrics'> & {
  metrics: Metrics | null
}

export const isMetricsCardComponentCompleted = (component: MetricsCardComponentWithEmptyMetrics | MetricsCardComponent): component is MetricsCardComponent => {
  return !!component.metrics
}

export const constructEmptyMetricsCardComponent = (): MetricsCardComponentWithEmptyMetrics => {
  return {
    id: 0,
    sectionId: 0,
    componentType: METRICS_CARD,
    abscissa: 0,
    ordinate: 0,
    width: METRICS_CARD_COMPONENT_MIN_WIDTH,
    height: METRICS_CARD_COMPONENT_MIN_HEIGHT,
    referenceDate: null,
    name: '',
    unit: null,
    subMetricsName: null,
    metrics: null,
    subMetrics: null,
    conditionalStatements: [],
  }
}

export const isComponentTypeMetricsCard = (component: Component): component is MetricsCardComponent | MetricsCardComponentWithEmptyMetrics => component.componentType === METRICS_CARD

const getBaseValue = (component: MetricsCardComponent, conditionalStatement: MetricsCardComponentConditionalStatement): number | null => {
  if (isConditionalStatementBaseSelf(conditionalStatement)) return component.metrics.value
  if (isConditionalStatementBaseReference(conditionalStatement)) return component.subMetrics?.value ?? null
  return baseValueFromConditionalStatement(conditionalStatement)
}

const getThresholdValue = (component: MetricsCardComponent, conditionalStatement: MetricsCardComponentConditionalStatement): number | null => {
  if (isConditionalStatementThresholdReference(conditionalStatement)) return component.subMetrics?.value ?? null
  return thresholdValueFromConditionalStatement(conditionalStatement)
}

const extractPriorTargetDecoration = (
  component: MetricsCardComponent,
  targetCheckFunction: (conditionalStatement: MetricsCardComponentConditionalStatement) => boolean
): MetricsCardComponentConditionalStatement | null => {
  return component.conditionalStatements
    .filter(el => targetCheckFunction(el))
    .sort((a, b) => b.priority - a.priority)
    .reduce((found: MetricsCardComponentConditionalStatement | null, conditionalStatement: MetricsCardComponentConditionalStatement) => {
      if (found) return found
      const base = getBaseValue(component, conditionalStatement)
      if (isConditionalStatementComparatorIsNull(conditionalStatement)) {
        return compareWithComparator(base, conditionalStatement.comparator, null)
          ? conditionalStatement
          : null
      }
      const threshold = getThresholdValue(component, conditionalStatement)
      if (base === null || threshold === null) return found
      if (!compareWithComparator(base, conditionalStatement.comparator, threshold)) return found
      return conditionalStatement
    }, null)
}

export const applyConditionalStatementToMetricsCardComponentTextStyle = (component: MetricsCardComponent): CSSProperties => {
  if (!component.referenceDate) return {}
  const decoration = extractPriorTargetDecoration(component, isConditionalStatementTargetText)?.decoration
  return decoration ? styleFromTextDecoration(decoration as TextDecoration) : {}
}
export const applyConditionalStatementToMetricsCardComponentIconStyle = (component: MetricsCardComponent): CSSProperties => {
  if (!component.referenceDate) return {}
  const decoration = extractPriorTargetDecoration(component, isConditionalStatementTargetIcon)?.decoration
  return decoration ? styleFromIconDecoration(decoration as IconDecoration) : {}
}
export const applyConditionalStatementToMetricsCardComponentIconClass = (component: MetricsCardComponent): string | null => {
  if (!component.referenceDate) return null
  const decoration = extractPriorTargetDecoration(component, isConditionalStatementTargetIcon)?.decoration
  return decoration ? classFromIconDecoration(decoration as IconDecoration) : null
}
export const applyConditionalStatementToMetricsCardComponentBackgroundStyle = (component: MetricsCardComponent): CSSProperties => {
  if (!component.referenceDate) return {}
  const decoration = extractPriorTargetDecoration(component, isConditionalStatementTargetBackground)?.decoration
  return decoration ? styleFromBackgroundDecoration(decoration as BackgroundDecoration) : {}
}
// FIXME: component.unitが優先されることには違いないので、コンポーネント登録時にメトリクスのunitを初期値にするだけで処理が簡単になり、この関数も不要になる
export const displayUnitFromMeticsCardComponent = (component: MetricsCardComponent): string => component.unit || component.metrics.unit || ''
