import {
  MetricsListComponent,
  isComponentTypeMetricsList,
} from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import { RecordId, validRecordIdOrNull } from 'src/util/recordId';
import { PeriodUnit, isPeriodWithValue } from 'src/business/period';
import { MetricsTransitionComponentRewindPeriodUnit } from 'src/models/new/Component/MetricsComponent/metricsTransitionComponentProperties';
import { transpose } from 'src/util/array';
import { HeaderStructure } from 'src/util/dataHeader';
import {
  MetricsCardComponent,
  isComponentTypeMetricsCard,
  isMetricsCardComponentCompleted,
} from 'src/models/new/Component/MetricsComponent/metricsCardComponent';
import {
  MetricsPieChartComponent,
  isComponentTypeMetricsPieChart,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import { SYSTEM_DATETIME_FORMAT } from 'src/util/Datetime/format';
import {
  MetricsTransitionGraphComponent,
  isComponentTypeMetricsTransitionGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsTransitionGraphComponent';
import { GraphLayout } from 'src/business/graphLayout';
import { CommentComponent, isComponentTypeComment } from 'src/models/new/Component/commentComponent';
import {
  MetricsBarGraphComponent,
  isComponentTypeMetricsBarGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsBarGraphComponent';
import {
  MetricsGroupedGraphComponent,
  isComponentTypeMetricsGroupedGraph,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import {
  isComponentTypeMetricsTable,
  MetricsTableComponent,
} from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { Component } from 'src/models/new/component';

type ComponentBaseCreateRequestParameters<T> = {
  section_id: RecordId | null;
  name: string;
  abscissa: number;
  ordinate: number;
  height: number;
  width: number;
  component_type: T;
};

// 新モデルでのタイプ定義はmetrics_cardだがデータ互換性のためにcardへ変換する
type MetricsCardComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'card'> & {
  single_value_component: {
    unit_disp_name: string | null;
    metrics_id: RecordId;
    sub_disp_name: string | null;
    sub_metrics_id: RecordId | null;
  };
};

const convertToMetricsCardComponentCreateRequestParameters = (
  component: MetricsCardComponent,
): MetricsCardComponentCreateRequestParameters => {
  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'card',
    single_value_component: {
      // id
      // component_id
      // metrics: MetricsUpdateRequestParameters
      unit_disp_name: component.unit,
      metrics_id: component.metrics.id,
      sub_disp_name: component.subMetricsName,
      // sub_metrics
      sub_metrics_id: component.subMetrics?.id ?? null,
    },
  };
};

// 新モデルでのタイプ定義はmetrics_listだがデータ互換性のためにlistへ変換する
type MetricsListComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'list'> & {
  list_component: {
    period_unit: PeriodUnit;
    period_value: number | null;
    date_format: string;
    disp_days_rewind_unit: MetricsTransitionComponentRewindPeriodUnit | null;
    disp_days_rewind_value: number | null;
    has_sub_metrics: boolean;
    header: {
      cols: string[][];
      rows: string[][];
    };
    is_descend: boolean;
    is_future_displayed: boolean;
    is_today_displayed: boolean;
    is_unit_indivisual_displayed: boolean;
    is_vertical: boolean;
    metrics_list_components: {
      is_sub_metrics: boolean;
      sequential_order: number | null;
      metrics_id: RecordId;
    }[];
  };
};

const convertToMetricsListComponentCreateRequestParameters = (
  component: MetricsListComponent,
): MetricsListComponentCreateRequestParameters => {
  const convertHeaderStructureToArray = (header: HeaderStructure): string[][] => {
    const array = new Array(header.depth).fill(null).map((_) => new Array(header.breadth).fill('')) as string[][];
    header.data.forEach((el) => {
      // FIXME: noUncheckedIndexedAccess有効化に伴う暫定対応
      // array[el.level - 1][el.position - 1] => array[el.level - 1]![el.position - 1]!
      array[el.level - 1]![el.position - 1]! = el.value || '';
    });

    return array;
  };

  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    // 新モデルでのタイプ定義はmetrics_listだがデータ互換性のためにlistへ変換する
    component_type: 'list',
    list_component: {
      period_unit: component.period.unit,
      period_value: isPeriodWithValue(component.period) ? component.period.value : null,
      date_format: component.dateFormat,
      disp_days_rewind_unit: component.dispDaysRewindPeriod ? component.dispDaysRewindPeriod.unit : null,
      disp_days_rewind_value: component.dispDaysRewindPeriod ? component.dispDaysRewindPeriod.value : null,
      has_sub_metrics: component.data.layout.some((el) => el.isSubMetrics),
      header: {
        cols: convertHeaderStructureToArray(component.headers.head.layout),
        rows: transpose(convertHeaderStructureToArray(component.headers.side.layout)),
      },
      is_descend: component.isDescendingDateOrder,
      is_future_displayed: component.isFutureDisplayed,
      is_today_displayed: component.isTodayDisplayed,
      is_unit_indivisual_displayed: component.isUnitIndividualDisplayed,
      is_vertical: component.isVertical,
      metrics_list_components: component.data.layout.map((el) => {
        return {
          is_sub_metrics: el.isSubMetrics,
          sequential_order: el.sequentialOrder,
          metrics_id: el.metrics.id,
        };
      }),
    },
  };
};

// 新モデルでのタイプ定義はmetrics_tableだがデータ互換性のためにtableへ変換する
type MetricsTableComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'table'> & {
  table_component: {
    header: {
      cols: string[][];
      rows: string[][];
    };
    is_unit_indivisual_displayed: boolean;
    metrics_table_components: {
      row_number: number | null;
      column_number: number | null;
      metrics_id: RecordId;
    }[];
  };
};

const convertToMetricsTableComponentCreateRequestParameters = (
  component: MetricsTableComponent,
): MetricsTableComponentCreateRequestParameters => {
  const convertHeaderStructureToArray = (header: HeaderStructure): string[][] => {
    const array = new Array(header.depth).fill(null).map((_) => new Array(header.breadth).fill('')) as string[][];
    header.data.forEach((el) => {
      // FIXME: noUncheckedIndexedAccess有効化に伴う暫定対応
      // array[el.level - 1][el.position - 1] => array[el.level - 1]![el.position - 1]!
      array[el.level - 1]![el.position - 1]! = el.value || '';
    });

    return array;
  };

  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'table',
    table_component: {
      header: {
        cols: convertHeaderStructureToArray(component.headers.head.layout),
        rows: transpose(convertHeaderStructureToArray(component.headers.side.layout)),
      },
      is_unit_indivisual_displayed: component.isUnitIndividualDisplayed,
      metrics_table_components: component.data.map((el) => {
        return {
          row_number: el.row,
          column_number: el.column,
          metrics_id: el.metrics.id,
        };
      }),
    },
  };
};

// 新モデルでのタイプ定義はmetrics_graph.pie_chartだがデータ互換性のためにgraphへ変換する
// pie_chartの部分はgraph_componentプロパティの中で設定値を持つ
type MetricsPieChartComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'graph'> & {
  graph_component: {
    comparison_type: 'ratio';
    date_format: typeof SYSTEM_DATETIME_FORMAT; // 実際には使わないのだが、nullにするとAPIが弾くようになっている
    disp_content: 'num' | 'rate' | 'hidden';
    disp_days_rewind_unit: null; // 推移グラフのみ有効
    disp_days_rewind_value: null; // 推移グラフのみ有効
    graph_type1: 'circle';
    graph_type2: null;
    is_descend: false;
    is_future_displayed: false;
    is_today_displayed: false;
    legend_name_l1: null;
    legend_name_l2: null;
    legend_name_r1: null;
    legend_name_r2: null;
    period_unit: null;
    period_value: null;
    sub_disp_color: null;
    sub_disp_name: null;
    sub_metrics_id: null;
    unit_disp_name1: string | null;
    unit_disp_name2: null;
    metrics_graph_components: {
      disp_color: string;
      disp_name: string | null;
      graph_no: 'l1';
      metrics_id: RecordId;
      registration_order: number;
    }[];
  };
};

const convertToMetricsPieChartComponentCreateRequestParameters = (
  component: MetricsPieChartComponent,
): MetricsPieChartComponentCreateRequestParameters => {
  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'graph',
    graph_component: {
      comparison_type: 'ratio',
      date_format: SYSTEM_DATETIME_FORMAT,
      disp_content: component.displayValueSettings.displayAs,
      disp_days_rewind_unit: null,
      disp_days_rewind_value: null,
      graph_type1: 'circle',
      graph_type2: null,
      is_descend: false,
      is_future_displayed: false,
      is_today_displayed: false,
      legend_name_l1: null,
      legend_name_l2: null,
      legend_name_r1: null,
      legend_name_r2: null,
      period_unit: null,
      period_value: null,
      sub_disp_color: null,
      sub_disp_name: null,
      sub_metrics_id: null,
      unit_disp_name1: component.displayValueSettings.unit,
      unit_disp_name2: null,
      metrics_graph_components: component.data.map((el) => {
        return {
          disp_color: el.color,
          disp_name: el.alias,
          graph_no: 'l1',
          metrics_id: el.metrics.id,
          registration_order: el.internalOrder,
        };
      }),
    },
  };
};

// 新モデルでのタイプ定義はmetrics_graph.pie_chartだがデータ互換性のためにgraphへ変換する
// pie_chartの部分はgraph_componentプロパティの中で設定値を持つ
type MetricsBarGraphComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'graph'> & {
  graph_component: {
    comparison_type: 'ratio';
    date_format: typeof SYSTEM_DATETIME_FORMAT; // 実際には使わないのだが、nullにするとAPIが弾くようになっている
    disp_content: null;
    disp_days_rewind_unit: null; // 推移グラフのみ有効
    disp_days_rewind_value: null; // 推移グラフのみ有効
    graph_type1: 'horizontal_bar';
    graph_type2: null;
    is_descend: false;
    is_future_displayed: false;
    is_today_displayed: false;
    legend_name_l1: null;
    legend_name_l2: null;
    legend_name_r1: null;
    legend_name_r2: null;
    period_unit: null;
    period_value: null;
    sub_disp_color: string | null;
    sub_disp_name: string | null;
    sub_metrics_id: RecordId | null;
    unit_disp_name1: string | null;
    unit_disp_name2: null;
    metrics_graph_components: {
      disp_color: string;
      disp_name: string | null;
      graph_no: 'l1';
      metrics_id: RecordId;
      registration_order: number;
    }[];
  };
};

const convertToMetricsBarGraphComponentCreateRequestParameters = (
  component: MetricsBarGraphComponent,
): MetricsBarGraphComponentCreateRequestParameters => {
  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'graph',
    graph_component: {
      comparison_type: 'ratio',
      date_format: SYSTEM_DATETIME_FORMAT,
      disp_content: null,
      disp_days_rewind_unit: null,
      disp_days_rewind_value: null,
      graph_type1: 'horizontal_bar',
      graph_type2: null,
      is_descend: false,
      is_future_displayed: false,
      is_today_displayed: false,
      legend_name_l1: null,
      legend_name_l2: null,
      legend_name_r1: null,
      legend_name_r2: null,
      period_unit: null,
      period_value: null,
      sub_disp_color: component.data.reference?.color ?? null,
      sub_disp_name: component.data.reference?.label ?? null,
      sub_metrics_id: component.data.reference?.metrics.id ?? null,
      unit_disp_name1: component.unit,
      unit_disp_name2: null,
      metrics_graph_components: component.data.bars.map((el) => {
        return {
          disp_color: el.color,
          disp_name: el.alias,
          graph_no: 'l1',
          metrics_id: el.metrics.id,
          registration_order: el.sequentialOrder,
        };
      }),
    },
  };
};

// 新モデルでのタイプ定義はmetrics_graph.grouped_graphだがデータ互換性のためにgraphへ変換する
// grouped_graphの部分はgraph_componentプロパティの中で設定値を持つ
type MetricsGroupedGraphComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'graph'> & {
  graph_component: {
    comparison_type: 'ratio';
    date_format: typeof SYSTEM_DATETIME_FORMAT; // 実際には使わないのだが、nullにするとAPIが弾くようになっている
    disp_content: null;
    disp_days_rewind_unit: null;
    disp_days_rewind_value: null;
    graph_type1: 'bar' | 'line';
    graph_type2: 'bar' | 'line';
    is_descend: boolean;
    is_future_displayed: boolean;
    is_today_displayed: boolean;
    legend_name_l1: string | null;
    legend_name_l2: string | null;
    legend_name_r1: string | null;
    legend_name_r2: string | null;
    period_unit: null;
    period_value: null;
    sub_disp_color: string | null;
    sub_disp_name: string | null;
    sub_metrics_id: RecordId | null;
    unit_disp_name1: string | null;
    unit_disp_name2: string | null;
    metrics_graph_components: {
      disp_color: string;
      disp_name: string | null;
      graph_no: 'l1' | 'l2' | 'r1' | 'r2';
      metrics_id: RecordId;
      registration_order: number;
    }[];
  };
};

const graphNoFromGraphLayout = (graphLayout: GraphLayout): 'l1' | 'l2' | 'r1' | 'r2' => {
  if (graphLayout.scale === 'left' && graphLayout.number === 1) {
    return 'l1';
  } else if (graphLayout.scale === 'left' && graphLayout.number === 2) {
    return 'l2';
  } else if (graphLayout.scale === 'right' && graphLayout.number === 1) {
    return 'r1';
  } else if (graphLayout.scale === 'right' && graphLayout.number === 2) {
    return 'r2';
  }
  throw new Error('invalid graph layout');
};

const convertToMetricsGroupedGraphComponentCreateRequestParameters = (
  component: MetricsGroupedGraphComponent,
): MetricsGroupedGraphComponentCreateRequestParameters => {
  const graphType1 = component.data.graphs.find((el) => el.scale === 'left' && el.number === 1)!.plotType;
  const graphType2 = component.data.graphs.find((el) => el.scale === 'right' && el.number === 1)!.plotType;
  if (!graphType1 || !graphType2 || graphType1 === graphType2) {
    throw new Error('invalid plotTypes.');
  }

  const legendNameL1 = component.data.graphs.find((el) => el.scale === 'left' && el.number === 1)!.label;
  const legendNameL2 = component.data.graphs.find((el) => el.scale === 'left' && el.number === 2)!.label;
  const legendNameR1 = component.data.graphs.find((el) => el.scale === 'right' && el.number === 1)!.label;
  const legendNameR2 = component.data.graphs.find((el) => el.scale === 'right' && el.number === 2)!.label;

  const metricsGraphComponentData = component.data.graphs
    .map((graph) => {
      const graphNo = graphNoFromGraphLayout(graph);
      return graph.plots.map((plot) => {
        return {
          disp_color: plot.color,
          disp_name: plot.label,
          graph_no: graphNo,
          metrics_id: plot.metrics.id,
          registration_order: plot.group,
        };
      });
    })
    .flat();

  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'graph',
    graph_component: {
      comparison_type: 'ratio',
      date_format: SYSTEM_DATETIME_FORMAT,
      disp_content: null,
      disp_days_rewind_unit: null,
      disp_days_rewind_value: null,
      graph_type1: graphType1,
      graph_type2: graphType2,
      is_descend: false,
      is_future_displayed: false,
      is_today_displayed: false,
      legend_name_l1: legendNameL1,
      legend_name_l2: legendNameL2,
      legend_name_r1: legendNameR1,
      legend_name_r2: legendNameR2,
      period_unit: null,
      period_value: null,
      sub_disp_color: component.data.reference?.color || null,
      sub_disp_name: component.data.reference?.label || null,
      sub_metrics_id: component.data.reference?.metrics.id ?? null,
      // FIXME: 型的にはstring | nullだが、フォーム側の問題で空文字が入ってくることがある
      unit_disp_name1: component.scaleUnits.left || null,
      unit_disp_name2: component.scaleUnits.right || null,
      metrics_graph_components: metricsGraphComponentData,
    },
  };
};

// 新モデルでのタイプ定義はmetrics_graph.transition_graphだがデータ互換性のためにgraphへ変換する
// transition_graphの部分はgraph_componentプロパティの中で設定値を持つ
type MetricsTransitionGraphComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'graph'> & {
  graph_component: {
    comparison_type: 'transition';
    date_format: string;
    disp_content: null;
    disp_days_rewind_unit: MetricsTransitionComponentRewindPeriodUnit | null;
    disp_days_rewind_value: number | null;
    graph_type1: 'bar' | 'line';
    graph_type2: 'bar' | 'line';
    is_descend: boolean;
    is_future_displayed: boolean;
    is_today_displayed: boolean;
    legend_name_l1: string | null;
    legend_name_l2: string | null;
    legend_name_r1: string | null;
    legend_name_r2: string | null;
    period_unit: PeriodUnit;
    period_value: number | null;
    sub_disp_color: string | null;
    sub_disp_name: string | null;
    sub_metrics_id: RecordId | null;
    unit_disp_name1: string | null;
    unit_disp_name2: string | null;
    metrics_graph_components: {
      disp_color: string;
      disp_name: null;
      graph_no: 'l1' | 'l2' | 'r1' | 'r2';
      metrics_id: RecordId;
      registration_order: 1;
    }[];
  };
};

const convertToMetricsTransitionGraphComponentCreateRequestParameters = (
  component: MetricsTransitionGraphComponent,
): MetricsTransitionGraphComponentCreateRequestParameters => {
  const graphType1 = component.data.graphs.find((el) => el.scale === 'left' && el.number === 1)!.plotType;
  const graphType2 = component.data.graphs.find((el) => el.scale === 'right' && el.number === 1)!.plotType;
  if (!graphType1 || !graphType2 || graphType1 === graphType2) {
    throw new Error('invalid plotTypes.');
  }

  const legendNameL1 = component.data.graphs.find((el) => el.scale === 'left' && el.number === 1)!.label;
  const legendNameL2 = component.data.graphs.find((el) => el.scale === 'left' && el.number === 2)!.label;
  const legendNameR1 = component.data.graphs.find((el) => el.scale === 'right' && el.number === 1)!.label;
  const legendNameR2 = component.data.graphs.find((el) => el.scale === 'right' && el.number === 2)!.label;

  const metricsGraphComponentData = component.data.graphs
    .filter((el) => el.metrics)
    .map((el) => {
      const graphNo = graphNoFromGraphLayout(el);
      return {
        disp_color: el.color,
        disp_name: null,
        graph_no: graphNo,
        metrics_id: el.metrics!.id,
        // そのまま1を与えても型としてはnumberになってしまうので、型が1であるように明記する
        registration_order: 1 as 1,
      };
    });

  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'graph',
    graph_component: {
      comparison_type: 'transition',
      date_format: component.dateFormat,
      disp_content: null,
      disp_days_rewind_unit: component.dispDaysRewindPeriod?.unit ?? null,
      disp_days_rewind_value: component.dispDaysRewindPeriod?.value || null,
      graph_type1: graphType1,
      graph_type2: graphType2,
      is_descend: component.isDescendingDateOrder,
      is_future_displayed: component.isFutureDisplayed,
      is_today_displayed: component.isTodayDisplayed,
      legend_name_l1: legendNameL1,
      legend_name_l2: legendNameL2,
      legend_name_r1: legendNameR1,
      legend_name_r2: legendNameR2,
      period_unit: component.period.unit,
      period_value: isPeriodWithValue(component.period) ? component.period.value : null,
      sub_disp_color: component.data.reference?.color || null,
      sub_disp_name: component.data.reference?.label || null,
      sub_metrics_id: component.data.reference?.metrics.id ?? null,
      // FIXME: 型的にはstring | nullだが、フォーム側の問題で空文字が入ってくることがある
      unit_disp_name1: component.scaleUnits.left || null,
      unit_disp_name2: component.scaleUnits.right || null,
      metrics_graph_components: metricsGraphComponentData,
    },
  };
};

type CommentComponentCreateRequestParameters = ComponentBaseCreateRequestParameters<'comment'> & {
  comment_component: {
    component_size: number;
    placeholder: string | null;
  };
};

const convertToCommentComponentCreateRequestParameters = (
  component: CommentComponent,
): CommentComponentCreateRequestParameters => {
  return {
    section_id: validRecordIdOrNull(component.sectionId),
    name: component.name,
    abscissa: component.abscissa,
    ordinate: component.ordinate,
    height: component.height,
    width: component.width,
    component_type: 'comment',
    comment_component: {
      component_size: component.width,
      placeholder: component.placeholder,
    },
  };
};

export type ComponentCreateRequestParameters =
  | MetricsCardComponentCreateRequestParameters
  | MetricsListComponentCreateRequestParameters
  | MetricsTableComponentCreateRequestParameters
  | MetricsPieChartComponentCreateRequestParameters
  | MetricsBarGraphComponentCreateRequestParameters
  | MetricsGroupedGraphComponentCreateRequestParameters
  | MetricsTransitionGraphComponentCreateRequestParameters
  | CommentComponentCreateRequestParameters;

export const convertToComponentCreateRequestParameters = (component: Component): ComponentCreateRequestParameters => {
  if (isComponentTypeMetricsCard(component)) {
    if (!isMetricsCardComponentCompleted(component)) {
      throw Error('given card component is not completed.');
    }
    return convertToMetricsCardComponentCreateRequestParameters(component);
  } else if (isComponentTypeMetricsList(component)) {
    return convertToMetricsListComponentCreateRequestParameters(component);
  } else if (isComponentTypeMetricsTable(component)) {
    return convertToMetricsTableComponentCreateRequestParameters(component);
  } else if (isComponentTypeMetricsPieChart(component)) {
    return convertToMetricsPieChartComponentCreateRequestParameters(component);
  } else if (isComponentTypeMetricsBarGraph(component)) {
    return convertToMetricsBarGraphComponentCreateRequestParameters(component);
  } else if (isComponentTypeMetricsGroupedGraph(component)) {
    return convertToMetricsGroupedGraphComponentCreateRequestParameters(component);
  } else if (isComponentTypeMetricsTransitionGraph(component)) {
    return convertToMetricsTransitionGraphComponentCreateRequestParameters(component);
  } else if (isComponentTypeComment(component)) {
    return convertToCommentComponentCreateRequestParameters(component);
  }

  throw Error('invalid component_type is given.');
};
