import type { Report } from 'src/models/new/report';
import reportApi from 'src/apis/masters/report';
import { generateInjectionKey } from './logics/generateInjectionKey';
import { useSlicedAsyncResources, useSlicedAsyncResourcesProvider } from './useSlicedAsyncResources';

type UseReportsProviderArgs = {
  onLoad?: () => unknown;
};
type UseReportsProviderResult = {
  reportsRef: ReturnType<typeof useSlicedAsyncResourcesProvider<Report>>['resources'];
};

type UseReportsResult = {
  reportsRef: ReturnType<typeof useSlicedAsyncResources<Report>>['resources'];
  loadNextSlice: ReturnType<typeof useSlicedAsyncResources<Report>>['loadNextSlice'];
};

const key = generateInjectionKey('reports');

const fetchReports = async ({ page }: { page: number }) => {
  const { result } = await reportApi.index({
    time_span: null,
    theme_color: null,
    name: null,
    include_disabled: false,
    order: null,
    page,
  });
  return result.sort((a: Report, b: Report) => (a.name > b.name ? 1 : -1));
};

export const useReportsProvider = ({ onLoad }: UseReportsProviderArgs = {}): UseReportsProviderResult => {
  const { resources: reportsRef } = useSlicedAsyncResourcesProvider<Report>({
    key,
    fetcher: fetchReports,
    onLoad,
  });

  return {
    reportsRef,
  };
};

export const useReports = (): UseReportsResult => {
  const { resources: reportsRef, loadNextSlice } = useSlicedAsyncResources<Report>({ key });

  return {
    reportsRef,
    loadNextSlice,
  };
};
