import { Store } from 'vuex'
import { computed, ComputedRef } from 'vue'

export function wrappedMapGetters<K extends keyof any>(store: Store<any>, moduleName: string, propertyNameArrayOrMap: K[] | Record<K, any>): Record<K, ComputedRef> {
  const propertyNameMap: Record<K, any> = Array.isArray(propertyNameArrayOrMap)
    ? propertyNameArrayOrMap.reduce((acc, e) => {
      acc[e] = e; return acc
    }, {} as Record<K, any>)
    : propertyNameArrayOrMap
  // const ret: Record<K, ComputedRef> = Object.fromEntries(
  //   Object.entries(propertyNameMap).map(([k, v]) => {
  //     return [k as K, computed(() => store.getters[`${moduleName}/${v}`])]
  //   })
  // )
  const ret: Partial<Record<K, ComputedRef>> = {}
  Object.entries(propertyNameMap).forEach(([k, v]) => {
    ret[k as K] = computed(() => store.getters[`${moduleName}/${v}`])
  })
  return ret as Record<K, ComputedRef>
}
