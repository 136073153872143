import DashboardLayout from 'src/views/Dashboard/Layout/DashboardLayout.vue'

// 注) これを変えたらviews/Dashboard/Layout/sidebarLinkGroupsも確認すること
const routes = [
  {
    path: '',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: require('src/views/Dashboard/Home').default,
      },
      {
        path: 'reports',
        // FIXME: Reportsに変更
        name: 'ReportValues',
        component: require('src/views/Dashboard/ReportValues').default,
      },
      {
        path: 'reports/:reportId',
        // FIXME: ReportDetailに変更
        name: 'ReportValuesDetail',
        component: require('src/views/Dashboard/ReportValues/Detail').default,
      },
      {
        path: 'metrics-values',
        name: 'MetricsValues',
        component: require('src/views/Dashboard/MetricsValues').default,
      },
      {
        path: 'metrics/:metricsId/breakdown',
        name: 'MetricsBreakdown',
        component: require('src/views/Dashboard/Metrics/Breakdown').default,
      },
      {
        path: 'data-fetch-statuses',
        name: 'DataFetchStatuses',
        component: require('src/views/Dashboard/DataFetchStatuses').default,
      },
      {
        path: 'conversion-programs',
        name: 'ConversionPrograms',
        component: require('src/views/Dashboard/ConversionPrograms').default,
      },
    ],
  },
  {
    path: 'settings',
    component: DashboardLayout,
    redirect: 'settings/select',
    children: [
      {
        path: 'select',
        name: 'SettingsSelect',
        component: require('src/views/Dashboard/Settings/Select').default,
      },
      {
        path: 'logiscope-workplace',
        name: 'SettingsLogiscopeWorkplace',
        component: require('src/views/Dashboard/Settings/LogiscopeWorkplace').default,
      },
      {
        path: 'metrics',
        name: 'SettingsMetrics',
        component: require('src/views/Dashboard/Settings/Metrics').default,
      },
      {
        path: 'reports',
        name: 'SettingsReports',
        component: require('src/views/Dashboard/Settings/Reports').default,
      },
      {
        path: 'reports/edit/:reportId',
        name: 'SettingsReportsEdit',
        component: require('src/views/Dashboard/Settings/Reports/Form').default,
      },
      {
        path: 'reports/:reportId/links',
        name: 'SettingsReportsEditLinks',
        component: require('src/views/Dashboard/Settings/Reports/Links').default,
      },
      {
        path: 'reports/:reportId/replace-words',
        name: 'SettingsReportsReplaceWords',
        component: require('src/views/Dashboard/Settings/Reports/ReplaceWords').default,
      },
      {
        path: 'reports/:reportId/replace-metrics',
        name: 'SettingsReportsReplaceMetrics',
        component: require('src/views/Dashboard/Settings/Reports/ReplaceMetrics').default,
      },
      {
        path: 'reports/:reportId/components/:componentId/conditional-statements',
        name: 'SettingsComponentsEditConditionalStatements',
        component: require('src/views/Dashboard/Settings/Reports/Components/ConditionalStatements').default,
      },
      {
        path: 'reports/:reportId/components/:componentId/links',
        name: 'SettingsComponentsEditLinks',
        component: require('src/views/Dashboard/Settings/Reports/Components/Links').default,
      },
      {
        path: 'company-settings',
        name: 'SettingsCompanySettings',
        component: require('src/views/Dashboard/Settings/CompanySettings').default,
      },
      {
        path: 'users',
        name: 'SettingsUsers',
        component: require('src/views/Dashboard/Settings/Users').default,
      },
      {
        path: 'metrics-access-groups',
        name: 'SettingsMetricsAccessGroups',
        component: require('src/views/Dashboard/Settings/MetricsAccessGroups').default,
      },
      {
        path: 'report-access-groups',
        name: 'SettingsReportAccessGroups',
        component: require('src/views/Dashboard/Settings/ReportAccessGroups').default,
      },
    ],
  },
]

export default routes
