
import { defineComponent, getCurrentInstance, PropType } from 'vue'
import { timeSpanToLocalWord } from 'src/business/timeSpan'
import MetricsBadge from 'src/components/MetricsBadge.vue'
import { Metrics } from 'src/models/new/metrics'
import { wrappedMapGetters } from 'src/hooks/storeHook'

export type ExtendedMetrics = Metrics & {
  workplaceName: string
}

export default defineComponent({
  components: {
    MetricsBadge,
  },
  props: {
    metricsItems: {
      type: Array as PropType<ExtendedMetrics[]>,
      required: true,
    },
    edit: {
      type: Function,
      required: true,
    },
    del: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy
    // FIXME: 現状だとユーザー自身が何らか編集権限付きのアクセスグループに属していると
    // 実際にそのメトリクスに対して権限が無くても表示されてしまう
    const isUserAllowedToEdit = wrappedMapGetters(root.$store, 'user', ['hasMetricsGtManagerRole']).hasMetricsGtManagerRole

    return {
      props,
      isUserAllowedToEdit,
      timeSpanToLocalWord,
    }
  },
})
