import { useValidator } from 'src/composables/useValidator';
import type { MapObject, Validator } from 'vee-validate';
import { computed, getCurrentInstance, inject, onUnmounted, provide, ref, type Ref } from 'vue';
import { DataSourceParameterInput } from './types';
import { LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS } from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import { useDataSources } from 'src/composables/asyncResources/useDataSources';

type InjectionValue = {
  inputs: Ref<DataSourceParameterInput[]>;
  fieldNames: Ref<{ eachFields: string[]; combined: string }>;
  validationResult: Ref<MapObject>;
  validator: Ref<Validator>;
};

export type UseDataSourceParameterInputValidationArgs = {
  inputs: Ref<DataSourceParameterInput[]>;
};

export type UseDataSourceParameterInputValidationResult = {
  fieldNames: Ref<{ eachFields: string[]; combined: string }>;
  isDirty: Ref<boolean>;
  validationResult: Ref<MapObject>;
  cleanUpEachFieldValidationErrors: (fieldName: string) => void;
  validateEachField: (fieldIndex: number) => boolean;
  validateCombinedCondition: () => boolean;
  ensureValidation: () => Promise<boolean>;
};

const key = Symbol('dataSourceParameterInputValidation');

// 引数は同じProvide/Inject可能なスコープの呼び出し元の間では共有されている前提で、最初に渡された値のみ利用される
export const useDataSourceParameterInputValidation = (args: UseDataSourceParameterInputValidationArgs) => {
  const injected = inject<InjectionValue | null>(key, null);

  const { validationResult: originalValidationResult, setValidation } = useValidator();

  const inputs = injected !== null ? injected.inputs : args.inputs;
  const fieldNames =
    injected !== null
      ? injected.fieldNames
      : computed(() => {
          return {
            eachFields: inputs.value.map((_input, index) => `dataSourceParameterValue_${index}`),
            combined: 'dataSourceParameterValues',
          };
        });
  const validationResult = injected !== null ? injected.validationResult : originalValidationResult;
  // useDataSourceParameterInputValidationを利用している親のスコープに存在する場合はinjectedが存在する
  // その場合は親（最初に呼び出してprovideしたコンポーネント）をバリデータスコープのルートとして扱い、そのvalidatorを共用とする
  const validator =
    injected !== null ? injected.validator : (ref(getCurrentInstance()!.proxy.$validator) as Ref<Validator>);

  if (injected === null) {
    setValidation(
      computed(() => {
        // validationResultに監視するエラーを登録するだけなのでvalidationの中身はダミー値true
        return fieldNames.value.eachFields.reduce<MapObject>(
          (validations, eachField) => {
            validations[eachField] = true;
            return validations;
          },
          { [fieldNames.value.combined]: true },
        );
      }),
    );

    provide(key, {
      inputs,
      fieldNames,
      validationResult,
      validator,
    });
  }

  // 基本的に対外的なインターフェースはfieldIndexを要求する形だが、内部処理上fieldNameが
  // 先に分かっている場合があるのでfieldNameを指定してエラーをクリーンアップする関数も用意している
  const cleanUpValidationErrorsByFieldName = (fieldName: string) => {
    validator.value?.errors.remove(fieldName);
  };
  const cleanUpEachFieldValidationErrors = (fieldIndex: number) => {
    const fieldName = fieldNames.value.eachFields[fieldIndex];
    if (fieldName === undefined) {
      return;
    }
    cleanUpValidationErrorsByFieldName(fieldName);
  };

  const cleanUpCombinedConditionValidationErrors = () => {
    validator.value?.errors.remove(fieldNames.value.combined);
  };

  const cleanUpValidationErrors = () => {
    fieldNames.value.eachFields.forEach((fieldName) => {
      cleanUpValidationErrorsByFieldName(fieldName);
    });
    cleanUpCombinedConditionValidationErrors();
  };

  const validateValuePresent = (input: DataSourceParameterInput, fieldName: string): boolean => {
    if (input.values.length === 0) {
      validator.value?.errors.add({
        field: fieldName,
        rule: 'required',
        // msgの値を使うことはないがtruthyであってほしいのでダミー値を入れておく
        msg: 'error',
      });
      return false;
    }
    return true;
  };

  const validateLogiscopeAggregationKeyLength = (input: DataSourceParameterInput, fieldName: string): boolean => {
    if (input.values.some((el) => el.length > 20)) {
      validator.value?.errors.add({
        field: fieldName,
        rule: 'max',
        // msgの値を使うことはないがtruthyであってほしいのでダミー値を入れておく
        msg: 'error',
      });
      return false;
    }
    return true;
  };

  const validateLogiscopeAggregationKeyFormat = (input: DataSourceParameterInput, fieldName: string): boolean => {
    if (input.values.some((el) => !el.match(/^[a-zA-Z0-9]*$/))) {
      validator.value?.errors.add({
        field: fieldName,
        rule: 'match',
        // msgの値を使うことはないがtruthyであってほしいのでダミー値を入れておく
        msg: 'error',
      });
      return false;
    }
    return true;
  };

  const validateParameterCount = (inputs: DataSourceParameterInput[]): boolean => {
    const valueCount = inputs.reduce((count, input) => count + input.values.length, 0);
    if (valueCount > LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS) {
      validator.value?.errors.add({
        field: fieldNames.value.combined,
        rule: 'max',
        // msgの値を使うことはないがtruthyであってほしいのでダミー値を入れておく
        msg: 'error',
      });
      return false;
    }
    return true;
  };

  const { dataSourceParametersRef } = useDataSources();
  const logiscopeAggregationCodeParameterId = computed(() => {
    return dataSourceParametersRef.value.find((el) => el.name === 'logiscope_aggregation_code')?.id;
  });

  const validateEachField = (fieldIndex: number): boolean => {
    let isValid = true;
    const fieldName = fieldNames.value.eachFields[fieldIndex];
    const input = inputs.value[fieldIndex];
    if (input?.parameterId && fieldName !== undefined) {
      cleanUpValidationErrorsByFieldName(fieldName);

      const isPassed = validateValuePresent(input, fieldName);
      if (!isPassed) {
        isValid = false;
      }

      if (input.parameterId === logiscopeAggregationCodeParameterId.value) {
        const isPassed =
          validateLogiscopeAggregationKeyLength(input, fieldName) &&
          validateLogiscopeAggregationKeyFormat(input, fieldName);
        if (!isPassed) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const validateCombinedCondition = (): boolean => {
    cleanUpCombinedConditionValidationErrors();
    return validateParameterCount(inputs.value);
  };

  const ensureValidation = async (): Promise<boolean> => {
    let isValid = true;

    inputs.value.forEach(async (_, index) => {
      const isFieldValid = validateEachField(index);
      if (!isFieldValid) {
        isValid = false;
      }
    });

    const isCombinedConditionValid = validateCombinedCondition();
    if (!isCombinedConditionValid) {
      isValid = false;
    }

    return isValid;
  };

  onUnmounted(() => {
    cleanUpValidationErrors();
  });

  return {
    fieldNames,
    validationResult,
    cleanUpEachFieldValidationErrors,
    validateEachField,
    validateCombinedCondition,
    ensureValidation,
  };
};
