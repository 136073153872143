import { StringOrNumber } from 'src/models/common';

export const buildUrl = (
  scheme: string,
  mainPart: StringOrNumber,
  queryString: string = '',
  urlHash: string = '',
): string => {
  const queryPart = queryString !== '' ? `?${queryString}` : '';
  const hashPart = urlHash !== '' ? `#${queryString}` : '';
  return `${scheme}${mainPart}${queryPart}${hashPart}`;
};

const SCHEME_HTTPS = 'https://';
const SAFE_SCHEMAS = [SCHEME_HTTPS];

export const extractUrlMainPart = (parsedUrl: URL): string => parsedUrl.pathname.replace(/^\/\//g, '/');
export const extractUrlQueryString = (parsedUrl: URL): string => parsedUrl.search.replace(/^\?/g, '');
export const extractUrlHash = (parsedUrl: URL): string => parsedUrl.hash.replace(/^#/g, '');

const isParsableUrl = (url: string): boolean => {
  // この処理は互換的APIがURL.canParseがwhatwgの仕様書に定義されている
  // 現時点では対応ブラウザが少ないので、実質的に同じ処理を手書きしている
  try {
    // eslint-disable-next-line no-unused-vars
    const _ = new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isSafeUrl = (url: string): boolean => {
  const isSchemaSafe = SAFE_SCHEMAS.some((scheme) => url.startsWith(scheme));

  const host = url.replace(/^[^:/]+:\/\//, '').replace(/\/.*$/, '');

  const isNamedHost = /[a-zA-Z-]/.test(host);
  const is8bitIPv4Host = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(host);

  return isSchemaSafe && (isNamedHost || is8bitIPv4Host);
};

export const isInternalUrl = (url: string): boolean => !!url.startsWith(location.origin);

export const getUrlInternalPath = (url: string): string => {
  if (!isInternalUrl(url)) {
    return '';
  }
  return url.replace(location.origin, '');
};
