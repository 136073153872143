import { render, staticRenderFns } from "./IconSelectionForm.vue?vue&type=template&id=4159cf64&scoped=true&"
import script from "./IconSelectionForm.vue?vue&type=script&lang=ts&"
export * from "./IconSelectionForm.vue?vue&type=script&lang=ts&"
import style0 from "./IconSelectionForm.vue?vue&type=style&index=0&id=4159cf64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4159cf64",
  null
  
)

export default component.exports