// TODO: 全体的に作りが古いのでリファクタリング対象
// DataFetchStatusと共通の型、およびメソッドを持つ

const TARGET_MASTER_METRICS = 'metrics';
export type MASTER_CSV_JOB_STATUS_TARGET_MASTER = typeof TARGET_MASTER_METRICS;

const EXECUTE_TYPE_IMPORT = 'import';
const EXECUTE_TYPE_EXPORT = 'export';
export type MASTER_CSV_JOB_STATUS_EXECUTE_TYPE = typeof EXECUTE_TYPE_IMPORT | typeof EXECUTE_TYPE_EXPORT;

const EXECUTE_STATUS_FAILED = 'failed';
const EXECUTE_STATUS_IN_PROGRESS = 'in_progress';
const EXECUTE_STATUS_COMPLETED = 'completed';
export type MASTER_CSV_JOB_STATUS_EXECUTE_STATUS =
  | typeof EXECUTE_STATUS_FAILED
  | typeof EXECUTE_STATUS_IN_PROGRESS
  | typeof EXECUTE_STATUS_COMPLETED;

const EXECUTE_STATUS_COLOR_MAP = {
  [EXECUTE_STATUS_FAILED]: 'ed7d31',
  [EXECUTE_STATUS_IN_PROGRESS]: 'ffc000',
  [EXECUTE_STATUS_COMPLETED]: '70ad47',
};

export type MasterCsvJobStatus = {
  id: number;
  companyId: number;
  startTime: Date;
  endTime: Date | null;
  targetMaster: MASTER_CSV_JOB_STATUS_TARGET_MASTER;
  executeType: MASTER_CSV_JOB_STATUS_EXECUTE_TYPE;
  executeStatus: MASTER_CSV_JOB_STATUS_EXECUTE_STATUS;
  targetMasterText: string;
  executeStatusText: string;
  executeTypeText: string;
  errorMessage: string | null;
  fileName: string;
  isDownloadable: boolean;
};

export function getExecuteStatusColor(masterCsvJobStatus: Partial<MasterCsvJobStatus>) {
  return EXECUTE_STATUS_COLOR_MAP[masterCsvJobStatus.executeStatus!];
}
