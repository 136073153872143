
import { PropType, defineComponent } from 'vue';
import { CSSProperties } from 'vue/types/jsx';
import { Report } from 'src/models/new/report';

export default defineComponent({
  props: {
    report: {
      type: Object as PropType<Report>,
      required: true,
    },
  },

  setup(props) {
    const name = 'ReportThemeColorRoundPoint';

    const styleFromReport = (report: Report): Pick<CSSProperties, 'backgroundColor'> => {
      return { backgroundColor: `#${report.themeColor}` };
    };

    return { name, props, styleFromReport };
  },
});
