
import { PropType, computed, defineComponent, reactive, watch } from 'vue';
import Chart from 'chart.js';
import PieChart from 'src/components/UIComponents/Charts/PieChart.vue';
import {
  MetricsPieChartComponent,
  applyConditionalStatementToPieChartComponentColor,
  isMetricsPieChartComponentDisplayValueAsHidden,
  isMetricsPieChartComponentDisplayValueAsRate,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsPieChartComponent';
import {
  PieChartItem,
  getOptionForRatePieChart,
  getOrderedPieChartItems,
  legendLabelsFromPieChartItems,
  datasetFromPieChartItems,
  rateDatasetFromPieChartItems,
  getOptionForPieChart,
  getPieChartPlugins,
  CustomPieChartDataDataset,
  CustomPieChartOptions,
} from 'src/util/chart';
import { ChartDataLabel } from 'src/components/UIComponents/Charts/shared';

type State = {
  title: string;
  labels: ChartDataLabel[];
  datasets: CustomPieChartDataDataset[];
  options: CustomPieChartOptions;
  plugins: Chart.PluginServiceRegistrationOptions[];
  height: string;
};

export default defineComponent({
  components: {
    PieChart,
  },
  props: {
    component: {
      type: Object as PropType<MetricsPieChartComponent>,
      required: true,
    },
  },
  setup(props) {
    // state.datasetsの代入で使用する為、例外的にstate定義よりも先に定義する
    const generateChartItems = (component: MetricsPieChartComponent): PieChartItem[] => {
      const rawChartItems: PieChartItem[] = component.data.map((el) => {
        const overrideHashedColor = applyConditionalStatementToPieChartComponentColor(component, el.internalOrder);
        return {
          label: el.alias || el.metrics.name,
          value: el.metrics.value,
          decimalPlaces: el.metrics.decimalPlaces,
          scaling: el.metrics.scaling,
          color: overrideHashedColor ? (overrideHashedColor as string) : el.color,
        };
      });
      return getOrderedPieChartItems(rawChartItems);
    };

    const generateDatasets = (component: MetricsPieChartComponent): CustomPieChartDataDataset[] => {
      const chartItems = generateChartItems(component);
      const dataset = isMetricsPieChartComponentDisplayValueAsRate(component)
        ? rateDatasetFromPieChartItems(chartItems)
        : datasetFromPieChartItems(chartItems);
      return [dataset];
    };

    const state: State = reactive({
      title: computed(() => props.component.name),
      labels: computed(() => generateLabels(props.component)),
      // データセットは算出プロパティで渡してはならず、onMounted内ではなくsetup内で代入する必要がある
      // setup内で値が与えられない場合グラフがレンダリングされず、算出プロパティを与えると内部的に書き換えが発生して無限ループに陥る
      // オブジェクトは引き継いでいないはずだが、詳しい原因は不明
      datasets: generateDatasets(props.component),
      options: computed(() => generateOptions(props.component)),
      plugins: computed(() => generatePlugins(props.component)),
      // コンポーネントの1行あたりの高さが100pxで複数行の場合は間に10pxのマージンが入る
      // 行間のマージン1つ分、2行分のタイトルの高さ、タイトル下のマージン、カードのパディング合計との差をグラフの高さとする
      height: computed(() => `${110 * props.component.height - 110}px`),
    });

    const generateLabels = (component: MetricsPieChartComponent): ChartDataLabel[] => {
      const chartItems = generateChartItems(component);
      return legendLabelsFromPieChartItems(chartItems);
    };

    const generateOptions = (component: MetricsPieChartComponent): CustomPieChartOptions => {
      return !isMetricsPieChartComponentDisplayValueAsRate(component)
        ? getOptionForPieChart(component.displayValueSettings.unit)
        : getOptionForRatePieChart();
    };

    const generatePlugins = (component: MetricsPieChartComponent): Chart.PluginServiceRegistrationOptions[] => {
      return getPieChartPlugins(isMetricsPieChartComponentDisplayValueAsHidden(component));
    };

    watch(
      () => props.component,
      (component) => {
        state.datasets = generateDatasets(component);
      },
    );

    return {
      state,
      props,
    };
  },
});
