
import { vvGetError, vvGetErrorObject, vvHasError, vvValidate } from 'src/util/vee_validate';
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive } from 'vue';
import { MetricsAccessGroup } from 'src/models/new/AccessGroup/metricsAccessGroup';
import {
  ERROR_GROUP_SYSTEM,
  ERROR_GROUP_USER,
  ERROR_REASON_INVALID,
  ERROR_REASON_ACCESS_GROUP_NOT_FOUND,
  ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND,
  ERROR_REASON_METRICS_NOT_FOUND,
  TIME_SPANS,
  SYSTEM_MAX_VALUE,
  ERROR_REASON_NAME_NOT_UNIQUE,
} from 'src/consts';
import workplaceApi from 'src/apis/masters/workplace';
import metricsApi from 'src/apis/masters/metrics';
import SelectAccessGroupForm from 'src/components/NewSelectItemForm/SelectAccessGroupForm.vue';
import { requestTimeout } from 'src/util/request_animation_frame';
import { DirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import { isRecordIdValid, validRecordIdOrNull } from 'src/util/recordId';
import { metricsToTypeLocalWord, METRICS_ACCESS_GROUP_MAX_COUNT } from 'src/models/new/metrics';
import { Workplace } from 'src/models/new/workplace';
import InputError from 'src/components/InputError.vue';
import MetricsInputErrors from 'src/components/MetricsInputErrors.vue';
import InputWarn from 'src/components/InputWarn.vue';
import { useMetricsAccessGroupsOfMetrics } from 'src/views/Dashboard/Settings/Metrics/composables/useMetricsAccessGroupsOfMetrics';
import { useSimpleEvent } from 'src/composables/useSimpleEvent';
import { useScalingOptions } from 'src/composables/options/useScalingOptions';
import { useNotifiers } from 'src/composables/useNotifiers';

interface State {
  // 各種マスタデータのロード完了を示すフラグ
  isLoaded: boolean;
  // リクエスト送信処理中であることを表すフラグ
  isRequesting: boolean;
  showMetricsAccessGroupSelectForm: boolean;
  validations: Record<string, Object>;
  hasError: boolean;
  metrics: DirectInputMetrics;
  metricsIsNew: boolean;
  metricsTypeLocalWord: string;
  userSelectMetricsAccessGroups: Array<MetricsAccessGroup>;
  // プロパティとv-modelの接続を調整する
  validWorkplaceIdOrNull: number | null;
  workplaceOptions: Workplace[];
  showDefaultValueWarn: boolean;
}

const CONFIRM_ACCESS_GROUP_EVENT_KEY = 'confirmSelectAccessGroupForm';

export default defineComponent({
  components: {
    SelectAccessGroupForm,
    InputError,
    MetricsInputErrors,
    InputWarn,
  },
  props: {
    value: {
      type: Object as PropType<DirectInputMetrics>,
      required: true,
    },
  },
  emits: ['input', 'updated', 'close'],
  setup(props, { emit }) {
    const root = getCurrentInstance()!.proxy;
    const state: State = reactive({
      isLoaded: false,
      isRequesting: false,
      showMetricsAccessGroupSelectForm: false,
      validations: {
        name: { required: true, max: 50 },
        workplaceId: { required: true },
        unit: { required: false, max: 10 },
        decimalPlaces: { required: true, min_value: 0, max_value: 2, numeric: true },
        defaultValue: { required: false, floatWithDecimalPlaces: true },
      },
      hasError: computed(() => vvHasError(root)),
      metrics: computed({
        get() {
          return props.value;
        },
        set(next) {
          emit('input', structuredClone(next));
        },
      }),
      metricsIsNew: computed(() => !isRecordIdValid(state.metrics.id)),
      metricsTypeLocalWord: computed(() => metricsToTypeLocalWord(state.metrics)),
      userSelectMetricsAccessGroups: computed({
        get(): Array<MetricsAccessGroup> {
          return metricsAccessGroupsOnMetricsWithoutAdmin.value;
        },
        set(value: Array<MetricsAccessGroup>) {
          metricsAccessGroupsOnMetrics.value = metricsAccessGroupOnMetricsWithAdmin.value
            ? [metricsAccessGroupOnMetricsWithAdmin.value, ...value]
            : value;
        },
      }),
      validWorkplaceIdOrNull: computed({
        get() {
          return validRecordIdOrNull(state.metrics.workplaceId);
        },
        set(value) {
          state.metrics.workplaceId = value || 0;
        },
      }),
      workplaceOptions: [],
      showDefaultValueWarn: computed(() => {
        // 型がnumber|nullなので本来は左のnull判定だけで問題ないはずだが、
        // vueの仕様上inputフィールド更新後にstring型で値がセットされるため仕方なく右の判定をしている
        // (vue3アップデート & vee-validate4採用で解決される予定)
        return state.metrics.defaultValue !== null && String(state.metrics.defaultValue).length > 0;
      }),
    });

    const { notifyError } = useNotifiers();

    const {
      metricsAccessGroupsOnMetrics,
      metricsAccessGroupsOnMetricsWithoutAdmin,
      metricsAccessGroupOnMetricsWithAdmin,
      loadMetricsAccessGroupsOnMetrics,
    } = useMetricsAccessGroupsOfMetrics();

    const { options: scalingOptions } = useScalingOptions();

    const getError = (fieldName: string): string | null => vvGetError(root, fieldName);
    const getErrorObject = (fieldName: string): object | null => vvGetErrorObject(root, fieldName);

    const setWorkplaceOptions = async () => {
      state.workplaceOptions = await workplaceApi.index();
    };

    const goToAccessGroupSelectForm = (): void => {
      state.showMetricsAccessGroupSelectForm = true;
    };
    const backToMainForm = (): void => {
      state.showMetricsAccessGroupSelectForm = false;
    };

    // See: SelectAccessGroupForm
    const setSelectedAccessGroups = (metricsAccessGroups: Array<MetricsAccessGroup>): void => {
      state.userSelectMetricsAccessGroups = metricsAccessGroups;
      backToMainForm();
    };

    const { triggerer: confirmEventTriggerer } = useSimpleEvent(CONFIRM_ACCESS_GROUP_EVENT_KEY);

    const triggerConfirmEvent = () => {
      confirmEventTriggerer.trigger();
    };

    const onDefaultValueChange = (): void => {
      if (state.metrics.defaultValue && state.metrics.defaultValue < -SYSTEM_MAX_VALUE) {
        state.metrics.defaultValue = -SYSTEM_MAX_VALUE;
      } else if (state.metrics.defaultValue && state.metrics.defaultValue > SYSTEM_MAX_VALUE) {
        state.metrics.defaultValue = SYSTEM_MAX_VALUE;
      }
    };

    const close = (): void => emit('close');

    const save = async (): Promise<void> => {
      // 二重処理防止
      if (state.isRequesting) {
        return;
      }
      state.isRequesting = true;
      requestTimeout(() => {
        state.isRequesting = false;
      }, 300);

      if (!(await vvValidate(root))) {
        return;
      }

      const metrics: DirectInputMetrics = {
        ...state.metrics,
        accessGroupIds: state.userSelectMetricsAccessGroups.map((metricsAccessGroup) => metricsAccessGroup.id),
      };
      const operationWord = state.metricsIsNew ? '作成' : '更新';
      try {
        if (state.metricsIsNew) {
          await metricsApi.create(metrics);
        } else {
          await metricsApi.update(metrics);
        }
        emit('updated', `${state.metrics.name} を${operationWord}しました。`);
      } catch (err: any) {
        const errId = state.metricsIsNew ? 'ERR00001' : 'ERR00002';
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          const msg = 'アクセスする権限がありません。管理者にお問合せください。';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_METRICS_NOT_FOUND) {
          const msg = '選択したメトリクスは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_ACCESS_GROUP_NOT_FOUND) {
          const msg = '選択したメトリクスグループは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_LOGISCOPE_WORKPLACE_NOT_FOUND) {
          const msg = '選択した集計用センターは存在しません。ページを更新し、再度お試しください';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_INVALID) {
          notifyError(errRes.message, ERROR_GROUP_USER, err);
        } else if (errStatus === 409 && errRes.reason === ERROR_REASON_NAME_NOT_UNIQUE) {
          const msg = '入力されたメトリクス名は既に使用されています';
          notifyError(msg, ERROR_GROUP_USER, err);
        } else {
          const msg = `メトリクスの${operationWord}に失敗しました。管理者に連絡してください。`;
          notifyError(msg, ERROR_GROUP_SYSTEM, err, errId);
        }
      }
    };

    onMounted(async () => {
      // Vue 2x 暫定措置 3x系の場合はonUnmountedでフラグを戻す
      // Vue 2x ではonUnmountedがdestroyedに対するフックのエイリアスであるためonMountedの先頭に記述している
      state.isLoaded = false;
      state.showMetricsAccessGroupSelectForm = false;

      await Promise.all([loadMetricsAccessGroupsOnMetrics(state.metrics), setWorkplaceOptions()]);

      state.isLoaded = true;
    });

    return {
      state,
      props,
      getError,
      getErrorObject,
      METRICS_ACCESS_GROUP_MAX_COUNT,
      TIME_SPANS,
      goToAccessGroupSelectForm,
      backToMainForm,
      setSelectedAccessGroups,
      CONFIRM_ACCESS_GROUP_EVENT_KEY,
      triggerConfirmEvent,
      save,
      close,
      onDefaultValueChange,
      scalingOptions,
    };
  },
});
