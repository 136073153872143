
import Chart from 'chart.js';
import { Bar } from 'vue-chartjs';
import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { deepClone, deepMerge } from 'src/util/object';
import { BarLineChartDataDataset, BarLineChartOptions } from 'src/util/Chart/barLineChart';
import {
  ChartDataLabel,
  ChartElement,
  ChartPlugin,
  ChartScaleLabel,
  RelativePixelPosition,
  attachScaleLabels,
  buildChartData,
  isIncludingAnnotationPlugin,
  replaceStagedDataset,
  wrapOnChartClick,
} from 'src/components/UIComponents/Charts/shared';
import { horizontalLineAnnotationPluginDefaultOptions } from './annotationsPluginDefaultOptions';
import { tooltipsDefaultOptions } from './tooltipsDefaultOptions';
import { multiChartTooltipsDefaultOptions } from './multiChartTooltipsDefaultOptions';
import { HorizontalLineAnnotationOptions } from 'src/util/chart';

// 必ずbarがlineよりも奥側に描画されるようにするため、barの場合はorderに固定値で1000を加算する
const BAR_ORDER_FACTOR = 1000;

const axisTickOptions = {
  beginAtZero: true,
  callback: (value: number) => value.toLocaleString('ja-JP'),
  padding: 20,
  maxTicksLimit: 5,
  fontStyle: 'bold',
};

const defaultOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: true,
  },
  scales: {
    yAxes: [
      {
        id: 'y-axis-1',
        position: 'left',
        ticks: axisTickOptions,
        gridLines: {
          display: true,
          drawBorder: false,
        },
      },
      {
        id: 'y-axis-2',
        position: 'right',
        ticks: axisTickOptions,
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: false,
        },
      },
    ],
  },
  tooltips: tooltipsDefaultOptions,
};

type State = {
  styles: {
    height: string;
    width: string;
  };
};

export default defineComponent({
  components: {
    Bar,
  },
  props: {
    labels: {
      type: Array as PropType<ChartDataLabel[]>,
      required: true,
      description: 'Chart labels. Not reactive.',
    },
    datasets: {
      type: Array as PropType<BarLineChartDataDataset[]>,
      required: true,
      description: 'Chart datasets. Reactive. Do not use computed value.',
    },
    options: {
      type: Object as PropType<BarLineChartOptions | (BarLineChartOptions & HorizontalLineAnnotationOptions)>,
      required: false,
      description: 'Chart options. Half reactive (scrap and build new chart after mutation).',
    },
    plugins: {
      type: Array as PropType<ChartPlugin[]>,
      required: false,
      description: 'Chart plugins. Not reactive.',
    },
    scaleLabels: {
      type: Array as PropType<ChartScaleLabel[]>,
      required: false,
      description: 'Extended interface to set label on scales.',
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    onClick: {
      type: Function as PropType<
        (event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void
      >,
      required: false,
      description: 'Event which is fired when chart is clicked.',
    },
  },
  setup(props) {
    const myChart = ref(null as Bar | null);

    const state: State = reactive({
      styles: computed(() => ({ height: props.height, width: props.width })),
    });

    const setEnabledPluginDefaultOptions = (
      plugins: ChartPlugin[],
      options: Chart.ChartOptions,
    ): Chart.ChartOptions => {
      // BarLineChartの場合、想定しているプラグインの中でoptions以下に設定を持つものがないのでコメントアウト
      // if (!options.plugins) {
      //   options.plugins = {}
      // }

      if (isIncludingAnnotationPlugin(plugins)) {
        // annotationのオプションはoptions.pluginsではなく、options直下に設定する
        Object.assign(options, { annotation: horizontalLineAnnotationPluginDefaultOptions });
      }

      return options;
    };

    const computeScaleVisibilities = (
      options: Chart.ChartOptions,
      datasets: BarLineChartDataDataset[],
    ): Chart.ChartOptions => {
      const computedOptions = deepClone(options);
      computedOptions.scales?.yAxes?.forEach((el) => {
        el.display = datasets.some((dataset) => dataset.yAxisID === el.id);
      });

      return computedOptions;
    };

    const buildChartOptions = (
      options: BarLineChartOptions | (BarLineChartOptions & HorizontalLineAnnotationOptions),
      datasets: BarLineChartDataDataset[],
      plugins?: ChartPlugin[],
      scaleLabels?: ChartScaleLabel[],
      onClick?: (event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void,
    ): Chart.ChartOptions => {
      if (datasets.length > 1) {
        defaultOptions.tooltips = multiChartTooltipsDefaultOptions;
      }
      const mergedOptions = deepMerge(setEnabledPluginDefaultOptions(plugins ?? [], defaultOptions), options ?? {});
      const labelAttachedOptions = attachScaleLabels(scaleLabels ?? [], mergedOptions, 'y');
      const chartOptions = computeScaleVisibilities(labelAttachedOptions, datasets);

      if (onClick) {
        Object.assign(chartOptions, { onClick: wrapOnChartClick(onClick) });
      }

      return chartOptions;
    };

    const computeModifiedDatasetOrder = (datasets: BarLineChartDataDataset[]): BarLineChartDataDataset[] => {
      const computedDatasets = datasets.map(deepClone);
      return computedDatasets.map((el) => {
        const orderFactor = el.type !== undefined && el.type === 'bar' ? BAR_ORDER_FACTOR : 0;
        return {
          ...el,
          order: (el.order ?? 0) + orderFactor,
        };
      });
    };

    onMounted(async () => {
      const modifiedDatasets = computeModifiedDatasetOrder(props.datasets);

      const chartData = buildChartData(props.labels, modifiedDatasets);
      const chartOptions = buildChartOptions(
        props.options ?? {},
        props.datasets,
        props.plugins,
        props.scaleLabels,
        props.onClick,
      );

      // Vue-chartjs 4.x のマニュアルではpropsでdataとoptionsを渡せばよいことになっているが、上手くいかない
      // refでインスタンスを取得してrenderChartを実行するとグラフが描かれた
      myChart.value!.renderChart(chartData, chartOptions);
    });

    // datasetsはdataの要素数が一定である条件の中でインタラクティブに変更されるものとして想定する
    watch(
      () => props.datasets,
      (datasets) => {
        const modifiedDatasets = computeModifiedDatasetOrder(datasets);

        myChart.value!.$data._chart.data.datasets.forEach((el: BarLineChartDataDataset, index: number) => {
          replaceStagedDataset(el, modifiedDatasets[index]);
        });
        myChart.value!.$data._chart.update();
      },
      { deep: true },
    );

    // optionsはインタラクティブな変更を受け付ける
    // ただし、datasetsとは異なりグラフを最初から描き直す
    watch(
      () => props.options,
      (options) => {
        const modifiedDatasets = computeModifiedDatasetOrder(props.datasets);

        const chartData = buildChartData(props.labels, modifiedDatasets);
        const chartOptions = buildChartOptions(
          options ?? {},
          props.datasets,
          props.plugins,
          props.scaleLabels,
          props.onClick,
        );
        myChart.value!.renderChart(chartData, chartOptions);
      },
      { deep: true },
    );

    return {
      props,
      state,
      myChart,
    };
  },
});
