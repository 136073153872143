import { TIME_SPAN_DAILY } from 'src/business/timeSpan'
import { Metrics, internal } from 'src/models/new/metrics'
import { BasicMetrics } from 'src/models/new/Metrics/basicMetrics'

const DIRECT_INPUT = internal.DIRECT_INPUT

export type DirectInputMetrics = BasicMetrics & {
  defaultValue: number | null,
}

export const isMetricsDirectInputMetrics = (metrics: Metrics): metrics is DirectInputMetrics => {
  return metrics.metricsType === DIRECT_INPUT
}

export const constructEmptyDirectInputMetrics = (): DirectInputMetrics => {
  return {
    id: 0,
    workplaceId: 0,
    metricsType: DIRECT_INPUT,
    name: '',
    timeSpan: TIME_SPAN_DAILY,
    decimalPlaces: 0,
    scaling: 'one',
    unit: null,
    isEnabled: true,
    closingDate: null,
    value: null,
    accessGroupIds: [],
    defaultValue: null,
    updatedAt: null,
  }
}
