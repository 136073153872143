import type { FormItem } from 'src/components/NewSelectItemForm/types/formItem';
import type { TimeSpan } from 'src/consts';
import type { MetricsType } from 'src/models/new/metrics';
import { useWorkplaceOptions } from 'src/composables/options/useWorkplaceOptions';
import { useMetricsTypeOptions } from 'src/composables/options/useMetricsTypeOptions';
import { useTimeSpanOptions } from 'src/composables/options/useTimeSpanOptions';

// メトリクス検索フォームモード
// mode1: 集計用センターが必須
// mode2: メトリクスタイプと周期が必須
export type SearchMetricsFormMode = 'mode1' | 'mode2';

export type SearchMetricsParams = {
  workplaceId: number | null;
  name: string;
  metricsType: MetricsType | null;
  timeSpan: TimeSpan | null;
};

type SearchMetricsAllowedOptionMap = {
  workplaceIds?: number[];
  metricsTypes?: MetricsType[];
  timeSpans?: TimeSpan[];
};

export type SearchMetricsConfig = {
  mode?: SearchMetricsFormMode;
  allowedOptionMap: SearchMetricsAllowedOptionMap;
  defaultParams?: Partial<SearchMetricsParams>;
};

export const useSearchMetricsForm = (config: SearchMetricsConfig) => {
  const searchMetricsFormMode: SearchMetricsFormMode = config.mode ?? 'mode1';

  const { options: workplaceOptions, filterOptions: filterWorkplaceOptions } = useWorkplaceOptions();
  const { options: metricsTypeOptions, filterOptions: filterMetricsTypeOptions } = useMetricsTypeOptions();
  const { options: timeSpanOptions, filterOptions: filterTimeSpanOptions } = useTimeSpanOptions();

  filterWorkplaceOptions(config.allowedOptionMap.workplaceIds);
  filterMetricsTypeOptions(config.allowedOptionMap.metricsTypes);
  filterTimeSpanOptions(config.allowedOptionMap.timeSpans);

  const getWorkplaceFormItem = (params: { required: boolean; disabled: boolean }): FormItem<'select'> => ({
    searchKey: 'workplaceId',
    inputMode: 'select',
    inputOptions: {
      options: workplaceOptions.value,
      placeholder: '集計用センターを選択',
      disabled: params.disabled,
    },
    required: params.required,
  });

  const getMetricsTypeFormItem = (params: { required: boolean; disabled: boolean }): FormItem<'select'> => ({
    searchKey: 'metricsType',
    inputMode: 'select',
    inputOptions: { options: metricsTypeOptions.value, placeholder: 'タイプを選択', disabled: params.disabled },
    required: params.required,
  });

  const getTimeSpanFormItem = (params: { required: boolean; disabled: boolean }): FormItem<'select'> => ({
    searchKey: 'timeSpan',
    inputMode: 'select',
    inputOptions: {
      options: timeSpanOptions.value,
      placeholder: '周期を選択',
      disabled: params.disabled,
    },
    required: params.required,
  });

  const getNameFormItem = (params: { required: boolean }): FormItem<'text'> => ({
    searchKey: 'name',
    inputMode: 'text',
    inputOptions: {
      width: '250px',
      placeholder: 'メトリクス名で絞り込み',
    },
    required: params.required,
  });

  const mode1FormItems: Array<FormItem<'text' | 'select'>> = [
    getWorkplaceFormItem({
      required: true,
      disabled: config.allowedOptionMap.workplaceIds !== undefined && config.allowedOptionMap.workplaceIds.length <= 1,
    }),
    getNameFormItem({ required: false }),
    getMetricsTypeFormItem({ required: false, disabled: false }),
    getTimeSpanFormItem({
      required: false,
      disabled: config.allowedOptionMap.timeSpans !== undefined && config.allowedOptionMap.timeSpans.length <= 1,
    }),
  ];

  const mode2FormItems: Array<FormItem<'text' | 'select'>> = [
    getMetricsTypeFormItem({ required: true, disabled: false }),
    getTimeSpanFormItem({
      required: true,
      disabled: config.allowedOptionMap.timeSpans !== undefined && config.allowedOptionMap.timeSpans.length <= 1,
    }),
    getNameFormItem({ required: false }),
    getWorkplaceFormItem({
      required: false,
      disabled: false,
    }),
  ];

  const defaultParams =
    searchMetricsFormMode === 'mode1'
      ? {
          workplaceId: workplaceOptions.value[0]?.value ?? null,
          name: '',
          metricsType: null,
          timeSpan: timeSpanOptions.value[0]?.value ?? null,
        }
      : {
          metricsType: metricsTypeOptions.value[0]?.value ?? null,
          timeSpan: timeSpanOptions.value[0]?.value ?? null,
          name: '',
          workplaceId: null,
        };

  Object.assign(defaultParams, config.defaultParams ?? {});

  return {
    workplaceOptions,
    metricsTypeOptions,
    timeSpanOptions,
    formItems: searchMetricsFormMode === 'mode1' ? mode1FormItems : mode2FormItems,
    defaultParams,
  };
};
