import { TimeSpan } from 'src/business/timeSpan'
import { MetricsType } from 'src/models/new/metrics'
import { MetricsPartialInformation } from 'src/models/new/metricsPartialInformation'

export type MetricsCollectWithUniqueKeySetsResponseData = {
  id: number
  front_metrics_type: MetricsType
  logiscope_workplace_id: number
  name: string
  time_span: TimeSpan
}

export const convertFromMetricsCollectWithUniqueKeySetsResponseData = (data: MetricsCollectWithUniqueKeySetsResponseData): MetricsPartialInformation => {
  return {
    id: data.id,
    metricsType: data.front_metrics_type,
    workplaceId: data.logiscope_workplace_id,
    name: data.name,
    timeSpan: data.time_span,
  }
}
