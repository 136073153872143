
import { PropType, computed, defineComponent, reactive, watch } from 'vue';
import Chart from 'chart.js';
import BarLineChart from 'src/components/UIComponents/Charts/BarLineChart.vue';
import {
  addPluginForLineAnnotation,
  lineDatasetFromBarGraphItem,
  barDatasetFromBarGraphItem,
  CustomBarGraphDataDataset,
  CustomLineGraphDataDataset,
  CustomBarLineGraphOptions,
  HorizontalLineAnnotationOptions,
  buildHorizontalLineAnnotationOptions,
  getOptionForBarLineGraph,
  getCustomScaleLabelsForBarLineGraph,
} from 'src/util/chart';
import { ChartDataLabel, ChartScaleLabel } from 'src/components/UIComponents/Charts/shared';
import {
  MetricsGroupedGraphComponent,
  applyConditionalStatementToMetricsGroupedGraphComponentColor,
} from 'src/models/new/Component/MetricsComponent/GraphMetricsComponent/metricsGroupedGraphComponent';
import { GRAPH_LAYOUT_SCALE_POSITION_NAMES, orderFromGraphLayout } from 'src/business/graphLayout';
import { deepMerge } from 'src/util/object';

type State = {
  title: string;
  labels: ChartDataLabel[];
  datasets: Array<CustomBarGraphDataDataset | CustomLineGraphDataDataset>;
  options: CustomBarLineGraphOptions | (CustomBarLineGraphOptions & HorizontalLineAnnotationOptions);
  plugins: Chart.PluginServiceRegistrationOptions[];
  scaleLabels: ChartScaleLabel[];
  height: string;
};

export default defineComponent({
  components: {
    BarLineChart,
  },
  props: {
    component: {
      type: Object as PropType<MetricsGroupedGraphComponent>,
      required: true,
    },
  },
  setup(props) {
    const generateDatasets = (
      component: MetricsGroupedGraphComponent,
    ): Array<CustomBarGraphDataDataset | CustomLineGraphDataDataset> => {
      return component.data.graphs
        .sort((a, b) => orderFromGraphLayout(a) - orderFromGraphLayout(b))
        .filter((graph) => graph.plots.length > 0)
        .map((graph, index) => {
          const graphItems = graph.plots!.map((plot) => {
            const overrideHashedColor = applyConditionalStatementToMetricsGroupedGraphComponentColor(
              component,
              graph,
              plot.group,
            );
            return {
              value: plot.metrics.value,
              decimalPlaces: plot.metrics.decimalPlaces,
              scaling: plot.metrics.scaling,
              color: overrideHashedColor ? (overrideHashedColor as string) : plot.color,
              originalColor: plot.color,
              // optionsで上書きされ実際には使用されないが、型の都合上ダミー値をセットしている
              // See also: src/assets/src/util/Chart/custom/shared.ts
              label: '',
            };
          });
          const settings = {
            order: index + 1,
            // TODO: 暫定の仕様として設定、仕様を考える
            // 今まではラベルが設定されていないと表示されないようになっていた
            label: graph.label || `${GRAPH_LAYOUT_SCALE_POSITION_NAMES[graph.scale]}${graph.number}`,
            scale: graph.scale,
          };
          return graph.plotType === 'line'
            ? lineDatasetFromBarGraphItem(graphItems, settings)
            : barDatasetFromBarGraphItem(graphItems, settings);
        });
    };

    const state: State = reactive({
      title: computed(() => props.component.name),
      labels: computed(() => generateLabels(props.component)),
      // データセットは算出プロパティで渡してはならず、onMounted内ではなくsetup内で代入する必要がある
      // setup内で値が与えられない場合グラフがレンダリングされず、算出プロパティを与えると内部的に書き換えが発生して無限ループに陥る
      // オブジェクトは引き継いでいないはずだが、詳しい原因は不明
      datasets: generateDatasets(props.component),
      options: computed(() => generateOptions(props.component)),
      plugins: computed(() => generatePlugins(props.component)),
      scaleLabels: computed(() => generateScaleLabels(props.component)),
      // コンポーネントの1行あたりの高さが100pxで複数行の場合は間に10pxのマージンが入る
      // 行間のマージン1つ分、2行分のタイトルの高さ、タイトル下のマージン、カードのパディング合計との差をグラフの高さとする
      height: computed(() => `${110 * props.component.height - 110}px`),
    });

    const generateLabels = (component: MetricsGroupedGraphComponent): ChartDataLabel[] => {
      return component.data.graphs
        .find((el) => orderFromGraphLayout(el) === 1)!
        .plots.map((el) => el.label || el.metrics.name);
    };

    const generateOptions = (
      component: MetricsGroupedGraphComponent,
    ): CustomBarLineGraphOptions | (CustomBarLineGraphOptions & HorizontalLineAnnotationOptions) => {
      const options = getOptionForBarLineGraph();
      if (component.data.reference) {
        const verticalLineAnnotationOptions = buildHorizontalLineAnnotationOptions(
          component.data.reference.metrics.value,
          component.data.reference.metrics.decimalPlaces,
          component.data.reference.metrics.scaling,
          component.data.reference.label,
          component.data.reference.color,
        );
        return deepMerge(options, verticalLineAnnotationOptions);
      } else {
        return options;
      }
    };

    const generatePlugins = (_: MetricsGroupedGraphComponent): Chart.PluginServiceRegistrationOptions[] => {
      return addPluginForLineAnnotation([]);
    };

    const generateScaleLabels = (component: MetricsGroupedGraphComponent): ChartScaleLabel[] => {
      return getCustomScaleLabelsForBarLineGraph(component.scaleUnits.left, component.scaleUnits.right);
    };

    watch(
      () => props.component,
      (component) => {
        state.datasets = generateDatasets(component);
      },
    );

    return {
      state,
      props,
    };
  },
});
