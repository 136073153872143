import { ChartTooltipItem } from 'chart.js'
import { tooltipsDefaultOptions } from './tooltipsDefaultOptions'
import { ExtendedChartData } from 'src/util/Chart/shared'

// datasetsに含まれるdatasetが複数の場合に使用する
export const multiChartTooltipsDefaultOptions = {
  ...tooltipsDefaultOptions,
  callbacks: {
    ...tooltipsDefaultOptions.callbacks,
    title: (tooltipItems: ChartTooltipItem[], _chartData: ExtendedChartData): string => tooltipItems[0]?.label ?? '',
  },
}
