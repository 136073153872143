import { GrantedToAccessGroup, internal } from 'src/models/new/accessGroup';
import {
  AccessGroupResponseData,
  GrantedToAccessGroupResponseData,
} from 'src/models/api/AccessGroup/accessGroupResponseData';
import { ReportAccessGroup } from 'src/models/new/AccessGroup/reportAccessGroup';

const REPORT = internal.REPORT;

export type ReportAccessGroupResponseData = AccessGroupResponseData & {
  // レポートアクセスグループは権限を他のアクセスグループに与えることができる
  // その際、権限を共有する相手のアクセスグループの情報をgrantToに持つ
  granted_to_access_groups: GrantedToAccessGroupResponseData[];
};

export const convertFromReportAccessGroupResponseData = (
  response: ReportAccessGroupResponseData,
): ReportAccessGroup => {
  const grantedToAccessGroups: GrantedToAccessGroup[] = (response.granted_to_access_groups || []).map((el) => {
    return {
      id: el.id,
      name: el.name,
    };
  });
  return {
    id: response.id,
    name: response.name,
    type: REPORT,
    role: response.role,
    searchId: response.search_id,
    grantTo: grantedToAccessGroups,
  };
};
