import { useDeleteConfirmModal } from 'src/composables/useDeleteConfirmModal'
import { ComponentLink } from 'src/models/new/Link/componentLink'

export const useComponentLinkDeleteConfirmModal = () => {
  const { isDeleteConfirmModalShown, deleteTargetRef, openDeleteConfirmModalWithValue, closeDeleteConfirmModal } = useDeleteConfirmModal<ComponentLink>()
  const deleteTargetComponentLinkRef = deleteTargetRef

  return {
    isDeleteConfirmModalShown,
    deleteTargetComponentLinkRef,
    openDeleteConfirmModalWithValue,
    closeDeleteConfirmModal,
  }
}
