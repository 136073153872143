import { GridItemData } from 'vue-grid-layout';
import { Section } from 'src/models/new/section';

const COMMENT = 'comment';
const METRICS_CARD = 'metrics_card';
const METRICS_TABLE = 'metrics_table';
const METRICS_LIST = 'metrics_list';
const METRICS_GRAPH = 'metrics_graph';
const METRICS_GRAPH_PIE_CHART = `${METRICS_GRAPH}.pie_chart`;
const METRICS_GRAPH_BAR_GRAPH = `${METRICS_GRAPH}.bar_graph`;
const METRICS_GRAPH_GROUPED_GRAPH = `${METRICS_GRAPH}.grouped_graph`;
const METRICS_GRAPH_TRANSITION_GRAPH = `${METRICS_GRAPH}.transition_graph`;

export type ComponentType =
  | typeof COMMENT
  | typeof METRICS_CARD
  | typeof METRICS_TABLE
  | typeof METRICS_LIST
  | typeof METRICS_GRAPH
  | typeof METRICS_GRAPH_PIE_CHART
  | typeof METRICS_GRAPH_BAR_GRAPH
  | typeof METRICS_GRAPH_GROUPED_GRAPH
  | typeof METRICS_GRAPH_TRANSITION_GRAPH;

export type Component = {
  id: number;
  sectionId: number;
  name: string;
  componentType: ComponentType;
  abscissa: number;
  ordinate: number;
  width: number;
  height: number;
  includesLinks?: boolean;
};

export const COMPONENT_MAX_COMPONENT_LINKS = 6;

type PrimitiveComponentType = Extract<
  ComponentType,
  typeof METRICS_CARD | typeof METRICS_LIST | typeof METRICS_TABLE | typeof METRICS_GRAPH | typeof COMMENT
>;

export const COMPONENT_TYPES: ComponentType[] = [METRICS_CARD, METRICS_LIST, METRICS_TABLE, METRICS_GRAPH, COMMENT];

const isComponentTypePrimitive = (componentType: ComponentType): componentType is PrimitiveComponentType => {
  return COMPONENT_TYPES.includes(componentType as PrimitiveComponentType);
};

const COMPONENT_TYPE_LABELS: Record<PrimitiveComponentType, string> = {
  [METRICS_CARD]: 'カード',
  [METRICS_LIST]: 'リスト',
  [METRICS_TABLE]: '表',
  [METRICS_GRAPH]: 'グラフ',
  [COMMENT]: 'コメント',
};

export const componentTypeToLabel = (componentType: ComponentType): string => {
  if (!isComponentTypePrimitive(componentType)) {
    return '';
  }
  return COMPONENT_TYPE_LABELS[componentType];
};

const COMPONENT_MAX_ABSCISSA = 6;

export const layoutOfComponent = (component: Component): GridItemData => {
  return {
    x: component.abscissa,
    y: component.ordinate,
    w: component.width,
    h: component.height,
    i: component.id.toString(),
  };
};

// FIXME: 将来的にはSectionがcomponentsを持たなくなるので、インターフェースを変更する必要がある
// 重複判定ができればよいだけなので、Reportを引数によって内部のコンポーネントが重複しているか判定するだけでもよいかもしれない
export const isOverlappedWithOther = (component: Component, section: Section): boolean => {
  const top = component.ordinate;
  const bottom = component.ordinate + component.height;
  const left = component.abscissa;
  const right = component.abscissa + component.width;
  const overlapComponent = section.components.find((el) => {
    if (el.id === component.id) {
      return false;
    }
    const topE = el.ordinate;
    const bottomE = el.ordinate + el.height;
    const leftE = el.abscissa;
    const rightE = el.abscissa + el.width;
    if (bottom <= topE) {
      return false;
    }
    if (top >= bottomE) {
      return false;
    }
    if (right <= leftE) {
      return false;
    }
    if (left >= rightE) {
      return false;
    }
    return true;
  });

  return !!overlapComponent;
};

export const isOverBoundary = (component: Component): boolean => {
  return component.abscissa + component.width > COMPONENT_MAX_ABSCISSA;
};

// セクションへのコンポーネント追加時の座標情報を取得
// FIXME: ComponentのロジックにSectionを引数とする関数があるのは良くない
// 将来的にはSectionがcomponentsを持たなくなるので、その点も含めて再考する
export const getBottomOrdinate = (section: Section): number => {
  return section.components.reduce((bottom, component) => {
    return Math.max(bottom, component.ordinate + component.height);
  }, 0);
};

export const internal = {
  COMMENT,
  METRICS_CARD,
  METRICS_TABLE,
  METRICS_LIST,
  METRICS_GRAPH,
  METRICS_GRAPH_PIE_CHART,
  METRICS_GRAPH_BAR_GRAPH,
  METRICS_GRAPH_GROUPED_GRAPH,
  METRICS_GRAPH_TRANSITION_GRAPH,
} as const;
