import type { CsvRow } from 'src/components/UIComponents/Buttons/CsvDownload/types';
import { HeaderStructure } from 'src/util/dataHeader';
import { MetricsTableComponentTableCell } from 'src/models/new/Component/MetricsComponent/metricsTableComponent';
import { MetricsListComponentComputedListCell } from 'src/models/new/Component/MetricsComponent/metricsListComponent';
import { Metrics } from 'src/models/new/metrics';

const HEADER_CORNER_CELL_VALUE = '-';

export const generateComponentCsv = (
  head: HeaderStructure,
  side: HeaderStructure,
  contents: MetricsTableComponentTableCell[] | MetricsListComponentComputedListCell[],
  emptyCellValue: string = '',
): CsvRow[] => {
  const headDepth = head.depth;
  const headBreadth = head.breadth;
  const sideDepth = side.depth;
  const sideBreadth = side.breadth;
  const tableData = [] as CsvRow[];

  // 左上ヘッダー領域(空欄)と上部ヘッダー領域を作成
  tableData.push(...Array.from({ length: headDepth }, () => []));
  tableData.forEach((row: CsvRow, tableRowIndex) => {
    for (let tableColumn = 1; tableColumn <= sideDepth + headBreadth; tableColumn++) {
      // 左上ヘッダー領域
      if (tableColumn <= sideDepth) {
        row.push(HEADER_CORNER_CELL_VALUE);
        continue;
      }

      // 上部ヘッダー領域
      const cell = head.data.find((el) => el.level === tableRowIndex + 1 && el.position === tableColumn - sideDepth);
      if (!cell) {
        throw new Error('header data is not completed.');
      }
      row.push(cell.span > 0 ? cell.value ?? '' : emptyCellValue);
    }
  });

  // 左部ヘッダー領域とデータ領域を作成
  tableData.push(...Array.from({ length: sideBreadth }, () => []));
  tableData.forEach((row: CsvRow, tableRowIndex) => {
    if (headDepth > tableRowIndex) {
      return;
    }
    for (let tableColumn = 1; tableColumn <= sideDepth + headBreadth; tableColumn++) {
      // 左部ヘッダー領域
      if (tableColumn <= sideDepth) {
        const cell = side.data.find((el) => el.position === tableRowIndex + 1 - headDepth && el.level === tableColumn);
        row.push(cell ? cell.value ?? '' : emptyCellValue);
        continue;
      }

      // データ領域
      const cell =
        contents.find((el) => el.row === tableRowIndex + 1 - headDepth && el.column === tableColumn - sideDepth) ||
        null;
      row.push(cell ? formatCellMetricsValue(cell.metrics) : emptyCellValue);
    }
  });
  return tableData;
};

const formatCellMetricsValue = (metrics: Metrics): string => {
  if (metrics.value === null) {
    return '';
  }

  return metrics.value.toFixed(metrics.decimalPlaces);
};
