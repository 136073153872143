import { useDataSources } from 'src/composables/asyncResources/useDataSources'
import { RecordId } from 'src/util/recordId'
import { ComputedRef, computed, ref, unref } from 'vue'

export const useDataSourceOptions = () => {
  const {
    dataSourceKeysRef,
    dataSourceParametersRef,
    dataSourceParameterValuesRef,
  } = useDataSources()

  const endpointFilter = ref<string | null>(null)
  const keyOptionFilter = ref<RecordId[] | null>(null)
  const valueOptionFilter = ref<RecordId[] | null>(null)

  const filterEndpoint = (values?: string[]) => {
    endpointFilter.value = values?.[0] ?? null
  }

  const filterKeyOptions = (values?: RecordId[]) => {
    keyOptionFilter.value = values ?? null
  }

  const filterParameterOptions = (values?: RecordId[]) => {
    valueOptionFilter.value = values ?? null
  }

  const selectedEndpoint = ref<string | null>(null)
  const selectEndpoint = (endpoint: string | null) => {
    selectedEndpoint.value = endpoint
  }

  const selectedKeyId = ref<RecordId | null>(null)
  // 本来的にはDataSourceKeyを受け取るのが妥当だが、ユースケースを考えるとView上では選択したidしか分からないことが多い
  // その為例外的ではあるがidを受け取る形を選択している
  const selectKeyId = (dataSourceKeyId: number | null) => {
    selectedKeyId.value = dataSourceKeyId
  }

  const endpointOptions: ComputedRef<Array<{ value: string, label: string }>> = computed(() => {
    return dataSourceKeysRef.value.reduce((options, dataSourceKey) => {
      if (options.some((option) => option.value === dataSourceKey.endpoint)) return options

      options.push({
        value: dataSourceKey.endpoint,
        label: dataSourceKey.disp_endpoint,
      })
      return options
    }, [] as Array<{ value: string, label: string }>)
  })

  const keyOptions: ComputedRef<Array<{ value: RecordId, label: string }>> = computed(() => {
    const endpoint = unref(selectedEndpoint)
    if (endpoint === null) return []

    return dataSourceKeysRef.value.filter((dataSourceKey) => {
      return dataSourceKey.endpoint === endpoint &&
        (keyOptionFilter.value?.includes(dataSourceKey.id) ?? true)
    }).map((dataSourceKey) => {
      return {
        value: dataSourceKey.id,
        label: dataSourceKey.disp_name,
      }
    })
  })

  const parameterOptions: ComputedRef<Array<{ value: RecordId, label: string }>> = computed(() => {
    const keyId = unref(selectedKeyId)
    if (keyId === null) return []

    return dataSourceParametersRef.value.filter((dataSourceParameter) => {
      return dataSourceParameter.dataSourceKeyId === keyId &&
        (valueOptionFilter.value?.includes(dataSourceParameter.id) ?? true)
    }).map((dataSourceParameter) => {
      return {
        value: dataSourceParameter.id,
        label: dataSourceParameter.disp_name,
      }
    })
  })

  const getDataSourceParameterValueOptions = (parameterId: number | null, budgetGroupId: number | null): Array<{ value: string, label: string }> => {
    return dataSourceParameterValuesRef.value.filter((dataSourceParameterValue) => {
      return dataSourceParameterValue.dataSourceParameterId === parameterId &&
        dataSourceParameterValue.budgetGroupId === budgetGroupId
    }).map((dataSourceParameterValue) => {
      return {
        value: String(dataSourceParameterValue.id),
        label: dataSourceParameterValue.label,
      }
    })
  }

  return {
    endpointOptions,
    selectedEndpoint,
    keyOptions,
    selectedKeyId,
    parameterOptions,
    selectEndpoint,
    selectKeyId,
    filterEndpoint,
    filterKeyOptions,
    filterParameterOptions,
    getDataSourceParameterValueOptions,
  }
}
