import { LogiCoredataBudgetGroup } from 'src/models/new/logiCoredataBudgetGroup'
import { useLogiCoredataBudgetGroups } from 'src/composables/asyncResources/useLogiCoredataBudgetGroups'
import { useResourceOptions } from 'src/composables/useResourceOptions'
import { RecordId } from 'src/util/recordId'

export const useLogiCoredataBudgetGroupOptions = () => {
  const {
    options,
    setResources,
    filterOptions,
  } = useResourceOptions<LogiCoredataBudgetGroup>('name')

  const {
    logiCoredataBudgetGroupsRef,
  } = useLogiCoredataBudgetGroups()

  setResources(logiCoredataBudgetGroupsRef.value)

  return {
    options,
    filterOptions,
  }
}
