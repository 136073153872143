import { convertToReportCreateRequestParameters, ReportCreateRequestParameters } from './reportCreateRequestParameters'
import { RecordId } from 'src/util/recordId'
import { Report } from 'src/models/new/report'

export type ReportUpdateRequestParameters = Omit<ReportCreateRequestParameters, 'time_span'> & {
  id: RecordId
}

export const convertToReportUpdateRequestParameters = (report: Report): ReportUpdateRequestParameters => {
  const { time_span, ...params } = convertToReportCreateRequestParameters(report)
  return {
    ...params,
    id: report.id,
  }
}
