
import { setPageName } from 'src/hooks/displayPageNameHook'
import { computed, defineComponent, getCurrentInstance, reactive } from 'vue'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { navigateToLogiCoredata } from 'src/util/logibase'

type State = {
  logiCoredataUrl: string
  useLogiCoredata: boolean
}

export default defineComponent({
  setup() {
    const root = getCurrentInstance()!.proxy
    setPageName(root, '設定一覧')
    const state: State = reactive({
      logiCoredataUrl: computed(() => {
        const { logiCoredataUrl } = wrappedMapGetters(root.$store, 'user', ['logiCoredataUrl'])
        return logiCoredataUrl.value
      }),
      useLogiCoredata: computed(() => !!state.logiCoredataUrl),
    })
    const toLogiCoredata = (): void => {
      const path = '/workplace/select'
      navigateToLogiCoredata(state.logiCoredataUrl, path, true)
    }

    return {
      state,
      toLogiCoredata,
    }
  },
})

