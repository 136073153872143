import { RecordId } from 'src/util/recordId'
import { Ref, ref, unref } from 'vue'

type ResourceOption = {
  value: number
  label: string
}

export const useResourceOptions = <Resource extends { id: RecordId }>(labelProperty: keyof Resource) => {
  // アサーションを与えないとvalueへの代入時に型が合わない
  // @see: https://github.com/vuejs/core/issues/2136#issuecomment-1290541387
  const wholeResources = ref<Resource[]>([]) as Ref<Resource[]>
  const options = ref<ResourceOption[]>([])

  const setResources = (resources: Resource[]) => {
    wholeResources.value = resources
    options.value = buildOptions()
  }

  const buildOptions = (ids?: RecordId[]): ResourceOption[] => {
    const wholeResourcesValue = unref(wholeResources)
    const enabledOptionIds = ids ?? wholeResourcesValue.map(el => el.id)

    return wholeResourcesValue.filter(resource => enabledOptionIds.includes(resource.id))
      .map(resource => {
        return {
          value: resource.id,
          label: String(resource[labelProperty]),
        }
      }) ?? []
  }

  const filterOptions = (values?: RecordId[]) => {
    options.value = buildOptions(values)
  }

  return {
    options,
    setResources,
    filterOptions,
  }
}
