import { Workplace } from 'src/models/new/workplace'

export type WorkplaceResponseData = {
  id: number
  company_id: number
  workplace_id: number
  name: string
  disp_order: number
  created_at: string
  updated_at: string
  deleted_at: string | null
  created_by: number
  updated_by: number
}

export const convertFromWorkplaceResponseData = (data: WorkplaceResponseData): Workplace => {
  return {
    id: data.id,
    name: data.name,
    sequentialOrder: data.disp_order,
  }
}
