import axios from 'axios';
import { StringOrNumber } from 'src/models/common';
import { convertFromMetricsResponseData, MetricsResponseData } from 'src/models/api/Metrics/metricsResponseData';
import {
  convertMetricsIndexRequestParametersToLegacy,
  MetricsIndexRequestParameters,
} from 'src/models/api/Metrics/metricsIndexRequestParameters';
import { Metrics } from 'src/models/new/metrics';
import {
  convertToMetricsCreateRequestParameters,
  MetricsCreateRequestParameters,
} from 'src/models/api/Metrics/metricsCreateRequestParameters';
import {
  convertToMetricsUpdateRequestParameters,
  MetricsUpdateRequestParameters,
} from 'src/models/api/Metrics/metricsUpdateRequestParameters';
import { MetricsCollectWithUniqueKeySetsRequestParameters } from 'src/models/api/Metrics/metricsCollectWithUniqueKeySetsRequestParameters';
import { MetricsPartialInformation } from 'src/models/new/metricsPartialInformation';
import {
  convertFromMetricsCollectWithUniqueKeySetsResponseData,
  MetricsCollectWithUniqueKeySetsResponseData,
} from 'src/models/api/Metrics/metricsCollectWithUniqueKeySetsResponseData';
import { buildBackendUrl } from 'src/apis/util/url';
import { PaginatedMultiDataResponse } from 'src/models/api/shared/multiDataResponse';
import { convertFromPagination, Pagination } from 'src/models/api/shared/paginationResponseData';

const buildBasePath = (): string => '/masters/metrics';

export default {
  async index(data: MetricsIndexRequestParameters): Promise<{ result: Metrics[]; pagination: Pagination }> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<MetricsResponseData>>(url, {
      params: convertMetricsIndexRequestParametersToLegacy(data),
    });
    return {
      result: resData.result.map((v) => convertFromMetricsResponseData(v, {})),
      pagination: convertFromPagination(resData.pagination),
    };
  },
  async indexByIds(data: MetricsIndexRequestParameters): Promise<Metrics[]> {
    if (!data.ids) {
      return [];
    }

    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get<PaginatedMultiDataResponse<MetricsResponseData>>(url, { params: data });
    return resData.result.map((v) => convertFromMetricsResponseData(v, {}));
  },
  // FIXME: numberを要求する
  async show(id: StringOrNumber): Promise<Metrics> {
    const url = buildBackendUrl(buildBasePath(), id);
    const { data: resData } = await axios.get<MetricsResponseData>(url);
    return convertFromMetricsResponseData(resData, {});
  },
  async create(metrics: Metrics): Promise<{}> {
    const url = buildBackendUrl(buildBasePath());
    const convertedParams: MetricsCreateRequestParameters = convertToMetricsCreateRequestParameters(metrics);
    await axios.post(url, convertedParams);
    return {};
  },
  async update(metrics: Metrics): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), metrics.id);
    const convertedParams: MetricsUpdateRequestParameters = convertToMetricsUpdateRequestParameters(metrics);
    await axios.put(url, convertedParams);
    return {};
  },
  // FIXME: numberを要求する
  async delete(id: StringOrNumber): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), id);
    await axios.delete(url);
    return {};
  },
  async importCsv(data: FormData): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), 'import_csv');
    const { data: resData } = await axios.post(url, data, { headers: { 'content-type': 'multipart/form-data' } });
    return resData;
  },
  async exportCsv(data: MetricsIndexRequestParameters): Promise<{}> {
    const url = buildBackendUrl(buildBasePath(), 'export_csv');
    const { data: resData } = await axios.get(url, { params: convertMetricsIndexRequestParametersToLegacy(data) });
    return resData;
  },
  async collectWithUniqueKeySets(
    params: MetricsCollectWithUniqueKeySetsRequestParameters,
  ): Promise<MetricsPartialInformation[]> {
    const url = buildBackendUrl(buildBasePath(), 'collect_with_unique_key_sets');
    const { data: resData } = await axios.post(url, params);
    return (resData.result as MetricsCollectWithUniqueKeySetsResponseData[]).map(
      convertFromMetricsCollectWithUniqueKeySetsResponseData,
    );
  },
};
