import { getCurrentInstance } from 'vue'
import { ComponentLink } from 'src/models/new/Link/componentLink'
import componentLinkApi from 'src/apis/masters/componentLink'
import { useNotifiers } from 'src/composables/useNotifiers'
import { ERROR_GROUP_SYSTEM, ERROR_GROUP_USER } from 'src/consts'

export const useComponentLinkUpdater = () => {
  const vue = getCurrentInstance()!.proxy

  const {
    notifySuccess,
    notifyError,
  } = useNotifiers()

  const updateComponentLink = async(reportId: number, componentId: number, componentLink: ComponentLink): Promise<void> => {
    try {
      await componentLinkApi.update(reportId, componentId, componentLink)
      notifySuccess('リンクを更新しました。')
      vue.$emit('updated')
    } catch (error: any) {
      const status = error.response.status
      if ([403, 404].includes(status)) {
        const message = '変更権限がありません。管理者にお問合せください。'
        notifyError(message, ERROR_GROUP_USER, error)
      } else {
        const message = 'リンクの更新に失敗しました。'
        notifyError(message, ERROR_GROUP_SYSTEM, error)
      }
      vue.$emit('failed')
    }
  }

  return {
    updateComponentLink,
  }
}
