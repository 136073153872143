import axios from 'axios';
import { LogiscopeCompanyDetail } from 'src/models/logiscopeCompanyDetail';
import { buildBackendUrl } from 'src/apis/util/url';

export type LogiscopeCompanyDetailUpdateRequest = Partial<LogiscopeCompanyDetail>;

const buildBasePath = (): string => '/masters/closing_date';

export default {
  async show(): Promise<LogiscopeCompanyDetail> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.get(url);
    return resData;
  },
  async update(data: LogiscopeCompanyDetailUpdateRequest): Promise<LogiscopeCompanyDetail> {
    const url = buildBackendUrl(buildBasePath());
    const { data: resData } = await axios.put(url, data);
    return resData;
  },
};
