import { useEnumOptions } from 'src/composables/useEnumOptions'
import { AggregateFunction } from 'src/business/aggregateFunction'
import { LOGI_SYSTEM_DATA_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS } from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics'

export const useLogiSystemDataMetricsAggregateFunctionOptions = () => {
  const {
    options,
    filterOptions,
  } = useEnumOptions<AggregateFunction>(LOGI_SYSTEM_DATA_METRICS_AGGREGATE_FUNCTION_LOCAL_WORDS)

  return {
    options,
    filterOptions,
  }
}
