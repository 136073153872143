
import { PropType, computed, getCurrentInstance } from 'vue';
import { Location } from 'vue-router';

/**
 * AppLink
 * Vue routerのRouteLinkに相当するカスタムコンポーネント
 * 内部的にはVue routerのAPIを実行する
 * ただし、target属性を与えた場合はVue routerを使用せずにwindow.openをtargetを指定して実行する
 */
export default {
  props: {
    to: {
      type: Object as PropType<Location>,
      required: true,
    },
    target: {
      type: String as PropType<string | undefined>,
      required: false,
    },
  },
  setup(props) {
    const root = getCurrentInstance()!.proxy;
    const href = computed(() => root.$router.resolve(props.to).href);

    const onClick = () => {
      props.target ? window.open(href.value) : root.$router.push(href.value);
    };
    return {
      props,
      href,
      onClick,
    };
  },
};
