import { ZendeskArticle } from 'src/views/Dashboard/Home/Components/composables/useAnnouncementArticles';

type ArticleResponseData = {
  title: string;
  html_url: string;
  created_at: string;
};
export type ArticleIndexResponseData = {
  articles: ArticleResponseData[];
};
export const convertFromArticleResponseData = (data: ArticleResponseData): ZendeskArticle => {
  return {
    title: data.title,
    createdAt: data.created_at,
    htmlUrl: data.html_url,
  };
};
