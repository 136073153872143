import Vue from 'vue';
import VueRouter from 'vue-router';
import VueRouterPrefetch from 'vue-router-prefetch';
import VueNotify from 'vue-notifyjs';
import VeeValidate from 'vee-validate';
import VueMoment from 'vue-moment';
import moment from 'src/util/moment-ja';
import lang from 'element-ui/lib/locale/lang/ja';
import locale from 'element-ui/lib/locale';
import store from './store';
import filters from './filters';
import App from './App.vue';
import { timeDifferenceInSeconds } from 'src/util/datetime';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import initProgress from './progressbar';
import VTooltip from 'v-tooltip';

// router setup
import routes from './routes';

// css imports
import './assets/sass/paper-dashboard.scss';
import './assets/sass/element-variables.scss';
import './assets/sass/logiscope.scss';

// sentry
import * as Sentry from '@sentry/vue';
import { floatWithDecimalPlaces } from './util/VeeValidate/floatWithDecimalPlaces';
import { safeUrl } from './util/VeeValidate/safeUrl';
import { dateFormatExpression } from './util/VeeValidate/DateFormatExpression';

// plugin setup
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueRouterPrefetch);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents); // register global components
Vue.use(VueNotify);
Vue.use(VeeValidate);
Vue.use(VueMoment, { moment });
locale.use(lang);
Vue.use(VTooltip);

// register filters
Object.keys(filters).forEach((k) => {
  Vue.filter(k, filters[k as keyof typeof filters]);
});

// configure router
const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
initProgress(router);

if (process.env.RAILS_ENV && ['production', 'staging', 'demo', 'qa'].includes(process.env.RAILS_ENV)) {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN_VUE,
    environment: process.env.RAILS_ENV,
    tracesSampleRate: 1.0,
  });
}

// add custom validation rules
VeeValidate.Validator.extend('custom_after1', {
  getMessage: () => 'end_time must be same to or after start_time',
  validate: (value) => {
    if ((value.start_time || value.start_time === 0) && (value.end_time || value.start_time === 0)) {
      if (value.max_duration_seconds) {
        const diff = timeDifferenceInSeconds(value.start_time, value.end_time);
        return diff >= 0 && diff <= value.max_duration_seconds;
      } else {
        return value.start_time <= value.end_time;
      }
    }
    return true;
  },
});

VeeValidate.Validator.extend('floatWithDecimalPlaces', floatWithDecimalPlaces);
VeeValidate.Validator.extend('safeUrl', safeUrl);
VeeValidate.Validator.extend('dateFormatExpression', dateFormatExpression);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
