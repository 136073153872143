import { CSSProperties } from 'vue/types/jsx'
import { Icon, classFromIcon } from 'src/util/icon'
import { Decoration } from '../../conditionalStatement'

export const DECORATION_TARGET_ICON = 'icon'

export type IconDecoration = {
  target: typeof DECORATION_TARGET_ICON
  settings: {
    color: string
    icon: Icon
  }
}

export const isDecorationIconDecoration = (decoration: Decoration): decoration is IconDecoration => {
  return decoration.target === DECORATION_TARGET_ICON
}

export const styleFromIconDecoration = (decoration: IconDecoration): Pick<CSSProperties, 'color'> => {
  return {
    color: `#${decoration.settings.color}`
  }
}

export const classFromIconDecoration = (decoration: IconDecoration): string => {
  return classFromIcon(decoration.settings.icon)
}
