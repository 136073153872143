export type PaginationResponseData = {
  total_count: number;
  limit_value: number;
  total_pages: number;
  current_page: number;
};

export type Pagination = {
  total: number;
  currentPage: number;
  limitValue: number;
};

export const convertFromPagination = (pagination: PaginationResponseData): Pagination => {
  if (pagination.total_count == null || !pagination.current_page) {
    throw Error('Pagination object from API is not compatible format.');
  }
  return {
    total: pagination.total_count,
    currentPage: pagination.current_page,
    limitValue: pagination.limit_value,
  };
};
