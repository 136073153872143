import { ref, watch } from 'vue'
import {
  LogiSystemDataMetrics,
} from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics'
import { useLogiCoredataBudgetGroups } from 'src/composables/asyncResources/useLogiCoredataBudgetGroups'
import { useLogiCoredataWorkplaces } from 'src/composables/asyncResources/useLogiCoredataWorkplaces'
import { useLogiCoredataBudgetGroupOptions } from 'src/composables/options/useLogiCoredataBudgetGroupOptions'

export const useLogiCoredataWorkplaceInput = () => {
  const {
    logiCoredataBudgetGroupsRef,
  } = useLogiCoredataBudgetGroups()

  const {
    logiCoredataWorkplacesRef,
  } = useLogiCoredataWorkplaces()

  const {
    options: logiCoredataBudgetGroupOnWorkplaceOptions,
    filterOptions: filterLogiCoredataBudgetGroupOptions,
  } = useLogiCoredataBudgetGroupOptions()

  const logiCoredataWorkplaceId = ref<number | null>(null)

  filterLogiCoredataBudgetGroupOptions([])

  watch(logiCoredataWorkplaceId, (newValue) => {
    const budgetGroupIds = logiCoredataBudgetGroupsRef.value
      .filter(el => el.logiCoredataWorkplaceId === newValue)
      .map(el => el.id)

    filterLogiCoredataBudgetGroupOptions(budgetGroupIds)
  })

  const initializeValue = (metrics: LogiSystemDataMetrics) => {
    const selectedBudgetGroup = logiCoredataBudgetGroupsRef.value.find(el => el.id === metrics.budgetGroupId)
    logiCoredataWorkplaceId.value = logiCoredataWorkplacesRef.value
      .find(el => el.id === selectedBudgetGroup?.logiCoredataWorkplaceId)?.id ?? null
  }

  return {
    logiCoredataWorkplaceId,
    logiCoredataBudgetGroupOnWorkplaceOptions,
    initializeValue,
  }
}
