
import {
  GET_CURRENT_SORT_DIRECTIONS_KEY,
  GET_IS_SORTABLE_KEY,
  ON_SORT_FUNC_KEY,
} from 'src/components/UIComponents/Sorter/SortContext.vue';
import { computed, defineComponent, inject, SetupContext } from 'vue';
import { SortDirection } from 'src/components/UIComponents/Sorter/types';

const SORT_STATE_NONE = 0;
const SORT_STATE_ASC = 1;
const SORT_STATE_DESC = 2;

export default defineComponent({
  name: 'SortButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    sortKey: {
      type: String,
      required: true,
    },
  },
  setup(props, _context: SetupContext) {
    const getCurrentSortDirections = inject(GET_CURRENT_SORT_DIRECTIONS_KEY);
    const onSort = inject(ON_SORT_FUNC_KEY);
    const getIsSortable = inject(GET_IS_SORTABLE_KEY);
    if (getCurrentSortDirections === undefined || onSort === undefined || getIsSortable === undefined) {
      throw new Error('SortButton not used in the context of SortContext');
    }
    const currentSortDirections = getCurrentSortDirections();
    const isSortable = getIsSortable();

    const currentSortState = computed(() => {
      // currentSortDirectionsが複数あっても、画面上ソート中として表示するのは最優先のソート列のみとする
      // (将来的には、複数列同時にソートしている様を見せるようなこともあるかもしれない)
      const matchedDirectionSpec = currentSortDirections.value.slice(0, 1).find((e) => e.key === props.sortKey);
      if (matchedDirectionSpec === undefined) {
        return SORT_STATE_NONE;
      }
      return matchedDirectionSpec.asc ? SORT_STATE_ASC : SORT_STATE_DESC;
    });
    const sortIconClass = computed(() => {
      // ソート不可の場合は上下矢印でグレーアウト
      if (!isSortable.value) {
        return 'fa-sort text-gray';
      }

      switch (currentSortState.value) {
        case SORT_STATE_NONE:
          return 'fa-sort';
        case SORT_STATE_ASC:
          return 'fa-sort-up';
        case SORT_STATE_DESC:
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    });

    function doSort(): void {
      const sortDirection: SortDirection = {
        key: props.sortKey,
        // ソートNONEかDESCの状態でクリックされたらASC、それ以外はDESC
        asc: [SORT_STATE_NONE, SORT_STATE_DESC].includes(currentSortState.value),
      };
      onSort!(sortDirection);
    }

    return {
      sortIconClass,
      doSort,
    };
  },
});
