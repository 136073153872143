import { Metrics } from 'src/models/new/metrics'
import { convertFromMetricsResponseData, MetricsResponseData } from 'src/models/api/Metrics/metricsResponseData'

export type MetricsBreakdownResponse = {
  metrics: MetricsResponseData,
  target_metrics_list: MetricsResponseData[],
  include_forbidden: boolean
}

export type MetricsBreakdown = {
  metrics: Metrics,
  targetMetricsList: Metrics[],
  includeForbidden: boolean
}

// TODO: API側でmetrics_valueを返さずに、responseに必要な値をセットした状態で返すようになったら、
// TODO: convertFromMetricsResponseDataの第二引数(null)廃止予定
export const convertFromMetricsBreakdownResponse = (response: MetricsBreakdownResponse): MetricsBreakdown => {
  return {
    metrics: convertFromMetricsResponseData(response.metrics, null, false),
    targetMetricsList: (response.target_metrics_list as MetricsResponseData[]).map(v =>
      convertFromMetricsResponseData(v, null, true)
    ),
    includeForbidden: (response.include_forbidden as boolean)
  }
}
