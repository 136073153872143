type TextInputOptions = {
  width: string;
  placeholder: string;
};

type SelectInputOptions = {
  options: { label: string; value: string | number }[];
  placeholder: string;
  disabled: boolean;
};

export type FormItem<InputMode> = {
  searchKey: string;
  inputMode: InputMode;
  inputOptions: InputMode extends 'text' ? TextInputOptions : SelectInputOptions;
  required: boolean;
};

export const isFormItemText = (formItem: FormItem<unknown>): formItem is FormItem<'text'> => {
  return formItem.inputMode === 'text' && 'width' in formItem.inputOptions && 'placeholder' in formItem.inputOptions;
};

export const isFormItemSelect = (formItem: FormItem<unknown>): formItem is FormItem<'select'> => {
  return (
    formItem.inputMode === 'select' &&
    'options' in formItem.inputOptions &&
    'placeholder' in formItem.inputOptions &&
    'disabled' in formItem.inputOptions
  );
};
