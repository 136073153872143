
import Chart from 'chart.js'
import { HorizontalBar } from 'vue-chartjs'
import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { deepMerge } from 'src/util/object'
import {
  CHART_BASE_COLOR,
  ChartDataLabel,
  ChartElement,
  ChartPlugin,
  ChartScaleLabel,
  RelativePixelPosition,
  attachScaleLabels,
  buildChartData,
  isIncludingAnnotationPlugin,
  replaceStagedDataset,
  wrapOnChartClick,
} from 'src/components/UIComponents/Charts/shared'
import { HorizontalBarChartDataDataset, HorizontalBarChartOptions } from 'src/util/Chart/horizontalBarChart'
import { tooltipsDefaultOptions } from './tooltipsDefaultOptions'
import { VerticalLineAnnotationOptions } from 'src/util/chart'
import { verticalLineAnnotationPluginDefaultOptions } from './annotationsPluginDefaultOptions'
import { multiChartTooltipsDefaultOptions } from './multiChartTooltipsDefaultOptions'

const defaultOptions: Chart.ChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: true,
  },
  scales: {
    yAxes: [{
      id: 'y-axis-1',
      display: true,
      ticks: {
        padding: 20,
      },
      gridLines: {
        display: true,
        drawBorder: false,
        // colorとzeroLineColorはデフォルト色にそれぞれの補正がかかった状態になるので、同一色にする為に指定する
        color: `${CHART_BASE_COLOR}a0`,
        zeroLineColor: `${CHART_BASE_COLOR}a0`,
      },

    }],
    xAxes: [{
      id: 'x-axis-1',
      display: true,
      gridLines: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        callback: (value: number) => value.toLocaleString('ja-JP'),
        fontStyle: 'bold',
      },
      scaleLabel: {
        display: false,
      },
    }],
  },
  defaultColor: CHART_BASE_COLOR,
  tooltips: tooltipsDefaultOptions,
}

type State = {
  styles: {
    height: string
    width: string
  }
}

export default defineComponent({
  components: {
    HorizontalBar,
  },
  props: {
    labels: {
      type: Array as PropType<ChartDataLabel[]>,
      required: true,
      description: 'Chart labels. Not reactive.',
    },
    datasets: {
      type: Array as PropType<HorizontalBarChartDataDataset[]>,
      required: true,
      description: 'Chart datasets. Reactive. Do not use computed value.',
    },
    options: {
      type: Object as PropType<HorizontalBarChartOptions | HorizontalBarChartOptions & VerticalLineAnnotationOptions>,
      required: false,
      description: 'Chart options. Half reactive (scrap and build new chart after mutation).',
    },
    plugins: {
      type: Array as PropType<ChartPlugin[]>,
      required: false,
      description: 'Chart plugins. Not reactive.',
    },
    scaleLabels: {
      type: Array as PropType<ChartScaleLabel[]>,
      required: false,
      description: 'Extended interface to set label on scales.',
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    onClick: {
      type: Function as PropType<(event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void>,
      required: false,
      description: 'Event which is fired when chart is clicked.',
    },
  },
  setup(props) {
    const myChart = ref(null as HorizontalBar | null)

    const state: State = reactive({
      styles: computed(() => ({ height: props.height, width: props.width })),
    })

    const setEnabledPluginDefaultOptions = (plugins: ChartPlugin[], options: Chart.ChartOptions): Chart.ChartOptions => {
      // HorizontalBarChartの場合、想定しているプラグインの中でoptions以下に設定を持つものがないのでコメントアウト
      // if (!options.plugins) {
      //   options.plugins = {}
      // }

      if (isIncludingAnnotationPlugin(plugins)) {
        // annotationのオプションはoptions.pluginsではなく、options直下に設定する
        Object.assign(options, { annotations: verticalLineAnnotationPluginDefaultOptions })
      }

      return options
    }

    const buildChartOptions = (
      options: HorizontalBarChartOptions | HorizontalBarChartOptions & VerticalLineAnnotationOptions,
      datasets: HorizontalBarChartDataDataset[],
      plugins?: ChartPlugin[],
      scaleLabels?: ChartScaleLabel[],
      onClick?: (event: MouseEvent, chartElement: ChartElement | null, point: RelativePixelPosition | null) => void,
    ): Chart.ChartOptions => {
      if (datasets.length > 1) {
        defaultOptions.tooltips = multiChartTooltipsDefaultOptions
      }
      const mergedOptions = deepMerge(
        setEnabledPluginDefaultOptions(plugins ?? [], defaultOptions),
        options ?? {},
      )
      const chartOptions = attachScaleLabels(scaleLabels ?? [], mergedOptions, 'x')
      if (onClick) {
        Object.assign(chartOptions, { onClick: wrapOnChartClick(onClick) })
      }

      return chartOptions
    }

    onMounted(async() => {
      const chartData = buildChartData(props.labels, props.datasets)
      const chartOptions = buildChartOptions(
        props.options ?? {},
        props.datasets,
        props.plugins,
        props.scaleLabels,
        props.onClick,
      )

      // Vue-chartjs 4.x のマニュアルではpropsでdataとoptionsを渡せばよいことになっているが、上手くいかない
      // refでインスタンスを取得してrenderChartを実行するとグラフが描かれた
      myChart.value!.renderChart(chartData, chartOptions)
    })

    // datasetsはdataの要素数が一定である条件の中でインタラクティブに変更されるものとして想定する
    watch(() => props.datasets, (datasets) => {
      myChart.value!.$data._chart.data.datasets.forEach((el: HorizontalBarChartDataDataset, index: number) => {
        replaceStagedDataset(el, datasets[index])
      })
      myChart.value!.$data._chart.update()
    }, { deep: true })

    // optionsはインタラクティブな変更を受け付ける
    // ただし、datasetsとは異なりグラフを最初から描き直す
    watch(() => props.options, (options) => {
      const chartData = buildChartData(props.labels, props.datasets)
      const chartOptions = buildChartOptions(
        options ?? {},
        props.datasets,
        props.plugins,
        props.scaleLabels,
        props.onClick,
      )
      myChart.value!.renderChart(chartData, chartOptions)
    }, { deep: true })

    return {
      props,
      state,
      myChart,
    }
  },
})
