import axios from 'axios';
import { buildBackendUrl } from 'src/apis/util/url';
import { ReportLink } from 'src/models/new/Link/reportLink';
import {
  ReportLinkResponseData,
  convertFromReportLinkResponseData,
} from 'src/models/api/Link/ReportLink/reportLinkResponseData';
import { MultiDataResponse } from 'src/models/api/shared/multiDataResponse';

const buildBasePath = (reportId: number): string => `/reports/${reportId}/report_links`;

export default {
  async index(reportId: number): Promise<ReportLink[]> {
    const url = buildBackendUrl(buildBasePath(reportId));
    const { data: resData } = await axios.get<MultiDataResponse<ReportLinkResponseData>>(url);
    return resData.result.map(convertFromReportLinkResponseData);
  },
};
