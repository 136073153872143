import type { Metrics, MetricsType } from 'src/models/new/metrics';
import type { Scaling } from 'src/business/scaling';
import type {
  LogiSystemDataMetrics,
  LogiSystemDataMetricsAggregationFunction,
} from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import {
  type LogimeterDataMetrics,
  isMetricsLogimeterDataMetrics,
} from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logimeterDataMetrics';
import {
  type LogiboardDataMetrics,
  isMetricsLogiboardDataMetrics,
} from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logiboardDataMetrics';
import type { TimeSpan } from 'src/business/timeSpan';
import {
  type DirectInputMetrics,
  isMetricsDirectInputMetrics,
} from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import { type SummaryMetrics, isMetricsSummaryMetrics } from 'src/models/new/Metrics/summaryMetrics';
import type { AggregateFunction } from 'src/business/aggregateFunction';
import {
  type ReferenceMetrics,
  type OffsetPeriodUnit,
  isReferenceMetricsOffsetPeriodMonth,
  isReferenceMetricsOffsetPeriodWeek,
  isReferenceMetricsOffsetPeriodYear,
  isMetricsReferenceMetrics,
  internal,
} from 'src/models/new/Metrics/referenceMetrics';
import { type BundledMetrics, isMetricsBundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import { type CalculatedMetrics, isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics';
import { type DayOfWeek, dayOfWeekToNumber } from 'src/util/week';
import type { Operator } from 'src/util/operator';

const DAY = internal.DAY;
const WEEK = internal.WEEK;
const MONTH = internal.MONTH;
const YEAR = internal.YEAR;
const DOW = internal.DOW;

const BASIC_METRICS = 'basic_metrics';
const SUMMARY_METRICS = 'summary_metrics';
const CALC_METRICS = 'calc_metrics';
const REFERENCE_METRICS = 'reference_metrics';
const CROSS_BORDER_METRICS = 'cross_border_metrics';
type InternalMetricsType =
  | typeof BASIC_METRICS
  | typeof SUMMARY_METRICS
  | typeof CALC_METRICS
  | typeof REFERENCE_METRICS
  | typeof CROSS_BORDER_METRICS;

type DirectInputBasicMetricsProperties = {
  data_source: 'direct_input';
  default_value: number | null;
};
type LogiSystemDataBasicMetricsProperties = {
  data_source: 'logimeter' | 'logiboard';
  // ロジシステムデータでは使わない
  // default_value: number | null
  aggr_func: LogiSystemDataMetricsAggregationFunction;
  target_budget_group_id: number;
  data_source_key_id: number;
  basic_metrics_parameters: {
    data_source_parameter_id: number;
    value: string;
  }[];
};
type SummaryMetricsProperties = {
  target_metrics_id: number;
  aggr_func: AggregateFunction;
  is_zero_included: boolean;
  wday: Record<DayOfWeek, boolean>;
};
type ReferenceMetricsProperties = {
  target_metrics_id: number;
  offset_anchor_unit: Extract<OffsetPeriodUnit, typeof WEEK | typeof MONTH | typeof YEAR> | null;
  offset_anchor_diff: number | null;
  offset_unit1: OffsetPeriodUnit | typeof DOW | null;
  offset_value1: number;
  offset_unit2: Extract<OffsetPeriodUnit, typeof DAY> | null;
  offset_value2: number | null;
};
type BundledMetricsProperties = {
  aggr_func: AggregateFunction;
  is_zero_included: boolean;
  targets: {
    target_metrics_id: number;
  }[];
};
type CalculatedMetricsProperties = {
  operand1_id: number | null;
  operand1_value: number | null;
  operator1: Operator | null;
  operand2_id: number | null;
  operand2_value: number | null;
  operator2: Operator | null;
  operand3_id: number | null;
  operand3_value: number | null;
};
export type ChildMetricsCreateUpdateRequest =
  | LogiSystemDataBasicMetricsProperties
  | DirectInputBasicMetricsProperties
  | SummaryMetricsProperties
  | ReferenceMetricsProperties
  | BundledMetricsProperties
  | CalculatedMetricsProperties;

export type MetricsCreateRequestParameters = {
  logiscope_workplace_id: number;
  metrics_type: InternalMetricsType;
  front_metrics_type: MetricsType;
  name: string;
  time_span: TimeSpan;
  unit_name: string | null;
  decimal_places: number;
  scaling: Scaling;
  is_enabled: boolean;
  metrics_access_groups: {
    id: number;
  }[];
} & ChildMetricsCreateUpdateRequest;

export const convertToMetricsCreateRequestParameters = (metrics: Metrics): MetricsCreateRequestParameters => {
  const getInternalMetricsType = (metrics: Metrics): InternalMetricsType => {
    if (isMetricsDirectInputMetrics(metrics)) {
      return 'basic_metrics';
    }
    if (isMetricsLogimeterDataMetrics(metrics)) {
      return 'basic_metrics';
    }
    if (isMetricsLogiboardDataMetrics(metrics)) {
      return 'basic_metrics';
    }
    if (isMetricsCalculatedMetrics(metrics)) {
      return 'calc_metrics';
    }
    if (isMetricsBundledMetrics(metrics)) {
      return 'cross_border_metrics';
    }
    if (isMetricsSummaryMetrics(metrics)) {
      return 'summary_metrics';
    }
    if (isMetricsReferenceMetrics(metrics)) {
      return 'reference_metrics';
    }

    throw Error('Invalid metrics type.');
  };

  const getChildMetricsParams = (metrics: Metrics): ChildMetricsCreateUpdateRequest => {
    if (isMetricsDirectInputMetrics(metrics)) {
      return buildChildFromDirectInputMetrics(metrics as DirectInputMetrics);
    }
    if (isMetricsLogimeterDataMetrics(metrics)) {
      return buildChildFromLogimeterDataMetrics(metrics as LogimeterDataMetrics);
    }
    if (isMetricsLogiboardDataMetrics(metrics)) {
      return buildChildFromLogiboardDataMetrics(metrics as LogiboardDataMetrics);
    }
    if (isMetricsCalculatedMetrics(metrics)) {
      return buildChildFromCalculatedMetrics(metrics as CalculatedMetrics);
    }
    if (isMetricsBundledMetrics(metrics)) {
      return buildChildFromBundledMetrics(metrics as BundledMetrics);
    }
    if (isMetricsSummaryMetrics(metrics)) {
      return buildChildFromSummaryMetrics(metrics as SummaryMetrics);
    }
    if (isMetricsReferenceMetrics(metrics)) {
      return buildChildFromReferenceMetrics(metrics as ReferenceMetrics);
    }

    throw Error('Invalid metrics type.');
  };

  return {
    ...getChildMetricsParams(metrics),
    logiscope_workplace_id: metrics.workplaceId,
    metrics_type: getInternalMetricsType(metrics),
    front_metrics_type: metrics.metricsType,
    name: metrics.name,
    time_span: metrics.timeSpan,
    unit_name: metrics.unit,
    decimal_places: metrics.decimalPlaces,
    scaling: metrics.scaling,
    is_enabled: metrics.isEnabled,
    metrics_access_groups: metrics.accessGroupIds.map((el) => {
      return {
        id: el,
      };
    }),
  };
};

const buildChildFromLogiSystemDataMetrics = (
  metrics: LogiSystemDataMetrics,
): Omit<LogiSystemDataBasicMetricsProperties, 'data_source'> => {
  return {
    aggr_func: metrics.aggrFunc,
    target_budget_group_id: metrics.budgetGroupId,
    data_source_key_id: metrics.logiDataSourceId,
    basic_metrics_parameters: metrics.queryParameters.map((el) => {
      return {
        data_source_parameter_id: el.parameterId,
        value: el.value,
      };
    }),
  };
};
const buildChildFromLogimeterDataMetrics = (metrics: LogimeterDataMetrics): LogiSystemDataBasicMetricsProperties => {
  return { ...buildChildFromLogiSystemDataMetrics(metrics), data_source: 'logimeter' };
};
const buildChildFromLogiboardDataMetrics = (metrics: LogiboardDataMetrics): LogiSystemDataBasicMetricsProperties => {
  return { ...buildChildFromLogiSystemDataMetrics(metrics), data_source: 'logiboard' };
};

const buildChildFromDirectInputMetrics = (metrics: DirectInputMetrics): DirectInputBasicMetricsProperties => {
  return {
    data_source: 'direct_input',
    default_value: metrics.defaultValue,
  };
};

const buildChildFromSummaryMetrics = (metrics: SummaryMetrics): SummaryMetricsProperties => {
  return {
    target_metrics_id: metrics.targetMetricsId,
    aggr_func: metrics.aggrFunc,
    is_zero_included: metrics.isZeroIncluded,
    wday: metrics.weekDays,
  };
};

const buildReferenceMetricsOffsetProperties = (
  metrics: ReferenceMetrics,
): Omit<ReferenceMetricsProperties, 'target_metrics_id'> => {
  const base = {
    offset_anchor_unit: null,
    offset_anchor_diff: null,
    offset_unit1: null,
    offset_value1: null,
    offset_unit2: null,
    offset_value2: null,
  };
  if (
    isReferenceMetricsOffsetPeriodYear(metrics.offsetPeriod) &&
    (metrics.referencePointMonth !== null || metrics.referencePointDay !== null)
  ) {
    return {
      offset_anchor_unit: YEAR,
      offset_anchor_diff: metrics.offsetPeriod.value!,
      offset_unit1: MONTH,
      offset_value1: metrics.referencePointMonth!,
      offset_unit2: metrics.referencePointDay ? DAY : null, // 月次の場合は日を設定しない
      offset_value2: metrics.referencePointDay,
    };
  } else if (isReferenceMetricsOffsetPeriodYear(metrics.offsetPeriod)) {
    return {
      ...base,
      offset_unit1: YEAR,
      offset_value1: metrics.offsetPeriod.value!,
    };
  } else if (isReferenceMetricsOffsetPeriodMonth(metrics.offsetPeriod) && metrics.referencePointDay) {
    return {
      ...base,
      offset_anchor_unit: MONTH,
      offset_anchor_diff: metrics.offsetPeriod.value!,
      offset_unit1: DAY,
      offset_value1: metrics.referencePointDay!,
    };
  } else if (isReferenceMetricsOffsetPeriodMonth(metrics.offsetPeriod)) {
    return {
      ...base,
      offset_unit1: MONTH,
      offset_value1: metrics.offsetPeriod.value!,
    };
  } else if (isReferenceMetricsOffsetPeriodWeek(metrics.offsetPeriod) && metrics.referencePointDow) {
    return {
      ...base,
      offset_anchor_unit: WEEK,
      offset_anchor_diff: metrics.offsetPeriod.value!,
      offset_unit1: DOW,
      offset_value1: dayOfWeekToNumber(metrics.referencePointDow),
    };
  } else if (isReferenceMetricsOffsetPeriodWeek(metrics.offsetPeriod)) {
    return {
      ...base,
      offset_unit1: WEEK,
      offset_value1: metrics.offsetPeriod.value!,
    };
  } else {
    return {
      ...base,
      offset_unit1: DAY,
      offset_value1: metrics.offsetPeriod.value!,
    };
  }
};

const buildChildFromReferenceMetrics = (metrics: ReferenceMetrics): ReferenceMetricsProperties => {
  return {
    target_metrics_id: metrics.targetMetricsId,
    ...buildReferenceMetricsOffsetProperties(metrics),
  };
};

const buildChildFromBundledMetrics = (metrics: BundledMetrics): BundledMetricsProperties => {
  return {
    aggr_func: metrics.aggrFunc,
    is_zero_included: metrics.isZeroIncluded,
    targets: metrics.targetMetricsIds.map((el) => {
      return {
        target_metrics_id: el,
      };
    }),
  };
};

const buildChildFromCalculatedMetrics = (metrics: CalculatedMetrics): CalculatedMetricsProperties => {
  // FIXME: CalculatedMetricsのoperandsとoperatorsをタプル型に変更するとシンプルに書ける
  return {
    operand1_id: metrics.operands[0]?.metricsId ?? null,
    operand1_value: metrics.operands[0]?.constant ?? null,
    operator1: metrics.operators[0] ?? null,
    operand2_id: metrics.operands[1]?.metricsId ?? null,
    operand2_value: metrics.operands[1]?.constant ?? null,
    operator2: metrics.operators[1] ?? null,
    operand3_id: metrics.operands[2]?.metricsId ?? null,
    operand3_value: metrics.operands[2]?.constant ?? null,
  };
};
