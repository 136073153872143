import reportApi from 'src/apis/masters/report';
import { ref } from 'vue';
import { useUrlPathParameters } from 'src/composables/useUrlPathParameters';
import { type Report, constructEmptyReport } from 'src/models/new/report';

const urlPathParametersTemplate = {
  reportId: 0,
};

export const useReport = () => {
  const reportRef = ref<Report>(constructEmptyReport());

  const { urlPathParameters } = useUrlPathParameters(urlPathParametersTemplate);

  const loadReport = async () => {
    const reportId = urlPathParameters.value.reportId;
    if (reportId) {
      reportRef.value = await reportApi.show(reportId);
    } else {
      console.error('reportId is not in url params');
    }
  };

  return {
    reportRef,
    loadReport,
  };
};
