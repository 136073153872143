import { ActionTree, GetterTree, MutationTree } from 'vuex'

type State = {
  pageName: string | null,
}

// the definition of initial state is required.
const state = {
  pageName: null,
}

const getters: GetterTree<State, any> = {
  pageName: state => state.pageName,
}

const SET_PAGE_NAME = 'SET_PAGE_NAME'

const mutations: MutationTree<State> = {
  [SET_PAGE_NAME](state, { pageName }) {
    state.pageName = pageName
  },
}

const actions: ActionTree<State, any> = {
  setPageName({ commit }, { pageName }) {
    commit(SET_PAGE_NAME, { pageName })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
