import { AxiosError } from 'axios';
import { SYSTEM_DATE_FORMAT } from 'src/util/Datetime/format';
import { formatDate } from 'src/util/datetime';
import { getCurrentInstance, inject } from 'vue';

// ページの機能として与えられるメンバの集合
// 他のページ上にコンポーネントとして埋め込まれる場合はページ側からオーバーライドすることができる
export type ReportValuesDetailPageContextMembers = {
  pageName: string;
  getReportId: () => number | null;
  onFailedToGetReport: (err: AxiosError) => void;
  onDateChange: (date: Date) => void;
};
// コンポーネントを他のページ内の埋め込みとして使用する場合にオーバーライド可能なメンバの集合
// 定義の緩いPartial型であるため、受け渡しの際のみ使用し、受け取り後は直ちにPageContextMembersに変換すること
export type ReportValuesDetailPageContextInjection = Partial<ReportValuesDetailPageContextMembers>;

export const reportValuesDetailPageContextInjectionKey = Symbol('reportValuesDetailPageContext');

export const useReportValuesDetailPageContext = () => {
  const vue = getCurrentInstance()!.proxy;

  const defaultGetReportIdCallback = (): number | null => Number(vue.$route.params.reportId);

  const defaultFailedToGetReportCallback = (err: AxiosError) => {
    if (!err.response) {
      return;
    }
    if ([403, 404].includes(err.response.status)) {
      vue.$router.replace({ name: 'ReportValues' });
    }
  };

  const defaultOnDateInputCallback = (date: Date) => {
    vue.$router.push({
      name: 'ReportValuesDetail',
      params: {
        // FIXME: noUncheckedIndexedAccess有効化に伴う暫定対応
        // params.reportId => params.reportId!, params.name => params.name!, params.time_span => params.time_span!
        reportId: vue.$route.params.reportId!,
        name: vue.$route.params.name!,
        time_span: vue.$route.params.time_span!,
      },
      query: {
        dt: formatDate(date, SYSTEM_DATE_FORMAT),
      },
    });
  };

  const defaultPageContextMembers: ReportValuesDetailPageContextMembers = {
    pageName: 'レポート',
    getReportId: defaultGetReportIdCallback,
    onFailedToGetReport: defaultFailedToGetReportCallback,
    onDateChange: defaultOnDateInputCallback,
  };

  const pageContext: ReportValuesDetailPageContextMembers = {
    ...defaultPageContextMembers,
    ...inject<ReportValuesDetailPageContextInjection>(
      reportValuesDetailPageContextInjectionKey,
      defaultPageContextMembers,
    ),
  };

  return { pageContext };
};
